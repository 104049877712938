import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { examEyes, refractionObject } from 'src/app/models/category.model'
import { Util } from 'src/app/models/util.model'

@Component({
	selector: 'refraction',
	templateUrl: './refraction.component.html',
	styleUrls: ['./refraction.component.scss'],
})
export class RefractionComponent implements OnInit {
	@Input() refractionObj: refractionObject[]
	rightEyeExams: refractionObject
	leftEyeExams: refractionObject
	binoEyeExams: refractionObject

	hasMiddleInfo: boolean
	isCombinedData: boolean
	@Input() refractionExamsDate: string
	@Input() topoExamsDate: string

	// @Input('modal') modal: CategoriesController // the parent

	constructor(public translator: TranslateService) {
		this.hasMiddleInfo = false
		this.isCombinedData = false
		this.refractionExamsDate = ''
	}

	ngOnInit(): void {
		// console.log(this.refractionObj)
		if (this.refractionObj) {
			this.rightEyeExams = this.refractionObj.find((x) => x.eye == examEyes.RIGHT)
			this.leftEyeExams = this.refractionObj.find((x) => x.eye == examEyes.LEFT)
			this.binoEyeExams = this.refractionObj.find((x) => x.eye == examEyes.BINO)
		}

		if (this.rightEyeExams?.hasMiddleInfo || this.leftEyeExams?.hasMiddleInfo) {
			this.hasMiddleInfo = true
		}

		if (this.rightEyeExams?.isCombinedData || this.leftEyeExams?.isCombinedData || this.binoEyeExams?.isCombinedData) {
			this.isCombinedData = true
		}
	}
}
