import { Config } from '../../config'
import { DateParser } from './dateParser.model'
import { Util } from './util.model'

// 17.08.2021 oggetto generale, che descrive uno dei piani su Nexus
export class SalePlan {
	id: number
	name: string
	description: string
	level: string
	s_plan_details: any[] // TODO, item + credits, es, {HG_fundus, 6}, {HG_full, 9}
	//monthly_fee: number;

	static HG_FUNDUS = 'HG_fundus'
	static HG_FULL = 'HG_full'
	static AI_DSS = 'AI_DSS'
	static AI_MCS = 'AI_MCS'

	static SALE_BASIC = 'basic'
	static SALE_MIDI = 'middle'
	static SALE_ADV = 'advanced'
	static SALE_EXP = 'expired'
	static SALE_FROZEN = 'frozen'
	static SALE_UAI = 'unlimited_AI'
	static SALE_FRTR = 'free trial'
	static SALE_FRZ = 'frozen'

	// att: valori che arrivano da enum sul DB
	static ITEM_CREDITS = 'credits'
	static ITEM_SPACEHG = 'space_HG'
	static ITEM_AI = 'AI'

	constructor(rawObj?) {
		this.id = 0 // meglio 0 18.04.2023
		this.name = '' // 'free ' + SalePlan.SALE_BASIC
		this.description = ''
		this.level = SalePlan.SALE_BASIC
		this.s_plan_details = []

		if (rawObj != null) {
			// prendo in automatico tutti i campi con lo stesso nome
			// var myJsonObj = angular.copy(rawObj); // e' gia' parserizzato
			var myJsonObj = rawObj // e' gia' parserizzato
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}

	// 27.10.2022 itemType uno tra: HG_FUNDUS, HG_FULL, AI_DSS, AI_MCS
	getCreditCost(itemType: string) {
		let cost = 0

		if (this.s_plan_details && this.s_plan_details.length > 0) {
			for (let i = 0; i < this.s_plan_details.length; i++) {
				if (this.s_plan_details[i].item == itemType) {
					cost = this.s_plan_details[i].credits
					break
				}
			}
		}
		return cost
	}

	// 21.12.2022 il costo max per i report HG
	public getMaxHgReportCost() {
		let costFull = this.getCreditCost(SalePlan.HG_FULL)
		let costFundus = this.getCreditCost(SalePlan.HG_FUNDUS)
		let max = Math.max(costFull, costFundus)

		return max
	}

	public getMinHgReportCost() {
		let costFull = this.getCreditCost(SalePlan.HG_FULL)
		let costFundus = this.getCreditCost(SalePlan.HG_FUNDUS)
		let min = Math.min(costFull, costFundus)

		return min
	}

	public getMCSAiReportCost() {
		let mcs = this.getCreditCost(SalePlan.AI_MCS)
		return mcs
	}

	public getDSSAiReportCost() {
		let dss = this.getCreditCost(SalePlan.AI_DSS)
		return dss
	}

	// 13.01.2023
	isFree() {
		return this.level == SalePlan.SALE_BASIC
	}

	isMiddle() {
		return this.level == SalePlan.SALE_MIDI
	}

	isAdvanced() {
		return this.level == SalePlan.SALE_ADV
	}

	isExpired() {
		return this.level == SalePlan.SALE_EXP
	}

	isFrozen() {
		return this.level == SalePlan.SALE_FROZEN
	}

	// non piú usata
	// isUnlimitedAi() {
	// 	return this.name == SalePlan.SALE_UAI
	// }
}

export class BalanceRec {
	user_id: number
	i_type: string
	notes: string
	amount: number
	buy_date: Date
	expiry_date: Date
	ref_id: string
	sku: string
	kind: string
	channel: string
	description: string

	constructor(rawObj) {
		this.user_id = 0
		this.i_type = ''
		this.notes = ''
		this.sku = ''
		this.kind = ''
		this.channel = ''
		this.amount = 0
		this.buy_date = null
		this.expiry_date = null
		this.ref_id = ''
		this.description = ''

		if (rawObj != null) {
			// prendo in automatico tutti i campi con lo stesso nome
			// var myJsonObj = angular.copy(rawObj); // e' gia' parserizzato
			var myJsonObj = rawObj // e' gia' parserizzato
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			// 04.10.2022 forzo le date per compatibilita' con firefox
			this.buy_date = DateParser.parseDate(rawObj.buy_date)
			this.expiry_date = DateParser.parseDate(rawObj.expiry_date)
		}
	}
}

// 26.10.2022 oggetto info da usare sia per currUser che per balance sui doctors
// contiene al suo interno anche un oggetto salePlan che corrisponde al proprio
// response da API saleplans/<usr_id>
export class SaleInfo {
	used_bytes: number
	sale_plan_id: number // doppione, e' su salePlan.id ?
	//balanceRecords: BalanceRec[] //  only on specific request to APIs
	free_trial: { purchased: string; active: string }
	salePlan: SalePlan
	planExpiryDate: Date
	planStartDate: Date
	available_credits: number
	purchased_credits: number // 20.12.2022
	services: service[]

	// per warnings
	flagExpiry: string
	flagExpiry_desc: string
	flagSpace: string
	flagSpace_desc: string
	flagCredits: string
	flagCredits_desc: string
	//flagRef: string

	constructor(rawObj) {
		this.used_bytes = 0
		this.sale_plan_id = 0 // important: used to decide if the obj is fake
		this.available_credits = 0
		this.purchased_credits = 0
		this.services = []

		this.flagExpiry = Config.GRAY
		this.flagExpiry_desc = ''
		this.flagSpace = Config.GRAY
		this.flagSpace_desc = ''
		this.flagCredits = Config.GRAY
		this.flagCredits_desc = ''

		this.salePlan = new SalePlan(null) // meglio che non sia nullo

		if (rawObj != null) {
			this.used_bytes = rawObj.used_bytes
			this.available_credits = rawObj.available_credits
			this.purchased_credits = rawObj.purchased_credits
			this.free_trial = rawObj.free_trial

			if (rawObj.sale_plan != null) {
				let draft = rawObj.sale_plan
				//console.log("(SaleInfo) raw:");
				//console.log(draft);
				this.salePlan = new SalePlan(draft)
				this.sale_plan_id = this.salePlan.id // patch ? cfr sul balance
			}

			this.planExpiryDate = DateParser.parseDate(rawObj.plan_expiry_date)
			this.planStartDate = DateParser.parseDate(rawObj.plan_start_date)

			if (rawObj.services != null) {
				for (let serv of rawObj.services) {
					this.services.push(new service(serv))
				}
			}
			// }

			// 04.10.2022 forzo le date per compatibilita' con firefox

			console.log('(SaleInfo) plan id:' + this.sale_plan_id + ' level: ' + this.salePlan.level)
		}

		this.initWarningFlags()
	}

	public getNDMservices(status_flag?: string): service[] {
		let ndmServices: service[] = []

		let servicesCopy = this.services.slice()

		if (status_flag) {
			for (let serv of servicesCopy) {
				if (
					(serv.status == serviceStatus.ACTIVE || serv.status == serviceStatus.EXPIRED || serv.status == serviceStatus.FROZEN) &&
					serv.service == serviceType.NDM &&
					serv.status_flag == status_flag
				) {
					ndmServices.push(serv)
				}
			}
		} else {
			for (let serv of servicesCopy) {
				if (
					(serv.status == serviceStatus.ACTIVE || serv.status == serviceStatus.EXPIRED || serv.status == serviceStatus.FROZEN) &&
					serv.service == serviceType.NDM
				) {
					ndmServices.push(serv)
				}
			}
		}

		return ndmServices
	}

	public getHGService(): service | null {
		let hgService: service = null
		let servicesCopy = this.services.slice()

		hgService = servicesCopy.find((s) => s.service == serviceType.HG && s.status == serviceStatus.ACTIVE)

		if (!hgService) {
			hgService = null
		}

		return hgService
	}

	public initWarningFlags() {
		// ndm services ************************
		let today = new Date()
		let preAlert = 21

		let resultsArray: string[] = []

		let NDMservices = this.getNDMservices()

		if (NDMservices.length > 0) {
			for (let serv of NDMservices) {
				//piani flat
				if (serv.service_mode == serviceMode.FLAT) {
					let tPreAlert = new Date(serv.end_date)
					tPreAlert = new Date(tPreAlert.setDate(-preAlert))

					if (serv.end_date <= today || serv.status == serviceStatus.EXPIRED) {
						resultsArray.push(Config.RED)
						serv.status_flag = Config.RED
					} else if (today >= tPreAlert && today < serv.end_date) {
						resultsArray.push(Config.YELLOW)
						serv.status_flag = Config.YELLOW
					} else {
						resultsArray.push(Config.GREEN)
						serv.status_flag = Config.GREEN
					}
					// piani renew montly ad esempio
				} else {
					if (serv.status == serviceStatus.EXPIRED) {
						resultsArray.push(Config.RED)
						serv.status_flag = Config.RED
					} else {
						resultsArray.push(Config.GREEN)
						serv.status_flag = Config.GREEN
					}
				}
			}

			// dal green al red in modo che vengono eseguiti tutti e rimane il piú importante
			if (resultsArray.indexOf(Config.GREEN) > -1) {
				this.flagExpiry = Config.GREEN
				this.flagExpiry_desc = Config.GREEN + ' - expired'
			}

			if (resultsArray.indexOf(Config.YELLOW) > -1) {
				this.flagExpiry = Config.YELLOW
				this.flagExpiry_desc = Config.YELLOW + ' - less than 21 days before expire'
			}

			if (resultsArray.indexOf(Config.RED) > -1) {
				this.flagExpiry = Config.RED
				this.flagExpiry_desc = Config.RED + ' OK'
			}
		} else {
			this.flagExpiry = Config.GREEN
			this.flagExpiry_desc = Config.GREEN + ' OK'
		}

		// credits ************************
		let limit = 45
		let totC = this.available_credits

		let HGService = this.getHGService()

		if (HGService) {
			if (HGService.service_mode == serviceMode.FLAT) {
				this.flagCredits = Config.GREEN
				this.flagCredits_desc = Config.GREEN + ' - ok enough credits'
			} else {
				if (totC) {
					// se 0 non entra mai qui, ma vorrei la notifica che é 0

					if (totC < 9) {
						this.flagCredits = Config.RED
						this.flagCredits_desc = Config.RED + ' - no credits'
					} else if (totC >= 9 && totC < limit) {
						this.flagCredits = Config.YELLOW
						this.flagCredits_desc = Config.YELLOW + ' - few credits'
					} else {
						this.flagCredits = Config.GREEN
						this.flagCredits_desc = Config.GREEN + ' - ok enough credits'
					}
				}

				if (totC == 0) {
					this.flagCredits = Config.RED
					this.flagCredits_desc = Config.RED + ' - no credits'
				}
			}
		} else if (!HGService && totC == 0) {
			this.flagCredits = Config.GREEN
			this.flagCredits_desc = Config.GREEN + ' - ok user without HG service'
		}

		// space ************************
		let maxSize = 2 * 1024 * 1024 * 1024 // 2 GB // 2147483648
		//let firstWarn = 80009926 // test
		//let secWarn = 83009926 // test
		let firstWarn = 1.9 * 1024 * 1024 * 1024 // 1,9 GB // 2040109465
		let secWarn = 1.8 * 1024 * 1024 * 1024 // 1,8 GB // 1932735283
		let usedBytes = this.used_bytes

		// 30.12.2022 soglia 2 GB solo per i free
		if (usedBytes && this.salePlan.level == SalePlan.SALE_BASIC) {
			if (usedBytes > secWarn) {
				this.flagSpace = Config.RED
				this.flagSpace_desc = Config.RED + ' - used more than 2 GB'
				Util.debug('(balance) RED - used more than max_size')
			} else if (usedBytes > 0 && usedBytes > firstWarn) {
				this.flagSpace = Config.YELLOW
				this.flagSpace_desc = Config.YELLOW + ' - almost reached the max size'
				Util.debug('(balance) YELLOW - used more than warn_size')
			} else {
				this.flagSpace = Config.GREEN
				this.flagSpace_desc = Config.GREEN + ' - ok, less than 2 GB'
			}
		}
	}

	public getUsedSpace(fixedMB?): string {
		var ret = '-'
		if (this.used_bytes > 0) {
			if (fixedMB && fixedMB == true) {
				ret = '' + Util.formatBytesToMB(this.used_bytes) // tutto in MB, senza unit
			} else {
				ret = Util.formatBytes(this.used_bytes, 2) // 2 = cifre dopo la virgola
			}
		}
		return ret
	}

	getCreditCost(itemType: string) {
		let cost = 0
		if (this.salePlan) {
			cost = this.salePlan.getCreditCost(itemType)
		}
		return cost
	}

	// public getFrozenStartDate() {
	// 	return this.salePlan.isFrozen() ? this.planStartDate : null
	// }

	public getActiveHGService(): service | null {
		let hgService = this.services.find((s) => s.service == serviceType.HG && s.status == serviceStatus.ACTIVE)

		return hgService
	}

	public getActiveAIService(sn: string): service | null {
		let aiService = this.services.find((s) => s.device_sn == sn && s.service == serviceType.AI && s.status == serviceStatus.ACTIVE)
		return aiService
	}
}

export class service {
	id: number
	additions: string
	balance_id: number
	pack_id: string
	pack: string
	description: string
	service_description: string
	service: serviceType
	service_mode: serviceMode
	status: serviceStatus
	status_flag: string // non arriva dalle api
	buy_date: Date
	start_date: Date
	end_date: Date
	device_model: string[]
	device_sn: string

	constructor(rawObj?) {
		this.id = 0
		this.additions = null
		this.balance_id = 0
		this.pack_id = ''
		this.pack = ''
		this.description = ''
		this.service_description = ''
		this.service = serviceType.NDM
		this.service_mode = serviceMode.FLAT
		this.status = serviceStatus.ACTIVE
		this.status_flag = Config.GRAY
		this.buy_date = null
		this.start_date = null
		this.end_date = null
		this.device_model = []
		this.device_sn = ''

		if (rawObj) {
			this.id = rawObj.id
			this.additions = rawObj.additions
			this.balance_id = rawObj.balance_id
			this.pack_id = rawObj.pack_id
			this.pack = rawObj.pack
			this.description = rawObj.description
			this.service = rawObj.service
			this.service_mode = rawObj.service_mode
			this.status = rawObj.status
			if (rawObj.device_model) {
				this.device_model = rawObj.device_model.split(/, ?/) //trasfonrmo in array di stringhe
			}

			this.device_sn = rawObj.device_sn
			this.buy_date = DateParser.parseDate(rawObj.buy_date)
			this.start_date = DateParser.parseDate(rawObj.start_date)
			this.end_date = DateParser.parseDate(rawObj.end_date)
		}
	}
}

export enum serviceStatus {
	ACTIVE = 'active',
	PURCHASED = 'purchased',
	EXPIRED = 'expired',
	FROZEN = 'frozen',
	SCHEDULED = 'scheduled',
}

export enum serviceType {
	NDM = 'NDM',
	NDM_S = 'NDM_S',
	AI = 'AI',
	HG = 'HG',
	TR = 'TR',
	RI = 'RI',
	IM = 'IM',
}

export enum serviceMode {
	FLAT = 'flat',
	CREDITS = 'credits',
	MONTLY_CAP = 'monthly_cap',
	MONTLY_RENEW = 'monthly_renew',
}

export class skuArticles {
	article: string
	description: string
	device: string
	id: number
	is_free: string // N or Y
	notes: string
	quantity: number
	recurrent: string // N or Y
	service: string
	sku: string
	sku_type: string

	constructor(rawObj?) {
		this.article = ''
		this.description = ''
		this.device = ''
		this.id = 0
		this.is_free = 'N'
		this.notes = ''
		this.quantity = 0
		this.recurrent = 'N'
		this.service = ''
		this.sku = ''
		this.sku_type = ''

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}
