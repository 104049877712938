import { Injectable } from '@angular/core'
import { Anamnesis, AnamnPillsData, PatientReply, VA } from '../models/anamnesis.model'
import { SessionService } from './session.service'
import { Config } from 'src/config'
import { DataModelService } from './data-model.service'
import { Subscription } from 'rxjs'
import { Util } from '../models/util.model'

@Injectable({
	providedIn: 'root',
})
export class AnamnesisService {
	medicalAnamnesis: Anamnesis[] = []
	impactAnamnesis: Anamnesis[] = []
	userLoggedSubscription: Subscription

	constructor(private session: SessionService, private data: DataModelService) {}

	// based on anamn_group of the optician
	// ritorna array di domande con traduzioni e array di risposte
	getAnamnesisQuestions(type: 'medical' | 'impact'): Promise<Anamnesis[]> {
		return new Promise<Anamnesis[]>((resolve, reject) => {
			// Determine the cache and group based on type
			const isMedical = type === 'medical'
			const cachedAnamnesis = isMedical ? this.medicalAnamnesis : this.impactAnamnesis
			let anamnGroup = isMedical ? 1 : 1000

			if (cachedAnamnesis && cachedAnamnesis.length > 0) {
				resolve(cachedAnamnesis)
				return
			}

			const lang = this.session.getLanguage()
			if (this.session.isLevel1()) {
				anamnGroup = isMedical ? this.session.user.settings.anamnesis_group : this.session.user.settings.impact_anamn_group
			}

			// Load anamnesis data and update the appropriate cache
			this.data
				.loadAnamnesis(this.session.buildBaseRequest(), anamnGroup, lang)
				.then((response) => {
					if (isMedical) {
						this.medicalAnamnesis = response
					} else {
						this.impactAnamnesis = response
					}
					this.checkuserLoggedSubscription()
					resolve(response)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	// come list mi basta un array di coppie: question_id, reply_id, solo quelle risposte
	savePatientAnamnesis(patientId: number, list: PatientReply[], type: 'medical' | 'impact') {
		let request: any = this.session.buildBaseRequest()
		request.method = 'POST'
		request.url = type === 'medical' ? Config.anamnesisEndpoint : Config.impactAnamnesisEndpoint

		// gestire poi su edit un "annullo" risposta ?

		let myAnamn = {
			patient_id: patientId,
			questions: list,
		}

		let myData = { anamnesis: myAnamn }

		return this.data.myPost(request, myData)
	}

	// domande e risposte date dal paziente
	getPatientAnamnesisAnswers(patientId: number, type: 'medical' | 'impact', repDate?, repId?): Promise<Anamnesis[] | null> {
		// let memoryAnam = this.data.getPatientAnam(patientId, type, repDate, repId)
		// // console.log(memoryAnam)

		// if (memoryAnam.result) {
		// 	return Promise.resolve(memoryAnam.anamnesis)
		// }

		// se no, le chiedo al server
		let lang = 'en'
		lang = this.session.getLanguage()

		return this.data.loadPatientAnamnesis(this.session.buildBaseRequest(), patientId, lang, type, repDate, repId)
	}

	// ************* patient anamnesis VA *****************

	savePatientAnamnesisVA(patientId: number, va: VA) {
		let request: any = this.session.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.vaEndpoint

		let data = {
			patient_id: patientId,
			va: va,
		}
		let myData = { patient_va: data }
		return this.data.myPost(request, myData)
	}

	getPatientVA(patientId: number, repDate?, repId?): Promise<VA> {
		let memoryVA = this.data.getVAPatient(patientId, repDate, repId)
		// console.log(memoryVA)

		if (memoryVA.result) {
			return Promise.resolve(memoryVA.va)
		}

		return this.data.loadPatientVA(this.session.buildBaseRequest(), patientId, repDate, repId)
	}

	getAnamnesisPills(patId: number, lang: string, day?: string): Promise<AnamnPillsData> {
		const request: any = this.session.buildBaseRequest()

		request.method = 'GET'
		request.url = Config.anamnesisEndpoint + '/pills?pat=' + patId + '&lang=' + lang

		if (day) {
			request.url += '&day=' + day
		}

		return this.data.myGet(request)
	}

	private checkuserLoggedSubscription() {
		if (this.session.isLevel1() && !this.userLoggedSubscription) {
			Util.debug('AnamnesisService - subscription')
			this.userLoggedSubscription = this.session.userLogged.subscribe((resp: boolean) => {
				// console.log(resp)
				if (!resp) {
					this.medicalAnamnesis = []
				}
			})
		}
	}
}
