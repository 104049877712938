import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
// import { Visit } from '../../models/visit.model'
// import { Patient } from '../../models/patient.model'

import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap'

import { Subscription } from 'rxjs'

import { Util } from '../../models/util.model'
import { ExamImage, FundusExam, FundusExtra } from 'src/app/models/exam.model'
import { reportsService } from 'src/app/service/reports.service'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'

import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons'

@Component({
	selector: 'aiReview-modal',
	templateUrl: './aiReview.modal.html',
	styleUrls: ['./aiReview.modal.scss'],
})
export class aiReviewModal implements OnInit, OnDestroy {
	fundusExamList: FundusExam[] // implementando il servizio non serve riceverlo dal padre, lista esami selezionati

	//Esami filtrati da fundusExamList left e right
	fundusExamListLeft: FundusExam[]
	fundusExamListRight: FundusExam[]
	fundusExamNotCentral: FundusExam[]

	//Array di immagini da selezionare per il grading, nel caso di piú esami selezionati
	examImagesLeft: ExamImage[]
	examImagesRight: ExamImage[]

	//Immagini selezionate per la richiesta
	leftSelected: ExamImage
	rightSelected: ExamImage

	loadingFundusSubscription: Subscription

	loading: boolean //from service
	progressbarValue: number //value of the progressbar 0 - 100
	progressbarType: string // define the color success = green, danger = red
	requestReviewStart: boolean // when request start
	requestReviewStopped: boolean // when request is cancelled or stopped
	requestReviewEnd: boolean // when request ends
	hiddenSelections: boolean // per nascondere le immagini in selezione multipla

	requestReviewTry: number

	statusString: string
	leftString: string
	rightString: string

	currentModal

	//icons
	faThumbsUp = faThumbsUp
	faThumbsDown = faThumbsDown
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		private translator: TranslateService,
		public modalService: NgbModal,
		private reportsService: reportsService,
		private ngbConfig: NgbProgressbarConfig,
		private toastService: AppToastService,
		private renderer: Renderer2
	) {
		Util.debug('(aiReview-modal) - constructor')

		this.loading = true
		this.requestReviewStart = false
		this.requestReviewStopped = false
		this.requestReviewEnd = false

		this.requestReviewTry = 0

		this.statusString = ''
		this.rightString = this.translator.instant('AI_REVIEW.NO_RIGHT')
		this.leftString = this.translator.instant('AI_REVIEW.NO_LEFT')

		// configurazione progress bar
		this.progressbarValue = 0
		this.progressbarType = 'success'
		this.ngbConfig.animated = true
		this.ngbConfig.max = 100
		this.ngbConfig.striped = true
		// this.ngbConfig.type = 'success'
		this.ngbConfig.height = '25px'
		//end

		this.fundusExamListLeft = []
		this.fundusExamListRight = []
		this.fundusExamNotCentral = []

		this.examImagesLeft = []
		this.examImagesRight = []

		this.leftSelected = new ExamImage()
		this.rightSelected = new ExamImage()

		// spostato qui, altrimenti nel ngOnInint, poteva succedere che ricaprendo il modal con le stesse selezioni di prima, venisse chiamato il next lato visitlist, prima di questa subscribe
		this.loadingFundusSubscription = this.reportsService.loadingFundus.subscribe((value) => {
			if (value && this.fundusExamList) {
				// console.log(this.fundusExamList)

				//Divido le immmagini in destro e sinistro mantenendo solo le centrali

				this.fundusExamListLeft = this.fundusExamList.filter((img) => img.eye == 'left')

				this.fundusExamListRight = this.fundusExamList.filter((img) => img.eye == 'right')

				this.fundusExamNotCentral = this.fundusExamList.filter((img) => img.image_central == null)

				// creo gli array di immagini
				this.examImagesLeft = this.createImgArray(this.fundusExamListLeft)
				this.examImagesRight = this.createImgArray(this.fundusExamListRight)

				//preseleziono se solo 1 immagine
				if (this.examImagesLeft && this.examImagesLeft.length == 1) {
					this.leftSelected = this.examImagesLeft[0]
				}

				if (this.examImagesRight && this.examImagesRight.length == 1) {
					this.rightSelected = this.examImagesRight[0]
				}

				this.loading = false
			}
		})
	}

	ngOnInit(): void {
		Util.debug('(aiReview-modal) - ngOnInit')
	}

	private createImgArray(fundusExam: FundusExam[]) {
		let examImages: ExamImage[] //ready for album
		examImages = []

		for (let i = 0; i < fundusExam.length; i++) {
			const exam = fundusExam[i]

			if (exam.extras.length > 0) {
				//fix caso in cui esame caricato senza immagini (solo durante test)
				let extras: FundusExtra[] = exam.extras.filter((d) => d.fixation == 'central')

				if (extras.length > 0) {
					let img: ExamImage = new ExamImage()

					img.date = exam.exam_date
					img.descr = extras[0].fixation
					img.examId = exam.id
					img.eye = exam.eye
					img.image = exam.image_central
					img.imgId = extras[0].id
					img.position = i
					img.quality = extras[0].quality
					img.selected = false
					img.type = Config.EXM_FUNDUS //non servirebbe

					examImages.push(img)
				}
			}
		}
		return examImages
	}

	//func. richiamata al click della selezione di immagini in caso di slezione multipla esami
	public imageSelection(img: ExamImage) {
		if (this.hiddenSelections) {
			//disabilito il click durante il processo
			return
		}
		// se click dopo uno stop e quindi ho immagini multiple
		if (this.requestReviewStopped) {
			this.requestReviewStart = false
			this.requestReviewStopped = false
			this.progressbarValue = 0
		}

		let eye = img.eye

		if (eye == 'left') {
			this.deselectAll(this.examImagesLeft) //deseleziono tutte le immagini

			this.leftSelected = img
		} else {
			this.deselectAll(this.examImagesRight)

			this.rightSelected = img
		}

		img.selected = true
	}

	private deselectAll(list: ExamImage[]) {
		for (let i = 0; i < list.length; i++) {
			const exam = list[i]

			exam.selected = false
		}
	}

	// # part 1 - quality request
	async requestReview() {
		Util.debug('(aiReview-modal) - requestReview start ')

		this.progressbarType = 'success'
		this.requestReviewStart = true
		this.hiddenSelections = true

		let left = this.leftSelected.imgId != 0
		let right = this.rightSelected.imgId != 0

		let retry = false //per far ritentare la chiamata in caso di fail per risposta >= 500 del server
		this.requestReviewTry++

		// console.log(left)
		// console.log(right)

		this.progressbarValue = 25 // Valutazione immagini
		this.statusString = this.translator.instant('AI_REVIEW.STEP1')

		if (left && this.leftSelected.quality < 1) {
			//se c'é una immagine left selezionata e la sua quality non é giá stata valutata
			Util.debug('(aiReview-modal) - left image quality request start ')

			var leftRequest = await this.reportsService //richiedo il quality della left
				.getAiQuality(this.leftSelected)
				.then((qlevel) => {
					Util.debug('quality: ' + qlevel)

					this.leftSelected.setQuality(qlevel)

					// setto la qualitá arrivata
					for (let i = 0; i < this.fundusExamList.length; i++) {
						if (this.fundusExamList[i].id == this.leftSelected.examId) {
							this.fundusExamList[i].extras[0].quality = this.leftSelected.quality
						}
					}

					return qlevel
				})
				.catch((err) => {
					console.log(err)

					if (err.status >= 500) {
						// se >=500 il server AI per qualche motivo ha risposto errore, ma rifacendo la richiesta va a buon fine

						this.progressbarValue = 100
						this.progressbarType = 'danger'
						this.statusString = this.translator.instant('AI_REVIEW.STEPERROR')

						retry = true
					} else {
						let msg = ''
						if (err.status == 422) {
							msg = 'AI report temporarily not available for *old* uploaded exams.'
						} else {
							msg = this.session.parseErrorMessage(err, 'alert')
						}

						let header = this.translator.instant('TOAST.HEADER.ERROR')
						let body = msg
						let options = new ToastOptions('error')

						this.toastService.show(header, body, false, options, 'center')
					}

					return -1
				})

			// console.log(this.leftSelected)
		} else {
			leftRequest = this.leftSelected.quality
		}

		if (right && this.rightSelected.quality < 1) {
			//se c'é una immagine right selezionata e la sua quality non é giá stata valutata
			Util.debug('(aiReview-modal) - right image quality request start ')

			var rightRequest = await this.reportsService
				.getAiQuality(this.rightSelected)
				.then((qlevel) => {
					Util.debug('quality: ' + qlevel)

					this.rightSelected.setQuality(qlevel)

					// setto la qualitá arrivata
					for (let i = 0; i < this.fundusExamList.length; i++) {
						if (this.fundusExamList[i].id == this.rightSelected.examId) {
							this.fundusExamList[i].extras[0].quality = this.rightSelected.quality
						}
					}

					return qlevel
				})
				.catch((err) => {
					console.log(err)

					if (err.status >= 500) {
						// se >=500 il server AI per qualche motivo ha risposto errore, ma rifacendo la richiesta va a buon fine

						this.progressbarValue = 100
						this.progressbarType = 'danger'
						this.statusString = this.translator.instant('AI_REVIEW.STEPERROR')

						retry = true
					} else {
						let msg = ''
						if (err.status == 422) {
							msg = 'AI report temporarily not available for *old* uploaded exams.'
						} else {
							msg = this.session.parseErrorMessage(err, 'alert')
						}

						let header = this.translator.instant('TOAST.HEADER.ERROR')
						let body = msg
						let options = new ToastOptions('error')

						this.toastService.show(header, body, false, options, 'center')
					}

					return -1
				})

			// console.log(this.rightSelected)
		} else {
			rightRequest = this.rightSelected.quality
		}

		if (retry && this.requestReviewTry < 2) {
			console.log('retry to send the request')
			this.requestReview()
			return
		}

		// console.log(leftRequest)
		// console.log(rightRequest)

		if (leftRequest == -1 || rightRequest == -1) {
			//se c'é stato un errore nella richiesta del quality
			let msg = this.translator.instant('AI_REVIEW.STEP1FAIL1')

			this.requestFail(msg)

			return
		} else {
			this.statusString = this.translator.instant('AI_REVIEW.STEP2')
			this.progressbarValue = 50
		}

		let leftQ = this.hasValidQuality(leftRequest)
		let rightQ = this.hasValidQuality(rightRequest)

		// console.log(leftQ)
		// console.log(rightQ)

		// se entrambe hanno una quality <5 possono essere inviate
		if (leftQ && rightQ) {
			Util.debug('both images have quality <=4, left: ' + leftRequest + 'right: ' + rightRequest)

			let leftMid = this.hasMidQuality(leftRequest)
			let rightMid = this.hasMidQuality(rightRequest)

			if (leftMid || rightMid) {
				// almeno 1 ha qualitá 4
				Util.debug('at least 1 images have quality = 4, left: ' + leftRequest + 'right: ' + rightRequest)

				let eye = this.getEyeString(leftMid, rightMid)

				let msg = this.translator.instant('AI_REVIEW.STEP2_CONFIRM1', { v1: eye })

				this.confirmGrading(msg)
			} else {
				// nessuna ha qualitá 4
				let msg = this.translator.instant('AI_REVIEW.STEP2_CONFIRM2')

				this.confirmGrading(msg)
			}
		} else {
			Util.debug('image quality not good = 5')
			// quality 5, non possono essere inviate
			this.requestStopped(true)
		}

		this.requestReviewTry = 0
	}

	// # part 2 - Grading Request
	private confirmGrading(msg) {
		this.requestReviewTry++

		// chiedo conferma di voler inviare le immagini
		this.openConfirmModal(msg)
			.result.then((result) => {
				// console.log(result)

				this.progressbarType = 'success'
				this.statusString = this.translator.instant('AI_REVIEW.STEP3')
				this.progressbarValue = 75

				let myImages: ExamImage[] = []

				if (this.leftSelected && this.leftSelected.imgId != 0) {
					myImages.push(this.leftSelected)
				}

				if (this.rightSelected && this.rightSelected.imgId != 0) {
					myImages.push(this.rightSelected)
				}
				// invio la ricvhiesta
				this.reportsService
					.aiReportRequest(myImages)
					.then((batchId) => {
						// console.log(batchId)

						this.statusString = this.translator.instant('AI_REVIEW.STEP4')
						this.requestReviewStopped = true
						this.progressbarValue = 100

						// console.log(this.fundusExamList)

						setTimeout(() => {
							this.requestReviewStart = false
							this.requestReviewEnd = true

							setTimeout(() => {
								this.close(batchId, myImages) //close modal with confirm
							}, 4000)
						}, 2000)
					})
					.catch((err) => {
						console.log(err)

						if (err.status >= 500) {
							// se >=500 il server AI per qualche motivo ha risposto errore, ma rifacendo la richiesta va a buon fine

							this.progressbarValue = 100
							this.progressbarType = 'danger'
							this.statusString = this.translator.instant('AI_REVIEW.STEPERROR')

							let msg = this.translator.instant('AI_REVIEW.STEPERROR_RETRY')

							if (this.requestReviewTry < 2) {
								this.confirmGrading(msg)
							} else {
								let msg = this.translator.instant('AI_REVIEW.REQUEST_FAIL')

								this.requestFail(msg)
							}
						} else {
							let errmsg = this.session.parseErrorMessage(err, 'alert')

							let header = this.translator.instant('TOAST.HEADER.ERROR')
							let body = errmsg
							let options = new ToastOptions('error')
							this.toastService.show(header, body, false, options, 'center')

							let msg = this.translator.instant('AI_REVIEW.REQUEST_FAIL')

							this.requestFail(msg)
						}
					})
			})
			.catch((res) => {
				console.log(res)
				this.requestStopped(false)
			})
	}

	private hasValidQuality(val: number) {
		return val >= 0 && val <= 4 // allineato con Vistel
	}

	private hasMidQuality(val: number) {
		return val == 4
	}

	private getEyeString(left: boolean, right: boolean) {
		let eye = ''
		if (left && right) {
			console.log('both q=4')
			return (eye = this.translator.instant('AI_REVIEW.BOTH_EYE'))
		} else if (left) {
			console.log('left q=4')

			return (eye = this.translator.instant('AI_REVIEW.LEFT_EYE'))
		} else if (right) {
			console.log('right q=4')

			return (eye = this.translator.instant('AI_REVIEW.RIGHT_EYE'))
		}
	}

	private requestFail(text: string) {
		this.statusString = text
		this.progressbarType = 'danger' // cambio il colore della barra
		this.progressbarValue = 100

		this.requestReviewStopped = true
		// this.requestReviewStart = false

		setTimeout(() => {
			this.dismiss()
		}, 6000)
	}

	private requestStopped(warning: boolean) {
		if (warning) {
			this.progressbarType = 'danger'

			let header = this.translator.instant('TOAST.HEADER.WARNING')
			let body = this.translator.instant('AI_REVIEW.STEP1FAIL2')
			let options = new ToastOptions('notification_s')
			this.toastService.show(header, body, false, options, 'center')
		}
		this.statusString = this.translator.instant('AI_REVIEW.STOPPED')
		this.progressbarValue = 100
		this.requestReviewStopped = true
		this.hiddenSelections = false
	}

	openConfirmModal(text: string) {
		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		return this.currentModal
	}

	dismiss() {
		Util.debug('(aiReviewModal) dismiss modal')
		this.activeModal.dismiss()
	}

	private close(batchId, myImages) {
		let array = []
		array.push(batchId, myImages)
		this.activeModal.close(array)
	}

	ngOnDestroy(): void {
		this.loadingFundusSubscription.unsubscribe()
		Util.debug('(aiReviewModal) - destroy')
	}
}
