import { Component, Input, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core'
import { User, UserDevice, userDeviceSubscription } from '../../models/user.model'
import { Doctor } from 'src/app/models/doctor.model'
import { Config } from '../../../config'
import { SessionService } from 'src/app/service/session.service'
import { TranslateService } from '@ngx-translate/core'

import { SaleInfo, SalePlan, service, serviceStatus, serviceType } from '../../models/salePlan.model'

import { Util } from '../../models/util.model'
import { Specialist } from 'src/app/models/specialist.model'
import { RequestChangeGraderModalContent } from './request-change-grader.modal'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { relType, supportTask } from 'src/app/models/changeGrader.model'
import { ToastOptions } from 'src/app/models/toast.model'
import { AppToastService } from 'src/app/service/toast.service'
import { Subject, Subscription } from 'rxjs'
import { throttleTime } from 'rxjs/operators'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'

import { faCircleUser } from '@fortawesome/free-regular-svg-icons'
import {
	faCaretDown,
	faCaretUp,
	faCircleCheck,
	faCirclePlus,
	faXmark,
	faCircleXmark,
	faCircleExclamation,
	faSortDown,
	faUpRightFromSquare,
	faClock,
} from '@fortawesome/free-solid-svg-icons'
import { pairingDevices } from 'src/app/models/device.model'
import { ConfirmModal } from '../confirm/confirm.modal'
import { AgrStatus, AgrType } from 'src/app/models/agreement.model'
import { AgreementModal } from '../agreement-modal/agreement-modal.modal'

export interface purchasePackage {
	package_name: string
	description: string
	pack_id: string
	buy_date: Date
	services: service[]
	availableDevices: UserDevice[]
	assignEnabled: boolean
	loading: boolean
}

@Component({
	selector: 'app-credits',
	templateUrl: './credits.component.html',
	styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() currUser: User
	@Input() currDoc: Doctor
	@Input() saleInfo: SaleInfo

	@ViewChild(MatTable) table: MatTable<any>
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort

	// new develops
	purchaseServices: service[]
	accountServices: service[]
	deviceServices: service[]
	userDevices: userDeviceSubscription[]
	pairingDevice: pairingDevices[]

	showEcommerce: boolean

	purchasePackService: purchasePackage[]

	userDevicesTable: MatTableDataSource<userDeviceSubscription>

	displayedColumns: string[]

	expandedElement: userDeviceSubscription | null

	deviceToUpdate: UserDevice

	servicesUpdatedStatusSubscribe: Subscription

	askSupportTask: Subject<null> = new Subject<null>()

	currentProfile: string
	expireDate: string
	gracePeriodExpiryDate: string

	usedSpace: string

	credits: string

	viewReady: boolean

	specialistList: Specialist[]
	totGraders: number
	canReqNewGrader: boolean

	currentModal: NgbModalRef
	user: User | Doctor

	faCircleUser = faCircleUser
	faCaretDown = faCaretDown
	faCaretUp = faCaretUp
	faCircleCheck = faCircleCheck
	faCirclePlus = faCirclePlus
	faXmark = faXmark
	faCircleXmark = faCircleXmark
	faCircleExclamation = faCircleExclamation
	faSortDown = faSortDown
	faUpRightFromSquare = faUpRightFromSquare
	faClock = faClock

	constructor(public session: SessionService, private translator: TranslateService, public modalService: NgbModal, private toastService: AppToastService) {
		Util.debug('(Credits) - constructor')

		this.purchaseServices = []
		this.accountServices = []
		this.deviceServices = []
		this.userDevices = []
		this.pairingDevice = []
		this.purchasePackService = []

		this.deviceToUpdate = null

		this.displayedColumns = ['model', 'sn', 'services_names', 'status', 'expand']

		this.currentProfile = ''

		this.expireDate = '-'
		this.gracePeriodExpiryDate = '-'

		this.usedSpace = '-'

		this.credits = 'No credits'

		this.viewReady = false

		this.specialistList = []
		this.totGraders = 0
		this.canReqNewGrader = false

		this.showEcommerce = true
	}

	ngOnInit(): void {
		Util.debug('(Credits) - OnInit')

		// console.log(this.session.user)

		if (this.currUser != null) {
			this.currentProfile = 'is_user'

			this.specialistList = this.currUser.specialists
		} else if (this.currDoc != null) {
			this.currentProfile = 'is_doc'

			this.specialistList = this.currDoc.specialists
		}

		this.specialistList = this.specialistList.filter((s) => s.user_subtype == Config.SUB_STD || s.user_subtype == Config.SUB_PRIVATE)

		this.initUser()

		if (this.session.isOptician() && this.saleInfo.salePlan.isAdvanced() && !this.session.userHasPrivateGrader() && this.specialistList.length !== 0) {
			this.session.getTotGraders().then((res) => {
				this.totGraders = res.graders_available
				if (this.totGraders > 1) {
					this.canReqNewGrader = true
				}
			})
		}

		this.servicesUpdatedStatusSubscribe = this.session.servicesUpdatedStatus.subscribe((res: service[]) => {
			// console.log(res)
			// this.saleInfo.services = res
			this.initUserServices()
			// this.initUserDevices()
			this.initUserDevicesTable(this.userDevices)
		})

		// implementato throttleTime per impedire richieste multiple in poco tempo, al max 1 ogni 10s
		this.askSupportTask.pipe(throttleTime(10000)).subscribe(() => {
			this.session
				.loadRequestList()
				.then((tasks: supportTask[]) => {
					let changeRequest = tasks.filter((ts) => ts.req_type == relType.CHANGEGRADER) // filtro solo le richieste di cambio grader, potrebbero esserci altre cose

					if (changeRequest.length == 0) {
						this.modalService.open(RequestChangeGraderModalContent, {
							size: 'md',
						})
					} else {
						let header = this.translator.instant('TOAST.HEADER.WARNING')
						let body = this.translator.instant('CREDITS.CHANGE_GRADER_ERR')
						let options = new ToastOptions('info_blue')

						this.toastService.show(header, body, false, options, 'center')
					}
				})
				.catch((err) => {
					console.log(err)

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant(err)
					let options = new ToastOptions('error')

					this.toastService.show(header, body, false, options, 'center')
				})
		})
	}

	ngAfterViewInit() {
		Util.debug('(Credits) - AfterViewInit')
		this.initUserDevicesTable(this.userDevices)
	}

	initUser() {
		Util.debug('(Credits) - initUser')
		if (this.currentProfile === 'is_user') {
			this.user = this.currUser
			// console.log(this.user.user_type)
		} else if (this.currentProfile === 'is_doc') {
			this.user = this.currDoc
			// console.log(this.user)
		}

		this.initUserServices()
		// this.initUserDevices()
		this.initCreditsTab()
	}

	private initUserServices() {
		Util.debug('(Credits) - initUserServices')
		this.purchaseServices = []
		this.accountServices = []
		this.deviceServices = []
		this.purchasePackService = []

		if (this.saleInfo.services.length > 0) {
			for (let serv of this.saleInfo.services) {
				serv.service_description = this.translator.instant('CREDITS.SERVICES_DESC.' + serv.service)
			}
			// console.log(this.saleInfo)
			this.purchaseServices = this.saleInfo.services.filter((s) => s.status == serviceStatus.PURCHASED && s.service != serviceType.HG)
			this.accountServices = this.saleInfo.services.filter((s) => s.service == serviceType.HG)
			this.deviceServices = this.saleInfo.services.filter((s) => s.status != serviceStatus.PURCHASED && s.service != serviceType.HG)
			// this.accountServices = this.saleInfo.services.filter((s) => s.status == serviceStatus.ACTIVE && s.service == serviceType.HG) //consideravo se active ma meglio se anche expired
			// this.deviceServices = this.saleInfo.services.filter((s) => s.status == serviceStatus.ACTIVE && s.service != serviceType.HG)	//consideravo se active ma meglio se anche expired
		}

		this.initUserDevices().then(() => {
			if (this.purchaseServices.length > 0) {
				//metto insieme in pack i servizi che hanno lo stesso pack
				let packagesIds = [...new Set(this.purchaseServices.map((item) => item.pack_id))]

				for (let id of packagesIds) {
					let pack: purchasePackage = {
						package_name: '',
						description: '',
						pack_id: id,
						services: [],
						buy_date: null,
						availableDevices: [],
						assignEnabled: false,
						loading: false,
					}

					pack.services = this.purchaseServices.filter((s) => s.pack_id == id)
					pack.package_name = pack.services[0].pack
					pack.description = pack.services[0].description
					pack.buy_date = pack.services[0].buy_date

					if (this.purchaseServices.find((el) => el.pack_id == id).device_model && this.purchaseServices.find((el) => el.pack_id == id).device_model.length > 0) {
						// let devices = this.user.userDevices.filter((s) => s.model == pack.services[0].device_model)
						pack.availableDevices = this.userDevices.filter((d) => pack.services[0].device_model.includes(d.model) && d.canAcceptServices)
					} else {
						pack.availableDevices = this.userDevices.filter((d) => d.canAcceptServices)
					}
					// console.log(pack)
					this.purchasePackService.push(pack)
				}
				// console.log(this.purchasePackService)
			}
		})
	}

	private initUserDevices(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			Util.debug('(Credits) - initUserDevices')
			this.userDevices = []
			if (this.user.userDevices.length > 0) {
				for (let device of this.user.userDevices) {
					let userDevice: userDeviceSubscription = new userDeviceSubscription(device)

					let deviceServices = this.deviceServices.filter((s) => s.device_sn == device.sn && s.device_model.includes(device.model))

					userDevice.services = deviceServices

					userDevice.services_names = [...new Set(userDevice.services.map((item) => item.service))]

					userDevice = userDeviceSubscription.getServiceDevicesStatus(userDevice)

					userDevice = userDeviceSubscription.canDeviceAcceptPairServices(userDevice)

					this.userDevices.push(userDevice)
				}
				resolve(true)
			} else {
				resolve(true)
			}
		})

		return promise
	}

	private initUserDevicesTable(list: userDeviceSubscription[]) {
		console.log(list)
		Util.debug('(Credits) - initUserDevicesTable')
		this.userDevicesTable = new MatTableDataSource<userDeviceSubscription>(list)
		this.userDevicesTable.sort = this.sort
		if (this.userDevices.length > 4) {
			this.userDevicesTable.paginator = this.paginator
		}
	}

	public assignService(pack: purchasePackage) {
		Util.debug('(Credits) - assignService')

		for (let service of pack.services) {
			let pairDev: pairingDevices = {
				id: service.id,
				service: service.service,
				device_sn: this.deviceToUpdate.sn,
				device_model: this.deviceToUpdate.model,
			}
			this.pairingDevice.push(pairDev)
		}
	}

	public checkForAvailableAgreement(pack: purchasePackage) {
		let services: serviceType | string[] = [...new Set(pack.services.map((item) => item.service))]
		let agrStatus = this.session.getUserAgreementStatus(AgrStatus.AVAILABLE)

		let servicesWithAgreementToAccept: AgrType[] = []

		for (let agr of agrStatus.agreements) {
			//forzatura per serviceType|string per usare includes
			let serviceType: serviceType | string
			if (agr.service != '') {
				serviceType = agr.service
				if (services.includes(serviceType)) {
					servicesWithAgreementToAccept.push(agr.doc_type)
				}
			}
		}

		// console.log(services, agrStatus, servicesWithAgreementToAccept)
		// se ne ho da accettare che riguardano i servizi che sto facendo il pairing apro il modale, altrimenti skip
		if (servicesWithAgreementToAccept.length > 0) {
			this.currentModal = this.modalService.open(AgreementModal, { size: 'xl', keyboard: false, backdrop: 'static' })
			this.currentModal.componentInstance.fromPairing = true
			this.currentModal.componentInstance.pairingServicesType = servicesWithAgreementToAccept

			this.currentModal.result
				.then(() => {
					Util.debug('Agreements accepted')
					this.pairingService(pack)
				})
				.catch(() => {
					Util.debug('Agreements not accepted')
					pack.assignEnabled = false
					this.deviceToUpdate = null
					this.pairingDevice = []
				})
		} else {
			this.pairingService(pack)
		}
	}

	private pairingService(pack: purchasePackage) {
		let msg = this.translator.instant('CREDITS.PAIRING_QUEST', { device: this.pairingDevice[0].device_model, device_sn: this.pairingDevice[0].device_sn })

		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = msg

		this.currentModal.result
			.then((resp) => {
				pack.loading = true

				this.session
					.pairingServiceToDevice(this.pairingDevice)
					.then((res) => {
						// console.log(res)
						let header = this.translator.instant('TOAST.HEADER.SUCCESS')
						let body = this.translator.instant('CREDITS.ASSIGN_CONFIMED')
						let options = new ToastOptions('success')

						this.toastService.show(header, body, false, options, 'bottom-right')

						this.pairingDevice = []
						this.deviceToUpdate = null
					})
					.catch((err) => {
						pack.loading = false

						this.pairingDevice = []
						this.deviceToUpdate = null

						let header = this.translator.instant('TOAST.HEADER.ERROR')
						let body = this.translator.instant(err.error.error)
						let options = new ToastOptions('error')

						this.toastService.show(header, body, false, options, 'center')
					})
			})
			.catch(() => {
				pack.assignEnabled = false
				this.deviceToUpdate = null
				this.pairingDevice = []
			})
	}

	getDayDiff(startDate: Date, endDate: Date): number {
		// funzione per trovare il numero di giorni alla scadenza del profilo
		if (Number(startDate) > Number(endDate)) {
			console.log('Acount expired')
			return -1
		}
		const msInDay = 24 * 60 * 60 * 1000

		return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay)
	}

	private initCreditsTab() {
		Util.debug('(Credits) - initCreditsTab')
		// console.log(this.user)
		// console.log(this.saleInfo)
		if (this.saleInfo) {
			this.usedSpace = this.convertSpace(this.saleInfo.used_bytes, 2)

			this.credits = this.saleInfo.available_credits.toString()

			this.viewReady = true

			if (this.saleInfo.sale_plan_id > 0) {
				// this.saleInfo = plan;
				// console.log(this.saleInfo)

				if (this.saleInfo.salePlan.isExpired()) {
					this.gracePeriodExpiryDate = this.session.formatDate(Util.addDays(this.saleInfo.planExpiryDate, 30))
				}

				if (this.saleInfo.sale_plan_id > 1) {
					// se 2 basic se 3 adavanced
					this.expireDate = this.session.formatDate(this.saleInfo.planExpiryDate) // copio la data trasformandola in stringa nella proprietá expireDate
				}
			}
		}
	}

	convertSpace(bytes, decimals: number) {
		if (!+bytes) return '0 Bytes'
		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		const i = Math.floor(Math.log(bytes) / Math.log(k))
		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}

	onRequestNewGraderClick() {
		this.askSupportTask.next()
	}

	ngOnDestroy(): void {
		this.askSupportTask.unsubscribe()
		this.servicesUpdatedStatusSubscribe.unsubscribe()
	}
}
