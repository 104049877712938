import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ApexChart, ApexXAxis, ApexYAxis, ApexAxisChartSeries, ApexLegend, ApexStroke, ApexMarkers, ApexTooltip, ApexAnnotations } from 'ng-apexcharts'

type ChartOptions = {
	chart: ApexChart
	xaxis: ApexXAxis
	yaxis: ApexYAxis
	series: ApexAxisChartSeries
	legend: ApexLegend
	stroke: ApexStroke
	markers: ApexMarkers
	tooltip: ApexTooltip
	annotations: ApexAnnotations
}

@Component({
	selector: 'app-impact-eye-strain-graph',
	templateUrl: './impact-eye-strain-graph.component.html',
	styleUrls: ['./impact-eye-strain-graph.component.scss'],
})
export class ImpactEyeStrainGraphComponent implements OnInit {
	@Input() xValues: number[]
	@Input() xLabels: number[]
	@Input() stimulusYValues: number[]
	@Input() eyeStrainYValues: number[]

	chartOptions: ChartOptions

	constructor(private translator: TranslateService) {}

	ngOnInit() {
		this.chartOptions = {
			chart: {
				type: 'line',
				toolbar: { show: false, tools: { zoom: false } },
				zoom: { enabled: false },
			},
			xaxis: {
				type: 'numeric',
				// min: 90,
				// max: 451.9,
				categories: this.xValues,
				labels: {
					show: false,
					style: { fontFamily: 'Montserrat' },
				},
				axisTicks: { show: false },
				title: {
					offsetY: 15,
					text: this.translator.instant('IMPACT.EYE_STRAIN_X_AXIS'),
					style: { fontFamily: 'Montserrat' },
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				labels: {
					show: false,
				},
				tickAmount: 5,
				title: {
					text: this.translator.instant('IMPACT.EYE_STRAIN_Y_AXIS'),
					style: { fontFamily: 'Montserrat' },
				},
			},
			series: [
				{
					name: 'Stimulus',
					data: this.stimulusYValues.map((el, i) => {
						return {
							x: parseFloat(this.xValues[i].toFixed(0)),
							y: parseFloat(el.toFixed(1)),
						}
					}),
					color: '#530feb',
				},
				{
					name: 'Eye Strain',
					data: this.eyeStrainYValues.map((el, i) => {
						return {
							x: parseFloat(this.xValues[i].toFixed(0)),
							y: parseFloat(el.toFixed(1)),
						}
					}),
					color: '#e65d34',
				},
			],
			legend: {
				show: false,
			},
			stroke: {
				curve: 'monotoneCubic',
				width: 3,
			},
			markers: {
				size: [0, 2.5],
				strokeWidth: 0,
				hover: {
					size: undefined,
					sizeOffset: 0,
				},
			},
			tooltip: {
				enabled: false,
			},
			annotations: {
				xaxis: this.xLabels.map((el, i) => {
					return {
						x: this.xValues[i].toFixed(0),
						borderColor: '#000',
						opacity: 0.1,
						strokeDashArray: 0.1,
						label: {
							text: i === 0 ? '∞' : el.toFixed(0).toString(),
							position: 'bottom',
							orientation: 'horizontal',
							offsetY: 20,
							borderWidth: 0,
							style: {
								fontSize: i === 0 ? '1rem' : '',
								fontFamily: 'Montserrat',
							},
						},
					}
				}),
			},
		}
	}
}
