<!-- <div class="loading-container" [hidden]="!session.isLoadingPatients()">
	<div class="tail-spin">&nbsp;</div>
</div> -->

<div>
	<div class="patient-list-container topview z-depth-2 container-fluid">
		<!--  -->
		<div class="row">
			<div class="col-12">
				<div class="panel panel-default">
					<div class="panel-head mt-3">
						<div class="container-fluid">
							<div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col-12">
											<ol class="breadcrumb" *ngIf="session.isLevel3()">
												<li class="breadcrumb-item">
													<a> {{ doctorUsername }}</a>
												</li>
												<li class="breadcrumb-item active" aria-current="page">{{ 'PATIENT_LIST.PAGE_TITLE_DOCTOR' | translate }}</li>
											</ol>
										</div>
									</div>

									<div class="row">
										<div class="col-12 my-3">
											<button *ngIf="session.userCanCreatePatient()" type="button" class="btn btn-primary shadow" (click)="newPatientModal()">{{ 'PATIENT_LIST.ADD_NEW_PATIENT' | translate }}</button>
										</div>

										<div class="col-12">
											<p class="filter-title-section w-50" (click)="showFilters = !showFilters" [ngClass]="showFilters ? '' : 'border-bottom'">
												<label class="fw-bold uppercase">{{ 'DEVICE.FILTERS_TITLE' | translate }}</label>
												<fa-icon [icon]="faSortDown" faSortDown [ngClass]="showFilters ? 'show' : ''" aria-hidden="true" class="float-end"></fa-icon>

												<span *ngIf="filterSet" class="me-2 fw-light float-end">{{ 'DISTRIB_LIST.FILTERS.MESSAGE' | translate }}<fa-icon [icon]="faCircleExclamation" faCircleExclamation aria-hidden="true"></fa-icon></span>
											</p>
											<!-- Filters -->

											<form [formGroup]="filterForm1" class="row filter-form__container" [ngClass]="showFilters ? 'show' : ''">
												<!-- GRADING STATUS -->
												<div class="grad__cont col-xxl-2 col-sm-3 position-relative d-flex flex-row align-items-center mb-3">
													<mat-form-field appearance="outline" class="w-100">
														<!-- nella balance viene applicato questo colore automatico, qui no boh, applico io a mano -->
														<mat-label style="color: #4f0aeb">{{ session.isSpecialist() || session.isClinicAdmin() || session.isMiniC() ? ('PATIENT_LIST.FOR_LV2.GRADING_STATUS' | translate) : ('PATIENT_LIST.FOR_LV1.PENDING_STATUS' | translate) }}</mat-label>
														<mat-select name="grading" (selectionChange)="filterValue()" formControlName="grading">
															<mat-option *ngFor="let grad of gradingStatus" [value]="grad"> {{ grad }} </mat-option>
														</mat-select>
													</mat-form-field>
												</div>
												<!-- NEW REPORTS -->
												<div class="grad__cont col-xxl-2 col-sm-3 position-relative d-flex flex-row align-items-center mb-3" *ngIf="session.isLevel1() || session.isManagement()">
													<mat-form-field appearance="outline" class="w-100">
														<mat-label style="color: #4f0aeb">{{ 'PATIENT_LIST.FOR_LV2.REPORT_STATUS' | translate }}</mat-label>
														<mat-select name="reports" (selectionChange)="filterValue()" formControlName="reports">
															<mat-option *ngFor="let rep of reportState" [value]="rep"> {{ rep }} </mat-option>
														</mat-select>
													</mat-form-field>
												</div>

												<!-- GRADERS -->
												<!-- <div class="col-xxl-2 col-sm-3 grader__cont position-relative flex-row align-items-center" [hidden]="!session.isClinicAdmin()" [ngClass]="{ 'd-flex': session.isClinicAdmin() }"> -->
												<div class="col-xxl-2 col-sm-3 grader__cont position-relative flex-row align-items-center d-flex mb-3" *ngIf="session.isClinicAdmin()">
													<mat-form-field appearance="outline" [ngClass]="{ 'is-valid': myGradersControl.disabled }" class="w-100">
														<mat-label>{{ 'PATIENT_LIST.FOR_LV2.GRADERS' | translate }}</mat-label>

														<input type="text" placeholder="{{ 'PATIENT_LIST.FOR_LV2.FILTER_TYPING' | translate }}" matInput [formControl]="myGradersControl" [matAutocomplete]="auto" />

														<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="myGradersControl.disable(); filterValue()">
															<mat-option *ngFor="let option of filteredGraders | async" [value]="option"> {{ option }} </mat-option>
														</mat-autocomplete>

														<fa-icon [icon]="faCaretDown" faCaretDown class="float-end"></fa-icon>
													</mat-form-field>
													<a class="reset-button" [ngClass]="{ show: myGradersControl.disabled && !activateSpinner }" (click)="myGradersControl.enable(); myGradersControl.setValue(''); filterValue(); patientPref.graders = ''">
														<fa-icon [icon]="faXmark"></fa-icon>
													</a>
												</div>

												<!-- OPERATORS -->
												<div class="col-xxl-2 col-sm-3 operator__cont position-relative d-flex flex-row align-items-center mb-3" *ngIf="session.isSpecialist() || session.isClinicAdmin() || session.isMiniC()">
													<mat-form-field appearance="outline" [ngClass]="{ 'is-valid': myOperatorsControl.disabled }" class="w-100">
														<mat-label>{{ 'PATIENT_LIST.FOR_LV2.OPERATORS' | translate }}</mat-label>

														<input type="text" placeholder="{{ 'PATIENT_LIST.FOR_LV2.FILTER_TYPING' | translate }}" matInput [formControl]="myOperatorsControl" [matAutocomplete]="auto2" />

														<mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="myOperatorsControl.disable(); filterValue()">
															<mat-option *ngFor="let option of filteredOperators | async" [value]="option"> {{ option }} </mat-option>
														</mat-autocomplete>

														<fa-icon [icon]="faCaretDown" class="float-end" faCaretDown></fa-icon>
													</mat-form-field>
													<a class="reset-button ms-2" [ngClass]="{ show: myOperatorsControl.disabled && !activateSpinner }" (click)="myOperatorsControl.enable(); myOperatorsControl.setValue(''); filterValue(); patientPref.operators = ''"><fa-icon [icon]="faXmark" faXmark></fa-icon></a>
												</div>

												<!-- date filters -->
												<div class="col-xxl-2 col-sm-3 request-date__cont position-relative d-flex flex-row align-items-center mb-3" *ngIf="session.isSpecialist() || session.isClinicAdmin() || session.isMiniC()">
													<mat-form-field appearance="outline" (click)="picker1.open()" class="w-100">
														<mat-label>{{ 'PATIENT_LIST.FOR_LV2.REQUEST_DATE' | translate }}</mat-label>
														<input matInput [matDatepicker]="picker1" (dateChange)="filterValue()" formControlName="requestDate" />
														<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
														<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
														<mat-datepicker #picker1></mat-datepicker>
													</mat-form-field>
													<fa-icon [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm1.get('requestDate').value != null ? 'show' : ''" (click)="picker1.select(null); patientPref.req_date = null"></fa-icon>
												</div>

												<div class="col-xxl-2 col-sm-3 due-date__cont position-relative d-flex flex-row align-items-center mb-3" *ngIf="session.isSpecialist() || session.isClinicAdmin() || session.isMiniC()">
													<mat-form-field appearance="outline" (click)="picker2.open()" class="w-100">
														<mat-label>{{ 'PATIENT_LIST.FOR_LV2.DUE_DATE' | translate }}</mat-label>
														<input matInput [matDatepicker]="picker2" (dateChange)="filterValue()" formControlName="dueDate" />
														<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
														<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
														<mat-datepicker #picker2></mat-datepicker>
													</mat-form-field>
													<fa-icon [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm1.get('dueDate').value != null ? 'show' : ''" (click)="picker2.select(null); patientPref.due_date = null"></fa-icon>
												</div>

												<div class="col-xxl-2 col-sm-3 report-date__cont position-relative d-flex flex-row align-items-center mb-3" *ngIf="session.isSpecialist() || session.isClinicAdmin() || session.isMiniC()">
													<mat-form-field appearance="outline" (click)="picker3.open()" class="w-100">
														<mat-label>{{ 'PATIENT_LIST.FOR_LV2.REPORT_DATE' | translate }}</mat-label>
														<input matInput [matDatepicker]="picker3" (dateChange)="filterValue()" formControlName="reportDate" />

														<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
														<mat-datepicker #picker3></mat-datepicker>
													</mat-form-field>
													<fa-icon [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm1.get('reportDate').value != null ? 'show' : ''" (click)="picker3.select(null); patientPref.rep_date = null"></fa-icon>
												</div>

												<div class="col-xxl-2 col-sm-3 filter-text__cont position-relative d-flex flex-row align-items-center mb-3">
													<mat-form-field appearance="outline" class="w-100">
														<input matInput type="text" (keyup)="filterValue()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
													</mat-form-field>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-body position-relative" style="min-height: 35vh">
						<loader [activateSpinner]="activateSpinner"></loader>

						<div>
							<table mat-table [dataSource]="patientList" class="patient-list" matSort style="width: 100%" [ngClass]="!activateSpinner ? 'show' : ''">
								<!-- customer code -->
								<ng-container matColumnDef="code">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.CODE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ element.code }}</td>
								</ng-container>

								<!-- edit -->
								<ng-container matColumnDef="edit">
									<th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 1%"></th>
									<td mat-cell *matCellDef="let element">
										<a class="table-button" *ngIf="element.entry_type != 'DicomEntry' " ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="$event.stopPropagation(); openPatientModal(element)">
											<fa-icon *ngIf="!isLoadingPatientModal; else patientEditSpinner" [icon]="faPen" aria-hidden="true"></fa-icon>
											<ng-template #patientEditSpinner>
												<div class="spinner-border spinner-border-sm text-primary" role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</ng-template>
										</a>
									</td>
								</ng-container>

								<!-- customer name -->
								<ng-container matColumnDef="name">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ patNameTitle }}</th>
									<td mat-cell *matCellDef="let element" style="font-weight: bold" title="{{ !session.isClinicAdmin() ? ('PATIENT_LIST.HINT_VISITS' | translate) : '' }}">{{ element.name }}</td>
								</ng-container>

								<!-- internal code -->
								<ng-container matColumnDef="internal_code">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.INTERNAL_CODE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ element.internal_code }}</td>
								</ng-container>

								<!-- birth date -->
								<ng-container matColumnDef="birthYear">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>@if(session.isLevel1()){ {{ 'PATIENT_LIST.BIRTH_DATE' | translate }} }@else { {{ 'PATIENT_LIST.AGE' | translate }} }</th>
									<td mat-cell *matCellDef="let element" [ngStyle]="{ 'font-weight': today.getFullYear() <= element.birthYear ? 'bold' : '', color: today.getFullYear() <= element.birthYear ? '#ff3b2d' : '' }">@if(element.birthDate){ {{ session.formatDate(element.birthDate | stringToDate) }} }@else { {{ element.birthYear | age }} }</td>
								</ng-container>

								<!-- subscription time -->
								<ng-container matColumnDef="subscription_time">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.REGISTRATION_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ session.formatDateTime(element.subscription_time) }}</td>
								</ng-container>
								ñ
								<!-- last exam date -->
								<ng-container matColumnDef="lastExamDate">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.LAST_EXAM_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ session.formatDateTime(element.lastExamDate) }}</td>
								</ng-container>

								<!-- last device -->
								<ng-container matColumnDef="last_device">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.LAST_DEVICE' | translate }}</th>
									<td mat-cell *matCellDef="let element">
										@if(element.last_device){
										<span class="last-device-container">{{ element.last_device }}</span>
										}
									</td>
								</ng-container>

								<ng-container matColumnDef="reports">
									<th mat-header-cell *matHeaderCellDef class="text-center">
										<!-- {{ 'PATIENT_LIST.REPORTS' | translate }} -->
										<span class="position-relative">{{ 'PATIENT_LIST.REPORTS' | translate }}<span *ngIf="newReportCount > 0 && (session.isLevel1() || session.isManagement())" class="badge rounded-pill bg-success">{{ newReportCount }}</span></span>
									</th>
									<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation(); openReportsModal(element)" class="text-center">
										<div class="d-flex justify-content-center" style="overflow: visible">
											<!-- specialist solo icona documenti -->
											@if((session.isClinicAdmin() || session.isSpecialist()) ){ @if(element.tot_reports_done && element.tot_reports_done > 0){
											<span class="ms-1 me-3 position-relative" ngbTooltip="{{ 'PATIENT_LIST.TOOLTIPS.OPEN_REPORTS' | translate }}">
												<fa-icon class="fs-5" [icon]="faFileLines" faFileLines></fa-icon>
											</span>
											} } @else {
											<!-- optician e altri numero report, icona doc e icona verde se nuovi doc -->
											<span class="px-2" style="min-width: 2rem; display: inline-block" *ngIf="element.totalReports>0">{{ element.totalReports }} </span>
											@if(element.totalReports > 0){
											<span class="ms-1 position-relative" [ngbTooltip]="element.tot_new_report > 0 || element.tot_new_aireports > 0 ? ('PATIENT_LIST.TOOLTIPS.OPEN_REPORTS_WITH_NEW' | translate : { counter: element.tot_new_report + element.tot_new_aireports }) : ('PATIENT_LIST.TOOLTIPS.OPEN_REPORTS' | translate)">
												<fa-icon class="fs-5" [icon]="faFileLines" faFileLines></fa-icon>
												@if(element.tot_new_report > 0 || element.tot_new_aireports > 0){
												<fa-icon [icon]="faCircleCheck" faCircleCheck></fa-icon>
												}
											</span>
											} }
										</div>
									</td>
								</ng-container>

								<ng-container matColumnDef="pending">
									<th mat-header-cell *matHeaderCellDef class="text-center">
										<span class="position-relative"
											>{{ session.isSpecialist() || session.isClinicAdmin() ? ('PATIENT_LIST.HG_STATUS' | translate) : ('PATIENT_LIST.HG_STATUS_SPECIALIST' | translate) }}
											<span *ngIf="pendingCount > 0 && (session.isLevel1() || session.isManagement())" class="badge rounded-pill bg-secondary">{{ pendingCount }}</span>
										</span>
									</th>
									<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
										<div class="d-flex justify-content-center">
											@if(element.exams_to_review > 0){
											<fa-icon class="fs-6" [icon]="faClock" faClock [ngbTooltip]="session.isSpecialist() || session.isClinicAdmin() ? ('EXAMS.INFO_NEW_EXAMS' | translate) : ('PATIENT_LIST.TOOLTIPS.PENDING_REPORTS' | translate)"></fa-icon>
											} @else if(element.exams_to_review == 0 && (session.isSpecialist() || session.isClinicAdmin())){
											<fa-icon [icon]="faCircleCheck" faCircleCheck ngbTooltip="{{ 'EXAMS.INFO_ALL_EXAMS_REV' | translate }}"></fa-icon>
											}
										</div>
									</td>
								</ng-container>

								<ng-container matColumnDef="impact">
									<th mat-header-cell *matHeaderCellDef class="text-center">
										<!-- {{ 'PATIENT_LIST.FOR_LV1.IMPACT' | translate }} -->
									</th>
									<td mat-cell *matCellDef="let element" (click)="$event.stopPropagation(); showImpact(element)" class="text-center">
										@if(element.impact_enabled){
										<fa-icon class="fs-6" [icon]="faFolderOpen" faFolderOpen ngbTooltip="{{ 'PATIENT_LIST.TOOLTIPS.IMPACT' | translate }}"></fa-icon>
										}
									</td>
								</ng-container>

								<ng-container matColumnDef="actions">
									<th mat-header-cell *matHeaderCellDef class="text-end" style="width: 10%">
										<!-- Aggiounta questa larghezza per avere la colonna della larghezza del filtro e recuperare spazio per il tablet -->
										<!-- <mat-form-field appearance="outline">
											<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
										</mat-form-field> -->

										<div class="float-end">
											<button type="button" class="btn btn-primary btn-sm reload shadow" (click)="reloadList()" [disabled]="!reloadEnable">
												{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
											</button>
										</div>
									</th>
									<td mat-cell *matCellDef="let element">
										<div class="d-flex justify-content-end">
											<!-- <a class="table-button" *ngIf="session.userCanEditPatient() && !element.isReadOnly()" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="$event.stopPropagation(); openPatientModal(element)">
											<fa-icon *ngIf="!isLoadingPatientModal; else patientEditSpinner" [icon]="faEdit" aria-hidden="true"></fa-icon>
											<ng-template #patientEditSpinner>
												<div class="spinner-border spinner-border-sm text-primary" role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</ng-template>
										</a> -->

											<a class="table-button" *ngIf="canSeeAnamnesi" (click)="$event.stopPropagation(); openAnamnesisModal(element)" ngbTooltip="Anamnesis">
												<fa-icon [icon]="faClipboard" faClipboard aria-hidden="true"> </fa-icon>
											</a>

											<a class="table-button" *ngIf="session.userCanDeletePatient(element)" (click)="$event.stopPropagation(); openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}">
												<fa-icon [icon]="faTrashAlt" aria-hidden="true"> </fa-icon>
											</a>

											<a *ngIf="today.getFullYear() <= element.birthYear" class="me-2 wrong-age-icon" ngbTooltip="{{ 'PATIENT_LIST.WRONG_AGE' | translate }}"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon></a>

											<span *ngIf="element.isReadOnly() && element.dicom_id != ''" class="opacity-50" style="font-size: 12px">Dicom id: {{ element.dicom_id }}</span>
										</div>
									</td>
								</ng-container>

								<ng-container matColumnDef="request_date">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.FOR_LV2.REQUEST_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ session.formatDateTime(element.request_date) }}</td>
								</ng-container>

								<ng-container matColumnDef="due_date">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.FOR_LV2.DUE_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ session.formatDate(element.due_date) }}</td>
								</ng-container>

								<ng-container matColumnDef="report_date">
									<th mat-header-cell *matHeaderCellDef>{{ 'PATIENT_LIST.FOR_LV2.REPORT_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ session.formatDateTime(element.lastReportDate) }}</td>
								</ng-container>

								<ng-container matColumnDef="grader">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.FOR_LV2.GRADER' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ element.grader }}</td>
								</ng-container>

								<ng-container matColumnDef="created_by">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ session.isSpecialist() || session.isClinicAdmin() ? ('PATIENT_LIST.FOR_LV2.OPERATOR' | translate) : ('PATIENT.CREATED_BY' | translate) }}</th>
									<td mat-cell *matCellDef="let element">{{ getStrCreator(element.operator) }}</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="showVisits(row)" [ngClass]="[today.getFullYear() < row.birthYear ? 'wrong-age' : '', isMiniC ? 'isminic' : '', row.assigned_to == currUserId || row.exams_to_review == 0 ? '' : 'not_assigned']"></tr>
							</table>

							<mat-paginator [pageSizeOptions]="[10, 15, 20, 25, 50]" showFirstLastButtons aria-label="Select page of periodic elements" [ngClass]="!activateSpinner ? 'show mt-3' : ''"> </mat-paginator>

							<div class="noresults-banner" [ngClass]="{ show: (patList.length == 0 && !session.isLoadingPatients()) || isFilterTextEmpty }">
								<div class="container">
									<div class="row text-center noresults-banner__cont">
										<div class="col-5"></div>
										<div class="col-2 alert alert-secondary" role="alert" *ngIf="!filterSet">{{ 'PATIENT_LIST.FOR_LV2.NO_PATIENTS' | translate }}</div>
										<div class="col-2 alert alert-secondary" role="alert" *ngIf="filterSet">{{ 'PATIENT_LIST.FOR_LV2.NO_PAT_FILTER' | translate }}</div>
										<div class="col-5"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
