import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { NgbModal, NgbActiveModal, NgbNavChangeEvent, NgbNav } from '@ng-bootstrap/ng-bootstrap'

import { SessionService } from '../../service/session.service'

import { Util } from '../../models/util.model'
import { Anamnesis, PatientReply, VA } from 'src/app/models/anamnesis.model'
import { Patient } from 'src/app/models/patient.model'
import { AnamnesisService } from 'src/app/service/anamnesis.service'

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'

export enum AnamnesisMode {
	NEW = 'new',
	EDIT = 'edit',
	VIEW = 'view',
}

export enum AnamnesisType {
	IMPACT = 'impact',
	MEDICAL = 'medical',
	VA = 'va',
}

export type anamnesisTabs = {
	[key in AnamnesisType]: {
		id: AnamnesisType
		label: string
		active: boolean
		answered: boolean
		creationDate: Date
		totalSteps: number
		currentStep: number
		va: VA
		anamnesi: Anamnesis[]
	} //active non usato per adesso
}

@Component({
	selector: 'anamnesis-modal',
	templateUrl: './anamnesis.modal.html',
	styleUrls: ['./anamnesis.modal.scss'],
})
export class AnamnesisModal implements OnInit {
	@Input() currPatient: Patient
	@Input() currentAction: AnamnesisMode // new, edit, view
	@Input() impactEnable: boolean // se impact abilitato
	@Input() focusTab: AnamnesisType // se ricevuta in input mette il focus su quel tab, usato durante invio hg

	@ViewChild('nav') tabset: NgbNav

	impactAnamnesis: any // TO DO

	loading: boolean

	anamnesisTabs: anamnesisTabs
	activeTab: string

	firstkey: string
	lastKey: string

	isInFirstStep: boolean
	isInLastStep: boolean

	////

	vaComplete: boolean

	//icons
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		public anamnesisService: AnamnesisService,
		public translator: TranslateService,
		public modalService: NgbModal
	) {
		Util.debug('AnamnesiModal - constructor')

		this.currentAction = AnamnesisMode.NEW

		this.loading = true

		this.anamnesisTabs = {
			[AnamnesisType.IMPACT]: {
				id: AnamnesisType.IMPACT,
				label: this.translator.instant('ANAMNESIS_MODAL.IMPACT_ANAM'),
				active: true,
				answered: false,
				creationDate: null,
				totalSteps: 0,
				currentStep: 1,
				va: null,
				anamnesi: null,
			},
			[AnamnesisType.MEDICAL]: {
				id: AnamnesisType.MEDICAL,
				label: this.translator.instant('ANAMNESIS_MODAL.MEDICAL_ANAM'),
				active: true,
				answered: false,
				creationDate: null,
				totalSteps: 0,
				currentStep: 1,
				va: null,
				anamnesi: null,
			},
			[AnamnesisType.VA]: {
				id: AnamnesisType.VA,
				label: this.translator.instant('ANAMNESIS_MODAL.VA_ANAM'),
				active: true,
				answered: false,
				creationDate: null,
				totalSteps: 1,
				currentStep: 1,
				va: new VA(),
				anamnesi: null,
			},
		}

		this.activeTab = AnamnesisType.IMPACT

		this.isInFirstStep = true
		this.isInLastStep = false
	}
	ngOnInit(): void {
		Util.debug('AnamnesiModal - ngOninit')

		if (!this.impactEnable) {
			delete this.anamnesisTabs[AnamnesisType.IMPACT]
			this.activeTab = AnamnesisType.MEDICAL
		}

		if (this.focusTab) {
			this.activeTab = this.focusTab
		}

		// mi ricavo la prima e ultima chiave delle tab per i pulsanti
		this.firstkey = Object.keys(this.anamnesisTabs)[0]
		this.lastKey = Object.keys(this.anamnesisTabs)[Object.keys(this.anamnesisTabs).length - 1]

		this.initializeAnamnesis()
			.then((res) => {
				// console.log(res)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				if (this.currentAction != AnamnesisMode.NEW) {
					this.getPatientAnamnesis().finally(() => {
						// console.log(res)
						this.loading = false
						this.checkFirstStep()
						this.checkLastStep()
					})
				} else {
					this.loading = false
					this.checkFirstStep()
					this.checkLastStep()
				}
			})
	}

	private initializeAnamnesis(): Promise<boolean> {
		Util.debug('(AnamnesiModal) - initializeAnamnesis ')
		const promise = new Promise<boolean>((resolve, reject) => {
			let promiseArray: Promise<any>[] = [this.anamnesisService.getAnamnesisQuestions('medical')]

			// add impact anamnesis based if is enabled
			if (this.impactEnable) {
				promiseArray.push(this.anamnesisService.getAnamnesisQuestions('impact'))
			}
			Promise.all(promiseArray)
				.then((res) => {
					if (this.impactEnable) {
						this.anamnesisTabs[AnamnesisType.IMPACT].anamnesi = res[1]
					}
					this.anamnesisTabs[AnamnesisType.MEDICAL].anamnesi = res[0]
					resolve(true)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	private getPatientAnamnesis(): Promise<boolean> {
		const promise = new Promise<any>((resolve, reject) => {
			let patId = this.currPatient.id
			let promiseArray: Promise<any>[] = [this.anamnesisService.getPatientAnamnesisAnswers(patId, 'medical'), this.anamnesisService.getPatientVA(patId)]

			// add impact anamnesis based if is enabled
			if (this.impactEnable) {
				promiseArray.push(this.anamnesisService.getPatientAnamnesisAnswers(patId, 'impact'))
			}

			Promise.all(promiseArray)
				.then((res) => {
					// console.log(res)
					if (res[0] && res[0].length > 0) {
						this.anamnesisTabs[AnamnesisType.MEDICAL].anamnesi = res[0]
						this.anamnesisTabs[AnamnesisType.MEDICAL].creationDate = this.anamnesisTabs[AnamnesisType.MEDICAL].anamnesi[0].answered
					}

					this.anamnesisTabs[AnamnesisType.VA].va = res[1]
					this.anamnesisTabs[AnamnesisType.VA].creationDate = this.anamnesisTabs[AnamnesisType.VA].va.creation_date

					if (res[2] && res[2].length > 0) {
						this.anamnesisTabs[AnamnesisType.IMPACT].anamnesi = res[2]
						this.anamnesisTabs[AnamnesisType.IMPACT].creationDate = this.anamnesisTabs[AnamnesisType.IMPACT].anamnesi[0].answered
					}

					resolve(true)
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		})
		return promise
	}

	public onNavChange(event: NgbNavChangeEvent) {
		// console.log(event)
		this.activeTab = event.nextId

		this.checkFirstStep()
		this.checkLastStep()
	}

	private checkFirstStep() {
		if (this.firstkey == this.activeTab && this.anamnesisTabs[this.firstkey].currentStep == 1) {
			this.isInFirstStep = true
		} else {
			this.isInFirstStep = false
		}
	}

	private checkLastStep() {
		if (this.anamnesisTabs[this.activeTab].currentStep == this.anamnesisTabs[this.activeTab].totalSteps) {
			this.isInLastStep = true
		} else {
			this.isInLastStep = false
		}
	}

	onStepsFetched(type: string, event: { totalSteps: number }) {
		this.anamnesisTabs[type].totalSteps = event.totalSteps
		// this.totalSteps = event.totalSteps
	}

	goToPreviousStep() {
		if (this.isInFirstStep) {
			return
		}

		if (this.anamnesisTabs[this.activeTab].totalSteps > 1 && this.anamnesisTabs[this.activeTab].currentStep > 1) {
			this.anamnesisTabs[this.activeTab].currentStep--
		} else {
			const keys = Object.keys(this.anamnesisTabs)
			const activeTabIndex = keys.indexOf(this.activeTab)
			const previousTabIndex = activeTabIndex - 1

			if (previousTabIndex >= 0) {
				this.tabset.select(this.anamnesisTabs[keys[previousTabIndex]].id)
			}
		}

		this.checkFirstStep()
		this.checkLastStep()
	}

	goToNextStep() {
		if (this.anamnesisTabs[this.activeTab].totalSteps > 1 && this.anamnesisTabs[this.activeTab].currentStep < this.anamnesisTabs[this.activeTab].totalSteps) {
			this.anamnesisTabs[this.activeTab].currentStep++
		}

		this.checkFirstStep()
		this.checkLastStep()
	}

	// checkMandatoryVA(va: VA) {
	// 	// console.log(va)
	// 	this.vaComplete = va.va_right !== '' && va.va_left !== '' && va.va_bino !== ''
	// }

	buildAnswers(type: 'medical' | 'impact') {
		let amnlist = this.anamnesisTabs[type === 'medical' ? AnamnesisType.MEDICAL : AnamnesisType.IMPACT].anamnesi as Anamnesis[]
		const patReply: PatientReply[] = []
		// console.log(amnlist)

		for (let i = 0; i < amnlist.length; i++) {
			const element = amnlist[i]

			const answ = amnlist[i].answers

			let obj: PatientReply = { question_id: element.question_id, reply_ids: answ.filter((el) => el.checked).map((el) => el.reply_id) }

			patReply.push(obj)
		}

		return patReply
	}

	dismiss() {
		Util.debug('(AnamnesiModal) dismiss modal, no anamnesi saved')
		this.activeModal.close(false)
	}

	saveAnamnesis() {
		if (this.currentAction == AnamnesisMode.VIEW) {
			Util.debug('(AnamnesiModal) save anamnesis not allowed, mode VIEW')
			return
		}
		Util.debug('(AnamnesiModal) save anamnesis')
		this.loading = true

		switch (this.activeTab) {
			case AnamnesisType.IMPACT: {
				let answlist = this.buildAnswers(AnamnesisType.IMPACT)

				this.anamnesisService
					.savePatientAnamnesis(this.currPatient.id, answlist, 'impact')
					.then((resp) => {
						Util.debug('Save patAnamnesis impact')
						this.anamnesisTabs[AnamnesisType.IMPACT].creationDate = new Date()
						this.anamnesisTabs[AnamnesisType.IMPACT].answered = false
						this.tabset.select(AnamnesisType.MEDICAL)
						this.loading = false
					})
					.catch((err) => {
						this.loading = false
						console.log(err)
					})
					.finally(() => {
						this.checkFirstStep()
						this.checkLastStep()
					})
				break
			}

			case AnamnesisType.MEDICAL: {
				let answlist = this.buildAnswers(AnamnesisType.MEDICAL)
				// console.log(answlist)

				this.anamnesisService
					.savePatientAnamnesis(this.currPatient.id, answlist, 'medical')
					.then((resp) => {
						Util.debug('Save patAnamnesis medical')
						this.anamnesisTabs[AnamnesisType.MEDICAL].creationDate = new Date()
						this.anamnesisTabs[AnamnesisType.MEDICAL].answered = false
						this.tabset.select(AnamnesisType.VA)
						this.loading = false
					})
					.catch((err) => {
						this.loading = false
						console.log(err)
					})
					.finally(() => {
						this.checkFirstStep()
						this.checkLastStep()
					})
				break
			}

			case AnamnesisType.VA: {
				// console.log(this.anamnesisTabs[AnamnesisType.VA])

				this.anamnesisService
					.savePatientAnamnesisVA(this.currPatient.id, this.anamnesisTabs[AnamnesisType.VA].va as VA)
					.then((resp) => {
						Util.debug('Save patAnamnesis va')
						this.anamnesisTabs[AnamnesisType.VA].creationDate = new Date()
						this.anamnesisTabs[AnamnesisType.VA].answered = false
						this.activeModal.close(true)
					})
					.catch((err) => {
						this.loading = false
						console.log(err)
					})
					.finally(() => {
						this.checkFirstStep()
						this.checkLastStep()
					})
				break
			}
		}
	}
}
