import { RippleState } from '@angular/material/core'
import { NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'

import { Config } from '../../config'
import { CsvLine } from './csvLine.model'

export class Util {
	static getFullName(a, b) {
		if (!a) {
			return b
		}
		if (!b) {
			return a
		}

		return a + ' ' + b // nome e cognome

		// 27.09.2022 prima il cognome
		//return b + ' ' + a;

		/*
    if (a == b && a == '**QUICK_ENTRY**') // 15.03.2017
      return a;
    else 
    return a + ' ' + b;
    */
	}

	// 28.06.2018 richiamata da exam per calcolo CDR
	static roundTo(value, decimalpositions) {
		var i = value * Math.pow(10, decimalpositions)
		i = Math.round(i)
		return i / Math.pow(10, decimalpositions)
	}

	// 08.04.2020 --ls
	static isEmptyObj(obj) {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) return false
		}
		return true
	}

	// 15.06.2020 test ? [ls]
	static bagSize(bag) {
		var size = 0
		var key
		for (key in bag) {
			if (bag.hasOwnProperty(key)) size++
		}
		return size
	}

	// 31.05.2019 usata per birthDate e date nel report medical
	// stesse regole impostate sulla App (vd mail Alessio del 10.05.2019 )
	/* come su App:
    giorno-mese-anno: tedesco, spagnolo, francese, italiano, olandese, polacco, ucraino
    mese-giorno-anno: inglese
    anno-mese-giorno: ungherese, cinese
  */
	/*
	static DISAB_getDateFormatByLang(lang: string) {
		var dtFormat = ''

		if (lang == 'en') {
			dtFormat = 'MM/dd/yyyy';
		} else if (lang == 'hu' || lang == 'zh') {
			// ungherese, cinese
			dtFormat = 'yyyy/MM/dd';
		} else {
			dtFormat = 'dd/MM/yyyy'; // att: MM = mese, mm = minuti
		}
		return dtFormat
	}
  */

	// 30.07.2020 mette insieme se uguali, altrimenti entrambe
	static mergeStringDates(pachyDt, tonoDt) {
		var ret = ''
		var sum = '' + pachyDt + tonoDt
		if (sum.length == 0 || sum.length < 11) {
			// ce n'e' solo una o nessuna, ok questa
			ret = sum
		} else {
			// ci sono entrambe
			if (pachyDt.indexOf(tonoDt) == 0) {
				// .equals ?
				ret = pachyDt
			} else {
				ret = pachyDt + ' / ' + tonoDt
			}
		}
		return ret
	}

	// 25.11.2021
	static getPastDays(totBack): Date {
		var oggi = new Date()
		var oldDay = new Date(oggi.setDate(oggi.getDate() - totBack))
		return oldDay
	}

	// 30.11.2021 converte data in formato YYYY-MM-DD in oggetto per datePicker, {year: 2005, month: 3, day: 10}
	static stringToNgb(myDOB: string): NgbDateStruct {
		let myDt: NgbDateStruct
		const dateParts = myDOB.trim().split('-')
		if (dateParts.length === 3) {
			myDt = {
				year: parseInt(dateParts[0]),
				month: parseInt(dateParts[1]),
				day: parseInt(dateParts[2]),
			}
		}
		return myDt
	}

	static ngbToString(birthDate: NgbDateStruct) {
		// 25.02.2022 fix, forzato completamento con zeri
		let month = birthDate.month > 9 ? '' + birthDate.month : '0' + birthDate.month
		let day = birthDate.day > 9 ? '' + birthDate.day : '0' + birthDate.day

		let dob = birthDate.year + '-' + month + '-' + day
		return dob
	}

	// 05.07.2022  - 09.08.2022
	static dateToNgb(myDate: Date): NgbDateStruct {
		let myDt: NgbDateStruct
		if (myDate != null) {
			myDt = {
				year: myDate.getFullYear(),
				month: myDate.getMonth() + 1,
				day: myDate.getDate(),
			}
		}
		return myDt
	}

	// 01.08.2022 per vistel AI
	// if offset equals -60 then the time zone offset is UTC+01
	// the difference, in minutes, between UTC and local time.
	// Note that this means that the offset is positive if the local timezone is behind UTC
	// and negative if it is ahead.
	static getSzTimezoneOffset() {
		let offset = new Date().getTimezoneOffset()
		let hours = -(offset / 60)

		//let hours =  -(+600/60);  // australia, NY -4

		let ret = hours > 0 ? '+' : '-'

		let absVal = Math.abs(hours)

		if (absVal < 10) {
			ret += '0' + absVal
		} else {
			ret += '' + absVal
		}

		if (absVal < 100) {
			ret += '00'
		}

		console.log('Timezone offset: ' + offset + ' hours: ' + hours + ' szVal: ' + ret)

		//let ret = "+0200";  // ok Roma
		return ret
	}

	/*
    static padNumber(value: number) {
        if (isNumber(value)) {
            return ('0' + value).slice(-2);
        } else {
            return "";
        }
    }

    static isNumber(value: any): boolean {
        return !isNaN(toInteger(value));
    }

    */

	// 02.09.2020 usato per decrittare xml al volo [ls]
	static decryptObject(rowObj, cryptoUtils, goodKey) {
		var result = null // new PachyExam(draft);

		if (!rowObj) {
			return Promise.reject(result)
		}

		// campi crittati

		if (!goodKey) {
			console.log('decryptObject - manca keyPhoto')
			return Promise.reject(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.myObj = rowObj

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('decryptObject - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// misto dati/immagini, entrambi i formati
			return cryptoUtils
				.decryptAll(goodKey, bag)
				.then((myBag) => {
					if (myBag != null) {
						result = myBag.myObj

						return result
					} else {
						console.log('decryptObject - null bag ris')
					}
				})
				.catch((err) => {
					console.log('decryptObject ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}

	// 17.02.2021 (come su nexy)
	// min 8 characters, upper, lower case, symbol
	static validatePwd(value) {
		var validPwd = false
		var regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*_0-9])(.{8,})$')
		if (regex.test(value)) {
			validPwd = true
		}
		return validPwd
	}

	// 18.05.2021
	static validateEmail(mail) {
		// ko , accetta
		// var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
		//var regex = new RegExp( "^[A-z0-9\.\+_-]+@[A-z0-9\._-]+\.[A-z]{2,6}$" );
		// 06.04.2021
		var regex = /^[A-z0-9\.\+_-]+@[A-z0-9\._-]+\.[A-z]{2,6}$/
		if (regex.test(mail)) {
			return true
		} else {
			return false
		}
	}

	// converte available space da bytes
	static formatBytes(myBytes, decimalpositions) {
		let a = myBytes
		let b = decimalpositions

		if (null == a)
			// 05.11.2018 fix --ls
			return '-'

		if (0 == a) return '0 Bytes'

		let c = 1024,
			d = b || 2
		let e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

		let m = Math.log(c)
		let k = Math.log(a)

		let f = Math.floor(k / m) // ordine di grandezza, 1 -> KB, 2 -> MB

		//console.log("(formatB) bytes: "+a+" k: "+k+" m: "+m+" f: "+f);
		return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
	}

	// 26.09.2022 converte available space da bytes a MB, fisso senza units
	static formatBytesToMB(myBytes) {
		if (null == myBytes) return '-'
		if (0 == myBytes) return '0'

		let c = 1024
		let d = 1 || 2

		//let e = ['Bytes', 'KB', 'MB'];
		let f = 2 // voglio fisso in MB
		let ris = '' + parseFloat((myBytes / Math.pow(c, f)).toFixed(d)) //  + e[f];

		// 17.10.2022 forzo replacement del separatore - richiesta Pamela
		let ris2 = Util.replaceAll(ris, '.', ',')

		return ris2
	}

	// 18.05.2021 trovare modo ufficiale js ?
	static replaceAll(input, cosa, con) {
		// ne sostituisce solo uno...
		var myOut = input.replace(cosa, con)
		while (myOut.indexOf(cosa) > 0) {
			myOut = myOut.replace(cosa, con)
		}
		return myOut
	}

	// 16.02.2022
	static equalsIgnoreCase(a, b) {
		//let ret = (a.toUpperCase() == b.toUpperCase());
		// TODO, valutare ? gestisce anche caratteri stranieri
		let ret = a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
		return ret
	}

	// 19.10.2021
	static formatDiagnosisGroup(grp) {
		var ret = '-'
		// 09.05.2022 generalizzo
		if (grp < Config.DIAGN_GROUPS.length) {
			ret = Config.DIAGN_GROUPS[grp]
		} else {
			ret = '' + grp
		}
		return ret
	}

	// 26.08.2022 dal web
	// https://stackoverflow.com/questions/11257062/converting-json-object-to-csv-format-in-javascript
	// input arr e' un array di stringhe
	static convertToCSV(arr) {
		if (!arr) return null

		// ricava la riga di titolo dal nome dei campi
		const array = [Object.keys(arr[0])].concat(arr)

		return array
			.map((it) => {
				return Object.values(it).toString()
			})
			.join('\r\n')
	}

	// 11.01.2023
	// input myJsonArray e' un array di oggetti json
	static convertJsonToCsv(myJsonArray: any[], csvSep: string) {
		let fields = Object.keys(myJsonArray[0])

		let replacer = function (key, value) {
			return value === null ? '' : value
		}

		let csv = myJsonArray.map(function (row) {
			return fields
				.map(function (fieldName) {
					return JSON.stringify(row[fieldName], replacer)
				})
				.join(csvSep)
		})

		csv.unshift(fields.join(csvSep)) // add header column

		return csv
	}

	// 14.01.2022 richiamata in chiusura dei modals
	static getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop'
		} else {
			return 'with: ' + reason
		}
	}

	static downloadCSV(listToExport: any[], filename: string) {
		if (listToExport.length > 0) {
			let tmp = this.convertToCSV(listToExport)
			let strBuffer = this.replaceAll(tmp, ',', CsvLine.SEP)

			let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })
			this.mySaveAs(filename, blob)
		}
	}

	// per leggere la png del logo o altri files immagine
	static readFileAsDataUrl(file) {
		return new Promise((resolve, reject) => {
			const fr = new FileReader()
			fr.onerror = reject
			//fr.onload = function() {
			fr.onloadend = function () {
				resolve(fr.result)
				console.log('(myReadFile) done! ')
			}
			return fr.readAsDataURL(file) // NB: immagini, converte in base64
		})
	}

	// 09.05.2022 centralizzata lettura e upload di files di testo (anche xml)
	static myReadFileAsText(file: Blob): Promise<any> {
		return new Promise((resolve, reject) => {
			const fr = new FileReader()
			fr.onerror = reject

			fr.onloadend = function () {
				resolve(fr.result)
			}
			return fr.readAsText(file)
		})
	}

	// 26.07.2022 spostato da statistics
	public static mySaveAs(filename, myBlob) {
		// non funziona con IE
		// https://stackoverflow.com/questions/49762908/exporting-data-to-csv-file-in-angular-typescript

		//if(navigator.msSaveOrOpenBlob) {  // IE
		//  navigator.msSaveBlob(myBlob, filename);
		//} else {

		let fileURL = URL.createObjectURL(myBlob)
		//console.log("(mySaveAs) blob type: "+myBlob.type);

		let element = document.createElement('a')
		element.setAttribute('href', fileURL) // ok  05.07.2022
		element.setAttribute('download', filename)
		element.style.display = 'none'
		document.body.appendChild(element) //Append the element to work in firefox
		element.click()
		document.body.removeChild(element) //  opz
		URL.revokeObjectURL(fileURL)
		//}
	}

	// 26.07.2022 copiato da statistics
	public static myOpenOnNewWin(myBlob) {
		let fileURL = URL.createObjectURL(myBlob)
		console.log('(myOpen) blob type: ' + myBlob.type)

		// apre su nuova finestra
		window.open(fileURL, '_blank')
	}

	// 08.04.2022 centralizzata funzione per future modifiche
	static hideIcdsCodes(grp) {
		let ret = false
		if (grp == 2 || grp == 3) {
			ret = true
		}
		return ret
	}

	// 25.02.2022 non traccia in PROD
	// 02.11.2022 nemmeno in STG
	static debug(msg: string) {
		if (!Config.isProductionMode && !Config.isStagingMode) {
			console.log(msg)
		}
	}

	// 05.10.2022
	static info(msg: string) {
		if (!Config.isProductionMode) {
			console.log(msg)
		}
	}

	// 05.10.2022 anche in PROD
	static err(msg: string) {
		//if (!Config.isProductionMode) {
		console.log(msg)
		//}
	}

	// 25.09.2023
	/**
	 * Add a number of days to the given date
	 * @param  {Date} 	date The starting date
	 * @param  {Number} days The number of days to add
	 * @return {Date}        The starting date + the number of days given
	 */
	public static addDays(date: Date, days: number): Date {
		const resultDate = new Date(date)
		resultDate.setDate(resultDate.getDate() + days)
		return resultDate
	}

	public static flattenObject(obj: any, prefix = ''): any {
		return Object.keys(obj).reduce((acc, key) => {
			const prefixedKey = prefix ? `${prefix}.${key}` : key
			if (typeof obj[key] === 'object' && obj[key] !== null) {
				Object.assign(acc, Util.flattenObject(obj[key], prefixedKey))
			} else {
				acc[prefixedKey] = obj[key]
			}
			return acc
		}, {})
	}

	public static formatGmtTime(gmt: string): { gmt: string; state: number } {
		let result: { gmt: string; state: number } = {
			gmt: gmt,
			state: 0,
		}

		gmt.replace(/[^\d-]/g, '')

		let operator: string = ''
		let hasOperator = gmt.includes('-') || gmt.includes('+')
		let gmt_time = gmt

		if (hasOperator) {
			operator = gmt[0]
			gmt_time = gmt.slice(1)
		}

		if (gmt_time.length < 5) {
			if (gmt_time.length === 1) {
				result.gmt = '0' + gmt_time + ':00'
				result.state = 1
			} else if (gmt_time.length === 2) {
				result.gmt = gmt_time + ':00'
				result.state = 1
			} else if (gmt_time.length === 4) {
				result.gmt = gmt_time.slice(0, 2) + ':' + gmt_time.slice(2, 4)
				result.state = 1
			} else {
				console.log(' not valid timezone' + gmt_time)
				result.state = 2
			}

			if (hasOperator) {
				result.gmt = operator + result.gmt
			} else {
				result.gmt = '+' + result.gmt
			}
		}

		return result
	}

	public static secondsToTime(seconds: number): string {
		const hours = Math.floor(Math.abs(seconds) / 3600)
		const minutes = Math.floor((Math.abs(seconds) % 3600) / 60)

		const sign = seconds >= 0 ? '+' : '-'
		const hoursString = hours.toString().padStart(2, '0')
		const minutesString = minutes.toString().padStart(2, '0')

		return `${sign}${hoursString}:${minutesString}`
	}
}

/*
// non serve piu' ? [ls]
// 11.11.2019
export class TableSort {
	attribute: string;
	direction: string;

	constructor() {
		this.attribute = '';
		this.direction = '';
	}
}
*/

// usato anche per il TL di Vistel AI
// 07.02.2022 per esporre il pallino con info sul report
// diverso in base al livello utente
export class DotReport {
	class: string
	tooltip: string
	label: string
	weight: number // 24.11.2022 per poter fare il sort

	constructor() {
		this.class = 'statusrefH' // pallino vuoto
		this.tooltip = ''
		this.label = ''
		this.weight = 0
	}
}

// 29.04.2022
export class TeleParams {
	user_id: number
	url: string
	boothUrl: string
	chatUrl: string
	token: string
	name: string
	organization: string
	role: string
	groupId: number
	subtype: string
	targetOrganization: string
	targetGroupId: string

	constructor() {
		this.user_id = 0
		this.url = ''
		this.boothUrl = ''
		this.chatUrl = ''
		this.token = ''
		this.organization = ''
		this.name = ''
		this.role = ''
		this.groupId = 0
		this.subtype = ''
		this.targetOrganization = ''
		this.targetGroupId = ''
	}
}
