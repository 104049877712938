import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, Output } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

import { faSave, faEye, faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCircleMinus, faCircleCheck, faCircleXmark, faRotate } from '@fortawesome/free-solid-svg-icons'

// 25.07.2022    MAT TABLE IMPORT
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs } from '../../models/settings.model'

import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'

import { Util } from '../../models/util.model'
import { AiReport } from '../../models/aiReport.model'
import { DotReport } from '../../models/util.model'
import { reportsService } from 'src/app/service/reports.service'
import { Subscription } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { Patient } from 'src/app/models/patient.model'

// per il modal
var providers = [NgbModal, FormsModule]
@Component({
	selector: 'report-ai',
	templateUrl: './report-ai.component.html',
	styleUrls: ['./report-ai.component.scss'],
	providers,
})
export class ReportAiList implements OnInit {
	// presenta una tabella ma su un modal
	dataSource: MatTableDataSource<AiReport>

	@Input() reportList: AiReport[]
	@Input() targetPatient: Patient

	// 22.06.2022   MatTable controller pagination + sort
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: true }) sort: MatSort

	displayedColumns: string[]

	aiReportListSubscription: Subscription

	// myPageSize: number
	targetReport: number

	modalWaitPdf
	isGeneratingPdf: boolean

	// waitNewAiReport: boolean

	faEye = faEye
	faSave = faSave
	faCircle = faCircle // 07.10.2022 as default
	faRotate = faRotate

	constructor(
		public session: SessionService,
		public activeModal: NgbActiveModal,
		public translator: TranslateService,
		// public myMatPagIntl: MatPaginatorIntl,
		private activatedRoute: ActivatedRoute,
		public dataService: DataModelService,
		private reportsService: reportsService
	) {
		Util.debug('[AiRepList] constructor')

		this.modalWaitPdf = null
		this.isGeneratingPdf = false
		this.targetReport = 0
	}

	ngOnInit(): void {
		this.initColumns()
		Util.debug('[AiRepList] onInit')
		// if (this.reportList) {
		// 	Util.debug('[AiRepList] onInit, tot: ' + this.reportList.length)
		// } else {
		// 	Util.debug('[AiRepList] onInit - empty list')

		// 	this.reportList = this.visitListService.getReportAi()
		// }

		// translation for the mat-paginator
		// this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')
		this.manageList()
		// if (this.waitNewAiReport) {
		this.aiReportListSubscription = this.dataService.aiReportListChanged.subscribe((resp) => {
			// console.log(resp)
			if (resp.patId === this.targetPatient.id) {
				this.reportList = resp.reports
				this.manageList()
			}
			// this.waitNewAiReport = this.reportsService.isWaitNewAiReport()
		})
		// }

		// 24.08.2022 per RDS, gestione target report
		Util.debug('[AiRepList] afterInit, target rep. required: ' + this.targetReport)

		this.loadUrlParameters()
		// if (this.targetReport > 0) {
		// 	//if(this.patientId){  // per AI non serve
		// 	//  Util.debug("[AiRepList] afterInit - patient id: "+this.patientId);
		// 	//}
		// 	let myReportAi = this.getReportFromList(this.targetReport)
		// 	if (myReportAi) {
		// 		Util.debug('[AiRepList] - going to download target report ' + this.targetReport)
		// 		this.downloadPdf(myReportAi)
		// 	} else {
		// 		Util.debug('[AiRepList] - target report not found, id: ' + this.targetReport)
		// 	}
		// }
	}

	initColumns() {
		// colonne comuni a tutti i profili
		this.displayedColumns = ['id', 'creation_date', 'device', 'TL'] // "TLR", "TLL"

		if (this.session.isLevel1()) {
			this.displayedColumns.push('actions')
		} else if (this.session.isLevel3()) {
			this.displayedColumns.push('status')
			this.displayedColumns.push('grading')
		}
	}

	private manageList() {
		// console.log(this.reportList)

		if (this.reportList) {
			this.dataSource = new MatTableDataSource(this.reportList)
			this.dataSource.paginator = this.paginator
			this.dataSource.sort = this.sort

			this.sort.active = 'creation_date'
			this.sort.direction = 'desc'
			this.sort.sortChange.emit()
		} else {
			Util.debug('[AiRepList] manageList - empty !')
		}

		// this.loadingList = false
	}

	loadUrlParameters() {
		if (this.activatedRoute != null) {
			Util.debug('[AiRepList] - going to download target report ' + this.targetReport)

			let report = this.activatedRoute.snapshot.queryParams['report']
			if (report != null && parseInt(report) > 0) {
				this.targetReport = parseInt(report)

				let rep: AiReport = this.reportList.find((x) => x.id == this.targetReport)
				// console.log(rep)

				if (rep) {
					this.downloadPdf(rep)
				}
			}
		}
	}

	// 12.08.2022 for admins this is the only info
	showGrading(aiRep: AiReport) {
		let tl = AiReport.TL_EMPTY
		if (aiRep) {
			tl = aiRep.getTrafficLight()
		}
		if (tl == AiReport.TL_EMPTY) {
			// valutare se fare alert ?
			Util.debug('please wait, grading results not available yet. Batch-id: ' + aiRep.batch_id)
			return
		}

		let myOD = aiRep.grading['od'] // oggetto json
		let myOS = aiRep.grading['os']

		let myVal =
			'Report type: ' +
			aiRep.channel +
			' \r\n' +
			'batch: ' +
			aiRep.batch_id +
			' \r\n' +
			'Traffic Light: ' +
			aiRep.getTrafficLight() +
			' \r\n' +
			'OD: ' +
			JSON.stringify(myOD) +
			' \r\n' +
			'OS: ' +
			JSON.stringify(myOS)

		if (!this.session.isOptician()) {
			alert(myVal)
		}
	}

	// 26.08.2022
	public getTlDotReport(myRep: AiReport) {
		let dotReport = new DotReport() // default niente

		let tl = myRep.getTrafficLight()

		if (tl == AiReport.TL_GREEN) {
			dotReport.class = 'statusrefN' // pallino verde
			dotReport.tooltip = this.translator.instant('AI_REPORTS.GREEN')
			dotReport.label = ':)'
		} else if (tl == AiReport.TL_YELLOW) {
			dotReport.class = 'statusrefY' // giallo
			dotReport.tooltip = this.translator.instant('AI_REPORTS.YELLOW')
			dotReport.label = ':-'
		} else if (tl == AiReport.TL_RED) {
			dotReport.class = 'statusrefU' // rosso
			dotReport.tooltip = this.translator.instant('AI_REPORTS.RED')
			dotReport.label = ':('
		}

		return dotReport
	}

	getTlFaIcon(myRep: AiReport) {
		let alertText

		let tl = myRep.getTrafficLight()

		if (tl == AiReport.TL_GREEN) {
			alertText = this.translator.instant('AI_REPORTS.GREEN_BODY')
		} else if (tl == AiReport.TL_YELLOW) {
			alertText = this.translator.instant('AI_REPORTS.YELLOW_BODY')
		} else if (tl == AiReport.TL_RED) {
			alertText = this.translator.instant('AI_REPORTS.RED_BODY')
		} else {
			alertText = '-'
		}

		return alertText
	}

	getChannel(Channel: AiReport) {
		let textChannel = this.translator.instant('AI_REPORTS.MCS')
		if (Channel.channel == AiReport.DSS) {
			textChannel = this.translator.instant('AI_REPORTS.DSS')
		}
		return textChannel
	}

	downloadPdf(reportAi: AiReport) {
		if (!reportAi.is_waiting_PDF) {
			Util.debug('[AiReport] - downloadPdf id ' + reportAi.id)
			reportAi.is_waiting_PDF = true
			let originalStatus = reportAi.status

			this.isGeneratingPdf = true

			this.reportsService
				.loadAiReport(reportAi)
				.then((aiReport) => {
					// save as file
					Util.debug('[AiReport] - downloadPdf ok decrypt')
					reportAi.is_waiting_PDF = false
					// reportAi.status = 4 // set status 4 without reload list

					if (originalStatus != 4) {
						// aggiorno l'info sul paziente in modo da aggiornare lo stato dei nuovi report
						let obj: { prop: string; value: any }[] = []
						let value = this.targetPatient.tot_new_aireports
						if (value && value > 0) {
							value--
						}
						obj.push({ prop: 'tot_new_aireports', value: value })

						this.dataService.editPatientInfo(this.targetPatient.id, obj)
					}

					// TODO more info, remove timestamp
					let timestamp = Date.now().toString()
					let fileName = 'Ai_' + aiReport.channel + '_' + reportAi.id + '_' + 'pat_' + reportAi.patient_id + '_' + timestamp + '.pdf'

					let binaryData = aiReport.pdf_clear

					// 29.07.2022 FIX need to force Uint8Array !!!!
					var ia = new Uint8Array(binaryData.length)
					for (let i = 0; i < binaryData.length; i++) {
						//ia[i] = binaryData[i];  // ko
						ia[i] = binaryData.charCodeAt(i) // ok
					}

					//var blob = new Blob([ia], {type: "application/octet-stream"});
					//var blob = new Blob([ia], {type: "octet-stream"});
					var blob = new Blob([ia], { type: 'application/pdf' })

					Util.myOpenOnNewWin(blob)

					this.isGeneratingPdf = false
				})
				.catch((error) => {
					// 26.08.2022 fix
					Util.debug('[AiReport] err: ' + error.message)
					this.isGeneratingPdf = false // blocca lo spinner
					reportAi.is_waiting_PDF = false

					// meglio msg genrico.. // Invalid IV length; got 3 bytes and expected 16 bytes.
					//let msg = this.session.parseErrorMessage(error, "alert");
					let msg = 'Some problems occurred during the download. Please contact your reference in Visionix.'
					alert(msg)
				})
		} else {
			return
		}
	}

	// filterText() {
	// 	this.reportAiPref.filter = this.input.nativeElement.value
	// 	this.reportAiPref.filter = this.reportAiPref.filter.trim().toLocaleLowerCase()
	// 	this.dataSource.filter = this.reportAiPref.filter
	// }
	ngOnDestroy() {
		// sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportAiPref))
		// localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.reportAiPref.itemsPerPage))

		if (this.aiReportListSubscription) {
			Util.debug('unsubscribe aiReportListSubscription')
			this.aiReportListSubscription.unsubscribe()
		}
	}
}
