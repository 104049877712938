import { Config } from '../../config'
import { DateParser } from './dateParser.model'
import { Util } from './util.model'

import * as b64images from './b64images'
import * as forge from 'node-forge'
import { CorePdfReport } from './pdf.model'
import { CryptoUtilsService, dataType, postMessageToWorker, workerFunctionType } from '../service/crypto-utils.service'
import { environment } from 'src/environments/environment'

// 08.09.2021 costanti x gli occhi
export const RIGHT = 'right'
export const LEFT = 'left'
export const BINO = 'bino'
export const BOTH = 'both'
export const SINGLE = 'single' // unspecified   29.04.2022

// versione short di esame, solo info che servono nella visit-list
export class ExamType {
	id: number
	exam_type: string
	is_reviewed: string // 14.04.2020

	constructor(rawObj?) {
		this.exam_type = ''
		this.id = 0

		if (rawObj != null) {
			this.exam_type = rawObj.exam_type
			this.id = rawObj.exam_id
			this.is_reviewed = rawObj.is_reviewed
		}
	}

	static createExamsList(response) {
		var result = []
		var myResp = response.data.exams
		for (var i = 0; i < myResp.length; i++) {
			var ex = new ExamType(myResp[i])
			result.push(ex)
		}
		return result
	}
}

// poi le classi specifiche la estendono
export class Exam {
	static DEG_SYMBOL = '°' // 20.04.2022
	// "&#176;"; // String.fromCharCode(176);  // ko: "&deg;"   &deg; or &#176;
	static MICRO_SYMBOL = ' µm' // &micro;  &#xB5;
	static TOR_SYMBOL = ' mmHg' // pressione dell'occhio

	static MM_SYMBOL = ' mm' // con spazio davanti
	static DS_SYMBOL = ' DS'
	static DC_SYMBOL = ' DC'

	static D_SYMBOL = ' D' // "Diopter"  // 20.05.2022

	static idGen = 0
	id: number
	visit_id: number
	patient_id: number
	exam_type: string

	//date: Date;
	exam_date: Date

	device: string
	device_sn: string // 16.11.2022 required by Q.A. support team
	eye: string
	is_deleted: string
	is_valid: string

	created_by: number // 14.07.2021

	public hasImages: boolean // 28.12.2021
	public hasNumericData: boolean

	//tot_images: number; // TODO, per comodita' ?

	constructor(draft?) {
		this.id = 0
		this.visit_id = 0
		this.patient_id = 0
		this.eye = ''
		this.device = ''
		this.device_sn = ''
		this.exam_type = ''
		this.is_deleted = 'N'
		this.is_valid = 'Y'
		this.hasImages = false
		this.hasNumericData = false // valorizzo a true dove serve
		this.exam_date = null // 30.09.2022 patch ?

		if (draft) {
			// 15.06.2020
			this.id = draft.id
			this.visit_id = draft.visit_id
			this.patient_id = draft.patient_id
			this.eye = draft.eye
			this.device = draft.device
			this.exam_type = draft.exam_type

			if (draft.device_sn) {
				this.device_sn = draft.device_sn
				//Util.debug("(Exam) "+this.exam_type+" id: "+this.id+" Model:"+this.device+" SN:"+this.device_sn);
			}

			this.exam_date = DateParser.parseDate(draft.exam_date)

			// 14.07.2021
			if (draft.oper_id) this.created_by = draft.oper_id

			if (draft.created_by) this.created_by = draft.created_by

			// 28.10.2021 patch per le statistiche
			if (this.id == null && draft.exam_id) this.id = draft.exam_id
		}
	}

	// 15.04.2020 funzione di smistamento e specializzazione esami
	static createExam(draftEx, cryptoUtils, keyPhoto): Promise<Exam> {
		// console.log(draftEx)

		if (!draftEx) {
			return Promise.resolve(new Exam())
		}

		if (keyPhoto == null) {
			console.log('(createExam) null keyPhoto!')
		}

		if (draftEx.exam_type == Config.EXM_TOPO) {
			return TopoExam.createTExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_PACHYMULT) {
			return PachyMultExam.createPMExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_EXT) {
			// 15.06.2020
			return ExternalExam.createExtExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_DRYEYE) {
			// 23.06.2020
			return DryEyeExam.createDryEyeExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_PACHY) {
			// 21.07.2020
			return PachyExam.createPachyExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_TONO) {
			// 28.07.2020
			return TonoExam.createTonoExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_WF) {
			// 31.07.2020
			return WfExam.createWfExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_RETRO) {
			// 21.08.2020
			return RetroExam.createRetroExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_FUNDUS) {
			// 10.09.2020
			return FundusExam.createFundusExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_SBJ) {
			// 05.10.2020
			return SubjectiveExam.createSubjectiveExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_LM) {
			// 07.10.2020
			return LensmeterExam.createLensmeterExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_PUPIL) {
			// 07.10.2020
			return PupilExam.createPupilExam(draftEx, cryptoUtils, keyPhoto)
		} else if (draftEx.exam_type == Config.EXM_FASTREFRACTION) {
			return FRExam.createFRExam(draftEx, cryptoUtils, keyPhoto)
		} else {
			console.log('(createExam) TODO type: ' + draftEx.exam_type) // 21.08.2020

			// 15.06.2020 prendo i campi base, in chiaro
			return Promise.resolve(new Exam(draftEx))
		}
	}

	//15.06.2020 portare altrove?
	// potrebbe servire in futuro multi-lingua ? [ls]
	// vd mail Tzvi del 31/05/2020
	static getExamLabel(exam_type) {
		var label = ''

		if (exam_type == null || exam_type.length == 0) {
			return ''
		}

		// tutti 12
		switch (
			exam_type // vd valori su DB, tabella exam_types
		) {
			case Config.EXM_DRYEYE:
				label = 'Dry Eye'
				break

			case Config.EXM_EXT:
				label = 'External'
				break

			case Config.EXM_FUNDUS:
				label = 'Fundus'
				break

			case Config.EXM_LM:
				label = 'LensMeter' // poi e' tutto maiuscolo
				break

			case Config.EXM_PACHY:
				label = 'Pachymetry' // uguale per entrambi
				break

			case Config.EXM_PACHYMULT:
				label = 'Pachymetry M' // uguale per entrambi
				break

			case Config.EXM_PUPIL:
				label = 'Pupil' // su refraction ?!
				break

			case Config.EXM_RETRO:
				label = 'Retro'
				break

			case Config.EXM_SBJ:
				label = 'Subjective'
				break

			case Config.EXM_TONO:
				label = 'Tonometry'
				break

			case Config.EXM_TOPO:
				label = 'Topography'
				break

			case Config.EXM_WF:
				label = 'Wavefront'
				break

			case Config.EXM_FASTREFRACTION:
				label = 'Fast Refraction'
				break

			default:
				label = '' + exam_type
				console.log('(getExamLabel) not managed yet ' + exam_type)
		}

		return label
	}

	// 20.04.2022 steps 0.25 for sphere, cyl etc  con 2 decimali
	public static roundStep(xVal: string) {
		let num = parseFloat(xVal.replace(',', '.'))
		//let step = Math.ceil(x/5)*5;  // il successivo
		let step = Math.round(num / 0.25) * 0.25 // il piu' vicino
		return step.toFixed(2)
	}

	// 20.04.2022 DS with 2 decimals, steps 0.25
	public static getMySphere(szNum: string) {
		if (!szNum) return ''

		let val = parseFloat(szNum.replace(',', '.'))
		let step = Exam.roundStep(szNum)
		let ret = val && val != 0 ? '' + step + Exam.DS_SYMBOL : ''
		return ret
	}

	// 20.04.2022 DC with 2 decimals, steps 0.25
	public static getMyCylinder(szNum: string) {
		if (!szNum) return ''

		let val = parseFloat(szNum.replace(',', '.'))
		let step = Exam.roundStep(szNum)
		let ret = val && val != 0 ? '' + step + Exam.DC_SYMBOL : ''
		return ret
	}

	// 20.04.2022 degrees - no decimals
	public static getMyAxis(szNum: string) {
		if (!szNum) return ''

		let val1 = parseFloat(szNum.replace(',', '.')) // vanno tolti i decimali
		let val = Math.round(val1) // 20.04.2022

		// 21.04.2022 ok anche zero ?
		let ret = !isNaN(val1) ? '' + val + Exam.DEG_SYMBOL : szNum
		//let ret = "" + val + Exam.DEG_SYMBOL;

		return ret
	}
} // [end Exam]

/*
  static createExamList(response: Contracts.ExamsResponse, cryptoUtils, keyDoctor) {
    var result = [];

    for (var i = 0; i < response.data.data.length; i++) {
      var visit = response.data.data[i];
      result.push(Exam.createExam(visit, cryptoUtils, keyDoctor));
    }
    return cryptoUtils.q.all(result);
  }
  */
//}

// estensione di Exam generico
export class TopoExam extends Exam {
	eccentricity: string // sul DB e' double
	KPI: string
	SI: string

	K1_radius
	K1_r_unit: string
	K1_axis
	K1_a_unit: string

	K2_radius
	K2_r_unit: string
	K2_axis
	K2_a_unit: string

	CYL_power
	CYL_p_unit: string
	CYL_axis
	CYL_a_unit: string

	AVG: number // 25.05.2020

	image: string
	axial_map: string
	elevation_map: string
	tangential_map: string

	//new KISA fields
	topo_algorithm: string
	kisa: string
	kisa_unit: string
	K: string
	K_unit: string
	srax: string
	srax_unit: string
	i_minus_s: string
	i_minus_s_unit: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'topo'
		this.eccentricity = ''
		this.KPI = ''
		this.SI = ''

		this.K1_radius = ''
		this.K1_r_unit = ''
		this.K1_axis = ''
		this.K1_a_unit = ''
		this.K2_radius = ''
		this.K2_r_unit = ''
		this.K2_axis = ''
		this.K2_a_unit = ''
		this.CYL_power = ''
		this.CYL_p_unit = ''
		this.CYL_axis = ''
		this.CYL_a_unit = ''
		this.AVG = 0

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		this.image = null
		this.axial_map = null
		this.elevation_map = null
		this.tangential_map = null
		//this.tangential_map = "";

		this.topo_algorithm = ''
		this.kisa = ''
		this.kisa_unit = ''
		this.K = ''
		this.K_unit = ''
		this.srax = ''
		this.srax_unit = ''
		this.i_minus_s = ''
		this.i_minus_s_unit = ''
	}

	// 31.07.2020 aggiunto param draft
	// 15.06.2020 spostato qui da visit, per test [ls]
	static getFakeExam(topoOptionalValue, draft?) {
		var fakeExam = new TopoExam(draft)

		// if (fakeExam.eye == LEFT) {

		for (const prop in topoOptionalValue) {
			if (topoOptionalValue[prop].set) {
				if (prop == 'topo_algorithm') {
					fakeExam[prop] = 'Visionix'
				} else {
					fakeExam[prop] = topoOptionalValue[prop].value
				}
			} else if (!topoOptionalValue[prop].set && prop == 'topo_algorithm') {
				fakeExam[prop] = topoOptionalValue[prop].value //default CSO
			}
		}

		return fakeExam
	}

	// 30.03.2022
	getDiopter(mmValue: number) {
		let diopt = ''
		if (mmValue != 0) {
			// F = 1000*(1.3375 – 1) / r    // r is in mm.
			let tmp = 337.5 / mmValue
			// arrotonda e completa a max 2 decimali
			diopt = Number(tmp).toFixed(2)
		}
		return diopt
	}

	// 06.04.2022
	private getDegrees(val, unit) {
		let ret = ''
		if (val != null) {
			let deg = ''
			if (unit == 'deg') {
				deg = Exam.DEG_SYMBOL
			} else {
				deg = ' ' + unit
			}
			ret = '@' + val + deg
		}
		return ret
	}

	// 27.05.2020 valori formattati come unica stringa
	// 31.03.2022 aggiungiamo conversioni in diopter
	private getK(type: number) {
		// radius, r_unit, ax, ax_unit){

		let radius: number //this.K1_radius;
		let r_unit = null //this.K1_r_unit;
		let ax: number //this.K1_axis;
		let ax_unit = null // this.K1_a_unit;

		if (type == 1) {
			radius = parseFloat(this.K1_radius.replace(',', '.'))
			r_unit = this.K1_r_unit
			ax = parseFloat(this.K1_axis.replace(',', '.'))
			ax_unit = this.K1_a_unit
		} else if (type == 2) {
			radius = parseFloat(this.K2_radius.replace(',', '.'))
			r_unit = this.K2_r_unit
			ax = parseFloat(this.K2_axis.replace(',', '.'))
			ax_unit = this.K2_a_unit
		}

		//console.log("(getK): type: "+type+" radius:"+radius+" unit: "+r_unit);

		var ret = ''
		let radiusMM = ''
		let radiusD = ''
		let axis = ''

		if (radius != null && r_unit == 'mm') {
			// 30.06.2020
			radiusMM = '(' + Number(radius).toFixed(2) + ' ' + r_unit + ')' // in mm come arriva, con valore cmq arrotondato
		}

		//console.log("(getK): "+radiusMM);

		// portata fuori
		axis = this.getDegrees(ax, ax_unit)

		if (r_unit == 'mm' && radius != null) {
			radiusD = this.getDiopter(radius) + Exam.D_SYMBOL //  D = "Diopter";
		} else {
			radiusD = radius + ' ' + r_unit // orig value
		}

		//ret = radius + " " + this.K1_axis + " " + this.K1_a_unit;
		ret = radiusD + ' ' + radiusMM + ' ' + axis

		return ret
	}

	// 27.05.2020 valori formattati come unica stringa
	// 31.03.2022 aggiungiamo conversioni in diopter
	getK1() {
		return this.getK(1) // this.K1_radius, this.K1_r_unit, this.K1_axis, this.K1_a_unit);
	}

	getK2() {
		return this.getK(2)
	}

	getCYL() {
		var ret = ''
		if (this.CYL_power) {
			// 30.06.2020
			let step = Exam.roundStep(this.CYL_power)
			//ret = this.CYL_power + "" + this.CYL_p_unit + " " + this.CYL_axis + " " + this.CYL_a_unit;  // 06.04.2022
			//ret = this.CYL_power + "" + this.CYL_p_unit + " "+ this.getDegrees(this.CYL_axis, this.CYL_a_unit);
			ret = step + '' + this.CYL_p_unit + ' ' + this.getDegrees(this.CYL_axis, this.CYL_a_unit) // 06.04.2022
		}
		return ret
	}

	getAVG() {
		var ret = ''
		if (this.AVG != null) {
			// 20.04.2022 entrambe le misure
			let myAVG_D = ''
			let myAVGmm = ''

			if (this.K1_r_unit == 'mm') {
				myAVG_D = this.getDiopter(this.AVG) + Exam.D_SYMBOL
				myAVGmm = ' (' + this.AVG.toFixed(2) + ' ' + this.K1_r_unit + ')'
			} else {
				myAVG_D = this.AVG.toFixed(2) + ' ' + this.K1_r_unit
			}
			ret = myAVG_D + ' ' + myAVGmm
		}

		return ret
	}

	// 19.04.2022 with % unit
	getKPI() {
		let ret = ''
		if (this.KPI.trim().length > 0) {
			ret = this.KPI + ' %'
		}
		return ret
	}

	// 19.04.2022 2 decimals, no unit
	getSI() {
		let val = parseFloat(this.SI.replace(',', '.'))

		//console.log("(getSi) "+this.SI+" val: "+val);

		// 20.06.2022 vd bug 178, compare NaN
		//let ret = (val != 0) ? ""+Number(val).toFixed(2) : val + "" ;
		let ret = val && val != 0 ? '' + Number(val).toFixed(2) : ''

		return ret
	}

	// NB: prima number poi toFixed: completa 1 -> 1,00 , oltre a troncare 1,234 -> 1,23

	// 19.04.2022 2 decimals, no unit
	getEccentr() {
		let val = parseFloat(this.eccentricity.replace(',', '.'))
		let ret = val && val != 0 ? '' + Number(val).toFixed(2) : ''
		return ret
	}

	getKisa() {
		let ret = ''
		if (this.kisa.trim().length > 0) {
			let val = parseFloat(this.kisa.replace(',', '.'))
			ret = val && val != 0 ? '' + Number(val).toFixed(2) : ''

			if (this.kisa_unit) {
				ret += ' ' + this.kisa_unit
			} else {
				ret += ' %'
			}
		}

		return ret
	}

	static fullTopoFieldsToDecript = [
		'CYL_axis',
		'CYL_power',
		'K',
		'K1_axis',
		'K1_radius',
		'K2_axis',
		'K2_radius',
		'KPI',
		'SI',
		'axial_map',
		'eccentricity',
		'elevation_map',
		'i_minus_s',
		'kisa',
		'srax',
		'tangential_map',
		'topo_algorithm',
	]

	static shortTopoFieldsToDecript = [
		'CYL_axis',
		'CYL_power',
		'K',
		'K1_axis',
		'K1_radius',
		'K2_axis',
		'K2_radius',
		'KPI',
		'SI',
		'axial_map',
		'eccentricity',
		'elevation_map',
		'i_minus_s',
		'kisa',
		'srax',
		'tangential_map',
		'topo_algorithm',
	]

	static getTopoFields(): string[] {
		if (environment.development || environment.staging) {
			return this.fullTopoFieldsToDecript
		} else {
			return this.shortTopoFieldsToDecript
		}
	}

	static createTExam(draft, cryptoUtils, keyPhoto): Promise<TopoExam> {
		//var result = new TopoExam();
		var result = new TopoExam(draft) // 30.09.2022 patch ?

		if (draft == null) {
			//return Promise.resolve(result);
			return Promise.reject(result) // 06.04.2022
		}

		result.K1_r_unit = draft.K1_r_unit
		result.K1_a_unit = draft.K1_a_unit
		result.K2_r_unit = draft.K2_r_unit
		result.K2_a_unit = draft.K2_a_unit
		result.CYL_p_unit = draft.CYL_p_unit
		result.CYL_a_unit = draft.CYL_a_unit

		result.K_unit = draft.K_unit
		result.kisa_unit = draft.kisa_unit
		result.i_minus_s_unit = draft.i_minus_s_unit
		result.srax_unit = draft.srax_unit

		// campi crittati

		if (keyPhoto == null) {
			Util.debug('topoExam - manca keyPhoto')
			//return Promise.resolve(result);
			return Promise.reject(result) // 18.01.2022
		} else {
			// 30.04.2020 attivo solo su questa funzione
			//cryptoUtils.setCBC(true);

			var bag = cryptoUtils.generateBag()

			for (let field of this.getTopoFields()) {
				if (draft[field]) {
					bag[field] = draft[field]
				}
			}

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				Util.debug('topoExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			let data: postMessageToWorker = new postMessageToWorker()
			data.type = workerFunctionType.decryptDataWithKey
			data.key = keyPhoto
			data.dataToDecrypt = bag
			data.dataType = dataType.CryptoBag
			data.hasImages = true
			data.imagesFields = ['axial_map', 'elevation_map', 'tangential_map']

			return cryptoUtils
				.decryptDataWithKeyWorker(data)
				.then((myBag) => {
					// console.log(myBag)
					if (myBag != null) {
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]

								if (prop.indexOf('axial_map') == 0 || prop.indexOf('elevation_map') == 0 || prop.indexOf('tangential_map') == 0) {
									result.hasImages = true
								}
							}
						}

						if (result.K1_radius != null && result.K2_radius != null) {
							var k1 = parseFloat(result.K1_radius.replace(',', '.'))
							var k2 = parseFloat(result.K2_radius.replace(',', '.'))
							//Util.debug("TopoExam - K float values: ["+k1+"] ["+k2+"]");  // 31.03.2022 test

							// 12.10.2020 arrotondamento,, 7.925000000000001 -> 7.925 D
							//result.AVG = (k1 + k2)/2 ;
							var tmp = (k1 + k2) / 2
							result.AVG = Number(tmp.toFixed(4))
						}

						return result
					} else {
						console.log('pachyExam - null bag ris')
						return result
					}
				})
				.catch((err) => {
					console.log('pachyExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 15.04.2020
export class PachyMultExam extends Exam {
	image: string

	constructor(draft?) {
		// 12.10.2020 aggiunto draft, uniformato
		super(draft)

		this.exam_type = 'pachymult'

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		//this.image = "";
		this.image = null
	}

	static createPMExam(draft, cryptoUtils, keyPhoto): Promise<PachyMultExam> {
		var result = new PachyMultExam()

		if (!draft) {
			return Promise.resolve(result)
		}

		result.id = draft.id
		result.visit_id = draft.visit_id
		result.patient_id = draft.patient_id
		result.eye = draft.eye
		result.device = draft.device
		result.exam_type = draft.exam_type

		result.exam_date = DateParser.parseDate(draft.exam_date)

		// campi crittati

		if (keyPhoto == null) {
			console.log('pmExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()
			bag.image = draft.image
			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('pmExam - mancano le immagini')
				return Promise.resolve(result)
			}

			return cryptoUtils
				.decryptImages(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						result.image = myBag.image
						result.hasImages = true
						return result
					} else {
						console.log('pmExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('pmExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 15.06.2020
export class ExternalExam extends Exam {
	//image: string;  // 10.09.2020 nn serve

	image_auto: string

	// images_man: string[]; // cmq con la bag vanno elencati
	image_man_1: string
	image_man_2: string
	image_man_3: string
	image_man_4: string
	image_man_5: string
	image_man_6: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'extphoto'

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		//this.image_auto = "";
		this.image_auto = null

		//this.images_man = []; // non va bene per le bag, vd metodo  getManualImages, se serve
		/*    
    this.image_man_1 = "";
    this.image_man_2 = "";
    this.image_man_3 = "";
    this.image_man_4 = "";
    this.image_man_5 = "";
    this.image_man_6 = "";
    */
		this.image_man_1 = null
		this.image_man_2 = null
		this.image_man_3 = null
		this.image_man_4 = null
		this.image_man_5 = null
		this.image_man_6 = null
	}

	// 16.06.2020
	getManualImages(): string[] {
		var imgList = []

		if (this.image_man_1 != null && this.image_man_1.length > 0) {
			imgList.push(this.image_man_1)
		}

		if (this.image_man_2 != null && this.image_man_2.length > 0) {
			imgList.push(this.image_man_2)
		}

		if (this.image_man_3 != null && this.image_man_3.length > 0) {
			imgList.push(this.image_man_3)
		}

		if (this.image_man_4 != null && this.image_man_4.length > 0) {
			imgList.push(this.image_man_4)
		}

		if (this.image_man_5 != null && this.image_man_5.length > 0) {
			imgList.push(this.image_man_5)
		}

		if (this.image_man_6 != null && this.image_man_6.length > 0) {
			imgList.push(this.image_man_6)
		}

		return imgList
	}

	static createExtExam(draft, cryptoUtils, keyPhoto): Promise<ExternalExam> {
		//var result = new ExternalExam();
		var result = new ExternalExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		/* 15.06.2020 fatto nel costruttore della super ? [ls]

    result.id = draft.id;
    result.visit_id = draft.visit_id;
    result.patient_id = draft.patient_id;
    result.eye = draft.eye;
    result.device = draft.device;
    result.exam_type = draft.exam_type;
    result.exam_date = DateParser.parseDate(draft.exam_date);
    */

		// campi crittati

		if (keyPhoto == null) {
			console.log('extExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			// bag.image = draft.image;
			bag.image_auto = draft.image_auto
			bag.image_man_1 = draft.image_man_1
			bag.image_man_2 = draft.image_man_2
			bag.image_man_3 = draft.image_man_3
			bag.image_man_4 = draft.image_man_4
			bag.image_man_5 = draft.image_man_5
			bag.image_man_6 = draft.image_man_6

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('extExam - mancano le immagini')
				return Promise.resolve(result)
			}

			return cryptoUtils
				.decryptImages(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]

								result.hasImages = true
							}
						}

						/*
            result.image_auto = myBag.image_auto;                                    
            result.image_man_1 = myBag.image_man_1;
            result.image_man_2 = myBag.image_man_2;
            result.image_man_3 = myBag.image_man_3;
            result.image_man_4 = myBag.image_man_4;
            result.image_man_5 = myBag.image_man_5;
            result.image_man_6 = myBag.image_man_6;
            */

						return result
					} else {
						console.log('extExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('extExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 22.06.2020 estensione di Exam generico
export class DryEyeExam extends Exam {
	image: string
	first_breakup: string
	average_breakup: string
	percentage: string
	tear_meniscus: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'dryeye'

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		//this.image = "";
		this.image = null

		if (draft != null) {
			this.first_breakup = draft.first_breakup != null ? draft.first_breakup : ''
			this.average_breakup = draft.average_breakup != null ? draft.average_breakup : ''
			this.percentage = draft.percentage != null ? draft.percentage : ''
			this.tear_meniscus = draft.tear_meniscus != null ? draft.tear_meniscus : ''
		} else {
			this.first_breakup = ''
			this.average_breakup = ''
			this.percentage = ''
			this.tear_meniscus = ''
		}
	}

	static createDryEyeExam(draft, cryptoUtils, keyPhoto): Promise<DryEyeExam> {
		var result = new DryEyeExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			console.log('dryEyeExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.image = draft.image
			bag.first_breakup = draft.first_breakup
			bag.average_breakup = draft.average_breakup
			bag.percentage = draft.percentage
			bag.tear_meniscus = draft.tear_meniscus

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('dryEyeExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// unica nuova funzione che gestisce entrambi i formati, nuovo e vecchio
			// misto dati/immagini, entrambi i formati
			return cryptoUtils
				.decryptAll(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]
								if (prop.indexOf('image') == 0) {
									result.hasImages = true
								}
							}
						}

						return result
					} else {
						console.log('dryEyeExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('dryEyeExam ERR: ' + err)
					return Promise.reject(result)

					/*  NB: il return qui non viene poi gestito dalla chiamante, che riceve null !!!!!
        }).finally(()=> {
          console.log("dryEyeExam - finally");
          console.log(result);  // qui oggetto buono
          return result;
        */
				})
		}
	}
	//18-07-2023
	public getTear_meniscus() {
		let ret = ' '

		if (this.tear_meniscus != null && this.tear_meniscus.trim() != '') {
			let num = parseFloat(this.tear_meniscus.replace(',', '.'))

			if (num) ret = '' + num.toFixed(2)
		}
		return ret
	}

	// 31.07.2020 aggiunto parametro draft
	static getFakeExam(draft?) {
		var fakeExam = new DryEyeExam(draft)

		//fakeExam.device = "VX120";

		if (fakeExam.eye == LEFT) {
			// 19.08.2020 differenziati i valori
			fakeExam.first_breakup = '2.4'
			fakeExam.average_breakup = '4,56'
			fakeExam.percentage = '8,6'
			fakeExam.tear_meniscus = '0.0985'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.first_breakup = '3.2'
			fakeExam.average_breakup = '7,1'
			fakeExam.percentage = '8,4'
			fakeExam.tear_meniscus = '-2'
		}

		return fakeExam
	}
}

// 20.07.2020
export class PachyExam extends Exam {
	image: string
	image_with_data: string
	central: string
	nasal_angle: string
	temporal_angle: string
	acd: string

	// new fields
	focus_array: string
	sharp_array: string
	median_array: string
	max_val_array: string
	contrast_array: string
	is_img_sharp_array: string
	cct_microns_array: string
	pupil_center_x_array: string
	pupil_center_y_array: string
	slit_pos_array: string
	grab_elapsed_time_ms_array: string
	visu_pupil_center_x_array: string
	visu_pupil_center_y_array: string
	visu_pupil_radius_array: string
	kappa_offset_point_y: string
	kappa_offset_point_x: string
	pupilometry_kappa: string
	kappa_angle: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'pachy'
		this.central = ''
		this.nasal_angle = ''
		this.temporal_angle = ''
		this.acd = ''
		this.image = null
		this.image_with_data = null

		if (draft) {
			var myJsonObj = { ...draft }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}

		if (
			(this.central && this.central.trim() != '') ||
			(this.nasal_angle && this.nasal_angle.trim() != '') ||
			(this.acd && this.acd.trim() != '') ||
			(this.temporal_angle && this.temporal_angle.trim() != '')
		) {
			this.hasNumericData = true
		}
	}

	// 20.04.2022 no decimals
	public getCCT() {
		let ret = ' ' // blank per tabelle e pdf
		if (this.central != null && this.central.trim() != '') {
			// 17.05.2022 aggiunto test non vuoto
			ret = '' + this.central + Exam.MICRO_SYMBOL
		}
		return ret
	}

	// 20.04.2022 1 decimal place with degrees
	public getNasalAngle() {
		//return ""+ this.nasal_angle + Exam.DEG_SYMBOL;
		let ret = ' '
		if (this.nasal_angle != null && this.nasal_angle.trim() != '') {
			let num = Number(this.nasal_angle)
			if (num)
				// 02.08.2022
				ret = '' + num.toFixed(1) + Exam.DEG_SYMBOL
		}
		return ret
	}

	// 20.04.2022 1 decimal place with degrees
	public getTemporalAngle() {
		let ret = ' '
		if (this.temporal_angle != null && this.temporal_angle.trim() != '') {
			let num = Number(this.temporal_angle)
			if (num)
				// 02.08.2022
				ret = '' + num.toFixed(1) + Exam.DEG_SYMBOL
		}
		return ret
	}

	// 28/09/2022
	public getACD() {
		let ret = ' '
		if (this.acd != null && this.acd.trim() != '') {
			let num = Number(this.acd)
			if (num) ret = '' + num.toFixed(2) + Exam.MM_SYMBOL
		}
		return ret
	}

	public hasKappa(): boolean {
		return this.kappa_angle ? true : false
	}

	public getKappa() {
		let ret = ' '
		if (this.kappa_angle != null && this.kappa_angle.trim() != '') {
			let num = Number(this.kappa_angle)
			ret = '' + num.toFixed(2) + Exam.DEG_SYMBOL
		}
		return ret
	}

	static getFakeExam(pachyOptionalValue, draft?) {
		var fakeExam = new PachyExam(draft)

		// if (fakeExam.eye == LEFT) {
		for (const prop in pachyOptionalValue) {
			if (pachyOptionalValue[prop].set) {
				fakeExam[prop] = pachyOptionalValue[prop].value
			}
		}

		return fakeExam
	}

	static fullPachyFieldsToDecript = [
		'image',
		'image_with_data',
		'central',
		'nasal_angle',
		'temporal_angle',
		'acd',
		'focus_array',
		'pupil_center_x_array',
		'pupil_center_y_array',
		'slit_pos_array',
		'grab_elapsed_time_ms_array',
		'visu_pupil_center_x_array',
		'visu_pupil_center_y_array',
		'visu_pupil_radius_array',
		'kappa_offset_point_y',
		'kappa_offset_point_x',
		'pupilometry_kappa',
		'kappa_angle',
		'cct_microns_array',
		'contrast_array',
		'is_img_sharp_array',
		'max_val_array',
		'median_array',
		'sharp_array',
	]

	static shortPachyFieldsToDecript = [
		'image',
		'image_with_data',
		'central',
		'nasal_angle',
		'temporal_angle',
		'acd',
		// 'focus_array',
		// 'pupil_center_x_array',
		// 'pupil_center_y_array',
		// 'slit_pos_array',
		// 'grab_elapsed_time_ms_array',
		// 'visu_pupil_center_x_array',
		// 'visu_pupil_center_y_array',
		// 'visu_pupil_radius_array',
		// 'kappa_offset_point_y',
		// 'kappa_offset_point_x',
		// 'pupilometry_kappa',
		'kappa_angle',
		// 'cct_microns_array',
		// 'contrast_array',
		// 'is_img_sharp_array',
		// 'max_val_array',
		// 'median_array',
		// 'sharp_array',
	]

	static getPachyFields(): string[] {
		if (environment.development || environment.staging) {
			return this.fullPachyFieldsToDecript
		} else {
			return this.shortPachyFieldsToDecript
		}
	}

	static createPachyExam(draft, cryptoUtils: CryptoUtilsService, keyPhoto: forge.util.ByteStringBuffer): Promise<PachyExam> {
		var result = new PachyExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		if (keyPhoto == null) {
			console.log('pachyExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			for (let field of this.getPachyFields()) {
				if (draft[field]) {
					bag[field] = draft[field]
				}
			}

			cryptoUtils.purge(bag)
			// console.log(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('pachyExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			let data: postMessageToWorker = new postMessageToWorker()
			data.type = workerFunctionType.decryptDataWithKey
			data.key = keyPhoto
			data.dataToDecrypt = bag
			data.dataType = dataType.CryptoBag
			data.hasImages = true
			data.imagesFields = ['image', 'image_with_data']

			return cryptoUtils
				.decryptDataWithKeyWorker(data)
				.then((myBag) => {
					// console.log(myBag)
					if (myBag != null) {
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]

								// TO DO, TRASFORMARE GLI ARRAY

								if (prop.indexOf('image') == 0 || prop.indexOf('image_with_data') == 0) {
									result.hasImages = true
								}
							}
						}

						return result
					} else {
						console.log('pachyExam - null bag ris')
						return result
					}
				})
				.catch((err) => {
					console.log('pachyExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 28.07.2020
export class TonoExam extends Exam {
	// no images, data only

	IOP: string
	IOPc: string
	correction_type: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'tono'

		if (draft != null) {
			this.IOP = draft.IOP != null ? draft.IOP : ''
			this.IOPc = draft.IOPc != null ? draft.IOPc : ''
			this.correction_type = draft.correction_type != null ? draft.correction_type : ''
		} else {
			this.IOP = ''
			this.IOPc = ''
			this.correction_type = ''
		}

		this.hasImages = false
	}

	// 20.04.2022 1 decimal with mmHg unit
	public getIOP() {
		let ret = ' '
		if (this.IOP != null && this.IOP != '') {
			ret = '' + Number(this.IOP).toFixed(1) + Exam.TOR_SYMBOL
		}
		return ret
	}

	public getIOPc() {
		let ret = ' '
		if (this.IOPc != null && this.IOPc != '') {
			ret = '' + Number(this.IOPc).toFixed(1) + Exam.TOR_SYMBOL
		}
		return ret
	}

	static getFakeExam(draft?) {
		var fakeExam = new TonoExam(draft)

		//fakeExam.device = "VX120";

		if (fakeExam.eye == LEFT) {
			// 19.08.2020 differenziati i valori
			fakeExam.IOP = '14.0'
			fakeExam.IOPc = '16.3'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.IOP = '15.5'
			fakeExam.IOPc = '17.3'
		}

		fakeExam.correction_type = 'Custom' //senza il post da errore

		return fakeExam
	}

	static createTonoExam(draft, cryptoUtils, keyPhoto): Promise<TonoExam> {
		var result = new TonoExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			console.log('tonoExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.IOP = draft.IOP
			bag.IOPc = draft.IOPc
			//bag.correction_type = draft.correction_type; // 07.10.2022 no, e' in chiaro

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('tonoExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// unica nuova funzione che gestisce entrambi i formati, nuovo e vecchio
			// misto dati/immagini, entrambi i formati
			return cryptoUtils
				.decryptAll(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						if (myBag.IOP) {
							// 07.10.2022
							result.IOP = myBag.IOP
						}
						if (myBag.IOPc) {
							// 07.10.2022
							result.IOPc = myBag.IOPc
						}
						// 28.12.2021
						if (result.IOP.trim() != '' || result.IOPc.trim() != '') {
							result.hasNumericData = true
						}

						return result
					} else {
						console.log('tonoExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('tonoExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

export class FRExam extends Exam {
	pupil_size_day: string
	pupil_real_size_day: string
	sphere_day: string
	cylinder_day: string
	axis_day: string
	vertex_distance: string

	pupil_real_size_night: string
	pupil_size_night: string
	sphere_night: string
	cylinder_night: string
	axis_night: string

	K1_radius: string
	K1_axis: string
	K2_radius: string
	K2_axis: string

	CYL_power: string
	CYL_axis: string

	AVG: number

	image_sh: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'fast_refraction'

		this.pupil_size_day = ''
		this.pupil_real_size_day = ''
		this.sphere_day = ''
		this.cylinder_day = ''
		this.axis_day = ''
		this.vertex_distance = ''

		this.pupil_real_size_night = ''
		this.pupil_size_night = ''
		this.sphere_night = ''
		this.cylinder_night = ''
		this.axis_night = ''

		this.K1_radius = ''
		this.K1_axis = ''
		this.K2_radius = ''
		this.K2_axis = ''

		this.CYL_power = ''
		this.CYL_axis = ''

		this.AVG = 0

		this.image_sh = null

		if (draft) {
			var myJsonObj = { ...draft }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}

	getExamLabel() {
		return 'FASTR'
	}

	private getParseNum(num) {
		let val = 0

		if (num) {
			// 15.09.2022 patch - fix
			val = parseFloat(num.replace(',', '.'))
		} else {
			num = ''
		}

		let ret = val && val != 0 ? '' + Number(val).toFixed(2) + Exam.MM_SYMBOL : '' + num
		return ret
	}

	public getRealPupilSizeDay() {
		return this.getParseNum(this.pupil_real_size_day)
	}

	public getRealPupilSizeNight() {
		return this.getParseNum(this.pupil_real_size_night)
	}

	public getPupilDay() {
		return this.getParseNum(this.pupil_size_day)
	}

	public getPupilNight() {
		return this.getParseNum(this.pupil_size_night)
	}

	public getSphereDay() {
		return Exam.getMySphere(this.sphere_day)
	}

	public getSphereNight() {
		return Exam.getMySphere(this.sphere_night)
	}

	public getCylDay() {
		return Exam.getMyCylinder(this.cylinder_day)
	}

	public getCylNight() {
		return Exam.getMyCylinder(this.cylinder_night)
	}

	public getAxisDay() {
		return Exam.getMyAxis(this.axis_day)
	}

	public getAxisNight() {
		return Exam.getMyAxis(this.axis_night)
	}

	public getVertex() {
		return this.getParseNum(this.vertex_distance)
	}

	private getK(type: number) {
		// radius, r_unit, ax, ax_unit){

		let radius: number
		let r_unit = 'mm'
		let ax: number
		let ax_unit = '°'

		if (type == 1) {
			radius = parseFloat(this.K1_radius.replace(',', '.'))
			ax = parseFloat(this.K1_axis.replace(',', '.'))
		} else if (type == 2) {
			radius = parseFloat(this.K2_radius.replace(',', '.'))
			ax = parseFloat(this.K2_axis.replace(',', '.'))
		}

		var ret = ''
		let radiusMM = ''
		let radiusD = ''
		let axis = ''

		if (radius != null && r_unit == 'mm') {
			radiusMM = '(' + Number(radius).toFixed(2) + ' ' + r_unit + ')'
		}

		// portata fuori
		axis = this.getDegrees(ax, ax_unit)

		if (r_unit == 'mm' && radius != null) {
			radiusD = this.getDiopter(radius) + Exam.D_SYMBOL
		} else {
			radiusD = radius + ' ' + r_unit
		}

		ret = radiusD + ' ' + radiusMM + ' ' + axis

		return ret
	}

	private getDegrees(val, unit) {
		let ret = ''
		if (val != null) {
			let deg = ''
			if (unit == 'deg') {
				deg = Exam.DEG_SYMBOL
			} else {
				deg = ' ' + unit
			}
			ret = '@' + val + deg
		}
		return ret
	}

	getDiopter(mmValue: number) {
		let diopt = ''
		if (mmValue != 0) {
			let tmp = 337.5 / mmValue
			diopt = Number(tmp).toFixed(2)
		}
		return diopt
	}

	getK1() {
		return this.getK(1)
	}

	getK2() {
		return this.getK(2)
	}

	getCYL() {
		var ret = ''
		if (this.CYL_power) {
			let step = Exam.roundStep(this.CYL_power)
			ret = step + ' ' + 'D' + ' ' + this.getDegrees(this.CYL_axis, '°')
		}
		return ret
	}

	getAVG() {
		var ret = ''
		if (this.AVG != null) {
			// 20.04.2022 entrambe le misure
			let myAVG_D = ''
			let myAVGmm = ''

			// if (this.K1_r_unit == 'mm') {
			myAVG_D = this.getDiopter(this.AVG) + Exam.D_SYMBOL
			myAVGmm = ' (' + this.AVG.toFixed(2) + ' ' + 'mm' + ')'
			// } else {
			// 	myAVG_D = this.AVG.toFixed(2) + ' ' + this.K1_r_unit
			// }
			ret = myAVG_D + ' ' + myAVGmm
		}

		return ret
	}

	static getFakeExam(fakeFastRefractionExams, draft?) {
		var fakeExam = new FRExam(draft)

		// if (fakeExam.eye == LEFT) {
		for (const prop in fakeFastRefractionExams) {
			if (fakeFastRefractionExams[prop].set) {
				fakeExam[prop] = fakeFastRefractionExams[prop].value
			}
		}

		return fakeExam
	}

	static fullFastRefracionFieldsToDecript = [
		'pupil_size_day',
		'pupil_real_size_day',
		'sphere_day',
		'cylinder_day',
		'axis_day',
		'vertex_distance',
		'pupil_real_size_night',
		'pupil_size_night',
		'sphere_night',
		'cylinder_night',
		'axis_night',
		'K1_radius',
		'K1_axis',
		'K2_radius',
		'K2_axis',
		'CYL_power',
		'CYL_axis',
		'image_sh',
	]

	static createFRExam(draft, cryptoUtils: CryptoUtilsService, keyPhoto: forge.util.ByteStringBuffer): Promise<FRExam> {
		var result = new FRExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		if (keyPhoto == null) {
			Util.debug('FRExam - missing keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			for (let field of this.fullFastRefracionFieldsToDecript) {
				if (draft[field]) {
					bag[field] = draft[field]
				}
			}
			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				Util.debug('FRExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			let data: postMessageToWorker = new postMessageToWorker()
			data.type = workerFunctionType.decryptDataWithKey
			data.key = keyPhoto
			data.dataToDecrypt = bag
			data.dataType = dataType.CryptoBag
			data.hasImages = true
			data.imagesFields = ['image_sh']

			return cryptoUtils
				.decryptDataWithKeyWorker(data)
				.then((myBag) => {
					// console.log(myBag)
					if (myBag != null) {
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]

								if (result.image_sh != null && result.device != 'ER') {
									result.hasImages = true
								}
							}
						}

						return result
					} else {
						console.log('pachyExam - null bag ris')
						return result
					}
				})
				.catch((err) => {
					console.log('pachyExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 31.07.2020
// Text data will be presented in the "Refraction" category,
// images will be shown in the "Cat/Glc" category.

export class WfExam extends Exam {
	image_grid: string
	image_meso: string

	// 12.10.2020 estensione dei campi
	pupil_real_size_day: string
	pupil_size_day: string
	sphere_day: string
	cylinder_day: string
	axis_day: string
	vertex_distance: string

	pupil_real_size_night: string
	pupil_size_night: string
	sphere_night: string
	cylinder_night: string
	axis_night: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'wf'

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		//this.image_grid = "";
		this.image_grid = null
		this.image_meso = null

		if (draft != null) {
			var myJsonObj = { ...draft }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			// 30.09.2022 PATCH, il quickAssign sovrascrive exam_date!  - vd altri punti cambio nome var con camelCase [ls]
			this.exam_date = DateParser.parseDate(draft.exam_date)
		} else {
			this.pupil_real_size_day = ''
			this.pupil_size_day = ''
			this.sphere_day = ''
			this.cylinder_day = ''
			this.axis_day = ''

			this.pupil_real_size_night = ''
			this.pupil_size_night = ''
			this.sphere_night = ''
			this.cylinder_night = ''
			this.axis_night = ''
		}
	}

	// 14.09.2021 ok WF per quasi tutti, se device = "VX90" o "VX65", diventa ARK
	getExamLabel() {
		var lab = 'WF' // this.filter('translate')('EXAMS.WF');
		if (this.device != null && (this.device == 'VX65' || this.device == 'VX90')) {
			lab = 'ARK' // this.filter('translate')('EXAMS.ARK');
		}
		return lab
	}

	// 05.09.2022 renamed in aperture, pupil is the "real" value
	// 20.04.2022 mm with 2 decimals
	private getParseNum(num) {
		let val = 0

		if (num) {
			// 15.09.2022 patch - fix
			val = parseFloat(num.replace(',', '.'))
		} else {
			num = ''
		}

		let ret = val && val != 0 ? '' + Number(val).toFixed(2) + Exam.MM_SYMBOL : '' + num
		return ret
	}

	public getRealPupilSizeDay() {
		return this.getParseNum(this.pupil_real_size_day)
	}

	public getRealPupilSizeNight() {
		return this.getParseNum(this.pupil_real_size_night)
	}

	public getPupilDay() {
		return this.getParseNum(this.pupil_size_day)
	}

	public getPupilNight() {
		return this.getParseNum(this.pupil_size_night)
	}

	// 20.04.2022 DS with 2 decimals, steps 0.25
	private getSphere(type) {
		let num = type == 'day' ? this.sphere_day : this.sphere_night
		return Exam.getMySphere(num) // su Exam
	}

	public getSphereDay() {
		return this.getSphere('day')
	}

	public getSphereNight() {
		return this.getSphere('night')
	}

	// 20.04.2022 DC with 2 decimals, steps 0.25
	private getCylinder(type) {
		let num = type == 'day' ? this.cylinder_day : this.cylinder_night
		return Exam.getMyCylinder(num) // su Exam
	}

	public getCylDay() {
		return this.getCylinder('day')
	}

	public getCylNight() {
		return this.getCylinder('night')
	}

	// 20.04.2022 degrees - no decimals
	private getAxis(type) {
		let num = type == 'day' ? this.axis_day : this.axis_night
		return Exam.getMyAxis(num)
	}

	public getAxisDay() {
		return this.getAxis('day')
	}

	public getAxisNight() {
		return this.getAxis('night')
	}

	public getVertex() {
		return this.getParseNum(this.vertex_distance)
	}

	static getFakeExam(draft?) {
		var fakeExam = new WfExam(draft)

		//fakeExam.device = "VX120";

		if (fakeExam.eye == LEFT) {
			// 19.08.2020 differenziati
			fakeExam.pupil_real_size_day = '2.96'
			fakeExam.sphere_day = '-4.36'
			fakeExam.cylinder_day = '-0.60'
			fakeExam.axis_day = '2.61'
			fakeExam.pupil_size_day = '3.0mm'
			fakeExam.vertex_distance = '12'

			fakeExam.pupil_real_size_night = '3.36'
			fakeExam.sphere_night = '-4.00'
			fakeExam.cylinder_night = '-0.62'
			fakeExam.axis_night = '2.63'
			fakeExam.pupil_size_night = '5.0mm'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.pupil_real_size_day = '2.94'
			fakeExam.sphere_day = '-4.11'
			fakeExam.cylinder_day = '-0.29'
			fakeExam.axis_day = '2.62'
			fakeExam.pupil_size_day = '3.1mm'
			fakeExam.vertex_distance = '13'

			fakeExam.pupil_real_size_night = '3.34'
			fakeExam.sphere_night = '-4.10'
			fakeExam.cylinder_night = '-0.65'
			fakeExam.axis_night = '2.64'
			fakeExam.pupil_size_night = '5.1mm'
		}

		return fakeExam
	}

	static createWfExam(draft, cryptoUtils, keyPhoto): Promise<WfExam> {
		var result = new WfExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			Util.debug('wfExam - missing keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.image_grid = draft.image_grid
			bag.image_meso = draft.image_meso

			bag.pupil_real_size_day = draft.pupil_real_size_day
			bag.pupil_size_day = draft.pupil_size_day
			bag.sphere_day = draft.sphere_day
			bag.cylinder_day = draft.cylinder_day
			bag.axis_day = draft.axis_day
			bag.vertex_distance = draft.vertex_distance

			bag.pupil_real_size_night = draft.pupil_real_size_night
			bag.pupil_size_night = draft.pupil_size_night
			bag.sphere_night = draft.sphere_night
			bag.cylinder_night = draft.cylinder_night
			bag.axis_night = draft.axis_night

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				Util.debug('wfExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// unica nuova funzione che gestisce entrambi i formati, nuovo e vecchio
			// misto dati/immagini, entrambi i formati
			return cryptoUtils
				.decryptAll(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]
							}
						}

						// 18.10.2022 da ER arriva anche valore vuoto " " - fix bug 224
						// 28.12.2021 serve per togliere WF da GLC, se arriva da ER, senza immagine
						//if (result.image_grid != null) {
						if (result.image_grid != null && result.device != 'ER') {
							result.hasImages = true
						}

						return result
					} else {
						Util.debug('wfExam - null bag ris')
					}
				})
				.catch((err) => {
					Util.debug('wfExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 21.08.2020 2 immagini e dati, esposti su Glaucoma (solo una delle due img)
export class RetroExam extends Exam {
	image_low: string
	image_high: string

	// 01.09.2020 3 campi dati da eliminare, non vengono esposti
	cortical: string
	nuclear: string
	posterior: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'retro'

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		//this.image_low = "";
		this.image_low = null
		this.image_high = null

		if (draft != null) {
			this.cortical = draft.cortical != null ? draft.cortical : ''
			this.nuclear = draft.nuclear != null ? draft.nuclear : ''
			this.posterior = draft.posterior != null ? draft.posterior : ''
		} else {
			this.cortical = ''
			this.nuclear = ''
			this.posterior = ''
		}
	}

	static getFakeExam(draft?) {
		var fakeExam = new RetroExam(draft)

		//fakeExam.device = "VX600";

		if (fakeExam.eye == LEFT) {
			// differenziati i valori
			fakeExam.cortical = '3'
			fakeExam.nuclear = '2'
			fakeExam.posterior = '1'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.cortical = '1'
			fakeExam.nuclear = '2'
			fakeExam.posterior = '3'
		}

		return fakeExam
	}

	static createRetroExam(draft, cryptoUtils, keyPhoto): Promise<RetroExam> {
		var result = new RetroExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			console.log('retroExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.image_low = draft.image_low
			bag.image_high = draft.image_high

			bag.cortical = draft.cortical
			bag.nuclear = draft.nuclear
			bag.posterior = draft.posterior

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				Util.debug('retroExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// unica nuova funzione che gestisce entrambi i formati, nuovo e vecchio
			// misto dati/immagini, entrambi i formati
			return cryptoUtils
				.decryptAll(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]
								if (prop.indexOf('image_low') == 0 || prop.indexOf('image_high') == 0) {
									result.hasImages = true
								}
							}
						}

						/*
          result.image_low = myBag.image_low;
          result.image_high = myBag.image_high;
          result.cortical   = myBag.cortical;
          result.nuclear = myBag.nuclear;
          result.posterior = myBag.posterior;
          */

						return result
					} else {
						Util.debug('retroExam - null bag ris')
					}
				})
				.catch((err) => {
					Util.debug('retroExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 24.08.2022 extra for fundus
// extra info for fundus & AI
export class FundusExtra {
	id: number
	fixation: string
	quality: number
	//cdr: number;

	constructor(rawObj?) {
		this.id = 0
		this.fixation = ''
		this.quality = 0

		if (rawObj != null) {
			let myJsonObj = { ...rawObj } // copio campi con stesso nome
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}

		if (!this.quality) {
			this.quality = 0
		}
	}
}

// 10.09.2020 Fundus - Retinas

export class FundusExam extends Exam {
	//mosaic: string; // TODO

	CDR: string
	vCDR: string
	//optic_disk_area: string;
	//artery_vein: string;

	image_central: string
	image_nasal: string
	image_supero_nasal: string
	image_supero_temporal: string
	image_temporal: string
	image_inferior: string
	image_external: string
	image_central_nasal: string

	hasCDR: boolean // 12.10.2021
	extras: FundusExtra[] // 24.08.2022

	constructor(draft?) {
		super(draft)

		this.exam_type = Config.EXM_FUNDUS // "fundus";

		this.CDR = ''
		this.vCDR = ''
		this.hasCDR = false
		//this.optic_disk_area = "";
		//this.artery_vein = "";

		// 12.10.2020 meglio init a null, altrim. se poi manca la considera cmq vuota [ls]
		/*
    this.image_central = "";
    this.image_nasal = "";
    this.image_supero_nasal = "";
    this.image_supero_temporal = "";
    this.image_temporal = "";
    this.image_inferior = "";
    this.image_external = "";
    this.image_central_nasal = "";
    */
		this.image_central = null
		this.image_nasal = null
		this.image_supero_nasal = null
		this.image_supero_temporal = null
		this.image_temporal = null
		this.image_inferior = null
		this.image_external = null
		this.image_central_nasal = null

		this.extras = null

		// array di extras
		if (draft && draft.extra) {
			this.extras = []
			//console.log(draft.extra);
			for (let i = 0; i < draft.extra.length; i++) {
				let myExtra = new FundusExtra(draft.extra[i])
				this.extras.push(myExtra)
				Util.debug(i + ' fundus extra: ' + myExtra.id + ', ' + myExtra.fixation + ', QA: ' + myExtra.quality)
			}
		}
	}

	// 24.08.2022
	getExtraByFixation(myFix) {
		let myExtra: FundusExtra
		myExtra = null

		if (this.extras) {
			for (let i = 0; i < this.extras.length; i++) {
				if (this.extras[i].fixation == myFix) {
					myExtra = this.extras[i]
				}
			}
		}

		if (myExtra) {
			Util.debug('(getExtra) fundus imgid: ' + myExtra.id + ', ' + myExtra.fixation + ', QA: ' + myExtra.quality)
		}

		return myExtra
	}

	// 06.05.2022 2 decimali, no unit
	getCDR(defaultVal: string) {
		if (!defaultVal) {
			// 14.06.2022 valore di default se manca
			defaultVal = ''
		}
		let val = parseFloat(this.CDR.replace(',', '.'))
		let ret = val && val != 0 ? '' + Number(val).toFixed(2) : defaultVal
		return ret
	}

	// 14.06.2022
	getVertCDR(defaultVal: string) {
		if (!defaultVal) {
			// 14.06.2022 valore di default se manca
			defaultVal = ''
		}
		let val = parseFloat(this.vCDR.replace(',', '.'))
		let ret = val && val != 0 ? '' + Number(val).toFixed(2) : defaultVal
		return ret
	}

	static getFakeExam(draft?) {
		var fakeExam = new FundusExam(draft)

		if (fakeExam.eye == LEFT) {
			// 19.08.2020 differenziati
			fakeExam.CDR = '0.84'
			fakeExam.vCDR = '0.30'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.CDR = '0.85'
			fakeExam.vCDR = '0.26'
		}

		return fakeExam
	}

	static createFundusExam(draft, cryptoUtils, keyPhoto): Promise<FundusExam> {
		var result = new FundusExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			Util.debug('fundusExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.CDR = draft.CDR
			bag.vCDR = draft.vCDR
			//bag.optic_disk_area =	  draft.optic_disk_area;
			//bag.artery_vein =		    draft.artery_vein;

			bag.image_central = draft.image_central
			bag.image_nasal = draft.image_nasal
			bag.image_supero_nasal = draft.image_supero_nasal
			bag.image_supero_temporal = draft.image_supero_temporal
			bag.image_temporal = draft.image_temporal
			bag.image_inferior = draft.image_inferior
			bag.image_external = draft.image_external
			bag.image_central_nasal = draft.image_central_nasal

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				Util.debug('fundusExam - no images')
				return Promise.resolve(result)
			}

			return cryptoUtils
				.decryptAll(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]

								if (prop.indexOf('image') == 0) {
									result.hasImages = true
								}
							}
						}

						// 12.10.2021
						if (result.CDR != null && result.CDR != '0') {
							result.hasCDR = true
						}
						if (result.vCDR != null && result.vCDR != '0') {
							result.hasCDR = true
						}

						// 28.12.2021
						if (result.hasCDR) {
							result.hasNumericData = true
						}

						return result
					} else {
						console.log('fundusExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('fundusExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 05.10.2020 solo dati numerici, no immagini
export class SubjectiveExam extends Exam {
	sphere: string
	cylinder: string
	axis: string

	prism_h: string
	base_h: string
	prism_v: string
	base_v: string
	add: string

	DVA_LM: string
	NVA_LM: string
	DVA_WF: string
	NVA_WF: string
	DVA_REF: string
	NVA_REF: string

	PDm: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'subjective'

		if (draft != null) {
			this.sphere = draft.sphere != null ? draft.sphere : ''
			this.cylinder = draft.cylinder != null ? draft.cylinder : ''
			this.axis = draft.axis != null ? draft.axis : ''
			this.prism_h = draft.prism_h != null ? draft.prism_h : ''
			this.base_h = draft.base_h != null ? draft.base_h : ''
			this.prism_v = draft.prism_v != null ? draft.prism_v : ''
			this.base_v = draft.base_v != null ? draft.base_v : ''
			this.add = draft.add != null ? draft.add : ''

			this.DVA_LM = draft.DVA_LM != null ? draft.DVA_LM : ''
			this.NVA_LM = draft.NVA_LM != null ? draft.NVA_LM : ''
			this.DVA_WF = draft.DVA_WF != null ? draft.DVA_WF : ''
			this.NVA_WF = draft.NVA_WF != null ? draft.NVA_WF : ''
			this.DVA_REF = draft.DVA_REF != null ? draft.DVA_REF : ''
			this.NVA_REF = draft.NVA_REF != null ? draft.NVA_REF : ''

			this.PDm = draft.PDm != null ? draft.PDm : ''
		} else {
			this.sphere = ''
			this.cylinder = ''
			this.axis = ''
			this.prism_h = ''
			this.base_h = ''
			this.prism_v = ''
			this.base_v = ''
			this.add = ''
			this.DVA_LM = ''
			this.NVA_LM = ''
			this.DVA_WF = ''
			this.NVA_WF = ''
			this.DVA_REF = ''
			this.NVA_REF = ''
			this.PDm = ''
		}

		this.hasImages = false
	}

	// 20.04.2022 DS with 2 decimals, steps 0.25
	getSphere() {
		let ret = Exam.getMySphere(this.sphere) // su Exam
		return ret
	}

	// 20.04.2022
	getCylinder() {
		let ret = Exam.getMyCylinder(this.cylinder) // su Exam
		return ret
	}

	// 20.04.2022
	getAxis() {
		let ret = Exam.getMyAxis(this.axis) // su Exam
		return ret
	}

	// distance visual acuity
	public getDVA(param?) {
		var ret = ''

		if (param && param == 'WF') {
			ret = this.DVA_WF
		} else if (param && param == 'LM') {
			ret = this.DVA_LM
		} else {
			ret = this.DVA_REF // refraction
		}

		return ret
	}

	// near visual acuity
	public getNVA(param?) {
		var ret = ''

		if (param && param == 'WF') {
			ret = this.NVA_WF
		} else if (param && param == 'LM') {
			ret = this.NVA_LM
		} else {
			ret = this.NVA_REF // refraction
		}

		return ret
	}

	static getFakeExam(draft?) {
		var fakeExam = new SubjectiveExam(draft)

		//fakeExam.device = "ER";

		if (fakeExam.eye == LEFT) {
			// differenziati i valori
			fakeExam.sphere = '+3,30'
			fakeExam.cylinder = '-0.6'
			fakeExam.axis = '0'
			fakeExam.prism_h = '5'
			fakeExam.base_h = '6'
			fakeExam.prism_v = '7'
			fakeExam.base_v = '7'
			fakeExam.add = '6'
			fakeExam.DVA_LM = '6/6'
			fakeExam.NVA_LM = '6/7'
			fakeExam.DVA_WF = '5/6'
			fakeExam.NVA_WF = '6/6'
			fakeExam.DVA_REF = '6/7.5'
			fakeExam.NVA_REF = '6/7'
			fakeExam.PDm = '31'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.sphere = '-1,6'
			fakeExam.cylinder = '+2.2'
			fakeExam.axis = '160'
			fakeExam.prism_h = '5'
			fakeExam.base_h = '5'
			fakeExam.prism_v = '4'
			fakeExam.base_v = '4'
			fakeExam.add = '5'
			fakeExam.DVA_LM = '5/5'
			fakeExam.NVA_LM = '5/6'
			fakeExam.DVA_WF = '6/6'
			fakeExam.NVA_WF = '6/7'
			fakeExam.DVA_REF = '4/6'
			fakeExam.NVA_REF = '5/6'
			fakeExam.PDm = '30'
		} else if (fakeExam.eye == BINO) {
			// 08.09.2021

			// altri campi non valorizzati
			//fakeExam.add = "4";
			fakeExam.DVA_LM = '3/3'
			fakeExam.NVA_LM = '3/4'

			fakeExam.DVA_WF = '4/6'
			fakeExam.NVA_WF = '2/6'

			fakeExam.DVA_REF = '5/7.5'
			fakeExam.NVA_REF = '3/7'

			fakeExam.PDm = '66'
		}

		return fakeExam
	}

	static createSubjectiveExam(draft, cryptoUtils, keyPhoto): Promise<SubjectiveExam> {
		var result = new SubjectiveExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			console.log('subjectiveExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.sphere = draft.sphere
			bag.cylinder = draft.cylinder
			bag.axis = draft.axis
			bag.prism_h = draft.prism_h
			bag.base_h = draft.base_h
			bag.prism_v = draft.prism_v
			bag.base_v = draft.base_v
			bag.add = draft.add
			bag.DVA_LM = draft.DVA_LM
			bag.NVA_LM = draft.NVA_LM
			bag.DVA_WF = draft.DVA_WF
			bag.NVA_WF = draft.NVA_WF
			bag.DVA_REF = draft.DVA_REF
			bag.NVA_REF = draft.NVA_REF
			bag.PDm = draft.PDm

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('subjectiveExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// qui non ci sono immagini
			//return cryptoUtils.decryptAll(keyPhoto, bag)
			return cryptoUtils
				.decryptDataWithKey(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]
							}
						}

						return result
					} else {
						console.log('subjectiveExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('subjectiveExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 07.10.2020 solo dati numerici, no immagini
export class LensmeterExam extends Exam {
	sphere: string
	cylinder: string
	axis: string

	prism_h: string
	base_h: string
	prism_v: string
	base_v: string
	add: string
	PDm: string

	constructor(draft?) {
		super(draft)

		this.exam_type = 'lensmeter'

		if (draft != null) {
			this.sphere = draft.sphere != null ? draft.sphere : ''
			this.cylinder = draft.cylinder != null ? draft.cylinder : ''
			this.axis = draft.axis != null ? draft.axis : ''
			this.prism_h = draft.prism_h != null ? draft.prism_h : ''
			this.base_h = draft.base_h != null ? draft.base_h : ''
			this.prism_v = draft.prism_v != null ? draft.prism_v : ''
			this.base_v = draft.base_v != null ? draft.base_v : ''
			this.add = draft.add != null ? draft.add : ''
			this.PDm = draft.PDm != null ? draft.PDm : ''
		} else {
			this.sphere = ''
			this.cylinder = ''
			this.axis = ''
			this.prism_h = ''
			this.base_h = ''
			this.prism_v = ''
			this.base_v = ''
			this.add = ''
			this.PDm = ''
		}

		this.hasImages = false
	}

	// 20.04.2022 DS with 2 decimals, steps 0.25
	getSphere() {
		let ret = Exam.getMySphere(this.sphere) // su Exam
		return ret
	}

	// 20.04.2022
	getCylinder() {
		let ret = Exam.getMyCylinder(this.cylinder) // su Exam
		return ret
	}

	// 20.04.2022
	getAxis() {
		let ret = Exam.getMyAxis(this.axis) // su Exam
		return ret
	}

	static getFakeExam(draft?) {
		var fakeExam = new LensmeterExam(draft)

		if (fakeExam.eye == LEFT) {
			// differenziati i valori
			fakeExam.sphere = '+3,25'
			fakeExam.cylinder = '-0.5'
			fakeExam.axis = '0'
			fakeExam.prism_h = '6'
			fakeExam.base_h = '6'
			fakeExam.prism_v = '7'
			fakeExam.base_v = '7'
			fakeExam.add = '6'
			fakeExam.PDm = '30'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.sphere = '-1,5'
			fakeExam.cylinder = '+2.3'
			fakeExam.axis = '160'
			fakeExam.prism_h = '5'
			fakeExam.base_h = '5'
			fakeExam.prism_v = '4'
			fakeExam.base_v = '4'
			fakeExam.add = '5'
			fakeExam.PDm = '31'
		} else if (fakeExam.eye == BINO) {
			fakeExam.PDm = '62'
		}

		return fakeExam
	}

	static createLensmeterExam(draft, cryptoUtils, keyPhoto): Promise<LensmeterExam> {
		var result = new LensmeterExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			console.log('lensmeterExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.sphere = draft.sphere
			bag.cylinder = draft.cylinder
			bag.axis = draft.axis
			bag.prism_h = draft.prism_h
			bag.base_h = draft.base_h
			bag.prism_v = draft.prism_v
			bag.base_v = draft.base_v
			bag.add = draft.add
			bag.PDm = draft.PDm

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('lensmeterExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// qui non ci sono immagini
			//return cryptoUtils.decryptAll(keyPhoto, bag)
			return cryptoUtils
				.decryptDataWithKey(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]
							}
						}

						return result
					} else {
						console.log('lensmeterExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('lensmeterExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 09.11.2020 dati e una immagine (grafico)
export class PupilExam extends Exam {
	scotopic_pupil: string
	scotopic_dec_x: string
	scotopic_dec_y: string
	scotopic_k: string

	low_pupil: string
	low_dec_x: string
	low_dec_y: string
	low_k: string

	mesopic_pupil: string
	mesopic_dec_x: string
	mesopic_dec_y: string
	mesopic_k: string

	photopic_pupil: string
	photopic_dec_x: string
	photopic_dec_y: string
	photopic_k: string

	graph: string // immagine

	constructor(draft?) {
		super(draft)

		this.exam_type = 'pupil'

		if (draft != null) {
			this.scotopic_pupil = draft.scotopic_pupil != null ? draft.scotopic_pupil : ''
			this.scotopic_dec_x = draft.scotopic_dec_x != null ? draft.scotopic_dec_x : ''
			this.scotopic_dec_y = draft.scotopic_dec_y != null ? draft.scotopic_dec_y : ''
			this.scotopic_k = draft.scotopic_k != null ? draft.scotopic_k : ''
			this.low_pupil = draft.low_pupil != null ? draft.low_pupil : ''
			this.low_dec_x = draft.low_dec_x != null ? draft.low_dec_x : ''
			this.low_dec_y = draft.low_dec_y != null ? draft.low_dec_y : ''
			this.low_k = draft.low_k != null ? draft.low_k : ''
			this.mesopic_pupil = draft.mesopic_pupil != null ? draft.mesopic_pupil : ''
			this.mesopic_dec_x = draft.mesopic_dec_x != null ? draft.mesopic_dec_x : ''
			this.mesopic_dec_y = draft.mesopic_dec_y != null ? draft.mesopic_dec_y : ''
			this.mesopic_k = draft.mesopic_k != null ? draft.mesopic_k : ''
			this.photopic_pupil = draft.photopic_pupil != null ? draft.photopic_pupil : ''
			this.photopic_dec_x = draft.photopic_dec_x != null ? draft.photopic_dec_x : ''
			this.photopic_dec_y = draft.photopic_dec_y != null ? draft.photopic_dec_y : ''
			this.photopic_k = draft.photopic_k != null ? draft.photopic_k : ''
		} else {
			this.scotopic_pupil = ''
			this.scotopic_dec_x = ''
			this.scotopic_dec_y = ''
			this.scotopic_k = ''
			this.low_pupil = ''
			this.low_dec_x = ''
			this.low_dec_y = ''
			this.low_k = ''
			this.mesopic_pupil = ''
			this.mesopic_dec_x = ''
			this.mesopic_dec_y = ''
			this.mesopic_k = ''
			this.photopic_pupil = ''
			this.photopic_dec_x = ''
			this.photopic_dec_y = ''
			this.photopic_k = ''
			this.graph = ''
		}
	}

	static getFakeExam(draft?) {
		var fakeExam = new PupilExam(draft)

		//fakeExam.device preso dalla visita

		if (fakeExam.eye == LEFT) {
			// differenziati i valori

			fakeExam.scotopic_pupil = '5.4 mm'
			fakeExam.scotopic_dec_x = '-0.21'
			fakeExam.scotopic_dec_y = '-0.13'
			fakeExam.scotopic_k = '4.1'
			fakeExam.low_pupil = '3.4 mm'
			fakeExam.low_dec_x = '-0.21'
			fakeExam.low_dec_y = '-0.24'
			fakeExam.low_k = '5.3'
			fakeExam.mesopic_pupil = '3.1 mm'
			fakeExam.mesopic_dec_x = '-0.21'
			fakeExam.mesopic_dec_y = '-0.27'
			fakeExam.mesopic_k = '5.6'
			fakeExam.photopic_pupil = '3.2 mm'
			fakeExam.photopic_dec_x = '-0.21'
			fakeExam.photopic_dec_y = '-0.29'
			fakeExam.photopic_k = '5.9'
		} else if (fakeExam.eye == RIGHT) {
			fakeExam.scotopic_pupil = '4.4 mm'
			fakeExam.scotopic_dec_x = '-0.22'
			fakeExam.scotopic_dec_y = '-0.14'
			fakeExam.scotopic_k = '4.1'
			fakeExam.low_pupil = '4.4 mm'
			fakeExam.low_dec_x = '-0.21'
			fakeExam.low_dec_y = '-0.24'
			fakeExam.low_k = '6.3'
			fakeExam.mesopic_pupil = '2.1 mm'
			fakeExam.mesopic_dec_x = '-0.21'
			fakeExam.mesopic_dec_y = '-0.27'
			fakeExam.mesopic_k = '4.6'
			fakeExam.photopic_pupil = '2.2 mm'
			fakeExam.photopic_dec_x = '-0.22'
			fakeExam.photopic_dec_y = '-0.28'
			fakeExam.photopic_k = '6.9'
		}

		return fakeExam
	}

	static createPupilExam(draft, cryptoUtils, keyPhoto): Promise<PupilExam> {
		var result = new PupilExam(draft)

		if (!draft) {
			return Promise.resolve(result)
		}

		// campi crittati

		if (keyPhoto == null) {
			console.log('pupilExam - manca keyPhoto')
			return Promise.resolve(result)
		} else {
			var bag = cryptoUtils.generateBag()

			bag.scotopic_pupil = draft.scotopic_pupil
			bag.scotopic_dec_x = draft.scotopic_dec_x
			bag.scotopic_dec_y = draft.scotopic_dec_y
			bag.scotopic_k = draft.scotopic_k
			bag.low_pupil = draft.low_pupil
			bag.low_dec_x = draft.low_dec_x
			bag.low_dec_y = draft.low_dec_y
			bag.low_k = draft.low_k
			bag.mesopic_pupil = draft.mesopic_pupil
			bag.mesopic_dec_x = draft.mesopic_dec_x
			bag.mesopic_dec_y = draft.mesopic_dec_y
			bag.mesopic_k = draft.mesopic_k
			bag.photopic_pupil = draft.photopic_pupil
			bag.photopic_dec_x = draft.photopic_dec_x
			bag.photopic_dec_y = draft.photopic_dec_y
			bag.photopic_k = draft.photopic_k

			bag.graph = draft.graph // immagine

			cryptoUtils.purge(bag)

			if (Util.isEmptyObj(bag)) {
				console.log('pupilExam - empty bag to decrypt!')
				return Promise.resolve(result)
			}

			// qui ci sono dati ed immagini
			//return cryptoUtils.decryptDataWithKey(keyPhoto, bag)
			return cryptoUtils
				.decryptAll(keyPhoto, bag)
				.then((myBag) => {
					if (myBag != null) {
						// 09.10.2020 ciclo senza enumerarle tutte [ls]
						for (var prop in myBag) {
							if (myBag.hasOwnProperty(prop)) {
								result[prop] = myBag[prop]
							}
						}

						return result
					} else {
						console.log('pupilExam - null bag ris')
					}
				})
				.catch((err) => {
					console.log('pupilExam ERR: ' + err)
					return Promise.reject(result)
				})
		}
	}
}

// 24.04.2020 ripristinata, con campi diversi
// serve per array di foto, su modulo album, trasversale agli esami
export class ExamImage {
	eye: string
	image: string
	descr: string
	date: Date
	type: string // 07.07.2020 aggiunto, serve in fase di scelta immag nel report [ls]

	selected: boolean // 28.05.2020
	examId: number
	position: number // 28.09.2020 serve per abbinare con le scelte del refertatore

	quality: number // 24.08.2022 per AI
	imgId: number

	constructor(draft?) {
		this.quality = 0 // valori ok da 1 a 3, 4 - 6 ko
		this.imgId = 0
		this.selected = true // default
		this.eye = ''
		this.image = ''
		this.descr = ''
		this.type = ''
		this.examId = 0
		this.position = -1 // undefined

		if (draft) {
			this.eye = draft.eye
			this.date = draft.date

			// 28.05.2020
			this.examId = draft.examId

			if (draft.selected) this.selected = draft.selected

			if (draft.type) this.type = draft.type

			if (draft.position && draft.position != -1) this.position = draft.position
		}
	}

	// 24.08.2022 da Vistel AI
	public setQuality(val: number) {
		this.quality = val
	}

	// 06.04.2022 per gestire categ con pachy_with_data
	// 11.11.2020 per test
	static getFakeImage() {
		var fakeImg = new ExamImage()
		fakeImg.date = new Date()
		fakeImg.image = b64images.logoTransparent
		fakeImg.descr = 'FAKE'
		return fakeImg
	}
}

export class ExamImage2 {
	eye: string
	image: string
	descr: string
	date: Date
	type: string
	selected: boolean
	examId: number
	position: number
	quality: number
	imgId: number

	constructor(draft?: Exam) {
		this.quality = 0 // valori ok da 1 a 3, 4 - 6 ko
		this.imgId = 0
		this.selected = true
		this.eye = ''
		this.image = ''
		this.descr = ''
		this.type = ''
		this.examId = 0
		this.position = -1 // undefined

		if (draft) {
			this.eye = draft.eye
			this.date = draft.exam_date
			this.type = draft.exam_type
			this.examId = draft.id
		}
	}

	// 24.08.2022 da Vistel AI
	public setQuality(val: number) {
		this.quality = val
	}

	// 06.04.2022 per gestire categ con pachy_with_data
	// 11.11.2020 per test
	static getFakeImage() {
		var fakeImg = new ExamImage2()
		fakeImg.date = new Date()
		fakeImg.image = b64images.logoTransparent
		fakeImg.descr = 'FAKE'
		return fakeImg
	}
}

// ***********************************************
// 22.11.2022 Additions table contains additional data for a specific exam
export class Addition {
	static idGen = 0
	id: number
	visit_id: number
	patient_id: number
	exam_id: number
	exam_type: string
	eye: string
	device: string
	name: string
	fixation: string
	image: string // campo crittato, arriva solo con get singola
	details: string // arriva solo con get singola, dato in chiaro

	creationDate: Date
	created_by: number

	public isFilter: boolean
	public isCDR: boolean

	constructor(draft?) {
		this.id = 0
		this.visit_id = 0
		this.patient_id = 0
		this.eye = ''
		this.device = ''
		this.exam_type = ''
		this.exam_id = 0
		this.name = ''
		this.details = ''
		this.isFilter = false
		this.isCDR = false
		this.creationDate = null
		this.created_by = 0

		if (draft) {
			let myJsonObj = { ...draft }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
			this.creationDate = DateParser.parseDate(draft.creation_date)

			// valorizza isFilter in base ai valori del campo name
			if (this.name) {
				if (this.name == 'Filters' || this.name == 'ImageTool') {
					this.isFilter = true
				} else if (this.name == 'CDR') {
					this.isCDR = true
				}
			}

			this.fixation = this.getFixation()
		}
	}

	static create(draftObj, cryptoUtils, keyPhoto) {
		let result = new Addition()

		if (!draftObj) {
			return Promise.resolve(result) // oggetto vuoto
		}

		result = new Addition(draftObj) // intanto senza decrypt

		if (!keyPhoto) {
			console.log('(createAddition) null keyPhoto!')
			return Promise.resolve(result)
		}

		let bag = cryptoUtils.generateBag()
		bag.image = draftObj.image

		cryptoUtils.purge(bag)

		if (Util.isEmptyObj(bag)) {
			Util.debug('addition - empty bag to decrypt!')
			return Promise.resolve(result)
		}

		return cryptoUtils
			.decryptImage(keyPhoto, bag)
			.then((myImg) => {
				if (myImg != null) {
					result.image = myImg // gia' l'immagine, non una bag
				}
				return result
			})
			.catch((err) => {
				return result
			})

		/*
    //return cryptoUtils.decryptAll(keyPhoto, bag) // bag mista dati/immagini, entrambi i formati
      .then((myBag) => {
        if (myBag != null) {  
          for (var prop in myBag) {
            if (myBag.hasOwnProperty(prop)) {
              result[prop] = myBag[prop]
            }
          }
        }
        return result;
      });
    */
	}

	private getFixation() {
		let fix = ''
		if (this.details && this.details.length > 0) {
			let obj = JSON.parse(this.details)
			//Util.debug(this.id+' addition - details:'+obj)
			if (obj.fixation) {
				fix = obj.fixation
			}
		}
		return fix
	}

	public getFilter() {
		let fix = ''
		if (this.details && this.details.length > 0) {
			//Util.debug(this.id+' addition - details:'+this.details)
			let obj = JSON.parse(this.details)
			if (obj.filters) {
				fix = obj.filters
			}
		}
		return fix
	}
}
