import { Injectable, EventEmitter } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'

import { Config } from '../../config'
import { CryptoUtilsService } from './crypto-utils.service'

import * as forge from 'node-forge' // per le keybox

// MODELS ******
import { Admin, AdminResponse, AdminsResponse } from '../models/admin.model'
import { Doctor, DoctorResponse, DoctorsResponse } from '../models/doctor.model'
import { Patient, PatientResponse, PatientsResponse } from '../models/patient.model'
import {
	Distrib,
	Relation,
	Specialist,
	RelationJson,
	DistributorResponse,
	DistributorsResponse,
	RelationsResponse,
	ClinicsResponse,
	ClinicsJson,
} from '../models/specialist.model'

import { Category } from '../models/category.model'
import { Addition, Exam, ExamType } from '../models/exam.model'
import { Visit, VisitsResponse } from '../models/visit.model'
import { Report, FullReport, ReportStatus } from '../models/report.model'
import { AiReport } from '../models/aiReport.model'
import { ICD, ICDenv, SrvDiagnosis } from '../models/diagnosis.model'
import { Device, SwUpdate } from '../models/device.model'
import { Util } from '../models/util.model'
import { Agreement } from '../models/agreement.model'
import { Anamnesis, VA } from '../models/anamnesis.model'
import { timeout } from 'rxjs/operators'
import { ChangeGraderFormQuestion } from '../models/changeGrader.model'
import { BehaviorSubject, Subject } from 'rxjs'
import { userLocation } from '../models/address.model'
import { LoaderStatus } from '../elements/loader-status/loader-status.component'
import { ImpactReportData, ImpactDates, ImpactHistoryData, ImpactRecommendationResponse, ImpactRecommendationRequest } from '../models/impact.model'
import { CategoriesDictionary } from './reports.service'

//import { StringDecoder } from "string_decoder";

export enum DataStatus {
	NOT_LOADED,
	LOADING,
	LOADED,
	LOAD_FAILED,
}

export enum Status {
	UNKNOWN = 'unknown',
	LOADING = 'loading',
	AVAILABLE = 'available',
	NOT_AVAILABLE = 'not_available',
}

@Injectable({
	providedIn: 'root',
})
export class DataModelService {
	//LOADER STATUS
	loaderStatusChanges: Subject<LoaderStatus>

	// DOCTORS
	doctorList: Doctor[]
	doctorListMap: Map<number, Doctor>
	doctorListStatus: DataStatus
	doctor: Doctor
	doctorStatus: DataStatus
	public doctoListChanged: Subject<Doctor[]>

	// PATIENTS
	patientList: Patient[]
	patientListStatus: DataStatus
	patientListStatusObservable: BehaviorSubject<DataStatus>
	patientListChanged: Subject<Patient[]>
	patient: Patient
	patientStatus: DataStatus
	patientListMap: Map<number, Patient>
	lastUpdtPatList: Date

	// VISITS
	visitList: Visit[] // 24.03.2020
	visitListStatus: DataStatus

	//
	// examList: ExamType[] // 15.04.2020 piu' chiaro, sono solo info basic senza immagini
	examListMap: Map<number, Exam[]> // number é id paziente
	examListStatus: DataStatus
	// exam: Exam // esame generico
	examStatus: DataStatus

	// 16.04.2020 uso oggetto con anche array di esami
	categories: Category[]

	// DISTRIBUTORS
	distrib: Distrib
	distribStatus: DataStatus
	distribList: Distrib[]
	distribListMap: Map<number, Distrib>
	distribListStatus: DataStatus
	distribListChanged: Subject<Distrib[]>

	// RELATIONS
	relsList: Relation[] // 03.05.2017
	relsListMap: Map<number, Relation>
	relsListStatus: DataStatus
	relation: Relation = null // 13.09.2019
	relationStatus: DataStatus
	graderId: number // 07.06.2023 il grader a cui si riferisce la lista rels

	adminList: Admin[] // 08.08.2018
	adminListStatus: DataStatus
	admin: Admin
	adminStatus: DataStatus

	// HG reports ************
	reportListMap: Map<number, { date: string | null; reports: Report[] }> // id paziente e array di tutti i suoi report date=toISOString
	reportList: Report[] // array di oggetti con info, non ci sono i pdf
	reportListStatus: DataStatus
	fullReports: FullReport[] // array di oggetti completi, singoli reports con tutte le diagnosi
	reportStatus: DataStatus // sul load singolo
	reportListChanged: Subject<{ patId: number; reports: Report[] }>

	// 24.08.2022 AI reports ***********

	aiReportList: AiReport[]
	aiReportListMap: Map<number, { date: string | null; reports: AiReport[] }>
	aiReportListStatus: DataStatus
	aiReportStatus: DataStatus // sul load singolo
	aiReportListChanged: Subject<{ patId: number; reports: AiReport[] }>

	// **********************

	medicalAnamnesisMap: Map<string, { date: string | null; repId: number | null; anamnesis: Anamnesis[] }[]> // key = patId + 'medical'
	impactAnamnesisMap: Map<string, { date: string | null; repId: number | null; anamnesis: Anamnesis[] }[]> // key = patId + 'impact'

	vaMaps: Map<number, { date: string | null; repId: number | null; va: VA }[]> // number == patId

	// 11.04.2022 gli icd ora sono per gruppo e per lingua, TreeMap
	//icdsMaps : Map<string, data.ICD[]>;  // la key e' gruppo_lang, quindi 1_en, 2_en, 2_it, etc.
	blocksMap: Map<string, string> // 14.04.2022
	icdsEnvs: Map<string, ICDenv> // 14.04.2022 // la key e' gruppo_lang, quindi 1_en, 2_en, 2_it, etc.

	private icdList: ICD[] // lista degli icd10, valorizzata dopo la login
	icdListStatus: DataStatus

	/* 19.05.2022 dentro icdsEnvs 
  // 30.11.2020 liste degli icd10, filtrati per categoria
  icdListR: ICD[]; 
  icdListA: ICD[];
  icdListC: ICD[];
  icdListF: ICD[];
  icdListG: ICD[];
	*/

	// DEVICES
	private devicesList: Device[]
	deviceListMap: Map<number, Device>
	devicesListStatus: DataStatus
	device: Device
	deviceStatus: DataStatus
	deviceListChanged: Subject<Device[]>

	// SWUPDATE
	updatesList: SwUpdate[]
	updatesListStatus: DataStatus
	swUpdate: SwUpdate // singolo record della updatesList
	swUpdateStatus: DataStatus

	// AGREEMENTS
	// available
	agreementsList: Agreement[]
	agreementListStatus: DataStatus

	patientAgreementsList: Agreement[]
	agreementStatus: DataStatus // per il singolo

	// accepted
	acceptedAgreementsList: Agreement[]
	acceptedAgreementsListMap: Map<number, Agreement>
	acceptedAgreementListStatus: DataStatus

	// USER LOCATIONS
	userLocationsListMap: Map<number, userLocation>

	constructor(
		private http: HttpClient,
		private translator: TranslateService, // 14.04.2022 to decode block icd
		private cryptoUtils: CryptoUtilsService
	) {
		console.log('(DM costruttore) ')
		this.loaderStatusChanges = new Subject<LoaderStatus>()
		this.resetAll()
	}

	// 18.01.2022 richiamato anche alla logout
	resetAll() {
		this.doctorListStatus = DataStatus.NOT_LOADED
		this.doctorStatus = DataStatus.NOT_LOADED

		this.patientListStatus = DataStatus.NOT_LOADED
		// this.patientStatus = DataStatus.NOT_LOADED

		this.visitListStatus = DataStatus.NOT_LOADED
		this.examListStatus = DataStatus.NOT_LOADED
		this.examStatus = DataStatus.NOT_LOADED

		this.distribListStatus = DataStatus.NOT_LOADED
		this.relsListStatus = DataStatus.NOT_LOADED
		this.relationStatus = DataStatus.NOT_LOADED

		this.adminListStatus = DataStatus.NOT_LOADED
		this.adminStatus = DataStatus.NOT_LOADED

		this.reportListStatus = DataStatus.NOT_LOADED
		this.reportStatus = DataStatus.NOT_LOADED

		this.aiReportListStatus = DataStatus.NOT_LOADED
		this.aiReportStatus = DataStatus.NOT_LOADED

		this.devicesListStatus = DataStatus.NOT_LOADED
		this.deviceStatus = DataStatus.NOT_LOADED

		// TODO capire quali servono vuoti e quali meglio nulli

		this.doctorList = []
		this.doctorListMap = new Map<number, Doctor>()
		this.doctor = new Doctor()
		this.doctoListChanged = new Subject<Doctor[]>()

		this.patientList = []
		this.patient = new Patient()
		this.patientListMap = new Map<number, Patient>()
		this.lastUpdtPatList = null
		this.patientListStatusObservable = new BehaviorSubject<DataStatus>(DataStatus.NOT_LOADED)
		this.patientListChanged = new Subject<Patient[]>()

		this.distrib = new Distrib()
		this.distribList = []
		this.distribListMap = new Map<number, Distrib>()
		this.graderId = 0
		this.distribListChanged = new Subject<Distrib[]>()

		this.relsList = []
		this.relsListMap = new Map<number, Relation>()
		this.relation = null

		this.adminList = []
		this.admin = new Admin()

		this.visitList = null
		// this.examList = null
		this.examListMap = new Map<number, Exam[]>()
		// this.exam = null
		this.categories = null

		this.reportListMap = new Map<number, { date: string | null; reports: Report[] }>()
		this.reportList = null
		this.fullReports = null
		this.reportListChanged = new Subject<{ patId: number; reports: Report[] }>()

		this.aiReportList = null // 24.08.2022
		this.aiReportListMap = new Map<number, { date: string | null; reports: AiReport[] }>()
		this.aiReportListChanged = new Subject<{ patId: number; reports: AiReport[] }>()

		this.medicalAnamnesisMap = new Map<string, { date: string | null; repId: number | null; anamnesis: Anamnesis[] }[]>() // key = patId + 'medical'
		this.impactAnamnesisMap = new Map<string, { date: string | null; repId: number | null; anamnesis: Anamnesis[] }[]>() // key = patId + 'impact'
		this.vaMaps = new Map<number, { date: string | null; repId: number | null; va: VA }[]>() // number == patId

		this.icdList = null
		/* 
    this.icdListR = null;
    this.icdListA = null;
    this.icdListC = null;
    this.icdListF = null;
    this.icdListG = null;
		*/

		this.devicesList = []
		this.deviceListMap = new Map<number, Device>()
		this.device = null
		this.deviceListChanged = new Subject<Device[]>()

		this.updatesList = null
		this.swUpdate = null

		this.icdsEnvs = new Map<string, ICDenv>() // 14.04.2022

		// 25.05.2022
		this.agreementsList = []
		this.patientAgreementsList = []
		this.agreementListStatus = DataStatus.NOT_LOADED
		this.agreementStatus = DataStatus.NOT_LOADED

		this.acceptedAgreementListStatus = DataStatus.NOT_LOADED
		this.acceptedAgreementsListMap = new Map<number, Agreement>()
		this.acceptedAgreementsList = []

		this.userLocationsListMap = new Map<number, userLocation>()
	}

	// da richiamare quando cambio doctor, by admins or specialists
	// reset pazienti, visite, esami
	changeDoctor() {
		this.resetDoctor() // 06.05.2022 added
		this.resetPats()
		this.resetVisits()
	}

	// 06.05.2022
	resetDoctor() {
		this.doctorStatus = DataStatus.NOT_LOADED
		//this.doctor = new Doctor();  // valutare se meglio null o vuoto
		this.doctor = null
	}

	// 28.01.2022
	resetPats() {
		this.patientListStatus = DataStatus.NOT_LOADED
		this.patientListStatusObservable.next(DataStatus.NOT_LOADED)
		// this.patientStatus = DataStatus.NOT_LOADED
		this.patientList = []
		this.patient = new Patient()
		this.patientListMap = new Map<number, Patient>() // 16.09.2022
		this.lastUpdtPatList = null
	}

	resetVisits() {
		this.examListStatus = DataStatus.NOT_LOADED
		this.visitListStatus = DataStatus.NOT_LOADED
		this.examStatus = DataStatus.NOT_LOADED
		this.reportListStatus = DataStatus.NOT_LOADED
		this.reportStatus = DataStatus.NOT_LOADED
		this.aiReportListStatus = DataStatus.NOT_LOADED
		this.aiReportStatus = DataStatus.NOT_LOADED

		this.visitList = null
		// this.examList = null
		this.examListMap = new Map<number, Exam[]>()
		// this.exam = null
		this.categories = null

		this.reportList = null
		this.reportListMap = new Map<number, { date: string | null; reports: Report[] }>()
		this.fullReports = null
		this.aiReportList = null
		this.aiReportListMap = new Map<number, { date: string | null; reports: AiReport[] }>()
	}

	// *********************************************************************

	// 22.11.2021
	myGet(request): Promise<any> {
		const promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myGet] calling: ' + request.url)
			this.http.get<any>(request.url, { headers: request.headers }).subscribe(
				(response) => {
					resolve(response)
				},
				(error) => {
					reject(error)
				}
			)
		})
		return promise
	}

	// 22.11.2021
	myPost(request, message, time?: number): Promise<any> {
		if (!time) {
			time = 80000
		}
		const promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myPost] calling: ' + request.url)
			this.http
				.post<any>(request.url, message, { headers: request.headers })
				.pipe(timeout(time))
				.toPromise()
				.then((res) => {
					// Success
					resolve(res)
				})
				.catch((err) => {
					// 05.08.2022
					reject(err)
				})
		})
		return promise
	}

	// 29.11.2021
	myPut(request, message): Promise<any> {
		const promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myPut] calling: ' + request.url)
			this.http
				.put<any>(request.url, message, { headers: request.headers })
				.toPromise()
				.then((res) => {
					// Success
					resolve(res)
				})
				.catch((err) => {
					// 11.01.2022
					reject(err)
				})
		})
		return promise
	}

	// 30.11.2021
	myDelete(request): Promise<any> {
		const promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myDel] calling: ' + request.url)
			this.http
				.delete<any>(request.url, { headers: request.headers })
				.toPromise()
				.then((res) => {
					// Success
					resolve(res)
				})
				.catch((err) => {
					// 05.08.2022
					reject(err)
				})
		})
		return promise
	}

	// ******* doctors ****************************************

	// 03.10.2022 aggiunti parametri per modalita' stats con calcolo crediti e soglia data
	// 06.10.2017 aggiunto parametro opzionale per ignorare i campi crittati --ls
	//loadDoctors(request, distribPwd) {

	loadDoctors(request, distribPwd, ignoreCritt?, mode?, toDate?): Promise<boolean> {
		this.doctorList = undefined
		this.doctorListStatus = DataStatus.LOADING

		request.url = Config.doctorsEndpoint

		// 03.10.2022
		if (mode && toDate) {
			request.url += '?mode=' + mode + '&toDate=' + toDate
		}

		return this.myGet(request)
			.then((response: DoctorsResponse) => {
				// console.log(response)
				return Doctor.createDoctorList(response, this.cryptoUtils, distribPwd, ignoreCritt).then((doctorList) => {
					this.addDoctorToList(doctorList)

					// this.doctorList = doctorList
					this.doctorListStatus = DataStatus.LOADED
					return true
				})
			})
			.catch((error) => {
				this.doctorList = null
				this.doctorListStatus = DataStatus.LOAD_FAILED
				console.log('DM (loadDoctors) ko!')
				console.log(error)
				throw error // 23.08.2018 cosi' la gestisce poi la chiamante --ls
			})
	}

	// 08.02.2021 added superBKey
	loadDoctor(request, distribPwd, doctorId: string, superBKey?): Promise<boolean> {
		request.url = Config.doctorsEndpoint + '/' + doctorId

		this.doctor = undefined
		this.doctorStatus = DataStatus.LOADING

		console.log('DM (loadDoctor) going to ask for doctor ' + doctorId)

		//return this.http.get<any>(myUrl, {headers: request.headers})
		return this.myGet(request)
			.then((response: DoctorResponse) => {
				// 17.05.2021 non corrisponde esattamente ma non ha impatto?
				var rawDoctor = response.doctor // .data.doctor;
				var ignoreCritt = false // decritta tutto --ls

				//console.log("DM (loadDoctor) raw:");
				// console.log(rawDoctor)

				return Doctor.createDoctor(rawDoctor, this.cryptoUtils, distribPwd, ignoreCritt, superBKey)
					.then((doctor) => {
						//console.log("DM (loadDoctor) created:");
						if (doctor != null) {
							// 06.05.2022
							this.doctor = doctor
							//this.doctor.id = doctorId;

							const myBag = this.cryptoUtils.generateBag()
							if (this.doctor.licence_num) {
								myBag['licence_num'] = this.doctor.licence_num
							}
							this.cryptoUtils.purge(myBag)
							this.cryptoUtils.decryptDataWithKey(superBKey, myBag).then((bag) => {
								if (this.doctor.licence_num) {
									this.doctor.order_reg_num = bag['licence_num']
								}
								this.doctorStatus = DataStatus.LOADED

								this.addDoctorToList([doctor])

								// non tracciare xche' vedrebbe il nome anche il ref
								//console.log("(loadDoctor) ok doct id "+this.doctor.id+" name:"+this.doctor.name); // 07.10.2021 solo per test
								Util.debug('DM (loadDoctor) ok doct id ' + this.doctor.id) // 07.10.2021 solo per test
								return true
							})
						} else {
							console.log('(loadDoctor) null ?!') // 06.05.2022
							this.doctorStatus = DataStatus.LOAD_FAILED
							return false
						}
					})
					.catch((err) => {
						// err su createDoctor

						this.doctorStatus = DataStatus.LOAD_FAILED
						console.log(err)
						throw err // cosi' la gestisce poi la chiamante --ls
					})
			})
			.catch((error) => {
				// err su API request
				this.doctorStatus = DataStatus.LOAD_FAILED
				console.log(error)
				//return false;
				throw error // cosi' la gestisce poi la chiamante --ls
			})
	}

	private addDoctorToList(doctors: Doctor[]) {
		Util.debug('(DM) Add doctor to List...')

		// per ogni doctor che gli passo, controllo se é nella map
		for (let doc of doctors) {
			//solo se il record non é giá presente nella map o se c'é ma é crittato
			let id = doc.user_id

			if (!this.doctorListMap.has(id)) {
				this.doctorListMap.set(id, doc)
			} else {
				let doct = this.doctorListMap.get(id)

				// se il doc in lista non è decrittato, allora lo sovrascrivo perché quello che mi arriva anche se non decrittato cmq va bene e gli vado sopra
				// oppure se il doc che passo é decrittato lo sovrascrivo sempre, dovrebbe essere piú aggirnato
				// DA MIGLIOORARE CON CHIAMATE CON DATA ETC
				if (!doct.isDecrypted || doc.isDecrypted) {
					this.doctorListMap.set(id, doc)
				}
			}
		}

		this.doctorList = Array.from(this.doctorListMap.values())

		this.doctoListChanged.next(this.doctorList)
	}

	public updateDoctorOnList(doctor: Doctor) {
		Util.debug('(DM) update doctor on list...')
		// console.log(doctor)

		this.doctorListMap.set(doctor.user_id, doctor)

		this.doctorList = Array.from(this.doctorListMap.values())

		this.doctoListChanged.next(this.doctorList)
	}

	// 25.10.2019
	getDoctorFromList(docId): Doctor {
		var myDoc = null
		if (this.doctorList != null) {
			for (let i = 0; i < this.doctorList.length; i++) {
				if (this.doctorList[i].id == docId) {
					myDoc = this.doctorList[i]
					break
				}
			}
		}
		return myDoc
	}

	// 18.01.2022 quello corrente, verifica id richiesto
	getDoctor(docId): Doctor {
		let myDoc: Doctor // = null;
		if (this.doctor != null && this.doctor.id == docId) {
			myDoc = this.doctor
		}
		return myDoc
	}

	// 27.05.2022 dismettere ?
	private getCurrentDoctor(): Doctor {
		// 15.11.2017 meglio che accesso diretto ?
		return this.doctor
	}

	// 02.03.2021
	getDoctorsNumber() {
		var tot = 0
		if (this.doctorList != null) {
			tot = this.doctorList.length
		}
		return tot
	}

	// 06.06.2022 per la new relation
	// NB: uso status per la lista doctors, ma non sovrascrivo le globali
	loadAvailableOpts(request, specId: number) {
		this.doctorListStatus = DataStatus.LOADING

		request.url = Config.doctorsEndpoint + '/available?specialist=' + specId

		return this.myGet(request)
			.then((response: DoctorsResponse) => {
				return Doctor.createDoctorList(response, this.cryptoUtils, null, true).then((doctorList) => {
					if (doctorList != null) this.doctorListStatus = DataStatus.LOADED
					else this.doctorListStatus = DataStatus.LOAD_FAILED

					return doctorList
				})
			})
			.catch((error) => {
				this.doctorListStatus = DataStatus.LOAD_FAILED
				console.log('DM (loadAvailableOpts) ko!')
				console.log(error)
				throw error
			})
	}

	// ***************** PATIENTS *************************

	// 06.05.2022 added status, for admins, to load only the active or the deleted
	public loadPatients(request, keyDoctor: forge.util.ByteStringBuffer, doctorId?: string): Promise<boolean> {
		// :Promise<any> : Promise<boolean>  : Observable<any>
		this.patientListStatus = DataStatus.LOADING
		this.patientListStatusObservable.next(DataStatus.LOADING)

		var keyDati = keyDoctor

		request.url = Config.patientsEndpoint

		//explicit doctorId
		if (doctorId) {
			request.url += '?doctor_id=' + doctorId
			// la richiesta proviene da un distrib, valorizzo anche la key x estrarre i dati poi
			Util.debug('(loadPatients) by dealer or admin, doctId: ' + doctorId)
		}

		// console.log(this.lastUpdtPatList)

		if (this.lastUpdtPatList) {
			// console.log(this.lastUpdtPatList.toISOString())
			request.url += '?last_update=' + this.lastUpdtPatList.toISOString()
		}

		return this.myGet(request)
			.then((response: PatientsResponse) => {
				// (response: any) => {
				// console.log(response)

				//return Patient.createPatientList(response, this.cryptoUtils, keyDati)
				return this.createPatientList(response, keyDati).then((patientList) => {
					if (patientList != null) {
						Util.debug('DM (loadPatients) rcvd :' + patientList.length)
					}

					this.addPatientToList(patientList)

					this.patientListStatus = DataStatus.LOADED
					this.patientListStatusObservable.next(DataStatus.LOADED)

					return true
				})
				/* .catch((err) => {  // 06.05.2022 per evitare errore in console ?! no, non passa di qui
            console.log("(DM loadPatients) 1 KO!");   
            console.log(err);  
            throw err; // cosi' la gestisce poi la chiamante --ls           
          }); */
			})
			.catch((error) => {
				this.patientList = null
				this.patientListStatus = DataStatus.LOAD_FAILED
				this.patientListStatusObservable.next(DataStatus.LOAD_FAILED)
				console.log('DM (loadPatients) 2 KO!')
				console.log(error)
				throw error // cosi' la gestisce poi la chiamante --ls
			})

		//return Promise.all(this.patientList);
	}

	private addPatientToList(patients: Patient[]) {
		Util.debug('(DM) Add patients to List...')

		for (let pat of patients) {
			let id = pat.id
			this.patientListMap.set(id, pat)
		}

		this.patientList = Array.from(this.patientListMap.values())
		this.patientListChanged.next(this.patientList)
	}

	public editPatientInfo(patId: number, preperty: { prop: string; value: any }[]) {
		if (this.patientListMap.has(patId)) {
			let pat = this.patientListMap.get(patId)

			for (let p of preperty) {
				pat[p.prop] = p.value
			}

			this.patientListMap.set(patId, pat)

			this.patientList = Array.from(this.patientListMap.values())
			this.patientListChanged.next(this.patientList)
			this.patientListStatus = DataStatus.LOADED
			this.patientListStatusObservable.next(DataStatus.LOADED)
		}
	}

	// public updatePatientOnList(patient: Patient) {
	// 	Util.debug('(DM) update patient on list...')
	// 	// console.log(doctor)

	// 	this.patientListMap.set(patient.id, patient)
	// 	this.patientList = Array.from(this.patientListMap.values())
	// 	this.patientListChanged.next(this.patientList)
	// }

	// 21.09.2022 richiamata da session se ok delete sul server
	removeMapPatient(patId: number) {
		if (this.patientListMap != null) {
			Util.debug('DM (delMapPat) id:' + patId)
			this.patientListMap.delete(patId)

			this.patientList = Array.from(this.patientListMap.values())
			this.patientListChanged.next(this.patientList)
		}
	}

	// 16.09.2022 spostato da Patients, era static
	private createPatientList(response: PatientsResponse, keyDoctor): Promise<Patient[]> {
		let result = []
		let i = 0

		if (response != null) {
			var myList = response.patients // 22.11.2021
			for (i = 0; i < myList.length; i++) {
				let jsonPat = myList[i]
				//result.push(Patient.createPatient(jsonPat, this.cryptoUtils, keyDoctor));
				result.push(this.managePat(jsonPat, keyDoctor))
			}
		}
		// qui i e' ok ma la map ancora vuota
		Util.debug('(createPatientList) ok tot giri ' + i + ' map: ' + this.patientListMap.size)
		return Promise.all(result)
	}

	// 16.09.2022
	private managePat(jsonPat, keyDoctor): Promise<Patient> {
		//Util.debug("DM (managePat) rcvd: ");
		// console.log(jsonPat)
		return Patient.createPatient(jsonPat, this.cryptoUtils, keyDoctor).then((myPat: Patient) => {
			if (myPat.lastUpdated && this.lastUpdtPatList < myPat.lastUpdated) {
				// console.log('(DM) managePat: ' + myPat.id + ' - ' + myPat.lastUpdated)
				this.lastUpdtPatList = myPat.lastUpdated
			}
			return myPat
		})
	}

	//Note: doctorId it is useless for lev1, required for lev2 users --ls
	public loadPatient(request, keyDoctor, patientId: number): Promise<boolean> {
		request.url = Config.patientsEndpoint + '/' + patientId
		this.patient = null
		// this.patientStatus = DataStatus.LOADING

		return this.myGet(request)
			.then((response: PatientResponse) => {
				var rawPat = null

				if (response != null) {
					rawPat = response.patient
				}

				if (rawPat != null) {
					return Patient.createPatient(rawPat, this.cryptoUtils, keyDoctor).then((myPatient) => {
						this.patient = myPatient
						// console.log(this.patient)

						//this.patient.id = patientId;  // a cosa serve ?

						this.addPatientToList([this.patient])

						// this.patientStatus = DataStatus.LOADED
						this.patientListStatusObservable.next(DataStatus.LOADED)

						return true
					})
				} else {
					Util.debug('ko patient response!') // solo per test
					console.log(response)
					// this.patientStatus = DataStatus.LOAD_FAILED
				}
			})
			.catch((error) => {
				this.patient = null
				//this.patient.id = undefined;  // 07.02.2017
				// this.patientStatus = DataStatus.LOAD_FAILED
				console.log('(loadPatient) err: ' + error.message) // quando scade la sessione qui ho undefined ?

				throw error // 13.02.2020 fix
			})
	}

	public async loadPendingPatients(request) {
		request.url = Config.pendingPatientsEndpoint
		return this.myGet(request)
	}

	//22.02.2017 per il calcolo dell'eta', il doctor ha la bDate, il lev 2 no
	public getPatientYear() {
		var ret = ''
		if (this.patient) {
			//ret = this.patient.birthDate.toUTCString();
			//if(!ret) {// i livelli 2 non la vedono
			ret = '' + this.patient.birthYear
			//}
		}
		return ret
	}

	// 24.03.2020  visite
	loadVisits(request, patientId: string): Promise<boolean> {
		request.method = 'GET'

		// unica chiamata, anche per liv2
		//console.log("(DM) loading visits for patient "+patientId);
		// visits?patient_id=NN
		request.url = Config.visitsEndpoint + '?patient_id=' + patientId

		this.visitList = undefined
		this.visitListStatus = DataStatus.LOADING

		//return this.http(request)
		return this.myGet(request)
			.then((response: VisitsResponse) => {
				//

				// console.log(response) // 15.12.2021 test trace, togliere...  [ls]

				var vList = Visit.createVisitsList(response)
				//this.visitList = Visit.createVisitsList(response);
				if (vList != null) {
					this.visitList = vList
					this.visitListStatus = DataStatus.LOADED
					Util.debug('(loadVisits) ok, tot: ' + vList.length)
					return true
				}
			})
			.catch((error) => {
				this.visitList = null
				this.visitListStatus = DataStatus.LOAD_FAILED
				console.log('(loadVisits) ko!')
				console.log(error)
				throw error
			})
	}

	// 01.06.2022
	getVisitsNumber() {
		var tot = 0
		if (this.visitList != null) {
			tot = this.visitList.length
		}
		return tot
	}

	loadExamsNew(request, keyDoctor: forge.util.ByteStringBuffer | string, examId: number, examType: string, force?: boolean): Promise<Exam> {
		const promise = new Promise<any>((resolve, reject) => {
			request.url = Config.examsEndpoint + '/' + examId + '?exam_type=' + examType

			if (force) {
				request.url += '&force=true'
			}

			this.myGet(request)
				.then((response) => {
					Exam.createExam(response.exam, this.cryptoUtils, keyDoctor)
						.then((myExam) => {
							// console.log(myExam)
							this.saveExamInMemory(myExam)
							resolve(myExam)
						})
						.catch((error) => {
							console.log(error)
							reject(error)
						})
				})
				.catch((error) => {
					console.log(error)
					reject(error)
				})
		})
		return promise
	}

	loadExam(request, keyDoctor, examId: string, examType: string, catName: string, force?: boolean): Promise<Exam> {
		request.url = Config.examsEndpoint + '/' + examId + '?exam_type=' + examType

		if (force) {
			request.url += '&force=true'
		}

		// this.exam = null
		this.examStatus = DataStatus.LOADING

		if (keyDoctor == null) {
			console.log('(DM loadExam) null keyPhoto!')
		}

		return this.myGet(request).then((response) => {
			// console.log(response) // 27.08.2020 tolta trace
			//var draftEx = response.data.exam;
			var draftEx = response.exam
			// 15.04.2020 qui gestiamo in modo generico, nella funzione fa la specializzazione
			return Exam.createExam(draftEx, this.cryptoUtils, keyDoctor)
				.then((myExam) => {
					// console.log(myExam)

					if (myExam != null) {
						//console.log("(DM loadExam) ok exam "+myExam.id+" "+myExam.exam_type);
						// this.exam = myExam
						this.examStatus = DataStatus.LOADED

						// 15.04.2020
						if (catName) {
							this.addToCategory(catName, myExam)
						}
						this.saveExamInMemory(myExam)

						//return true;
						// 22.12.2021
					} else {
						console.log('(DM loadExam) KO null exam') // 06.04.2020
						// this.exam = null
						this.examStatus = DataStatus.LOAD_FAILED
					}

					return myExam
				})
				.catch((error) => {
					// this.exam = undefined
					this.examStatus = DataStatus.LOAD_FAILED
					console.log('(loadExam) ' + error)
					if (catName) {
						this.setCategoryStatus(catName, DataStatus.LOAD_FAILED)
					}
					throw error // 13.02.2020 fix
				})
		})
	}

	private saveExamInMemory(myExam: Exam) {
		if (!this.examListMap.has(myExam.patient_id)) {
			this.examListMap.set(myExam.patient_id, [myExam])
		} else {
			let savedValues: Exam[] = this.examListMap.get(myExam.patient_id)

			if (!savedValues.find((x) => x.id == myExam.id)) {
				savedValues.push(myExam)
				this.examListMap.set(myExam.patient_id, savedValues)
			}
		}

		// console.log(Array.from(this.examListMap.values()))
	}

	public getExamsInMemory(patientId: number): { hasExams: boolean; exams: Exam[] } {
		let resp: { hasExams: boolean; exams: Exam[] } = { hasExams: false, exams: [] }

		if (this.examListMap.has(patientId)) {
			resp.exams = this.examListMap.get(patientId)
			resp.hasExams = true
		}

		return resp
	}

	// 17.04.2020
	getVisit(visitId): Visit {
		var myVisit = null
		if (this.hasLoadedVisits() && this.visitList != null) {
			for (let i = 0; i < this.visitList.length; i++) {
				if (this.visitList[i].id == visitId) {
					myVisit = this.visitList[i]
					break
				}
			}
		}
		return myVisit
	}

	// svuota precedenti array e prepara per nuova visualizzazione
	// da richiamare solo la prima volta, dopo che e' stata fatta
	// una selezione della visita o dei moduli (per i refertatori)
	initCategories(activeCatObj?: CategoriesDictionary) {
		Util.debug('(initCategories) inizio, svuoto')

		this.categories = new Array(Config.CATEG_NAMES.length)

		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			var myCateg = new Category()

			myCateg.name = Config.CATEG_NAMES[i]

			this.categories[i] = myCateg // ok
		}

		if (activeCatObj) {
			for (const category in activeCatObj) {
				let cat = this.categories.find((x) => x.name == category)
				cat.expected = activeCatObj[category].expected
			}
		}

		// console.log(this.categories)
	}

	// myExam e' un Exam esteso, specifico per ciascuno
	addToCategory(catName: string, myExam) {
		if (this.categories == null) {
			console.log('(addToCategory) null categs!')
			return
		}

		for (let i = 0; i < this.categories.length; i++) {
			if (this.categories[i].name == catName) {
				// 24.03.2022 passa di qui troppo tardi, al click sul tab richiesto
				// 28.12.2021 se WF senza immagini -> non aggiungo su glc
				if (myExam.exam_type == Config.EXM_WF && catName == Config.CAT_GLC) {
					if (myExam.hasImages) {
						//console.log("(addToCategory) WF with images - ok GLC!");
						this.categories[i].examList.push(myExam)
					} else {
						Util.debug('(addToCategory) WF without images - ignoring GLC !')
						this.categories[i].expected-- // 31.01.2022 altrim. poi mi trovo con meno esami di quelli attesi
					}
				} else {
					this.categories[i].examList.push(myExam)
				}

				// 28.07.2020 FIX, valorizzo qui [ls]
				if (myExam != null) {
					if (
						myExam.exam_type == Config.EXM_TOPO ||
						myExam.exam_type == Config.EXM_DRYEYE ||
						myExam.exam_type == Config.EXM_TONO ||
						//myExam.exam_type == Config.EXM_FUNDUS ||   // 12.10.2021
						myExam.exam_type == Config.EXM_SBJ ||
						myExam.exam_type == Config.EXM_LM ||
						myExam.exam_type == Config.EXM_PACHY
					) {
						this.categories[i].hasMiddleInfo = true
					}

					// 12.10.2021 il fundus li prevede ma VX650 non li manda
					if (myExam.exam_type == Config.EXM_FUNDUS) {
						if (myExam.hasCDR) {
							this.categories[i].hasMiddleInfo = true
						}
					}

					// 18.08.2020 patch per esami tasversali alle categorie
					if (catName == Config.CAT_REFRACTION) {
						if (myExam.exam_type == Config.EXM_TOPO || myExam.exam_type == Config.EXM_WF) {
							this.categories[i].hasMiddleInfo = true
						}
					}
				}

				// controlla se ha finito
				if (this.categories[i].examList.length == this.categories[i].expected) {
					this.categories[i].status = DataStatus.LOADED
					Util.debug('DM (addToCategory) ' + i + ' name: ' + this.categories[i].name + ' completed!')
				} else {
					// 03.06.2020
					//console.log("(addToCategory) "+i+" name: "+this.categories[i].name+" got "+this.categories[i].examList.length+" of "+this.categories[i].expected);
				}
				break
			}
		}
	}

	/*
  //15.04.2020
  hasCategoryExams(catName){
    var myList = this.getCategoryExams(catName);    
    return (myList!=null && myList.length >0);
  }
*/

	//15.04.2020
	getCategoryExams(catName) {
		var myList

		if (this.categories == null) return null

		for (let i = 0; i < this.categories.length; i++) {
			if (this.categories[i].name == catName) {
				myList = this.categories[i].examList
				break
			}
		}

		return myList
	}

	setCategoryStatus(catName, newStatus) {
		this.updtCategory(catName, newStatus)
	}

	updtCategory(catName, newStatus, totExpected?) {
		if (this.categories == null) {
			console.log('(updtCategory) null categs')
			return
		}

		for (let i = 0; i < this.categories.length; i++) {
			if (this.categories[i].name == catName) {
				this.categories[i].status = newStatus
				if (totExpected) {
					this.categories[i].expected = totExpected
				}
				break
			}
		}
	}

	getCategory(catName) {
		var myCateg: Category
		myCateg = null

		if (this.categories == null) return null

		for (let i = 0; i < this.categories.length; i++) {
			if (this.categories[i].name == catName) {
				myCateg = this.categories[i]
				break
			}
		}
		return myCateg
	}

	// 15.04.2020
	// richiede gli esami di una categoria.
	// chiederli uno alla volta e poi tenerli insieme (array);
	// una unica richiesta potrebbe risultare troppo pesante (troppe immagini su una response)
	loadCategoryExams(request, keyImages, examSelection, catName: string, force?: boolean): Promise<any[]> {
		if (!examSelection || examSelection.length == 0) return null

		var result = []

		// 16.04.2020 si salva anche il totale per sapere quando li avra' ricevuti tutti
		var totExpectedExams = examSelection.length
		Util.debug('DM (loadCategoryExams) expected: ' + totExpectedExams) // 22.12.2021

		//this.setCategoryStatus(catName, DataStatus.LOADING);
		this.updtCategory(catName, DataStatus.LOADING, totExpectedExams)

		for (let i = 0; i < examSelection.length; i++) {
			var examId = examSelection[i].id
			var examType = examSelection[i].exam_type
			//console.log("DM (loadCategoryExams) "+i+" carico "+examType+" id "+examId);
			result.push(this.loadExam(request, keyImages, examId, examType, catName, force))
		}

		return Promise.all(result) // 17.04.2020
	}

	/*
  loadExamImages(request, keyPhoto, examId: string) {
  }

  // 12.06.2017 per il report
  loadLastImages(request, keyPhoto, patientId: string, docId? :string) {
    request.method = "GET";
   
  }
*/

	// 22.11.2022 - ritorna la lista di additions disponibili per questo esame, poi bisogna fare singole get
	loadAdditions(request, examType: string, examId: number, myKey) {
		// [GET] /additions?exam_id=<exmId>&exam_type=<type>
		request.method = 'GET'
		request.url = Config.additionsEndpoint + '?exam_type=' + examType + '&exam_id=' + examId

		let additionsList = []

		return this.myGet(request)
			.then((response) => {
				// solo per test DEV iniziale
				Util.debug('DM (loadAdditions)')
				if (response) {
					//console.log(response);
					let myList = response.additions
					//console.log(myList);
					for (let i = 0; i < myList.length; i++) {
						//additionsList.push(new Addition(myList[i]));
						additionsList.push(Addition.create(myList[i], this.cryptoUtils, myKey))
					}
				}
				return Promise.all(additionsList)
			})
			.catch((error) => {
				console.log('DM (loadAdditions) ko!')
				throw error // cosi' la gestisce poi la chiamante --ls
			})
	}

	loadAddition(request, itemId: number, myKey) {
		request.method = 'GET'
		request.url = Config.additionsEndpoint + '/' + itemId

		return this.myGet(request)
			.then((response) => {
				Util.debug('DM (loadAddition)')
				if (response) {
					return Addition.create(response.addition, this.cryptoUtils, myKey)
				} else {
					return Promise.reject('ko addition response')
				}
			})
			.catch((error) => {
				console.log('DM (loadAddition) ko ' + itemId)
				throw error // cosi' la gestisce poi la chiamante --ls
			})
	}

	// *************************************************************************

	// 16.11.2017 aggiunto parametro per non fare decrypt
	loadDistributors(request, distribPwd, ignoreCritt: boolean, superKeyBox): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			request.method = 'GET'

			request.url = Config.distributorsEndpoint

			// this.distribList = null
			this.distribListStatus = DataStatus.LOADING

			this.myGet(request)
				.then((response: DistributorsResponse) => {
					//console.log("DM (loadDistributors)");
					// console.log(response)

					Util.debug('DM (loadDistribs) ... valid box ? ' + (superKeyBox != null))

					let myList = response.data
					let promiseArray = []
					let loaderStatusData: LoaderStatus = {
						activateLoader: true,
						max: myList.length,
						current: 0,
						label: this.translator.instant('TOAST.GRADER'),
					}
					this.loaderStatusChanges.next(loaderStatusData)
					// invece di mostrare la lista solo alla fine della decritt di tutto, adesso che viene fatto nel web worker, ogni decritt fatta la metto in lista
					this.distribListStatus = DataStatus.LOADED
					for (let dist of myList) {
						promiseArray.push(
							Distrib.createDistrib(dist, this.cryptoUtils, distribPwd, ignoreCritt, superKeyBox).then((distrib) => {
								// console.log(distrib)
								this.addDistribToList([distrib])
								loaderStatusData.current++
								this.loaderStatusChanges.next(loaderStatusData)
								return distrib
							})
						)
						//console.log("(createDistribList) doc "+i+" ok");
					}

					Promise.all(promiseArray).then((distribList) => {
						// console.log(distribList)
						loaderStatusData.activateLoader = false
						this.loaderStatusChanges.next(loaderStatusData)
						// this.addDistribToList(distribList)
						// this.distribList = distribList
						// this.distribListStatus = DataStatus.LOADED
						resolve(true)
					})
					// questa sotto cumulativa old
					// return Distrib.createDistribList(response, this.cryptoUtils, distribPwd, ignoreCritt, superKeyBox).then((distribList) => {
					// 	// console.log(distribList)
					// 	this.addDistribToList(distribList)
					// 	// this.distribList = distribList
					// 	this.distribListStatus = DataStatus.LOADED
					// 	return true
					// })
				})
				.catch((error) => {
					this.distribList = null
					this.distribListStatus = DataStatus.LOAD_FAILED
					console.log('DM (loadDistribs) ko!')
					console.log(error)
					reject(error)
					// throw error // cosi' la gestisce poi la chiamante --ls
				})
		})
		return promise
	}

	loadDistrib(request, nsPwd, distribId: string, superKeyBox?): Promise<Distrib> {
		const promise = new Promise<Distrib>((resolve, reject) => {
			request.method = 'GET'
			request.url = Config.distributorsEndpoint + '/' + distribId

			this.distrib = null
			this.distribStatus = DataStatus.LOADING

			//return this.http.get<any>(request.url, {headers: request.headers})
			//.subscribe(

			this.myGet(request)
				.then((response: DistributorResponse) => {
					// console.log(response)

					//var distrResp = response.data.distributor;
					var distrResp = response.distributor
					let ignoreCritt = false // 30.05.2023
					Distrib.createDistrib(distrResp, this.cryptoUtils, nsPwd, ignoreCritt, superKeyBox).then((distrib: Distrib) => {
						// console.log(distrib)
						if (distrib != null) {
							this.distrib = distrib
							//this.distrib.id = distribId;  // ovvio ?!
							this.distribStatus = DataStatus.LOADED

							this.addDistribToList([distrib])

							Util.debug('DM distrib: ' + distrib.name)
						} else {
							Util.debug('DM ko distrib: ' + distribId)
							this.distribStatus = DataStatus.LOAD_FAILED
						}
						//return true; // 06.05.2022
						resolve(distrib)
					})
				})
				.catch((error) => {
					// console.log(error)
					this.distribStatus = DataStatus.LOAD_FAILED
					reject(error)
					// throw error
				})
		})

		return promise
	}

	// chiamata per avere la lista dei clinici di un ClinicAdmin
	private loadRelatedClinics(request): Promise<ClinicsJson[]> {
		Util.debug('(DM) - loadRelatedClinics')
		return this.myGet(request)
			.then((response: ClinicsResponse) => {
				return Promise.resolve(response.clinics)
			})
			.catch((error) => {
				console.log(error)
				throw error
			})
	}

	public getRelatedClinics(request, ignoreCritt: boolean, superKeyBox): Promise<Distrib[]> {
		Util.debug('(DM) - getRelatedClinics')

		const promise = new Promise<Distrib[]>((resolve, reject) => {
			this.loadRelatedClinics(request)
				.then((response: ClinicsJson[]) => {
					// console.log(response)
					let createDistrbArray: ClinicsJson[] = [] // array di clinici per creare i distrib
					let distribToReturn: Distrib[] = [] // lista distrb da ritornare

					//prendo solo quelli active, escludoi i deleted
					const onlyActive = response.filter((c) => c.is_deleted == 'N')

					// console.log(this.distribListMap)

					for (let sigleC of onlyActive) {
						if (ignoreCritt) {
							// se ignoro la crittografia, se in lista non ho il distributore, lo aggiungo alla lista per creare il distrib
							// altrimenti (se ce l'ho), lo aggiungo alla lista da ritornare
							if (!this.distribListMap.has(sigleC.user_id)) {
								createDistrbArray.push(sigleC)
							} else {
								let distrb = this.distribListMap.get(sigleC.user_id)
								distribToReturn.push(distrb)
							}
						} else {
							// se voglio il mini decrittato, se non c'e l'ho il list devo perr forza crearlo
							// se c'é il lista ed é gia decrittato lo ritorno, altrimenti devo crearlo decrittato
							if (!this.distribListMap.has(sigleC.user_id)) {
								createDistrbArray.push(sigleC)
							} else {
								let distrb = this.distribListMap.get(sigleC.user_id)

								if (distrb.isDecrypted) {
									distribToReturn.push(distrb)
								} else {
									createDistrbArray.push(sigleC)
								}
							}
						}
					}

					if (createDistrbArray.length > 0) {
						let promiseArray = []
						for (let dist of createDistrbArray) {
							promiseArray.push(Distrib.createDistrib(dist, this.cryptoUtils, null, ignoreCritt, superKeyBox))
						}

						Promise.all(promiseArray).then((distribList: Distrib[]) => {
							// console.log(distribList)
							this.addDistribToList(distribList)

							distribToReturn = distribToReturn.concat(distribList)

							resolve(distribToReturn)
						})
					} else {
						resolve(distribToReturn)
					}
				})
				.catch((error) => {
					console.log(error)
					reject(error)
				})
		})

		return promise
	}

	public loadAvailableGraders(request, goodPwd): Promise<Distrib[]> {
		Util.debug('(DM) - loadAvailableGraders')

		//potrtei giá avere i graders in lista, ma devo avere la lista di quelli disponibili per questo opt, quindi la chiamata la devo fare cmq
		let distribToReturn: Distrib[] = []

		const promise = new Promise<Distrib[]>((resolve, reject) => {
			//la get la faccio lo stesso, poi vedo quelli che ho giá, quelli che non ho faccio la decrypt
			this.myGet(request)
				.then((response: DistributorsResponse) => {
					// console.log(response)

					let notLoadedDistr: DistributorsResponse = { data: [] }

					for (let rawDist of response.data) {
						let id = rawDist.user_id

						if (!this.distribListMap.has(id)) {
							notLoadedDistr.data.push(rawDist)
						} else {
							if (!this.distribListMap.get(id).isDecrypted) {
								notLoadedDistr.data.push(rawDist)
							} else {
								distribToReturn.push(this.distribListMap.get(id))
							}
						}
					}

					let ignoreCritt = false

					if (notLoadedDistr && notLoadedDistr.data.length > 0) {
						Distrib.createDistribList(notLoadedDistr, this.cryptoUtils, goodPwd, ignoreCritt, null).then((distribList) => {
							// console.log(distribList)
							this.addDistribToList(distribList)

							for (let distr of distribList) {
								distribToReturn.push(distr)
							}
							resolve(distribToReturn)
						})
					} else {
						resolve(distribToReturn)
					}
				})
				.catch((error) => {
					console.log(error)
					reject(error)
				})
		})

		return promise
	}

	private addDistribToList(distribs: Distrib[]) {
		// Util.debug('(DM) Add grader to List...')
		// console.log(distribs)
		// per ogni ditrub che gli passo, controllo se é nella map
		for (let distr of distribs) {
			//solo se il recordo non é giá presente nella map o se c'é ma é crittato
			let id = distr.user_id
			// se ci sono relazioni in memoria di questo distrib, le assegno
			this.assignRelationInMemory(distr)

			this.assignAgreementsInMemory(distr)

			if (!this.distribListMap.has(id)) {
				this.distribListMap.set(id, distr)
			} else {
				let distributor = this.distribListMap.get(id)

				if (!distributor.isDecrypted || distr.isDecrypted) {
					this.distribListMap.set(id, distr)
				}
			}
		}
		this.distribList = Array.from(this.distribListMap.values())
		// console.log(this.distribList)
		this.distribListChanged.next(this.distribList)
	}

	private assignRelationInMemory(distrib: Distrib) {
		distrib.relations = []
		for (let rel of this.relsListMap.values()) {
			if (rel.distrib_id == distrib.id) {
				distrib.relations.push(rel)
				distrib.loadRelationsComplete = true
			}
		}
	}

	private assignAgreementsInMemory(distrib: Distrib) {
		distrib.acceptedAgreements = []
		for (let agr of this.acceptedAgreementsListMap.values()) {
			if (agr.userId == distrib.user_id) {
				distrib.acceptedAgreements.push(agr)
				distrib.loadAgreementsComplete = true
			}
		}
	}

	// non viene usato da nessuno
	setCurrentDistrib(distribId: string) {
		if (distribId && distribId != '') {
			let id = Number(distribId)
			if (this.distribListMap.has(id)) {
				this.distrib = this.distribListMap.get(id)
				this.distribStatus = DataStatus.LOADED
			}
		}
		// this.distrib.id = distribId
		// if (this.distribList) {
		// 	for (let i = 0; i < this.distribList.length; i++) {
		// 		if (this.distribList[i].id == distribId) {
		// 			this.distrib = this.distribList[i]
		// 			this.distribStatus = DataStatus.LOADED
		// 			break
		// 		}
		// 	}
		// }
	}

	// 21.01.2022 ritorna tutte le relazioni del distrib loggato
	// private loadRelations(request, goodKey: string): Promise<Relation[]> {
	// 	let distribId = 'myself'
	// 	return this.loadDistribDocs(request, goodKey, distribId)
	// }

	// 21.01.2022 load all relations, cambio nome, era loadDistribDocs
	// 02.05.2017 ritorna le relazioni valide per questo distributore
	loadRelations(request, admPwd: string, distribId: string, ignoreCritt: boolean): Promise<Relation[]> {
		request.method = 'GET'

		if (distribId == 'myself') {
			// 21.01.2022
			request.url = Config.relationsEndpoint
		} else {
			request.url = Config.distributorsEndpoint + '/' + distribId + '/rels'
		}

		Util.debug('DM (loadDistribDocs) going to call: ' + request.url)

		this.relsList = [] // undefined;
		this.relsListStatus = DataStatus.LOADING

		return this.myGet(request)
			.then((response: RelationsResponse) => {
				//console.log("DM (loadRelations) resp:");
				// console.log(response) // 03.09.2019 tolta trace, pesante [ls]

				return Relation.createRelsList(response, this.cryptoUtils, admPwd, ignoreCritt).then((list) => {
					this.relsList = list
					this.addRelationToList(list)
					this.graderId = parseInt(distribId)
					Util.debug('DM (loadDistribDocs) tot: ' + list.length + ' for grader ' + this.graderId)
					this.relsListStatus = DataStatus.LOADED
					return list
				})
			})
			.catch((error) => {
				this.relsListStatus = DataStatus.LOAD_FAILED
				//return false;
				//return this.cryptoUtils.q.all(error);
				return Promise.reject(error)
			})
	}

	private addRelationToList(rels: Relation[]) {
		Util.debug('(DM) Add relations to List...')
		for (let rel of rels) {
			this.relsListMap.set(rel.id, rel)
		}

		// é vero che viene chiamata sulla get di un singolo distributore, ma nel caso in futuro venga usata anche su liste di relazioni multi ditrib
		// mi ricavo i distributori nelle relazioni e su ognuno aggiungo le relazioni
		let distributorsId = [...new Set(rels.map((item) => item.distrib_id))]

		for (let distId of distributorsId) {
			if (this.distribListMap.has(+distId)) {
				let distributor = this.distribListMap.get(+distId)
				this.assignRelationInMemory(distributor)
			}
		}

		this.relsList = Array.from(this.relsListMap.values())
	}

	// 03.09.2019 miglioria [ls]
	resetRelationList() {
		this.relsList = [] // empty
		this.relsListStatus = DataStatus.NOT_LOADED
		this.graderId = 0
	}

	// 02.03.2023 richiesta relazione specifica, forse cancellata, da optician con precedente ref
	// per presentare i dati sul pdf
	loadRelationReferrer(request, specId: number, docId: number, goodKey: string): Promise<Specialist> {
		request.method = 'GET'
		request.url = Config.relationsEndpoint + '/' + docId + '?ref_id=' + specId

		this.relation = null
		this.relationStatus = DataStatus.LOADING

		//console.log("(loadRelation) asking for "+request.url);

		return this.myGet(request).then((response) => {
			// RelationsResponse
			//console.log(response); // solo per test, pesante
			let myRef: any
			if (response.referrer != null) {
				myRef = response.referrer
			} else {
				Util.debug('(loadRelationRef) unrecognized response!')
				console.log(response) // solo per test, pesante
			}

			return Specialist.createSpecialist(myRef, this.cryptoUtils, goodKey)
			//.then((myReferrer) => {
			// salvarlo sui distributors, se lev1 ? no, sono oggetti Distrib
			//return myReferrer;
			//});
		})
	}

	/*
  // 21.01.2022 cambiata un po'
  // 28.10.2021 era su nexy, non ancora implementata su nexus
  // 13.09.2019 ritorna la relazione del distrib loggato con un doct, con la sua immagine signature
  loadRelation(request, specId, docId: string, goodKey: string) : Promise<Relation> {
    request.method = "GET";
    
    //request.url = Config.doctorsEndpoint + "/" + docId + "/relation";
    //request.url = Config.doctorsEndpoint + "/" + docId ;

    request.url = Config.relationsEndpoint + "/?doctor=" + docId;

    this.relation = null; 
    this.relationStatus = DataStatus.LOADING;

    console.log("(loadRelation) asking for "+request.url); 

    return this.myGet(request)
    .then((response) => {   // RelationsResponse
      
      console.log(response); // solo per test, pesante      

      let myRel: Relation;

      // 21.01.2022 patch
      if(response.relations != null){
        let list = response.relations;
        for(let i=0; i<list.length; i++){
          let rel = list[i];
          if(rel.doctor_id == docId){
            myRel = rel;
            break;
          }
        }
      }

      //return data.Relation.createFullRelation(response.data.data, this.cryptoUtils, goodKey)
      return Relation.createRelation(myRel, this.cryptoUtils, goodKey)
        .then((rel: Relation) => {                                          
            
          if(rel != null) {
            this.relation = rel;    
            this.relationStatus = DataStatus.LOADED;                               
            //if(this.relation != null)
            console.log("(loadRelation) ok rel, displ. name: "+this.relation.display_name); 

          } else {
            this.relationStatus = DataStatus.LOAD_FAILED;    // 06.12.2019        
            console.log("(loadRelation) ko rel with "+docId); 
          }

          return rel;
        });

    })
    .catch((error) => {
        this.relationStatus = DataStatus.LOAD_FAILED;        
        //return this.cryptoUtils.q.all(error);   
        return Promise.reject(error);   
    });    

  }
  */

	// 13.09.2019
	getRelation(distId, docId): Relation {
		var myRel = null

		if (this.relationStatus == DataStatus.LOADED && this.relation != null && this.relation.doctor_id == docId && this.relation.distrib_id == distId) {
			myRel = this.relation
			Util.debug('DM (getRelation) ok, current rel between ' + distId + ' and ' + docId)
		} else {
			//console.log("(getRelation) KO, not found between "+distId+" and "+docId);
			//provo dalla lista
		}

		// 28.10.2021 patch
		if (myRel == null && this.relsList != null) {
			for (let i = 0; i < this.relsList.length; i++) {
				var rel = this.relsList[i]
				if (rel.distrib_id == distId && rel.doctor_id == docId) {
					myRel = rel
					Util.debug('DM (getRelation) found from the list')
					break
				}
			}
		}

		if (myRel == null) {
			console.log('(getRelation) not found (from cache) between ' + distId + ' and ' + docId)
		}

		return myRel
	}

	// 24.01.2022 aggiunge o aggiorna una relazione
	// override di quella in lista
	updateRelation(myRel: Relation, force: boolean) {
		if (this.relation == null || force) {
			this.relation = myRel
			this.relationStatus = DataStatus.LOADED

			if (myRel.decrypted) {
				Util.debug('(updateRelation) displ. name: ' + this.relation.display_name + ' has_sign? ' + this.relation.has_signature)
			} else {
				Util.debug('(updateRelation) rel still encrypted, has_sign? ' + this.relation.has_signature)
			}
		} else if (this.relation.doctor_id == myRel.doctor_id && this.relation.distrib_id == myRel.distrib_id) {
			// todo CFR
			Util.debug('(updateRelation) already loaded - TODO completare merge!')

			// 08.06.2023 merge, salvo la keyPhoto!
			if (!myRel.docKeyPhoto && this.relation.docKeyPhoto) {
				myRel.docKeyPhoto = this.relation.docKeyPhoto
			}
			if (!myRel.docKeyBoxPhoto && this.relation.docKeyBoxPhoto) {
				myRel.docKeyBoxPhoto = this.relation.docKeyBoxPhoto
			}

			// 04.08.2022
			if (myRel.decrypted && !this.relation.decrypted) {
				this.relation = myRel
				Util.debug('(updateRelation) overwritten with the decrypted one')
			} else {
				Util.debug('(updateRelation) kept the old one')
			}

			this.relationStatus = DataStatus.LOADED
		} else {
			// c'e' gia' ma diversa
			Util.debug('(updateRelation) already loaded a different one, override')
			this.relation = myRel
			this.relationStatus = DataStatus.LOADED
		}

		// 25.01.2022 aggiorno anche la lista
		if (this.relsList == null) {
			this.relsList = []
		}
		if (this.relsList != null) {
			let id = 0

			for (let relation of this.relsListMap.values()) {
				if (relation.distrib_id == myRel.distrib_id && relation.doctor_id == myRel.doctor_id) {
					id = relation.id

					Util.debug('updateRelation - find rel id: ' + id)
				}
			}

			this.relsListMap.delete(id)
			this.addRelationToList([myRel])

			// for (let i = 0; i < this.relsList.length; i++) {
			// 	var rel = this.relsList[i]
			// 	if (rel.distrib_id == myRel.distrib_id && rel.doctor_id == myRel.doctor_id) {
			// 		this.relsList[i] = myRel // sovrascrivo
			// 		Util.debug('(updateRelation) updated in the list, pos: ' + i)
			// 		break
			// 	}
			// }
		}
	}

	// 09.05.2024 aggiorna una relazione esistente, prendendo solo i campi presenti nella nuova
	mergeRelation(myRel: Relation) {
		// let myIndex = -1

		if (this.relsList != null) {
			for (let relation of this.relsListMap.values()) {
				if (relation.distrib_id == myRel.distrib_id && relation.doctor_id == myRel.doctor_id) {
					if (!relation.licence_num && myRel.licence_num) relation.licence_num = myRel.licence_num

					if (!relation.keybox_public && myRel.keybox_public) relation.keybox_public = myRel.keybox_public

					if (!relation.docKeyPhoto && myRel.docKeyPhoto) relation.docKeyPhoto = myRel.docKeyPhoto

					// TODO altri campi
				}
			}

			// for (let i = 0; i < this.relsList.length; i++) {
			// 	var rel = this.relsList[i]
			// 	if (rel.distrib_id == myRel.distrib_id && rel.doctor_id == myRel.doctor_id) {
			// 		// trovata
			// 		myIndex = i
			// 		break
			// 	}
			// }
		}

		// trovata, procedo con il merge, solo su campi mancanti
		// if (myIndex >= 0) {
		// 	let relOrig = this.relsList[myIndex]

		// 	if (!relOrig.licence_num && myRel.licence_num) relOrig.licence_num = myRel.licence_num

		// 	if (!relOrig.keybox_public && myRel.keybox_public) relOrig.keybox_public = myRel.keybox_public

		// 	if (!relOrig.docKeyPhoto && myRel.docKeyPhoto) relOrig.docKeyPhoto = myRel.docKeyPhoto

		// 	// TODO altri campi

		// 	this.relsList[myIndex] = relOrig // aggiorno con i nuovi campi ricevuti
		// }
	}

	// 08.08.2018  *********

	loadAdmins(request, distribPwd, ignoreCritt?) {
		request.method = 'GET'
		request.url = Config.adminsEndpoint

		this.adminList = undefined
		this.adminListStatus = DataStatus.LOADING

		return this.myGet(request)
			.then((response: AdminsResponse) => {
				//console.log(response);

				//return data.Admin.createAdminList(response, this.cryptoUtils, distribPwd)
				return Admin.createAdminList(response, this.cryptoUtils, distribPwd, ignoreCritt).then((adminList) => {
					this.adminList = adminList
					this.adminListStatus = DataStatus.LOADED
					return true
				})
			})
			.catch((error) => {
				this.adminList = null
				this.adminListStatus = DataStatus.LOAD_FAILED
				console.log('DM (loadAdmins) ko!')
				console.log(error)
				throw error // 23.08.2021 la gestisce poi la chiamante --ls
			})
	}

	loadAdmin(request, distribPwd, adminId: string) {
		request.method = 'GET'
		request.url = Config.adminsEndpoint + '/' + adminId

		this.admin = null
		this.adminStatus = DataStatus.LOADING

		Util.debug('(loadAdmin) going to ask for admin ' + adminId)

		//return this.http(request)
		return (
			this.myGet(request)
				//.then((response: Contracts.AdminsResponse) => {
				.then((response: AdminResponse) => {
					var draftAdm = response.admin // .data.admin;
					//console.log(draftAdm);

					return Admin.createAdmin(draftAdm, this.cryptoUtils, distribPwd, false).then((adm) => {
						this.admin = adm
						this.adminStatus = DataStatus.LOADED
						Util.debug('(loadAdmin) ok admin ' + this.admin.id)
						return true
					})
				})
		)
	}

	// 30.09.2021
	// non fa una nuova richiesta: se ha gia' la lista, prende da quella
	getAdminFromList(admId): Admin {
		var myAdm = null

		// 28.01.2022
		if (this.admin != null && this.admin.id == admId) {
			myAdm = this.admin
		} else if (this.adminList != null) {
			for (let i = 0; i < this.adminList.length; i++) {
				if (this.adminList[i].id == admId) {
					myAdm = this.adminList[i]
					break
				}
			}
		}
		return myAdm
	}

	/*
  // 24.06.2019 ottiene un array con soli {id; nome}
  loadTemplateNames(request){
  */

	// 20.01.2021 aggiunta lingua
	// 14.05.2020
	loadIcdList(request, groupId, lang): Promise<boolean> {
		this.icdList = null
		this.icdListStatus = DataStatus.LOADING

		request.method = 'GET'

		// 20.01.2021 aggiunto filtro gruppo e lingua
		request.url = Config.reportsEndpoint + '/icds' + '?lang=' + lang + '&group=' + groupId

		//return this.http(request)
		return this.myGet(request)
			.then((result) => {
				//var lista = result.data;
				var lista = result // 20.12.2021

				this.icdList = [] // array vuoto

				/* 19.05.2022 dentro icdsEnvs 
        this.icdListA = [];   // 30.11.2020 inizializzo x tutte le categ
        this.icdListC = [];        
        this.icdListF = [];
        this.icdListG = [];
        this.icdListR = [];
				*/

				if (lista != null && lista.icds != null) {
					var listaTemp = lista.icds

					Util.debug('(loadIcdList) OK group: ' + groupId + ' tot: ' + listaTemp.length)

					//console.log("(loadIcdList) OK "+listaTemp.length); // ok, 2
					//console.log(listaTemp);   // solo per test

					for (let i = 0; i < listaTemp.length; i++) {
						//this.icdList.push(new data.ICD(listaTemp[i]));
						var myIcd = new ICD(listaTemp[i])
						this.icdList.push(myIcd)

						// 19.05.2022 sono dentro icdsEnvs
						//this.manageIcdBlocks(myIcd);  // 30.11.2020
					}
				} else {
					console.log('(loadIcdList) KO: ')
					console.log(result)
				}

				this.icdListStatus = DataStatus.LOADED

				// 11.04.2022 gestione multi-gruppo
				let key = this.getIcdMapKey(groupId, lang)

				if (this.icdsEnvs.has(key)) {
					// TODO, update or replace ?
					//alert("update ICD list - TODO");
					console.log('DM (loadIcdList) - key already exists ' + key)
				} else {
					Util.debug('(loadIcdList) - new icds env: ' + key)
					let currEnv: ICDenv
					currEnv = new ICDenv(groupId, lang)
					currEnv.setFullList(this.icdList, this.translator)
					this.icdsEnvs.set(key, currEnv)
				}

				return true
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				console.log('(loadIcdList) - KO ' + msg)
				console.log(err) // es, token expired

				this.icdList = null
				this.icdListStatus = DataStatus.LOAD_FAILED

				throw err //  la gestisce poi la chiamante --ls
			})
	}

	// 11.04.2022
	getIcdMapKey(groupId?: number, lang?: string) {
		if (lang == null) lang = 'en'
		if (groupId == null) groupId = 1
		let key = groupId + '_' + lang
		return key
	}

	// richiamata dal pdf
	// 03.06.2020 ritorna la descrizione associata ad un codice ICD, eventualm. gruppo e lingua (usi futuri)
	// 11.04.2022 obbligatori gruppo e lingua
	//getIcdDescr(icdCode, group?, lang?) {
	getIcdDescr(icdCode, group, lang, categ) {
		var ret = ''
		let myIcd = this.getICDFromCode(icdCode, group, lang, categ)
		if (myIcd != null) {
			// 18.05.2022 solo per test
			if (myIcd.displDescr.trim() == '') {
				console.log('(getIcdDescr) - KO descr for ' + icdCode)
				myIcd = this.getICDFromCode(icdCode, group, lang, null)
				if (myIcd != null) {
					if (myIcd.displDescr.trim() == '') {
						console.log('(getIcdDescr) - [2] KO descr for ' + icdCode)
					}
				}
			}

			// 19.04.2022 patch
			if (Util.hideIcdsCodes(group)) {
				ret = myIcd.displDescr // composta con eye
			} else {
				ret = myIcd.descr // il cod icd viene esposto a parte
			}
		}
		return ret
	}

	// 18.05.2022 aggiunta categ, altrim. per gruppo 2 non ho le displDescr!
	// 11.04.2022
	//public getICDForPdf(code, group, lang){
	public getICDFromCode(icdCode: string, group: number, lang: string, categ: string): ICD {
		let myIcd: ICD
		myIcd = null

		// 11.04.2022 gestione multi-gruppo
		let key = this.getIcdMapKey(group, lang)

		let myIcdList = null
		//let myIcdList = this.icdList;  // 14.04.2022
		if (this.icdsEnvs.has(key)) {
			let myEnv = this.icdsEnvs.get(key)

			// 18.05.2022 fix, per gruppo 2 sono senza descr, serve passare anche la categoria ?!
			Util.debug('(getICDFromCode) categ: ' + categ + ' looking for ' + icdCode)

			if (categ != null) {
				myIcdList = myEnv.getCategList(categ)

				// 11.08.2022 aggiunto test su length, patch per Clalit su gruppo 1
				if (myIcdList != null && myIcdList.length == 0) {
					myIcdList = myEnv.getFullList()
				}
			} else {
				myIcdList = myEnv.getFullList()
			}
		}

		if (myIcdList != null) {
			Util.debug('(getICDFromCode) categ: ' + categ + ' tot: ' + myIcdList.length)
			for (let i = 0; i < myIcdList.length; i++) {
				//Util.debug("(getICDFromCode) i: "+i+" code: "+myIcdList[i].code);

				if (myIcdList[i].code == icdCode) {
					//myIcd = myIcdList[i];  // meglio fare un clone ?
					//myIcd = new ICD(myIcdList[i]);  // 18.05.2022 ko
					myIcd = ICD.cloneMe(myIcdList[i]) // 19.05.2022

					break
				}
			}
		} else {
			console.log('(getICDFrmCode) empty list for grp:' + group + ' lang: ' + lang)
		}

		// 15.04.2022 solo per test
		if (myIcd != null) {
			Util.debug('(getICDFrmCode) grp:' + group + ' lang: ' + lang + ' code:' + icdCode + ' eye:' + myIcd.eye + ' descr: ' + myIcd.displDescr)
		} else {
			Util.debug('(getICDFrmCode) grp:' + group + ' lang: ' + lang + ' code:' + icdCode + ' NOT FOUND!')
		}

		/* 05.08.2022 tolta trace
		if(myIcdList){			
			for(let i=0; i<myIcdList.length && i <8 ; i++){			
				Util.debug("(getICDFromCode) i: "+i+" code: "+myIcdList[i].code);
			}
		}
    */

		return myIcd
	}

	// 15.04.2022
	hasLoadedIcds(group, lang) {
		let ret = false

		if (group != null && lang != null) {
			let key = this.getIcdMapKey(group, lang)
			ret = this.icdsEnvs.has(key)
		}
		Util.debug('(hasLoadedIcds) group ' + group + ' lang ' + lang + ' loaded ? ' + ret)

		return ret
	}

	// 14.04.2022 aggiunto gruppo e gestione con mappe Env
	// 30.11.2020
	getIcdList(catName, group?, lang?) {
		let myEnv: ICDenv
		let myList = null

		// 14.04.2022 gestione multi-gruppo
		if (group != null && lang != null) {
			let key = this.getIcdMapKey(group, lang)
			let fullList = null
			//let myIcdList = this.icdList;  // 14.04.2022
			if (this.icdsEnvs.has(key)) {
				myEnv = this.icdsEnvs.get(key)
				fullList = myEnv.getFullList()
			}

			if (fullList != null) {
				// ok, allora gia' calcolate le subCategs
			} else {
				Util.debug('(getIcdList) missing for this group ' + group + ' or lang ' + lang)
				// TODO !!!!
			}
		}

		// ritorno sottogruppo di ICD, adatti a questa categoria
		if (catName != null && myEnv != null) {
			switch (catName) {
				case Config.CAT_REFRACTION:
					//myList = this.icdListR;  // 15.04.2022
					myList = myEnv.icdListR
					break

				case Config.CAT_ANTERIOR:
					//myList = this.icdListA;
					myList = myEnv.icdListA
					break

				case Config.CAT_CORNEA:
					//myList = this.icdListC;
					myList = myEnv.icdListC
					break

				case Config.CAT_FUNDUS:
					//myList = this.icdListF;
					myList = myEnv.icdListF
					break

				case Config.CAT_GLC:
					//myList = this.icdListG;
					myList = myEnv.icdListG
					break

				default:
					console.log('(getIcdList) not managed ' + catName)
			}
		}

		if (catName == null) {
			Util.debug('(getIcdList) invalid categ, full list!')
		} else if (myList == null || myList.length == 0) {
			// non trovata ?
			Util.debug('(getIcdList) ko partial for ' + catName)
			myList = this.icdList // full list
		}

		if (myList != null) Util.debug('(getIcdList) len: ' + myList.length)

		return myList
	}

	/* 19.05.2022 spostato dentro icdsEnvs 	
  // 30.11.2020  gestione dei 13 blocks sulle categorie
  private manageIcdBlocks(myIcd){
    
    if(myIcd.block == "dry_eye"){
      this.icdListA.push(myIcd);  // anterior

    } else if(myIcd.block == "cornea" ||
              myIcd.block == "dry_eye" ||  
              myIcd.block == "keratoconus"  ){   
      this.icdListC.push(myIcd); // cornea
    
    } else if(myIcd.block == "glaucoma" || 
              myIcd.block == "cataract" || 
              myIcd.block == "narrow_angle"){
      this.icdListG.push(myIcd); // GLC
    
    } else if(myIcd.block == "amd" ||
              myIcd.block == "diabetic" ||
              myIcd.block == "disc_anom" ||
              myIcd.block == "high_myopia" ||
              myIcd.block == "hypertensive" ||
              myIcd.block == "retinal_vascular" ){   
      this.icdListF.push(myIcd); // fundus
    
    } else if(myIcd.block == "high_myopia" ||
              myIcd.block == "keratoconus" ||
              myIcd.block == "refraction" ){   
      this.icdListR.push(myIcd); // refraction
    }   

  }
	*/

	// **********************************************
	// HG REPORTS
	//ottiene un array con info sui reports, niente da decrittare
	loadReportList(request, patientId, datetime?): Promise<Report[]> {
		this.reportList = null
		this.reportListStatus = DataStatus.LOADING

		request.method = 'GET'
		request.url = Config.reportsEndpoint + '?patient_id=' + patientId

		if (datetime) {
			request.url += '&last_update=' + datetime
		}

		let requestDate = new Date()

		return this.myGet(request)
			.then((result) => {
				// console.log(result)

				var lista = result.reports
				let reportList: Report[] = []

				if (lista && lista.length > 0) {
					Util.debug('DM (loadReportList) OK ' + lista.length)
					for (let repo of lista) {
						reportList.push(new Report(repo))
					}
				}
				// anche se replist = [] aggiorno la data ora
				this.addReportListMap(patientId, reportList, requestDate)
				this.reportListStatus = DataStatus.LOADED

				return reportList
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				console.log('(loadReportList) - KO ' + msg)
				console.log(err) // es, token expired

				this.reportList = null
				this.reportListStatus = DataStatus.LOAD_FAILED

				throw err //  la gestisce poi la chiamante --ls
			})
	}

	// 01.06.2020 singolo
	loadReport(request, keyDoctor, reportId: string): Promise<FullReport> {
		request.method = 'GET'

		// unica chiamata per tutti i profili
		request.url = Config.reportsEndpoint + '/' + reportId

		// svuotare eventuale elemento su array ? non dovrebbe esserci
		this.reportStatus = DataStatus.LOADING

		//return this.http(request)
		const promise = new Promise<FullReport>((resolve, reject) => {
			this.myGet(request)
				//.then((response: Contracts.ReportResponse) => {
				.then((response: any) => {
					//console.log(response); // solo per test

					var rawObj = null
					if (response != null) {
						//rawObj = response.data.report;
						rawObj = response.report
					}

					// console.log(rawObj) // 15.06.2022 tolta trace

					if (rawObj != null && rawObj.diagnosis != null) {
						var myRep = new FullReport(rawObj)

						var diagnList = rawObj.diagnosis
						SrvDiagnosis.createDiagnosis(diagnList, this.cryptoUtils, keyDoctor).then((diagn) => {
							myRep.setDiagnCategories(diagn) // e' un array
							this.addReport(myRep)

							this.cryptoUtils.decryptDataWithKey(keyDoctor, rawObj.follow_up_notes).then((bagCri) => {
								this.reportStatus = DataStatus.LOADED
								Util.debug('DM (loadReport) ok report ' + myRep.id)
								myRep.follow_up_notes = bagCri

								resolve(myRep)
							})
						})
					} else {
						console.log('ko report response')
						this.reportStatus = DataStatus.LOAD_FAILED

						reject(null)
					}
				})
				.catch((error) => {
					this.reportStatus = DataStatus.LOAD_FAILED
					console.log('DM (loadReport) err: ' + error.message) // quando scade la sessione qui ho undefined ?
					reject(error)
					throw error // fix
				})
		})

		return promise
	}

	public changeHgReportstatus(report: Report, status: ReportStatus) {
		if (this.reportListMap.has(report.patient_id)) {
			let reports = this.reportListMap.get(report.patient_id).reports
			let rep = reports.find((x) => x.id == report.id)
			if (rep) {
				rep.status = status
			}

			this.reportListChanged.next({ patId: report.patient_id, reports: this.reportListMap.get(report.patient_id).reports })
		}
	}

	private addReportListMap(patId: number, repList: Report[], requestDate: Date) {
		if (this.reportListMap.has(patId)) {
			// let reports = this.reportListMap.get(patId).reports
			// reports.push(...repList)
			const reports: Report[] = this.reportListMap.get(patId).reports
			repList.forEach((newReport: Report) => {
				const existingIndex: number = reports.findIndex((report: Report) => report.id === newReport.id)
				if (existingIndex !== -1) {
					reports[existingIndex] = newReport
				} else {
					reports.push(newReport)
				}
			})

			this.reportListMap.set(patId, { date: requestDate.toISOString(), reports: reports })
		} else {
			this.reportListMap.set(patId, { date: requestDate.toISOString(), reports: repList })
		}

		this.reportListChanged.next({ patId: patId, reports: this.reportListMap.get(patId).reports })
	}

	public getCustomerReportList(userId: number): { date: string; reports: Report[] } {
		let repList: { date: string; reports: Report[] } = { date: null, reports: [] }
		if (this.reportListMap.has(userId)) {
			repList = this.reportListMap.get(userId)
		}
		return repList
	}

	// public updateCustomerReportList(userId: number, repList: Report[]) {
	// 	this.reportListMap.set(userId, { date: null, reports: repList })
	// }

	//
	public getReportById(reportId: number) {
		var rep: FullReport
		rep = null

		if (this.fullReports != null) {
			for (let i = 0; i < this.fullReports.length; i++) {
				if (this.fullReports[i].id == reportId) {
					rep = this.fullReports[i]
					Util.debug('(getReportById) found ' + reportId)
					break
				}
			}
		}
		return rep
	}

	// 01.06.2020 serve ?
	/*
  public resetReportById(repId: string) {   
      
    if(this.diagnosisList != null){
      for(let i=0; i< this.diagnosisList.length; i++){
        if(this.diagnosisList[i].report_id == repId){
          this.diagnosisList[i] = null;
          console.log("(resetReportById) azzerato "+repId);  
          break;
        }
      }
    }
    return;
  }
*/

	// 01.06.2020 un fullReport ha un array di diagnosi sulle categorie, max 5
	private addReport(templ: FullReport) {
		if (templ == null) return

		if (this.fullReports == null) {
			this.fullReports = []
			//console.log("(addReport) nuovo, aggiungo "+templ.id);
			this.fullReports.push(templ)
			return
		}

		var found = false
		var i = 0
		for (i = 0; i < this.fullReports.length; i++) {
			if (this.fullReports[i].id == templ.id) {
				found = true // gia' presente
				// sovrascrivo !? [ls]
				Util.debug('(addReport) ' + i + ' overwrite ' + templ.id)
				this.fullReports[i] = templ
				break
			}
		}

		if (found == false) {
			//console.log("(addReport) "+i+" aggiungo in coda "+templ.id);
			this.fullReports.push(templ)
		}

		return
	}

	/*

public getCategReportById(reportId: string) {   
    
    var rep : data.SrvDiagnosis;
    rep = null;
    
    if(this.diagnosisList != null){
      for(let i=0; i< this.diagnosisList.length; i++){
        if(this.diagnosisList[i].report_id == reportId){
          rep = this.diagnosisList[i];
          console.log("(getReportById) trovato "+reportId);  
          break;
        }
      }
    }    
    return rep;
  }


  // 01.06.2020 un report e' un array di diagnosi sulle categorie, maxLen = 5
  private addDiagnReport(templ : data.Diagnosis) {           
    
    if(templ == null)
      return;

    if(this.diagnosisList == null){
      this.diagnosisList = [];
      console.log("(addReport) nuovo, aggiungo "+templ.report_id);  
      this.diagnosisList.push(templ);
      return;
    }
    
    var found = false;
    var i = 0;
    for(i=0; i< this.diagnosisList.length; i++){
      if(this.diagnosisList[i].report_id == templ.report_id) {               
        found = true; // gia' presente         
        // sovrascrivo !? [ls]
        console.log("(addReport) "+i+" sovrascrivo "+templ.report_id);  
        this.diagnosisList[i] = templ;
        break;
      }
    }
    
    if(found == false){
      console.log("(addReport) "+i+" aggiungo in coda "+templ.report_id);  
      this.diagnosisList.push(templ);
    }

    return;
  }
*/

	// ********** AI Reports *******************  integrated 24.08.2022

	// 25.07.2022 ottiene un array con info sui reports, niente da decrittare
	loadAiReportList(request, patientId: number, datetime?: string): Promise<AiReport[]> {
		this.aiReportList = null
		this.aiReportListStatus = DataStatus.LOADING

		request.method = 'GET'
		request.url = Config.aiReportsEndpoint + '?patient_id=' + patientId

		if (datetime) {
			request.url += '&last_update=' + datetime
		}

		let requestDate = new Date()

		return this.myGet(request)
			.then((result) => {
				// console.log(result) //  solo per test
				var reports = result.reports
				let reportList: AiReport[] = []

				if (reports != null && reports.length > 0) {
					Util.debug('DM (loadAiReportList) OK ' + reports.length)

					for (let aiRep of reports) {
						reportList.push(new AiReport(aiRep))
					}
				}

				this.aiReportListStatus = DataStatus.LOADED
				this.addAIReportListMap(patientId, reportList, requestDate)

				return reportList
			})
			.catch((err) => {
				//var msg = (err.data)? err.data.error : err.toString();
				console.log('(loadAiReportList) - KO!')
				console.log(err) // es, token expired

				this.aiReportList = null
				this.aiReportListStatus = DataStatus.LOAD_FAILED

				throw err //  la gestisce poi la chiamante --ls
			})
	}

	private addAIReportListMap(patId: number, repList: AiReport[], requestDate: Date) {
		if (this.aiReportListMap.has(patId)) {
			// let reports = this.aiReportListMap.get(patId).reports
			// reports.push(...repList)
			const aireports: AiReport[] = this.aiReportListMap.get(patId).reports
			repList.forEach((newReport: AiReport) => {
				const existingIndex: number = aireports.findIndex((report: AiReport) => report.id === newReport.id)
				if (existingIndex !== -1) {
					aireports[existingIndex] = newReport
				} else {
					aireports.push(newReport)
				}
			})

			this.aiReportListMap.set(patId, { date: requestDate.toISOString(), reports: aireports })
		} else {
			this.aiReportListMap.set(patId, { date: requestDate.toISOString(), reports: repList })
		}

		this.aiReportListChanged.next({ patId: patId, reports: this.aiReportListMap.get(patId).reports })
	}

	private changeAiReportstatus(aireport: AiReport) {
		if (this.aiReportListMap.has(aireport.patient_id)) {
			let aireports = this.aiReportListMap.get(aireport.patient_id).reports
			let rep = aireports.find((x) => x.id == aireport.id)

			if (rep) {
				rep.status = 4
			}

			this.aiReportListChanged.next({ patId: aireport.patient_id, reports: this.aiReportListMap.get(aireport.patient_id).reports })
		}
	}

	public getCustomerAiReportList(userId: number): { date: string; reports: AiReport[] } {
		let repList: { date: string; reports: AiReport[] } = { date: null, reports: [] }
		if (this.aiReportListMap.has(userId)) {
			repList = this.aiReportListMap.get(userId)
		}
		return repList
	}

	// 25.07.2022 singolo AI
	loadAiReport(request, reportId: number, redKey: any): Promise<AiReport> {
		request.method = 'GET'

		// unica chiamata per tutti i profili
		request.url = Config.aiReportsEndpoint + '/' + reportId

		// svuotare eventuale elemento su array ? non dovrebbe esserci
		this.aiReportStatus = DataStatus.LOADING

		return this.myGet(request)
			.then((response: any) => {
				//console.log(response); // solo per test
				var rawObj = null
				if (response != null) {
					rawObj = response.report
				}

				//console.log(rawObj);

				if (rawObj != null) {
					return AiReport.createAiReport(rawObj, this.cryptoUtils, redKey) // deve anche decrittare il pdf
						.then((myRep: AiReport) => {
							// console.log(myRep) // TEMP TODO
							this.changeAiReportstatus(myRep)
							this.aiReportStatus = DataStatus.LOADED
							//Util.debug("DM (loadAiReport) ok report "+myRep.basicInfo());
							Util.debug('DM (loadAiReport) ok report ' + myRep.id)
							return myRep
						})
				} else {
					console.log('DM (loadAiReport) ko report response')
					console.log(response)
					this.aiReportStatus = DataStatus.LOAD_FAILED
					return null
				}
			})
			.catch((error) => {
				this.aiReportStatus = DataStatus.LOAD_FAILED
				Util.debug('DM (loadAiReport) err: ' + error.message) // quando scade la sessione qui ho undefined ?
				throw error // fix
			})
	}

	// *****************************************

	// 02.09.2020
	// ritorna un oggetto visita, con il campo xml decrittato
	loadVisitXml(request, keyImage, visitId: string): Promise<Visit> {
		request.method = 'GET'
		request.url = Config.visitsEndpoint + '/' + visitId

		//return this.http(request)
		return this.myGet(request)
			.then((response: any) => {
				var xmlCri = null

				//console.log("(DM loadVisitXml) response:"); // solo per test
				//console.log(response); // solo per test

				if (response != null && response.visit != null) {
					//console.log(response.data); // solo per test

					//xmlCri = response.data.visit.xml_blob;
					xmlCri = response.visit.xml_blob // 30.12.2021

					Util.debug('(DM loadVisitXml) going to decrypt...') // solo per test
					return Util.decryptObject(xmlCri, this.cryptoUtils, keyImage).then((xmlReadable) => {
						var myVisit = new Visit(response.visit)
						myVisit.xml_blob = xmlReadable // ora in chiaro
						return myVisit
					})
				} else {
					return response
				}
			})
			.catch((error) => {
				console.log('(loadVisitXml) err: ' + error.message) // quando scade la sessione qui ho undefined ?
				throw error // fix
			})
	}

	/*
  // 02.09.2020 
  // ritorna solo lo stream xml, decrittato
  OLDloadVisitXml(request, keyImage, visitId: string) {
    request.method = "GET";        
    request.url = Config.visitsEndpoint + "/" + visitId;
            
    return this.http(request)
      .then((response: any) => {      
        
        var xmlCri = null;
        if(response.data != null && response.data.visit != null){            
          
          //console.log(response.data); // solo per test            
          xmlCri = response.data.visit.xml_blob;
          console.log("(DM loadVisitXml) going to decrypt..."); // solo per test
          return data.Util.decryptObject(xmlCri, this.cryptoUtils, keyImage);

        } else {
          return response;
        } 

      })
      .catch((error) => {                         
        console.log("(loadVisitXml) err: "+error.message);  // quando scade la sessione qui ho undefined ?
        throw error;  // fix
    });  
  }
  */

	// ***********************

	// 12.05.2020
	getReports(patientId): Report[] {
		var ret = null

		if (this.reportList && this.reportList.length > 0) {
			var rep = this.reportList[0]
			if (rep.patient_id == patientId) ret = this.reportList
		}

		return ret
	}

	// 09.03.2021 devices ******

	loadDevices(request) {
		request.method = 'GET'
		request.url = Config.devicesEndpoint

		this.devicesList = []
		this.devicesListStatus = DataStatus.LOADING

		return this.myGet(request)
			.then((response: any) => {
				// console.log(response)
				//var devices = response.data.devices;
				var devices = response.devices
				this.addDevicesToList(devices)
				this.devicesListStatus = DataStatus.LOADED
				return true
			})
			.catch((error) => {
				console.log('(loadDevices) error!')
				this.devicesListStatus = DataStatus.LOAD_FAILED
				throw error
			})
	}

	private addDevicesToList(devices: Device[]) {
		Util.debug('(DM) Add devices to list...')

		for (let dev of devices) {
			let device = new Device(dev)
			this.deviceListMap.set(device.id, device)
		}

		this.devicesList = Array.from(this.deviceListMap.values())

		this.deviceListChanged.next(this.devicesList)
	}

	public updateDeviceList(device: Device) {
		Util.debug('(DM) Update device on list...')

		this.deviceListMap.set(device.id, device)

		this.devicesList = Array.from(this.deviceListMap.values())

		// this.deviceListChanged.next(this.devicesList)
	}

	public getDevicesList(): Device[] {
		return this.devicesList
	}

	public getDevice(id: number): Device | null {
		let device = null

		if (this.deviceListMap.has(id)) {
			device = this.deviceListMap.get(id)
		}

		return device
	}

	loadDevice(request, devId: number) {
		request.method = 'GET'
		request.url = Config.devicesEndpoint + '/' + devId

		this.device = null
		this.deviceStatus = DataStatus.LOADING
		Util.debug('DM (loadDevice) going to ask for device ' + devId)

		return this.myGet(request)
			.then((response: any) => {
				// DeviceResponse
				// console.log(response)
				//this.device = new Device(response.data.device);  // 20.06.2022
				this.device = new Device(response.device)
				this.deviceStatus = DataStatus.LOADED
				Util.debug('(loadDevice) ok dev ' + this.device.id)
				return true
			})
			.catch((error) => {
				console.log('(loadDevice) error!')
				this.device = null
				this.deviceStatus = DataStatus.LOAD_FAILED
				throw error
			})
	}

	isLoadingDevices() {
		return this.devicesListStatus == DataStatus.LOADING
	}

	hasLoadedDevices() {
		return this.devicesListStatus == DataStatus.LOADED
	}

	isLoadingDevice() {
		return this.deviceStatus == DataStatus.LOADING
	}

	hasLoadedDevice() {
		return this.deviceStatus == DataStatus.LOADED
	}

	// ********** agreements *******************

	// 25.05.2022 per gli admins
	loadAgreementsList(request, country?, target?): Promise<Agreement[]> {
		this.agreementsList = null
		this.agreementListStatus = DataStatus.LOADING

		request.method = 'GET'

		// 20.01.2021 aggiunto filtro gruppo e lingua
		request.url = Config.agreementsEndpoint

		if (target) {
			request.url += '?target=' + target // 20.02.2023
			if (country) {
				request.url += '&country=' + country
			}
		} else if (country) {
			request.url += '?country=' + country
		}

		// if (myAgrStatus) {
		// 	request.url += '&purpose=general'
		// } else if (myAgrTHStatus) {
		// 	request.url += '&purpose=telehealth'
		// }

		return this.myGet(request)
			.then((result) => {
				this.agreementsList = [] // array vuoto

				if (result != null && result.agreements != null) {
					var listaTemp = result.agreements

					Util.debug('(loadAgreements) OK tot: ' + listaTemp.length)

					for (let i = 0; i < listaTemp.length; i++) {
						var myAgr = new Agreement(listaTemp[i])
						this.agreementsList.push(myAgr)
					}
				} else {
					console.log('(loadAgreements) KO: ')
					console.log(result)
				}

				this.agreementListStatus = DataStatus.LOADED
				return this.agreementsList
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				console.log('(loadAgreements) - KO ' + msg)
				console.log(err) // es, token expired

				//this.agreementsList = null;
				this.agreementsList = [] // array vuoto

				this.agreementListStatus = DataStatus.LOAD_FAILED

				throw err //  la gestisce poi la chiamante --ls
			})
	}

	// 20.02.2023 agreement da far accettare agli optician
	// loadUsersAgreement(request, country): Promise<Agreement> {
	// 	return this.loadAgreement(request, 'optician', country)
	// }

	// 20.02.2023 agreement da far accettare al patient
	// loadPatientsAgreement(request, country): Promise<Agreement> {
	// 	return this.loadAgreement(request, 'patient', country)
	// }

	// agreement accettato dal patient
	// loadPatientAgreement(request, patId: number) {
	// 	return this.loadAgreement(request, 'patient', null, null, patId)
	// }

	// accepted by optician or graders
	loadAcceptedAgreement(request, userId?): Promise<Agreement[]> {
		Util.debug('(loadAcceptedAgreement) going to request... ' + userId)

		request.method = 'GET'
		if (userId) {
			request.url = Config.agreementsEndpoint + '/' + userId + '/acceptedlist'
		} else {
			request.url = Config.agreementsEndpoint + '/0/acceptedlist'
		}

		return this.myGet(request)
			.then((result) => {
				// console.log(result)

				if (result != null && result.agreements != null) {
					var listaTemp = result.agreements
					var agrList: Agreement[] = []

					Util.debug('(loadAgreements) OK tot: ' + listaTemp.length)

					for (let agr of listaTemp) {
						let agreement = new Agreement(agr)
						agreement.userId = userId
						agrList.push(agreement)
					}

					this.addAgreementsToList(agrList)
				} else {
					console.log('(loadAgreements) KO: ')
					console.log(result)
				}

				this.acceptedAgreementListStatus = DataStatus.LOADED
				return agrList
			})

			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				console.log('(loadAgreements) - KO ' + msg)
				console.log(err) // es, token expired

				this.acceptedAgreementListStatus = DataStatus.LOAD_FAILED

				throw err //  la gestisce poi la chiamante --ls
			})
	}

	private addAgreementsToList(agreements: Agreement[]) {
		for (let agr of agreements) {
			this.acceptedAgreementsListMap.set(agr.id, agr)
		}

		// é vero che viene chiamata sulla get di un singolo distributore, ma nel caso in futuro venga usata anche su liste di relazioni multi ditrib
		// mi ricavo i distributori nelle relazioni e su ognuno aggiungo le relazioni
		let distributorsId = [...new Set(agreements.map((item) => item.userId))]

		for (let distId of distributorsId) {
			if (this.distribListMap.has(+distId)) {
				let distributor = this.distribListMap.get(+distId)
				this.assignAgreementsInMemory(distributor)
			}
		}

		this.acceptedAgreementsList = Array.from(this.acceptedAgreementsListMap.values())
	}

	loadOpticianAgreementForPatient(request, country): Promise<Agreement[]> {
		Util.debug('(loadOpticianAgreementForPatient) going to request... ' + country)

		request.method = 'GET'
		request.url = Config.agreementsEndpoint + '?target=patient&country=' + country

		return this.myGet(request)
			.then((result) => {
				this.patientAgreementsList = []

				if (result != null && result.agreements != null) {
					const listaTemp = result.agreements

					Util.debug('(loadOpticianAgreementForPatient) OK tot: ' + listaTemp.length)

					for (let i = 0; i < listaTemp.length; i++) {
						const myAgr = new Agreement(listaTemp[i])

						this.patientAgreementsList.push(myAgr)
					}
				} else {
					console.log('(loadOpticianAgreementForPatient) KO: ')
					console.log(result)
				}

				return this.patientAgreementsList
			})

			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				console.log('(loadOpticianAgreementForPatient) - KO ' + msg)
				console.log(err)
				this.patientAgreementsList = [] // array vuoto

				this.agreementListStatus = DataStatus.LOAD_FAILED

				throw err
			})
	}

	// 20.02.2023 esteso con target
	// 17.02.2023 esteso con parametro agrId per acceptedByPatient
	// 25.05.2022 param user_id servira' agli admin, TODO
	// private loadAgreement(request, target: string, country: string, userId?: number, patId?: number, agrId?: number): Promise<Agreement> {
	// 	request.method = 'GET'
	// 	request.url = Config.agreementsEndpoint

	// 	if (patId) {
	// 		request.url += '/0/patient/?pat_id=' + patId // 17.02.2023 l'ultimo agr accettato
	// 	} else if (agrId || agrId == 0) {
	// 		// ok anche 0 -> l'ultimo accettato
	// 		request.url += '/' + agrId // 17.02.2023  quello accettato dall'utente loggato
	// 	} else {
	// 		request.url += '?target=' + target // 20.02.2023
	// 		if (country) {
	// 			request.url += '&country=' + country
	// 		}
	// 	}

	// 	//} else if(userId){  // TODO by admins
	// 	//  request.url += "&user_id="+userId;

	// 	this.agreementStatus = DataStatus.LOADING

	// 	return this.myGet(request)
	// 		.then((result) => {
	// 			if (result != null && result.agreement) {
	// 				let myAgr = new Agreement(result.agreement)
	// 				Util.debug('(loadAgreem) OK ' + myAgr.id)
	// 				//console.log(myAgr);  // solo per test, poi togliere

	// 				// gestione se c'e' gia'... usare treeMap ?
	// 				// per l'utente lev1 dovrebbe essercene sempre e solo uno, servira' per admins
	// 				// anche agreement per i pazienti, o refertatori con diversi opticians
	// 				this.addAgreement(myAgr)
	// 				this.agreementStatus = DataStatus.LOADED
	// 				//return true
	// 				return myAgr
	// 			} else {
	// 				console.log('DM (loadAgreem) empty response')
	// 				//Util.debug(result);
	// 				//this.agreementStatus = DataStatus.LOAD_FAILED;
	// 				this.agreementStatus = DataStatus.NOT_LOADED // meglio ?

	// 				//return false;
	// 				return null
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			var msg = err.data ? err.data.error : err.toString()
	// 			console.log('(loadAgreem) - KO ' + msg)
	// 			console.log(err) // es, token expired

	// 			this.agreementStatus = DataStatus.LOAD_FAILED

	// 			throw err //  la gestisce poi la chiamante --ls
	// 		})
	// }

	// 14.02.2023 aggiunto parametro id, per gestione agreement del paziente
	// alias piu' chiaro, da usare nei controllers
	getAgreementById(myId: number): Agreement {
		let myAgr: Agreement
		myAgr = null
		if (this.acceptedAgreementsListMap.has(myId)) {
			myAgr = this.acceptedAgreementsListMap.get(myId)
		}

		return myAgr
	}

	// 14.02.2023 aggiunto parametro id, per gestione agreement del paziente
	// 11.04.2022
	getAgreement(myCountry: string): Agreement {
		let myAgr: Agreement
		myAgr = null

		for (let agreement of this.acceptedAgreementsListMap.values()) {
			if (agreement.country == myCountry) {
				myAgr = agreement
				break
			}
		}

		return myAgr
	}

	// private addAgreement(myAgr: Agreement) {
	// 	let len = 0
	// 	let found = false

	// 	if (this.agreementsList) {
	// 		len = this.agreementsList.length
	// 	} else {
	// 		this.agreementsList = []
	// 	}

	// 	for (let i = 0; i < len; i++) {
	// 		// test sull'id e' univoco, su country potrebbero essercene due, ora che abbiamo anche per patient
	// 		//let eq = Util.equalsIgnoreCase(this.agreementsList[i].country, myAgr.country)
	// 		let eq = this.agreementsList[i].id == myAgr.id
	// 		if (eq) {
	// 			// sovrascrivo ?!
	// 			Util.debug('DM (addAgreement) already existing for ' + myAgr.country)
	// 			found = true
	// 			this.agreementsList[i] = myAgr
	// 			break
	// 		}
	// 	}

	// 	if (!found) {
	// 		this.agreementsList.push(myAgr)
	// 	}

	// 	len = this.agreementsList.length
	// 	return len
	// }

	// AVAILABLE
	isLoadingAgreements() {
		return this.agreementListStatus == DataStatus.LOADING
	}

	hasLoadedAgreements() {
		return this.agreementListStatus == DataStatus.LOADED
	}

	isLoadingAgreement() {
		return this.agreementStatus == DataStatus.LOADING
	}

	hasLoadedAgreement(country?) {
		return this.agreementStatus == DataStatus.LOADED
	}

	// ACCEPETED

	isLoadingAcceptedAgreements() {
		return this.agreementListStatus == DataStatus.LOADING
	}

	hasLoadedAcceptedAgreements() {
		return this.agreementListStatus == DataStatus.LOADED
	}

	isLoadingAcceptedAgreement() {
		return this.agreementStatus == DataStatus.LOADING
	}

	hasLoadedAcceptedAgreement(country?) {
		return this.agreementStatus == DataStatus.LOADED
	}

	// ************ change grader ********************

	loadChangeGraderForm(request, lang: string): Promise<ChangeGraderFormQuestion[]> {
		request.method = 'GET'
		request.url = Config.surveysEndpoint + '?lang=' + lang
		return this.myGet(request).then((response) => {
			const questionsList = response.questions
			if (questionsList && questionsList.length > 0) {
				return questionsList
			}
		})
	}

	// *********** anamnesis *****************

	// ritorna array di domande con traduzioni e array con opzioni per le risposte, per il gruppo richiesto
	loadAnamnesis(request, anamnGroup: number, lang: string): Promise<Anamnesis[]> {
		let anmList: Anamnesis[]
		anmList = []

		request.method = 'GET'
		request.url = Config.anamnesisEndpoint + '?group=' + anamnGroup + '&lang=' + lang
		return this.myGet(request).then((response) => {
			//console.log(response) // solo per test

			let list = response.questions
			if (list && list.length > 0) {
				for (let i = 0; i < list.length; i++) {
					let item = new Anamnesis(list[i])
					anmList.push(item)
				}
			}
			return anmList
		})
	}

	// domande e risposte date dal paziente
	loadPatientAnamnesis(request, patientId: number, lang: string, type: 'medical' | 'impact', repDate?, repId?): Promise<Anamnesis[]> {
		let anmList: Anamnesis[]
		anmList = null
		request.method = 'GET'
		request.url = (type === 'medical' ? Config.anamnesisEndpoint : Config.impactAnamnesisEndpoint) + '/' + patientId + '?lang=' + lang
		if (repDate) {
			request.url += '&before=' + repDate
		} else if (repId) {
			request.url += '&report=' + repId
		}
		return this.myGet(request).then((response) => {
			//console.log(response) // solo per test

			let list = response.questions
			if (list && list.length > 0) {
				anmList = []
				for (let i = 0; i < list.length; i++) {
					let item = new Anamnesis(list[i])
					anmList.push(item)
				}
			}
			// console.log(anmList)

			if (anmList && anmList.length > 0) {
				this.updatePatientAnamn(patientId, anmList, type, repDate, repId)
			}

			return anmList
		})
	}

	// *********** anamnesis VA *****************

	loadPatientVA(request, patientId: number, repDate?, repId?): Promise<VA> {
		request.method = 'GET'
		request.url = `${Config.vaEndpoint}/${patientId}`

		const queryParams = []

		if (repDate) {
			queryParams.push(`day=${repDate}`)
		} else if (repId) {
			queryParams.push(`report=${repId}`)
		}

		if (queryParams.length > 0) {
			request.url += `?${queryParams.join('&')}`
		}

		return this.myGet(request)
			.then((response) => {
				// console.log(response)

				let va: VA
				va = new VA(response.patient_va)

				if (response.patient_va) {
					this.updateVaPatient(patientId, va, repDate, repId)
				}

				return va
			})
			.catch((err) => {
				console.log('loadPatientVA - error')
				console.log(err)

				throw err
			})
	}

	private updatePatientAnamn(patId: number, anamnList: Anamnesis[], type: 'medical' | 'impact', repDate: string, repId: number) {
		const anamnMap = type === 'medical' ? this.medicalAnamnesisMap : this.impactAnamnesisMap
		const key = `${patId}-${type}`

		if (anamnMap.has(key)) {
			let existingValue = anamnMap.get(key)
			if (existingValue) {
				existingValue.push({ date: repDate, repId: repId, anamnesis: anamnList })
				anamnMap.set(key, existingValue)
			}
		} else {
			anamnMap.set(key, [{ date: repDate, repId: repId, anamnesis: anamnList }])
		}
	}

	public getPatientAnam(patId: number, type: 'medical' | 'impact', repDate: string, repId: number): { result: boolean; anamnesis: Anamnesis[] } {
		const anamnMap = type === 'medical' ? this.medicalAnamnesisMap : this.impactAnamnesisMap
		const key = `${patId}-${type}`

		let result: { result: boolean; anamnesis: Anamnesis[] } = { result: false, anamnesis: [] }

		if (anamnMap.has(key)) {
			let values = anamnMap.get(key)
			let val = values.find((value) => value.date == repDate && value.repId == repId)
			if (val) {
				result.result = true
				result.anamnesis = val.anamnesis
			}
		}

		return result
	}

	private updateVaPatient(patId: number, va: VA, repDate: string, repId: number) {
		if (this.vaMaps.has(patId)) {
			let existingValue = this.vaMaps.get(patId)
			if (existingValue) {
				existingValue.push({ date: repDate, repId: repId, va: va })
				this.vaMaps.set(patId, existingValue)
			}
		} else {
			this.vaMaps.set(patId, [{ date: repDate, repId: repId, va: va }])
		}
	}

	public getVAPatient(patId: number, repDate: string, repId: number): { result: boolean; va: VA } {
		let result: { result: boolean; va: VA } = { result: false, va: null }

		if (this.vaMaps.has(patId)) {
			let values = this.vaMaps.get(patId)
			let val = values.find((value) => value.date == repDate && value.repId == repId)
			if (val) {
				result.result = true
				result.va = val.va
			}
		}
		return result
	}

	// 18.03.2021  ******** software updates ***********

	// lista di tutti quelli disponibili sul server
	// 23.03.2021 se presente parametro devId, filtra su quelli disponibili per uno specifico device
	loadUpdates(request): Promise<SwUpdate[]> {
		const promise = new Promise<SwUpdate[]>((resolve, reject) => {
			request.method = 'GET'

			//} else if(devModel){
			//  request.url = Config.updatesEndpoint + "/device?model=" + devModel; // ricerca con filtro

			request.url = Config.updatesEndpoint

			this.updatesList = []
			this.updatesListStatus = DataStatus.LOADING

			this.myGet(request)
				.then((response) => {
					// console.log(response) // solo per test
					var updates = response.updates

					for (let i = 0; i < updates.length; i++) {
						var swUpdt = new SwUpdate(updates[i])
						this.updatesList.push(swUpdt)
					}
					resolve(this.updatesList)
					this.updatesListStatus = DataStatus.LOADED
					console.log('(loadUpdates) tot ' + this.updatesList.length)
				})
				.catch((error) => {
					console.log('(loadUpdates) error!')
					this.updatesList = null
					this.updatesListStatus = DataStatus.LOAD_FAILED
					reject(error)
				})
		})
		return promise
	}

	loadDeviceUpdates(request, devId: number): Promise<SwUpdate[]> {
		const promise = new Promise<SwUpdate[]>((resolve, reject) => {
			request.method = 'GET'
			request.url = Config.updatesEndpoint + '/device?id=' + devId

			this.myGet(request)
				.then((response) => {
					// console.log(response) // solo per test
					var updates: SwUpdate[] = []

					if (response.updates.length > 0) {
						for (let updt of response.updates) {
							updates.push(new SwUpdate(updt))
						}
					}

					resolve(updates)
				})
				.catch((error) => {
					console.log('(loadDeviceUpdates) error!')
					reject(error)
				})
		})

		return promise
	}

	/* not used
  loadSwUpdate(request, devId: number, devModel? :string) {
    request.method = "GET";
    
    if(devId>0) {
      request.url = Config.updatesEndpoint + "/device?id=" + devId;
      //request.url = Config.updatesEndpoint + "/" + devId;
    
    //} else if(devModel){
    //  request.url = Config.updatesEndpoint + "/device?model=" + devModel; // ricerca con filtro
    
    } else {
      return false;
    }

    this.swUpdate = undefined;
    this.swUpdateStatus = DataStatus.LOADING;
    console.log("(loadSwUpdate) going to ask for swUpdt "+devId);

    return this.http(request)
    .then((response) => {   // : Contracts.SwUpdateResponse  TODO
      console.log(response);             
      this.swUpdate = new data.SwUpdate(response.data.updates);
      this.swUpdateStatus = DataStatus.LOADED;
      console.log("(loadSwUpdate) ok dev "+this.swUpdate.id);
      return true;
    })
    .catch((error) => {
      console.log("(loadSwUpdate) error!");
      this.swUpdate = null;
      this.swUpdateStatus = DataStatus.LOAD_FAILED;
      throw error;
    });

  }

  */

	/*
  // 26.06.2019
  public getTemplateById(templId: number) {   
    
    var templ : data.PdfTemplate;
    templ = null;
    
    if(this.hasLoadedTemplate() && this.pdfTemplateList != null){
      for(let i=0; i< this.pdfTemplateList.length; i++){
        if(this.pdfTemplateList[i].id == templId){
          templ = this.pdfTemplateList[i];
          console.log("(getTemplateById) trovato "+templId);  
          break;
        }
      }
    }

    //if(templ!=null)
    //  console.log("(getTemplateById) id "+templ.id);

    return templ;
  }

  // 01.08.2019 richiamata se viene cancellato, da templateList 
  public resetTemplateById(templId: number) {   
      
    if(this.hasLoadedTemplate() && this.pdfTemplateList != null){
      for(let i=0; i< this.pdfTemplateList.length; i++){
        if(this.pdfTemplateList[i].id == templId){
          this.pdfTemplateList[i] = null;
          console.log("(resetTemplateById) azzerato "+templId);  
          break;
        }
      }
    }
    return;
  }


  // 26.06.2019
  private addTemplate(templ : data.PdfTemplate) {           
    
    if(templ == null)
      return;

    if(this.pdfTemplateList == null){
      this.pdfTemplateList = [];
      console.log("(addTemplate) nuovo, aggiungo "+templ.id);  
      this.pdfTemplateList.push(templ);
      return;
    }
    
    var found = false;
    var i = 0;
    for(i=0; i< this.pdfTemplateList.length; i++){
      if(this.pdfTemplateList[i].id == templ.id) {               
        found = true; // gia' presente         
        // 02.08.2019 sovrascrivo, piu' fresco ? [ls]
        console.log("(addTemplate) "+i+" sovrascrivo "+templ.id);  
        this.pdfTemplateList[i] = templ;
        break;
      }
    }
    
    if(found == false){
      console.log("(addTemplate) "+i+" aggiungo in coda "+templ.id);  
      this.pdfTemplateList.push(templ);
    }

    return;
  }

  */

	/*
  // 10.04.2019 se viene sovrascritto o cancellato --ls
  resetExternalReport(myId){
    if(this.externalReport && this.externalReport.patient_id == myId) {
      this.externalReport = null;
    }
  }
*/

	// 18.10.2019 dicom servers list  **********************

	/*
  
  loadDicomServers(request){
    //this.dicomSrvList = null;
    this.dicomSrvListStatus = DataStatus.LOADING;

    request.method = "GET";
    request.url = Config.dicomSrvEndpoint;

    return this.http(request)
    .then((result) => {        
        var lista = result.data.server;  
        //this.dicomSrvList = [];   // array vuoto           

        if(lista != null){          
          console.log("(loadDicomServers) OK "+lista.length); 
          console.log(lista);   // solo per test           
          
          for(let i=0; i<lista.length; i++){
            var dicomSrv = new data.DicomServer(lista[i]); 
            this.addDicomServer(dicomSrv);              
          }
        }
          
        this.dicomSrvListStatus = DataStatus.LOADED;

        return true; 
      
      })
      .catch((err) =>  {          
          var msg = (err.data)? err.data.error : err.toString();  // 02.05.2018          
          console.log("(loadDicomServers) - KO "+msg);   
          console.log(err); // es, token expired
          
          //this.dicomSrvList = null;
          this.dicomSrvListStatus = DataStatus.LOAD_FAILED;

          throw err; // cosi' la gestisce poi la chiamante --ls
          
      });
  }



  
  public addDicomServer(dSrv : data.DicomServer) {           
    
    if(dSrv == null)
      return;

    if(this.dicomSrvList == null){
      this.dicomSrvList = [];
      //console.log("(addDicomServer) nuovo, aggiungo "+dSrv.id);  
      this.dicomSrvList.push(dSrv);
      this.dicomSrvListStatus = DataStatus.LOADED; 
      return;
    }
    
    var found = false;
    var i = 0;
    for(i=0; i< this.dicomSrvList.length; i++){
      if(this.dicomSrvList[i].id == dSrv.id) {               
        found = true; // gia' presente                 
        //console.log("(addDicomServer) "+i+" sovrascrivo "+dSrv.id);  
        this.dicomSrvList[i] = dSrv;
        break;
      }
    }
    
    if(found == false){
      //console.log("(addDicomServer) "+i+" aggiungo in coda "+dSrv.id);  
      this.dicomSrvList.push(dSrv);
    }
    this.dicomSrvListStatus = DataStatus.LOADED; 
    return;
  }


  // 10.2019 se viene sovrascritto o cancellato --ls
  removeDicomServer(myId){
    // TODO
  }

  // 22.10.2019
  resetDicomServers(){
    this.dicomSrvList = null;
    this.dicomSrvListStatus = DataStatus.NOT_LOADED; 
    console.log("(resetDicomServers) done.");  
  }

*/

	// *********** patients ************

	isLoadingPatients() {
		return this.patientListStatus == DataStatus.LOADING
	}

	hasLoadedPatients() {
		return this.patientListStatus == DataStatus.LOADED
	}

	getTotPatients() {
		let tot = 0
		if (this.patientList != null) {
			tot = this.patientList.length
		}
		return tot
	}

	// isLoadingPatient() {
	// 	return this.patientStatus == DataStatus.LOADING
	// }

	hasLoadedPatient(patId: number, full?: boolean): { resp: boolean; pat: Patient | null } {
		// il parametro full serve per avere il paziente full con anche address
		// nella get patientList normale, l'address non viene giú, arriva array vuoto
		// quando poi viene richiesta la get sul singolo pat, ritorna anche l'address
		let ret: { resp: boolean; pat: Patient | null } = { resp: false, pat: null }

		if (this.patientListMap.has(patId)) {
			ret = { resp: true, pat: this.patientListMap.get(patId) }

			if (full && ret.pat.addresses.length == 0) {
				ret.resp = false
			}
		}

		return ret
	}

	// ******** doctors **************

	isLoadingDoctors() {
		return this.doctorListStatus == DataStatus.LOADING
	}

	hasLoadedDoctors() {
		return this.doctorListStatus == DataStatus.LOADED
	}

	loadedDoctorsAreDecrypted() {
		let ret = false

		if (this.doctorList) {
			ret = this.doctorList[0].isDecrypted
		}

		return ret
	}

	getTotDoctors() {
		let tot = 0
		if (this.doctorList != null) {
			tot = this.doctorList.length
		}
		return tot
	}

	isLoadingDoctor() {
		return this.doctorStatus == DataStatus.LOADING
	}

	// 24.02.2021, esteso con parametro docId, richiamare sempre questo
	/*
  hasLoadedDoctor() {
    return (this.doctorStatus == DataStatus.LOADED);
  }
  */
	hasLoadedDoctor(docId) {
		var ret = false
		ret = this.doctorStatus == DataStatus.LOADED
		if (ret && docId) {
			//Util.debug('DM (hasLoadedDoctor) TEST - ' + docId + ' ? ' + ret);
			ret = false
			if (this.doctor != null) {
				if (this.doctor.id == docId) {
					ret = true
				}
			}
		}
		//Util.debug('DM (hasLoadedDoctor) ' + docId + ' ? ' + ret);
		return ret
	}

	// ******** visits **************

	isLoadingVisits() {
		return this.visitListStatus == DataStatus.LOADING
	}

	hasLoadedVisits() {
		return this.visitListStatus == DataStatus.LOADED
	}

	getTotVisits() {
		let tot = 0
		if (this.visitList != null) {
			tot = this.visitList.length
		}
		return tot
	}

	// ******** exams **************

	isLoadingExams() {
		return this.examListStatus == DataStatus.LOADING
	}

	hasLoadedExams() {
		return this.examListStatus == DataStatus.LOADED
	}

	isLoadingExam() {
		return this.examStatus == DataStatus.LOADING
	}

	hasLoadedExam() {
		return this.examStatus == DataStatus.LOADED
	}

	/*
  isLoadingImages() {
    return this.imageStatus == DataStatus.LOADING;
  }

  hasLoadedImages() {
    return this.imageStatus == DataStatus.LOADED;
  }
  */

	// ******** specialists distrib remotes **************

	hasLoadedDistributor(myId?) {
		var ret = false
		ret = this.distribStatus == DataStatus.LOADED

		if (ret && myId) {
			ret = false
			if (this.distrib != null) {
				if (this.distrib.id == myId) {
					ret = true
				}
			}
		}

		//Util.debug("DM (hasLoadedDoctor) "+docId+" ? "+ret);

		return ret
	}

	isLoadingDistributor() {
		return this.distribStatus == DataStatus.LOADING
	}

	isLoadingDistribList() {
		return this.distribListStatus == DataStatus.LOADING
	}

	hasLoadedDistribList() {
		return this.distribListStatus == DataStatus.LOADED
	}

	isLoadingRels() {
		return this.relsListStatus == DataStatus.LOADING
	}

	// 07.06.2023 aggiunto test per collegare il grader alla sua lista di relazioni
	hasLoadedRels(distribId: number) {
		return this.relsListStatus == DataStatus.LOADED && this.graderId == distribId
	}

	// 04.05.2022
	getTotRemotes() {
		let tot = 0
		// if (this.distribList != null) {
		// 	tot = this.distribList.length
		// }

		tot = this.distribListMap.size
		return tot
	}

	// 06.05.2022
	getDistribFromList(usrId: number): Distrib {
		var distributor = null

		if (this.distrib != null && this.distrib.user_id == usrId) {
			Util.debug('DM (getDistribFromList) got single, using it')
			return this.distrib
		}

		if (this.distribListMap.has(usrId)) {
			distributor = this.distribListMap.get(usrId)
		}
		// troppe trace con le cliniche
		//if (!myLev2) {
		//Util.debug('DM (getDistribFromList) not found id ' + usrId)
		//}

		return distributor
	}

	// ******** admins **************

	isLoadingAdmins() {
		return this.adminListStatus == DataStatus.LOADING
	}

	hasLoadedAdmins() {
		return this.adminListStatus == DataStatus.LOADED
	}

	isLoadingAdmin() {
		return this.adminStatus == DataStatus.LOADING
	}

	hasLoadedAdmin() {
		return this.adminStatus == DataStatus.LOADED
	}

	// 28.01.2022
	getTotAdmins() {
		let tot = 0
		if (this.adminList != null) {
			tot = this.adminList.length
		}
		return tot
	}

	// ******** reports **************

	isLoadingReportList() {
		return this.reportListStatus == DataStatus.LOADING
	}

	// 26.06.2020 aggiunto controllo sul paziente corrente, serve ? [ls]
	hasLoadedReportList(patId?) {
		var flag = this.reportListStatus == DataStatus.LOADED

		if (flag && patId != null) {
			if (this.reportList != null && this.reportList.length > 0) {
				var currPat = this.reportList[0].patient_id
				if (currPat != patId) {
					console.log('(dataM) error on report list! local pat ' + currPat + ' asked for ' + patId)
					flag = false
				}
			}
		}

		return flag
	}

	// 20.12.2021
	getTotReports() {
		let tot = 0
		if (this.reportList != null) {
			tot = this.reportList.length
		}
		return tot
	}

	// ******** impact ********

	loadImpactReport(request, patientId: number, day: string): Promise<ImpactReportData> {
		request.url = Config.impactEndpoint

		if (patientId && day) {
			request.url += '/' + patientId + '?day=' + day
		}

		return this.myGet(request)
			.then((res) => {
				return res
			})
			.catch((error) => {
				console.log('DM (loadImpactReport) ko!')
				console.log(error)
				return error
			})
	}

	loadImpactAvailableDates(request, patientId: number): Promise<ImpactDates> {
		request.url = Config.impactEndpoint

		if (patientId) {
			request.url += '/' + patientId + '/available_days'
		}

		return this.myGet(request)
			.then((res) => {
				return res
			})
			.catch((error) => {
				console.log('DM (loadImpactAvailableDates) ko!')
				console.log(error)
				throw error
			})
	}

	loadImpactTopicHistory(request, patientId: number, topic: string, day: string): Promise<ImpactHistoryData> {
		request.url = Config.impactEndpoint

		if (patientId && topic && day) {
			request.url += '/' + patientId + '/historical?topic=' + topic + '&from_day=' + day
		}

		return this.myGet(request)
			.then((res) => {
				return res
			})
			.catch((error) => {
				console.log('DM (loadImpactTopicHistory) ko!')
				console.log(error)
				throw error
			})
	}

	loadImpactRecommendations(request, patientId: number, data: ImpactRecommendationRequest): Promise<ImpactRecommendationResponse> {
		request.url = Config.impactEndpoint

		if (patientId) {
			request.url += '/' + patientId + '/lenses'
		}

		return this.myPost(request, data)
			.then((res) => {
				return res
			})
			.catch((error) => {
				console.log('DM (loadImpactRecommendations) ko!')
				console.log(error)
				return error
			})
	}

	// ***********************************

	// ***********************************

	/*
  hasLoadedTemplateNames() {
    return (this.templNamesStatus == DataStatus.LOADED);
  }


  isLoadingTemplate() {
    return (this.templStatus == DataStatus.LOADING);
  }
  hasLoadedTemplate() {
    return (this.templStatus == DataStatus.LOADED);
  }

  // 18.10.2019
  isLoadingDicomServers() {
    return (this.dicomSrvListStatus == DataStatus.LOADING);
  }
  hasLoadedDicomServers() {
    return (this.dicomSrvListStatus == DataStatus.LOADED);
  }

  */

	// 16.04.2020
	isLoadingCategory(catName) {
		var flag = false
		var catg = this.getCategory(catName)
		if (catg != null && catg.status == DataStatus.LOADING) {
			flag = true
		}
		return flag
	}

	hasLoadedCategory(catName) {
		var flag = false
		var catgInfo = this.getCategory(catName)

		// viene calcolato durante le add dei singoli esami
		if (catgInfo != null && catgInfo.status == DataStatus.LOADED) {
			flag = true
		}
		//console.log("DM (hasLoadedCategory) "+catName+": "+flag); // 03.01.2022
		return flag
	}

	// ***********************************

	// 18.03.2021
	isLoadingUpdates() {
		return this.updatesListStatus == DataStatus.LOADING
	}

	hasLoadedUpdates() {
		return this.updatesListStatus == DataStatus.LOADED
	}

	isLoadingSwUpdate() {
		return this.swUpdateStatus == DataStatus.LOADING
	}

	hasLoadedSwUpdate() {
		return this.swUpdateStatus == DataStatus.LOADED
	}
}
