import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { ImpactReportData, ImpactEyeData, ImpactTopic, ImpactSection, ImpactRecommendationResponse } from '../../models/impact.model'
import { SessionService } from 'src/app/service/session.service'
import { ImpactService } from 'src/app/service/impact.service'
import { CryptoUtilsService } from 'src/app/service/crypto-utils.service'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { TopicHistoryModal } from './topic-history.modal/topic-history.modal'
import { Patient } from 'src/app/models/patient.model'
import { ReadMoreModal } from './read-more.modal/read-more.modal'

@Component({
	selector: 'impact-modal',
	templateUrl: './impact.modal.html',
	styleUrls: ['./impact.modal.scss'],
})
export class ImpactModal implements OnInit {
	@Input() patient: Patient

	impactReportData: ImpactReportData
	impactRecommendationData: ImpactRecommendationResponse
	selectedDate: string
	oldestAvailableDate: string
	availableDates: string[]
	refractionData: ImpactSection
	adaptationProgressiveData: ImpactSection
	eyeStrainData: ImpactSection
	dayNightData: ImpactSection
	leftRefractionEyeData: ImpactEyeData
	rightRefractionEyeData: ImpactEyeData
	isLoadingReportData: boolean
	isLoadingRecommendationData: boolean
	topicHistoryModal: NgbModalRef
	readMoreModal: NgbModalRef

	faChevronDown = faChevronDown

	constructor(
		public session: SessionService,
		public cryptoUtils: CryptoUtilsService,
		public impactService: ImpactService,
		public activeModal: NgbActiveModal,
		public modalService: NgbModal
	) {
		this.impactReportData = null
		this.availableDates = []
		this.selectedDate = null
		this.oldestAvailableDate = null
		this.leftRefractionEyeData = null
		this.rightRefractionEyeData = null
		this.isLoadingReportData = false
		this.isLoadingRecommendationData = false
	}

	ngOnInit() {
		this.isLoadingReportData = true
		this.isLoadingRecommendationData = true
		this.impactService.getImpactAvailableDates(this.patient.id).then((res) => {
			this.availableDates = res.impact_available_dates
			this.selectedDate = this.availableDates[0]
			this.oldestAvailableDate = new Date(Math.min(...this.availableDates.map((date) => new Date(date).getTime()))).toISOString()
			this.impactService.getImpactReport(this.patient.id, this.selectedDate).then((data) => {
				this.handleImpactReportData(data)
				this.isLoadingReportData = false
			})
		})
	}

	onChangeDate(event: Event) {
		this.isLoadingReportData = true
		this.isLoadingRecommendationData = true
		this.selectedDate = (event.target as HTMLInputElement).value
		this.impactService.getImpactReport(this.patient.id, this.selectedDate).then((data) => {
			this.handleImpactReportData(data)
			this.isLoadingReportData = false
		})
	}

	handleImpactReportData(data: ImpactReportData) {
		// Initialize/reset in case of missing sections
		this.refractionData = null
		this.adaptationProgressiveData = null
		this.eyeStrainData = null
		this.dayNightData = null

		// sort eyes objects so that the 'right' is the first
		for (const section of data.impact.sections) {
			for (const topic of section.topics) {
				topic.eyes.sort((a, b) => (a.eye === 'right' ? -1 : 1))
			}
		}

		this.impactReportData = data

		this.impactReportData.impact.sections.forEach((el) => {
			switch (el.title) {
				case 'refraction':
					this.refractionData = el
					if (this.refractionData) {
						this.leftRefractionEyeData = this.refractionData.topics[0].eyes.find((el) => el.eye === 'left')
						this.rightRefractionEyeData = this.refractionData.topics[0].eyes.find((el) => el.eye === 'right')
					}
					break
				case 'adaptation_progressive':
					this.adaptationProgressiveData = el
					this.adaptationProgressiveData.show = false
					break
				case 'eye_strain':
					this.eyeStrainData = el
					this.eyeStrainData.show = false
					break
				case 'day_night':
					this.dayNightData = el
					this.dayNightData.show = false
					break
				default:
					break
			}
		})

		const ranges = {}
		for (const section of this.impactReportData.impact.sections) {
			if (section.title !== 'refraction') {
				for (const topic of section.topics) {
					ranges[topic.title] = topic.inRange ? 'in' : 'out'
				}
			}
		}

		this.impactService.getImpactRecommendations({ ranges }, this.patient.id, this.selectedDate).then((res) => {
			this.impactRecommendationData = res
			this.isLoadingRecommendationData = false
		})
	}

	formatDateString(s: string): string {
		return this.session.formatDate(new Date(s))
	}

	formatDateTimeString(s: string): string {
		return this.session.formatDateTime(new Date(s))
	}

	getMaxDecimals(a: number, b: number, c: number): number {
		// count decimal digits
		const countDecimals = (num: number): number => {
			const numString = num.toString()
			if (numString.includes('.')) {
				return numString.split('.')[1].length
			}
			return 0 // no decimals
		}

		const decimalsA = countDecimals(a)
		const decimalsB = countDecimals(b)
		const decimalsC = countDecimals(c)

		return Math.max(decimalsA, decimalsB, decimalsC)
	}

	openReadMoreModal(url: string) {
		this.readMoreModal = this.modalService.open(ReadMoreModal, { size: 'lg' })

		this.readMoreModal.componentInstance.url = url
	}

	openTopicHistory(section: ImpactSection, topic: ImpactTopic) {
		this.topicHistoryModal = this.modalService.open(TopicHistoryModal, {
			size: 'lg',
			windowClass: 'impactModalClass',
		})

		this.topicHistoryModal.componentInstance.section = section.title
		this.topicHistoryModal.componentInstance.topic = topic.title
		this.topicHistoryModal.componentInstance.normRange = { min: topic.range_low, max: topic.range_high }
		this.topicHistoryModal.componentInstance.patientId = this.patient.id
		this.topicHistoryModal.componentInstance.day = this.selectedDate
	}

	close() {
		this.activeModal.dismiss()
	}
}
