import { Injectable } from '@angular/core'

import {
	Exam,
	ExamImage,
	FundusExam,
	ExamType,
	ExamImage2,
	ExternalExam,
	DryEyeExam,
	TopoExam,
	PachyMultExam,
	RetroExam,
	WfExam,
	PachyExam,
	SubjectiveExam,
	FRExam,
} from '../models/exam.model'
import { Visit } from '../models/visit.model'
import { SessionService } from './session.service'

import { Config } from '../../config'

import { Util } from '../models/util.model'
import { Subject } from 'rxjs'
import { AiImage, AiPatient, AiReport, aiBatchId } from '../models/aiReport.model'
import { Patient } from '../models/patient.model'
import { DataModelService } from './data-model.service'
import { DiagnosisReport, ReportType } from '../models/pdf.model'
import { TranslateService } from '@ngx-translate/core'
import { AnamnesisService } from './anamnesis.service'
import { DateParser } from '../models/dateParser.model'
import { FullReport } from '../models/report.model'
import { CategExam, SrvDiagnosis } from '../models/diagnosis.model'
import { Anamnesis, VA } from '../models/anamnesis.model'
import { LoaderStatus } from '../elements/loader-status/loader-status.component'
import { CryptoUtilsService } from './crypto-utils.service'

import * as forge from 'node-forge'

// EXAMS TO CATEGORIES LEGEND
/*
	Config.CAT_ANAMNESIS (anamnesis)

	Config.CAT_ANTERIOR (anterior):  Config.EXM_DRYEYE (dryeye), Config.EXM_EXT (extphoto)
	Config.CAT_FUNDUS (fundus): Config.EXM_FUNDUS (fundus)
	Config.CAT_REFRACTION (refraction): Config.EXM_LM (lensmeter), Config.EXM_SBJ (subjective), Config.EXM_TOPO (topo), Config.EXM_WF (wf)
	Config.CAT_GLC (glaucoma): Config.EXM_PACHY (pachy), Config.EXM_RETRO (retro), Config.EXM_TONO (tono)
	Consifg.CAT_CORNEA (cornea): Config.EXM_PACHYMULT (pachymult), Config.EXM_TOPO (topo)
*/

export interface CategoriesDictionary {
	[key: string]: { exams: string[]; active: boolean; expected: number }
}

// export interface CategoriesDictionary {
// 	[key: string]: boolean
// }

@Injectable({ providedIn: 'root' })
export class reportsService {
	doctorId: number
	patientId: number
	currentPatient: Patient

	activeCategories: CategoriesDictionary

	public loadingFundus: Subject<boolean>

	private aiBatchId: string
	private currDevice: string

	private fundusExamList: FundusExam[] //esami in memoria
	private fundusExamListRequested: FundusExam[] //esami che vengono richiesti, nuovi o giá in memoria, che vengono poi ritornati al chiamante

	// private waitNewAiReport: boolean //quando apro la AiReport list, se questo é true rimane in ascolto per ricevere la lista aggiornata

	constructor(
		private session: SessionService,
		private dataService: DataModelService,
		private translator: TranslateService,
		private anamnesisService: AnamnesisService
	) {
		Util.debug('reportsService - START')

		this.fundusExamList = []
		this.fundusExamListRequested = []

		this.loadingFundus = new Subject<boolean>() //nella visitlist viene chiamato il next quando vengono caricate le immagini

		// this.waitNewAiReport = false

		this.activeCategories = {}

		this.initActiveCategories()
	}

	private initActiveCategories() {
		Config.CATEG_NAMES.forEach((category) => {
			this.activeCategories[category] = {
				exams: Config.CATEGORIES_EXAMS[category],
				active: false,
				expected: 0,
			}
		})
	}

	public getActiveCategoriesObj(): CategoriesDictionary {
		return this.activeCategories
	}

	// 1) AIReview

	// func. per salvare le fundus giá scaricate in memoria e non richiederle nuovamente
	public checkFundusInMemory(examList: ExamType[]) {
		Util.debug('reportsService - checkFundusInMemory')

		this.fundusExamListRequested = []

		let examListCopy = examList.slice()

		// console.log(examListCopy)
		// console.log(this.fundusExamList)

		const promise = new Promise<FundusExam[]>((resolve, reject) => {
			if (this.fundusExamList.length == 0) {
				Util.debug('(reportsService - checkFundusInMemory) Nessuna fundus richiesta precedentemente..')
			} else {
				Util.debug('(reportsService - checkFundusInMemory) Ci sono fundus richieste precedentemente..')
				for (let i = 0; i < examList.length; i++) {
					const exam = examList[i]

					let list = this.fundusExamList.filter((d) => d.id == exam.id) //filtro la lista per exam id, se vuota vuol dire che devo richiederlo

					// console.log(list)

					// se lista non vuota, non serve che richiedo quell'esame
					if (list.length > 0) {
						Util.debug('(reportsService - checkFundusInMemory) rimuovo lesame giá scaricato dalla lista')

						let indx = examListCopy.map((i) => i.id).indexOf(list[0].id) //examListCopy potrebbero non essere nello stesso ordine per cui devo cercare l'indice

						examListCopy.splice(indx, 1)

						this.fundusExamListRequested.push(list[0])
					}
				}
			}

			// console.log(this.fundusExamList)
			// console.log(this.fundusExamListRequested)

			// se examListCopy non é vuota vuol dire che ci sono esami da richiedere
			if (examListCopy.length > 0) {
				this.loadExams(examListCopy, this.patientId)
					.then((fundusExams) => {
						// console.log(fundusExams)

						for (let exam of fundusExams) {
							this.fundusExamList.push(exam as FundusExam)

							this.fundusExamListRequested.push(exam as FundusExam)
						}
						resolve(this.fundusExamListRequested)
					})
					.catch((err) => {
						Util.debug('VisitListService - loadCategoryExams fails')
						console.log(err)
						reject(err)
					})
			} else {
				resolve(this.fundusExamListRequested)
			}
		})

		return promise
	}

	// func per richiedere la quality di una immagine, ritorna la quality
	public getAiQuality(fundusImage: ExamImage) {
		Util.debug('(visitListService - getAiQuality) ')

		const promise = new Promise<number>((resolve, reject) => {
			let request: any = this.session.buildBaseRequest()
			request.method = 'POST'
			request.url = Config.aiReportsEndpoint + '/quality'

			let dataReq = {
				image: {
					examId: fundusImage.examId,
					fixation: fundusImage.descr,
					imgBase64: this.getImgBase64(fundusImage.image),
				},
			}

			let timeout = 360000 //5 minuti

			this.dataService
				.myPost(request, dataReq, timeout)
				.then((ris) => {
					let qLevel = 0
					if (ris && ris.qualityLevel) {
						qLevel = ris.qualityLevel
					}
					Util.debug('(requireAI) imgId: ' + fundusImage.imgId + ' ' + fundusImage.descr + ' level: ' + qLevel)
					fundusImage.quality = qLevel

					resolve(qLevel)
				})
				.catch((err) => {
					Util.debug('(getAiQuality) ko ')
					this.session.isExpired(err) // eventualmente forza logout
					reject(err)
				})
		})

		return promise
	}

	private getImgBase64(image: string): string {
		let ind = image.indexOf(CryptoUtilsService.JPEG_BASE64)
		let lenPatt = CryptoUtilsService.JPEG_BASE64.length

		let inizio = image.substring(0, 30)
		Util.debug('S (getAiQuality) inizio ' + inizio)

		if (ind == 0) {
			// inizia per...
			image = image.substring(lenPatt)
		}

		return image
	}

	// ## Step finale dopo aver chiesto la quality, se user continua vengono inviate
	public aiReportRequest(myImages: ExamImage[]) {
		Util.debug('(reportsService - aiReportRequest) ')

		this.currDevice = this.getDevice(myImages)

		const promise = new Promise<string>((resolve, reject) => {
			let options = {
				//channel: this.aiType,
				language: this.session.getLanguage(),
				timezone: Util.getSzTimezoneOffset(), // "+0200" ok per Europa/Rome
				device: this.currDevice,
			}

			// console.log(options)

			if (!options.device) {
				let msg = 'Options parameters without device!'
				reject(msg)
			}

			// chiede la key x crittare i dati paziente, poi invia richiesta grading con tutte img buone
			this.getPatInfoCri()
				.then((patInfoCritted) => {
					let patId = this.currentPatient.id
					// console.log(patInfoCritted)

					this.postAiReportRequest(patId, patInfoCritted, this.aiBatchId, myImages, options)
						.then((ris) => {
							Util.debug(ris) // ok, stampa il batchId
							resolve(ris)
						})
						.catch((err) => {
							// let msg = this.session.parseErrorMessage(err, 'alert')
							// alert(msg)
							reject(err)
						})
				})
				.catch((err) => {
					console.log(err)
					let msg = this.session.parseErrorMessage(err, 'alert')
					reject(msg)
					// resolve('gtbrini-5489dnj3-vsdndus') //for test
				})
		})

		return promise
	}

	// TO DO Da fixare nel caso immagini da device diversi
	private getDevice(images: ExamImage[]) {
		let examId

		// fix quando si seleziona solo 1 immagine destra, ritornava device undefined
		if (images[0].examId > 0) {
			examId = images[0].examId
		} else {
			examId = images[1].examId
		}

		for (let i = 0; i < this.fundusExamList.length; i++) {
			const exam = this.fundusExamList[i]

			if (exam.id == examId) {
				return exam.device
			}
		}
	}

	// 21.07.2022 need this to encrypt patient data to be on the report
	private getPatInfoCri(): Promise<string> {
		Util.debug('reportsService - getPatInfoCri')
		return this.session.getAiKey().then((ris) => {
			if (ris && ris.keybox) {
				let resp = ris.keybox // contiene sia la keybox che il batchId
				//Util.debug(resp);

				this.aiBatchId = resp.batch
				let keybox = resp.symmetricKey

				Util.debug('(getAiKeybox) batch:' + this.aiBatchId) // ok
				Util.debug('(getAiKeybox) box:' + keybox) // ok

				// patInfoCritted
				return this.crittaPatient(keybox)
			} else {
				Util.debug(ris)
				return Promise.reject('ko ')
			}
		})
	}

	private crittaPatient(aiKeybox?) {
		let myPatInfo: AiPatient // nested json

		let myPat = this.currentPatient

		Util.debug('(crittaPatient) DOB:' + myPat.birthDate + ' toVistel: ' + myPat.getDobForAi())

		myPatInfo = {
			name: myPat.getFullName(),
			gender: myPat.getGenderForAi(),
			birthday: myPat.getDobForAi(), // YYYYMMDD,
			isDiabetes: null,
			isHypertension: null,
			isHighMyopia: null,
			laserPhotocoagulation: {
				exist: null,
				data: [],
			},
			medicalHistory: null,
		}

		return this.session
			.extractAiRedKey(aiKeybox) // with user's RSA private key
			.then((redKey) => {
				// 22.07.2022 forzato stringify
				return this.session.encryptData(redKey, JSON.stringify(myPatInfo))
			})
			.catch((myErr) => {
				Util.debug('(crittaPat) ko!')
				return Promise.reject(myErr)
			})
	}

	private postAiReportRequest(patId: number, myPatInfoCri: string, myBatch: string, myImages: ExamImage[], options?: any): Promise<any> {
		let request: any = this.session.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.aiReportsEndpoint + '/grading'

		let imgList: AiImage[] = []

		for (let img of myImages) {
			imgList.push(new AiImage(img))
		}

		Util.debug('S (getAiQuality) full myPatInfoCri: ' + myPatInfoCri)

		let binary = forge.util.decode64(myPatInfoCri)
		let input = forge.util.createBuffer(binary)

		// read the iv and take it away from the input
		let origIV = input.getBytes(16)
		let origInfo = input.getBytes() // rimanente

		// rimetto in base64 le due parti
		let myIv = forge.util.encode64(origIV)
		let myPatCri = forge.util.encode64(origInfo)

		Util.debug('S (getAiQuality) iv ' + myIv)
		Util.debug('S (getAiQuality) myPatCri ' + myPatCri)

		if (!options) {
			options = {
				//channel: "dss",
				language: this.session.getLanguage(),
				timezone: '+0200',
				device: 'VX610',
			}
		}

		let dataReq = {
			grading: {
				language: options.language,
				timezone: options.timezone,
				device: options.device,
				batchId: myBatch,
				images: imgList,
				patientId: patId,
				iv: myIv,
				patientInfo: myPatCri,
			},
		}

		let timeout = 360000 //5 minuti
		return this.dataService.myPost(request, dataReq, timeout)
	}

	// ### AIReview END #####

	public loadAiReports(patId: number): Promise<AiReport[]> {
		const promise = new Promise<AiReport[]>((resolve, reject) => {
			Util.debug('(loadReportsAi) for patient ' + patId)

			if (patId <= 0) {
				Util.debug('(loadReportsAi) invalid patient! ' + patId)
				reject('invalid patient')
				return
			}

			const aiReportList = this.dataService.getCustomerAiReportList(patId)
			var datetime: string | null = null

			if (aiReportList.reports.length > 0) {
				Util.debug('(loadReports) found ' + aiReportList.reports.length + ' reports')
				datetime = aiReportList.date
			}

			let request: any = this.session.buildBaseRequest()
			this.dataService
				.loadAiReportList(request, patId)
				.then((result) => {
					resolve(result)
				})
				.catch((error) => {
					console.log(error)
					reject(error)
				})
		})
		return promise
	}

	public loadAiReport(reportAi: AiReport): Promise<AiReport> {
		// TO DO, LEGGERE DA QUELLO IN MEMORIA
		let reportId = reportAi.id
		let myKeybox = reportAi.keybox

		Util.debug('reportsService (loadReportAi) id ' + reportId)
		let request: any = this.session.buildBaseRequest()

		return this.session.extractAiRedKey(myKeybox).then((myRedKey) => {
			return this.dataService.loadAiReport(request, reportId, myRedKey)
		})
	}

	//### Clear func ###

	public clearAllFundusList() {
		// alla logout
		Util.debug('reportsService - clearAllFundusList')
		this.fundusExamList = []
		this.fundusExamListRequested = []
	}

	// 2) HGReview
	// to do spostarle qua

	// 3) print PDF
	public printPDF(input: Visit | FullReport, type: ReportType): Promise<boolean> {
		const promise = new Promise<any>((resolve, reject) => {
			this.initActiveCategories()
			let reportPdf = new DiagnosisReport(this.session, this.dataService, this.translator, this.currentPatient)

			let patId: number
			let date: Date
			let exams: ExamType[] = []
			let anamDate = null
			let repId: number = null
			let force: boolean = false

			if (input instanceof Visit) {
				Util.debug('printPDF - input type Visit, reportType: ' + type)
				date = input.date
				patId = input.patient_id
				anamDate = DateParser.formatSqlDate(input.date)
				exams = input.visitExams

				reportPdf.setVisitDate(date)
			} else if (input instanceof FullReport) {
				Util.debug('printPDF - input type FullReport, reportType: ' + type)
				patId = input.patient_id
				repId = input.id
				reportPdf.setReportInfo(input)
				// anamDate = DateParser.formatSqlDateLong(input.creation_date)
				// mi ricavo ExamType[]
				let diagnosis: SrvDiagnosis[] = input.diagnosis
				let examList: ExamType[] = []

				diagnosis.forEach((d) => {
					for (let ex of d.exam_list) {
						examList.push(new ExamType(ex))
					}
				})

				// devo filtrare xk cosí ho duplicati, in quanto alcuni esami sono su piú categorie
				exams = examList.filter((exam, index, self) => index === self.findIndex((e) => JSON.stringify(e) === JSON.stringify(exam)))

				// console.log('exams:', exams)
			} else {
				reject('unknown input')
				return
			}

			if (type == ReportType.DIAGNOSIS) {
				// serve per il pdf per poterlo richiedere anche se non c'é NDM valido
				// aggiunge &force=true al URL
				force = true
			}

			reportPdf.setReportType(type)
			let examTypes: string[] = exams.map((exam) => exam.exam_type)

			// attivo le categories che contengono i tipi di esame nella visita
			// essenzialmente solo perché gli serve al pdf avere un oggetto simile
			for (let exam of examTypes) {
				for (const category in this.activeCategories) {
					if (this.activeCategories[category].exams.includes(exam)) {
						this.activeCategories[category].active = true
						this.activeCategories[category].expected++
					}
				}
			}
			// this.dataService.initCategories(this.activeCategories)

			let promiseArray: [Promise<Exam[]>, Promise<Anamnesis[]>, Promise<VA>] = [
				this.loadExams(exams, patId, force),
				this.anamnesisService.getPatientAnamnesisAnswers(patId, 'medical', anamDate, repId),
				this.anamnesisService.getPatientVA(patId, anamDate, repId),
			]

			Promise.all(promiseArray)
				.then((resp) => {
					let exams = resp[0]
					let patAnam = resp[1]
					let patVa = resp[2]

					let subjectiveExams = exams.filter((exam) => exam.exam_type == Config.EXM_SBJ) as SubjectiveExam[]
					if (subjectiveExams && subjectiveExams.length > 0) {
						reportPdf.setCombinedRefraction(true)
					}

					let fastRefractionExams = exams.filter((exam) => exam.exam_type == Config.EXM_FASTREFRACTION) as FRExam[]
					if (fastRefractionExams && fastRefractionExams.length > 0) {
						reportPdf.setIsFastRefraction(true)
					}

					let images: ExamImage2[] = this.initExamImagesForReport(exams)
					reportPdf.setImages(images)
					reportPdf.setExamList(exams)
					reportPdf.setVaAnswers(patVa)
					if ((patAnam && patAnam.length > 0) || input instanceof FullReport) {
						reportPdf.setAnamnesisAnswers(patAnam)
						this.activeCategories[Config.CAT_ANAMNESIS].active = true
					}

					this.checkForDisableCategories(exams)
					// console.log(this.activeCategories)
					reportPdf.setActiveCategories(this.activeCategories)

					reportPdf.printPdf(this.currentPatient)

					resolve(true)
					return
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		})
		return promise
	}

	public loadExams(exams: ExamType[], patientId: number, force?: boolean): Promise<Exam[]> {
		// console.log('exams:', exams)

		const promise = new Promise<any>((resolve, reject) => {
			let examIds = exams.map((exam) => exam.id)
			let respFromData = this.dataService.getExamsInMemory(patientId)
			var response: Exam[] = []

			let examsToRequest: ExamType[] = []
			let missingExams: ExamType[] = []

			if (respFromData.hasExams) {
				// ha esami salvati in memoria ma sono tutti? controllo
				// potrebbe aver aperto la categories, che si é scaricata solo la refraction, prima pagina e poi uscito
				missingExams = exams.filter((e) => !respFromData.exams.some((exam) => exam.id === e.id))
				// rispondo solo quelli richiesti
				response = respFromData.exams.filter((exam) => examIds.includes(exam.id))

				if (missingExams.length > 0) {
					examsToRequest = missingExams
				} else {
					resolve(response)
					return
				}
			} else {
				examsToRequest = exams
			}

			// if(examsToRequest.length > 0) {
			let request = this.session.buildBaseRequest()
			let key = this.session.getExamKey()

			if (!key || key == null) {
				Util.debug('(reportsService - loadExams) null key!!!')
				reject('ko key')
				return
			}

			let promiseArray: Promise<Exam>[] = []

			let loaderStatusData: LoaderStatus = {
				activateLoader: true,
				max: examsToRequest.length,
				current: 0,
				label: 'Esami',
			}
			this.dataService.loaderStatusChanges.next(loaderStatusData)

			for (let exam of examsToRequest) {
				promiseArray.push(
					this.dataService.loadExamsNew(request, key, exam.id, exam.exam_type, force).then((exams) => {
						loaderStatusData.current++
						this.dataService.loaderStatusChanges.next(loaderStatusData)
						return exams
					})
				)
			}

			Promise.all(promiseArray)
				.then((result) => {
					loaderStatusData.activateLoader = false
					this.dataService.loaderStatusChanges.next(loaderStatusData)
					// console.log(result)
					if (respFromData.hasExams) {
						response.push(...result)
					} else {
						response = result
					}

					resolve(response)
					return
				})
				.catch((error) => {
					console.log(error)
					reject(error)
					return
				})

			// }
		})
		return promise
	}

	//per tutti gli esami che ho creo le immagini
	// ExamImage2 é parallelo a ExamImage, poi sostiruirá ExamImage
	public initExamImagesForReport(exams: Exam[]): ExamImage2[] {
		let resultImages: ExamImage2[] = []

		for (const category in Config.CATEGORIES_EXAMS_WITH_iMAGES) {
			let examTypes = Config.CATEGORIES_EXAMS_WITH_iMAGES[category]
			let filteredExams = exams.filter((exam) => examTypes.includes(exam.exam_type))
			// solo 4 categorie prevedono immagini, quindi mi prendo solo quelle

			// ANTERIOR
			if (category == Config.CAT_ANTERIOR) {
				// NOTA: per farlo meglio queste distinzioni bisognerebbe credo farle nel new ExamImage2(exam)
				let externalExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_EXT && exam.hasImages) as ExternalExam[]
				let dryEyeExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_DRYEYE && exam.hasImages) as DryEyeExam[]

				// external
				if (externalExams && externalExams.length > 0) {
					for (let exam of externalExams) {
						for (let i = 0; i < Config.externalImgNames.length; i++) {
							const externalname = Config.externalImgNames[i]

							if (exam[externalname.db]) {
								let image: ExamImage2
								image = new ExamImage2(exam)
								image.image = exam[externalname.db]
								image.descr = externalname.label
								image.position = 0
								resultImages.push(image)
							}
						}
					}
				}

				// dryeye
				if (dryEyeExams && dryEyeExams.length > 0) {
					for (let exam of dryEyeExams) {
						if (exam.image) {
							let image: ExamImage2
							image = new ExamImage2(exam)
							image.image = exam.image
							image.descr = Config.descrImgDryEye[0]
							image.position = 0
							resultImages.push(image)
						}
					}
				}
			}

			// CORNEA
			if (category == Config.CAT_CORNEA) {
				let topoExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_TOPO && exam.hasImages) as TopoExam[]
				let PachyMultExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_PACHYMULT && exam.hasImages) as PachyMultExam[]

				// Topo
				if (topoExams && topoExams.length > 0) {
					for (let exam of topoExams) {
						for (let i = 0; i < Config.topoImgNames.length; i++) {
							const toponame = Config.topoImgNames[i]

							if (exam[toponame.db]) {
								let image: ExamImage2
								image = new ExamImage2(exam)
								image.image = exam[toponame.db]
								image.descr = toponame.label
								image.position = i
								resultImages.push(image)
							}
						}
					}
				}

				// PachyMulty
				if (PachyMultExams && PachyMultExams.length > 0) {
					for (let exam of PachyMultExams) {
						if (exam.image) {
							let image: ExamImage2
							image = new ExamImage2(exam)
							image.image = exam.image
							image.descr = Config.descrImgPachyMult[0]
							image.position = 0
							resultImages.push(image)
						}
					}
				}
			}

			// GLAUCOMA
			if (category == Config.CAT_GLC) {
				let retroExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_RETRO && exam.hasImages) as RetroExam[]
				let wfExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_WF && exam.hasImages) as WfExam[]
				let pachyExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_PACHY && exam.hasImages) as PachyExam[]

				// Retro
				if (retroExams && retroExams.length > 0) {
					for (let exam of retroExams) {
						for (let i = 0; i < Config.retroImgNames.length; i++) {
							const retroname = Config.retroImgNames[i]

							if (exam[retroname.db]) {
								let image: ExamImage2
								image = new ExamImage2(exam)
								image.image = exam[retroname.db]
								image.descr = retroname.label
								image.position = i
								resultImages.push(image)
							}
						}
					}
				}

				// WF
				if (wfExams && wfExams.length > 0) {
					for (let i = 0; i < wfExams.length; i++) {
						const exam = wfExams[i]
						if (exam.image_grid) {
							let image: ExamImage2
							image = new ExamImage2(exam)
							image.image = exam.image_grid
							image.descr = Config.descrImgWf[0]
							image.position = i
							resultImages.push(image)
						}
					}
				}

				// Pachy
				if (pachyExams && pachyExams.length > 0) {
					for (let exam of pachyExams) {
						if (exam.image_with_data != null) {
							let image: ExamImage2
							image = new ExamImage2(exam)
							image.image = exam.image_with_data
							image.descr = Config.descrImgPachyS[1]
							image.position = 1
							resultImages.push(image)
						} else if (exam.image != null) {
							let image: ExamImage2
							image = new ExamImage2(exam)
							image.image = exam.image
							image.descr = Config.descrImgPachyS[0]
							image.position = 0
							resultImages.push(image)
						}
					}
				}
			}

			//FUNDUS
			if (category == Config.CAT_FUNDUS) {
				let fundusExams = filteredExams.filter((exam) => exam.exam_type == Config.EXM_FUNDUS && exam.hasImages) as FundusExam[]

				if (fundusExams && fundusExams.length > 0) {
					for (let exam of fundusExams) {
						for (let i = 0; i < Config.fundusImgNames.length; i++) {
							const fundusname = Config.fundusImgNames[i]

							if (exam[fundusname.db]) {
								let image: ExamImage2
								image = new ExamImage2(exam)
								image.image = exam[fundusname.db]
								image.descr = fundusname.label
								image.position = i
								if (exam.extras) {
									let extraF = exam.getExtraByFixation(image.descr) // fissazione
									if (extraF) {
										image.quality = extraF.quality
										image.imgId = extraF.id
									}
								}
								resultImages.push(image)
							}
						}
					}
				}
			}
		}
		// console.log(resultImages)
		return resultImages
	}

	private checkForDisableCategories(exams: Exam[]) {
		// se device 'ER' cat GLC e CORNEA disabilitate
		// metto anche constrollo se non ha immagini in Or per GLC, mettendolo in OR devo stare attento se peró o altri esami di quella categoria non devo disabilitarla
		// nella categories non era in OR ma AND per cui il c'era cmq il problema su esami autocaricati

		// check WF
		let ERwfExams = exams.filter((exam) => exam.exam_type == Config.EXM_WF && exam.device == 'ER')
		let wfExamsWithImages = exams.filter((exam) => exam.exam_type == Config.EXM_WF && exam.hasImages) as WfExam[]
		let glaucExams = exams.filter((exam) => exam.exam_type == Config.EXM_PACHY || exam.exam_type == Config.EXM_RETRO || exam.exam_type == Config.EXM_TONO)

		if ((wfExamsWithImages && wfExamsWithImages.length == 0 && glaucExams && glaucExams.length == 0) || (ERwfExams && ERwfExams.length > 0)) {
			//disabilito la cetegory GLC
			this.activeCategories[Config.CAT_GLC].active = false
		}

		//check cornea
		let topoExams = exams.filter((exam) => exam.exam_type == Config.EXM_TOPO && exam.device == 'ER') as TopoExam[]

		if (topoExams && topoExams.length > 0) {
			this.activeCategories[Config.CAT_CORNEA].active = false
		}
	}
}
