<div class="loading-container" [hidden]="!dataService.isLoadingDoctors()">
	<div class="tail-spin">&nbsp;</div>
</div>
<div>
	<div class="" [hidden]="dataService.isLoadingDoctors()">
		<div class="container-fluid doctor-list-container">
			<div class="row">
				<div class="col-12">
					<div class="row">
						<div class="panel-head py-1">
							<div class="col">
								<div *ngIf="session.isSuperB()">
									<button type="button" class="btn btn-primary add-button" (click)="openCreateColleagueModal()">{{ 'DOCTOR_LIST.ADD_NEW' | translate }}</button>
								</div>
							</div>
							<div class="col">
								<div class="float-end">
									<button type="button" class="btn btn-primary btn-sm reload shadow" (click)="reloadList()" [disabled]="!reloadEnable">
										{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
									</button>
								</div>
							</div>
						</div>
					</div>
					<!-- 
					<div class="panel-head py-1">
						<div>
							<button type="button" class="btn add-button" (click)="test()">TEST</button>
						</div>
					</div> -->
					<div class="panel-body">
						<table mat-table [dataSource]="doctorList" matSort style="width: 100%">
							<ng-container matColumnDef="username" *ngIf="!session.isSpecialist()">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<!-- [disable] lo uso per disabilitare il sort -->
									<span *ngIf="!AdvanceChecked">{{ 'DOCTOR_LIST.USERNAME' | translate }} </span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'DOCTOR_LIST.USERNAME' | translate }}</div>
										<mat-form-field appearance="outline">
											<input matInput [formControl]="usernameFilter" />
										</mat-form-field>
										<a (click)="usernameFilter.setValue('')" class="reset-button" *ngIf="usernameFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)" [ngStyle]="{'color': element.isActive ? 'black' : 'rgb(70, 81, 82)', 'fontWeight': element.isActive ? '900' : '100', 'fontStyle': element.isActive ? 'normal' : 'italic'}">{{ element.username }}</td>
							</ng-container>
							<ng-container matColumnDef="username" *ngIf="session.isSpecialist()">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCTOR_LIST.USERNAME' | translate }}</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.username }}</td>
							</ng-container>

							<ng-container matColumnDef="user_type">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked">{{ 'DOCTOR.TYPE' | translate }}</span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'DOCTOR.TYPE' | translate }}</div>
										<mat-form-field appearance="outline" class="w4rem">
											<input matInput [formControl]="usertypeFilter" />
										</mat-form-field>
										<a (click)="usertypeFilter.setValue('')" class="reset-button" *ngIf="usertypeFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.getProfile()}}</td>
							</ng-container>

							<ng-container matColumnDef="user_subtype">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked">{{ 'DOCTOR.SUBTYPE' | translate }}</span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'DOCTOR.SUBTYPE' | translate }}</div>
										<mat-form-field appearance="outline" class="w4rem">
											<input matInput [formControl]="usersubtypeFilter" />
										</mat-form-field>
										<a (click)="usersubtypeFilter.setValue('')" class="reset-button" *ngIf="usersubtypeFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.user_subtype }}</td>
							</ng-container>

							<ng-container matColumnDef="is_test">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked">{{ 'CREATE_USER.TEST' | translate }}</span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'CREATE_USER.TEST' | translate }}</div>
										<mat-form-field appearance="outline" class="w3rem">
											<input matInput [formControl]="istestFilter" />
										</mat-form-field>
										<a (click)="istestFilter.setValue('')" class="reset-button" *ngIf="istestFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.is_test }}</td>
							</ng-container>

							<ng-container matColumnDef="code">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="session.isInstaller()" [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked || session.isSpecialist()">{{ 'DOCTOR.CODE' | translate }}</span>

									<div *ngIf="AdvanceChecked && !session.isSpecialist()" class="text-start">
										<div>{{ 'DOCTOR.CODE' | translate }}</div>
										<mat-form-field appearance="outline" class="w3rem">
											<input matInput [formControl]="codeFilter" />
										</mat-form-field>
										<a (click)="codeFilter.setValue('')" class="reset-button" *ngIf="codeFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" [hidden]="session.isInstaller()" (click)="showPatients(element.id)">{{ element.code }}</td>
							</ng-container>

							<ng-container matColumnDef="groupId">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked || session.isInstaller()">{{ 'DOCTOR.GROUP_ID' | translate }}</span>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.groupId }}</td>
							</ng-container>

							<ng-container matColumnDef="country">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>
									<span *ngIf="!AdvanceChecked">{{ 'DOCTOR_LIST.COUNTRY' | translate }}</span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'DOCTOR_LIST.COUNTRY' | translate }}</div>
										<mat-form-field appearance="outline" class="w4rem">
											<input matInput [formControl]="countryFilter" />
										</mat-form-field>
										<a (click)="countryFilter.setValue('')" class="reset-button" *ngIf="countryFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.country }}</td>
							</ng-container>

							<ng-container matColumnDef="organization">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked || session.isInstaller()">{{ 'DOCTOR.ORGANIZATION' | translate }}</span>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.mainAddress.organization }}</td>
							</ng-container>

							<ng-container matColumnDef="subscriptionTime">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>
									<span>{{ 'DOCTOR_LIST.CLIENT_SINCE' | translate }}</span>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ session.formatDate(element.subscriptionTime) }}</td>
							</ng-container>

							<ng-container matColumnDef="patientCount" *ngIf="!session.isSpecialist()">
								<th mat-header-cell *matHeaderCellDef [hidden]="session.isInstaller()" mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked">{{ 'DOCTOR_LIST.PATIENT_COUNT' | translate }}</span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'DOCTOR_LIST.PATIENT_COUNT' | translate }}</div>
										<mat-form-field appearance="outline" class="w4rem">
											<input matInput [formControl]="patientcountFilter" />
										</mat-form-field>
										<a (click)="patientcountFilter.setValue('')" class="reset-button" *ngIf="patientcountFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" [hidden]="session.isInstaller()" (click)="showPatients(element.id)">{{ element.patientCount }}</td>
							</ng-container>

							<ng-container matColumnDef="patientCount" *ngIf="session.isSpecialist()">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCTOR_LIST.PATIENT_COUNT' | translate }}</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.visiblePatCount}}</td>
							</ng-container>

							<ng-container matColumnDef="created_by">
								<th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]=" disableSort=='true' ? true : false ">
									<span *ngIf="!AdvanceChecked">{{ 'DISTRIB_LIST.CREATED_BY' | translate }}</span>

									<div *ngIf="AdvanceChecked" class="text-start">
										<div>{{ 'DISTRIB_LIST.CREATED_BY' | translate }}</div>
										<mat-form-field appearance="outline">
											<input matInput [formControl]="createdbyFilter" />
										</mat-form-field>
										<a (click)="createdbyFilter.setValue('')" class="reset-button" *ngIf="createdbyFilter.value"><fa-icon [icon]="faXmark"></fa-icon></a>
									</div>
								</th>
								<td mat-cell *matCellDef="let element" (click)="showPatients(element.id)">{{ element.created_by }}</td>
							</ng-container>

							<ng-container matColumnDef="info">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MESSAGE.INFO' | translate }}</th>
								<td mat-cell *matCellDef="let element">
									<a (click)="openInfoModal(element)" ngbTooltip="{{ 'RELATIONS.INFO_HINT' | translate }}" placement="right">
										<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
									</a>
								</td>
							</ng-container>

							<ng-container matColumnDef="filter">
								<th mat-header-cell *matHeaderCellDef>
									<mat-form-field appearance="outline" *ngIf="!session.isSpecialist()"> <input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter [disabled]="AdvanceChecked" /> </mat-form-field>&nbsp;

									<mat-slide-toggle *ngIf="session.isLevel3() && !session.isSpecialist()" [color]="color" [(ngModel)]="AdvanceChecked" [disabled]="disabled" ngbTooltip="Advance filter" (change)="advanceFilter()"> </mat-slide-toggle>
									<mat-form-field *ngIf="session.isSpecialist()">
										<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
									</mat-form-field>
								</th>

								<td mat-cell *matCellDef="let element">
									<div *ngIf="!session.isSpecialist()">
										<a class="table-button" *ngIf="session.isLevel3() || session.isSuperB()" [hidden]="element.isDeleted()" (click)="openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}"><fa-icon [icon]="faTrashAlt" aria-hidden="true"></fa-icon> </a>

										<!-- check for isSupport -->
										<a class="table-button ms-1" *ngIf="session.isLevel3() || session.isSuperB() || session.isSupport()" [hidden]="element.isDeleted()" [ngbTooltip]="(session.isSupport() && !session.isSuperSupport()) ? ('BUTTONS.SHOW' | translate) : ('BUTTONS.EDIT' | translate) " (click)="openDoctorModal(element)">
											@if(session.isSupport() && !session.isSuperSupport()){
											<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
											}@else {
											<fa-icon [icon]="faEdit" aria-hidden="true"></fa-icon>
											}
										</a>
										<!-- <a class="table-button ms-1" *ngIf="session.isSupport()" ngbTooltip="{{ 'BUTTONS.SHOW' | translate }}" (click)="openDoctorModal(element)">
											<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
										</a> -->
										<a class="table-button ms-1" *ngIf="session.isInstaller()" ngbTooltip="{{ 'TELEREFRACT.GO_BUTTON' | translate }}" (click)="goToTelerefract(element)">
											<fa-icon [icon]="faLaptopHouse" aria-hidden="true"></fa-icon>
										</a>

										<!-- ## check for isSupport -->

										<a class="table-button ms-1" *ngIf="session.isLevel3() || session.isSupport()" [hidden]="!element.isActive" ngbTooltip="{{ 'NAVBAR.DEVICES' | translate }}" (click)="openDeviceModal(element)"> <fa-icon [icon]="faDevice" aria-hidden="true"></fa-icon></a>

										<a class="table-button ms-1" *ngIf="session.isLevel3() || session.isSupport()" ngbTooltip="credits" (click)="openSaleBalanceModal(element)">
											<fa-icon [icon]="faDollar" aria-hidden="true"></fa-icon>
										</a>

										<a class="table-button ms-1" *ngIf="(session.isLevel3() || session.isSupport()) && !element.isMini() && !element.isSuperB()" [hidden]="element.isDeleted()" ngbTooltip="relations" (click)="openDoctorRelations(element)">
											<fa-icon [icon]="faLink" aria-hidden="true"></fa-icon>
										</a>

										<a class="table-button ms-1" *ngIf="!session.isGroupB()" ngbTooltip="events log" (click)="openUserEvents(element)">
											<fa-icon [icon]="faRectangleList" aria-hidden="true"></fa-icon>
										</a>
									</div>
								</td>
							</ng-container>
							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
						</table>

						<mat-paginator [pageSizeOptions]="[10, 15, 20, 25, 50, 999]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
