import { DataStatus } from '../service/data-model.service'
import { FRExam, LensmeterExam, SubjectiveExam, TopoExam, WfExam } from './exam.model'

export enum examEyes {
	RIGHT = 'right',
	LEFT = 'left',
	BINO = 'bino',
}
// 16.04.2020
export class Category {
	// position: number
	name: string
	status: DataStatus
	expected: number // numero di esami che dovra' avere, per gestire fase di loading
	examList: any[] // sono Exam "estesi"

	// 23.06.2020 per display e print [ls]
	hasMiddleInfo: boolean
	hasImagesR: boolean
	hasImagesL: boolean

	hasDataR: boolean // 11.10.2021 esami su occhio singolo
	hasDataL: boolean

	hasReportDone: boolean // 07.07.2020 per il report

	constructor(name?) {
		// this.position = 0
		this.name = ''
		this.status = DataStatus.NOT_LOADED
		this.examList = []
		this.expected = -1 // valutare cosa e' meglio

		this.hasMiddleInfo = false
		this.hasImagesL = false
		this.hasImagesR = false

		this.hasReportDone = false

		if (name != null) {
			// 30.06.2020 aggiunto param nel costruttore
			this.name = name
		}
	}

	public getStatus() {
		return this.status
	}
}

export class refractionObject {
	eye: examEyes
	isCombinedData: boolean
	hasMiddleInfo: boolean

	ref_examDate: string

	ls: boolean
	ls_sphere: string
	ls_cylinder: string
	ls_axis: string
	ls_prism_h: string
	ls_prism_v: string
	ls_add: string
	ls_PDm: string
	//
	wf: boolean
	wf_Label: string
	wf_sphere_day: string
	wf_sphere_night: string
	wf_cylinder_day: string
	wf_cylinder_night: string
	wf_axis_day: string
	wf_axis_night: string
	wf_pupil_size_day: string
	wf_pupil_size_night: string
	wf_pupil_real_size_day: string
	wf_pupil_real_size_night: string
	wf_vertex_distance: string
	//
	sb: boolean
	sb_sphere: string
	sb_cylinder: string
	sb_axis: string
	sb_prism_h: string
	sb_prism_v: string
	sb_add: string
	sb_PDm: string
	//
	sb_DVA_LM: string
	sb_NVA_LM: string
	sb_DVA_WF: string
	sb_NVA_WF: string
	sb_DVA_REF: string
	sb_NVA_REF: string
	//
	tp: boolean
	tp_Examdate: string
	tp_K1: string
	tp_K2: string
	tp_CYL: string
	tp_AVG: string

	constructor(topo?: TopoExam, wavefront?: WfExam, subjective?: SubjectiveExam, lensmeter?: LensmeterExam, fastRefraction?: FRExam) {
		this.eye = null
		this.isCombinedData = false
		this.hasMiddleInfo = false

		this.ref_examDate = ''

		this.ls_sphere = null
		this.ls_cylinder = null
		this.ls_axis = null
		this.ls_prism_h = null
		this.ls_prism_v = null
		this.ls_add = null
		this.ls_PDm = null

		this.wf_Label = null
		this.wf_sphere_day = null
		this.wf_sphere_night = null
		this.wf_cylinder_day = null
		this.wf_cylinder_night = null
		this.wf_axis_day = null
		this.wf_axis_night = null
		this.wf_pupil_size_day = null
		this.wf_pupil_size_night = null
		this.wf_pupil_real_size_day = null
		this.wf_pupil_real_size_night = null
		this.wf_vertex_distance = null

		this.sb_sphere = null
		this.sb_cylinder = null
		this.sb_axis = null
		this.sb_prism_h = null
		this.sb_prism_v = null
		this.sb_add = null
		this.sb_PDm = null

		this.sb_DVA_LM = null
		this.sb_DVA_WF = null
		this.sb_DVA_REF = null
		this.sb_NVA_LM = null
		this.sb_NVA_WF = null
		this.sb_NVA_REF = null

		this.tp_Examdate = ''
		this.tp_K1 = null
		this.tp_K2 = null
		this.tp_CYL = null
		this.tp_AVG = null

		if (lensmeter) {
			this.ls = true
			this.ls_sphere = lensmeter.getSphere()
			this.ls_cylinder = lensmeter.getCylinder()
			this.ls_axis = lensmeter.getAxis()
			this.ls_prism_h = lensmeter.prism_h
			this.ls_prism_v = lensmeter.prism_v
			this.ls_add = lensmeter.add
			this.ls_PDm = lensmeter.PDm
		}

		if (fastRefraction) {
			this.wf = true
			this.hasMiddleInfo = true
			this.wf_Label = fastRefraction.getExamLabel()
			this.wf_sphere_day = fastRefraction.getSphereDay()
			this.wf_sphere_night = fastRefraction.getSphereNight()
			this.wf_cylinder_day = fastRefraction.getCylDay()
			this.wf_cylinder_night = fastRefraction.getCylNight()
			this.wf_axis_day = fastRefraction.getAxisDay()
			this.wf_axis_night = fastRefraction.getAxisNight()
			this.wf_pupil_size_day = fastRefraction.getPupilDay()
			this.wf_pupil_size_night = fastRefraction.getPupilNight()
			this.wf_pupil_real_size_day = fastRefraction.getRealPupilSizeDay()
			this.wf_pupil_real_size_night = fastRefraction.getRealPupilSizeNight()
			this.wf_vertex_distance = fastRefraction.getVertex()
		} else if (wavefront) {
			this.wf = true
			this.hasMiddleInfo = true
			this.wf_Label = wavefront.getExamLabel()
			this.wf_sphere_day = wavefront.getSphereDay()
			this.wf_sphere_night = wavefront.getSphereNight()
			this.wf_cylinder_day = wavefront.getCylDay()
			this.wf_cylinder_night = wavefront.getCylNight()
			this.wf_axis_day = wavefront.getAxisDay()
			this.wf_axis_night = wavefront.getAxisNight()
			this.wf_pupil_size_day = wavefront.getPupilDay()
			this.wf_pupil_size_night = wavefront.getPupilNight()
			this.wf_pupil_real_size_day = wavefront.getRealPupilSizeDay()
			this.wf_pupil_real_size_night = wavefront.getRealPupilSizeNight()
			this.wf_vertex_distance = wavefront.getVertex()
		}

		if (subjective) {
			this.sb = true
			this.isCombinedData = true
			this.sb_sphere = subjective.getSphere()
			this.sb_cylinder = subjective.getCylinder()
			this.sb_axis = subjective.getAxis()
			this.sb_prism_h = subjective.prism_h
			this.sb_prism_v = subjective.prism_v
			this.sb_add = subjective.add
			this.sb_PDm = subjective.PDm

			this.sb_DVA_LM = subjective.getDVA('LM')
			this.sb_DVA_WF = subjective.getDVA('WF')
			this.sb_DVA_REF = subjective.getDVA()
			this.sb_NVA_LM = subjective.getNVA('LM')
			this.sb_NVA_WF = subjective.getNVA('WF')
			this.sb_NVA_REF = subjective.getNVA()
		}

		if (fastRefraction) {
			this.tp = true
			this.hasMiddleInfo = true
			this.tp_K1 = fastRefraction.getK1()
			this.tp_K2 = fastRefraction.getK2()
			this.tp_CYL = fastRefraction.getCYL()
			this.tp_AVG = fastRefraction.getAVG()
		} else if (topo) {
			this.tp = true
			this.hasMiddleInfo = true
			this.tp_K1 = topo.getK1()
			this.tp_K2 = topo.getK2()
			this.tp_CYL = topo.getCYL()
			this.tp_AVG = topo.getAVG()
		}
	}
}
