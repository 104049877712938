import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, NgForm } from '@angular/forms' // ReactiveFormsModule

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { Visit } from '../../models/visit.model'

import { VisitListComponent } from './visitList.component'
import {
	DryEyeExam,
	ExternalExam,
	FRExam,
	FundusExam,
	LensmeterExam,
	PachyExam,
	PachyMultExam,
	PupilExam,
	RetroExam,
	SubjectiveExam,
	TonoExam,
	TopoExam,
	WfExam,
} from '../../models/exam.model'
import { fakeFastRefractionExams, fakePachyExam, fakeTopoExams } from 'src/app/models/fakeExams.model'

var providers = [NgbModal, FormsModule]

@Component({
	selector: 'visitList-modal',
	templateUrl: './visitList.modal.html',
	styleUrls: ['./visitList.modal.scss'],
	providers,
})
export class VisitListModal implements OnInit {
	@Input() parent: VisitListComponent
	@Input() currentPatientId: number
	@Input() currentAction: string
	@Input() lastVisitId: number
	@Input() visitList: Visit[]

	@ViewChild('visitForm') visitForm: NgForm
	@ViewChild('examForm') examForm: NgForm

	form: NgForm

	userDevices: string[]
	userSN: string[]
	visitsIds: number[]

	demoVisit: Visit
	flagDone: boolean
	exam: any
	examsTypes: string[]
	eyes: any

	wait: boolean

	pachyOptionalValue: fakePachyExam
	topoOptionalValue: fakeTopoExams
	fastRefractionValues: fakeFastRefractionExams

	constructor(public activeModal: NgbActiveModal, public session: SessionService, private translator: TranslateService) {
		console.log('(visitListModal)')

		this.demoVisit = new Visit()
		this.demoVisit.device = ''
		this.demoVisit.name = 'All'
		this.demoVisit.date = new Date()
		this.exam = new DryEyeExam()

		this.userDevices = [...new Set(this.session.user.getDevices().map((item) => item.model))]
		this.userSN = [...new Set(this.session.user.getDevices().map((item) => item.sn))]
		this.visitsIds = []
		this.visitList = []

		// console.log(this.exam);

		this.examsTypes = this.session.examTypes
		// console.log(this.examsTypes);
		this.eyes = this.session.eyes
		this.exam.exam_type = Config.EXM_TONO // 27.06.2023 piu' comodo this.examsTypes[0]
		this.exam.eye = this.eyes[0]

		this.exam.visit_id = 0

		this.flagDone = false

		this.wait = false
	}

	ngOnInit(): void {
		console.log('(visitListModal) - ngOnInit')

		this.demoVisit.patient_id = this.currentPatientId

		this.exam.patient_id = this.currentPatientId

		// this.exam.visit_id = this.lastVisitId
		console.log(this.visitList)

		if (this.visitList.length > 0) {
			this.visitsIds = this.visitList.map((item) => item.id)

			console.log(this.visitList)
		}

		this.onChangeEye()
	}

	dismiss() {
		// console.log(this.currentPatient);
		//this.activeModal.dismiss(this.flagDone);
		this.activeModal.close(this.flagDone)
	}

	onChangeEye() {
		if (this.exam.eye == 'left') {
			this.pachyOptionalValue = fakePachyExam.fakePachyValueLEFT
			this.topoOptionalValue = fakeTopoExams.fakeTopoValueLEFT
			this.fastRefractionValues = fakeFastRefractionExams.fakeFastRefractionValueLEFT
		} else {
			this.pachyOptionalValue = fakePachyExam.fakePachyValueRIGHT
			this.topoOptionalValue = fakeTopoExams.fakeTopoValueRIGHT
			this.fastRefractionValues = fakeFastRefractionExams.fakeFastRefractionValueRIGHT
		}
	}

	onChangeType() {
		switch (this.exam.exam_type) {
			case Config.EXM_DRYEYE:
				this.exam = new DryEyeExam(this.exam)

				break

			case Config.EXM_EXT:
				this.exam = new ExternalExam(this.exam)

				break

			case Config.EXM_FUNDUS:
				this.exam = new FundusExam(this.exam)

				break

			case Config.EXM_LM:
				this.exam = new LensmeterExam(this.exam)

				break

			case Config.EXM_PACHY:
				this.exam = new PachyExam(this.exam)

				break

			case Config.EXM_PACHYMULT:
				this.exam = new PachyMultExam(this.exam)

				break

			case Config.EXM_PUPIL:
				this.exam = new PupilExam(this.exam)

				break

			case Config.EXM_RETRO:
				this.exam = new RetroExam(this.exam)

				break

			case Config.EXM_SBJ:
				this.exam = new SubjectiveExam(this.exam)

				break

			case Config.EXM_TONO:
				this.exam = new TonoExam(this.exam)

				break

			case Config.EXM_TOPO:
				this.exam = new TopoExam(this.exam)

				break

			case Config.EXM_WF:
				this.exam = new WfExam(this.exam)

				break

			case Config.EXM_FASTREFRACTION:
				this.exam = new FRExam(this.exam)

				break
		}
	}

	submit() {
		if (this.currentAction == 'createVisit') {
			this.form = this.visitForm
			// console.log(this.form);
		} else {
			this.form = this.examForm
			// console.log(this.form);
		}

		if (this.form.valid) {
			var fileIds = []

			if (this.currentAction == 'createVisit') {
				console.log('createVisit')

				if (this.demoVisit.id == 0) {
					// nuova

					console.log('createVisit demovisit.id=0')

					return this.postVisit()
						.then((resp) => {
							// console.log(resp);
							console.log('ok post visit number: ' + resp)

							this.flagDone = true

							this.activeModal.close(this.flagDone)
						})
						.catch((error) => {
							console.log('(testVisit) KO post')
							alert(error)
						})
				} else {
					console.log('createVisit demovisit.id !=0')
					this.loadFileXml().then((xmlBlob: string) => {
						if (xmlBlob == null) {
							console.log('(uplVisit) ko xml!')
						}
						if (xmlBlob != null) {
							this.demoVisit.xml_blob = xmlBlob
							//console.log("(uplVisit) xml: "+xmlBlob);
						}

						return this.session
							.uploadXmlVisit(this.demoVisit)
							.then((resp) => {
								console.log(resp)

								this.flagDone = true

								this.activeModal.close(this.flagDone)
							})
							.catch((error) => {
								console.log('(testVisit) KO upload xml')
								alert('ko upload xml, ' + error)
							})
						this.flagDone = true
						this.activeModal.close(this.flagDone)
					})
				}
			} else if (this.currentAction == 'createExam') {
				console.log('(testExam) inizio')

				if (this.exam.visit_id == 0) {
					alert('You must select a visit id')
					return
				}

				this.wait = true

				var examDraft = null // new data.Exam();

				if (this.exam.exam_type == 'extphoto') {
					examDraft = new ExternalExam(this.exam) // prende solo i campi generali
					//examDraft = data.ExternalExam.getFakeExam(this.exam); // non esiste xche' nn ci sono dati

					fileIds.push('image_auto')
					fileIds.push('image_man_1')
					fileIds.push('image_man_2')
					fileIds.push('image_man_3')
					fileIds.push('image_man_4')
					fileIds.push('image_man_5')
					fileIds.push('image_man_6')
				} else if (this.exam.exam_type == 'topo') {
					// 23.06.2020 rivedere
					//examDraft = this.exam;
					examDraft = TopoExam.getFakeExam(this.topoOptionalValue, this.exam)

					examDraft.axial_map = null
					examDraft.elevation_map = null
					examDraft.tangential_map = null

					fileIds.push('axial_map')
					fileIds.push('elevation_map')
					fileIds.push('tangential_map')
				} else if (this.exam.exam_type == 'pachymult') {
					examDraft = new PachyMultExam(this.exam)
					examDraft.image = null

					fileIds.push('image')
				} else if (this.exam.exam_type == 'pachy') {
					// 20.07.2020

					examDraft = PachyExam.getFakeExam(this.pachyOptionalValue, this.exam)

					examDraft.image = null
					examDraft.image_with_data = null

					fileIds.push('image')
					fileIds.push('image_with_data')
				} else if (this.exam.exam_type == 'dryeye') {
					//examDraft = new data.DryEyeExam(this.exam);  // prende solo i campi generali
					examDraft = DryEyeExam.getFakeExam(this.exam) // 31.07.2020

					examDraft.image = null

					fileIds.push('image')
				} else if (this.exam.exam_type == 'tono') {
					// 28.07.2020 senza immagini
					//examDraft = new data.TonoExam(this.exam);
					examDraft = TonoExam.getFakeExam(this.exam)
				} else if (this.exam.exam_type == 'wf') {
					// 31.07.2020

					examDraft = WfExam.getFakeExam(this.exam)

					examDraft.image_grid = null
					examDraft.image_meso = null

					fileIds.push('image_grid')
					fileIds.push('image_meso')
				} else if (this.exam.exam_type == 'retro') {
					// 21.08.2020

					examDraft = RetroExam.getFakeExam(this.exam)

					examDraft.image_low = null
					examDraft.image_high = null

					fileIds.push('image_low')
					fileIds.push('image_high')
				} else if (this.exam.exam_type == 'fundus') {
					// 17.09.2020

					examDraft = FundusExam.getFakeExam(this.exam)

					examDraft.image_central = null
					examDraft.image_temporal = null
					examDraft.image_nasal = null
					examDraft.image_supero_nasal = null
					examDraft.image_supero_temporal = null
					examDraft.image_inferior = null
					examDraft.image_external = null
					examDraft.image_central_nasal = null

					fileIds.push('image_central')
					fileIds.push('image_temporal')
					fileIds.push('image_nasal')
					fileIds.push('image_supero_nasal')
					fileIds.push('image_supero_temporal')
					fileIds.push('image_inferior')
					fileIds.push('image_external')
					fileIds.push('image_central_nasal')
				} else if (this.exam.exam_type == 'subjective') {
					// 05.10.2020 senza immagini
					examDraft = SubjectiveExam.getFakeExam(this.exam)
				} else if (this.exam.exam_type == 'lensmeter') {
					// 09.10.2020
					examDraft = LensmeterExam.getFakeExam(this.exam)
				} else if (this.exam.exam_type == 'pupil') {
					// 09.10.2020
					examDraft = PupilExam.getFakeExam(this.exam)
					fileIds.push('graph')
				} else if (this.exam.exam_type == 'fast_refraction') {
					examDraft = FRExam.getFakeExam(this.fastRefractionValues, this.exam)

					examDraft.image_sh = null

					fileIds.push('image_sh')
				} else {
					// TODO
					examDraft = this.exam
					examDraft.image = null
					fileIds.push('image')
				}

				var totImg = 0
				//this.myReadFile(myImg)
				//this.myReadFile(nomeFile)

				this.loadFileList(fileIds)
					.then((imgBlobs) => {
						for (var i = 0; i < fileIds.length; i++) {
							var imgBlob = imgBlobs[i]

							if (imgBlob == null) {
								// 28.08.2020 fix
								// ignoro
							} else if (fileIds[i] == 'image_sh') {
								examDraft.image_sh = imgBlob
							} else if (fileIds[i] == 'image') {
								examDraft.image = imgBlob
							} else if (fileIds[i] == 'axial_map') {
								examDraft.axial_map = imgBlob
							} else if (fileIds[i] == 'elevation_map') {
								examDraft.elevation_map = imgBlob
							} else if (fileIds[i] == 'tangential_map') {
								examDraft.tangential_map = imgBlob
							} else if (fileIds[i] == 'image_auto') {
								examDraft.image_auto = imgBlob
							} else if (fileIds[i] == 'image_man_1') {
								examDraft.image_man_1 = imgBlob
							} else if (fileIds[i] == 'image_man_2') {
								examDraft.image_man_2 = imgBlob
							} else if (fileIds[i] == 'image_man_3') {
								examDraft.image_man_3 = imgBlob
							} else if (fileIds[i] == 'image_man_4') {
								examDraft.image_man_4 = imgBlob
							} else if (fileIds[i] == 'image_man_5') {
								examDraft.image_man_5 = imgBlob
							} else if (fileIds[i] == 'image_man_6') {
								examDraft.image_man_6 = imgBlob
							} else if (fileIds[i] == 'image_with_data') {
								// 21.07.2020 pachyExam
								examDraft.image_with_data = imgBlob
							} else if (fileIds[i] == 'image_grid') {
								// 31.07.2020 wfExam
								examDraft.image_grid = imgBlob
							} else if (fileIds[i] == 'image_meso') {
								examDraft.image_meso = imgBlob
							} else if (fileIds[i] == 'image_low') {
								// 21.08.2020 retroExam
								examDraft.image_low = imgBlob
							} else if (fileIds[i] == 'image_high') {
								examDraft.image_high = imgBlob
							} else if (fileIds[i] == 'image_central') {
								// 17.09.2020 fundus
								examDraft.image_central = imgBlob
							} else if (fileIds[i] == 'image_temporal') {
								examDraft.image_temporal = imgBlob
							} else if (fileIds[i] == 'image_nasal') {
								examDraft.image_nasal = imgBlob
							} else if (fileIds[i] == 'image_supero_nasal') {
								examDraft.image_supero_nasal = imgBlob
							} else if (fileIds[i] == 'image_supero_temporal') {
								examDraft.image_supero_temporal = imgBlob
							} else if (fileIds[i] == 'image_inferior') {
								examDraft.image_inferior = imgBlob
							} else if (fileIds[i] == 'image_external') {
								examDraft.image_external = imgBlob
							} else if (fileIds[i] == 'image_central_nasal') {
								examDraft.image_central_nasal = imgBlob
							} else if (fileIds[i] == 'graph') {
								// pupil 09.11.2020
								examDraft.graph = imgBlob
							}

							if (imgBlob) totImg++
						}

						console.log('(testExam) after read files, got ' + totImg + ' images')
						//console.log(examDraft.axial_map);   // qui ok, immagine in base64

						//console.log("(testExam) eye "+examDraft.eye+" eccentricity "+examDraft.eccentricity);

						let visit = this.visitList.find((el) => el.id == this.exam.visit_id)
						// 15.06.2020 spostate qui
						examDraft.patient_id = this.currentPatientId
						examDraft.visit_id = this.exam.visit_id
						examDraft.eye = this.exam.eye
						examDraft.device = visit.device // vuoto
						examDraft.device_sn = visit.device_sn

						this.session
							.createExam(examDraft)
							.then((resp) => {
								this.wait = false
								console.log(resp.exams)

								this.flagDone = true
								this.activeModal.close(this.flagDone)
							})
							.catch((error) => {
								console.log('(testExam) KO post')
								this.flagDone = true

								var msg = error.data ? error.data.error : error.toString()
								alert(msg)
							})
					})
					.catch((error) => {
						console.log('(testExam) KO read file ')
						this.flagDone = true
						console.log(error)
					})
			}
		}
	}

	postVisit() {
		console.log('post visit')

		var myDate = new Date().toISOString().substring(0, 19) // yyyy-mm-dd hh.mm.ss

		var vistDraft = {
			patient_id: this.demoVisit.patient_id,
			name: this.demoVisit.name,
			visit_date: myDate, // this.demoVisit.date,
			device: this.demoVisit.device,
			device_sn: this.demoVisit.device_sn,
		}

		return this.session.createVisit(vistDraft).then() // 07.05.2020 added
	}

	private loadFileXml(): Promise<string> {
		var imgFile = null
		var imgId = 'xml_blob'
		var elem = <HTMLInputElement>document.getElementById(imgId)

		if (elem != null) imgFile = elem.files[0]

		if (!imgFile) {
			// "please provide an xml file to upload"
			var msg = 'please provide an xml file to upload' //VISITS.XML_REQUIRED'
			alert(msg)

			return null
		}

		console.log('(loadFileXml) loading ' + imgFile.name + ' as ' + imgId)
		return this.myReadFileAsText(imgFile)
	}

	// 01.09.2020 per load xml
	private myReadFileAsText(file: Blob): Promise<any> {
		return new Promise((resolve, reject) => {
			const fr = new FileReader()
			fr.onerror = reject

			fr.onloadend = function () {
				resolve(fr.result)
			}
			//return fr.readAsDataURL(file); // per le immagini, converte in base64
			return fr.readAsText(file)
		})
	}

	private myReadFile(file) {
		return new Promise((resolve, reject) => {
			const fr = new FileReader()
			fr.onerror = reject

			//fr.onload = function() {
			fr.onloadend = function () {
				resolve(fr.result)
				console.log('(myReadFile) done! ')
			}
			return fr.readAsDataURL(file) // NB: converte in base64
			//return fr.readAsText(file);
		})
	}

	private loadFileList(inputIds) {
		var ris = []
		for (var i = 0; i < inputIds.length; i++) {
			var imgFile = null
			var imgId = inputIds[i]

			var elem = <HTMLInputElement>document.getElementById(imgId)

			if (elem != null) {
				imgFile = elem.files[0]
			}

			if (imgFile) {
				console.log('(loadFileList) loading ' + imgFile.name + ' as ' + imgId)
				ris.push(this.myReadFile(imgFile))
				// non funziona la promise...
				//var elem = {"name": imgId, "blob": this.myReadFile(imgFile)};
				//ris.push(elem);
				//ris.push({"name": imgId, "blob": this.myReadFile(imgFile)});
			} else {
				// 28.08.2020 fix, ripristinato, altrim. sbaglia ordine
				console.log('(loadFileList) missing file for ' + imgId)
				ris.push(null)
			}
		}
		return Promise.all(ris)
	}
}
