import { Injectable } from '@angular/core'
import { SessionService } from './session.service'
import { DataModelService } from './data-model.service'
import {
	ImpactReportData,
	ImpactDates,
	ImpactHistoryData,
	ImpactEyeData,
	ImpactSection,
	ImpactRecommendationResponse,
	ImpactRecommendationRequest,
	ImpactTopic,
} from '../models/impact.model'
import { postMessageToWorker, workerFunctionType, dataType, CryptoUtilsService } from './crypto-utils.service'

@Injectable({
	providedIn: 'root',
})
export class ImpactService {
	userImpactReportMap: Map<string, ImpactReportData> // key: patientId + date
	userImpactDatesMap: Map<number, string[]> // key: patientId
	userImpactHistoryMap: Map<string, ImpactHistoryData> // key: patientId + topic + date
	userImpactRecommendationsMap: Map<string, ImpactRecommendationResponse> // key: patientId + date

	constructor(private session: SessionService, private data: DataModelService, private cryptoUtils: CryptoUtilsService) {
		this.userImpactReportMap = new Map<string, ImpactReportData>()
		this.userImpactDatesMap = new Map<number, string[]>()
		this.userImpactHistoryMap = new Map<string, ImpactHistoryData>()
		this.userImpactRecommendationsMap = new Map<string, ImpactRecommendationResponse>()
	}

	getImpactReport(patientId: number, day: string): Promise<ImpactReportData> {
		return new Promise((resolve, reject) => {
			if (this.userImpactReportMap.has(`${patientId}${day}`)) {
				resolve(this.userImpactReportMap.get(`${patientId}${day}`))
			} else {
				this.data
					.loadImpactReport(this.session.buildBaseRequest(), patientId, day)
					.then((res) => {
						const key = this.session.getExamKey()

						this.decryptImpactReportData(res, this.session.user.password, key).then((decrData) => {
							this.userImpactReportMap.set(`${patientId}${day}`, this.formatImpactReportData(decrData))
							resolve(this.formatImpactReportData(decrData))
						})
					})
					.catch((err) => {
						if (!this.session.isExpired(err)) {
							reject(err)
							throw err
						}
					})
			}
		})
	}

	getImpactAvailableDates(patientId: number): Promise<ImpactDates> {
		return new Promise((resolve, reject) => {
			if (this.userImpactDatesMap.has(patientId)) {
				resolve({ impact_available_dates: this.userImpactDatesMap.get(patientId) })
			} else {
				this.data
					.loadImpactAvailableDates(this.session.buildBaseRequest(), patientId)
					.then((res) => {
						this.userImpactDatesMap.set(patientId, res.impact_available_dates)
						resolve(res)
					})
					.catch((err) => {
						if (!this.session.isExpired(err)) {
							reject(err)
							throw err
						}
					})
			}
		})
	}

	getImpactTopicHistory(patientId: number, topic: string, day: string): Promise<ImpactHistoryData> {
		return new Promise((resolve, reject) => {
			if (this.userImpactHistoryMap.has(`${patientId}${topic}${day}`)) {
				resolve(this.userImpactHistoryMap.get(`${patientId}${topic}${day}`))
			} else {
				this.data
					.loadImpactTopicHistory(this.session.buildBaseRequest(), patientId, topic, day)
					.then((res) => {
						const key = this.session.getExamKey()

						this.decryptImpactHistoryData(res, this.session.user.password, key, this.session.user.username.toLowerCase()).then((decrData) => {
							this.userImpactHistoryMap.set(`${patientId}${topic}${day}`, this.formatImpactHistoryData(decrData))
							resolve(this.formatImpactHistoryData(decrData))
						})
					})
					.catch((err) => {
						if (!this.session.isExpired(err)) {
							reject(err)
							throw err
						}
					})
			}
		})
	}

	getImpactRecommendations(impactRecommendationData: ImpactRecommendationRequest, patientId: number, day: string): Promise<ImpactRecommendationResponse> {
		return new Promise((resolve, reject) => {
			if (this.userImpactRecommendationsMap.has(`${patientId}${day}`)) {
				resolve(this.userImpactRecommendationsMap.get(`${patientId}${day}`))
			} else {
				this.data
					.loadImpactRecommendations(this.session.buildBaseRequest(), patientId, impactRecommendationData)
					.then((res) => {
						this.userImpactRecommendationsMap.set(`${patientId}${day}`, res)
						resolve(res)
					})
					.catch((err) => {
						if (!this.session.isExpired(err)) {
							reject(err)
							throw err
						}
					})
			}
		})
	}

	async decryptImpactReportData(encrData: ImpactReportData, password: string, key: any) {
		const decrData = encrData // Deep copy of the original data

		const postMessageToWorkerTemplate: postMessageToWorker = new postMessageToWorker()
		postMessageToWorkerTemplate.type = workerFunctionType.decryptDataAllInOne
		postMessageToWorkerTemplate.password = password
		postMessageToWorkerTemplate.key = key
		postMessageToWorkerTemplate.dataType = dataType.string

		for (const section of decrData.impact.sections) {
			for (const topic of section.topics) {
				for (const eye of topic.eyes) {
					for (const key in eye) {
						if (eye.hasOwnProperty(key) && !['eye', 'exam_date', 'exam_type'].includes(key)) {
							eye[key] = await this.cryptoUtils.decryptDataWithKeyWorker({ ...postMessageToWorkerTemplate, dataToDecrypt: eye[key] })
						}
					}
				}
			}
		}

		return decrData
	}

	async decryptImpactHistoryData(encrData: ImpactHistoryData, password: string, key: any, username: string) {
		const decrData = encrData // Deep copy of the original data

		const postMessageToWorkerTemplate: postMessageToWorker = new postMessageToWorker()
		postMessageToWorkerTemplate.type = workerFunctionType.decryptDataAllInOne
		postMessageToWorkerTemplate.password = password
		postMessageToWorkerTemplate.key = key
		postMessageToWorkerTemplate.dataType = dataType.string

		for (const eye of decrData.historical_topic.eyes) {
			for (const key in eye) {
				if (eye.hasOwnProperty(key) && !['eye', 'exam_date', 'exam_type'].includes(key)) {
					eye[key] = await this.cryptoUtils.decryptDataWithKeyWorker({ ...postMessageToWorkerTemplate, dataToDecrypt: eye[key] })
				}
			}
		}

		return decrData
	}

	formatImpactReportData(data: ImpactReportData) {
		const formattedImpactReportData = data

		for (const section of formattedImpactReportData.impact.sections) {
			for (const topic of section.topics) {
				for (const eye of topic.eyes) {
					this.calculateTopicValue(topic, eye)
				}

				topic.inRange = topic.eyes.every((el) => el.inRange)
			}
			section.inRange = section.topics.every((el) => el.inRange)
		}

		return formattedImpactReportData
	}

	formatImpactHistoryData(data: ImpactHistoryData) {
		const formattedImpactHistoryData = data

		if (formattedImpactHistoryData.historical_topic.display_mode === 'direct') {
			for (const eye of formattedImpactHistoryData.historical_topic.eyes) {
				if (eye.value && eye.value.replace) {
					eye.decrValue = parseFloat(eye.value.replace(',', '.'))
				}
			}
		}

		if (formattedImpactHistoryData.historical_topic.display_mode === 'calculate') {
			for (const eye of formattedImpactHistoryData.historical_topic.eyes) {
				this.calculateTopicValue(formattedImpactHistoryData.historical_topic, eye)
			}
		}

		return formattedImpactHistoryData
	}

	calculateTopicValue(topic: ImpactTopic, eye: ImpactEyeData) {
		switch (topic.display_mode) {
			case 'direct':
				eye.decrValue = parseFloat(eye.value.replace(',', '.'))
				eye.inRange = eye.decrValue >= topic.range_low && eye.decrValue <= topic.range_high

				break
			case 'calculate':
				if (topic.title === 'daynight_delta') {
					const sphere_dayParsed = parseFloat(eye.sphere_day)
					const sphere_nightParsed = parseFloat(eye.sphere_night)
					const cylinder_dayParsed = parseFloat(eye.cylinder_day)
					const cylinder_nightParsed = parseFloat(eye.cylinder_night)

					const x = sphere_dayParsed - cylinder_dayParsed / 2
					const y = sphere_nightParsed - cylinder_nightParsed / 2

					eye.decrValue = parseFloat((x - y).toFixed(2))
					eye.inRange = eye.decrValue >= topic.range_low && eye.decrValue <= topic.range_high
				}

				break
			case 'graph':
				if (topic.title === 'accomodation_topic') {
					const fixationArrParsed = eye.nv_fixation_power_array.split(';').map((el) => parseFloat(el.trim()))
					const cylinderArrParsed = eye.nv_cylinder_array.split(';').map((el) => parseFloat(el.trim()))
					const sphereArrParsed = eye.nv_sphere_array.split(';').map((el) => parseFloat(el.trim()))

					eye.decrNvFixationPowerArray = []
					eye.decrNvCylinderArray = []
					eye.decrNvSphereArray = []
					eye.accomodationXValues = []
					eye.accomodationEyeStrainYValues = []
					eye.accomodationStimulusYValues = []

					if (fixationArrParsed.length === cylinderArrParsed.length && cylinderArrParsed.length === sphereArrParsed.length) {
						// get rid of the 0 values in the fixation array and the corresponding values in the cylinder and sphere arrays
						const filteredData = fixationArrParsed
							.map((value, index) => ({
								fixation: value,
								cylinder: cylinderArrParsed[index],
								sphere: sphereArrParsed[index],
							}))
							.filter((el) => el.fixation !== 0)

						eye.decrNvFixationPowerArray = filteredData.map((el) => el.fixation)
						eye.decrNvCylinderArray = filteredData.map((el) => el.cylinder)
						eye.decrNvSphereArray = filteredData.map((el) => el.sphere)

						// test values to be removed when feature is approved

						// riga 1
						// eye.decrNvFixationPowerArray = [1.405240419297114, 0.905240419297114, 0.4052404192971139, -0.09475958070288605, -0.594759580702886, -1.094759580702886]
						// eye.decrNvCylinderArray = [-0.1670934940155246, -0.1495954928213344, -0.1630962467331075, -0.2019206362461943, -0.15095692741141373, -0.1808270298585912]
						// eye.decrNvSphereArray = [1.4877896616370714, 1.6490702045398935, 1.5714382077385296, 1.7492226898398944, 1.744779688891207, 1.6756545481605645]

						// riga 2
						// eye.decrNvFixationPowerArray = [
						// 	1.4475119462682149, 0.9475119462682149, 0.4475119462682149, -0.05248805373178511, -0.5524880537317851, -1.0524880537317851,
						// ]
						// eye.decrNvCylinderArray = [
						// 	-0.060835159559232285, -0.05260120495589512, -0.08272919665259172, -0.08892786996820533, -0.0686285616835367, -0.18743929469431547,
						// ]
						// eye.decrNvSphereArray = [1.6133753561342328, 1.6408086238715691, 1.8111281443813712, 1.8091576935484943, 1.746872728300786, 1.6949495118172315]

						// riga 3
						// eye.decrNvFixationPowerArray = [
						// 	1.064960101558563, 0.564960101558563, 0.06496010155856302, -0.435039898441437, -0.935039898441437, -1.435039898441437, -1.935039898441437,
						// ]
						// eye.decrNvCylinderArray = [
						// 	-0.16680277333895624, -0.15385978284776658, -0.08656428607025364, -0.10492617998512026, -0.16109428067969536, -0.04042179084510845, -0.1842708607467876,
						// ]
						// eye.decrNvSphereArray = [
						// 	1.2148124186975884, -0.1587062343759119, -0.679356306134726, -0.45393138199889954, -2.100588652259733, -1.2766380901241483, 0.6930247958866016,
						// ]

						// riga 4
						// eye.decrNvFixationPowerArray = [
						// 	1.836493747298539, 1.336493747298539, 0.836493747298539, 0.336493747298539, -0.16350625270146102, -0.663506252701461, -1.163506252701461,
						// 	-1.663506252701461,
						// ]
						// eye.decrNvCylinderArray = [
						// 	-0.1246685632107959, -0.04841954882547797, -0.010970918126126113, -0.049751049766339255, -0.03945224053350349, -0.009055670264297792,
						// 	-0.06935323996130473, -0.12510603271980802,
						// ]
						// eye.decrNvSphereArray = [
						// 	1.9544005585933637, 1.8734214383208332, 1.7027834358768592, 1.854530192569408, 1.688288004876657, 1.714802028704248, 1.5267109799304013,
						// 	1.1924275430094409,
						// ]

						// all the following calculations are derived from those done at the device level, variable names are also similar/same to ease comparison
						const dist0 = eye.decrNvFixationPowerArray[0]
						const sizeX = 420
						const middleX = 300
						const sizeY = 300
						const middleY = 220
						const sphereRange = 6

						eye.accomodationFixationArrInCm = eye.decrNvFixationPowerArray.map((el) => {
							if (Math.abs(dist0 - el) > 0.001) {
								return Math.abs(100 / (dist0 - el))
							} else {
								return 400
							}
						})

						const eyeStrainArr = eye.decrNvSphereArray.map((el, i) => el + eye.decrNvCylinderArray[i] / 2)

						const xVal = eye.accomodationFixationArrInCm
						const y1Val = eyeStrainArr
						const y2Val = eye.decrNvFixationPowerArray

						const sphere = Math.max(...y1Val)
						const sphere2 = Math.max(...y2Val)
						const sphereMax = Math.min(...y1Val)
						const accomodation = Math.abs(sphereMax - sphere)
						const comfort = accomodation / 2
						const comfortDist = Math.min(100 / comfort, 200)
						const effortDist = Math.min(100 / accomodation, 200)

						xVal.forEach((el, i) => {
							if (el <= 400 && y1Val[i] >= -20 && y1Val[i] <= 20) {
								if (el > 100) {
									eye.accomodationXValues.push(middleX - sizeX / 3 - (sizeX / 1800) * (el - 100))
								} else {
									eye.accomodationXValues.push(middleX + sizeX / 2 - (el * sizeX * 5) / 600)
								}
							} else {
								// the first element should never reach this condition; however, we use 0 as a fallback to avoid breaking the chart later,
								eye.accomodationXValues.push(eye.accomodationXValues[i - 1] || 0)
							}
						})

						xVal.forEach((el, i) => {
							if (el <= 400 && y1Val[i] >= -20 && y1Val[i] <= 20) {
								eye.accomodationEyeStrainYValues.push(400 - (middleY + sizeY / 2 + ((y1Val[i] - sphere) * sizeY) / sphereRange))
							} else {
								// the first element should never reach this condition; however, we use 0 as a fallback to avoid breaking the chart later,
								eye.accomodationEyeStrainYValues.push(400 - (eye.accomodationEyeStrainYValues[i - 1] || 0))
							}
						})

						xVal.forEach((el, i) => {
							if (el <= 400 && y1Val[i] >= -20 && y1Val[i] <= 20) {
								eye.accomodationStimulusYValues.push(400 - (middleY + sizeY / 2 + ((y2Val[i] - sphere2) * sizeY) / sphereRange))
							} else {
								// the first element should never reach this condition; however, we use 0 as a fallback to avoid breaking the chart later,
								eye.accomodationStimulusYValues.push(400 - (eye.accomodationStimulusYValues[i - 1] || 0))
							}
						})

						const sizeXRecalc = eye.accomodationXValues.slice(-1)[0] - eye.accomodationXValues[0]

						let greenWidth: number
						if (comfortDist > 100) {
							greenWidth = sizeXRecalc / 6 - (sizeXRecalc / 840) * (comfortDist - 60)
						} else {
							greenWidth = sizeXRecalc - (comfortDist * sizeXRecalc * 5) / 600
						}

						let yellowWidth: number
						if (effortDist > 100) {
							yellowWidth = sizeXRecalc / 6 - (sizeXRecalc / 6 / 140) * (effortDist - 60) - greenWidth
						} else {
							yellowWidth = sizeXRecalc - (effortDist * sizeXRecalc * 5) / 600 - greenWidth
						}

						const orangeWidth = sizeXRecalc - (greenWidth + yellowWidth)

						const closest = eye.accomodationFixationArrInCm.reduce(
							(previousClosest, currentValue, currentIndex) => {
								const currentDiff = Math.abs(currentValue - topic.range_low)
								const previousClosestDiff = Math.abs(previousClosest.value - topic.range_low)

								if (currentDiff < previousClosestDiff) {
									return { value: currentValue, index: currentIndex }
								}
								return previousClosest
							},
							{ value: eye.accomodationFixationArrInCm[0], index: 0 }
						)

						eye.inRange = sizeXRecalc - (orangeWidth + yellowWidth) >= eye.accomodationXValues[closest.index]
					} else {
						eye.inRange = false
						console.error("accomodation_topic arrays lengths aren't equal")
					}
				}

				break
		}
	}

	clearMaps() {
		this.userImpactReportMap = new Map<string, ImpactReportData>()
		this.userImpactDatesMap = new Map<number, string[]>()
		this.userImpactHistoryMap = new Map<string, ImpactHistoryData>()
		this.userImpactRecommendationsMap = new Map<string, ImpactRecommendationResponse>()
	}
}
