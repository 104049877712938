import { Component, ElementRef, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ActivatedRoute } from '@angular/router'
import { faTrashAlt, faEdit, faEye, faCalendarAlt, faRectangleList } from '@fortawesome/free-regular-svg-icons'
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons'
import { faLaptopCode, faDollarSign, faXmark, faRotate, faLink } from '@fortawesome/free-solid-svg-icons'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'

import { Doctor } from '../../models/doctor.model'
import { Util } from '../../models/util.model'

import { BalanceRec } from '../../models/salePlan.model' // 28.09.2022

import { DoctorModal } from './doctor.modal'
import { MiniModal } from './mini.modal' // 22.03.22
import { BalanceModal } from './balance.modal'
import { RelationInfoModal } from '../relations/relationInfo.modal'
import { UserDevicesModal } from './userDevices.modal'

import { DeleteModalContent, itemType } from '../delete/delete.modal'
import { RelationsModal } from './relations/relations.modal'

import { MatTable, MatTableDataSource } from '@angular/material/table' //10.10.22
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator' //10.10.22
import { MatSort, Sort } from '@angular/material/sort' //10.10.22
import { FormControl } from '@angular/forms'
import { TablePrefs, Preferences } from '../../models/settings.model'
import { Subscription } from 'rxjs'
import { UserEventsModal } from 'src/app/elements/userEvents/userEvent.modal'
import { Events, UserEvents } from 'src/app/models/userEvents.model'
import { ToastOptions } from 'src/app/models/toast.model'
import { AppToastService } from 'src/app/service/toast.service'

@Component({
	selector: 'doctors',
	templateUrl: './doctorList.component.html',
	styleUrls: ['./doctorList.component.scss'],
})
export class DoctorListComponent implements OnInit, OnDestroy, AfterViewInit {
	doctorList: MatTableDataSource<Doctor>
	doctorListRaw: Doctor[]
	doctorListChanged: Subscription

	@ViewChild(MatPaginator) paginator: MatPaginator //10.10.22
	@ViewChild(MatSort) sort: MatSort //10.10.22
	@ViewChild('filter') input: ElementRef //10.10.22
	@ViewChild(MatTable) table: MatTable<any>
	displayedColumns: string[]
	sortStatus: Sort
	pageStatus: PageEvent
	doctorPref: TablePrefs
	localStorageName: string

	originalFilterPredicate: any

	usernameFilter = new FormControl('')
	usertypeFilter = new FormControl('')
	usersubtypeFilter = new FormControl('')
	codeFilter = new FormControl('')
	istestFilter = new FormControl('')
	countryFilter = new FormControl('')
	subscriptiontimeFilter = new FormControl('')
	patientcountFilter = new FormControl('')
	createdbyFilter = new FormControl('')

	filterValues = {
		username: '',
		user_type: '',
		user_subtype: '',
		code: '',
		is_test: '',
		country: '',
		subscriptionTime: '',
		patientCount: '',
		created_by: '',
	}

	disableSort = 'false'

	//Slider definition 13.10.22
	color = 'primary'
	AdvanceChecked = false
	disabled = false
	//end

	location: any
	modal: any

	currentAction: string
	currentModal

	reloadEnable: boolean

	success = false
	fail = false

	currentDoctor: Doctor // arriva dalla lista, non ha tutte le tabelle figlie

	refId: number // 04.05.2022

	//miniDoct: User; // 04.02.2021 per nuovo miniB -> spostato

	btnSaveDisabled: boolean // 11.02.2021
	validMiniPwd: boolean // 31.03.2021

	filter = '' // usernamecontains.usernameContainsFilter();  // username o code
	filterNameSurname = '' // namecontains.nameContainsFilter(); // name o username

	filterUser = '' // userContains.userContainsFilter();  // 24.10.2019 estesa per i distributori, anche su code

	filterByBrand = '' // brandFilter.brandFilter();

	filterString: string = '' // usato per campi non crittati
	filterName: string = '' // usato per il name, decrittato

	// 14.11.2017 solo per NS, metto a true per velocizzare la lista
	ignoreCritt = false

	filtersEnabled // 12.11.2019

	// 13.11.2019 usare brands su session, centralizzato [ls]
	brands = []
	filterBrand: string
	doctList
	totElements: number // 27.01.2022

	doctorsFilterEdit: Doctor[] // lista usata per mostrare i colleghi di un gruppo

	//input per la directive
	//refreshFlag: string  // 20.01.2023 non serve piu'

	faEdit = faEdit // per usarla su html  13.01.2022
	faTrashAlt = faTrashAlt
	faEye = faEye
	faDevice = faLaptopCode // 07.09.2022
	faDollar = faDollarSign
	faXmark = faXmark
	faLaptopHouse = faLaptopHouse
	faRotate = faRotate
	faLink = faLink
	faRectangleList = faRectangleList

	constructor(
		public session: SessionService,
		public translator: TranslateService,
		public modalService: NgbModal,
		public dataService: DataModelService,
		public myMatPagIntl: MatPaginatorIntl,
		private activatedRoute: ActivatedRoute,
		private toastService: AppToastService
	) {
		Util.debug('(doctListComponent) - constructor')

		// 09.06.2022 serve ???
		//this.session.checkRoute();  // controlla se e' loggato, serve per reload ?

		this.ignoreCritt = this.getDefaultCrittVal()

		// 25.03.2020 per nuova tabella FE
		//this.manageDoctList();
		this.doctList = [] // 14.01.2022
		this.clearCurrentDoctor() // lo inizializza vuoto

		this.loadUrlParameters() // valorizza refId se arrivo da listaRemotes, come lev3

		//this.wrongPwd = false;
		this.reloadEnable = false

		this.filtersEnabled = false
		this.brands = this.session.brands // 13.11.2019

		// 23.03.2022 erano su init, meglio qui ?
		//this.refreshFlag = 'init_DoctListComp' // innesca il load della tabella

		this.initColumns()

		this.localStorageName = this.session.getUserId() + ' - doctortPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.doctorPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.doctorPref = new TablePrefs()

			this.doctorPref.empty = false
			// default data
			this.doctorPref.sort = 'subscriptionTime'

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.doctorPref))
		}
	}

	ngOnInit(): void {
		Util.debug('(doctListComp) - ngOnInit')

		this.doctorListChanged = this.dataService.doctoListChanged.subscribe((doctors: Doctor[]) => {
			// console.log(doctors)
			this.initTable(doctors)
		})

		this.usernameFilter.valueChanges.subscribe((data) => {
			this.filterValues.username = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.usertypeFilter.valueChanges.subscribe((data) => {
			this.filterValues.user_type = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.usersubtypeFilter.valueChanges.subscribe((data) => {
			this.filterValues.user_subtype = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.codeFilter.valueChanges.subscribe((data) => {
			this.filterValues.code = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.istestFilter.valueChanges.subscribe((data) => {
			this.filterValues.is_test = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.countryFilter.valueChanges.subscribe((data) => {
			this.filterValues.country = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.subscriptiontimeFilter.valueChanges.subscribe((data) => {
			this.filterValues.subscriptionTime = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.patientcountFilter.valueChanges.subscribe((data) => {
			this.filterValues.patientCount = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		this.createdbyFilter.valueChanges.subscribe((data) => {
			this.filterValues.created_by = data.toLowerCase()
			this.doctorList.filter = JSON.stringify(this.filterValues)
		})

		// translation for the mat-paginator
		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')
		// for page destroying
		window.onbeforeunload = () => this.ngOnDestroy()
	}

	ngAfterViewInit(): void {
		this.loadDoctorList(false)
	}

	advanceFilter() {
		//quando abilito o disabilito il filtro avanzato, cambio la modalitá del filtro
		if (this.AdvanceChecked) {
			//resetto il filter search quando vado nella modalitá avanzata
			this.input.nativeElement.value = ''
			this.doctorList.filter = ''

			// this.createFilter(this.session);
			this.doctorList.filterPredicate = this.createFilter() // Cambio il filtro
			this.disableSort = 'true'
		} else {
			// resetto tutti i filtri
			if (this.usernameFilter.value != '') {
				this.usernameFilter.setValue('')
			}
			if (this.usertypeFilter.value != '') {
				this.usertypeFilter.setValue('')
			}
			if (this.usersubtypeFilter.value != '') {
				this.usersubtypeFilter.setValue('')
			}
			if (this.codeFilter.value != '') {
				this.codeFilter.setValue('')
			}
			if (this.istestFilter.value != '') {
				this.istestFilter.setValue('')
			}
			if (this.countryFilter.value != '') {
				this.countryFilter.setValue('')
			}
			// if (this.subscriptiontimeFilter.value != '') {
			// 	this.subscriptiontimeFilter.setValue('');
			// }
			if (this.patientcountFilter.value != '') {
				this.patientcountFilter.setValue('')
			}
			if (this.createdbyFilter.value != '') {
				this.createdbyFilter.setValue('')
			}
			//end
			this.disableSort = 'false'
			this.doctorList.filterPredicate = this.originalFilterPredicate
		}
	}

	reloadList() {
		Util.debug('(PatientListComponent) - reload')
		this.reloadEnable = false
		this.loadDoctorList(true)
	}

	createFilter(): (data: any, filter: string) => boolean {
		let filterFunction = function (data, filter): boolean {
			let searchTerms = JSON.parse(filter)

			if (data.patientCount === undefined) {
				//se undefined lo imposto ''
				data.patientCount = ''
			}

			// if (data.subscriptionTime === null) {
			// 	// se null lo imposto ''
			// 	// data.subscriptionTime = Date.parse(null);
			// 	data.subscriptionTime = new Date(0);
			// }

			return (
				data.username.toLowerCase().indexOf(searchTerms.username) !== -1 &&
				data.user_type.toLowerCase().indexOf(searchTerms.user_type) !== -1 &&
				data.user_subtype.toLowerCase().indexOf(searchTerms.user_subtype) !== -1 &&
				data.code.toLowerCase().indexOf(searchTerms.code) !== -1 &&
				data.is_test.toLowerCase().indexOf(searchTerms.is_test) !== -1 &&
				data.country.toLowerCase().indexOf(searchTerms.country) !== -1 &&
				// data.subscriptionTime.toLocaleDateString().toString().toLowerCase().indexOf(searchTerms.subscriptionTime) !== -1 && //non funziona bene
				data.patientCount.toString().toLowerCase().indexOf(searchTerms.patientCount) !== -1 &&
				data.created_by.toString().toLowerCase().indexOf(searchTerms.created_by) !== -1
			)
		}
		return filterFunction
	}

	// tentativo di fare la richiesta al server solo quando serve, se lista giá caricata prima, o anche nelle statistiche, non la riscarica, spostata la chiamata
	// nel ngAfterViewInit, in quanto le var sort in viewchild, se avevo giá la lista pronta non erano inizializzate
	// forse viene usato per ricaricare la lista dopo la chiusura del modale
	loadDoctorList(force) {
		if (this.dataService.hasLoadedDoctors() && !force) {
			Util.debug('(DoctorsList) - doctors already loaded')

			// console.log(this.dataService.doctorList)

			this.initTable(this.dataService.doctorList)
		} else {
			Util.debug('(DoctorsList) - doctors not loaded yet ')

			let ignoreCritt = !this.session.isInstaller()

			this.session
				.loadDoctorList(ignoreCritt)
				.then((doctors) => {
					// console.log(doctors)
					// this.initTable(doctors) // non serve piú con la subscription
				})
				.catch((myErr) => {
					let msg = this.session.parseErrorMessage(myErr, 'trace')
					Util.debug('(DoctorsList) KO ' + msg)
					console.log(myErr)
				})
		}
	}

	private initTable(doctors: Doctor[]) {
		Util.debug('(DoctorList) initTable')
		// console.log(doctors)
		// 18.04.2023 fix, non qui
		//this.doctorsFilterEdit = doctors
		//Util.debug('(DoctorsLst) tot: ' + doctors.length) // 18.11.2022

		this.doctorListRaw = doctors

		this.doctorList = new MatTableDataSource<Doctor>(this.doctorListRaw) // 24.11.2022 ripristinata

		this.doctorList.paginator = this.paginator
		this.doctorList.sort = this.sort

		// se io aggiungo alla lista degli operator non decryptati, un operator decryttato, il filtro non funziona piú. Come se uno dei campi nuovi non gli comoda
		this.doctorList.filterPredicate = function (data, filter: string): boolean {
			return (
				data.username.toLowerCase().includes(filter) ||
				data.user_type.toLowerCase().includes(filter) ||
				data.user_subtype.toString().includes(filter) ||
				data.is_test.toString().includes(filter) ||
				data.code.toString().includes(filter) ||
				data.groupId.toString().includes(filter) ||
				data.country.toString().includes(filter) ||
				data.mainAddress.organization.toString().includes(filter)
			)
		}

		this.originalFilterPredicate = this.doctorList.filterPredicate // mi ricavo il filterPredicate originale, in modo da poterlo applicare all'occorrenza per cambiare tipo di filtro

		//Implementato un ordinamento automatico al caricamento della pagina
		// automatic sort
		this.ApplySettings(this.doctorPref, this.doctorList)

		setTimeout(() => {
			this.reloadEnable = true
		}, 5000)

		//****** solo per test *******************************
		let totExpected = doctors.length
		let totDispl = this.doctorList.data.length

		if (totExpected != totDispl) {
			Util.debug('(DoctorsList) ERR, forzare reload page! expected docts ' + totExpected + ' displayed: ' + totDispl)
			// TODO reload automatico - FIXME
			//if (!Config.isProductionMode) {
			//alert('list not correctly loaded, please force page refresh')
			//}
		}
		// ************************************
	}

	public ApplySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		this.input.nativeElement.value = pref.filter

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}

	initColumns() {
		this.displayedColumns = []
		this.displayedColumns.push('username', 'code', 'patientCount')

		if (this.session.isInstaller()) {
			this.displayedColumns.push('groupId', 'user_type', 'user_subtype', 'country', 'organization')
		}

		if (this.session.isLevel3() || this.session.isStats() || this.session.isDistributor() || this.session.isSupport()) {
			this.displayedColumns.push('user_type', 'user_subtype', 'country', 'subscriptionTime')

			if (!this.session.isSupport()) {
				// Azure #17847
				this.displayedColumns.push('created_by')
			}

			if (this.session.isAdmin()) {
				this.displayedColumns.push('is_test')
			}
		} else if (this.session.isGroupB()) {
			this.displayedColumns.push('user_type', 'country', 'subscriptionTime') // serve la country ??
		} else if (this.session.isSpecialist()) {
			this.displayedColumns.push('info')
		}

		this.displayedColumns.push('filter')
	}

	/*
	// 12.05.2022 verificare tot record caricati in tabella ?
	ngAfterViewInit() {
		// qui this.totElements undefined
		//Util.debug("(doctListComp) - ngAfterViewInit - totElem: "+this.totElements); 		
	}
	*/

	// 04.05.2022
	private loadUrlParameters() {
		this.refId = 0

		if (this.activatedRoute != null) {
			// 09.06.2022 added test
			this.activatedRoute.queryParams.subscribe((params) => {
				let myParam = params['distrib']
				if (myParam != null && parseInt(myParam) > 0) {
					this.refId = myParam
				}
			})
		}
	}

	// 09.09.2021 vd simile su session
	// 26.08.2021 metto la username del superB al posto del created by, per i mini
	getStrCreator(subType, usrId) {
		var ret = '' + usrId
		if (subType == Config.SUB_MINI) {
			var myDoct = this.getDoctorById(usrId)
			if (myDoct != null) {
				ret = myDoct.username
			}
		} else if (this.session.isLevel3()) {
			// 30.09.2021
			ret = this.session.getAdminUsername(usrId) // visibile solo per i livelli 3
		}

		return ret
	}

	getDoctorById(myId) {
		var myDoct = null
		if (this.doctList != null) {
			//myDoct = (this.doctList).filter(x => x.id == myId)[0];
			myDoct = this.doctList.find((x) => x.id == myId)
		}
		return myDoct
	}

	// 20.11.2019
	getDefaultCrittVal() {
		var ret = false
		if (this.session.isGod() || this.session.isSpecialist() || this.session.isVice()) {
			ret = true
		}
		return ret
	}

	showFilters() {
		if (this.filtersEnabled == false) this.filtersEnabled = true
		else this.filtersEnabled = false
	}

	getBrand(currBrand) {
		return '' // TEMP TODO this.session.getClassBrand(currBrand); // 19.08.2019
	}

	setCurrentDoctor(doctor: Doctor) {
		if (doctor) {
			// 29.09.2022 patch per non sovrascrivere da lista se ho gia' il full obj
			if (this.currentDoctor && this.currentDoctor.isDecrypted && !doctor.isDecrypted && this.currentDoctor.user_id == doctor.user_id) {
				Util.debug('(setCurrentDoctor) - already loaded')
				return
			}

			this.currentDoctor = doctor

			// 18.04.2023 moved here
			//if (doctorFromList.isSuperB()) {  // 18.04.2023
			if (doctor.isGroupB() && (this.session.isAdmin() || this.session.isSupport())) {
				let currGroup = doctor.groupId
				//Util.debug('(setCurrentDoctor) current group: '+currGroup);
				if (currGroup > 0) {
					//this.doctorsFilterEdit = this.doctorListRaw.filter((d) => d.created_by == check && d.isActive == true)
					this.doctorsFilterEdit = this.doctorListRaw.filter((d) => d.created_by == currGroup && d.isActive == true)
					//Util.debug('(setCurrentDoctor) tot colleagues: '+this.doctorsFilterEdit.length);
				}
			}
		} else {
			this.clearCurrentDoctor()
		}
	}

	clearCurrentDoctor() {
		this.currentDoctor = new Doctor()
	}

	// 24.02.2021 portata fuori funzione [ls]
	private loadSingleDoctor(doctId: number, force?: boolean): Promise<boolean> {
		/* 23.11.2022 ko, in certi casi non funziona - FIXME 
    // 19.10.2022 patch, il test isDecrypted non garantisce che sia stato caricato il singolo
		let loadedSingle = this.session.hasLoadedDoctor(doctId)
		// 29.09.2022
		if (this.currentDoctor && this.currentDoctor.user_id == doctId) {
			//if (this.currentDoctor.isDecrypted && && this.currentDoctor.settings) {
			if (loadedSingle) {
				Util.debug('(loadSingleDoc) 1 - already loaded');
				return Promise.resolve(true);
			}
		}
    */

		//AGGIUNTO IL FORCE FINCHÉ NON ARRIVERÁ DA SIGNALR IL DOCTOR MODIFICATO

		let origDoctor = this.session.getDtDoctor(doctId)

		if (origDoctor && !force) {
			// 07.09.2022 ok already loaded
			this.setCurrentDoctor(origDoctor)
			Util.debug('(loadSingleDoc) 2 - already loaded')
			return Promise.resolve(true)
		} else {
			//Util.debug('(loadSingleDoc) 3 - going to load id '+doctId);
			return this.session
				.loadDoctor(doctId)
				.then(() => {
					origDoctor = this.session.getDtDoctor(doctId)
					if (origDoctor != null) {
						this.setCurrentDoctor(origDoctor)
						Util.debug('(loadSingleDoc) ok loaded')
						//faccio bk dei campi per gestire eventuale delete per cfr [ls]
						return true
					}
				})
				.catch((err) => {
					//var msg = (err.data)? err.data.error : err.toString();
					let msg = this.session.parseErrorMessage(err, 'alert') // 28.03.2022
					Util.debug('(loadSingleDoc) - KO ' + msg)
					alert('Error loading doctor id ' + doctId)
					return false
				})
		}
	}

	showPatients(doc) {
		// 20.05.2022 riab
		/*
    if(this.session.isStats()){  // 20.07.2021
      return;  // ignora
    }*/
		// 01.06.2023 sespecialist adesso non permettiamo di filtrare la lista
		if (this.session.isSpecialist() || this.session.isInstaller()) {
			return
		} else {
			this.session.loadPatientsPage(doc)
		}
	}

	// 06.09.2022
	openDeviceModal(doctorFromList: Doctor) {
		// intanto parto con questo (proveniente dalla lista,senza decrypt), poi lo aggiorno
		this.setCurrentDoctor(doctorFromList)
		let len = 0

		if (doctorFromList.userDevices) {
			len = doctorFromList.userDevices.length
			Util.debug('(usrDevice) [1] tot: ' + len)
		}

		// richiede info current doctor x dettagli e lista devices
		this.loadSingleDoctor(doctorFromList.user_id, true).then((flagDone) => {
			if (this.currentDoctor.userDevices) {
				len = this.currentDoctor.userDevices.length
				Util.debug('(usrDevice) [2] tot: ' + len)
			}

			//alert("devices list, tot for this user: "+len);

			this.currentModal = this.modalService.open(UserDevicesModal, { size: 'xl' })
			this.currentModal.componentInstance.devicesList = this.currentDoctor.userDevices // 15.09.2022 TODO

			// arriva qui facendo la close del modal
			this.currentModal.result.then(
				(changed) => {
					Util.debug('UserDeviceModal - After modal closed, changes ? ' + changed)
				},
				(reason) => {
					let ris = 'Dismissed ' + Util.getDismissReason(reason)
					Util.debug(ris)
				}
			)
		})
	}
	private openDoctorModalChanges(doctorFromList: Doctor) {
		// 22.02.2021 nascondo msg ok/error
		this.success = false
		this.fail = false
		this.currentAction = 'modify'
		if (this.session.isSupport() && !this.session.isSuperSupport()) {
			this.currentAction = 'support'
		}

		let check = doctorFromList.created_by // inutile passarlo come parametro se e' gia' contenuto nel primo

		//this.dispositivePwd = "";
		//this.wrongPwd = false;
		//this.new_nexy_sn = ""; // 27.06.2018 ripulisce da edit precedenti
		//this.force_nexy = 'N'; // per il radio di scelta

		// intanto parto con questo (proveniente dalla lista,senza decrypt), poi lo aggiorno
		this.setCurrentDoctor(doctorFromList)

		// map the doctors array
		this.doctorsFilterEdit = [] // svuoto da prima

		// richiede info current doctor x display livelli 2
		this.loadSingleDoctor(doctorFromList.user_id, true).then((flagDone) => {
			Util.debug('(openDoctorModal) tot colleagues: ' + this.doctorsFilterEdit.length)

			this.currentModal = this.modalService.open(DoctorModal, { size: 'xl' })
			this.currentModal.componentInstance.currentDoctor = this.currentDoctor
			this.currentModal.componentInstance.currentAction = this.currentAction
			this.currentModal.componentInstance.doctorsFilterEdit = this.doctorsFilterEdit

			Util.debug('(openDoctorModal) test 1') // 19.10.2022

			// arriva qui facendo la close del modal
			this.currentModal.result.then(
				(changedDoc) => {
					Util.debug('DoctorModal - After modal closed, changed ? ' + changedDoc)
					//this.submit(changedDoc); // gia' fatto dalla chiamante

					if (changedDoc) {
						// 15.09.2022
						//this.refreshFlag = 'editDoct_done'
						this.loadDoctorList(true) // ricarico la lista dopo la modifica
					}

					// 19.11.2019  ripristino default [ls]
					this.ignoreCritt = this.getDefaultCrittVal()
				},
				(reason) => {
					let ris = 'Dismissed ' + Util.getDismissReason(reason)
					Util.debug(ris)
				}
			)
		})
	}
	openDoctorModal(doctorFromList: Doctor) {
		// tolto check, e' un campo di doctorFromList
		if (doctorFromList == null) {
			this.currentAction = '-' // disab
			Util.debug('(openDoctorModal) edit only')
			return
		}
		this.session.verifyUserToken().then(
			(changedDoc) => {
				this.openDoctorModalChanges(doctorFromList) // , check)
			},
			(reason) => {
				alert('error')
			}
		)
	}

	goToTelerefract(doctorFromList: Doctor) {
		const currentUser = this.session.getCurrentUser()

		this.session.goLiveTelerefract({
			token: this.session.getCurrentUser().token.substring(7),
			name: `${currentUser.firstname} ${currentUser.lastname}`,
			organization: currentUser.mainAddress.organization,
			userId: currentUser.user_id,
			role: currentUser.role,
			groupId: currentUser.groupId,
			subtype: currentUser.user_subtype,
			targetOrganization: doctorFromList.mainAddress.organization,
			targetGroupId: doctorFromList.groupId,
		})
	}

	// 02.03.2021  TODO separare
	openCreateColleagueModal() {
		this.btnSaveDisabled = false
		// 04.02.2021 to create a miniB
		if (this.session.isSuperB()) {
			this.currentAction = 'createMini'
			//Util.debug("(openCreateColleagueModal) createMini");
		} else {
			this.currentAction = '-' // disab
			Util.debug('(openCreateColleagueModal) 404')
			return
		}

		// 02.03.2021 controllo su maxColleagues
		// quelli del doctor che li sta creando
		var currSettings = this.session.getCurrentUser().settings
		var totColleagues = this.doctList.length // li ha appena caricati

		// 18.08.2021 vanno esclusi i miniB deleted ?

		Util.debug('(openCreateColleagueModal) max colleagues:' + currSettings.max_colleagues + ' curr:' + totColleagues)

		if (currSettings != null && totColleagues >= currSettings.max_colleagues) {
			//var msg = "You reached the max number of colleagues for your group. Please contact NS support";
			var msg = this.translator.instant('CREATE_USER.COLLEAGUES_MSG')
			alert(msg)
			return
		}

		/*
    // 22.02.2021 nascondo msg ok/error
    this.success = false;
    this.fail = false;
    
    this.miniDoct = new User(this.session.cryptoUtils); 
    this.miniDoct.type = UserType.OPERATOR;    
    this.miniDoct.subType = UserType.DOCTOR;    
    this.miniDoct.user_subtype = "Mini";

    // forzo la stessa organization dell'utente corrente, poi readonly ?
    this.miniDoct.organization = this.session.getCurrentUser().organization;    
    */

		this.currentModal = this.modalService.open(MiniModal, { size: 'xl' })
		//this.currentModal.componentInstance.currentSettings = this.currentDoctor;

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(docId) => {
				//this.loadDoctorList() // 20.01.2023 spostato sotto, solo se e' cambiato

				Util.debug('MiniModal - After modal closed ' + docId)
				//this.submit(changedDoc); // gia' fatto dalla chiamante

				if (docId != null && parseInt(docId) > 0) {
					//this.refreshFlag = 'miniCreated_' + docId
					this.loadDoctorList(true) // ricarico la lista dopo la modifica
				}

				// 19.11.2019  ripristino default [ls]
				//this.ignoreCritt = this.getDefaultCrittVal();
			},
			(reason) => {
				let ris = 'Dismissed ' + Util.getDismissReason(reason)
				Util.debug(ris)
			}
		)
	}

	// 21.01.2022 for ref to see display name and signature for each operator
	openInfoModal(doctorFromList: Doctor) {
		if (!this.session.isSpecialist()) {
			alert('(showRelDetails) - forbidden!')
		}

		this.currentAction = 'showRelInfo'

		// intanto parto con questo (proveniente dalla lista,senza decrypt)
		this.setCurrentDoctor(doctorFromList)

		// 07.06.2023 a cosa serve chiedere anche il doc? [ls]
		// 28.01.2022 richiedo la relazione per vedere displ name e firma
		let docId = parseInt(doctorFromList.id)
		//this.session.loadDoctor(docId)
		//.then(() => {
		//let currDoct: Doctor
		//currDoct = this.session.getDtDoctor(docId)
		//if (currDoct != null) {
		//this.setCurrentDoctor(currDoct)

		Util.debug('(showRelDetails) asking for relation...')
		this.session.getCurrentRelation(docId).then((currRelation) => {
			// 07.06.2023 se e' una nuova relazione, devo aprire la keybox_public, solo per la prima volta
			if (currRelation && currRelation.keybox_public) {
				Util.debug('(showRelDetails) Nuova relazione')
				// TODO
			}

			this.currentModal = this.modalService.open(RelationInfoModal, { size: 'xl' })
			this.currentModal.componentInstance.currentRel = currRelation

			// arriva qui facendo la close del modal
			this.currentModal.result.then(
				(changes) => {
					Util.debug('RelInfo - After modal closed, ' + changes)
				},
				(reason) => {
					let ris = 'Dismissed ' + Util.getDismissReason(reason)
					Util.debug(ris)
				}
			)
		})
		//}
		//})
	}

	// anche il support
	// 28.09.2022 only by admins
	openSaleBalanceModal(doctorFromList: Doctor) {
		if (!this.session.isAdmin() && !this.session.isSupport()) {
			alert('(saleBalance) - forbidden!')
			return
		}

		this.currentAction = 'saleBalance'

		// intanto parto con questo (proveniente dalla lista)
		this.setCurrentDoctor(doctorFromList)

		let docId = parseInt(doctorFromList.id)
		//let docId = doctorFromList.user_id;

		// richiede info current doctor x dettagli
		this.loadSingleDoctor(docId, true).then((flagDone) => {
			//this.session.getUserBalance(docId)  // todo
			//.then((records) => {

			this.session.loadUserBalance(docId).then((resp) => {
				//Util.debug("(saleBalance) ");
				//console.log(resp);
				let records: BalanceRec[]
				records = []
				if (resp && resp.balance) {
					for (let i = 0; i < resp.balance.length; i++) {
						let rec = new BalanceRec(resp.balance[i])
						if (rec.sku) {
							rec.description = this.session.getSkuDescription(rec.sku)
						}
						records.push(rec)
					}
					this.currentDoctor.setBalanceRecords(records)
					//doctorFromList.setBalanceRecords(records);
				}

				this.currentModal = this.modalService.open(BalanceModal, { size: 'xl' })

				this.currentModal.componentInstance.currentDoctor = this.currentDoctor
				//this.currentModal.componentInstance.records = records;

				// arriva qui facendo la close del modal
				this.currentModal.result.then(
					(changedDoc) => {},
					(reason) => {
						Util.debug('Dismissed ' + Util.getDismissReason(reason))
					}
				)
			})
		})
	}

	public openDeleteModal(doctor: Doctor) {
		this.setCurrentDoctor(doctor)
		this.currentAction = 'delete'

		this.currentModal = this.modalService.open(DeleteModalContent, { size: 'l' })

		this.currentModal.componentInstance.itemType = itemType.OPERATOR
		this.currentModal.componentInstance.itemDescription = 'Operator: ' + doctor.getFullName() + ' - ' + doctor.username

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(confirmed) => {
				Util.debug('(delDoc) After modal closed: ' + confirmed) // confirmed = true/false
				if (confirmed) {
					this.deleteDoct(doctor)
				}
			},
			(reason) => {
				let ris = '(delDoc) Dismissed ' + Util.getDismissReason(reason)
				Util.debug(ris)
			}
		)

		//this.dispositivePwd = "";
		//this.wrongPwd = false;
	}

	private deleteDoct(doc) {
		// 08.02.2021 esteso anche ai superB
		// anticipato qui il test, per tutte le azioni --ls
		//if (!(this.session.isGod() || this.session.isVice())) {
		if (!(this.session.isGod() || this.session.isVice() || this.session.isSuperB())) {
			Util.debug('DL Utente non abilitato, act: ' + this.currentAction)
			return
		}

		// controllo fatto sul chiamante
		// conferma password dispositiva per delete

		this.session
			.deleteDoctor(this.currentDoctor.id) // doc ?
			.then(() => {
				//this.refreshFlag = 'DoctListPostDelete_' + this.currentDoctor.id // innesca il refresh della tabella
				alert('OK, user deleted!') // 23.03.2022

				this.loadDoctorList(true) // ricarico la lista dopo la delete
			})
	}

	public openDoctorRelations(doctor: Doctor) {
		this.setCurrentDoctor(doctor)

		this.currentModal = this.modalService.open(RelationsModal, { size: 'xl' })

		let promise: Promise<any> = this.loadSingleDoctor(doctor.user_id, true).then((flagDone) => {
			this.currentModal.componentInstance.waitDoctor = false
			this.currentModal.componentInstance.currentDoctor = this.currentDoctor
		})
		this.currentModal.componentInstance.loadDoctor = promise

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(confirmed) => {
				Util.debug('(change rel) After modal closed: ') // confirmed = true/false
			},
			(reason) => {
				Util.debug('(change rel) Dismissed ')
			}
		)
	}

	public openUserEvents(doctor: Doctor) {
		this.setCurrentDoctor(doctor)
		this.currentModal = this.modalService.open(UserEventsModal, { size: 'xl' })

		const promise = new Promise<boolean>((resolve, reject) => {
			const afterDate = this.currentDoctor.userEvents.askDate
			Util.debug('afterDate: ' + afterDate)
			// se ho una data passata, la passo alla chiamata, che mi ritornerá solo gli ultimi eventi
			// quindi poi devo chiamare un update e non un set degli eventi
			this.session
				.getUserEvents(doctor.user_id, false, afterDate)
				.then((events: UserEvents[]) => {
					if (afterDate) {
						this.currentDoctor.updateUserEvents(events)
					} else {
						this.currentDoctor.setUserEvents(events)
					}

					this.dataService.updateDoctorOnList(this.currentDoctor)

					this.currentModal.componentInstance.list = this.currentDoctor.userEvents.userEvent
					this.currentModal.componentInstance.userId = this.currentDoctor.user_id
					this.currentModal.componentInstance.userName = this.currentDoctor.getFullName()

					resolve(true)
				})
				.catch((err) => {
					console.log(err)
					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant(err.error.error)
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')

					reject()
					// INSERT ERR toast
				})
		})

		this.currentModal.componentInstance.isLevel1 = true
		this.currentModal.componentInstance.loadEvents = promise
	}

	// 31.03.2021 portata su Util, richiamata anche da altri [ls]
	validatePwd(value) {
		this.validMiniPwd = Util.validatePwd(value)
	}
	filterText() {
		this.doctorPref.filter = this.input.nativeElement.value
		this.doctorPref.filter = this.doctorPref.filter.trim().toLocaleLowerCase()
		this.doctorList.filter = this.doctorPref.filter
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.doctorPref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.doctorPref.itemsPerPage))

		this.doctorListChanged.unsubscribe()
	}
}
