<!-- <div class="loading-container" [hidden]="!dataService.isLoadingVisits()">
	<div class="tail-spin">&nbsp;</div>
</div> -->
<div class="visit-list-container container-fluid panel-modal position-relative">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
	</div>

	<div class="row">
		<div class="col">
			<div class="row" *ngIf="session.isLevel1() && session.isLocalNS()">
				<div class="col">
					<div class="test-buttons-container pe-4">
						<button class="btn btn-outline-secondary me-3" (click)="openDeviceModal()">New Device</button>
						<button class="btn btn-outline-secondary me-3" (click)="openVisitModal()">New Visit</button>
						<button class="btn btn-outline-secondary" (click)="openExamModal()">New Exam</button>
					</div>
				</div>
			</div>
			<div class="panel-heading p-0">
				<div class="row">
					<!-- <div class="col-6"> -->
					<!-- <a *ngIf="!singleOwner && !session.isSpecialist() " class="underline" (click)="session.loadPatientsPage(doctorId)"> {{ doctorName }} </a> -->
					<!--<a *ngIf="singleOwner" class="underline" (click)="session.loadPatientsPage()"> {{ 'PATIENT_LIST.PAGE_TITLE_DOCTOR' | translate }} </a>-->

					<!-- levati i click in quanto ora abbiamo la patient list flat e incasinano solo. Tenuto solo per admin-->
					<!-- <span *ngIf="!singleOwner && session.isSpecialist()"> {{ doctorName }} </span> -->

					<!-- &rArr; <span *ngIf="currentPatient">{{ currentPatient.name }}</span> -->
					<!-- </div> -->
					<!-- <div class="loading-container" [hidden]="dataService.hasLoadedPatient(patientId)">
						<div class="tail-spin">&nbsp;</div>
					</div> -->

					<div *ngIf="currentPatient">
						<patient [currPatient]="currentPatient"></patient>
					</div>
				</div>
			</div>
			<div class="panel-heading">
				<div class="heading-buttons-container">
					<div class="visit-button-container" *ngIf="isReqEnabled">
						<button type="button" class="btn btn-primary" (click)="requestReview()" [disabled]="selectedRowsHG.size == 0">
							<span>{{ 'VISITS.HG_REVIEW' | translate }}</span>
						</button>
					</div>

					<div class="visit-button-container" *ngIf="isAiReqEnabled">
						<button type="button" class="btn btn-primary" (click)="requestAIReview()" [disabled]="selectedRowsAI.size == 0">
							<span>{{ 'VISITS.AI_REVIEW' | translate }}</span>
						</button>
					</div>

					<div class="visit-button-container" *ngIf="canGrade">
						<button type="button" class="btn btn-primary" (click)="openWizardReport()" [disabled]="disableNewReportBtn">
							<span>{{ 'REPORT.NEW_REPORT' | translate }}</span>
						</button>
					</div>

					<!-- <div *ngIf="!session.isClalit()" class="visit-button-container">
						<button type="button" class="btn btn-primary position-relative" (click)="openReportList()" [disabled]="!hasReports">
							<span>
								{{ 'REPORT.REPORTS' | translate }}
								<span class="badge bg-danger" [hidden]="newReportCount == 0 || session.isSpecialist()"> {{ newReportCount }} </span>
							</span>
						</button>
					</div> -->

					<!-- <div class="visit-button-container" *ngIf="isAiReqEnabled || session.isAdmin()">
						<button type="button" class="btn btn-primary" (click)="openAiReportList()" [disabled]="!hasReportsAi">
							<span>
								<span class="me-2" *ngIf="aiReportReq > 0">
									<div class="lds-ring-small">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</span>

								{{ aiReportReq > 0 ? ('VISITS.WAIT_AI_REP' | translate) : ('VISITS.AI_REPORT' | translate) }}
								<span class="badge bg-danger" [hidden]="newReportAiCount == 0 || session.isSpecialist()"> {{ newReportAiCount }} </span>
							</span>
						</button>
					</div> -->
				</div>

				<div>
					<div>
						<button type="button" class="btn btn-sm btn-primary float-end reload" (click)="reloadList()" [disabled]="!reloadEnable">
							{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="spacer"></div>

		<div class="container visitList" style="min-height: 35vh">
			<loader [activateSpinner]="loadingVisitList"></loader>

			<!-- <div class="loader-container" [ngClass]="loadingVisitList ? 'show' : '' ">
				<div [hidden]="false" class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div> -->

			<table mat-table [dataSource]="visitList" matSort style="width: 100%" class="visit-list-container__table" [ngClass]="loadingVisitList ? '' : 'show'">
				<!-- id -->
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
					<td mat-cell *matCellDef="let element" class="normalcase">{{ element.id }}</td>
				</ng-container>

				<!-- HG_review -->
				<ng-container matColumnDef="HG_review">
					<th mat-header-cell *matHeaderCellDef>
						<div [innerHTML]="hgColumn"></div>
					</th>
					<td mat-cell *matCellDef="let element">
						<!-- checkbox hg -->
						@if(element.can_ask_HG) {
						<div class="checkbox d-flex" placement="left" *ngIf="element.is_visible == 'N' && !element.hasBeenReviewed && !element.is_waiting_HG" (click)="$event.stopPropagation()" ngbTooltip="{{ 'VISITS.SELECT_TO_REVIEW_HINT' | translate }}">
							<input *ngIf="element.can_ask_HG" class="form-check-input" type="checkbox" id="flexCheckDefault-{{ element.id }}" (change)="checkRowHG(element, $event)" [(ngModel)]="element.is_selected_HG" />
						</div>
						<!-- spinner -->
						<div *ngIf="element.is_waiting_HG" class="spinner-border spinner-border-sm text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
						} @else {
						<div class="d-inline me-2">
							<fa-icon [icon]="faBan" faBan ngbTooltip="{{ 'VISITS.SERVICE_NOT_AVAILABLE' | translate }}" aria-hidden="true"></fa-icon>
						</div>
						}

						<!-- ok giá richiesto e ricevuto il report -->
						<div *ngIf="element.hasBeenReviewed" class="d-inline">
							<i class="glyphicon me-2" ngbTooltip="{{'VISITS.HG_REPORT_DONE' | translate}}">
								<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
							</i>
						</div>

						<!-- ok report chiesto, in attesa -->
						<div *ngIf="element.is_visible == 'Y' && element.hasBeenReviewed == false && !element.is_waiting_HG">
							<i class="glyphicon me-2" ngbTooltip="{{ 'VISITS.ALREADY_SENT_HINT' | translate }} - {{ session.formatDateTime(element.grading_request) }}">
								<fa-icon [icon]="faUpload" faUpload aria-hidden="true"></fa-icon>
							</i>
							<!-- undo the review already sent -->
							<i class="fa-thin fa-rotate-left" (click)="undoReviewRequest(element); $event.stopPropagation()" ngbTooltip="{{ 'VISITS.UNDO_REVIEWED_REQUEST' | translate }}">
								<fa-icon [icon]="faRotateLeft" faRotateLeft aria-hidden="true"></fa-icon>
							</i>
						</div>

						<div *ngIf="element.is_visible == 'H'">
							<i class="glyphicon glyphicon-ban-circle" ngbTooltip="{{ 'VISITS.NO_GRADING_HINT' | translate }}" placement="right">
								<fa-icon [icon]="faTimesCircle" aria-hidden="true"></fa-icon>
							</i>
						</div>
					</td>
				</ng-container>

				<!-- AI_review -->
				<ng-container matColumnDef="AI_review">
					<th mat-header-cell *matHeaderCellDef>
						<div [innerHTML]="aiColumn"></div>
					</th>
					<td mat-cell *matCellDef="let element">
						<!-- checkbox ai -->
						<div class="d-flex">
							@if(element.can_ask_AI) {
							<div class="checkbox me-2 d-flex align-items-center" ngbTooltip="{{ 'VISITS.SELECT_TO_REVIEW_HINT' | translate }}" placement="left" (click)="$event.stopPropagation()">
								<input class="form-check-input" type="checkbox" id="flexCheckDefault-{{ element.id }}" (change)="checkRowAI(element, $event)" [(ngModel)]="element.is_selected_AI" />
							</div>
							} @else {
							<div>
								<fa-icon [icon]="faBan" class="me-2" faBan ngbTooltip="{{ 'VISITS.SERVICE_NOT_AVAILABLE' | translate }}" aria-hidden="true"></fa-icon>
							</div>
							} @if(element.ai_reviewed == 'Y'){
							<div>
								<fa-icon [icon]="faUpload" class="me-2" faUpload ngbTooltip="{{'VISITS.AI_REPORT_DONE' | translate}}" aria-hidden="true"></fa-icon>
							</div>
							}
						</div>
					</td>
				</ng-container>

				<!-- is_visible -->
				<ng-container matColumnDef="is_visible">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<span *ngIf="element.is_visible === 'Y' && !element.hasBeenReviewed">
							<i class="status-icon" [ngbTooltip]="'VISITS.REQUESTED_ON' | translate : { date: session.formatDateTime(element.grading_request) }" placement="top"></i>
						</span>
					</td>
				</ng-container>

				<!-- date -->
				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.DATE' | translate }}</th>
					<td mat-cell *matCellDef="let element" style="white-space: nowrap">{{ session.formatDateTime(element.date) }}</td>
				</ng-container>

				<!-- name -->
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>
						<span>{{ 'MISC.VISIT' | translate }}</span> <br />
						<span>(only for dev and Admin)</span>
					</th>
					<td mat-cell *matCellDef="let element" title="visit id: {{ element.id }}" style="white-space: nowrap" class="ps-1">{{ element.name }}</td>
				</ng-container>

				<!-- exam_type -->
				<ng-container matColumnDef="exam_type">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.EXAMS' | translate }}</th>
					<td mat-cell *matCellDef="let element" style="max-width: 50rem">
						<button *ngFor="let module of element.getTypes(); let in = index" [ngClass]="{ selected: isModSelected(element.id, module), reviewed: element.examTypes[in].is_reviewed == 'Y', hover_enabled: session.isSpecialist() }" class="module" (click)="onExamClick(element, module); $event.stopPropagation()">
							{{ getExamLabel(module) }}
							<i class="glyphicon" *ngIf="element.examTypes[in].is_reviewed == 'Y'" ngbTooltip="{{ 'VISITS.ALREADY_REVIEWED_HINT' | translate }}">
								<fa-icon [icon]="faCheck" aria-hidden="true"></fa-icon>
							</i>
						</button>
					</td>
				</ng-container>

				<!-- device -->
				<ng-container matColumnDef="device">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.DEVICE' | translate }}</th>
					<td mat-cell *matCellDef="let element" class="normalcase">{{ element.device }}</td>
				</ng-container>

				<!-- device_sn -->
				<ng-container matColumnDef="device_sn">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.DEVICE' | translate }} SN</th>
					<td mat-cell *matCellDef="let element" class="normalcase">{{ element.device_sn }}</td>
				</ng-container>

				<!-- has_xml -->
				<ng-container matColumnDef="has_xml">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.XML' | translate }}</th>
					<td mat-cell *matCellDef="let element" class="normalcase">
						<a *ngIf="element.has_xml" class="table-button" (click)="downloadXml(element.id); $event.stopPropagation()" ngbTooltip="{{ 'VISITS.DOWNLOAD_XML_HINT' | translate }}" placement="left">
							<fa-icon [icon]="faSave" style="color: #717ace" aria-hidden="true"></fa-icon>
						</a>
					</td>
				</ng-container>

				<!-- consent -->
				<ng-container matColumnDef="consent">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.CONSENT' | translate }}</th>
					<td mat-cell *matCellDef="let element" class="normalcase">
						<a *ngIf="element.consent_id" class="table-button" ngbTooltip="{{ 'VISITS.CONSENT_VALID_DATE' | translate : { date: session.formatDate(element.date), id: element.consent_id } }}" placement="top">
							<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
						</a>
					</td>
				</ng-container>

				<!-- PDF -->
				<ng-container matColumnDef="pdf">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>Print pdf</th>
					<td mat-cell *matCellDef="let element" class="normalcase" (click)=" $event.stopPropagation(); downloadPDF(element)">
						<div *ngIf="!element.is_waiting_PDF">
							@if(!element.can_see && session.isOptician()){

							<fa-icon [icon]="faBan" faBan ngbTooltip="{{ 'VISITS.SERVICE_NOT_AVAILABLE' | translate }}" aria-hidden="true"></fa-icon>
							}@else {
							<fa-icon [icon]="faFilePdf" faFilePdf aria-hidden="true"></fa-icon>
							}
						</div>

						<div *ngIf="element.is_waiting_PDF" class="spinner-border spinner-border-sm text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</td>
				</ng-container>

				<!-- last Column - block bouble click view - only click the buttons -->
				<ng-container matColumnDef="filter">
					<th mat-header-cell *matHeaderCellDef class="text-end">
						<mat-form-field appearance="outline">
							<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
						</mat-form-field>
					</th>
					<td mat-cell *matCellDef="let element"></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openCategoriesModal(row)" [class.frozen-visit]="!row.can_see && session.isOptician()"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons [ngClass]="loadingVisitList ? '' : 'show'"> </mat-paginator>
		</div>
	</div>
</div>
