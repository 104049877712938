<div class="panel panel-modal panel-default ng-cloak position-relative anamnesi-modal">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
	</div>

	<div class="container-fluid">
		<!-- PATIENT INFO -->
		<div class="row justify-content-center anamnesi-modal__patient-info">
			<div class="col-auto text-uppercase">
				<patient [currPatient]="currPatient"></patient>
			</div>
		</div>
		<!-- TABS -->
		<div class="row anamnesi-modal__header mt-2">
			<div class="col-12">
				<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-pills nav-justified" (navChange)="onNavChange($event)">
					<li *ngFor="let tab of anamnesisTabs | keyvalue" [ngbNavItem]="tab.value.id" class="nav-item me-3 position-relative">
						<a ngbNavLink uppercase class="nav-link fw-bold">
							{{ tab.value.label }} @if(tab.value.creationDate){
							<fa-icon [icon]="faCircleCheck" faCircleCheck ngbTooltip=" {{'ANAMNESIS_MODAL.COMPILED' | translate : {date: session.formatDate(tab.value.creationDate)} }} "></fa-icon>
							} @else {
							<fa-icon [icon]="faCircleXmark" faCircleXmark ngbTooltip="{{'ANAMNESIS_MODAL.NOT_COMPILED' | translate}}"></fa-icon>
							}
						</a>
						<ng-template ngbNavContent>
							@if(tab.key == 'va'){
							<app-pat-va [thisVa]="tab.value.va" [viewmode]="currentAction == 'view'" (uncorrected-populated)="tab.value.answered = $event" (out-va)="tab.value.va = $event"></app-pat-va>
							} @else {
							<app-pat-anamnesis
								[viewmode]="currentAction == 'view'"
								(out-anamnesis)="tab.value.anamnesi= $event; tab.value.answered = true"
								[thisAnamnesis]="tab.value.anamnesi"
								[currentStep]="tab.value.currentStep"
								[isImpact]="tab.value.label === translator.instant('ANAMNESIS_MODAL.IMPACT_ANAM')"
								(stepsFetched)="onStepsFetched(tab.key, $event)"></app-pat-anamnesis>
							}
						</ng-template>
					</li>
				</ul>
			</div>
		</div>
		@if(anamnesisTabs[activeTab].creationDate ){
		<div class="row anamnesi-modal__creation-date justify-content-center">
			<div class="col-auto mt-3"><label> {{'ANAMNESIS_MODAL.DATE' | translate}}</label> <span class="ps-3 fw-bold">{{session.formatDate(anamnesisTabs[activeTab].creationDate )}}</span></div>
		</div>
		}

		<div class="row anamnesi-modal__body position-relative">
			<!-- loader -->
			<loader [activateSpinner]="loading"></loader>
			<div class="col-12 d-flex flex-column justify-content-between" style="overflow: hidden; min-height: 50vh">
				<div [ngbNavOutlet]="nav" class="pt-3" *ngIf="!loading"></div>

				<div class="d-flex justify-content-between mt-4" *ngIf="!loading">
					<button type="button" [disabled]="isInFirstStep" (click)="goToPreviousStep()" class="btn btn-primary w-auto">{{ 'BUTTONS.BACK' | translate }}</button>
					<button type="button" *ngIf="!isInLastStep" (click)="goToNextStep()" class="btn btn-primary w-auto">{{ 'BUTTONS.NEXT' | translate }}</button>
					<button type="button" *ngIf="isInLastStep && currentAction != 'view' " class="btn btn-primary" [disabled]="!anamnesisTabs[activeTab].answered" (click)="saveAnamnesis()">{{ 'BUTTONS.SAVE' | translate }}</button>
				</div>
			</div>
		</div>
	</div>
</div>
