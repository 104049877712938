export enum docType {
	RELEASE = 'release_notes',
	MANUAL = 'manual',
	FAQ = 'FAQ',
}

export class Documents {
	id: number
	doc_type: docType | string
	target: string
	url: string
	version: string
	device: string
	brand: string
	valid_from: string

	constructor(rawObj?) {
		this.id = 0
		this.doc_type = ''
		this.target = ''
		this.url = ''
		this.version = ''
		this.device = ''
		this.brand = ''
		this.valid_from = ''

		if (rawObj) {
			// copia tutti i campi con lo stesso nome
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}
