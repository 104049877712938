export class Pms {
	name: string
	id: number
	public_key: string
	country: string
	connection_date: Date
	url: string
	loading: boolean

	constructor(rawObj?) {
		this.name = ''
		this.id = 0
		this.public_key = ''
		this.country = ''
		this.connection_date = null
		this.url = ''
		this.loading = false

		if (rawObj) {
			let myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
		if (rawObj.connection_date) {
			this.connection_date = new Date(rawObj.connection_date)
		}

		this.url = '/assets/images/' + this.name.toLowerCase() + '.png'
	}
}
