<div class="first-login">
	<!-- <button type="button" class="btn" (click)="success=!success">sucess</button>
	<button type="button" class="btn" (click)="isLoading=!isLoading">loading</button> test buttons -->
	<div class="container" [hidden]="session.isLogged() && session.isLogging()">
		<div class="row justify-content-center">
			<!-- <div class="first-log-container"> -->
			<div class="col-8 first-log-container py-4">
				<div class="row">
					<div class="col-12 header tab-container">
						<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav justify-content-around" [destroyOnHide]="false" (activeIdChange)="onNavChange($event)">
							<!--  STEP 1 -->
							<li [ngbNavItem]="tabs[0].id" class="nav-item me-3 position-relative" [ngClass]="{'isdone': tabs[0].isDone ,'active': tabs[0].id === activeTab}" [disabled]="true" #tab1>
								<a ngbNavLink uppercase class="nav-link fw-bold">{{tabs[0].id + 1}}</a>
								<span class="pils-title" [ngClass]="{'active': tabs[0].id === activeTab}">{{tabs[0].pilsTitle}}</span>
								<ng-template ngbNavContent>
									<div class="container-fluid body" [ngClass]="isLoading ? '' : 'show' ">
										<form name="firstLoginForm" class="row d-flex justify-content-between panel-body__container-form" [formGroup]="firstLoginForm" (ngSubmit)="submit()">
											<!--  -->
											<div class="col-6">
												<div class="form-group position-relative password1-field mb-5">
													<fa-icon [icon]="showPwd1 ? faEyeOpen : faEyeClosed" (click)="showPwd1 = !showPwd1" aria-hidden="true" class="show-psw-icon"></fa-icon>
													<label for="password">{{ 'FIRST_LOGIN.PASSWORD' | translate }}</label>
													<input [type]="showPwd1 ? 'text' : 'password'" class="form-control" name="password" id="password" placeholder="{{ 'FIRST_LOGIN.PASSWORD_HINT' | translate }}" (ngModelChange)="onChangeNewPwd($event)" formControlName="password1" />
													<fa-icon [ngClass]="firstLoginForm.get('password1').valid ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
													<!-- <p class="error-message mt-2" [hidden]="validPwd">{{ 'PROFILE.INVALID_PASSWORD' | translate }}</p> -->
													<p class="error-msg" *ngIf="firstLoginForm.get('password1').invalid">
														<span [ngClass]="firstLoginForm.get('password1').errors['Invalid blank space'] ? 'show' : '' ">{{ 'FIRST_LOGIN.VALIDATOR_BLANK_CHAR' | translate }}</span>
													</p>
												</div>

												<div class="form-group my-4 password2-field position-relative">
													<fa-icon [icon]="showPwd2 ? faEyeOpen : faEyeClosed" (click)="showPwd2 = !showPwd2" aria-hidden="true" class="show-psw-icon"></fa-icon>
													<label for="password">{{ 'FIRST_LOGIN.PASSWORD_CONFIRM' | translate }}</label>
													<input [type]="showPwd2 ? 'text' : 'password'" class="form-control" name="passwordConfirm" id="passwordConfirm" placeholder="{{ 'FIRST_LOGIN.PASSWORD_CONFIRM_HINT' | translate }}" formControlName="password2" />
													<fa-icon [ngClass]="firstLoginForm.get('password2').valid ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
													<p class="error-msg">
														<span [ngClass]="firstLoginForm.get('password2').invalid ? 'show' : ''">{{ ('FIRST_LOGIN.PWD_ERR_NOT_MATCH' | translate )}}</span>
													</p>
												</div>
											</div>

											<div class="col-4 form-group">
												<p class="fw-bold">{{ 'FIRST_LOGIN.VALIDATOR_TITLE' | translate }}</p>
												<div class="mandatory-points">
													<div class="position-relative" [ngClass]="charNum ?  'valid': 'invalid' ">
														<p>
															<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
															<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_CHAR' | translate }}</span>
														</p>
													</div>
													<div class="position-relative" [ngClass]="uppercase ?  'valid': 'invalid' ">
														<p>
															<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
															<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_UPP' | translate }}</span>
														</p>
													</div>
													<div class="position-relative" [ngClass]="specialChar ?  'valid': 'invalid' ">
														<p>
															<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
															<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_SPEC' | translate }}</span>
														</p>
													</div>
												</div>
											</div>

											<div class="text-center" *ngIf="!isLoading">
												<button type="submit" class="btn btn-primary mt-5" [disabled]="firstLoginForm.invalid">{{ 'BUTTONS.CONTINUE' | translate }}</button>
											</div>
										</form>
									</div>
								</ng-template>
							</li>

							<!--  STEP 2 -->
							<li [ngbNavItem]="tabs[1].id" class="nav-item me-3 position-relative" [ngClass]="{'isdone': tabs[1].isDone ,'active': tabs[1].id === activeTab}" [disabled]="true" #tab2>
								<a ngbNavLink uppercase class="nav-link fw-bold">{{tabs[1].id + 1}}</a>
								<span class="pils-title" [ngClass]="{'active': tabs[1].id === activeTab}">{{tabs[1].pilsTitle}}</span>

								<div class="line" #line1 [ngClass]="{'isdone ': tabs[0].isDone}"></div>

								<ng-template ngbNavContent>
									<!-- <p class="me-3">{{ puk }}</p> -->
									<div class="mt-4 body d-flex flex-column align-items-center justify-content-around" [ngClass]="isLoading ? '' : 'show' ">
										<div class="form-group position-relative w-50 text-center">
											<label for="puk" class="form-label">{{'FIRST_LOGIN.YOUR_PUK' | translate}}</label>
											<input type="text" class="form-control text-center" name="puk" id="puk" [value]="puk" disabled />
										</div>

										<div class="puk-warinig-text position-relative w-75">
											<fa-icon [icon]="faTriangleExclamation" faTriangleExclamation aria-hidden="true"></fa-icon>
											<p class="fw-bold text-center">{{ 'FIRST_LOGIN.PUK_WARNING' | translate }}</p>
										</div>

										<p class="puk-text" [ngClass]="[pukcopied.state? 'show' : '', pukcopied.result ? 'success' : 'fail' ]">{{pukcopied.message}}</p>

										<button class="btn btn-primary" *ngIf="!pukcopied.state" (click)="copyToClipboard()"><fa-icon [icon]="faCopy" faCopy aria-hidden="true" class="me-2"></fa-icon>{{ 'BUTTONS.COPY' | translate }}</button>

										<button class="btn btn-primary" *ngIf="pukcopied.state && !pukPrinted" (click)="printPuk(puk)"><fa-icon [icon]="faDownload" aria-hidden="true" class="me-2"></fa-icon>{{ 'BUTTONS.DOWNLOAD' | translate }}</button>

										<button class="btn btn-primary" *ngIf="pukPrinted" (click)="next()">{{ 'BUTTONS.CONTINUE' | translate }}</button>
									</div>
								</ng-template>
							</li>

							<!--  STEP 3 -->
							<li [ngbNavItem]="tabs[2].id" class="nav-item me-3 position-relative" [ngClass]="{'isdone': tabs[2].isDone ,'active': tabs[2].id === activeTab}" [disabled]="true" #tab3>
								<a ngbNavLink uppercase class="nav-link fw-bold">{{tabs[2].id + 1}}</a>
								<span class="pils-title" [ngClass]="{'active': tabs[2].id === activeTab}">{{tabs[2].pilsTitle}}</span>

								<div class="line" #line2 [ngClass]="{'isdone ': tabs[1].isDone}"></div>

								<ng-template ngbNavContent>
									<!-- <p [hidden]="success || fail">{{ 'VERIFY_PUK.DESCRIPTION' | translate }}</p>
									<p [hidden]="success || fail">{{ 'VERIFY_PUK.DESCRIPTION_LINE2' | translate }}</p>-->

									<form name="verifyPukForm" *ngIf="!pukVerified.state" #verifyPukForm="ngForm" class="body d-flex flex-column align-items-center justify-content-around" [ngClass]="isLoading ? '' : 'show' ">
										<p class="generate-new-puk fail" *ngIf="isDoctorPukTime" (click)="generateNewPuk()">{{ 'FIRST_LOGIN.MISSED_PUK' | translate }}</p>

										<div class="form-group w-50 text-center">
											<label for="insertedPUK">{{ 'VERIFY_PUK.PUK' | translate }}</label>
											<input type="text" maxlength="20" class="form-control normalcase text-center" name="insertedPUK" id="insertedPUK" placeholder="{{ 'VERIFY_PUK.PUK_HINT' | translate }}" [(ngModel)]="insertedPUK" required />
										</div>

										<p class="puk-text" [ngClass]="[pukPasted.state? 'show' : '', pukPasted.result ? 'success' : 'fail' ]">{{pukPasted.message}}</p>

										<button class="btn btn-primary" *ngIf="!pukPasted.state" (click)="pasteToClipboard()"><fa-icon [icon]="faPaste" faPaste aria-hidden="true" class="me-2"></fa-icon>{{ 'BUTTONS.PASTE' | translate }}</button>

										<button type="submit" *ngIf="pukPasted.state" (click)="submitPUK()" class="btn btn-primary mt-4" [disabled]="verifyPukForm.invalid">{{ 'BUTTONS.SUBMIT' | translate }}</button>
									</form>

									<div class="row" *ngIf="pukVerified.state && pukVerified.result">
										<div class="col-12 text-center success-container position-relative">
											<h3 class="fw-bold">{{ 'VERIFY_PUK.DESCRIPTION_SUCCESS' | translate }}</h3>

											<fa-icon style="color: #28b32e; font-size: 100px" [ngClass]="showIcon ? 'show' : ''" [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
											<div>
												<!-- <p>{{ insertedPUK }}</p> -->
												<button type="button" class="btn btn-primary" (click)="goLanding()" [disabled]="!showIcon">{{ 'BUTTONS.PROCEED' | translate }}</button>
											</div>
										</div>
									</div>

									<div class="row" *ngIf="pukVerified.state && !pukVerified.result">
										<div class="col-12">
											<p class="puk-text text-center show fail">{{pukVerified.message}}</p>
										</div>
									</div>
								</ng-template>
							</li>
						</ul>
					</div>
				</div>

				<div class="row mt-5">
					<div class="col-12 position-relative">
						<loader [activateSpinner]="isLoading"></loader>

						<div class="text-center" *ngIf="!isLoading && !pukVerified.state">
							<h3 class="panel-title uppercase fw-bold">{{ tabs[activeTab].title }}</h3>
							<p>{{ tabs[activeTab].subtitle }}</p>
						</div>

						<p class="text-center fw-bold mt-5" *ngIf="isLoading">{{pleaseWait}}</p>

						<div [ngbNavOutlet]="nav" class="pt-3"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
