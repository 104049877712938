<div class="release-notes-container position-relative">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="activeModal.dismiss()"></button>
	</div>

	<loader [activateSpinner]="loading"></loader>

	<iframe *ngIf="releaseNotesUrl" sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-presentation" allow-same-origin [src]="releaseNotesUrl"></iframe>

	<p *ngIf="!releaseNotesUrl && !loading">{{'RELEASE_NOTES.NO_RELEASE_NOTES' | translate}}</p>
</div>
