import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SessionService } from '../../service/session.service'

import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'
import { Country } from '../../models/countries.models'

import { NgbModal, NgbActiveModal, NgbNav, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { FormControl, FormGroup, FormsModule, NgForm, Validators } from '@angular/forms'

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faInfoCircle, faTriangleExclamation, faXmark, faCheck, faDownload } from '@fortawesome/free-solid-svg-icons'

import { Config } from '../../../config'

import { Patient, TabsPatientModal } from '../../models/patient.model'
import { Address } from '../../models/address.model'
import { Util } from '../../models/util.model'
import { User } from 'src/app/models/user.model'
import { ActivatedRoute, Router } from '@angular/router'

import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance()

import * as _moment from 'moment'
const moment = _moment

var providers = [NgbModal, FormsModule]

@Component({
	selector: 'patient-modal',
	templateUrl: './patient.modal.html',
	styleUrls: ['./patient.modal.scss'],
	providers,
})
export class PatientModalContent implements OnInit {
	@Input() currentAction: string
	@Input() currPatient: Patient
	@ViewChild('nav') tabset: NgbNav

	patientForm: FormGroup
	formPristine: boolean

	birthDate: moment.Moment
	validBirthDate: boolean
	countries: Country[]

	today: Date
	dateFormat: string
	minDate: Date
	maxDate: Date

	phoneNumber: string
	countryIso2: string

	disableButton: boolean

	loading: boolean

	patList: Patient[] // 25.02.2022 sul directive, qui serve x controllo duplicati

	userSex = [
		{ code: 1, descr: 'SEX.1' },
		{ code: 2, descr: 'SEX.2' },
		{ code: 3, descr: 'SEX.3' }, // OTHER  12.11.2019
	]

	userRace = [
		{ code: 0, descr: 'RACE.0' },
		{ code: 1, descr: 'RACE.1' },
		{ code: 2, descr: 'RACE.2' },
		{ code: 3, descr: 'RACE.3' },
		{ code: 4, descr: 'RACE.4' },
		{ code: 5, descr: 'RACE.5' },
		{ code: 6, descr: 'RACE.6' },
	]

	telerefEnabled: boolean // visualizza il flag
	flagDone: boolean
	teleRefrUrl: string // 21.09.2022
	syncrRefrSent: boolean
	telerefractMsg: string // 28.11.2022
	postTelerefractError: boolean

	isCreate: boolean // mi serve per capire se disabilitare o meno il click sulle tab, solo se create
	countryEditable: boolean

	currUser: User
	userCountryObj: Country

	currentModal: NgbModalRef

	prefix: string

	vaComplete: boolean

	//icons
	faCalendar = faCalendarAlt
	faInfoCircle = faInfoCircle
	faTriangleExclamation = faTriangleExclamation
	faXmark = faXmark
	faCheck = faCheck
	faDownload = faDownload

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		private translator: TranslateService,
		public modalService: NgbModal,
		public routing: Router
	) {
		this.initPatientForm()
		this.validBirthDate = true

		var lang = this.session.getLanguage()
		// this.dateOptions.language = lang

		this.dateFormat = moment.localeData(lang).longDateFormat('L')

		let currDay = new Date()

		this.minDate = new Date(currDay.getFullYear() - 120, 1, 1)
		this.maxDate = new Date(currDay.getFullYear(), currDay.getMonth(), currDay.getDate())

		this.phoneNumber = ''
		this.countryIso2 = ''

		this.countries = []

		this.patList = []

		this.flagDone = false

		this.formPristine = true
		this.loading = false
		this.disableButton = false

		this.currUser = this.session.user

		this.telerefEnabled = false
		// this.telerefractSyncr = false
		this.telerefractMsg = ''
		this.postTelerefractError = false

		this.today = new Date()

		this.isCreate = true
		this.countryEditable = true
	}

	ngOnInit(): void {
		Util.debug('(ngOninit - patientModal)')

		// console.log(this.currPatient)

		this.isCreate = this.currentAction == Config.ACT_CREATE

		this.telerefEnabled = this.session.isTelerefractEnabled()
		console.log('telerefract enabled: ' + this.telerefEnabled)

		//this.teleRefrUrl = 'https://tele-viz.com:8000/PatientRegistration/AddPatientFromNexus' // 29.09.2022
		this.teleRefrUrl = Config.telerefractUrl + '/PatientRegistration/AddPatientFromNexus' // 02.11.2022 spostata la parte fissa di url sul config

		// devo chiamarla sempre xk mi serve il countryIso2 per validare il numero
		// this.getCountry().then((countries) => {
		let userCountry = this.currUser.getCountry()
		this.countries = this.session.getDtCountries()
		this.userCountryObj = this.countries.find((el) => el.alpha3 == userCountry) // la mando al search_element e la seleziona automaticamente
		this.prefix = this.userCountryObj.dial_code
		this.countryIso2 = this.userCountryObj.alpha2

		// })

		if (this.isCreate) {
			Util.debug('(patientModal) - new patient')

			this.currPatient.addresses[0] = new Address()

			this.patientForm.get('telerefractSyncr').setValue(this.telerefEnabled)

			this.syncrRefrSent = false

			// if (this.anamnesisGroup != 0) {
			// 	this.getAnamnesis() // basato sul gruppo dell'optician owner
			// }
		} else {
			Util.debug('(patientModal) - edit patient')

			this.birthDate = moment(this.currPatient.birthDate, 'YYYY-MM-DD')
			// console.log(this.birthDate)
			this.patientForm.get('birthDate').setValue(this.birthDate)

			// let country = this.countries.find((el) => el.alpha3 == this.currPatient.addresses[0].country)

			// this.searchCountry.select(country) //seleziono la country nel searchdropdown

			if (this.currPatient.addresses[0]) {
				if (this.currPatient.addresses[0].phone1) {
					let number = this.currPatient.addresses[0].phone1.split(' ')

					if (number.length > 1) {
						this.patientForm.get('prefix').setValue(number[0])
						this.patientForm.get('phone').setValue(number[1])
					} else {
						this.patientForm.get('phone').setValue(number[0])
						this.patientForm.get('prefix').markAsDirty()
					}
				}

				if (this.currPatient.addresses[0].country && this.currPatient.addresses[0].country.length > 2) {
					this.patientForm.get('country').setValue(this.currPatient.addresses[0].country)
					this.patientForm.get('country').disable()
					this.countryEditable = false
				}

				this.patientForm.get('city').setValue(this.currPatient.addresses[0]?.city)
				this.patientForm.get('email').setValue(this.currPatient.addresses[0]?.ref_email)
			} else {
				this.currPatient.addresses.push(new Address())
				this.patientForm.get('country').markAsDirty()
			}
			this.patientForm.get('firstName').setValue(this.currPatient.firstName)
			this.patientForm.get('lastName').setValue(this.currPatient.lastName)
			this.patientForm.get('gender').setValue(this.currPatient.sex)
			this.patientForm.get('race').setValue(this.currPatient.race)
			this.patientForm.get('internalCode').setValue(this.currPatient.internal_code)
			this.patientForm.get('personalId').setValue(this.currPatient.personal_id)
			this.patientForm.get('dicomId').setValue(this.currPatient.dicom_id)

			this.checkBirthDate()
		}

		// console.log(this.patientForm)
	}

	private initPatientForm() {
		this.patientForm = new FormGroup({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			birthDate: new FormControl('', [Validators.required, this.dateValidator.bind(this)]),
			gender: new FormControl(null, Validators.required),
			race: new FormControl(0, Validators.required), //settato valore default -
			country: new FormControl('', Validators.required),
			city: new FormControl(''),
			internalCode: new FormControl(''),
			phone: new FormControl('', [Validators.nullValidator, this.validatorOtpPhone.bind(this)]),
			prefix: new FormControl('', [Validators.nullValidator, this.validatorPrerfix.bind(this)]),
			email: new FormControl('', [Validators.nullValidator, this.emailValidator.bind(this)]),
			personalId: new FormControl(''),
			dicomId: new FormControl(''),
			telerefractSyncr: new FormControl(false),
		})

		this.patientForm.get('birthDate').markAsTouched()
	}

	private dateValidator(control: FormControl): { [s: string]: boolean } {
		let today = moment(new Date())

		let year = moment(control.value).year()
		// let month = moment(control.value).month()

		if (year == today.year() || year.toString().length < 4) {
			return { ['invalid year']: true }
		}

		return null
	}

	private emailValidator(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			var valid = control.value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

			if (valid == null) {
				return { ['Invalid email']: true }
			}
		}
		return null
	}

	private validatorOtpPhone(control: FormControl): { [s: string]: boolean } {
		// console.log(this.countryIso2)

		if (control.value && control.value != '') {
			let formNum = control.value.toString()

			if (formNum === '' || formNum === undefined) {
				return { ['No number inserted']: true }
			}

			if (formNum.length == 1) {
				return { ['Invalid number inserted']: true }
			}

			if (this.countryIso2) {
				const number = phoneUtil.parse(formNum, this.countryIso2)

				if (phoneUtil.isValidNumberForRegion(number, this.countryIso2)) {
					return null
				} else {
					return { ['Invalid number inserted']: true }
				}
			} else {
				return null
				// return { ['No country selected']: true }
			}
		}
	}

	private validatorPrerfix(control: FormControl): { [s: string]: boolean } {
		if (this.patientForm) {
			let phonePristine = this.patientForm.get('phone').pristine

			if (control.pristine) {
				if (!phonePristine) {
					return { ['insert a valid prefix']: true }
				} else {
					return null
				}
			} else {
				let isValid = this.session.isValidPrefixCode(control.value)

				if (!isValid) {
					return { ['insert a valid prefix']: true }
				} else {
					return null
				}
			}
		}
	}

	public checkBirthDate() {
		console.log('checkBirthDate')
		if (this.birthDate == null || this.today.getFullYear() - this.birthDate.year() < 2) {
			this.validBirthDate = false
		} else {
			this.validBirthDate = true
		}
	}

	public selectDate() {
		// console.log('selectDate')
		this.birthDate = this.patientForm.get('birthDate').value
		this.checkBirthDate()
	}

	public selCountry(event: { countryIso2: string; countryIso3: string }) {
		// console.log(event)
		this.patientForm.get('country').setValue(event.countryIso3)
		this.patientForm.markAsDirty()

		this.countryIso2 = event.countryIso2

		let pref = this.session.getDialCodeFromAlpha3(event.countryIso3)

		if (pref) {
			this.patientForm.get('prefix').setValue(pref)
		}
	}

	public resetCountry(bol: boolean) {
		// console.log(bol)
		if (bol) {
			this.patientForm.get('country').setValue('')
		}
	}

	public passBack() {
		Util.debug('(PatientModal) - passback')

		// console.log(this.currPatient)

		this.disableButton = true
		this.loading = true

		let dob = this.birthDate.format('YYYY-MM-DD')
		let year = this.birthDate.year()

		Util.debug('(passBack) - DOB: ' + dob)

		this.currPatient.setDOB(dob, year)

		this.currPatient.firstName = this.patientForm.get('firstName').value
		this.currPatient.lastName = this.patientForm.get('lastName').value
		this.currPatient.sex = this.patientForm.get('gender').value
		this.currPatient.race = this.patientForm.get('race').value
		this.currPatient.addresses[0].country = this.patientForm.get('country').value
		this.currPatient.addresses[0].city = this.patientForm.get('city').value
		this.currPatient.addresses[0].phone1 = this.patientForm.get('prefix').value + ' ' + this.patientForm.get('phone').value
		this.currPatient.internal_code = this.patientForm.get('internalCode').value
		this.currPatient.addresses[0].ref_email = this.patientForm.get('email').value
		this.currPatient.personal_id = this.patientForm.get('personalId').value
		this.currPatient.dicom_id = this.patientForm.get('dicomId').value

		if (this.currentAction == Config.ACT_CREATE) {
			// console.log(this.mainAddr)

			this.validateNewPatient().then((resp) => {
				// console.log(resp)
				let proceed = resp

				if (!proceed) {
					Util.debug('(passBack) do not close the modal, stay here...')
					this.disableButton = false
					this.loading = false
					return
				}
				// only create the patient, but don't save anamnesis and agreement yet
				Util.debug('(patForm) going to create new patient')
				this.session.createPatient(this.currPatient).then((resp) => {
					// console.log(resp)
					this.disableButton = false

					if (this.patientForm.get('telerefractSyncr').value) {
						// show form to upload on telerefract platform, in PROD will be done automatically
						Util.debug('(patForm) new pat done, resp: ')
						//console.log(resp);
						let nexusId = 0 // estrarre dalla resp
						if (resp && resp.patient_id) {
							nexusId = parseInt(resp.patient_id)
							if (nexusId > 0) {
								//let msg = "Ok create new patient, id: "+nexusId;
								//alert(msg);
								// this.parent.reloadList() // 22.09.2022 for next check of duplicated pats // 02-03-23 changed from forceListReload to reloadList
								this.currPatient.id = nexusId
								this.syncrRefrSent = false // abilita bottone

								// se sono prod e staging fa automaticamente il post telerefract
								if (this.session.isProduction() || this.session.isStaging()) {
									this.postTelerefractError = false
									this.postTelerefract()
										.then(() => {
											console.log('(patForm) - postTelerefract ok')
											// this.next()
											this.flagDone = true
											this.loading = false
										})
										.catch((err) => {
											console.log(err)
											this.postTelerefractError = true
											this.flagDone = true
											this.loading = false
										})
								} else {
									this.flagDone = true
									this.loading = false
								}
								// this.patientForm.reset()
								// this.initPatientForm()
								return
							} else {
								this.flagDone = false // qualcosa ko...
							}
						}
						return
					} else {
						this.activeModal.close(resp.patient_id)

						this.flagDone = true
						this.loading = false
						return
					}
				})
			})
		} else {
			Util.debug('(PatientModal) - edit patient')

			this.checkForm()

			//rimuovo eventuali query parameters se apro edit patient from RDS
			this.routing.navigate([])

			if (!this.formPristine) {
				this.session
					.updatePatient(this.currPatient)
					.then((resp) => {
						// console.log(resp)
						this.disableButton = false
						this.activeModal.close(this.currPatient.id)
					})
					.catch((err) => {
						console.log(err)
					})
			} else {
				this.activeModal.close(this.currPatient.id)
			}
		}
	}
	// 24.02.2022
	public validateNewPatient(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			Util.debug('(validateNewPatient) sex: ' + this.currPatient.sex)

			this.getDuplicated().then((num) => {
				let oldPat = num // oldPat > 0 duplicato
				if (oldPat > 0) {
					let msg = this.translator.instant('PATIENT.MESSAGE_EXISTING_PATIENT')

					let confirmModal = this.openConfirmModal(msg)
					confirmModal.result
						.then((resp) => {
							if (resp) {
								this.activeModal.dismiss('goto:' + oldPat) // close modal
								resolve(false)
							}
						})
						.catch((err) => {
							Util.debug('(validateNewPatient) stay here...')
							resolve(false)
						})
				} else {
					resolve(true) // no duplicato
				}
			})
		})

		return promise
	}

	// 25.02.2022 returns the id of a duplicated patient or 0 if not found.
	private getDuplicated(): Promise<number> {
		const promise = new Promise<number>((resolve, reject) => {
			let myPat = this.currPatient
			let existingOne = 0

			// 11.03.2022 se arrivo qui prima di aver valorizzato nome, cognome e dob, esco
			if (this.birthDate == null || myPat.firstName == null || myPat.lastName == null) {
				resolve(existingOne)
			}

			this.session.getDtPatientList().then((patients) => {
				this.session.getDtPatientListUnsubscribe()
				// console.log(patients)
				this.patList = patients

				// 25.02.2022 fixed DOB format among all devices
				let dob = this.birthDate.format('YYYY-MM-DD')
				let year = this.birthDate.year()

				myPat.setDOB(dob, year)

				let dobNew = myPat.birthDate

				Util.debug('(getDuplicatedPat) new pat DOB:' + dobNew + ' tot pats: ' + this.patList.length)

				for (let i = 0; i < this.patList.length; i++) {
					let pat = this.patList[i]

					if (pat.id != myPat.id) {
						// avoid himself during edit
						// cfr tra date, mi fermo al day, escludo orario

						let dobOld = pat.birthDate // e' gia' troncato
						//console.log("isDuplicatedPat) id: "+pat.id+" DOB: "+dobOld);

						if (Util.equalsIgnoreCase(dobNew, dobOld)) {
							// same DOB
							// test name, surname, sex
							if (Util.equalsIgnoreCase(pat.firstName, myPat.firstName) && Util.equalsIgnoreCase(pat.lastName, myPat.lastName) && pat.sex == myPat.sex) {
								console.log('(isDuplicatedPat) found patient ' + pat.id + ' sex: ' + pat.sex)
								existingOne = pat.id // parseInt(pat.id);  16.09.2022
								break
							}
						}
					}
				}

				console.log('(isDuplicatedPat) ' + existingOne)

				resolve(existingOne)
			})
		})

		return promise

		//}
	}

	// 21.09.2022
	public postTelerefract(): Promise<any> {
		// prende la url dal form
		// invia i campi paziente + i campi user
		const promise = new Promise<any>((resolve, reject) => {
			if (this.teleRefrUrl == '') {
				alert('Please fill the callback url.')
				reject()
			}

			this.syncrRefrSent = true // to disable button avoid post twice

			let currUsr = this.session.getCurrentUser()

			let myForm = new FormData() // as form data
			myForm.append('patient_name', this.currPatient.firstName)
			myForm.append('patient_surname', this.currPatient.lastName)
			myForm.append('patient_id', '' + this.currPatient.id)

			myForm.append('user_id', '' + currUsr.user_id)
			myForm.append('username', currUsr.username)
			myForm.append('user_role', currUsr.role)
			myForm.append('organization', currUsr.getOrganization()) // 07.10.2022
			myForm.append('token', currUsr.token.substring(7))

			//this.doFormPost(this.teleRefrUrl, elements); // crea un post con un form fittizio

			this.session.myExternalPost(this.teleRefrUrl, myForm).subscribe({
				next: (value) => {
					// Success
					console.log('(myTelerefrPost) ok syncr ')
					if (this.session.isStaging()) {
						this.telerefractMsg = 'ok syncr with teleRefract platform' // solo per test in STG
					}
					resolve(value)
				},
				error: (err) => {
					console.log(err)
					let status = err.status
					let msg = this.session.parseErrorMessage(err, 'alert')
					console.log('ko syncr with teleRefract - status: ' + status + ' msg: ' + msg)
					if (!this.session.isProduction() && !this.session.isStaging()) {
						alert('ko syncr with teleRefract - status: ' + status + ' msg: ' + msg)
					}
					reject(err)
				},
			})
		}).finally(() => this.activeModal.close(this.currPatient.id))

		return promise
	}

	private openConfirmModal(text: string): NgbModalRef {
		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		return this.currentModal
	}

	// 	obj.forEach((obj) => {
	// 		if (this.currPatient.va[obj] != '' && this.currPatient.va[obj] != null) {
	// 			resp = true
	// 		}
	// 	})
	// 	// console.log(resp)
	// 	return resp
	// }

	private checkForm() {
		if (this.patientForm) {
			// console.log(this.form.pristine)
			this.formPristine = this.patientForm.pristine
		}
	}

	private dismiss() {
		Util.debug('(PatientModal) - closeModal')
		this.routing.navigate([])
		this.activeModal.dismiss()
	}

	public closeModal() {
		this.checkForm()

		if (this.patientForm.valid) {
			let msg = this.translator.instant('CONFIRM.CONFIRM_TEXT')

			let confirmModal = this.openConfirmModal(msg)
			confirmModal.result
				.then(() => {
					this.dismiss()
				})
				.catch((err) => {
					Util.debug('dismissed')
				})
		} else {
			this.dismiss()
		}
	}
}
