<div class="full-container catInfo">
	<div class="dataWrapper" *ngIf="!hasMiddleInfo">
		<label>{{ 'MISC.NO_DATA' | translate }}</label>
	</div>

	<div class="dataWrapper" *ngIf="hasMiddleInfo">
		<!-- modalita' Combi, con SBJ, scenario 2 e 3 -->
		<div class="tblWrapper" *ngIf="isCombinedData">
			<table class="catInfo refraction z-depth-4" *ngIf="rightEyeExams?.ls || rightEyeExams?.wf || rightEyeExams?.sb">
				<thead>
					<tr>
						<th>{{ 'MISC.RIGHT' | translate }}</th>

						<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
						<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
						<th>{{ 'REFRACTION.AXIS' | translate }}</th>
						<th>{{ 'REFRACTION.PX' | translate }}</th>
						<!-- <th>{{"REFRACTION.BASE_H" | translate}}</th> -->
						<th>{{ 'REFRACTION.PY' | translate }}</th>
						<!-- <th>{{"REFRACTION.BASE_V" | translate}}</th> -->
						<th>{{ 'REFRACTION.ADD' | translate }}</th>
					</tr>
				</thead>
				<tr *ngIf="rightEyeExams?.ls">
					<td>{{ 'EXAMS.LSM' | translate }}</td>
					<td>{{ rightEyeExams.ls_sphere }}</td>
					<td>{{ rightEyeExams.ls_cylinder}}</td>
					<td>{{ rightEyeExams.ls_axis }}</td>
					<td>{{ rightEyeExams.ls_prism_h }}</td>
					<td>{{ rightEyeExams.ls_prism_v}}</td>
					<td>{{ rightEyeExams.ls_add }}</td>
				</tr>
				<tr *ngIf="rightEyeExams?.wf">
					<td>{{ rightEyeExams.wf_Label }}</td>
					<!-- {{"EXAMS.WF" | translate}} -->
					<td>{{ rightEyeExams.wf_sphere_day }}</td>
					<td>{{ rightEyeExams.wf_cylinder_day }}</td>
					<td>{{ rightEyeExams.wf_axis_day }}</td>

					<!-- <td colspan='5'>&nbsp;</td>  -->
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<!-- <td>&nbsp;</td>
          <td>&nbsp;</td> -->
				</tr>
				<tr *ngIf="rightEyeExams?.sb">
					<td>{{ 'EXAMS.RX' | translate }}</td>
					<td>{{ rightEyeExams.sb_sphere }}</td>
					<td>{{ rightEyeExams.sb_cylinder }}</td>
					<td>{{ rightEyeExams.sb_axis }}</td>
					<td>{{ rightEyeExams.sb_prism_h }}</td>
					<!-- <td [ngClass]="sbjR.base_h ?   'value' : 'novalue'">{{sbjR.base_h}}</td>  -->
					<td>{{ rightEyeExams.sb_prism_v }}</td>
					<!-- <td [ngClass]="sbjR.base_v ?   'value' : 'novalue'">{{sbjR.base_v}}</td> -->
					<td>{{ rightEyeExams.sb_add }}</td>
				</tr>
			</table>

			<div class="spacer"></div>
			<table class="catInfo refraction z-depth-4" *ngIf="leftEyeExams?.ls || leftEyeExams?.wf || leftEyeExams?.sb ">
				<thead>
					<tr>
						<th>{{ 'MISC.LEFT' | translate }}</th>

						<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
						<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
						<th>{{ 'REFRACTION.AXIS' | translate }}</th>
						<th>{{ 'REFRACTION.PX' | translate }}</th>
						<!-- <th>{{"REFRACTION.BASE_H" | translate}}</th>  -->
						<th>{{ 'REFRACTION.PY' | translate }}</th>
						<!--  <th>{{"REFRACTION.BASE_V" | translate}}</th>  -->
						<th>{{ 'REFRACTION.ADD' | translate }}</th>
					</tr>
				</thead>
				<tr *ngIf="leftEyeExams?.ls">
					<td>{{ 'EXAMS.LSM' | translate }}</td>
					<td>{{ leftEyeExams.ls_sphere }}</td>
					<td>{{ leftEyeExams.ls_cylinder}}</td>
					<td>{{ leftEyeExams.ls_axis}}</td>
					<td>{{ leftEyeExams.ls_prism_h }}</td>
					<td>{{ leftEyeExams.ls_prism_v }}</td>
					<td>{{ leftEyeExams.ls_add }}</td>
				</tr>
				<tr *ngIf="leftEyeExams?.wf">
					<td>{{ leftEyeExams.wf_Label }}</td>
					<!-- {{"EXAMS.WF" | translate}} -->
					<td>{{ leftEyeExams.wf_sphere_day }}</td>
					<td>{{ leftEyeExams.wf_cylinder_day }}</td>
					<td>{{ leftEyeExams.wf_axis_day }}</td>
					<!-- <td colspan='5'>&nbsp;</td> -->
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<!-- <td>&nbsp;</td>
          <td>&nbsp;</td>    -->
				</tr>
				<tr *ngIf="leftEyeExams?.sb">
					<td>{{ 'EXAMS.RX' | translate }}</td>
					<td>{{ leftEyeExams.sb_sphere }}</td>
					<td>{{ leftEyeExams.sb_cylinder }}</td>
					<td>{{ leftEyeExams.sb_axis }}</td>
					<td>{{ leftEyeExams.sb_prism_h }}</td>
					<td>{{ leftEyeExams.sb_prism_v }}</td>
					<td>{{ leftEyeExams.sb_add }}</td>
				</tr>
			</table>

			<div class="spacer"></div>

			<label>[{{ 'EXAM.DATE' | translate }}: {{ refractionExamsDate }}]</label>

			<div class="row nomargin">
				<div class="twothird-container">
					<table class="catInfo refraction z-depth-4">
						<thead>
							<tr>
								<th>{{ 'REFRACTION.VA' | translate }}</th>
								<th>{{ 'EXAMS.LSM' | translate }}</th>
								<th>{{ 'EXAMS.WF' | translate }}</th>
								<th>{{ 'EXAMS.RX' | translate }}</th>
								<th>{{ 'REFRACTION.NV' | translate }}</th>
								<!--<th>Unaided</th>  -->
							</tr>
						</thead>

						<tr *ngIf="rightEyeExams?.sb">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td>{{ rightEyeExams.sb_DVA_LM }}</td>
							<td>{{ rightEyeExams.sb_DVA_WF }}</td>
							<td>{{ rightEyeExams.sb_DVA_REF }}</td>
							<td>{{ rightEyeExams.sb_NVA_REF }}</td>
						</tr>
						<tr *ngIf="leftEyeExams?.sb">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td>{{ leftEyeExams.sb_DVA_LM }}</td>
							<td>{{ leftEyeExams.sb_DVA_WF }}</td>
							<td>{{ leftEyeExams.sb_DVA_REF }}</td>
							<td>{{ leftEyeExams.sb_NVA_REF }}</td>
						</tr>
						<tr *ngIf="binoEyeExams?.sb">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td>{{ binoEyeExams.sb_DVA_LM }}</td>
							<td>{{ binoEyeExams.sb_DVA_WF }}</td>
							<td>{{ binoEyeExams.sb_DVA_REF }}</td>
							<td>{{ binoEyeExams.sb_NVA_REF }}</td>
						</tr>
						<tr *ngIf="binoEyeExams?.sb">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
						</tr>
					</table>
				</div>
				<div class="third-container">
					<table class="catInfo refraction z-depth-4" *ngIf="rightEyeExams?.ls || rightEyeExams?.sb || leftEyeExams?.ls || leftEyeExams?.sb || binoEyeExams?.ls || binoEyeExams?.sb">
						<thead>
							<tr>
								<th>{{ 'REFRACTION.PD' | translate }}</th>
								<th>&nbsp;</th>
							</tr>
						</thead>

						<tr *ngIf="rightEyeExams?.ls || rightEyeExams?.sb">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td *ngIf="rightEyeExams?.ls">{{ rightEyeExams.ls_PDm }}</td>
							<td *ngIf="!rightEyeExams?.ls && rightEyeExams?.sb">{{ rightEyeExams.sb_PDm }}</td>
						</tr>
						<tr *ngIf="leftEyeExams?.ls || leftEyeExams?.sb">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td *ngIf="leftEyeExams?.ls">{{ leftEyeExams.ls_PDm }}</td>
							<td *ngIf="!leftEyeExams?.ls && leftEyeExams?.sb">{{ leftEyeExams.sb_PDm }}</td>
						</tr>
						<tr *ngIf="binoEyeExams?.ls || binoEyeExams?.sb">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td *ngIf="binoEyeExams?.ls">{{ binoEyeExams.ls_PDm }}</td>
							<td *ngIf="!binoEyeExams?.ls && binoEyeExams?.sb">{{ binoEyeExams.sb_PDm }}</td>
						</tr>
						<tr *ngIf="!binoEyeExams?.ls && !binoEyeExams?.sb">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td [ngClass]="'novalue'">&nbsp;</td>
						</tr>
					</table>
				</div>
			</div>

			<div class="spacer"></div>
		</div>

		<!-- modalita' LSM e WF singoli, scenario 1 e 4 -->
		<div class="tblWrapper" *ngIf="!isCombinedData && (rightEyeExams?.ls || leftEyeExams?.ls || rightEyeExams?.wf || leftEyeExams?.wf)">
			<div class="row nomargin">
				<div class="fivesixth-container">
					<table *ngIf="rightEyeExams?.ls|| leftEyeExams?.ls" class="catInfo refraction z-depth-4">
						<thead>
							<tr>
								<th>{{ 'EXAMS.LSM' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								<th>{{ 'REFRACTION.PX' | translate }}</th>
								<th>{{ 'REFRACTION.PY' | translate }}</th>
								<th>{{ 'REFRACTION.ADD' | translate }}</th>
								<!-- <th>{{"REFRACTION.BASE_V" | translate}}</th>  -->
								<!-- <th>{{"REFRACTION.BASE_H" | translate}}</th> -->
							</tr>
						</thead>
						<tr *ngIf="rightEyeExams?.ls">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td>{{ rightEyeExams.ls_sphere }}</td>
							<td>{{ rightEyeExams.ls_cylinder }}</td>
							<td>{{ rightEyeExams.ls_axis }}</td>
							<td>{{ rightEyeExams.ls_prism_h }}</td>
							<td>{{ rightEyeExams.ls_prism_v }}</td>
							<td>{{ rightEyeExams.ls_add }}</td>
						</tr>
						<tr *ngIf="leftEyeExams?.ls">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td>{{ leftEyeExams.ls_sphere }}</td>
							<td>{{ leftEyeExams.ls_cylinder }}</td>
							<td>{{ leftEyeExams.ls_axis }}</td>
							<td>{{ leftEyeExams.ls_prism_h }}</td>
							<td>{{ leftEyeExams.ls_prism_v }}</td>
							<td>{{ leftEyeExams.ls_add }}</td>
						</tr>
					</table>
				</div>
				<div class="sixth-right-container">
					<!-- sixth-byside-container -->
					<table *ngIf="rightEyeExams?.ls || leftEyeExams?.ls || binoEyeExams?.ls" class="catInfo refraction z-depth-4">
						<thead>
							<tr>
								<th>{{ 'REFRACTION.PD' | translate }}</th>
								<th>{{ 'EXAMS.LSM' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="rightEyeExams?.ls">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td>{{ rightEyeExams.ls_PDm }}</td>
						</tr>
						<tr *ngIf="leftEyeExams?.ls">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td>{{ leftEyeExams.ls_PDm }}</td>
						</tr>
						<tr *ngIf="binoEyeExams?.ls">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td>{{ binoEyeExams.ls_PDm }}</td>
						</tr>
					</table>
				</div>
			</div>

			<div class="spacer"></div>

			<label>[{{ 'EXAM.DATE' | translate }}: {{ refractionExamsDate }}]</label>

			<div class="row nomargin">
				<div class="col-sm-12 col-md-12 col-lg-6">
					<table class="catInfo refraction z-depth-4">
						<thead>
							<tr *ngIf="rightEyeExams?.wf">
								<th>{{ 'MISC.RIGHT' | translate }}</th>
								<th *ngIf="rightEyeExams.wf_pupil_real_size_day != ''">{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								<th *ngIf="rightEyeExams.wf_vertex_distance != ''">{{ 'REFRACTION.VERTEX' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="rightEyeExams?.wf">
							<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
							<td *ngIf="rightEyeExams.wf_pupil_real_size_day != ''">{{ rightEyeExams.wf_pupil_real_size_day }}</td>
							<td>{{ rightEyeExams.wf_pupil_size_day}}</td>
							<td>{{ rightEyeExams.wf_sphere_day }}</td>
							<td>{{ rightEyeExams.wf_cylinder_day }}</td>
							<td>{{ rightEyeExams.wf_axis_day }}</td>
							<td *ngIf="rightEyeExams.wf_vertex_distance  != ''">{{ rightEyeExams.wf_vertex_distance }}</td>
						</tr>
						<tr *ngIf="rightEyeExams?.wf">
							<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
							<td *ngIf="rightEyeExams.wf_pupil_real_size_day != ''">{{ rightEyeExams.wf_pupil_real_size_night }}</td>
							<td>{{ rightEyeExams.wf_pupil_size_night}}</td>
							<td>{{ rightEyeExams.wf_sphere_night }}</td>
							<td>{{ rightEyeExams.wf_cylinder_night }}</td>
							<td>{{ rightEyeExams.wf_axis_night }}</td>
							<td *ngIf="rightEyeExams.wf_vertex_distance != ''">{{ rightEyeExams.wf_vertex_distance }}</td>
						</tr>
					</table>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-6">
					<table class="catInfo refraction z-depth-4">
						<thead>
							<tr *ngIf="leftEyeExams?.wf">
								<th>{{ 'MISC.LEFT' | translate }}</th>
								<th *ngIf="leftEyeExams.wf_pupil_real_size_day != ''">{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								<th *ngIf="leftEyeExams.wf_vertex_distance != ''">{{ 'REFRACTION.VERTEX' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="leftEyeExams?.wf">
							<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
							<td *ngIf="leftEyeExams.wf_pupil_real_size_day != ''">{{ leftEyeExams.wf_pupil_real_size_day }}</td>
							<td>{{ leftEyeExams.wf_pupil_size_day}}</td>
							<td>{{ leftEyeExams.wf_sphere_day }}</td>
							<td>{{ leftEyeExams.wf_cylinder_day }}</td>
							<td>{{ leftEyeExams.wf_axis_day }}</td>
							<td *ngIf="leftEyeExams.wf_vertex_distance  != ''">{{ leftEyeExams.wf_vertex_distance }}</td>
						</tr>
						<tr *ngIf="leftEyeExams?.wf">
							<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
							<td *ngIf="leftEyeExams.wf_pupil_real_size_day != ''">{{ leftEyeExams.wf_pupil_real_size_night }}</td>
							<td>{{ leftEyeExams.wf_pupil_size_night}}</td>
							<td>{{ leftEyeExams.wf_sphere_night }}</td>
							<td>{{ leftEyeExams.wf_cylinder_night }}</td>
							<td>{{ leftEyeExams.wf_axis_night }}</td>
							<td *ngIf="leftEyeExams.wf_vertex_distance != ''">{{ leftEyeExams.wf_vertex_distance }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<div class="spacer"></div>

		<div class="tblWrapper" *ngIf="rightEyeExams?.tp || leftEyeExams?.tp">
			<label *ngIf="topoExamsDate != ''">[{{ 'EXAM.DATE' | translate }}: {{ topoExamsDate }}]</label>
			<table class="catInfo refraction z-depth-4" name="KERATOMETRY">
				<thead>
					<tr>
						<th>{{ 'EXAMS.KERATOMETRY' | translate }}</th>
						<th>{{ 'TOPO.K1' | translate }}</th>
						<th>{{ 'TOPO.K2' | translate }}</th>
						<th>{{ 'TOPO.CYL' | translate }}</th>
						<th>{{ 'TOPO.AVG' | translate }}</th>
					</tr>
				</thead>
				<tr *ngIf="rightEyeExams?.tp ">
					<td>{{ 'MISC.RIGHT' | translate }}</td>
					<td>{{ rightEyeExams.tp_K1 }}</td>
					<td>{{ rightEyeExams.tp_K2 }}</td>
					<td>{{ rightEyeExams.tp_CYL }}</td>
					<td>{{ rightEyeExams.tp_AVG }}</td>
				</tr>

				<tr *ngIf="leftEyeExams?.tp">
					<td>{{ 'MISC.LEFT' | translate }}</td>
					<td>{{ leftEyeExams.tp_K1 }}</td>
					<td>{{ leftEyeExams.tp_K2 }}</td>
					<td>{{ leftEyeExams.tp_CYL }}</td>
					<td>{{ leftEyeExams.tp_AVG }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>
