import { Pipe, PipeTransform } from '@angular/core'
import * as _moment from 'moment'
const moment = _moment

@Pipe({
	name: 'stringToDate',
})
export class StringToDate implements PipeTransform {
	transform(birthDate: string): Date | null {
		if (!birthDate) {
			return null
		}

		try {
			return new Date(birthDate)
		} catch {
			return null
		}
	}
}
