import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'
import { AppToastService } from 'src/app/service/toast.service'

import { FormControl, FormGroup, NgForm, NgModelGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import { ToastOptions } from 'src/app/models/toast.model'

import { editAddress, editLogo, editReport, editsInfo, User, UserDevice } from '../../models/user.model'
import { Util } from '../../models/util.model'
import { Preferences } from '../../models/settings.model'
import { Agreement, AgrStatus } from '../../models/agreement.model'

import { Report } from '../../models/report.model'

import {
	faInfoCircle,
	faTriangleExclamation,
	faCheck,
	faXmark,
	faPrint,
	faCircleXmark,
	faCircleCheck,
	faEye,
	faEyeSlash,
	faSortDown,
	faBan,
	faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import iso from 'iso-3166-1'
import { checkVAT, Country as JsvatCountry, countries as jsvatCountryList } from 'jsvat'
import { VatCheckerResponseT } from '../users/createUser.component'
import * as b64images from '../../models/b64images'
import * as forge from 'node-forge'

import { Subscription } from 'rxjs'
import { MapService } from 'src/app/service/map.service'
import { Address } from 'src/app/models/address.model'
import { docType, Documents } from 'src/app/models/documents.model'
import { ExternalDocumentComponent } from 'src/app/elements/release-notes/release-notes.modal'
import { Pms } from 'src/app/models/pms.model'

@Component({
	selector: 'profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('generalInfo') generalInfo: NgForm
	@ViewChild('reportFields') reportFields: NgForm
	@ViewChild('signature') signature: ElementRef
	@ViewChild('logo') logo: ElementRef

	displayedColumns: string[]
	displayedPatColumns: string[]
	addressMissed: string

	profileStateSubscription: Subscription
	isProfileComplete: boolean

	currentModal
	currUser: User // 07.03.2019
	currentAction: string
	activeTab: string

	attentionBodyEmail: string
	attentionBodyVat: string

	//ACCOUNT TAB
	editsInfo: editsInfo
	editAddress: editAddress
	editReport: editReport
	editLogo: editLogo
	// form: FormGroup
	countryIso2: string
	jsvatCountry: JsvatCountry
	// vatCheck
	isFillingVat: boolean
	shouldFillVat: boolean
	vatError: boolean
	internalVatCheckerError: boolean
	isValidatingVat: boolean

	fileInvalid: boolean
	logoFileInvalid: boolean
	loadSignature: boolean
	loadLogo: boolean
	signatureMandatoryText: string
	invalidSize: boolean
	logoInvalidSize: boolean

	prevLogo: string
	prevLogo_name: string

	disclaimer: string
	passwordChanging: boolean
	changePasswordForm: FormGroup
	changePwdError: string
	showPwd1: boolean
	showPwd2: boolean
	showPwdForm: boolean
	myNewPwd: string
	charNum: boolean
	uppercase: boolean
	specialChar: boolean

	showkey: boolean
	keyPhoto: string
	keydoctor: string

	addressFields: FormGroup
	isAddressValid: boolean
	isPrivate: boolean
	dropDownRoles: any[]
	showRoleInfo: boolean
	showMap: boolean

	//OPTIONS TAB
	prefs: Preferences // 10.03.2022

	// enabledSaveButton: boolean

	// MANUALS TAB
	loadingManuals: boolean
	manuals: Documents[]
	documents: Documents[]

	// DOCUMENTS TAB
	// agrStatus: AgrStatus // 27.05.2022
	myAgreement: Agreement[]
	agreementsList: {
		url: string
		label: string
		status: string
		date: Date
	}[]
	patientAgreement: {
		url: string
		label: string
		status: string
		date: Date
	}[]
	termsUrl: string
	privacyUrl: string
	enableActivityTab: boolean
	showPatientDocuments: boolean
	showManualTab: boolean

	static PDF_FORMATS = Config.PDF_FORMATS
	static DATE_FORMATS = Config.DATE_FORMATS

	//DEVICES TAB
	enableDevicesTab: boolean
	userDevices: UserDevice[]

	//ACTIVITY TAB
	ActivitiesListR: Report[]
	hasReports: boolean

	// PMS TAB
	enablePmsTab: boolean
	onePmsActive: boolean
	pmsList: Pms[]
	waitPMSresponse: boolean

	showVat: boolean
	// userPlanReady: boolean

	// calcolate date
	year: number
	month: number
	computedMessage: string
	yearsOptions: number[]

	// zipCheck
	zipPattern: string
	zipError: boolean

	faInfoCircle = faInfoCircle // 22.03.2022
	faSave = faSave // per usarla su html
	faTriangleExclamation = faTriangleExclamation
	faXmark = faXmark
	faCheck = faCheck
	faEye = faEye
	faEyeSlash = faEyeSlash
	faPrint = faPrint
	faCircleXmark = faCircleXmark
	faCircleCheck = faCircleCheck
	faSortDown = faSortDown
	faBan = faBan
	faCircleExclamation = faCircleExclamation

	constructor(
		public session: SessionService,
		public dataService: DataModelService,
		private translator: TranslateService,
		public modalService: NgbModal,
		private toastService: AppToastService,
		private mapService: MapService
	) {
		this.showkey = false
		this.keyPhoto = ''
		this.keydoctor = ''

		// serve per cambio pwd
		this.fileInvalid = true
		this.logoFileInvalid = true
		Util.debug('[profile] - constructor')
		// console.log(this.session.user)

		this.editsInfo = new editsInfo()
		this.editLogo = new editLogo()
		this.editAddress = new editAddress()
		this.editReport = new editReport()

		this.showVat = true

		this.year = null
		this.month = null
		this.computedMessage = ''
		this.yearsOptions = []

		// this.edits = {}
		this.currentAction = 'editProfile'
		this.activeTab = 'account' // default tab loaded

		this.loadSignature = false
		this.loadLogo = false
		this.signatureMandatoryText = this.translator.instant('PROFILE.SIGNATURE_WARN')
		this.invalidSize = false
		this.logoInvalidSize = false
		this.addressMissed = ''
		this.isPrivate = false

		this.initProfileData()
		// this.initPwdTab()
		this.initPrefs()

		this.disclaimer = this.translator.instant('PROFILE.PASSWORD_DISCLAIMER')
		this.changePwdError = this.translator.instant('MESSAGE.REQUIRED')
		this.myNewPwd = ''
		this.charNum = false
		this.uppercase = false
		this.specialChar = false
		this.showPwd1 = false
		this.showPwd2 = false
		this.showPwdForm = false
		this.passwordChanging = false
		this.changePasswordForm = new FormGroup({
			oldPassword: new FormControl('', [Validators.required]),
			password1: new FormControl('', [Validators.required, this.validatorPassword.bind(this), this.invalidBlankSpace.bind(this)]),
			password2: new FormControl('', [Validators.required, this.validatorConfirmPassword.bind(this)]),
		})

		this.changePasswordForm.get('password2').disable()

		this.dropDownRoles = [
			{ description: this.translator.instant('PROFILE.ADDRESS_ROLE_0'), role: Config.CLINIC },
			{ description: this.translator.instant('PROFILE.ADDRESS_ROLE_1'), role: Config.LEGAL },
		]
		this.initAddrerssForm()
		this.showRoleInfo = false

		if (this.session.addressLocationEnabled()) {
			this.showMap = true
		} else {
			this.showMap = false
		}

		// this.initCreditsTab()
		// this.balanceRecords = []
		let country = this.addressFields.get('country').value
		if (country) {
			this.countryIso2 = iso.whereAlpha3(country).alpha2
		} else {
			this.countryIso2 = ''
		}
		this.jsvatCountry = jsvatCountryList.find((jsvatCountry) => {
			return jsvatCountry.codes[1] === country
		})
		// console.log(country)
		this.showVat = this.session.isEcommerceEnabled()

		this.ActivitiesListR = []
		this.hasReports = false

		// this.userPlanReady = false

		// 27.05.2022
		this.termsUrl = ''
		this.privacyUrl = ''
		this.myAgreement = []
		this.agreementsList = []
		this.patientAgreement = []
		// this.agrStatus = this.session.getUserAgreementStatus()

		// era attivo per session.isSpecialist() || session.hasEmail() || session.hasAllReportInfo()
		this.enableActivityTab = (this.session.isSpecialist() && this.session.isProfileComplete()) || this.session.isClinicAdmin()
		this.showPatientDocuments = this.session.isOptician() && this.session.getUserBrand() !== Config.BR_RDS
		this.showManualTab = this.session.getUserBrand() !== Config.BR_RDS

		this.userDevices = this.currUser.getDevices()
		this.enableDevicesTab = this.userDevices.length > 0
		this.toastService.checkDevicesGMT()

		if (this.session.isOptician() && !this.session.isMini()) {
			this.attentionBodyEmail = this.translator.instant('PROFILE.MANDATORY_MAIL_OPT')
		} else {
			this.attentionBodyEmail = this.translator.instant('PROFILE.MANDATORY_MAIL')
		}

		this.attentionBodyVat = this.translator.instant('PROFILE.MANDATORY_VAT')

		//this.validPwd = false; // all'inizio tutto vuoto, ma cosi' compaiono le indicazioni

		// check
		this.zipError = true
		this.zipPattern = ''
		this.shouldFillVat = false
		this.isFillingVat = false
		this.vatError = false
		this.internalVatCheckerError = false
		this.isValidatingVat = false

		this.isProfileComplete = true

		this.manuals = []
		this.documents = []
		this.loadingManuals = false

		this.enablePmsTab = false
		this.onePmsActive = false
		this.pmsList = []
		this.waitPMSresponse = false
	}

	ngOnInit(): void {
		Util.debug('[profile] - ngOnInit')

		if (this.session.isMissingMandatoryVat()) {
			this.shouldFillVat = true
		}

		if (!this.session.isAdmin()) {
			this.initAgreement()
		}
		if (this.session.isOptician()) {
			this.initPatientAgreement()
			this.initPMSTab()
		}
		this.initColumns()

		this.session.setProfileRedirectAuxFunc(this.setAccountTabActive.bind(this))
		//this.tooltipMsg = "This value will appear in the pdf reports"; // TODO, su json lingue

		this.isProfileComplete = this.session.checkProfileComplete.value
		// console.log(this.isProfileComplete)

		this.profileStateSubscription = this.session.checkProfileComplete.subscribe((val: boolean) => {
			Util.debug('Profile updated' + val)

			this.initProfileData()

			this.isProfileComplete = val

			if (val) {
				if (this.session.isFirstLogin()) {
					this.session.endFirstLogin()

					// setTimeout(() => {
					// 	this.goLanding() // 12-05-2023
					// }, 2000)
				}

				// check if push notifications should be enabled
				// if (sessionStorage.getItem('pushNotificationFlag') !== 'enabled') {
				// 	this.signalR.manageConnection()
				// }
			}
		})
	}

	ngAfterViewInit(): void {
		// set email mandatory or not
		if (this.session.isLevel1() || this.session.isLevel2() || this.session.isClinicAdmin() || this.session.isSupport()) {
			// TO DO modo migliore, se non metto un timeout i form sono undefined

			setTimeout(() => {
				this.generalInfo.form.get('email').setValidators([Validators.required, this.emailValidator.bind(this)]) // Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')
				this.generalInfo.form.get('email').updateValueAndValidity()
			}, 1000)
		}

		if (this.shouldFillVat) {
			const y = document.getElementById('vat').getBoundingClientRect().top + window.scrollY
			window.scroll({
				top: y,
				behavior: 'smooth',
			})
		}
	}

	// we have to use this to update the current position in the tab we've just changed
	public onNavChange(event) {
		// console.log(event)

		if (this.session.isOptician()) {
			this.initPatientAgreement()
		}
		// event.activeId: tab di provenienza
		var nextTab = event.nextId // tab di destinazione
		Util.debug('C (onNavChange) event, next: ' + nextTab)

		this.activeTab = nextTab

		if (nextTab == 'account') {
			this.currentAction = 'editProfile'
			this.initProfileData()
		} else if (nextTab == 'options') {
			this.currentAction = 'setOptions'
		} else if (nextTab == 'contracts') {
			this.currentAction = 'contracts'
			// solo la prima volta, quando cambio tab, c'e' gia'

			if (this.session.isLevel1() || this.session.isClinicAdmin()) {
				if (this.myAgreement.length == 0) {
					this.initAgreement()
				}
			}
			// } else if (nextTab == 'credits') {
			// 	// 26.10.2022
			// 	this.currentAction = 'showplan'
			// 	//this.initCreditsTab()  // fatto su init
		} else if (nextTab == 'activity') {
			// 26.10.2022
			this.currentAction = 'activity'
			this.initActivities()
		} else if (nextTab == 'devices') {
			this.userDevices = this.currUser.getDevices()

			// console.log(this.userDevices)
		} else if (nextTab == 'manuals') {
			this.loadingManuals = true
			this.session
				.getDocuments()
				.then((res) => {
					this.manuals = res.filter((doc) => doc.doc_type == docType.MANUAL)
					this.documents = res.filter((doc) => doc.doc_type == docType.RELEASE)
					this.loadingManuals = false
				})
				.catch((error) => {
					this.loadingManuals = false
				})
		}
	}

	public openexternalDocument(url: string) {
		let releaseModal = this.modalService.open(ExternalDocumentComponent, { size: 'xl', keyboard: false, backdrop: 'static' })
		releaseModal.componentInstance.url = url
	}

	setAccountTabActive() {
		if (this.shouldFillVat) {
			this.activeTab = 'account'
			const vatElement = document.getElementById('vat')
			if (vatElement) {
				setTimeout(() => {
					const y = vatElement.getBoundingClientRect().top + window.scrollY
					window.scroll({
						top: y,
						behavior: 'smooth',
					})
				}, 250)
			}
		}
	}

	// 1) ACCOUNT TAB SECTION

	async validateVat(vat: string) {
		if (vat === '' || vat === undefined) {
			this.vatError = false
			this.internalVatCheckerError = false
			return
		}

		this.vatError = !checkVAT(`${this.countryIso2}${vat}`, [this.jsvatCountry]).isValid

		if (!this.vatError) {
			this.isValidatingVat = true
			this.internalVatCheckerError = false

			this.session
				.vatChecker({ state: this.countryIso2.toUpperCase(), vatNumber: vat.replace(' ', '') })
				.then((res: VatCheckerResponseT) => {
					if (res.error) {
						return (this.internalVatCheckerError = true)
					}

					if (!res.valid) {
						return (this.vatError = true)
					}
				})
				.catch(() => {
					this.internalVatCheckerError = true
				})
				.finally(() => {
					this.isValidatingVat = false
				})
		}
	}

	onChangeVat(event: Event) {
		this.isFillingVat = this.editsInfo.vat && this.editsInfo.vat !== ''
		this.validateVat((event.target as HTMLInputElement).value)
	}

	private emailValidator(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			var valid = control.value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

			if (valid == null) {
				return { ['Invalid email']: true }
			}
		}
		return null
	}

	private initAddrerssForm() {
		this.isAddressValid = false

		this.addressFields = new FormGroup({
			address: new FormControl(this.currUser.mainAddress.address_line1, [Validators.required]),
			zip: new FormControl(this.currUser.mainAddress.zip, [Validators.required, Validators.minLength(4), Validators.maxLength(10)]),
			city: new FormControl(this.currUser.mainAddress.city, [Validators.required]),
			province: new FormControl(this.currUser.mainAddress.province, [Validators.required]),
			country: new FormControl(this.currUser.getCountry(), [Validators.required]),
			role: new FormControl('', [Validators.required]),
			showOnReport: new FormControl(''),
		})

		const valid = this.currUser.getMainAddressCoordinates()

		if (this.session.addressLocationEnabled()) {
			this.addressFields.disable()
			this.addressFields.get('role').reset()

			if (valid) {
				this.isAddressValid = true
			}
		} else {
			this.isAddressValid = true
			this.addressFields.get('country').disable()
			this.addressFields.get('role').disable()
		}

		//OPTICIAN
		if (this.session.isOptician() && !this.session.isMini()) {
			this.addressFields.get('showOnReport').enable()
			if (this.currUser.settings.address_on_report == 'Y') {
				this.addressFields.get('showOnReport').setValue(true)
			} else {
				this.addressFields.get('showOnReport').setValue(false)
			}
		}

		// CLINIC ADMIN - SUPERB
		if (this.session.isClinicAdmin() || this.session.isSuperB()) {
			// a default deve essere clinic e non cambiabile alla first login che non ha la location
			// non deve essere inoltre cambiabile se ha una sola location

			if (this.currUser.mainAddress.role && this.currUser.mainAddress.role != '') {
				this.addressFields.get('role').setValue(this.currUser.mainAddress.role)
			} else {
				this.addressFields.get('role').setValue(Config.CLINIC)
				this.changeRole()
			}

			if (this.session.user.canDeleteClinicAddress()) {
				this.addressFields.get('role').enable()
			} else {
				this.addressFields.get('role').disable()

				if (this.session.user.getMainAddressRole() == Config.LEGAL) {
					this.addressFields.get('role').enable()
				}
			}
		}

		// console.log(this.addressFields.get('role').value)
	}

	saveShowOnReport() {
		if (this.addressFields.get('showOnReport').value) {
			this.prefs.address_on_report = 'Y'
		} else {
			this.prefs.address_on_report = 'N'
		}
	}

	public changeRole() {
		// in questo modo se cambio/salvo un indirizzo il componente mappa puó prendersi giú la role dallo user in memoria
		this.session.user.mainAddress.role = this.addressFields.get('role').value
	}

	public saveRole() {
		this.currUser.mainAddress.role = this.addressFields.get('role').value

		this.mapService
			.updateProfileAddress(this.currUser.mainAddress)
			.then((resp) => {
				// console.log(resp)
				this.toastShow(true)
			})
			.catch((err) => {
				console.log(err)
				this.toastShow(false, err)
			})
	}

	public saveAddress() {
		let address: Address = this.currUser.mainAddress

		address.address_line1 = this.addressFields.get('address').value
		address.zip = this.addressFields.get('zip').value
		address.city = this.addressFields.get('city').value
		address.province = this.addressFields.get('province').value
		address.country = this.addressFields.get('country').value

		this.mapService
			.updateProfileAddress(address)
			.then((resp) => {
				// console.log(resp)
				this.toastShow(true)
			})
			.catch((err) => {
				console.log(err)
				this.toastShow(false, err)
			})
	}

	private initProfileData() {
		this.editsInfo.firstName = this.session.getFirstName()
		this.editsInfo.lastName = this.session.getLastName()
		this.editsInfo.ref_email = this.session.getEmail()
		this.editsInfo.phone1 = this.session.getPhone()
		this.editsInfo.organization = this.session.getOrganization()
		this.editsInfo.vat = this.session.getVat()

		this.currUser = this.session.getCurrentUser()

		if (this.session.isLevel1()) {
			this.keyPhoto = this.currUser.keyPhoto.data.slice()
			this.keyPhoto = forge.util.encode64(this.keyPhoto)
			this.keydoctor = this.currUser.keyDoctor.data.slice()
			this.keydoctor = forge.util.encode64(this.keydoctor)
		}

		this.initAddrerssForm()

		//valutare se riabilitarlo per il cambio psw con puk per i no lv.1
		if (this.session.isLevel1()) {
			if (!this.currUser.logo) {
				this.session.updateLogo('', '')
			}

			const isLogoEmpty = !this.currUser.logo || (this.currUser.logo === b64images.logoTransparent && this.currUser.logo_name === 'transparent.png')

			this.prevLogo = isLogoEmpty ? '' : this.currUser.logo
			this.prevLogo_name = isLogoEmpty ? '' : this.currUser.logo_name

			if (isLogoEmpty) {
				this.editLogo.logo = ''
				this.editLogo.logo_name = ''
			} else {
				Util.debug('we have logo')
				this.logoFileInvalid = false
				this.editLogo.logo = this.currUser.logo
				this.editLogo.logo_name = this.currUser.logo_name
			}
		}

		// console.log(this.edits)
		// console.log(this.currUser)

		if (this.currUser.order_reg_num) {
			// 11.02.2021

			if (this.currUser.order_reg_num == '-' && this.session.isSpecialist()) {
				this.editReport.order_reg_num = ''
			} else {
				this.editReport.order_reg_num = this.currUser.order_reg_num
			}
		}

		if (this.currUser.display_name) {
			this.editReport.display_name = this.currUser.display_name
		}

		this.editReport.signature_name = this.currUser.signature_name

		if (this.currUser.signature != '' && this.currUser.signature != null) {
			Util.debug('we have signature')
			this.fileInvalid = false
			this.editReport.signature = this.currUser.signature
		}
		// Se first Login e campi con '-' allora gli sbianco e forzo il save
		if (this.session.isFirstLogin() && this.session.isSpecialist()) {
			// console.log('is first login')
			if (this.editsInfo.firstName == '-') {
				this.editsInfo.firstName = ''
			}

			if (this.editsInfo.lastName == '-') {
				this.editsInfo.lastName = ''
			}
		}

		//SPECIALIST
		if (this.session.isSpecialist() && !this.session.isMiniC()) {
			this.addressMissed = this.translator.instant('PROFILE.ADDRESS_MISSED_SPC')

			if (this.session.user.isPrivate()) {
				this.isPrivate = true
			}
		}

		//OPTICIAN
		if (this.session.isOptician() && !this.session.isMini()) {
			this.addressMissed = this.translator.instant('PROFILE.ADDRESS_MISSED_OPT')
		}

		//CLINIC ADMIN
		if (this.session.isClinicAdmin() || this.session.isSuperB()) {
			this.addressMissed = this.translator.instant('PROFILE.ADDRESS_MISSED_CAD')
		}
	}

	editReportInfo() {
		Util.debug('profile info submit called, action: ' + this.currentAction)

		this.reportFields.form.markAsPristine()

		this.confirmEdit()
			.result.then((result) => {
				if (result) {
					if (this.session.isSpecialist()) {
						this.session
							.updateReportInfo(this.editReport)
							.then(() => {
								this.toastShow(true)
							})
							.catch((err) => {
								console.log(err)
								this.toastShow(false, err)

								return false
							})
					} else if (this.session.isOptician()) {
						this.session
							.updateLogo(this.editLogo.logo, this.editLogo.logo_name)
							.then(() => {
								this.prevLogo = this.editLogo.logo
								this.prevLogo_name = this.editLogo.logo_name
							})
							.catch((err) => {
								var errMsg = this.session.parseErrorMessage(err, 'alert')

								var myMsg = 'Error during logo update: ' + errMsg
								alert(myMsg)

								// this.enabledSaveButton = true
								return false
							})
					}
				} else {
					return
				}
			})
			.catch(() => {
				Util.debug('dismissed')
				Util.debug('(editProf) closing confirm modal, discard changes')
				// return this.session.getLandingPage() // 12-05-2023
				return
			})
	}

	editProfileInfo() {
		Util.debug('profile info submit called, action: ' + this.currentAction)

		this.generalInfo.form.markAsPristine()

		this.confirmEdit()
			.result.then((result) => {
				if (result) {
					Util.debug('(editProf) going to update profiles info, phone: ' + this.editsInfo.phone1)
					// console.log(this.editsInfo)
					this.session
						.updateProfileInfo(this.editsInfo)
						.then(() => {
							this.toastShow(true)
						})
						.catch((err) => {
							console.log(err)
							this.toastShow(false, err)

							return false
						})

					//this.confirmEditsModal = null;
				} else {
					return
				}
			})
			.catch(() => {
				Util.debug('dismissed')
				Util.debug('(editProf) closing confirm modal, discard changes')
				// return this.session.getLandingPage() // 12-05-2023
				return
			})
	}

	private confirmEdit(): NgbModalRef {
		var msg = this.translator.instant('PROFILE.MODAL_QUESTION')

		let confirmModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal

		confirmModal.componentInstance.isExit = false
		confirmModal.componentInstance.isQuest = true
		confirmModal.componentInstance.warnText = msg

		return confirmModal
	}

	// 2) SIGNATURE SECTION
	checkSignature(event) {
		console.log('Check signature... size:  ' + event.target.files[0].size)

		// console.log(event.target)
		// console.log(event.target.files[0])

		let type = event.target.files[0].type

		if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
			this.loadSignature = true
			this.invalidSize = false

			let size = event.target.files[0].size

			let maxsize = 1000000 // 1MB

			if (size >= maxsize) {
				console.log('Invalid file, the size exceed the 1MB of size')

				this.loadSignature = false
				this.invalidSize = true
				this.fileInvalid = true

				this.signature.nativeElement.value = ''

				this.editReport.signature = ''
				this.editReport.signature_name = '' // 16.06.2023

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = this.translator.instant('MESSAGE.SIGNATURE_INVALID')
				let options = new ToastOptions('error')

				this.toastService.show(header, body, false, options, 'center')

				return
			}

			this.reportFields.form.markAsDirty() // l'input file é come se non facesse parte del form, per cui devo gestire manulamente il suo utilizzo

			let file = event.target.files[0]

			// console.log(file.name)

			const reader = new FileReader()

			reader.readAsDataURL(file)

			Util.debug('loading....')

			reader.addEventListener('load', (e) => {
				Util.debug('end')

				this.loadSignature = false
				this.fileInvalid = false

				const data = e.target.result

				this.editReport.signature = data
				this.editReport.signature_name = file.name // 16.06.2023

				// console.log(data)
			})
		} else {
			console.log('Invalid file,  ' + type)

			this.loadSignature = false
			this.invalidSize = true
			this.fileInvalid = true

			this.signature.nativeElement.value = ''

			this.editReport.signature = ''
			this.editReport.signature_name = '' // 16.06.2023

			let header = this.translator.instant('TOAST.HEADER.ERROR')
			let body = this.translator.instant('MESSAGE.SIGNATURE_INVALID_TYPE')
			let options = new ToastOptions('error')

			this.toastService.show(header, body, false, options, 'center')

			return
		}
	}

	resetSignature() {
		this.signature.nativeElement.value = ''
		this.loadSignature = false
		this.fileInvalid = true
		this.reportFields.form.markAsPristine()
		this.editReport.signature = ''
		this.editReport.signature_name = '' // 16.06.2023
	}

	// 3) LOGO SECTION
	checkLogo(event) {
		console.log('Check logo... size:  ' + event.target.files[0].size)

		let type = event.target.files[0].type

		if (type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') {
			this.loadLogo = true
			this.logoInvalidSize = false

			let size = event.target.files[0].size

			let maxsize = 1000000 // 1MB

			if (size >= maxsize) {
				console.log('Invalid file, the size exceed the 1MB of size')

				this.loadLogo = false
				this.logoInvalidSize = true
				this.logoFileInvalid = true

				this.logo.nativeElement.value = ''

				this.editLogo.logo = ''
				this.editLogo.logo_name = ''

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = this.translator.instant('MESSAGE.LOGO_INVALID')
				let options = new ToastOptions('error')

				this.toastService.show(header, body, false, options, 'center')

				return
			}

			this.reportFields.form.markAsDirty()

			let file = event.target.files[0]

			const reader = new FileReader()

			reader.readAsDataURL(file)

			Util.debug('loading....')

			reader.addEventListener('load', (e) => {
				Util.debug('end')

				this.loadLogo = false
				this.logoFileInvalid = false

				const data = e.target.result

				this.editLogo.logo = data
				this.editLogo.logo_name = file.name
			})
		} else {
			console.log('Invalid file,  ' + type)

			this.loadLogo = false
			this.logoInvalidSize = true
			this.logoFileInvalid = true

			this.logo.nativeElement.value = ''

			this.editLogo.logo = ''
			this.editLogo.logo_name = ''

			let header = this.translator.instant('TOAST.HEADER.ERROR')
			let body = this.translator.instant('MESSAGE.LOGO_INVALID_TYPE')
			let options = new ToastOptions('error')

			this.toastService.show(header, body, false, options, 'center')

			return
		}
	}

	resetLogo() {
		this.logo.nativeElement.value = ''
		this.loadLogo = false
		this.logoFileInvalid = true
		this.editLogo.logo = ''
		this.editLogo.logo_name = ''
		this.reportFields.form.markAsDirty()
	}

	// 4) PASSWORD SECTION

	submitChangePwd() {
		this.changePwdError = this.translator.instant('MESSAGE.REQUIRED')
		this.passwordChanging = true

		if (!this.checkFormValues()) {
			Util.debug('Pwd (submitChangePwd) invalid form')
			this.passwordChanging = false

			let header = this.translator.instant('TOAST.HEADER.ERROR')
			let body = this.translator.instant('MESSAGE.WRONG_PWD')
			let options = new ToastOptions('error')
			this.toastService.show(header, body, false, options, 'center')

			return
		}

		Util.debug('Pwd (submitChangePwd) ')

		this.changePwd()
			.then((ris: boolean) => {
				if (ris) {
					//alert("ok pwd changed!");  // se ok c'e' gia'
					//this.activeModal.close("ok");
				} else {
					Util.debug('(changePwd) ko 1')
				}
			})
			.catch((err) => {
				// 11.02.2022 uniformata gestione errori
				Util.debug('(changePwd) ko 2:')
				let msg = this.session.parseErrorMessage(err, 'alert')
				var myMsg = 'Error during edit profile: ' + msg
				alert(myMsg)
			})
	}

	validatorPassword(control: FormControl): { [s: string]: boolean } {
		if (!Util.validatePwd(control.value)) {
			return { ['password not valid']: true }
		}

		return null
	}

	validatorConfirmPassword(control: FormControl): { [s: string]: boolean } {
		// console.log(control.value)

		if (this.changePasswordForm) {
			if (control.value != this.changePasswordForm.get('password1').value) {
				return { ['password not macth']: true }
			}
		}

		return null
	}

	invalidBlankSpace(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			if (control.value.indexOf(' ') >= 0) {
				return { ['Invalid blank space']: true } // posso cambiare la scritta di errore dinamicamente cercando questo errore
			}
		}
		return null
	}

	checkFormValues() {
		if (!this.session.isCorrectPwd(this.changePasswordForm.get('oldPassword').value)) {
			Util.debug('(formPwd) old pwd does not match')
			this.changePasswordForm.get('oldPassword').reset()
			this.changePwdError = this.translator.instant('MESSAGE.WRONG_PWD')

			return false
		}

		return true
	}

	changePwd(): Promise<boolean> {
		// 28.09.2021 generalizzata anche per lev3
		//this.session.changeDoctorsPwd(this.profile.new_password)
		return this.session
			.changeUserPassword(this.changePasswordForm.get('password1').value)
			.then(() => {
				this.showPwdForm = false
				this.passwordChanging = false
				this.changePasswordForm.reset()
				// var okMsg = this.translator.instant('FIRST_LOGIN.OK_PWD_CHANGED')

				this.toastShow(true)

				return true
			})
			.catch((err) => {
				// 28.09.2021
				// 11.02.2022 uniformata gestione errori
				let msg = this.session.parseErrorMessage(err, 'alert')
				var myMsg = 'Error during pwd change. ' + msg
				alert(myMsg)
				return false
			})
	}

	onChangeNewPwd(val) {
		this.myNewPwd = this.changePasswordForm.get('password1').value
		// almeno 8 caratteri
		let charRegex = new RegExp('^(?=.*[a-z])(.{8,})$')

		if (charRegex.test(this.myNewPwd)) {
			this.charNum = true
		} else {
			this.charNum = false
		}
		// almeno una lettera maiuscola
		let upperRegex = new RegExp('^(?=.*[A-Z])')

		if (upperRegex.test(this.myNewPwd)) {
			this.uppercase = true
		} else {
			this.uppercase = false
		}
		// almeno una lettera speciale o numero
		let specialRegex = new RegExp('^(?=.*[.!@#$%^&*_0-9])')

		if (specialRegex.test(this.myNewPwd)) {
			this.specialChar = true
		} else {
			this.specialChar = false
		}

		if (this.changePasswordForm.get('password1').valid) {
			this.changePasswordForm.get('password2').enable()
		} else if (this.changePasswordForm.get('password2').enabled) {
			this.changePasswordForm.get('password2').disable()
		}
	}

	// 5) OPTIONS SECTION
	initPrefs() {
		this.prefs = {
			pdf_format: this.currUser.settings.pdf_format,
			date_format: this.currUser.settings.date_format,
			lang: this.currUser.settings.lang,
			address_on_report: this.currUser.settings.address_on_report,
		}
	}

	setOptions(event?, langData?) {
		Util.debug('(setOptions) begin...')

		Util.debug('(setOptions) onLanguageClick')
		// added this if for the save button in the profile
		if (langData) {
			this.session.onLanguageClick(event, langData)
		}
		Util.debug('(setOptions) onLanguageClick done')

		// if (this.currentAction != 'setOptions') {
		// 	Util.debug('(setOptions) ko action, ' + this.currentAction)
		// 	return false
		// }

		// this.enabledSaveButton = false // per evitare doppi invii  16.02.2022

		// 16.02.2022 per ora questi campi
		//let myPreferences : data.Preferences;
		let myPreferences = this.prefs

		// console.log(myPreferences)

		this.addressFields.get('showOnReport').markAsPristine() //nascondo il pulsante

		this.session
			.updateUsrPrefs(myPreferences)
			.then(() => {
				this.session.refreshProfile()
				this.toastShow(true)

				// var okMsg = this.translator.instant('MESSAGE.OK_SAVED')
				// alert(okMsg) // feedback per l'utente
				return true
			})
			.catch((err) => {
				//var errMsg = (err.data)? err.data.error : err.toString();
				var errMsg = this.session.parseErrorMessage(err, 'alert') // 28.03.2022
				this.toastShow(false, errMsg)
				// var myMsg = 'Error during settings update: ' + errMsg
				// alert(myMsg)

				// this.enabledSaveButton = true
				return false
			})
	}

	// 6) ACTIVITIES SECTION
	initActivities() {
		//this.computedMessage = "";
		//this.year = null;
		//this.month = null;

		if (!this.hasReports) {
			this.session
				.loadAllReports()
				.then((replist) => {
					if (replist != null && replist.length > 0) {
						this.hasReports = true
						this.ActivitiesListR = replist
					}
				})
				.catch((error) => {
					Util.debug('(countNewReports) KO profile')
					Util.debug(error)
				})
		}
	}

	// 7) DOCUMENTS SECTION

	initColumns() {
		Util.debug('[Documents] initColumns')
		this.displayedColumns = ['document_type', 'status', 'date', 'id', 'actions']
		this.displayedPatColumns = ['document_type', 'date', 'id', 'actions']
	}

	initAgreement() {
		if (!this.session.isManagement()) {
			this.session.getAcceptedUserAgreement().then((agrs: Agreement[]) => {
				// console.log(agrs)

				if (agrs != null && agrs.length > 0) {
					this.myAgreement = agrs
					this.agreementsList = agrs
						.filter((agreement) => agreement.is_valid === 'Y')
						.map((agreement) => {
							return {
								label: `AGREEMENT.${agreement.doc_type.toUpperCase()}_LINK`,
								status: 'accepted',
								date: agreement.acceptanceDate,
								url: agreement.url,
								id: agreement.id,
							}
						})

					Util.debug('P (initAgreement) ok, country: ' + agrs[0].country)
				} else {
					Util.debug('P (initAgreement) ko!')
				}
			})
		}
	}

	initPatientAgreement() {
		this.session.getOpticianAgreementForPatient().then((agrs: Agreement[]) => {
			// console.log(agrs)

			if (agrs != null && agrs.length > 0) {
				this.patientAgreement = agrs.map((agreement) => {
					return {
						label: `AGREEMENT.${agreement.doc_type.toUpperCase()}_LINK`,
						status: 'accepted',
						date: agreement.validFrom,
						url: agreement.url,
						id: agreement.id,
					}
				})

				Util.debug('P (initPatientAgreement) ok, country: ' + agrs[0].country)
			} else {
				Util.debug('P (initPatientAgreement) ko!')
			}
		})
	}

	// 8) DEVICES SECTION

	formatSize(av_space) {
		return Util.formatBytes(av_space, 2) // 2 = cifre dopo la virgola
	}

	// PMS SECTION

	private initPMSTab() {
		this.session
			.getPmsList()
			.then((resp) => {
				// console.log(resp)

				if (resp && resp.pms_list.length > 0) {
					for (let pms of resp.pms_list) {
						if (pms.connection_date) {
							this.onePmsActive = true
						}
						this.pmsList.push(new Pms(pms))
					}

					this.enablePmsTab = true
				} else {
					this.enablePmsTab = false
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}

	public disconnectPms(pms: Pms) {
		Util.debug('disconnectPms')

		pms.loading = true
		this.waitPMSresponse = true

		this.session
			.disconnectPMS(pms)
			.then((resp) => {
				// console.log(resp)
				pms.connection_date = null
				this.onePmsActive = false
				pms.loading = false
				this.waitPMSresponse = false
			})
			.catch((err) => {
				console.log(err)
				pms.loading = false
				this.waitPMSresponse = false
			})
	}

	public connectPms(pms: Pms) {
		Util.debug('connectPms')

		this.waitPMSresponse = true
		pms.loading = true

		this.session
			.connectPMS(pms)
			.then((resp) => {
				pms.connection_date = new Date()
				this.onePmsActive = true
				pms.loading = false
				this.waitPMSresponse = false
			})
			.catch((err) => {
				console.log(err)
				pms.loading = false
				this.waitPMSresponse = false
			})
	}

	// OTHERS SECTION
	private toastShow(isSuccess: boolean, err?: string) {
		let header = ''
		let body = ''
		let options = null

		if (isSuccess) {
			header = this.translator.instant('TOAST.HEADER.SUCCESS')
			body = this.translator.instant('MESSAGE.OK_SAVED')
			options = new ToastOptions('success')
			this.toastService.show(header, body, false, options, 'bottom-right')
		} else {
			header = this.translator.instant('TOAST.HEADER.ERROR')
			body = 'Error during edit profile: ' + this.session.parseErrorMessage(err, 'alert')
			options = new ToastOptions('error')
			this.toastService.show(header, body, false, options, 'center')
		}
	}

	refresh() {
		Util.debug('(profile_refresh) ')
		this.session.relogin()
		this.currentModal.dismiss()
	}

	// 27.05.2022
	downloadContract() {
		alert('not available yet')
		// TODO pdf
	}

	// 03.03.2021 quando esco da pg di edit profile con close/cancel
	goLanding() {
		//this.location.url("this.session.getLandingPage()");
		//this.session.checkRoute();  // 05.01.2022
		this.session.getLandingPage() // 10.01.2022
	}

	test(num) {
		alert('(test) ' + num)
	}

	// zipCheck(zip) {
	// 	zip = zip.target.value
	// 	if (this.editAddress.zipMin && this.editAddress.zipMax) {
	// 		if (this.editAddress.country == 'PRT') {
	// 			this.zipPattern = '[A-z0-9]{4}[-][A-z0-9]{3}'
	// 			if (zip.match(/[A-z0-9]{4}[-][A-z0-9]{3}/)) {
	// 				return (this.zipError = true)
	// 			} else {
	// 				return (this.zipError = false)
	// 			}
	// 		} else {
	// 			this.zipPattern = ''
	// 		}
	// 	}
	// }

	ngOnDestroy(): void {
		this.profileStateSubscription.unsubscribe()
	}
}
