<div class="panel panel-modal panel-default ng-cloak position-relative" style="overflow: hidden">
	<loader [activateSpinner]="loading"></loader>

	<!-- modal-panel  -->
	<div class="panel-heading">
		<label [hidden]="currentAction != 'create'" class="panel-title">{{'PATIENT.MODAL_TITLE_NEW' | translate}}</label>
		<label [hidden]="currentAction != 'modify'" class="panel-title">{{'PATIENT.MODAL_TITLE_UPDATE' | translate}}</label>
	</div>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
	</div>

	<div class="panel-body patient-details-container pt-3" [ngClass]="loading ? '' : 'show' ">
		<form *ngIf="!flagDone" class="d-flex flex-column" [formGroup]="patientForm">
			<div class="container-fluid">
				<div class="row" *ngIf="currentAction == 'modify'">
					<div class="form-group col-3">
						<label for="code">{{"PATIENT.MODAL_CODE" | translate}}</label>
						<br />
						<input type="text" class="form-control" readonly name="code" id="code" [value]="currPatient.code" />
					</div>

					<div class="form-group col-3">
						<label for="email">{{"MISC.SINCE" | translate}}</label>
						<br />{{session.formatDate(currPatient.subscription_time)}}
					</div>
				</div>

				<!-- 1st line -->
				<div class="row">
					<div class="form-group col-6">
						<label for="first-name">{{'PATIENT.MODAL_FIRST_NAME' | translate}} *</label>
						<br />
						<input type="text" class="form-control" maxlength="30" id="first-name" name="first-name" placeholder="{{'PATIENT.MODAL_FIRST_NAME_HINT' | translate}}" formControlName="firstName" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
						<div class="error-message" [hidden]="!(patientForm.get('firstName').touched && patientForm.get('firstName').invalid)">{{'MESSAGE.REQUIRED' | translate}}</div>
					</div>

					<div class="form-group col-6">
						<label for="last-name">{{'PATIENT.MODAL_LAST_NAME' | translate}} *</label>

						<br />
						<input type="text" class="form-control" maxlength="30" id="last-name" name="last-name" placeholder="{{'PATIENT.MODAL_LAST_NAME_HINT' | translate}}" formControlName="lastName" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
						<div class="error-message" [hidden]="!(patientForm.get('lastName').touched && patientForm.get('lastName').invalid)">{{'MESSAGE.REQUIRED' | translate}}</div>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<!-- mat datepicker -->
						<span class="custom-mat-hint">{{dateFormat}}</span>
						<mat-form-field appearance="outline">
							<mat-label><label for="birth-date">{{"PATIENT.MODAL_BIRTH_DATE" | translate}} </label></mat-label>
							<input matInput [matDatepicker]="picker1" (dateChange)="selectDate(); checkBirthDate()" name="birthDate" id="birthDate" [min]="minDate" [max]="maxDate" formControlName="birthDate" />
							<!-- <mat-hint>{{dateFormat}}</mat-hint> -->
							<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</mat-form-field>
						<div class="error-message" [hidden]="!(patientForm.get('birthDate').touched && patientForm.get('birthDate').invalid)">{{'MESSAGE.REQUIRED' | translate}}</div>
					</div>
					<div class="form-group col-6 d-flex justify-content-evenly">
						<div [hidden]="birthDate == null " style="margin-right: 10px">
							<label for="age">{{ 'PATIENT.AGE' | translate }}</label>
							<b>
								<p *ngIf="currentAction == 'create'; else ageModify" style="font-size: 20px">{{ birthDate ? ((birthDate.format('YYYY-MM-DD'))| age) : ''}}</p>

								<ng-template #ageModify>
									<p style="font-size: 20px">{{ (birthDate.format('YYYY-MM-DD')) | age }}</p>
									<!-- per la conversione in etá vuole la stringa "yyyy-mm-dd" -->
								</ng-template>
							</b>
						</div>

						<div class="mini-modal-warning" [hidden]="validBirthDate">
							<div class="arrow"></div>
							<div *ngIf="currentAction == 'create'; else warningBirth">
								<div class="warning-header warning"><fa-icon [icon]="faTriangleExclamation" class="warning"></fa-icon> {{'MISC.ATTENTION' | translate}}</div>
								<div class="warning-body">{{'PATIENT.MODAL_WARN_AGE' | translate}}</div>
							</div>

							<ng-template #warningBirth>
								<div>
									<div class="warning-header warning">
										<fa-icon [icon]="faTriangleExclamation" class="warning" [ngClass]="{ 'error-blink' : birthDate.year() > today.getFullYear() }"></fa-icon>
										{{'MISC.ATTENTION' | translate}}
									</div>
									<div class="warning-body">{{ birthDate.year() < today.getFullYear() ? ('PATIENT.MODAL_WARN_AGE' | translate) : ('PATIENT.MODAL_WRONG_BIRTH'| translate) }}</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

				<!-- 2nd line -->
				<div class="row">
					<div class="form-group col-6">
						<label for="sex">{{"PATIENT.MODAL_SEX" | translate}} *</label>
						<select class="form-control" name="gender" id="gender" formControlName="gender" (change)="validateNewPatient()">
							<option *ngFor="let s of userSex" [ngValue]="s.code">{{s.descr | translate}}</option>
						</select>
						<div class="error-message" [hidden]="!(patientForm.get('gender').touched && patientForm.get('gender').invalid)">{{'MESSAGE.REQUIRED' | translate}}</div>
					</div>

					<div class="form-group col-6">
						<label for="pid">{{"PATIENT.MODAL_PERSONAL_ID" | translate}}</label>
						<input type="text" class="form-control" maxlength="100" name="pid" placeholder="{{'PATIENT.MODAL_PERSONAL_ID_HINT' | translate}}" formControlName="personalId" />
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<label for="internal_code" nowrap>{{"PATIENT.INTERNAL_CODE" | translate}}</label>
						<input type="text" class="form-control" maxlength="50" name="internal_code" id="internal_code" placeholder="{{'PATIENT.MODAL_INTERNAL_CODE_HINT' | translate}}" formControlName="internalCode" />
					</div>

					<div class="form-group col-6">
						<label for="country">{{ 'PATIENT.MODAL_COUNTRY' | translate }} * </label>
						<searchdropdown *ngIf="countries != null && countries.length > 0 && countryEditable" class="normalcase" style="padding: 0" [countries]="countries" [userCountry]="userCountryObj" #searchCountry (outcountry)="selCountry($event)" (cancelCountry)="resetCountry($event); patientForm.get('country').markAsTouched()" aria-required="true"> </searchdropdown>
						<input *ngIf="!countryEditable" type="text" class="form-control" maxlength="10" name="country" placeholder="{{'PATIENT.MODAL_PERSONAL_ID_HINT' | translate}}" formControlName="country" />
						<div class="error-message" [hidden]="!(patientForm.get('country').touched && patientForm.get('country').invalid)">{{'MESSAGE.REQUIRED' | translate}}</div>
					</div>
				</div>

				<!-- 3th line -->
				<div class="row">
					<div class="form-group col-6">
						<label for="email">{{"PATIENT.MODAL_EMAIL" | translate}}</label>
						<input type="text" class="form-control" maxlength="100" name="email" id="email" placeholder="{{'PATIENT.MODAL_EMAIL_HINT' | translate}}" formControlName="email" />
					</div>

					<div class="form-group col-6 position-relative">
						<label for="phone">{{"PATIENT.MODAL_PREFIX_PHONE" | translate}}</label>
						<div class="input-group input-group">
							<input type="text" class="input-group-text prefix-form" maxlength="5" minlength="2" style="width: 30%" formControlName="prefix" pattern="\+?\d*" oninput="this.value = this.value.replace(/[^0-9+]/g, '').replace(/^([0-9])(?!$)/g, '+')" />
							<input type="text" class="form-control" maxlength="20" name="phone" placeholder="{{'PATIENT.MODAL_PHONE_HINT' | translate}}" formControlName="phone" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
						</div>
						<div *ngIf="patientForm.get('prefix').invalid">
							<span class="errors" *ngIf="patientForm.get('prefix').errors['insert a valid prefix']">{{'PATIENT.MODAL_INVALID_PRERFIX' | translate}}</span>
						</div>
						<div *ngIf="patientForm.get('phone').invalid">
							<span class="errors" *ngIf="patientForm.get('phone').errors['Invalid number inserted']">{{'PATIENT.MODAL_INVALID_PHONE' | translate}}</span>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-6" *ngIf="currPatient.isDicom()">
						<label for="dicom">DICOM ID</label>
						<input type="text" class="form-control normalcase" id="dicom" name="dicom" readonly formControlName="dicomId" />
					</div>
				</div>

				<div class="spacer">&nbsp;</div>

				<div class="row" *ngIf="telerefEnabled && currentAction == 'create' " [hidden]="this.session.isDoctor()">
					<div class="form-group col-12">
						<div class="checkboxSel d-flex">
							<input class="form-check-input me-2" id="teleR" name="teleR" type="checkbox" formControlName="telerefractSyncr" />
							<label class="form-check-label" for="telerefract"> {{'TELEREFRACT.ADD_QUEUE' | translate}}</label>
						</div>
					</div>
				</div>

				<div class="row" *ngIf="telerefEnabled && currentAction != 'create'" [hidden]="this.session.isDoctor()">
					<div class="col-6">
						<button type="submit" [disabled]="patientForm.invalid || patientForm.get('gender').value == '0' || syncrRefrSent" (click)="postTelerefract()" class="btn btn-danger">{{'TELEREFRACT.ADD_QUEUE_NOW' | translate}}</button>
					</div>
				</div>

				<div class="spacer">&nbsp;</div>
			</div>
		</form>

		<!-- after create patient the ui shows this -->

		<div class="container" *ngIf="flagDone && telerefEnabled">
			<form telerform="ngForm" class="row">
				<div class="full-container fields-container">
					<div class="row" *ngIf="(session.isProduction() || session.isStaging()) && postTelerefractError">
						<div class="col-12">
							<div class="alert alert-danger text-center" role="alert">{{'TELEREFRACT.ADD_TO_QUEUE_ERROR' | translate}}</div>
						</div>
					</div>

					<div [hidden]="(session.isProduction() || session.isStaging())">
						<div class="row">
							<div class="form-group full-container">
								<label for="url">TeleRefract CallBack Patient URL *</label>
								<input type="text" class="form-control" maxlength="200" id="url" name="url" [(ngModel)]="teleRefrUrl" required />
							</div>
						</div>

						<div class="row">
							<div class="form-group third-container">
								<label for="first">{{'PATIENT.MODAL_FIRST_NAME' | translate}}</label>
								<br />
								<input type="text" class="form-control" id="first" name="firstname" [value]="currPatient.firstName" readonly />
							</div>

							<div class="form-group third-container">
								<label for="last">{{"PATIENT.MODAL_LAST_NAME" | translate}}</label>
								<br />
								<input type="text" class="form-control" name="last" id="lastname" [value]="currPatient.lastName" readonly />
							</div>

							<div class="form-group third-container">
								<label for="code" nowrap>ID</label>
								<input type="text" class="form-control" name="code" id="id" [value]="currPatient.id" readonly />
							</div>
						</div>

						<div class="row">
							<div class="full-container">
								NB: This form will be hidden after tests, post will be done automatically.
								<br />&nbsp;
							</div>
						</div>

						<div class="row">
							<div class="col-12">
								<button type="button" (click)="postTelerefract();" [disabled]="syncrRefrSent" class="btn btn-primary">Notify Telerefract</button>

								<!-- <button type="button" (click)="restart();" [disabled]="!syncrRefrSent" class="btn btn-primary">new pat</button> -->
							</div>
							<div class="third-container">&nbsp;</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>

	<div class="container">
		<div class="row">
			<div class="col-12 text-end">
				<button type="button" [disabled]="disableButton || patientForm.invalid" (click)="passBack()" class="btn btn-primary w-auto">{{'BUTTONS.SAVE' | translate}}</button>
			</div>
		</div>
	</div>
</div>
