<div class="panel panel-modal panel-default ng-cloak position-relative topic-history-container">
	<loader [activateSpinner]="isLoading"></loader>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
	</div>

	<div *ngIf="!isLoading">
		<h4 class="fw-bold">{{"IMPACT.SECTION_TITLE_" + section.toUpperCase() | translate}}</h4>
		<h5 class="fw-bold">{{"IMPACT.TOPIC_TITLE_" + topic.toUpperCase() | translate}}</h5>

		<div class="history-legend">
			<span class="indicator results"></span>
			<span class="value">{{"IMPACT.HISTORY_RESULTS" | translate}}</span>
			<span class="indicator norm"></span>
			<span class="value">{{"IMPACT.HISTORY_NORM" | translate}}</span>
		</div>

		<div class="row" id="chart">
			<div class="col-6 chart-container">
				<div class="chart-header">
					<fa-icon [icon]="faEye" size="2x" faEye aria-hidden="true"></fa-icon>
					<span>{{ "IMPACT.LEFT_EYE" | translate }}</span>
				</div>
				<apx-chart [chart]="chartOptionsL.chart" [xaxis]="chartOptionsL.xaxis" [yaxis]="chartOptionsL.yaxis" [annotations]="chartOptionsL.annotations" [series]="chartOptionsL.series" [grid]="chartOptionsL.grid" [dataLabels]="chartOptionsL.dataLabels"></apx-chart>
			</div>
			<div class="col-6 chart-container">
				<div class="chart-header justify-content-end">
					<fa-icon [icon]="faEye" size="2x" faEye aria-hidden="true"></fa-icon>
					<span>{{ "IMPACT.RIGHT_EYE" | translate }}</span>
				</div>
				<apx-chart [chart]="chartOptionsR.chart" [xaxis]="chartOptionsR.xaxis" [yaxis]="chartOptionsR.yaxis" [annotations]="chartOptionsR.annotations" [series]="chartOptionsR.series" [grid]="chartOptionsR.grid" [dataLabels]="chartOptionsR.dataLabels"></apx-chart>
			</div>
		</div>
	</div>
</div>
