<div class="container-fluid position-relative panel-modal report-modal-new">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
	</div>

	<div class="row">
		<div class="col-12" style="min-height: 50vh">
			<div class="row report-modal-new__header">
				<div class="col-12 mb-3">
					<div class="row justify-content-center patient-info-section">
						<div class="col-auto">
							<label class="uppercase title fw-bold">{{ 'PATIENT.INFO' | translate }}</label>
						</div>
						<div class="col-auto"><label class="uppercase fw-bold">{{ 'PATIENT.NAME' | translate }}: </label> <span> {{ patient.name }}</span></div>
						<div class="col-auto"><label class="uppercase fw-bold">{{ 'PATIENT.CODE' | translate }}: </label> <span> {{ patient.code }}</span></div>
					</div>
				</div>
				<div class="col-12">
					<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-pills justify-content-center" (navChange)="onNavChange($event)">
						<li [ngbNavItem]="reportsTabs[0].id" class="nav-item me-3 position-relative" [disabled]="!reportsTabs[0].active">
							<a ngbNavLink uppercase class="nav-link fw-bold">{{ reportsTabs[0].label }}</a>
							<ng-template ngbNavContent>
								<loader [activateSpinner]="isLoadingHG"></loader>
								<reports-hg *ngIf="!isLoadingHG && !loadingHGFail" [reportList]="hgReportList" [targetPatient]="patient"></reports-hg>
								<p class="report-modal-new__error-message" *ngIf="isLoadingHG && loadingHGFail">{{ 'NEW_REPORTS_MODAL.HG_ERROR_MESSAGE' | translate }}</p>
							</ng-template>

							<span *ngIf="newHGReportCount > 0 && (session.isLevel1() || session.isManagement())" class="badge rounded-pill bg-success">{{ newHGReportCount }}</span>
						</li>

						@if(!session.isSpecialist() && !session.isClinicAdmin()){
						<li [ngbNavItem]="reportsTabs[1].id" class="nav-item me-3 position-relative" [disabled]="!reportsTabs[1].active">
							<a ngbNavLink uppercase class="nav-link fw-bold">{{ reportsTabs[1].label }}</a>
							<ng-template ngbNavContent>
								<loader [activateSpinner]="isLoadingAI"></loader>
								<report-ai *ngIf="!isLoadingAI && !loadingAIFail" [reportList]="aiReportList" [targetPatient]="patient"></report-ai>
								<p class="report-modal-new__error-message" *ngIf="isLoadingAI && loadingAIFail">{{ 'NEW_REPORTS_MODAL.AI_ERROR_MESSAGE' | translate }}</p>
							</ng-template>
							<span *ngIf="newAIReportCount > 0 && (session.isLevel1() || session.isManagement())" class="badge rounded-pill bg-success">{{ newAIReportCount}}</span>
						</li>
						}
					</ul>
				</div>
			</div>

			<div class="row report-modal-new__body mt-3 position-relative" style="min-height: 30vh">
				<div class="col-12">
					<div [ngbNavOutlet]="nav" class="pt-3"></div>
				</div>
			</div>
		</div>
	</div>
</div>
