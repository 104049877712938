import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap'

import { Validators, FormGroup, FormControl } from '@angular/forms'

import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { PukPdf } from '../../models/pdf.model'

import { faCircleXmark, faCircleCheck, faDownload, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { faCopy, faPaste, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'

// ex firstLogin controller - dopo questa, passa a verifyPuk

@Component({
	selector: 'activation',
	templateUrl: './activation.component.html',
	styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit, AfterViewInit {
	@ViewChild('nav') tabset: NgbNav
	@ViewChild('tab1') tab1: ElementRef
	@ViewChild('tab2') tab2: ElementRef
	@ViewChild('line1') line1: ElementRef
	@ViewChild('line2') line2: ElementRef

	tabs: { id: number; isDone: boolean; pilsTitle: string; title: string; subtitle: string }[]
	activeTab: number

	firstLoginForm: FormGroup

	myNewPwd: string
	passwordConfirm: string

	puk: string
	pukcopied: { state: boolean; result: boolean; message: string }
	insertedPUK: string

	pukPrinted: boolean

	pukPasted: { state: boolean; result: boolean; message: string }
	pukVerified: { state: boolean; result: boolean; message: string }

	showPwd1: boolean
	showPwd2: boolean

	isDoctorPukTime: boolean

	showIcon: boolean

	isLoading: boolean
	pleaseWait = ''
	charNum: boolean
	uppercase: boolean
	specialChar: boolean

	validPwd: boolean // 03.03.2021

	faDownload = faDownload
	faCircleXmark = faCircleXmark
	faCircleCheck = faCircleCheck
	faCopy = faCopy
	faPaste = faPaste
	faEyeOpen = faEye
	faEyeClosed = faEyeSlash
	faTriangleExclamation = faTriangleExclamation

	constructor(
		public session: SessionService,
		private translator: TranslateService,
		public modalService: NgbModal, //private dataService: DataModelService
		private toastService: AppToastService,
		private renderer: Renderer2
	) {
		this.isDoctorPukTime = this.session.isDoctorPukTime()
		this.activeTab = this.isDoctorPukTime ? 2 : 0
		this.tabs = [
			{
				id: 0,
				isDone: this.isDoctorPukTime ? true : false,
				pilsTitle: this.translator.instant('FIRST_LOGIN.STEP1_PILS_TITLE'),
				title: this.translator.instant('FIRST_LOGIN.STEP1_TITLE'),
				subtitle: this.translator.instant('FIRST_LOGIN.STEP1_SUBTITLE'),
			},
			{
				id: 1,
				isDone: this.isDoctorPukTime ? true : false,
				pilsTitle: this.translator.instant('FIRST_LOGIN.STEP2_PILS_TITLE'),
				title: this.translator.instant('FIRST_LOGIN.STEP2_TITLE'),
				subtitle: this.translator.instant('FIRST_LOGIN.STEP2_SUBTITLE'),
			},
			{
				id: 2,
				isDone: false,
				pilsTitle: this.translator.instant('FIRST_LOGIN.STEP3_PILS_TITLE'),
				title: this.translator.instant('FIRST_LOGIN.STEP3_TITLE'),
				subtitle: this.translator.instant('FIRST_LOGIN.STEP3_SUBTITLE'),
			},
		]

		this.showPwd1 = false
		this.showPwd2 = false

		this.puk = ''
		this.isLoading = false
		this.insertedPUK = ''

		this.showIcon = false

		this.pukcopied = { state: false, result: false, message: '' }
		this.pukPasted = {
			state: this.isDoctorPukTime ? true : false,
			result: this.isDoctorPukTime ? true : false,
			message: '',
		}
		this.pukVerified = { state: false, result: false, message: '' }

		this.pukPrinted = false

		this.pleaseWait = '' // "Provide new password, then please wait while generating security keys....";

		this.validPwd = false

		this.myNewPwd = ''
		this.passwordConfirm = ''

		this.charNum = false
		this.uppercase = false
		this.specialChar = false
	}

	ngOnInit(): void {
		window.addEventListener('resize', () => {
			this.calculateLines()
		})

		this.firstLoginForm = new FormGroup({
			password1: new FormControl(this.myNewPwd, [Validators.required, this.validatorPassword.bind(this), this.invalidBlankSpace.bind(this)]),
			password2: new FormControl(this.passwordConfirm, [Validators.required, this.validatorConfirmPassword.bind(this)]),
		})

		this.firstLoginForm.get('password2').disable()
	}

	ngAfterViewInit(): void {
		this.calculateLines()
	}

	private calculateLines() {
		// le linee che congiungono i vari step possonon avere lunghezze diverse in base alla dimensione della finestra, per cui lo calcolo ogni volta
		const rect1 = this.tab1.nativeElement.getBoundingClientRect()
		const rect2 = this.tab2.nativeElement.getBoundingClientRect()

		const distanza = rect2.left - rect1.right
		const elementWidth = rect2.left - rect2.right

		this.renderer.setStyle(this.line1.nativeElement, 'width', distanza + 'px')
		this.renderer.setStyle(this.line1.nativeElement, 'transform', 'translateX(' + elementWidth + 'px)')

		this.renderer.setStyle(this.line2.nativeElement, 'width', distanza + 'px')
		this.renderer.setStyle(this.line2.nativeElement, 'transform', 'translateX(' + elementWidth + 'px)')
	}

	public onNavChange(id: number) {
		// console.log('onNavChange: ' + id)
		setTimeout(() => {
			this.isLoading = false
		}, 200)
	}

	public next() {
		let tab = this.tabs.find((t) => t.id == this.activeTab)
		tab.isDone = true

		this.activeTab++
		this.tabset.select(this.activeTab)
	}

	validatorPassword(control: FormControl): { [s: string]: boolean } {
		if (!Util.validatePwd(control.value)) {
			return { ['password not valid']: true }
		}

		return null
	}

	validatorConfirmPassword(control: FormControl): { [s: string]: boolean } {
		if (control.value != this.myNewPwd) {
			return { ['password not macth']: true }
		}

		return null
	}

	invalidBlankSpace(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			if (control.value.indexOf(' ') >= 0) {
				return { ['Invalid blank space']: true } // posso cambiare la scritta di errore dinamicamente cercando questo errore
			}
		}
		return null
	}

	// 14.03.2022 event coincide con il valore digitato nel form
	onChangeNewPwd(event) {
		this.myNewPwd = this.firstLoginForm.get('password1').value

		this.validPwd = Util.validatePwd(this.myNewPwd)
		//this.fail = !(this.validPwd);  # 03.05.2022 qui troppe volte, anche mentre scrive la pwd

		// almeno 8 caratteri
		let charRegex = new RegExp('^(?=.*[a-z])(.{8,})$')

		if (charRegex.test(this.myNewPwd)) {
			this.charNum = true
		} else {
			this.charNum = false
		}

		// almeno una lettera maiuscola
		let upperRegex = new RegExp('^(?=.*[A-Z])')

		if (upperRegex.test(this.myNewPwd)) {
			this.uppercase = true
		} else {
			this.uppercase = false
		}

		// almeno una lettera speciale o numero
		let specialRegex = new RegExp('^(?=.*[.!@#$%^&*_0-9])')

		if (specialRegex.test(this.myNewPwd)) {
			this.specialChar = true
		} else {
			this.specialChar = false
		}

		if (this.firstLoginForm.get('password1').valid) {
			this.firstLoginForm.get('password2').enable()
		} else if (this.firstLoginForm.get('password2').enabled) {
			this.firstLoginForm.get('password2').disable()
		}
	}

	generateNewPuk() {
		this.pleaseWait = this.translator.instant('FIRST_LOGIN.GENERATING_KEYS')

		this.isLoading = true

		this.activeTab--

		this.session
			.initializeUserAccount(this.session.user.password)
			.then((puk) => {
				Util.debug('(activation) initialize success')

				this.puk = puk

				this.pleaseWait = ''

				this.tabset.select(this.activeTab)
				this.isLoading = false

				this.isDoctorPukTime = false
				this.pukPasted.state = false
			})
			.catch((error) => {
				this.puk = ''
				console.log('(activation) initialize error')
				console.log(error)
				this.isLoading = false

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = this.translator.instant('VERIFY_PUK.DESCRIPTION_FAIL')
				let options = new ToastOptions('error')

				this.toastService.show(header, body, false, options, 'center')
			})
	}

	submit() {
		//if (form.$valid)
		this.passwordConfirm = this.firstLoginForm.get('password2').value

		// 03.05.2022 dovrebbe averlo bloccato prima di qui, cmq...
		this.validPwd = Util.validatePwd(this.myNewPwd)

		if (!this.validPwd) {
			console.log('activation - invalid password')
			return
		}

		if (this.myNewPwd != this.passwordConfirm) {
			// 02.01.2018 aggiunto controllo che sia diversa da quella iniziale,
			// cfr analoghe funzioni su profile.ts, TODO usare funzioni comuni  --ls
		} else if (this.session.isCorrectPwd(this.myNewPwd)) {
			let header = this.translator.instant('FIRST_LOGIN.DESCRIPTION_FAIL')
			let options = new ToastOptions('error')

			this.toastService.show(header, this.translator.instant('FIRST_LOGIN.PWD_ERR_NOT_DIFF'), false, options, 'center')

			this.myNewPwd = ''
			this.passwordConfirm = ''
			//alert(this.errMsg);
		} else {
			//this.pleaseWait = 'Generating security keys...'
			this.pleaseWait = this.translator.instant('FIRST_LOGIN.GENERATING_KEYS')

			this.isLoading = true

			this.session
				.initializeUserAccount(this.myNewPwd)
				.then((puk) => {
					Util.debug('(activation) initialize success')
					// console.log(puk)
					this.puk = puk
					this.pleaseWait = ''

					this.next()
				})
				.catch((error) => {
					this.puk = ''
					console.log('(activation) initialize error')
					console.log(error)
					this.isLoading = false

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant('VERIFY_PUK.DESCRIPTION_FAIL')
					let options = new ToastOptions('error')

					this.toastService.show(header, body, false, options, 'center')
				})
		}
	}

	goLanding() {
		//this.location.url(this.session.getLandingPage());
		return this.session.getLandingPage() // 14.03.2022
	}

	printPuk(puk) {
		var pukPdf = new PukPdf(this.session, this.translator)
		pukPdf.setPuk(puk)
		pukPdf.generatePdf()

		this.pukPrinted = true

		// this.next()
	}

	copyToClipboard() {
		navigator.clipboard
			.writeText(this.puk)
			.then(() => {
				this.pukcopied.state = true
				this.pukcopied.result = true
				this.pukcopied.message = this.translator.instant('FIRST_LOGIN.PUK_COPIED_TRUE')
			})
			.catch((err) => {
				this.pukcopied.state = true
				this.pukcopied.result = false
				this.pukcopied.message = this.translator.instant('FIRST_LOGIN.PUK_COPIED_FALSE')
				console.error('Errore durante la copia: ', err)
			})
	}

	pasteToClipboard() {
		navigator.clipboard
			.readText()
			.then((puk) => {
				this.insertedPUK = puk
				this.pukPasted.state = true
				this.pukPasted.result = true
				this.pukPasted.message = this.translator.instant('FIRST_LOGIN.PUK_PASTED_TRUE')
			})
			.catch((err) => {
				this.pukPasted.state = true
				this.pukPasted.result = false
				this.pukPasted.message = this.translator.instant('FIRST_LOGIN.PUK_PASTED_FALSE')
				console.error('Errore durante la copia: ', err)
			})
	}

	submitPUK() {
		this.isLoading = true
		// 08.08.2022 tolgo spazi in testa e coda, per aiutare l'utente
		let myUsrPuk = this.insertedPUK.trim()

		this.pleaseWait = this.translator.instant('FIRST_LOGIN.VERIFING_PUK')

		this.session
			.verifyDoctorPuk(myUsrPuk)
			.then((puk) => {
				this.isLoading = false
				console.log('(puk) initialize success')

				this.pukVerified.state = true
				this.pukVerified.result = true
				this.pukVerified.message = ''

				// 09.05.2024 questa e' piu' leggera della loadDistribDoctors(...)
				if (this.session.isSpecialist()) {
					this.session.reactivateAllRelations()
					// TODO: verificare che il load relations fatto qui e anche nell'initAfterLogin non dia problemi
				}

				// 20.06.2023 bisogna aggiornare le relazioni, caricare gli icds, etc
				if (this.session.isLevel2() || this.session.isClinicAdmin()) {
					this.session.initAfterLogin().then(() => {
						this.session.initAL = false
						this.showIcon = true
					})
					//this.session.loadDistribDoctors(this.session.getUserId())
					// NB: questo fa anche verifyNewRelations
				} else {
					setTimeout(() => {
						this.showIcon = true
					}, 700)
				}
				this.insertedPUK = ''
			})
			.catch((error) => {
				this.isLoading = false

				this.insertedPUK = ''
				console.log('(puk) initialize error')
				console.log(error)

				this.pukVerified.state = true
				this.pukVerified.result = false
				this.pukVerified.message = this.translator.instant('VERIFY_PUK.DESCRIPTION_FAIL')

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = this.translator.instant('VERIFY_PUK.PUK_ERROR')
				let options = new ToastOptions('error')

				this.toastService.show(header, body, false, options, 'center')
			})
	}
}
