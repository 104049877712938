<div class="panel panel-modal panel-default ng-cloak position-relative impact-container">
	<loader [activateSpinner]="isLoadingReportData"></loader>

	<div class="select-dates-container">
		<select (change)="onChangeDate($event)" name="selectedDate" id="selectedDate" #selectedDate [disabled]="isLoadingReportData">
			<option value="{{date}}" *ngFor="let date of availableDates">{{formatDateString(date)}}</option>
		</select>
	</div>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
	</div>

	<div class="row mt-3 mb-3">
		<div class="datafield col-auto">
			<span>{{ 'PATIENT.INFO' | translate }}</span>
		</div>
		<field class="field col-auto" name="{{ 'PATIENT.NAME' | translate }}" value="{{ patient.name }}"></field>
		<field class="field col-auto" name="{{ 'PATIENT.CODE' | translate }}" value="{{ patient.code }}"></field>
		<field class="field col-auto" name="{{ 'PATIENT.AGE' | translate }}" value="{{ (session.isLevel1() ? patient.birthDate : '' + patient.birthYear) | age }}"> </field>
		<field class="field col-auto" name="{{ 'PATIENT.SEX' | translate }}" value="{{ 'SEX.' + patient.sex | translate }}"></field>
		<field class="field col-auto" name="{{ 'PATIENT.PERSONAL_ID' | translate }}" value="{{ patient.personal_id}}"></field>
	</div>

	<div class="row">
		<div *ngIf="!isLoadingReportData && impactReportData" class="col-9">
			<!-- REFRACTION SECTION -->
			<div class="section-container" *ngIf="refractionData">
				<div class="d-flex align-items-center section-header">
					<div class="impact-icon refraction-icon">&nbsp;</div>
					<div>
						<h5 class="section-header-title mb-0">{{"IMPACT.SECTION_TITLE_REFRACTION" | translate}}</h5>
						<p class="m-0">{{formatDateTimeString(refractionData.exam_date)}}</p>
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-6">
						<table class="refraction-table refraction">
							<thead>
								<tr *ngIf="rightRefractionEyeData">
									<th>{{ 'MISC.RIGHT' | translate }}</th>
									<th>{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
									<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
									<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
									<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								</tr>
							</thead>
							<tr *ngIf="rightRefractionEyeData">
								<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
								<td>{{ rightRefractionEyeData.pupil_real_size_day + ' mm' }}</td>
								<td>{{ rightRefractionEyeData.sphere_day + ' DS' }}</td>
								<td>{{ rightRefractionEyeData.cylinder_day + ' DC' }}</td>
								<td>{{ rightRefractionEyeData.axis_day + '°' }}</td>
							</tr>
							<tr *ngIf="rightRefractionEyeData">
								<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
								<td>{{ rightRefractionEyeData.pupil_real_size_night + ' mm' }}</td>
								<td>{{ rightRefractionEyeData.sphere_night + ' DS' }}</td>
								<td>{{ rightRefractionEyeData.cylinder_night + ' DC' }}</td>
								<td>{{ rightRefractionEyeData.axis_night + '°' }}</td>
							</tr>
						</table>
					</div>
					<div class="col-6">
						<table class="refraction-table">
							<thead>
								<tr *ngIf="leftRefractionEyeData">
									<th>{{ 'MISC.LEFT' | translate }}</th>
									<th>{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
									<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
									<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
									<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								</tr>
							</thead>
							<tr *ngIf="leftRefractionEyeData">
								<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
								<td>{{ leftRefractionEyeData.pupil_real_size_day + ' mm' }}</td>
								<td>{{ leftRefractionEyeData.sphere_day + ' DS' }}</td>
								<td>{{ leftRefractionEyeData.cylinder_day + ' DC' }}</td>
								<td>{{ leftRefractionEyeData.axis_day + '°' }}</td>
							</tr>
							<tr *ngIf="leftRefractionEyeData">
								<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
								<td>{{ leftRefractionEyeData.pupil_real_size_night + ' mm' }}</td>
								<td>{{ leftRefractionEyeData.sphere_night + ' DS' }}</td>
								<td>{{ leftRefractionEyeData.cylinder_night + ' DC' }}</td>
								<td>{{ leftRefractionEyeData.axis_night + '°' }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>

			<!-- ADAPTATION PROGRESSIVE SECTION -->
			<div class="section-container" *ngIf="adaptationProgressiveData" [ngClass]="adaptationProgressiveData.inRange ? 'normal' : 'moderate' ">
				<div class="d-flex align-items-center justify-content-between section-header">
					<div class="d-flex align-items-center">
						<div class="impact-icon adaptation-progressive-icon">&nbsp;</div>
						<div>
							<div class="d-flex align-items-center">
								<h5 class="section-header-title mb-0">{{"IMPACT.SECTION_TITLE_ADAPTATION_PROGRESSIVE" | translate}}</h5>
								<span *ngIf="adaptationProgressiveData.read_more_url" (click)="openReadMoreModal(adaptationProgressiveData.read_more_url)" class="impact-icon read-more-icon"></span>
							</div>
							<p class="m-0">{{formatDateTimeString(adaptationProgressiveData.exam_date)}}</p>
						</div>
					</div>
					<div>
						<span class="in-range-indicator" [ngClass]="adaptationProgressiveData.inRange ? 'normal' : 'moderate' ">{{"IMPACT.IN_RANGE_INDICATOR_" + (adaptationProgressiveData.inRange ? "NORMAL" : "MODERATE") | translate}}</span>
						<fa-icon [icon]="faChevronDown" faChevronDown [ngClass]="adaptationProgressiveData.show ? 'show' : '' " aria-hidden="true" (click)="adaptationProgressiveData.show = !adaptationProgressiveData.show"></fa-icon>
					</div>
				</div>
				<div *ngIf="adaptationProgressiveData.show">
					<div *ngFor="let topic of adaptationProgressiveData.topics" class="slider-topic-container">
						<div class="d-flex">
							<p class="topic-title">{{"IMPACT.TOPIC_TITLE_" + topic.title.toUpperCase() | translate}}</p>
							<span *ngIf="topic.read_more_url" (click)="openReadMoreModal(topic.read_more_url)" class="impact-icon read-more-icon"></span>
						</div>
						<div class="eyes-results-container">
							<div *ngFor="let eye of topic.eyes" class="col-6">
								<div class="eye-result eye-slider">
									<span class="eye-label text-center">{{"IMPACT." + eye.eye.toUpperCase() + "_EYE" | translate}}</span>
									<app-impact-slider *ngIf="eye.decrValue" [rangeMin]="topic.range_low" [rangeMax]="topic.range_high" [sliderValue]="eye.decrValue" [inRange]="eye.inRange" [decimalDigits]="topic.decimals || getMaxDecimals(topic.range_low, topic.range_high, eye.decrValue)"></app-impact-slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- EYE STRAIN SECTION -->
			<div class="section-container" *ngIf="eyeStrainData" [ngClass]="eyeStrainData.inRange ? 'normal' : 'moderate' ">
				<div class="d-flex align-items-center justify-content-between section-header">
					<div class="d-flex align-items-center">
						<div class="impact-icon eye-strain-icon">&nbsp;</div>
						<div>
							<div class="d-flex align-items-center">
								<h5 class="section-header-title mb-0">{{"IMPACT.SECTION_TITLE_EYE_STRAIN" | translate}}</h5>
								<span *ngIf="eyeStrainData.read_more_url" (click)="openReadMoreModal(eyeStrainData.read_more_url)" class="impact-icon read-more-icon">&nbsp;</span>
							</div>
							<p class="m-0">{{formatDateTimeString(eyeStrainData.exam_date)}}</p>
						</div>
					</div>
					<div>
						<span class="in-range-indicator" [ngClass]="eyeStrainData.inRange ? 'normal' : 'moderate' ">{{"IMPACT.IN_RANGE_INDICATOR_" + (eyeStrainData.inRange ? "NORMAL" : "MODERATE") | translate}}</span>
						<fa-icon [icon]="faChevronDown" faChevronDown [ngClass]="eyeStrainData.show ? 'show' : '' " aria-hidden="true" (click)="eyeStrainData.show = !eyeStrainData.show"></fa-icon>
					</div>
				</div>
				<div *ngIf="eyeStrainData.show">
					<div *ngFor="let topic of eyeStrainData.topics" class="slider-topic-container">
						<div class="eyes-results-container">
							<div *ngFor="let eye of topic.eyes" class="col-6">
								<div class="eye-result eye-graph">
									<div class="d-flex justify-content-between">
										<span class="eye-label text-center">{{"IMPACT." + eye.eye.toUpperCase() + "_EYE" | translate}}</span>
										<span *ngIf="eye.eye === 'left'" class="stimulus-legend">
											<span class="stimulus-legend-indicator"></span>
											<span class="stimulus-legend-label">Stimulus</span>
										</span>
									</div>
									<app-impact-eye-strain-graph
										*ngIf="eye.accomodationXValues && eye.accomodationXValues.length > 0 && eye.accomodationEyeStrainYValues && eye.accomodationEyeStrainYValues.length > 0 && eye.accomodationStimulusYValues && eye.accomodationStimulusYValues.length > 0"
										[xLabels]="eye.accomodationFixationArrInCm"
										[xValues]="eye.accomodationXValues"
										[stimulusYValues]="eye.accomodationStimulusYValues"
										[eyeStrainYValues]="eye.accomodationEyeStrainYValues"></app-impact-eye-strain-graph>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- DAY / NIGHT SECTION -->
			<div class="section-container" *ngIf="dayNightData" [ngClass]="dayNightData.inRange ? 'normal' : 'moderate' ">
				<div class="d-flex align-items-center justify-content-between section-header">
					<div class="d-flex align-items-center">
						<div class="impact-icon day-night-icon">&nbsp;</div>
						<div>
							<div class="d-flex align-items-center">
								<h5 class="section-header-title mb-0">{{"IMPACT.SECTION_TITLE_DAY_NIGHT" | translate}}</h5>
								<span *ngIf="dayNightData.read_more_url" (click)="openReadMoreModal(dayNightData.read_more_url)" class="impact-icon read-more-icon">&nbsp;</span>
							</div>
							<p class="m-0">{{formatDateTimeString(dayNightData.exam_date)}}</p>
						</div>
					</div>
					<div>
						<span class="in-range-indicator" [ngClass]="dayNightData.inRange ? 'normal' : 'moderate' ">{{"IMPACT.IN_RANGE_INDICATOR_" + (dayNightData.inRange ? "NORMAL" : "MODERATE") | translate}}</span>
						<fa-icon [icon]="faChevronDown" faChevronDown [ngClass]="dayNightData.show ? 'show' : '' " aria-hidden="true" (click)="dayNightData.show = !dayNightData.show"></fa-icon>
					</div>
				</div>
				<div *ngIf="dayNightData.show">
					<div *ngFor="let topic of dayNightData.topics" class="slider-topic-container">
						<div class="eyes-results-container">
							<div *ngFor="let eye of topic.eyes" class="col-6">
								<div class="eye-result eye-slider">
									<span class="eye-label text-center">{{"IMPACT." + eye.eye.toUpperCase() + "_EYE" | translate}}</span>
									<app-impact-slider *ngIf="eye.decrValue" [rangeMin]="topic.range_low" [rangeMax]="topic.range_high" [sliderValue]="eye.decrValue" [inRange]="eye.inRange" [decimalDigits]="topic.decimals || getMaxDecimals(topic.range_low, topic.range_high, eye.decrValue)"></app-impact-slider>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div *ngIf="!isLoadingRecommendationData && impactRecommendationData" class="col-3">
			<h4 *ngIf="impactRecommendationData.lenses.recommendations.length > 0" class="fw-bold mb-3">{{'IMPACT.RECOMMENDATION_TITLE' | translate}}</h4>
			<div *ngFor="let recommendation of impactRecommendationData.lenses.recommendations; index as i" class="recommendation-container mb-3">
				<div class="row flex-nowrap">
					<span class="recommendation-index">{{i+1}}</span>
					<div class="col-11">
						<h5 class="fw-bold">{{recommendation.title}}</h5>
						<p class="m-0">{{recommendation.body}}</p>
					</div>
				</div>
			</div>
			<h5 *ngIf="impactRecommendationData.lenses.comments.length > 0" class="fw-bold text-center mb-3">{{'IMPACT.COMMENTS_TITLE' | translate}}</h5>
			<div *ngFor="let comment of impactRecommendationData.lenses.comments" class="comment-container">
				<div class="col-12">
					<h5 class="fw-bold">{{comment.title}}</h5>
					<p class="m-0">{{comment.body}}</p>
				</div>
			</div>
		</div>
	</div>
</div>
