import { Component, inject, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'release-notes',
	templateUrl: './release-notes.modal.html',
	styleUrls: ['./release-notes.modal.scss'],
})
export class ExternalDocumentComponent implements OnInit {
	@Input() url: string
	releaseNotesUrl: SafeResourceUrl
	loading: boolean
	private sanitizer: DomSanitizer = inject(DomSanitizer)
	constructor(public activeModal: NgbActiveModal) {
		this.loading = true
	}

	ngOnInit(): void {
		// console.log(this.url)

		this.releaseNotesUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
		this.loading = false
	}
}
