<div class="panel panel-modal position-relative">
	<div class="panel-heading">
		<span class="panel-title" [hidden]="!(currentAction === 'createVisit' )">New Visit</span>
		<span class="panel-title" [hidden]="!(currentAction === 'createExam' )">New Exam</span>
	</div>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
	</div>
	<!-- Se create visit -->
	<div class="panel-body" *ngIf="currentAction === 'createVisit'">
		<form name="visitForm" class="container" #visitForm="ngForm">
			<div class="row">
				<div class="form-group col-6">
					<label for="patient_id">PATIENT ID</label>
					<input type="text" class="form-control" id="patient_id" readonly value="{{currentPatientId}}" />
				</div>
				<div class="form-group col-6">
					<label for="name">Visit Name</label>
					<input type="text" class="form-control" id="name" name="name" [(ngModel)]="demoVisit.name" required />
				</div>
				<div class="form-group col-6">
					<label for="device">DEVICE</label>
					<select name="device" class="form-select" id="device-model" [(ngModel)]="demoVisit.device" required>
						<option *ngFor="let model of userDevices" [value]="model">{{model}}</option>
						<!-- ['VX650', 'VX600', 'VX130', 'VX120', 'ER', 'VX40', 'DEM', 'NEXY'] -->
					</select>
				</div>

				<div class="form-group col-6">
					<label for="sn">Serial Number</label>
					<select name="sn" class="form-select" id="sn" [(ngModel)]="demoVisit.device_sn" required>
						<option *ngFor="let sn of userSN" [value]="sn">{{sn}}</option>
					</select>
				</div>
			</div>

			<div class="row">&nbsp;</div>
			<div class="col-12 mt-2">Upload xml on an existing visit:</div>

			<!-- upload xml su visit esistente  01.09.2020  -->
			<div class="row">
				<div class="form-group col-2">
					<label for="visit_id">VISIT_ID</label>
					<input type="text" class="form-control" id="visit_id" name="visit_id" [(ngModel)]="demoVisit.id" required />
				</div>
				<div class="form-group col-10">
					<label for="xml">XML</label>
					<input type="file" class="form-control" id="xml_blob" name="xml_blob" [(ngModel)]="demoVisit.xml_blob" accept=".xml" />
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-12 d-flex justify-content-around">
					<div class="text-end">
						<button type="button" class="btn btn-primary" [disabled]="visitForm.invalid" (click)="submit()">{{"BUTTONS.SUBMIT" | translate}}</button>
					</div>
					<div class="text-end">
						<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('undo');">{{'BUTTONS.UNDO' | translate}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>

	<!-- Se create visit -->

	<div class="panel-body" *ngIf="currentAction === 'createExam'">
		<div class="loading-container" [hidden]="!wait">
			<div class="tail-spin">&nbsp;</div>
		</div>

		<form name="examForm" #examForm="ngForm" class="container">
			<div class="row mt-3">
				<div class="form-group col-3">
					<label for="type">TYPE</label>
					<select name="type" [(ngModel)]="exam.exam_type" class="form-select" id="type" required (change)="onChangeType()">
						<!-- ['topo', 'pachymult', 'extphoto', 'dryeye', 'pachy', 'tono', 'wf', 'retro', 'fundus', 'subjective', 'lensmeter'], 'pupil' -->
						<option *ngFor="let exam of examsTypes" [value]="exam">{{exam}}</option>
					</select>
				</div>

				<div class="form-group col-3">
					<label for="eye">EYE</label>
					<select name="eye" [(ngModel)]="exam.eye" class="form-select" id="eye" required (change)="onChangeEye()">
						<option *ngFor="let eye of session.eyes" [value]="eye">{{eye}}</option>
					</select>
				</div>

				<div class="form-group col-3">
					<label for="idvisit">VISIT_ID</label>
					<!-- <input type="text" class="form-control" id="visit_id" name="visit_id" [(ngModel)]="" required /> -->
					<select name="idvisit" [(ngModel)]="exam.visit_id" class="form-select" id="idvisit" required>
						<option *ngFor="let id of visitsIds" [value]="id">{{id}}</option>
					</select>
				</div>

				<div class="form-group col-3">
					<label for="patient_id">PAT_ID</label>
					<input type="text" class="form-control" id="patient_id" name="patient_id" [(ngModel)]="exam.patient_id" readonly />
				</div>
			</div>

			<div class="row">
				<div class="form-group col-12">sample data will be filled automatically, just select images, if any.</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'topoDISAB'">
				<div class="form-group col-4">
					<label for="eccentricity">eccentricity</label>
					<input type="text" class="form-control" id="eccentricity" name="eccentricity" [(ngModel)]="exam.eccentricity" />
				</div>

				<div class="form-group col-4">
					<label for="kpi">KPI</label>
					<input type="text" class="form-control" id="kpi" name="kpi" [(ngModel)]="exam.KPI" />
				</div>

				<div class="form-group col-4">
					<label for="si">SI</label>
					<input type="text" class="form-control" id="si" name="si" [(ngModel)]="exam.SI" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'topoDISAB'">
				<div class="form-group full-container">
					K1_radius: <input type="text" size="5" id="K1_radius" name="K1_radius" [(ngModel)]="exam.K1_radius" /> K1_r_unit: <input type="text" size="5" id="K1_r_unit" name="K1_r_unit" [(ngModel)]="exam.K1_r_unit" /> K1_axis: <input type="text" size="5" id="K1_axis" name="K1_axis" [(ngModel)]="exam.K1_axis" /> K1_a_unit:
					<input type="text" size="5" id="K1_a_unit" name="K1_a_unit" [(ngModel)]="exam.K1_a_unit" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'topoDISAB'">
				<div class="form-group full-container">
					K2_radius: <input type="text" size="5" id="K2_radius" name="K2_radius" [(ngModel)]="exam.K2_radius" /> K2_r_unit: <input type="text" size="5" id="K2_r_unit" name="K2_r_unit" [(ngModel)]="exam.K2_r_unit" /> K2_axis: <input type="text" size="5" id="K2_axis" name="K2_axis" [(ngModel)]="exam.K2_axis" /> K2_a_unit:
					<input type="text" size="5" id="K2_a_unit" name="K2_a_unit" [(ngModel)]="exam.K2_a_unit" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'topoDISAB'">
				<div class="form-group full-container">
					CYL_power: <input type="text" size="5" id="CYL_power" name="CYL_power" [(ngModel)]="exam.CYL_power" /> CYL_p_unit: <input type="text" size="5" id="CYL_p_unit" name="CYL_p_unit" [(ngModel)]="exam.CYL_p_unit" /> CYL_axis: <input type="text" size="5" id="CYL_axis" name="CYL_axis" [(ngModel)]="exam.CYL_axis" /> CYL_a_unit:
					<input type="text" size="5" id="CYL_a_unit" name="CYL_a_unit" [(ngModel)]="exam.CYL_a_unit" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'dryeyeDISAB'">
				<div class="form-group full-container">
					first_breakup: <input type="text" size="5" id="first_breakup" name="first_breakup" [(ngModel)]="exam.first_breakup" /> average_breakup: <input type="text" size="5" id="average_breakup" name="average_breakup" [(ngModel)]="exam.average_breakup" /> percentage: <input type="text" size="5" id="percentage" name="percentage" [(ngModel)]="exam.percentage" /> tear_meniscus:
					<input type="text" size="5" id="tear_meniscus" name="tear_meniscus" [(ngModel)]="exam.tear_meniscus" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'pachyDISAB'">
				<div class="form-group full-container">
					central: <input type="text" size="5" id="central" name="central" [(ngModel)]="exam.central" /> nasal_angle: <input type="text" size="5" id="nasal_angle" name="nasal_angle" [(ngModel)]="exam.nasal_angle" /> temporal_angle:
					<input type="text" size="5" id="temporal_angle" name="temporal_angle" [(ngModel)]="exam.temporal_angle" />
				</div>
			</div>

			<div class="row">
				<div class="form-group col-6" *ngIf="exam.exam_type == 'pachymult' || exam.exam_type == 'dryeye' || exam.exam_type == 'pachy'">
					<label for="image">IMAGE</label>
					<input type="file" class="form-control" id="image" name="image" [(ngModel)]="exam.image" accept=".jpg,.jpeg,.png" />
				</div>

				<div class="form-group col-6" *ngIf="exam.exam_type == 'fast_refraction'">
					<label for="image_sh">IMAGE_SH</label>
					<input type="file" class="form-control" id="image_sh" name="image_sh" [(ngModel)]="exam.image_sh" accept=".jpg,.jpeg,.png" />
				</div>

				<div class="form-group col-6" *ngIf="exam.exam_type == 'topo'">
					<label for="axial_map">AXIAL MAP</label>
					<input type="file" class="form-control" id="axial_map" name="axial_map" [(ngModel)]="exam.axial_map" accept=".jpg,.jpeg,.png" />
				</div>

				<!-- <div class="col-6 d-flex align-items-center" *ngIf="exam.exam_type == 'topo'">
					<div class="form-check">
						<input type="checkbox" class="form-check-input" id="fake_exam_with_kisa" name="fake_exam_with_kisa" [(ngModel)]="exam.fake_exam_with_kisa" />
						<label class="form-check-label ms-4" for="fake_exam_with_kisa">WITH KISA</label>
					</div>
				</div> -->

				<div class="form-group col-6" *ngIf="exam.exam_type == 'pachy'">
					<label for="image_with_data">IMG with DATA</label>
					<input type="file" class="form-control" id="image_with_data" name="image_with_data" [(ngModel)]="exam.image_with_data" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<!-- ADDED FILEDS PACHY -->
			<div class="row" *ngIf="exam.exam_type == 'pachy'">
				<div class="col-2 d-flex align-items-center mt-3" *ngFor="let item of pachyOptionalValue | keyvalue">
					<div class="form-check">
						<input type="checkbox" class="form-check-input" id="{{item.key}}" [name]="item.key" [(ngModel)]="pachyOptionalValue[item.key].set" />
						<label class="form-check-label ms-4" for="fake_exam_with_kisa">{{ item.key }}</label>
					</div>
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'topo'">
				<div class="form-group col-6">
					<label for="tangential_map">TANG. MAP</label>
					<input type="file" class="form-control" id="tangential_map" name="tangential_map" [(ngModel)]="exam.tangential_map" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="elevation_map">ELEV. MAP</label>
					<input type="file" class="form-control" id="elevation_map" name="elevation_map" [(ngModel)]="exam.elevation_map" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<!-- ADDED FIELDS TOPO -->
			<div class="row" *ngIf="exam.exam_type == 'topo'">
				@for(item of topoOptionalValue | keyvalue; track item) { @if(topoOptionalValue[item.key].edit){
				<div class="col-2 d-flex align-items-center mt-3">
					<div class="form-check">
						<input type="checkbox" class="form-check-input" id="{{item.key}}" [name]="item.key" [(ngModel)]="topoOptionalValue[item.key].set" />
						<label class="form-check-label ms-4" for="fake_exam_with_kisa">{{ item.key }}</label>
					</div>
				</div>
				} }
			</div>

			<div class="row" *ngIf="exam.exam_type == 'extphoto' ">
				<div class="form-group col-6">
					<label for="image">IMAGE AUTOMATIC</label>
					<input type="file" class="form-control" id="image_auto" name="image_auto" [(ngModel)]="exam.image_auto" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="img1">IMAGE MANUAL 1</label>
					<input type="file" class="form-control" id="image_man_1" name="image_auto_1" [(ngModel)]="exam.image_man_1" accept=".jpg,.jpeg,.png" />
				</div>
			</div>
			<div class="row" *ngIf="exam.exam_type == 'extphoto' ">
				<div class="form-group col-6">
					<label for="img2">IMAGE MAN 2</label>
					<input type="file" class="form-control" id="image_man_2" name="image_auto_2" [(ngModel)]="exam.image_man_2" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="img3">IMAGE MAN 3</label>
					<input type="file" class="form-control" id="image_man_3" name="image_auto_3" [(ngModel)]="exam.image_man_3" accept=".jpg,.jpeg,.png" />
				</div>
			</div>
			<div class="row" *ngIf="exam.exam_type == 'extphoto' ">
				<div class="form-group col-6">
					<label for="img4">IMAGE MAN 4</label>
					<input type="file" class="form-control" id="image_man_4" name="image_auto_4" [(ngModel)]="exam.image_man_4" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="img5">IMAGE MAN 5</label>
					<input type="file" class="form-control" id="image_man_5" name="image_auto_5" [(ngModel)]="exam.image_man_5" accept=".jpg,.jpeg,.png" />
				</div>
			</div>
			<div class="row" *ngIf="exam.exam_type == 'extphoto' ">
				<div class="form-group col-6">
					<label for="img6">IMAGE MAN 6</label>
					<input type="file" class="form-control" id="image_man_6" name="image_auto_6" [(ngModel)]="exam.image_man_6" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'wf'">
				<div class="form-group col-6">
					<label for="image_grid">GRID</label>
					<input type="file" class="form-control" id="image_grid" name="image_grid" [(ngModel)]="exam.image_grid" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="image_meso">MESO</label>
					<input type="file" class="form-control" id="image_meso" name="image_meso" [(ngModel)]="exam.image_meso" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'retro'">
				<div class="form-group col-6">
					<label for="image_low">LOW</label>
					<input type="file" class="form-control" id="image_low" name="image_low" [(ngModel)]="exam.image_low" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="image_high">HIGH</label>
					<input type="file" class="form-control" id="image_high" name="image_high" [(ngModel)]="exam.image_high" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'fundus' ">
				<div class="form-group col-6">
					<label for="image">CENTRAL FIXATION</label>
					<input type="file" class="form-control" id="image_central" name="image_central" [(ngModel)]="exam.image_central" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label>CENTRAL-NASAL</label>
					<input type="file" class="form-control" id="image_central_nasal" name="image_central_nasal" [(ngModel)]="exam.image_central_nasal" accept=".jpg,.jpeg,.png" />
				</div>
			</div>
			<div class="row" *ngIf="exam.exam_type == 'fundus' ">
				<div class="form-group col-6">
					<label for="img2">EXTERNAL</label>
					<input type="file" class="form-control" id="image_external" name="image_external" [(ngModel)]="exam.image_external" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label for="img3">INFERIOR</label>
					<input type="file" class="form-control" id="image_inferior" name="image_inferior" [(ngModel)]="exam.image_inferior" accept=".jpg,.jpeg,.png" />
				</div>
			</div>
			<div class="row" *ngIf="exam.exam_type == 'fundus' ">
				<div class="form-group col-6">
					<label>NASAL</label>
					<input type="file" class="form-control" id="image_nasal" name="image_nasal" [(ngModel)]="exam.image_nasal" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label>SUPERO-NASAL</label>
					<input type="file" class="form-control" id="image_supero_nasal" name="image_supero_nasal" [(ngModel)]="exam.image_supero_nasal" accept=".jpg,.jpeg,.png" />
				</div>
			</div>
			<div class="row" *ngIf="exam.exam_type == 'fundus' ">
				<div class="form-group col-6">
					<label>SUPERO-TEMPORAL</label>
					<input type="file" class="form-control" id="image_supero_temporal" name="image_supero_temporal" [(ngModel)]="exam.image_supero_temporal" accept=".jpg,.jpeg,.png" />
				</div>
				<div class="form-group col-6">
					<label>TEMPORAL</label>
					<input type="file" class="form-control" id="image_temporal" name="image_temporal" [(ngModel)]="exam.image_temporal" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<div class="row" *ngIf="exam.exam_type == 'pupil'">
				<div class="form-group col-6">
					<label for="graph">GRAPH</label>
					<input type="file" class="form-control" id="graph" name="graph" [(ngModel)]="exam.graph" accept=".jpg,.jpeg,.png" />
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-8"></div>

				<div class="col-4">
					<div class="row">
						<div class="col-6 text-end">
							<button type="submit" class="btn btn-primary" [disabled]="examForm.invalid" (click)="submit()">{{"BUTTONS.SUBMIT" | translate}}</button>
						</div>
						<div class="col-6 text-end">
							<button type="clear" class="btn btn-secondary ms-3" (click)="activeModal.dismiss('undo');">{{'BUTTONS.UNDO' | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
