import { Address } from './address.model'
import { DateParser } from './dateParser.model'

import { Util, DotReport } from './util.model'
import { Agreement, AgreementInfo } from './agreement.model'
import { Anamnesis, VA } from './anamnesis.model'
import { CryptoUtilsService } from '../service/crypto-utils.service'

export interface PatientsResponse {
	patients: PatientJson[]
}

export interface PatientResponse {
	patient: PatientJson
}

export class TabsPatientModal {
	tab_id: number
	state: boolean
	complete: boolean
	description: string

	constructor(id, state, complete, desc) {
		this.tab_id = id
		this.state = state
		this.complete = complete
		this.description = desc
	}
}

export class Credentials {
	username: string
	password: string
	email: string
	tel_number: number
	send: boolean

	constructor(rawObj?) {
		this.username = ''
		this.password = ''
		this.email = ''
		this.tel_number = null
		this.send = false

		if (rawObj) {
			// copia tutti i campi con lo stesso nome
			let myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}

export class PatientJson {
	patient_id: number
	pending_id: number
	personal_id: string
	code: string
	firstname: string
	lastname: string
	sex: number
	race: number
	birthday_date: string // arriva crittata
	birthday_year: number
	subscription_time: string
	addresses: Address[] // 21.06.2019 miglioria codice --ls
	last_update: string
	is_valid: string
	has_report: string // 03.03.2017
	is_deleted: string
	last_visit: string // 22.11.2021

	agreement: AgreementInfo
	anamnesis: Anamnesis[]

	exm_to_review: number // 02.12.2021
	exams_to_review: number // 07.04.2022 piu' chiaro x i clients, TODO

	entry_type: string // 10.05.2022
	dicom_id: string // 10.05.2022

	internal_code: string // es cod. fiscale o num. di previdenza sociale
	//personal_data: string;

	created_by: number
	tot_invisible_visit: number

	tot_reports: number // 06.05.2022
	tot_new_report: number // 07.02.2022

	tot_aireports: number // 26.08.2022
	tot_new_aireports: number // 26.08.2022

	// 16.06.2023
	last_grading_request: string
	last_grading_done: string
	last_visit_id: number
	grader_id: number // 16.06.2023 the grader who did the report at last_grading_done time

	unique_hash: string // 16.02.2023
	customer_uuid: string

	constructor() {}
}

export interface patientList {
	patients: Patient[]
}

export class Patient {
	static idGen = 0 // ??
	id: number // 16.09.2022 era stringa, valutare benefici ?
	pending_id: number // 16.09.2022 era stringa, valutare benefici ?
	personal_id: string
	name: string // 27.09.2022 era nome + cognome, girato in cognome + nome [richiesta piloti italiani]
	firstName: string
	lastName: string

	assigned_to: number
	//birthDate: Date;
	birthDate: string // we convey that format for DOB is yyyy-MM-dd .
	birthYear: number
	lastExamDate: Date
	code: string
	sex: number // sul DB e' un numero, poi decodifica con lookup
	race: number

	// 23.03.2020 usare struttura
	addresses: Address[]
	//address: Address;

	internal_code: string //  28.09.2017
	entry_type: string // DicomEntry, FullEntry
	origin

	dicom_id: string // 10.05.2022

	agreement: AgreementInfo //14-02-23
	anamnesis: Anamnesis[] //16-02-23
	va: VA //25-08-23

	impact_enabled: boolean

	// notUsed yet on nexus:
	//user_anamnesis: UsrAnamnesis[];
	//user_dicom: UsrDicom;  // 28.09.2017
	//personal_data: string; // 02.03.2018 del medico, crittate
	//ref_visible: string; // 06.07.2018 {Y,N}
	//tot_visible_photo: number;  // 13.07.2018

	//new for clinical 09.06.23
	request_date: Date
	due_date: Date
	lastReportDate: Date
	grader_id: number // 16.06.2023 the grader who did the report at last_grading_done time
	grader: string

	created_by: number // 08.02.2021
	operator: string //01.06.2023 add for new patienlist
	subscription_time: Date // 08.04.2022

	// deve diventare un semaforo: new report, pending = in attesa, o tutto fermo
	//has_report: string;  // viene composto in base ai report nuovi, TODO
	exams_to_review: number // 20.10.2020 non solo per gli admins
	tot_invisible_visit: number

	tot_new_report: number
	tot_reports: number
	tot_aireports: number
	tot_new_aireports: number

	totalReports: number

	tot_reports_done: number //only for specialist, rappresenta il numero dei suoi report su quel paziente, usato per far vedere o meno l'inoa report

	// tot_diagonal_reports: number // 13.09.2022
	// tot_diagonal_records: number

	unique_hash: string // 16.02.2023 questo serve solo su json in uscita ?
	customer_uuid: string // 16.02.2023 potrebbe servire esporlo?

	isDeleted: boolean // 07.02.2022

	public statusHG: number
	public statusAI: number
	public statusDP: number

	public lastUpdated: Date // 16.09.2022

	// 10.05.2022 vd enum su DB, campo entry_type
	static TYPE_FULL = 'FullEntry'
	static TYPE_DICOM = 'DicomEntry'
	static TYPE_FULLDICOM = 'FullDicomEntry'

	// origin from db
	static ENTRY_SELF = 'SelfReg'
	static ENTRY_WEB = 'web'
	static ENTRY_DEVICE = 'device'

	// prende la parte di dati in chiaro
	constructor(rawPatient?: PatientJson) {
		this.id = 0 // "0";  // 03.04.2017 --ls
		this.pending_id = 0
		this.personal_id = ''

		this.firstName = ''
		this.lastName = ''

		this.assigned_to = 0

		this.addresses = []
		this.dicom_id = ''
		this.isDeleted = false

		this.operator = ''
		this.grader = ''
		this.exams_to_review = 0
		this.tot_invisible_visit = 0

		this.agreement = new AgreementInfo()
		this.anamnesis = []
		this.va = new VA()

		//HG
		this.tot_reports = 0
		this.tot_new_report = 0

		//AI
		this.tot_aireports = 0
		this.tot_new_aireports = 0

		this.totalReports = 0

		this.tot_reports_done = 0

		this.statusHG = 0
		this.statusAI = 0
		this.statusDP = 0

		this.lastUpdated = null

		this.internal_code = ''

		if (rawPatient != null) {
			// console.log(rawPatient)
			// 23.08.2022 intanto prende tutti i campi con lo stesso nome
			var myJsonObj = { ...rawPatient }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (!rawPatient.internal_code) {
				this.internal_code = ''
			}

			// sistemo i campi con nomi diversi e tutte le date
			this.id = rawPatient.patient_id
			this.pending_id = rawPatient.patient_id
			this.personal_id = rawPatient.personal_id

			this.operator = this.created_by.toString()

			if (this.grader_id) {
				this.grader = this.grader_id.toString()
			}

			//Util.debug('(createPatient) id ' + this.id + ' AI rep: ' + this.tot_aireports)

			// intanto partiamo con il code, per chi non vede il nome --ls
			//result.name = "PATIENT_" + rawPatient.code;
			this.name = 'CLIENT_' + rawPatient.patient_id

			// 16.06.2023 sistemiamo tutte le date

			if (rawPatient.last_visit) {
				// 04.11.2022 patch
				this.lastExamDate = DateParser.parseDate(rawPatient.last_visit)
			} else {
				this.lastExamDate = null
			}

			if (rawPatient.last_grading_request) {
				this.request_date = DateParser.parseDate(rawPatient.last_grading_request)

				//this.due_date = new Date();
				//this.due_date.setDate(this.request_date.getDate() + 2)  // solo giorni, ignora le ore?

				this.due_date = new Date(this.request_date.getTime() + 48 * 60 * 60 * 1000)
			} else {
				this.request_date = null
				this.due_date = null
			}

			if (rawPatient.last_grading_done) {
				this.lastReportDate = DateParser.parseDate(rawPatient.last_grading_done)
			} else {
				this.lastReportDate = null
			}

			//console.log("(createPatient) id "+result.id+" last visit:"+result.lastExamDate+" da DB: "+rawPatient.last_visit);

			this.birthYear = rawPatient.birthday_year // 22.02.2017 e' in chiaro --ls

			// result.code = rawPatient.code;
			if (rawPatient.is_deleted == 'Y') {
				// 29.03.2017
				//result.code = "<font color='red'>DEL_</font>" + rawPatient.code;  // KO
				this.code = 'DEL_' + rawPatient.code
				this.isDeleted = true
			} else {
				this.code = rawPatient.code
				this.isDeleted = false
			}

			// 08.04.2022
			this.subscription_time = DateParser.parseDate(rawPatient.subscription_time)
			//Util.debug("(createPatient) id "+this.id+" subscript_time: "+this.subscription_time);   // byAdmin non c'e', sulla lista

			if (rawPatient.last_update) {
				this.lastUpdated = DateParser.parseDate(rawPatient.last_update)
			}

			// 16.04.2019 tolta trace [ls]
			//console.log("(createPatient) id "+result.id+" tot_visible_photo "+result.tot_visible_photo);

			// TODO user_anamnesis

			// 02.12.2021 riattivato anche per lev1
			if (rawPatient.exm_to_review) {
				// dalla vista
				this.exams_to_review = rawPatient.exm_to_review
			} else if (rawPatient.exams_to_review) {
				// nome piu' chiaro x devices
				this.exams_to_review = rawPatient.exams_to_review
			} else {
				this.exams_to_review = 0
			}

			// 20.10.2020 patch per distinguere no_esami da tutti_esitati
			if (!rawPatient.last_visit) {
				this.exams_to_review = -1
			}

			if (rawPatient.tot_reports) {
				this.totalReports = this.totalReports + rawPatient.tot_reports
			}

			if (rawPatient.tot_aireports) {
				this.totalReports = this.totalReports + rawPatient.tot_aireports
			}
		}
	}

	// richiamata sia dal doctor che dal distrib, nel secondo caso i campi sensibili non arrivano proprio
	static createPatient(rawPatient: PatientJson, cryptoUtils: CryptoUtilsService, keyDoctor) {
		// console.log(rawPatient)

		var result = new Patient(rawPatient) // salva i dati in chiaro

		//Util.debug("(createPatient) code: "+result.code);  // ok

		if (!keyDoctor) {
			//console.log("(createPatient) keyDoctor mancante");
			//return cryptoUtils.q.when(result);
			return Promise.resolve(result)

			//} else {
		} else if (rawPatient.firstname) {
			// se distrib, il dato manca --ls
			//console.log("(createPatient) ok keyDoctor ");

			var bag = cryptoUtils.generateBag()

			bag['firstname'] = rawPatient.firstname
			bag['lastname'] = rawPatient.lastname
			bag['birthday_date'] = rawPatient.birthday_date
			bag['internal_code'] = rawPatient.internal_code
			bag['personal_id'] = rawPatient.personal_id
			//bag.personal_data = rawPatient.personal_data;

			if (rawPatient.addresses && rawPatient.addresses.length > 0) {
				var mainAddr = rawPatient.addresses[0]

				//bag.address_label = 1; // fisso
				bag['address_line1'] = mainAddr.address_line1
				bag['city'] = mainAddr.city
				bag['country'] = mainAddr.country
				bag['phone1'] = mainAddr.phone1
				bag['ref_email'] = mainAddr.ref_email
			} else {
				Util.debug('(createPatient) missing address for id ' + result.id)
			}

			// 29.09.2017 aggiunti i campi DICOM per il decrypt
			/*
            if (rawPatient.user_dicom != null) {
                bag.patient_id = rawPatient.user_dicom.patient_id;
                bag.patient_address = rawPatient.user_dicom.patient_address;
                bag.patient_birth_date = rawPatient.user_dicom.patient_birth_date;
                bag.patient_telephone_number = rawPatient.user_dicom.patient_telephone_number;
                bag.patient_telecom_information = rawPatient.user_dicom.patient_telecom_information;
            }
            */

			// 09.05.2022
			if (rawPatient.dicom_id != null) {
				bag['dicom_id'] = rawPatient.dicom_id
			}

			cryptoUtils.purge(bag)

			//console.log("(createPatient) inizio decrypt bag, nome cri: "+bag.firstname);

			// 20.03.2020 usa alias piu' chiaro

			return (
				cryptoUtils
					.decryptDataWithKey(keyDoctor, bag)
					//return cryptoUtils.decryptFromBase64ToBase64Content(keyDoctor, bag)

					.then((bagClear) => {
						result.firstName = bagClear['firstname']
						result.lastName = bagClear['lastname']
						result.personal_id = bagClear['personal_id']

						// 27.09.2022 prima il cognome
						//result.name = Util.getFullName(result.firstName, result.lastName);
						result.name = result.lastName + ' ' + result.firstName

						// 04.11.2022 patch per VX650, arriva come YYYY/MM/DD
						//result.birthDate = bagClear['birthday_date'] // dovrebbe essere una stringa yyyy-MM-dd
						let dob = bagClear['birthday_date'] // dovrebbe essere una stringa yyyy-MM-dd
						//console.log("(createPatient) id:"+result.id + " DOB: "+dob); // 04.11.2022
						if (dob.indexOf('/') > 0) {
							dob = Util.replaceAll(dob, '/', '-')
						}

						// 13.02.2023 patch per VX650 in germania - vd bug su mantis #360
						if (dob.indexOf('.') > 0) {
							dob = Util.replaceAll(dob, '.', '-')
						}

						// NB: non convertiamo in oggetto data, rimane una stringa!
						//console.log("(createPatient) id:"+result.id + " fixed DOB: "+dob);
						result.birthDate = dob

						// 29.11.2021 cfr con bYear
						if (new Date(result.birthDate).getFullYear() != result.birthYear) {
							console.log('(createPatient) conflict on DOB for pat id:' + result.id + ' year: ' + result.birthYear + ' full: ' + result.birthDate)
						}

						result.addresses = []

						//if(bagClear['address_label){  // ko ??
						if (rawPatient.addresses && rawPatient.addresses.length > 0) {
							var mainAddr = new Address()

							mainAddr.address_label = 1 // bagClear['address_label;
							mainAddr.city = bagClear['city']
							mainAddr.address_line1 = bagClear['address_line1']
							mainAddr.province = bagClear['province']
							mainAddr.country = bagClear['country']
							mainAddr.phone1 = bagClear['phone1']
							mainAddr.ref_email = bagClear['ref_email']

							result.setMainAddress(mainAddr)
						}

						//console.log('(createPatient) id:' + result.id+" city: "+ mainAddr.city)

						if (bagClear['internal_code'] || bagClear['internal_code'] == '') {
							result.internal_code = bagClear['internal_code']
						}
						// 09.05.2022
						if (bagClear['dicom_id']) {
							result.dicom_id = bagClear['dicom_id']
						}

						return result
					})
					.catch(() => {
						//try to fetch values singularly to fill only the correct values...

						console.log('(createPatient) ko decryptBag ' + result.id)

						// 06.11.2018 lo ritorno lo stesso, senza campi decrittati ? --ls
						// in questo caso bisogna farlo readonly
						return result
					})
			)
		} //else {
		//console.log("(createPatient) else finale ?!");  // 16.11.2020 per refertatori, sul singolo
		//}

		//return result;  // 17.01.2022
		return Promise.resolve(result)
	}

	// 16.09.2022 sposto su dataModel.service
	static OLDcreatePatientList(response: PatientsResponse, cryptoUtils, keyDoctor): Promise<Patient[]> {
		//
		var result = []
		var i = 0

		//if(response && response.data && response.data.patients){
		if (response != null) {
			//var myList = response.data.patients;
			var myList = response.patients // 22.11.2021
			for (i = 0; i < myList.length; i++) {
				var pat = myList[i]
				result.push(Patient.createPatient(pat, cryptoUtils, keyDoctor))
			}
		}
		console.log('(createPatientList) ok tot ' + i)
		//return cryptoUtils.q.all(result);
		return Promise.all(result)
	}

	// 23.03.2020
	public getMainAddress(): Address {
		if (this.addresses) return this.addresses[0]
		else return null
	}

	public setMainAddress(addr: Address) {
		if (!this.addresses) this.addresses = []

		if (this.addresses) {
			// 28.08.2023 patch, meglio forzare la posizione 0 ?[ls]
			//this.addresses.push(addr);
			this.addresses[0] = addr
		}
	}

	// 29.11.2021
	public setDOB(dob: string, year?: number) {
		if (dob != null && dob.length > 0) {
			this.birthDate = dob
		}
		if (year != null && year > 1900) {
			this.birthYear = year
		}
	}

	// 27.09.2022 prima il cognome, nelle liste. qui ?
	public getFullName() {
		return this.firstName + ' ' + this.lastName
		//return this.name   // questo ha prima il cognome
	}

	// 07.02.2022
	public hasNewReports() {
		return this.tot_new_report > 0
	}
	// vd metodo getPatDotReport, che ritorna un oggetto con class e tooltip, ma necessita del translat

	// 06.05.2022 HG, sia nuovi che non
	public hasReports() {
		return this.tot_reports > 0
	}

	// HG
	public hasPendingReports() {
		return this.exams_to_review > 0
	}

	// 26.08.2022 per reports AI
	public hasNewAiReports() {
		return this.tot_new_aireports > 0
	}

	// AI, sia nuovi che non
	public hasAiReports() {
		return this.tot_aireports > 0
	}

	// 13.09.2022 diagonal reports, completati (status=100) sia nuovi che non
	// public hasFullDiagonalReports() {
	// 	return this.tot_diagonal_reports > 0
	// }

	// // anche non completi, qls record
	// public hasDiagonalRecords() {
	// 	return this.tot_diagonal_records > 0
	// }

	// 07.02.2022 se ci sono visite con esami fatti ma non visibili al ref
	public canAskReview() {
		return this.tot_invisible_visit > 0
	}

	// 09.05.2022
	public isReadOnly() {
		return this.entry_type == Patient.TYPE_DICOM
	}

	// 09.05.2022
	isDicom() {
		return this.entry_type == Patient.TYPE_DICOM || this.entry_type == Patient.TYPE_FULLDICOM
	}

	// 15.07.2022 serve formato YYYYMMDD, noi abbiamo yyyy-MM-dd
	getDobForAi() {
		//let ret = this.birthDate.replaceAll('-', '');
		let re = /\-/gi
		let ret = this.birthDate.replace(re, '')
		return ret
	}

	// 15.07.2022
	getGenderForAi() {
		let val = ['-', 'M', 'F', '']
		let ret = ''

		if (this.sex < val.length) ret = val[this.sex]

		return ret
	}
}

export interface PendingPatientsResponse {
	patients: PendingPatient[]
}

export interface PendingPatient {
	country: string
	creation_date: string
	id: number
	owner_id: number
	priv_data: string
	sex: number
	unique_hash: string
	uuid: string
}

export interface privDataDecryptedT {
	firstname: string
	lastname: string
	birthdate: string
	email: string
	personalid: string
	phonenumber: string
}
