import { Config } from 'src/config'
import { DateParser } from './dateParser.model'
import { Prescription, SrvDiagnosis } from './diagnosis.model'
import { CsvLine } from './csvLine.model'

export enum ReportStatus {
	STATUS_UNKNOWN,
	STATUS_NEW,
	STATUS_READ,
}

export class Report {
	id: number
	patient_id: number
	creation_date: Date
	created_by: number
	optician_id: number
	optician_name: string
	report_type: string

	status: number
	refDisplayName: string //  05.06.2020
	sections: string[] // 22.10.2020

	diagn_group: number // 08.04.2022 serve per decodificare le descrizioni degli icd

	//szSections: string;  // 16.02.2021

	// // 12.06.2020 TODO usi futuri, es downloaded by patient [ls]
	// static STATUS_NEW = 1
	// static STATUS_READ = 2

	static statusDescr = [
		'-', // 0
		'new', // 1
		'downloaded', // 2
	]

	follow_up_months: number
	follow_up_notes: string

	is_waiting_PDF: boolean

	constructor(myObj?) {
		this.id = 0
		this.patient_id = 0
		this.optician_id = 0
		this.optician_name = ''
		this.report_type = ''
		this.creation_date = null
		this.created_by = 0
		this.refDisplayName = ''
		this.status = 0
		this.follow_up_months = 0
		this.follow_up_notes = ''

		this.is_waiting_PDF = false
		this.diagn_group = 0

		if (myObj != null) {
			// console.log(myObj)
			this.id = myObj.id
			this.patient_id = myObj.patient_id
			this.follow_up_notes = myObj.follow_up_notes

			if (myObj.creation_date != null) {
				//this.creation_date = myObj.creation_date; // come stringa
				this.creation_date = DateParser.parseDate(myObj.creation_date)
			}

			if (myObj.follow_up_months != null) {
				this.follow_up_months = myObj.follow_up_months
			}

			if (myObj.created_by != null) this.created_by = myObj.created_by

			if (myObj.optician_id != null) this.optician_id = myObj.optician_id

			if (myObj.report_type != null) this.report_type = myObj.report_type

			if (myObj.status != null) {
				if (myObj.status == '1') {
					this.status = ReportStatus.STATUS_NEW
				} else if (myObj.status == '2') {
					this.status = ReportStatus.STATUS_READ
				} // todo usi futuri
			}

			// 22.10.2020 TODO migliorare il formato della response
			if (myObj.diagnosis) {
				this.sections = []
				for (var i = 0; i < myObj.diagnosis.length; i++) {
					var categ = myObj.diagnosis[i]
					this.sections.push('' + categ.category)
				}
				//console.log("report "+this.id+" sections: ");
				//console.log(this.sections);
			}

			// 08.04.2022
			if (myObj.diagn_group) {
				this.diagn_group = myObj.diagn_group
			}

			//this.szSections = this.getSections();  // 16.12.2021
		}
	}

	public getStatusDescr() {
		var descr = ''
		if (this.status < Report.statusDescr.length) {
			descr = Report.statusDescr[this.status]
		} else {
			descr = '' + this.status
		}
		return descr
	}

	public static getSzStatus(status) {
		var descr = ''
		if (status < Report.statusDescr.length) {
			descr = Report.statusDescr[status]
		} else {
			descr = '' + status
		}
		return descr
	}

	// 22.10.2020
	public getSections(): string {
		var ret = ''

		// TODO, rimappare nomi categories nelle varie lingue, ma qui non ho translator...

		if (this.sections != null) {
			for (var i = 0; i < this.sections.length; i++) {
				ret += this.sections[i] + '; '
			}
		}
		return ret
	}

	// 16.11.2020 per il nome pdf, senza spazi
	getSectionsList() {
		var ret = ''
		if (this.sections != null) {
			for (var i = 0; i < this.sections.length; i++) {
				ret += this.sections[i] + '_'
			}
		}
		return ret
	}

	// 02.05.2022
	getDiagnGroupDescr() {
		let ret = '-'
		if (this.diagn_group && this.diagn_group < Config.DIAGN_GROUPS.length) {
			ret = Config.DIAGN_GROUPS[this.diagn_group]
		}
		return ret
	}

	// 19.01.2022 - 09.08.2022
	basicInfo() {
		let ret = ''

		if (this.id > 0) {
			ret =
				'repId: ' +
				this.id +
				CsvLine.SEP +
				' referrerId: ' +
				this.created_by +
				CsvLine.SEP +
				' patId: ' +
				this.patient_id +
				CsvLine.SEP +
				' creationDate: ' +
				this.creation_date.toISOString()
			// toISO:   2022-10-05T14:48:00.000Z
			// toString() troppo lungo, es: Thu Aug 04 2022 12:40:53 GMT+0200 (Ora legale dell’Europa centrale)
		}
		return ret
	}
}

// 01.06.2020  per singolo
export class FullReport extends Report {
	// sulla super ci sono gli altri campi
	// 01.06.2020 array di al max 5, una per ciascuna categoria
	//diagnosis: CategDiagnosis[];
	diagnosis: SrvDiagnosis[]
	prescriptions: Prescription[] // 26.10.2020 solo per refraction

	constructor(myObj?) {
		super(myObj)

		if (myObj.diagnosis) {
			this.diagnosis = []
		}

		// 26.10.2020
		if (myObj.prescriptions) {
			this.prescriptions = []
			for (var i = 0; i < myObj.prescriptions.length; i++) {
				var myRx = myObj.prescriptions[i] // myRx e' ancora un oggetto json
				if (myRx != null) {
					var finalP = new Prescription(myRx)
					this.prescriptions.push(finalP)
				}
			}
		}
	}

	// 23.09.2021
	hasRxFinal2() {
		var final2RxL = this.getPrescription('left', 2)
		var final2RxR = this.getPrescription('right', 2)
		var ret = final2RxL != null || final2RxR != null
		return ret
	}

	// 26.10.2020 solo per refraction
	getPrescription(eye, num) {
		var myFinalRx = null

		if (!this.prescriptions) {
			return null
		}

		var doneFirst = false
		for (var i = 0; i < this.prescriptions.length; i++) {
			var myRx = this.prescriptions[i]

			if (myRx.eye == eye) {
				if (num == 2 && doneFirst) {
					myFinalRx = myRx // ok questa
					break
				} else if (num == 1) {
					myFinalRx = myRx // ok questa
					break
				} else {
					// trovato il primo, continua x cercare il secondo
					doneFirst = true
				}
			}
		}

		return myFinalRx
	}

	setDiagnCategories(categDiagn) {
		this.diagnosis = categDiagn
	}

	getDiagnosis(categName): SrvDiagnosis {
		var ret = null
		if (this.diagnosis != null) {
			for (var i = 0; i < this.diagnosis.length; i++) {
				if (this.diagnosis[i].category == categName) {
					ret = this.diagnosis[i]
					break
				}
			}
		}
		return ret
	}

	getDetails(categName): string {
		var ret = ''
		var diag = this.getDiagnosis(categName)
		if (diag != null) {
			ret = diag.details
		}
		return ret
	}

	getTreatment(categName): string {
		var ret = ''
		var diag = this.getDiagnosis(categName)
		if (diag != null) {
			ret = diag.treatment
		}
		return ret
	}
}
