import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Amplify, ResourcesConfig } from 'aws-amplify'
import { withIdentityPoolId } from '@aws/amazon-location-utilities-auth-helper'
import * as maplibregl from 'maplibre-gl'
// import { createMap } from 'maplibre-gl-js-amplify'
import { BehaviorSubject, Subject, Subscription } from 'rxjs'
import { location, mapConfig } from 'src/app/models/map.model'
import { Util } from 'src/app/models/util.model'
import { MapService } from 'src/app/service/map.service'
import { SessionService } from 'src/app/service/session.service'

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
	getElementsSubscription: Subscription
	@Input() getElementsToDisplay: BehaviorSubject<
		{
			latitude: number
			longitude: number
			description: string
			color: string
			id: number
		}[]
	> //il componente che richiama la mappa, deve passargli la lista con un subject

	selectMarkerSubscription: Subscription
	@Input() selectMarker: Subject<{ marker: maplibregl.Marker; id: number }> //il componente che richiama la mappa, deve passargli il marker con un subject

	@Output() sendMarkers: EventEmitter<location[]>

	selectedLocations: location[]
	// searchOptions: searchOptions
	isLoadingMap: boolean

	coordinates: { longitude: number; latitude: number }[]
	locations: location[]

	mapOptions = {
		container: 'map', // id del div	in cui mettere la mappa
		region: '',
	}

	amplyConfig: ResourcesConfig = {
		Auth: {
			Cognito: {
				identityPoolId: '',
				allowGuestAccess: true,
			},
		},
		Geo: {
			LocationService: {
				region: '',
				maps: {
					items: {},
					default: '',
				},
				searchIndices: {
					//nel tipo LocationServiceConfig é searchIndices
					items: [],
					default: '',
				},
			},
		},
	}

	map: maplibregl.Map

	constructor(public session: SessionService, private mapService: MapService, private translator: TranslateService) {
		Util.debug('MapElement - Constructor')

		this.sendMarkers = new EventEmitter(null)

		this.isLoadingMap = true
		this.locations = []
		this.coordinates = []
		this.selectedLocations = []

		let token = this.session.user.token
		this.mapService
			.getAmplifyConfiguration(token)
			.then((res: mapConfig) => {
				this.mapOptions.region = res.mapConfig.region
				this.amplyConfig.Geo.LocationService.region = res.mapConfig.region
				this.amplyConfig.Auth.Cognito.identityPoolId = res.mapConfig.identityPoolId
				this.amplyConfig.Geo.LocationService.maps.items[res.mapConfig.mapName] = {}
				this.amplyConfig.Geo.LocationService.maps.default = res.mapConfig.mapName
				this.amplyConfig.Geo.LocationService.searchIndices.items = [res.mapConfig.indexItems]
				this.amplyConfig.Geo.LocationService.searchIndices.default = res.mapConfig.defaultIndex

				// console.log(this.country)

				// this.searchOptions = new searchOptions({ latitude: 0, longitude: 0 }, this.country, res.mapConfig.indexItems)

				Amplify.configure(this.amplyConfig)

				this.loadMap()
					.then(() => {
						this.getElementsSubscription = this.getElementsToDisplay.subscribe((elements) => {
							// console.log(elements)
							this.removeAllMarers().then(() => {
								this.selectedLocations = this.createLocations(elements)

								if (this.selectedLocations.length > 0) {
									if (this.map && this.map.loaded()) {
										this.addLocationsToMap(this.selectedLocations)
									} else {
										this.loadMap().then(() => {
											this.addLocationsToMap(this.selectedLocations)
										})
									}
								}
							})
						})
					})
					.catch((err) => {
						console.log(err)
						this.session.infoBridgeAvailable = false
						this.isLoadingMap = false
					})
			})
			.catch((err) => {
				console.log(err)
				this.session.infoBridgeAvailable = false
				this.isLoadingMap = false
			})
	}

	ngOnInit(): void {
		this.selectMarkerSubscription = this.selectMarker.subscribe((data) => {
			// console.log(data)
			if (data && data.marker) {
				let location = this.selectedLocations.find((loc) => {
					return loc.id === data.id
				})

				// console.log(location)

				if (location.selected) {
					return
				} else {
					this.deselectMarkers().then(() => {
						if (!location.marker.getPopup().isOpen()) {
							location.marker.togglePopup()
						}
						let lngLat = location.marker.getLngLat()
						location.selected = true
						this.flyToLocation(lngLat.lng, lngLat.lat, 10)
					})
				}
			} else {
				this.deselectMarkers()
				this.centerMap()
			}
		})
	}

	private addLocationsToMap(locations: location[]) {
		for (let loc of locations) {
			loc.marker.addTo(this.map)
		}
		this.sendMarkers.emit(locations)
		this.centerMap()
	}

	private loadMap(): Promise<void> {
		Util.debug('MapElement - loadMap ')

		const promise = new Promise<void>(async (resolve, reject) => {
			try {
				const authHelper = await withIdentityPoolId(this.amplyConfig.Auth.Cognito.identityPoolId)
				const region = this.amplyConfig.Geo.LocationService.region
				const mapName = this.amplyConfig.Geo.LocationService.maps.default

				this.map = new maplibregl.Map({
					container: this.mapOptions.container,
					style: `https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor`,
					...authHelper.getMapAuthenticationOptions(),
				})

				this.map.on('load', () => {
					Util.debug('MapElement - Map loaded')

					this.isLoadingMap = false
					this.map.addControl(new maplibregl.NavigationControl())

					resolve()
				})
			} catch (err) {
				console.log(err)
				reject(err)
			}

			// createMap(this.mapOptions)
			// 	.then((map: maplibregl.Map) => {
			// 		let mappa: maplibregl.Map = map
			// 		this.map = mappa

			// 		this.map.on('load', () => {
			// 			Util.debug('LocationElement - Map loaded')

			// 			this.isLoadingMap = false
			// 			this.map.addControl(new maplibregl.NavigationControl())

			// 			resolve()
			// 		})
			// 	})
			// 	.catch((err) => {
			// 		console.log(err)
			// 		reject()
			// 	})
		})

		return promise
	}

	private createLocations(elements: { latitude: number; longitude: number; description: string; color: string; id: number }[]): location[] {
		let locations: location[] = []

		if (elements) {
			for (let elem of elements) {
				let loc: location = new location()

				loc.place.label = elem.description
				let mark = this.createMarker(elem.longitude, elem.latitude, loc.place.label)
				loc.marker = mark
				loc.label = elem.description
				loc.id = elem.id

				locations.push(loc)
			}
		}
		return locations
	}

	private createMarker(longitude: number, latitude: number, label: string): maplibregl.Marker {
		let mark: maplibregl.Marker = new maplibregl.Marker({
			color: '#ff2424',
		})

		mark.setLngLat([longitude, latitude])

		let popUpOptions: maplibregl.PopupOptions
		popUpOptions = { closeButton: false }

		mark.setPopup(
			new maplibregl.Popup(popUpOptions)
				.setHTML(label)
				.on('close', () => {
					//il close non funziona bene, viene sempre chiamato anche all'open
					// Util.debug('popup was closed')
					this.deselectMarkers()
				})
				.on('open', () => {
					// Util.debug('popup was opened')
				})
		)

		return mark
	}

	private centerMap() {
		if (this.selectedLocations.length == 1) {
			let lngLat = this.selectedLocations[0].marker.getLngLat()
			this.flyToLocation(lngLat.lng, lngLat.lat, 12)
		} else {
			// creo un array di coordinate di tutte le cliniche
			// non posso usare lng e lat dell'address in quanto le location appena aggiunte non hanno le coordinate salvate nell'address
			let coordinates = this.selectedLocations.map((el) => [el.marker.getLngLat().lng, el.marker.getLngLat().lat])

			// let centroid = coordinates.reduce((x, y) => [x[0] + y[0] / coordinates.length, x[1] + y[1] / coordinates.length], [0, 0])
			// this.map.setCenter(centroid as [number, number])

			//centro la mappa in base ai marker inseriti
			let bounds = coordinates.reduce(
				(bounds, coord) => bounds.extend(coord as [number, number]),
				new maplibregl.LngLatBounds(coordinates[0] as [number, number], coordinates[0] as [number, number])
			)
			// Adatta la mappa ai marker inseriti mantenendo almeno un padding all'esterno sui padding
			this.map.fitBounds(bounds, { padding: 100 })
		}
	}

	private flyToLocation(longitude: number, latitude: number, zoom: number) {
		this.map.flyTo({ center: [longitude, latitude], zoom: zoom })
	}

	private deselectMarkers(): Promise<boolean> {
		Util.debug('deselectMarkers')
		const promise = new Promise<boolean>((resolve, reject) => {
			for (let loc of this.selectedLocations) {
				if (loc.selected) {
					Util.debug('deselectMarkers - selected')
					loc.selected = false

					if (loc.marker.getPopup().isOpen()) {
						loc.marker.togglePopup()
					}
				}
			}

			resolve(true)
		})

		return promise
	}

	private removeAllMarers(): Promise<boolean> {
		Util.debug('removeAllMarers')
		const promise = new Promise<boolean>((resolve, reject) => {
			for (let loc of this.selectedLocations) {
				loc.selected = false
				loc.marker.remove()
				// if (loc.selected) {
				// 	Util.debug('removeAllMarers - selected')
				// 	loc.selected = false
				// 	loc.marker.remove()
				// }
			}

			this.selectedLocations = []

			resolve(true)
		})

		return promise
	}

	ngOnDestroy(): void {
		Util.debug('[Map] - ngOnDestroy')

		if (this.getElementsSubscription) {
			this.getElementsSubscription.unsubscribe()
		}

		if (this.selectMarkerSubscription) {
			this.selectMarkerSubscription.unsubscribe()
		}
	}
}
