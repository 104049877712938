<!-- <div class="loading-container" [hidden]="!dataService.isLoadingReportList()">
	<div class="tail-spin">&nbsp;</div>
</div> -->

<!-- <div class="topview report-list-container">
		<div class="row">
			<div class="full-container">
				<div class="loading-container-small" *ngIf="isGeneratingPdf">
					<div>
						<h2 class="text-center">Generating pdf...</h2>
					</div>
					<div class="tail-spin">&nbsp;</div>
				</div>

				<div class="panel panel-default position-relative">
					<div class="btn-close-container-pos">
						<button type="button" class="btn-close" aria-label="Close" (click)="passBack()">
						</button>
					</div> -->

<!-- <div class="panel-heading">
						<label>{{"REPORT.TITLE_LIST" | translate}} - {{parent.patientInfo}}</label>
					</div> -->
<div>
	<!-- unica table per tutti i profili -->

	<table mat-table [dataSource]="reportListTable" matSort style="width: 100%">
		<!-- User NAme Column -->
		<ng-container matColumnDef="report_id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORT.REPORT_ID' | translate }}</th>
			<td mat-cell *matCellDef="let element">{{ element.id }}</td>
		</ng-container>

		<ng-container matColumnDef="creation_date">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORT.REPORT_DATE' | translate }}</th>
			<td mat-cell *matCellDef="let element">@if(session.isLevel1()){ {{ session.formatDate(element.creation_date) }} } @else { {{ session.formatDateTime(element.creation_date) }} }</td>
		</ng-container>

		<ng-container matColumnDef="diagn_group">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>DIAGN. GROUP</th>
			<td mat-cell *matCellDef="let element">{{ element.getDiagnGroupDescr() }}</td>
		</ng-container>

		<ng-container matColumnDef="created_by">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORT.SPECIALIST' | translate }}</th>
			<td mat-cell *matCellDef="let element">{{ element.refDisplayName }}</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORT.STATUS' | translate }}</th>
			<td mat-cell *matCellDef="let element">{{ element.getStatusDescr() }}</td>
		</ng-container>

		<ng-container matColumnDef="download">
			<th mat-header-cell *matHeaderCellDef>{{ 'BUTTONS.DOWNLOAD' | translate }}</th>
			<td mat-cell *matCellDef="let element">
				<div *ngIf="!element.is_waiting_PDF" class="ms-4">
					<!-- @if(currentPatient){ -->
					<a (click)="downloadReport(element)" ngbTooltip="{{ 'BUTTONS.DOWNLOAD' | translate }}">
						<fa-icon [icon]="faSave" faSave style="color: #717ace"></fa-icon>
					</a>
					<!-- } @else{
					<fa-icon [icon]="faBan" faBan ngbTooltip="Failed to load patient data" style="opacity: 0.5"></fa-icon>
					} -->
				</div>

				<div *ngIf="element.is_waiting_PDF" class="spinner-border spinner-border-sm text-primary ms-4" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ isNew: (session.isLevel1() === true || session.isLevel3() === true) && row.status === 1 }"></tr>
	</table>

	<mat-paginator [hidden]="reportList.length < 11" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons> </mat-paginator>
</div>

<div class="text-center mt-3" *ngIf="reportList.length == 0">
	<p>No data</p>
</div>
<!-- </div>
			</div>
		</div>
	</div> -->
