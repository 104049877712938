<div class="panel panel-default panel-modal panel-delete-container">
	<!-- modal-panel -->
	<div class="panel-heading">
		<p class="panel-title text-center"><b> {{title}}?</b></p>

		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
		</div>
	</div>
	<div class="container-fluid text-center">
		<div class="row">
			<div class="col-12">
				<p>{{subtitle}}</p>

				<p style="font-weight: bold">{{itemDescription}}</p>

				<div *ngIf="itemType == 'patient'">
					<p>
						<span class="me-2"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" style="color: #ff3b2d"></fa-icon></span>{{"DELETE.QUESTION_PATS" | translate}}
					</p>
				</div>
			</div>
		</div>
		<div class="row" *ngIf="requirePsw">
			<div class="col-sm-12">
				<form #confirmFrom="ngForm">
					<div class="form-group position-relative">
						<div class="d-flex align-items-center flex-column">
							<label for="confirm">{{"DELETE.LABEL" | translate}}</label>
							<input type="text" class="form-control text-center w-75" maxlength="20" id="confirm" name="confirm" [ngClass]="[psw.touched && !psw.valid ? 'has-error' : '', wrongPwd ? 'headshake' : ''] " [(ngModel)]="password" #psw="ngModel" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" #pwd required />
						</div>
						<span class="error-message" [hidden]="!wrongPwd">{{"MESSAGE.WRONG_PWD" | translate}}</span>
					</div>

					<div class="d-flex justify-content-evenly mt-4">
						<button class="btn btn-primary" [disabled]="confirmFrom.invalid" (click)="checkPsw()">{{"MISC.YES" | translate}}</button>
						<button type="button" class="btn btn-secondary" (click)="dismiss()">{{"MISC.NO" | translate}}</button>
					</div>
				</form>
			</div>
		</div>

		<!-- <div class="row">&nbsp;</div> -->

		<!-- ng-submit="modal.submit(deleteForm)" -->

		<div class="row mt-2" *ngIf="!requirePsw">
			<div class="col-sm-12 d-flex justify-content-evenly">
				<button type="button" class="btn btn-primary" (click)="passBack(true)">{{"MISC.YES" | translate}}</button>

				<button type="button" class="btn btn-secondary" (click)="dismiss()">{{"MISC.NO" | translate}}</button>
			</div>
		</div>
	</div>
</div>
