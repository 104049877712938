import { DateParser } from './dateParser.model'
export class Anamnesis {
	question_id: number
	question: string // the question text already translated
	priority: number // the order in the questions list
	level: string // general or medical, se in futuro servira' dividerle in due tab...
	answers: Answer[] // the options to reply
	answered: Date
	isAnswered: boolean
	input_type: string
	depends_on: string
	control_options: string
	tooltip: string
	image_url: string
	section: number
	section_title: string

	constructor(rawObj?) {
		this.question_id = 0
		this.question = ''
		this.priority = 0
		this.level = ''
		this.answers = []
		this.answered = null
		this.isAnswered = false
		this.input_type = ''
		this.depends_on = ''
		this.control_options = ''
		this.section = 0
		this.section_title = ''

		if (rawObj) {
			// copia tutti i campi con lo stesso nome
			let myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			// gestire campi con nomi diversi
			// 04.05.2023 patch per firefox
			if (rawObj.answered) {
				this.answered = DateParser.parseDate(rawObj.answered)
			} else {
				this.answered = null
			}
			if (rawObj.replies) {
				let list = rawObj.replies
				for (let i = 0; i < list.length; i++) {
					let myR = new Answer(list[i])
					this.answers.push(myR)
					if (myR.checked) this.isAnswered = true
				}
			}
		}
	}
}

export class Answer {
	reply_id: number
	reply: string // the answer text already translated
	checked: boolean

	constructor(rawObj?) {
		this.reply_id = 0
		this.reply = ''
		this.checked = false

		if (rawObj) {
			// copia tutti i campi con lo stesso nome
			let myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			// nome diverso xche' sul server ho Y/N, qui ho un boolean
			if (rawObj.selected && rawObj.selected == 'Y') {
				this.checked = true
			}
		}
	}
}
//22.02.23
export interface PatientReply {
	question_id: number
	reply_ids: number[]
}

export interface visus {
	name: string
	value: { right: string; left: string; bino: string }
}

export class VA {
	va_right: string
	va_left: string
	va_bino: string
	old_right: string
	old_left: string
	old_bino: string
	new_right: string
	new_left: string
	new_bino: string
	creation_date: Date

	constructor(rawObj?) {
		this.va_right = ''
		this.va_left = ''
		this.va_bino = ''
		this.old_right = ''
		this.old_left = ''
		this.old_bino = ''
		this.new_right = ''
		this.new_left = ''
		this.new_bino = ''
		this.creation_date = null

		if (rawObj) {
			// copia tutti i campi con lo stesso nome
			let myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (rawObj.creation_date) {
				this.creation_date = DateParser.parseDate(rawObj.creation_date)
			} else {
				this.creation_date
			}
		}
	}
}

export interface GroupedQuestions {
	substep: number
	questions: Anamnesis[]
	section_title: String
}

export interface AnamnPill {
	question_id: number
	display_id: number
	display_descr: string | null
	tag: string
	level: string
}

export interface AnamnPillsSection {
	group_id: number
	section: number
	section_title: string
	priority: number
	pills: AnamnPill[]
}

export interface AnamnPillsData {
	anamn_sections: AnamnPillsSection[]
}
