<div class="eye-strain-graph-container">
	<apx-chart
		[chart]="chartOptions.chart"
		[xaxis]="chartOptions.xaxis"
		[yaxis]="chartOptions.yaxis"
		[series]="chartOptions.series"
		[legend]="chartOptions.legend"
		[stroke]="chartOptions.stroke"
		[markers]="chartOptions.markers"
		[tooltip]="chartOptions.tooltip"
		[annotations]="chartOptions.annotations"></apx-chart>
</div>
