import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { LoginComponent } from '../app/component/login/login.component'
import { PatientListComponent } from './component/patients/patientList.component'
import { VisitListComponent } from '../app/component/visits/visitList.component'
import { DoctorListComponent } from '../app/component/doctors/doctorList.component'
import { CreateUserComponent } from '../app/component/users/createUser.component'
import { AdminListComponent } from '../app/component/admins/adminList.component'
import { ActivationComponent } from '../app/component/activation/activation.component' // 14.03.2022
// import { VerifyPukComponent } from '../app/component/verifyPuk/verifyPuk.component'
import { TelerefractComponent } from '../app/component/telerefract/telerefract.component' // 29.04.2022
import { RemoteListComponent } from '../app/component/remotes/remoteList.component' // 03.05.2022
import { RelationsComponent } from '../app/component/relations/relations.component' // 04.05.2022
import { AgreementComponent } from '../app/component/agreement/agreement.component' // 26.05.2022
import { StatisticsComponent } from '../app/component/statistics/statistics.component'
import { DevicesComponent } from '../app/component/devices/devices.component' // 15.06.2022
import { SapComponent } from '../app/component/sap/sap.component' // 06.12.2022
import { UpdatesComponent } from '../app/component/updates/updates.component' //29-11-22
import { WorkingComponent } from '../app/component/working/working.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { SubscriptionComponent } from './component/subscription/subscription.component'
import { TrialComponent } from './component/trial/trial.component'
import { ClinicsComponent } from './component/clinics/clinics.component'
import { DashboardComponent } from './component/dashboard/dashboard.component'
import { ProfileComponent } from '../app/component/profile/profile.component'
import { dbSettingComponent } from './component/dbsettings/dbsettings.component'
import { LocationComponent } from './elements/location/location.component'

const routes: Routes = [
	{ path: '', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'patients', component: PatientListComponent },
	// { path: 'visits', component: VisitListComponent },
	{ path: 'operators', component: DoctorListComponent },
	{ path: 'colleagues', component: DoctorListComponent }, // 11.03.2022
	{ path: 'admins', component: AdminListComponent },
	{ path: 'profile', component: ProfileComponent },
	{ path: 'createUser', component: CreateUserComponent },
	{ path: 'activation', component: ActivationComponent },
	{ path: 'agreement', component: AgreementComponent }, // 26.05.2022
	// { path: 'verifyPuk', component: VerifyPukComponent },
	{ path: 'telerefract', component: TelerefractComponent },
	{ path: 'remotes', component: RemoteListComponent },
	{ path: 'relations', component: RelationsComponent },
	{ path: 'statistics', component: StatisticsComponent },
	{ path: 'devices', component: DevicesComponent },
	{ path: 'updates', component: UpdatesComponent },
	{ path: 'subscription', component: SubscriptionComponent },
	{ path: 'trial', component: TrialComponent },
	{ path: 'graders', component: ClinicsComponent },
	{ path: 'dashboard', component: DashboardComponent },
	{ path: 'locations', component: LocationComponent },

	{ path: 'sap', component: SapComponent },

	{ path: 'dbsettings', component: dbSettingComponent },

	{ path: 'not-found', component: PageNotFoundComponent },

	{ path: '**', redirectTo: '/not-found' },

	// { path: '**', component: WorkingComponent },

	//{ path: '**', redirectTo: '/login' }
]

// 24.03.2022 per ricaricare in caso di cambio params, non funziona
//imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
