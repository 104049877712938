import { Component, ViewChild, OnInit, ElementRef, OnDestroy, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { VisitListModal } from './visitList.modal'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

// 02.09.2022    MAT TABLE IMPORT
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'

import { ActivatedRoute, Router } from '@angular/router'

import { faSave, faTimesCircle, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faUpload, faRotateLeft, faRotate, faSpinner, faCircleCheck, faBan } from '@fortawesome/free-solid-svg-icons'

// ********** specific for the prj  *************

import { Config } from '../../../config'

import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'

import { Util } from '../../models/util.model'
import { DateParser } from '../../models/dateParser.model'
import { Patient } from '../../models/patient.model'
import { Visit } from '../../models/visit.model'
import { Report } from '../../models/report.model'
import { AiReport, aiBatchId } from '../../models/aiReport.model'

import { SaleInfo } from '../../models/salePlan.model'

import {
	Exam,
	ExamType,
	LensmeterExam,
	ExamImage,
	FundusExam,
	// FundusExam, DryEyeExam, PachyExam, PupilExam,RetroExam,SubjectiveExam,TonoExam,TopoExam, WfExam,
} from 'src/app/models/exam.model'
import { CategoriesController } from '../categories/categories.controller'
import { TablePrefs } from '../../models/settings.model'
import { AppToastService } from 'src/app/service/toast.service'
import { Subscription } from 'rxjs'
import { ToastOptions } from 'src/app/models/toast.model'
import { Anamnesis, VA } from '../../models/anamnesis.model'
import { User } from 'src/app/models/user.model'

import { AnamnesisModal, AnamnesisMode, AnamnesisType } from '../anamnesis/anamesis.modal'
import { aiReviewModal } from './aiReview.modal'
import { reportsService } from 'src/app/service/reports.service'
import { addDeviceModal } from './addDevice.modal'
import { AnamnesisService } from 'src/app/service/anamnesis.service'
import { ReportType } from 'src/app/models/pdf.model'

export interface counterHG {
	fundus: number
	full: number
}

export interface selectedRowHGCounter {
	type: string //fundus o full
	date: string
}

@Component({
	selector: 'visits-list',
	templateUrl: './visitList.component.html',
	styleUrls: ['./visitList.component.scss'],
})
export class VisitListComponent implements OnInit, OnDestroy {
	@Input() currentPatient: Patient

	visitList: MatTableDataSource<Visit>
	visitListRaw: Visit[]
	loadingVisitList: boolean

	doctorName: string

	// ***********************
	currentModal: NgbModalRef
	wizardModal: NgbModalRef
	categoriesModal: NgbModalRef
	aiModal: NgbModalRef
	currentAction: string

	doctorId: number

	patientId: number

	//visList: Visit[]    // a cosa serve ? abbiamo gia' visitListRaw [ls]
	selectedRowsHG: Map<number, Visit>
	selectedRowsAI: Map<number, Visit>

	selectedRowHGCounter: selectedRowHGCounter[]

	totVisits: number

	exam: any // data.TopoExam;   // oggetto usato per crearne uno nuovo
	demoVisit: Visit // oggetto usato per crearne uno nuovo

	hasCredits: boolean // 28.12.2022
	isReqEnabled: boolean // mostra o no i checkboxes e il bottone per fare la richiesta
	disableReqReview: boolean // flag sul bottone abilita/disabilita in base ai check selezionati

	// Report AI **********
	hasAiCredits: boolean
	isAiReqEnabled: boolean
	disableAiReqReview: boolean // abilita/disabilita il pulsante di richiesta Ai grading

	selRow: boolean[] = []
	selMod: boolean[] = []

	// 21.04.2020 matrice associativa con i moduli selezionati
	// indicizzata con gli id delle visite e con gli examType, con valori boolean per la selezione
	selectedModules: any[]

	patientInfo: string // 16.10.2020 per titolo su modal dei report

	// Report HG **********
	canGrade: boolean // 04.04.2023
	reportList: Report[] // 12.05.2020
	//currentReport: data.FullReport; // 01.06.2020
	disableNewReportBtn: boolean // 14.10.2020
	newReportCount: number // qui conta solo quelli nuovi
	hasReports: boolean

	reportAiList: AiReport[]
	hasReportsAi: boolean
	newReportAiCount: number //number notification on AiReport button
	aiReportReq: number // number of request sent

	// **************************

	singleOwner: boolean // 24.03.2022

	// 21.12.2022 con la materialTable non usiamo piu'
	// sono input per la directive
	//refreshFlag: string; // basta cambiarlo quando serve fare il refresh, per es. un timestamp

	targetReportId: number // 23.08.2022 per RDS, accesso diretto
	targetAiReportId: number

	reloadEnable: boolean

	anamnesis: Anamnesis[]
	canSeeAnamnesis: boolean

	AnamDate: Date
	amnAccepted: boolean

	patientVa: VA

	currUser: User

	hgColumn: String
	aiColumn: String

	saleInfo: SaleInfo

	availableCredits: number
	creditsChangesSubscription: Subscription

	//angular material table
	// filterWord: string
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef
	displayedColumns: string[]
	sortStatus: Sort
	pageStatus: PageEvent
	visitPref: TablePrefs
	localStorageName: string

	//icons
	faSave = faSave // per usarla su html  13.01.2022
	faTimesCircle = faTimesCircle // per Hidden
	faUpload = faUpload
	faCheck = faCheck // 20.01.2022 per esami gia' gradati
	faRotateLeft = faRotateLeft
	faRotate = faRotate
	faSpinner = faSpinner
	faCircleCheck = faCircleCheck
	faBan = faBan
	faFilePdf = faFilePdf

	constructor(
		public session: SessionService,
		public anamesisService: AnamnesisService,
		public translator: TranslateService,
		public modalService: NgbModal,
		public dataService: DataModelService,
		public myMatPagIntl: MatPaginatorIntl,
		public activatedRoute: ActivatedRoute, //public settings: TableSettings            // 09.06.2022 test, tolto
		private toastService: AppToastService,
		private reportsService: reportsService,
		public activeModal: NgbActiveModal
	) {
		Util.debug('(visitListComponent) - constructor')

		this.hgColumn = this.translator.instant('VISITS.HG_COLUMN')
		this.aiColumn = this.translator.instant('VISITS.AI_COLUMN')

		// 11.01.2023 se utente fa F5, qui e' troppo presto, non ha ancora ricevuto il plan
		this.isReqEnabled = false // basato solo sul piano, middle o advanced
		this.hasCredits = false
		this.disableReqReview = true // flag per (dis)abilitare il bottone, in base alla selez. dei check

		this.hasAiCredits = false
		this.isAiReqEnabled = false //basato sul settings
		this.disableAiReqReview = true

		this.newReportAiCount = 0
		this.aiReportReq = 0

		this.selectedRowsHG = new Map<number, Visit>()
		this.selectedRowsAI = new Map<number, Visit>()

		this.selectedRowHGCounter = []

		this.newReportCount = 0
		this.hasReports = false

		this.amnAccepted = false // 26.06.2023
		this.patientVa = null

		this.currUser = this.session.user

		this.saleInfo = new SaleInfo(null)

		this.availableCredits = 0

		this.canGrade =
			(this.session.isSpecialist() || (session.isDoctor() && !session.isSuperB())) && // 04.04.2023 escludo i superB
			!session.isClalit()

		// 11.01.2023 gestione piano e crediti ***********
		if (this.session.isLevel1()) {
			this.managePlan()
			this.saleInfo = this.currUser.saleInfo
			this.availableCredits = this.saleInfo.available_credits
		}

		this.initColumns()

		// ************************************************

		this.doctorId = session.getCurrentDoctorId()
		// console.log(this.doctorId)
		this.patientId = 0

		// non il support
		this.canSeeAnamnesis = (this.session.isLevel1() && this.currUser.settings.anamnesis_group > 0) || this.session.isSpecialist() || this.session.isAdmin()

		// 26.06.2023 nel caso l'avesse gia' caricato da prima
		// this.currentPatient = this.session.getDtPatient(this.patientId)

		this.reportsService.doctorId = this.doctorId

		this.disableNewReportBtn = true // 11.02.2021 parte disabilitato
		this.toastService.init = false

		// this.countNewReports()

		// 25.03.2022 per esporre o meno il nome doctor nel titolo
		this.singleOwner = session.isLevel1()
		if (this.singleOwner) {
			if (session.isGroupB() && this.doctorId > 0) {
				this.singleOwner = false
			}
		} else {
			this.doctorName = this.session.getDoctorName(this.doctorId)
		}

		this.localStorageName = this.session.getUserId() + ' - visitPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.visitPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.visitPref = new TablePrefs()

			this.visitPref.empty = false
			// default data
			this.visitPref.sort = 'date'

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.visitPref))
		}
	}

	ngOnInit() {
		Util.debug('(visitListComponent) - onInit')

		// console.log(this.currentPatient)

		this.patientInfo = 'Patient ' + this.currentPatient.id
		this.reportsService.patientId = this.currentPatient.id
		this.reportsService.currentPatient = this.currentPatient
		this.patientId = this.currentPatient.id

		if (this.session.isLevel1()) {
			this.patientInfo = '' + this.currentPatient.name
		}

		this.manageVisitList()

		// this.countNewReports()

		this.initFakeExams()

		this.creditsChangesSubscription = this.session.creditsUpdatedStatus.subscribe((credits) => {
			this.availableCredits = credits
			// console.log(credits)
			this.toastService.checkCreditsNotifications()
		})

		// 20.01.2022 per gestire i reload della pg
		if (!this.session.isLevel1() && this.doctorId > 0) {
			this.session.loadDoctorKey('' + this.doctorId)
		}

		// translation for the mat-paginator
		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')

		// for page destroying
		window.onbeforeunload = () => this.ngOnDestroy()
	}

	private managePlan() {
		this.isReqEnabled = this.session.userCouldRequestReview() // basato solo sul piano, non sui crediti disponibili

		this.isAiReqEnabled = this.session.userCouldRequestAIReport()

		Util.debug('VL (managePlan) - isReqEnabled ? ' + this.isReqEnabled)

		this.initColumns() // cambiano i checkboxes in base a isReqEnabled
	}

	reloadList() {
		console.log('(VisitList) - reload')
		this.reloadEnable = false
		this.manageVisitList()
	}

	private initColumns() {
		this.displayedColumns = []

		if (this.session.isAdmin()) {
			this.displayedColumns.push('id')
		}

		// options
		if (this.isReqEnabled) {
			this.displayedColumns.push('HG_review')
		}

		if (this.isAiReqEnabled) {
			this.displayedColumns.push('AI_review') // controllare quando metterlo, potrebbe essere che non abbia grader ma solo AI attiva? // esattto ora puó essere
		}

		if (this.session.isGod() || this.session.isSupport()) {
			this.displayedColumns.push('is_visible')
		}
		this.displayedColumns.push('date')

		if (this.session.isAdmin() || this.session.isDev() || this.session.isLocalNS()) {
			this.displayedColumns.push('name')
		}

		this.displayedColumns.push('exam_type')
		this.displayedColumns.push('device')
		this.displayedColumns.push('device_sn')

		if (this.session.isSupport() || this.session.isAdmin()) {
			this.displayedColumns.push('has_xml')
		}

		this.displayedColumns.push('consent')

		if (this.session.isOptician()) {
			this.displayedColumns.push('pdf')
		}

		this.displayedColumns.push('filter')
	}

	private ApplySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		this.input.nativeElement.value = pref.filter

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC'
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop'
		} else {
			return 'with: ' + reason
		}
	}

	// load table data
	manageVisitList() {
		Util.debug('VL (MngVisitList) inizio')
		this.loadingVisitList = true
		this.session
			.loadVisitList(this.currentPatient.id, this.doctorId)
			.then((visits) => {
				this.visitListRaw = visits
				// console.log(this.visitListRaw)

				this.visitList = new MatTableDataSource<Visit>(this.visitListRaw)
				this.visitList.paginator = this.paginator
				this.visitList.sort = this.sort

				this.loadingVisitList = false
				setTimeout(() => {
					//abilito il pulsante dopo 5sec
					this.reloadEnable = true
				}, 5000)

				// automatic sort
				this.ApplySettings(this.visitPref, this.visitList)

				this.initVisitList()

				//only credits

				this.toastService.onlyCredits = true
				this.toastService.checkNotifications()
			})
			.catch((err) => {
				this.loadingVisitList = false
				if (!this.session.isExpired(err)) {
					var msg = err.data ? err.data.error : err.toString()
					alert(msg)
				}
			})
	}

	private initVisitList() {
		// 27.06.2023 perche' usare una var duplicata? uso la globale [ls]
		//this.visList = this.session.getDtVisitList() // getData().visitList;

		// console.log(this.visList)

		// 21.04.2020
		this.totVisits = 0
		if (this.visitListRaw) {
			this.totVisits = this.visitListRaw.length
		}

		this.selectedModules = new Array(this.totVisits)

		for (let i = 0; i < this.totVisits; i++) {
			var vis = this.visitListRaw[i]
			var selected = new Array()

			for (var j = 0; j < vis.examTypes.length; j++) {
				selected[vis.examTypes[j].exam_type] = false
			}
			var visitIndex = '' + vis.id
			this.selectedModules[visitIndex] = selected
		}

		// 04.06.2020 solo per specialists    // 22.01.2021 e doctors
		if (this.session.isSpecialist() || this.session.isDoctor()) {
			this.preSelectModules() // 27.05.2020 spostato qui dal modal,
		}

		if (this.isAiReqEnabled || this.session.isAdmin()) {
			// this.loadAiReports() //  abilita il bottone Ai-reports
		}
	}

	private updateSelectedRowHGCounter(): selectedRowHGCounter[] {
		this.selectedRowHGCounter = []
		// mi passo tutte le visite selezionate e cerco di capire il costo di quello che é stato selezionato
		// ogni record di selectedRowHGCounter ha una data per giorno e il tipo di esame richiesto per quel giorno
		for (let hgRow of this.selectedRowsHG.values()) {
			let visitDate = new Date(hgRow.date)
			visitDate.setHours(0, 0, 0, 0)
			let visitDateString = visitDate.toISOString()

			let visitType = 'full'

			if (hgRow.examTypes.length === 1 && hgRow.examTypes[0].exam_type == Config.EXM_FUNDUS) {
				visitType = Config.EXM_FUNDUS
			}

			if (this.selectedRowHGCounter.length > 0) {
				let row = this.selectedRowHGCounter.find((row) => row.date === visitDateString)

				if (row) {
					if (hgRow.examTypes[0].exam_type != row.type && row.type == Config.EXM_FUNDUS) {
						row.type = 'full'
					}
				} else {
					this.selectedRowHGCounter.push({ date: visitDateString, type: visitType })
				}
			} else {
				this.selectedRowHGCounter.push({ date: visitDateString, type: visitType })
			}
		}
		return this.selectedRowHGCounter
	}

	private getExamCounter(): counterHG {
		let counter: counterHG = { fundus: 0, full: 0 }
		// creo un oggetto dove trovo il numero di full o fundus richieste
		this.selectedRowHGCounter = this.updateSelectedRowHGCounter()

		for (let selRow of this.selectedRowHGCounter) {
			if (selRow.type == Config.EXM_FUNDUS) {
				counter.fundus++
			} else {
				counter.full++
			}
		}
		return counter
	}

	public checkRowHG(row: Visit, event) {
		// se arrivo qui, vuol dire che la visita puó essere gradata can_ask_HG = true
		let isChecked = event.target.checked

		if (isChecked) {
			//verifico se la visita ha esami
			if (!this.visitHasExams(row)) {
				row.is_selected_HG = false
				event.target.checked = false
				return
			}

			let canVisitDeviceRequestHGReview: { resp: boolean; checkCredits: boolean } = this.session.canVisitDeviceRequestHGReview()

			if (!canVisitDeviceRequestHGReview.resp) {
				let body = this.translator.instant('VISITS.NOT_ENOUGHT_HG_CREDITS')
				let header = this.translator.instant('TOAST.HEADER.WARNING')
				let options = new ToastOptions('notification_s')
				this.toastService.show(header, body, false, options, 'center')

				row.is_selected_HG = false
				event.target.checked = false
				return
			}

			//controllo che la visita selezionata sia dello stesso giorno delle altre selezionate
			let selectedRows = Array.from(this.selectedRowsHG.values())

			if (selectedRows && selectedRows.length > 0) {
				let rowDate = new Date(row.date)
				let selectedRowsDate = new Date(selectedRows[0].date)

				rowDate.setHours(0, 0, 0, 0)
				selectedRowsDate.setHours(0, 0, 0, 0)

				if (rowDate.getTime() != selectedRowsDate.getTime()) {
					Util.debug('rowDate != selectedRowsDate' + rowDate + ' != ' + selectedRowsDate)
					let body = this.translator.instant('VISITS.WRONG_VISITS_DATE')
					let header = this.translator.instant('TOAST.HEADER.WARNING')
					let options = new ToastOptions('notification_s')
					this.toastService.show(header, body, false, options, 'center')

					row.is_selected_HG = false
					event.target.checked = false
					return
				}
			}

			this.selectedRowsHG.set(row.id, row)

			if (canVisitDeviceRequestHGReview.checkCredits) {
				let counter = this.getExamCounter()

				const fundusVisitCost = this.saleInfo.salePlan.getCreditCost('HG_fundus')
				const fullVisitCost = this.saleInfo.salePlan.getCreditCost('HG_full')

				//calculate full request cost and check available credits
				const totalCost = fundusVisitCost * counter.fundus + fullVisitCost * counter.full
				if (totalCost > this.availableCredits) {
					let header = this.translator.instant('TOAST.HEADER.WARNING')
					let body = this.translator.instant('CREDITS.MULTIPLE_REQ_INSUFFICIENT_CREDITS', {
						tot_cost_credits: totalCost,
						available_credits: this.availableCredits,
					})
					let options = new ToastOptions('notification')
					this.toastService.show(header, body, false, options, 'center')

					event.target.checked = false
					this.unCheckHGRows(row)

					return
				}
			}
		} else {
			this.unCheckHGRows(row)
		}

		// console.log(Array.from(this.selectedRowsHG.values()))
	}

	public checkRowAI(row: Visit, event) {
		// se arrivo qui, vuol dire che la visita puó essere gradata can_ask_AI = true
		// console.log(row)
		let isChecked = event.target.checked

		if (isChecked) {
			//verifico se la visita ha esami
			if (!this.visitHasExams(row)) {
				row.is_selected_AI = false
				event.target.checked = false
				return
			}

			// verifico i crediti
			if (!this.session.canVisitDeviceRequestAIReview(row.device_sn)) {
				let body = this.translator.instant('VISITS.NOT_ENOUGHT_AI_CREDITS')
				let header = this.translator.instant('TOAST.HEADER.WARNING')
				let options = new ToastOptions('notification_s')
				this.toastService.show(header, body, false, options, 'center')

				row.is_selected_AI = false
				event.target.checked = false
				return
			}

			this.selectedRowsAI.set(row.id, row)
		} else {
			if (this.selectedRowsAI.has(row.id)) {
				this.selectedRowsAI.delete(row.id)
			}
		}

		// console.log(Array.from(this.selectedRowsAI.values()))
	}

	private visitHasExams(visit: Visit): boolean {
		// true if selected visit has exams
		var totExams = 0

		if (visit.visitExams != null) {
			totExams = visit.visitExams.length
		}

		if (totExams == 0) {
			visit.is_selected_AI = false // lo deseleziono
			var msg = 'Visit ' + visit.name + ' without exam is not gradable.'
			alert(msg)
			Util.debug(msg)
			return false
		}
		return true
	}

	private unCheckHGRows(visit?: Visit) {
		Util.debug('VisitList - unCheckHGRows')
		if (visit) {
			if (this.selectedRowsHG.has(visit.id)) {
				visit.is_selected_HG = false
				visit.is_waiting_HG = false
				this.selectedRowsHG.delete(visit.id)
			}
		} else {
			for (let visit of this.selectedRowsHG.values()) {
				visit.is_selected_HG = false
				visit.is_waiting_HG = false
			}

			this.selectedRowsHG.clear()
		}
	}

	private unCheckAIRows() {
		Util.debug('VisitList - unCheckAIRows')

		for (let visit of this.selectedRowsAI.values()) {
			visit.is_selected_AI = false
		}

		this.selectedRowsAI.clear()
	}

	onExamClick(visit: Visit, myExam?) {
		if (this.session.isSpecialist() || this.session.isDoctor()) {
			this.selectModule(visit.id, myExam)
		} else {
			this.openCategoriesModal(visit)
		}
	}

	//*************
	// HG REVIEW
	// ************

	requestReview() {
		if (this.currUser.settings.anamnesis_group > 0 && this.canSeeAnamnesis) {
			Util.debug('(visitList) Got anamnesis group > 0')

			this.checkPatientAnamnesis()
				.then((resp) => {
					var openAnamnesisModal = resp.res

					if (openAnamnesisModal) {
						this.confirmAnamnesiModal(resp.tab)
					} else {
						this.confirm()
					}
				})
				.catch((res) => {
					this.confirm()
				})
		} else {
			this.confirm()
		}
	}

	private confirmAnamnesiModal(tab: AnamnesisType) {
		Util.debug('(VisitList) - onlyContinueModal')
		let text = this.translator.instant('VISITS.UPDATED_ANAMNESIS')

		this.openConfirmModal(text)
			.result.then((result) => {
				Util.debug('open edit Anamnesi modal')

				this.currentModal = this.openAnamnesiModal(tab)
				// this.currentModal.componentInstance.currPatient = this.currentPatient

				this.currentModal.result.then((resp) => {
					// console.log(resp)

					this.confirm()
				})
				// se risp annulla invio
			})
			.catch((res) => {
				Util.debug('annullato')
				this.confirm()
			})
	}

	private openAnamnesiModal(tab: AnamnesisType): NgbModalRef {
		this.currentModal = this.modalService.open(AnamnesisModal, { size: 'xl', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.currPatient = this.currentPatient
		this.currentModal.componentInstance.currentAction = AnamnesisMode.EDIT
		this.currentModal.componentInstance.focusTab = tab
		return this.currentModal
	}

	private checkPatientAnamnesis(): Promise<{ res: boolean; tab: AnamnesisType }> {
		// funzione che fa il check se deve essere compilata e quindi mostrata anamnesi o va o entrambe
		let selectedRows = Array.from(this.selectedRowsHG.values())
		let visitsDate = new Date(selectedRows[0].date)
		let today = new Date()

		visitsDate.setHours(0, 0, 0, 0)
		today.setHours(0, 0, 0, 0)

		const promise = new Promise<{ res: boolean; tab: AnamnesisType }>((resolve, reject) => {
			let response: { res: boolean; tab: AnamnesisType } = { res: false, tab: null }
			// this.patientVa
			if (today.getTime() == visitsDate.getTime()) {
				// le visite selezionate sono di oggi quindi puó cvompilare l'anamnesi se serve
				Promise.all([this.anamesisService.getPatientAnamnesisAnswers(this.currentPatient.id, 'medical'), this.anamesisService.getPatientVA(this.currentPatient.id)])
					.then((resp) => {
						// medical anamnesis e va messe sulle prop giá presenti (this.currentPatient.anamnesis e this.patientVa) ma non servirebbe
						this.currentPatient.anamnesis = resp[0]
						this.patientVa = resp[1]

						// check va
						if (this.patientVa && this.patientVa.creation_date) {
							let vaDate: Date = null

							if (this.patientVa.creation_date) {
								vaDate = this.patientVa.creation_date
								vaDate.setHours(0, 0, 0, 0)

								if (vaDate.getTime() != today.getTime()) {
									response.res = true
									response.tab = AnamnesisType.VA
								}
							}
						} else {
							// o la VA o l'anamnesi sono vuoti quindi deve compilarle
							response.res = true
							response.tab = AnamnesisType.VA
						}

						//check medical
						if (this.currentPatient.anamnesis && this.currentPatient.anamnesis.length > 0) {
							let anamDate = this.currentPatient.anamnesis[0].answered
							anamDate.setHours(0, 0, 0, 0)

							if (today.getTime() != anamDate.getTime()) {
								// se anamnesi o Va sono vecchie, apro anamnesi
								response.res = true
								response.tab = AnamnesisType.MEDICAL
							}
						} else {
							response.res = true
							response.tab = AnamnesisType.MEDICAL
						}

						resolve(response)
					})
					.catch((err) => {
						Util.debug('err: ' + err)
						console.log('Get patient anamensis and VA to check failed')
						reject(response)
					})
			} else {
				Util.debug('(visitList) old visit, cannot check anamnesis')
				resolve(response)
			}
		})
		return promise
	}

	private confirm() {
		let text = this.translator.instant('VISITS.REVIEWED_REQUEST_CONFIRM')

		if (this.session.userHasPrivateGrader()) {
			text = this.translator.instant('VISITS.REVIEWED_REQUEST_CONFIRM_PRIVATE')
		}

		this.openConfirmModal(text)
			.result.then((result: boolean) => {
				if (result) {
					this.toastService.init = false

					var row = Array.from(this.selectedRowsHG.values())

					for (let visit of row) {
						visit.is_waiting_HG = true
					}

					//this.disableReqReview = true;  // disab il bottone  16.04.2020
					this.updateVisits(row.slice())
				}
			})
			.catch((res) => {
				this.unCheckHGRows()
				Util.debug('dismissed')
			})
	}

	async updateVisits(arrayCopy: Visit[]) {
		for (let i = 0; i < arrayCopy.length; i++) {
			let cont = i + 1
			var elem = arrayCopy[i]
			Util.debug('(requestReview) elem ' + i + ' visitId: ' + elem.id)

			this.selectedRowsHG.delete(elem.id) // svuoto l'array dell'elemento processato
			elem.is_selected_HG = false // 20-04-23 lo deseleziono

			var exit = false

			await this.session
				.updateVisit(elem)
				.then((response) => {
					Util.debug('(requestReview) ok, done! ')
					// console.log(response)

					if (response != null && response.visit != null) {
						var ind = this.visitListRaw.indexOf(elem)

						if (ind >= 0) {
							this.visitListRaw[ind].is_visible = 'Y' // aggiorna solo questo campo
							this.visitListRaw[ind].is_waiting_HG = false
						} else {
							Util.debug('(requestReview) not found !? visit ' + elem.id)
						}
					}

					if (cont == arrayCopy.length) {
						this.selectedRowsHG.clear() // svuoto l'array
					}
				})
				.catch((error) => {
					var ind = this.visitListRaw.indexOf(elem)

					if (ind >= 0) {
						this.visitListRaw[ind].is_waiting_HG = false
					}
					//
					Util.debug('(requestReview) KO ' + elem.id)
					Util.debug(error)

					// 20.03.2023 //21-04-2023 non serve piú con le notifiche dei crediti
					// let msg = this.session.parseErrorMessage(error)
					// alert(msg)

					// nel caso 7 crediti e richiedo il grading di una visita del vx650, arriva qua in errore ma ho i due messaggi uno sotto  l'altro
					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.session.parseErrorMessage(error)
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')

					exit = true
				})

			if (exit) {
				// se sono rimasti elementi selezionati, gli deseleziono. (poteva capitare in caso di pochi crediti e selezionando molti esami da gradare, usciva al primo catch ma ne rimanevano altri selezionati)
				this.unCheckHGRows()
				return
			}

			Util.debug('End updateVisit')
		}
	}

	undoReviewRequest(selVisit: Visit) {
		if (selVisit.hasBeenReviewed) {
			alert('cannot undo the request, exam(s) has already been graded!') // TODO, tradurre, anche se non dovrebbe neanche arrivare qui
			return
		}

		this.toastService.init = false

		let text = this.translator.instant('VISITS.UNDO_REVIEWED_REQUEST_CONFIRM')
		this.openConfirmModal(text)
			.result.then((result) => {
				// Util.debug(result)
				selVisit.is_waiting_HG = true
				if (result) {
					this.session
						.undoReviewReq(selVisit)
						.then((response: any) => {
							selVisit.is_waiting_HG = false
							this.manageVisitList()
							// this.managePlan() //
							// this.setVisitVisible(selVisit.id, 'N')
						})
						.catch((err) => {
							let msg = err.error.error

							if (err.status == 422) {
								msg = this.translator.instant('VISITS.UNDO_GRADING_FAILS')
							}

							let header = this.translator.instant('TOAST.HEADER.ERROR')
							let body = msg
							let options = new ToastOptions('error')

							this.toastService.show(header, body, false, options, 'center')
						})
				}
			})
			.catch((res) => {
				Util.debug('(undoReviewRequest) dismissed')
			})
	}

	openConfirmModal(text: string) {
		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		return this.currentModal
	}

	// ******************
	// AI REPORT
	// *****************

	public requestAIReview() {
		let myExams: ExamType[] //lista di esami da richiedere al loadCategoryExams
		myExams = []
		let myExamsControl: ExamType[] //Array di controllo per capire se ci sono esami non validi, ad  ogni ciclo del primo for viene resettato

		let header = ''
		let body = ''

		// caerco di capire se tutte le visite selezionate, possono essere usate per la AI in base al dispositivo
		let invalidVisit: boolean // visit without fundus or from not enabled device
		invalidVisit = false

		for (let visit of this.selectedRowsAI.values()) {
			if (!Config.AIDEVICES.includes(visit.device)) {
				// se il device della visita é ammesso
				Util.debug('(requestAIReview) - the visit:' + visit.id + ' is not valid for AI request')

				invalidVisit = true

				body = this.translator.instant('VISITS.AI_NOTALLOW1')
				//ne basta uno e esco
				break
			}

			myExamsControl = [] //reset per controllo sucessivo

			// controllo adesso se ci sono fundus nella visita selezionata
			if (!invalidVisit) {
				let examreq: ExamType

				for (let n = 0; n < visit.visitExams.length; n++) {
					const exam = visit.visitExams[n]

					if (exam.exam_type.includes(Config.EXM_FUNDUS)) {
						Util.debug('has fundus')

						examreq = { id: exam.id, exam_type: exam.exam_type, is_reviewed: '' }
						myExams.push(examreq)
						myExamsControl.push(examreq)
					}

					if (myExamsControl.length > 0) {
						invalidVisit = false
					} else {
						Util.debug('NO fundus')
						invalidVisit = true
						body = this.translator.instant('VISITS.AI_NOTALLOW2')
					}
				}
			}
		}

		if (invalidVisit) {
			header = 'Error'

			// on close notification, uncheck rows
			let options = new ToastOptions('notification_s', () => {
				this.unCheckAIRows()
			})

			this.toastService.show(header, body, false, options, 'center')

			return
		}

		// console.log(myExams)

		//Apro il modale e controllo se non gli ho giá richiesti e gli ho in memoria
		this.aiModal = this.modalService.open(aiReviewModal, { size: 'xl', keyboard: false, backdrop: 'static' })

		this.reportsService
			.checkFundusInMemory(myExams)
			.then((resp: FundusExam[]) => {
				// console.log(resp)

				if (resp.length > 0) {
					this.aiModal.componentInstance.fundusExamList = resp

					// setTimeout(() => {
					this.reportsService.loadingFundus.next(true) //Subject nel service in modo che il modale sa quando ha finito il load
					// }, 2000)
				} else {
					this.modalService.dismissAll()
				}
			})
			.catch((err) => {
				console.log(err)
				this.modalService.dismissAll()
			})

		this.aiModal.result
			.then((arrayResp) => {
				// Procedura di richiesta report arrivata alla fine restituendo il batc_id
				// console.log(batch_id)
				// let batch_id: aiBatchId = arrayResp[0]
				let sentIMages = arrayResp[1]
				// console.log(sentIMages)

				// this.managePlan()
				// only credits
				this.toastService.init = false
				// this.toastService.onlyCredits = true
				// this.toastService.checkNotifications()

				for (let visit of this.selectedRowsAI.values()) {
					for (let n = 0; n < visit.visitExams.length; n++) {
						const exam = visit.visitExams[n]

						if ((sentIMages[0] && exam.id == sentIMages[0].examId) || (sentIMages[1] && exam.id == sentIMages[1].examId)) {
							visit.ai_reviewed = 'Y'
						}
					}
				}

				let header = this.translator.instant('TOAST.HEADER.SUCCESS')
				let body = this.translator.instant('TOAST.NOTIFICATIONS.AI_REQUEST_SENT')
				let options = new ToastOptions('success')
				this.toastService.show(header, body, false, options, 'bottom-right')

				// this.loadAiReports(batch_id) // ricarico la list di reportAi, ma passandogli il batch_id

				this.unCheckAIRows()
			})
			.catch((err) => {
				// procedura conclusa con un errore, nessun report richiesto oppure con la x
				console.log(err)
				this.unCheckAIRows()
			})
	}

	// 20.10.2021 aggiunta richiesta lock sul paziente, prima di procedere
	openWizardReport() {
		if (this.currUser.status == 'disabled') {
			// se grader disabilitato non puó gradare
			Util.debug('(showVisits) this user is disabled!')

			let header = this.translator.instant('TOAST.NOTIFICATIONS.GR_STATUS')
			let body = this.translator.instant('TOAST.NOTIFICATIONS.GR_DISABLED')
			let options = new ToastOptions('notification_s')

			this.toastService.show(header, body, false, options, 'center')

			return
		}

		if (!this.session.isSpecialist() && !this.session.isDoctor()) return false

		let lockMsg = this.translator.instant('REPORT.ALERT_LOCK')
		// patient already locked by another specialist

		// richiesta lock sul paziente, prima di procedere
		this.session
			.lockPatient('' + this.patientId)
			.then(() => {
				// all here...
				Util.debug('(openWizardModal) ok lock on patient, proceed...')
				return this.myOpenWizardReport()
			})
			.catch((err) => {
				//var msg = (err.data)? err.data.error : err.toString();
				var msg = this.session.parseErrorMessage(err, 'alert') // 28.03.2022
				Util.debug('(openWizardModal) ko, err: ' + msg)

				if (err.status == 415) {
					// patient already locked
					msg = lockMsg // this.translator.instant('REPORT.ALERT_LOCK');  ko this.translator qui...
				}

				alert(msg)
				return false
			})
	}

	// 22.04.2020 solo per i refertatori,
	// 22.01.2021 esteso ai doctors [ls]
	// usa la matrice selectedModules con i moduli selezionati
	private myOpenWizardReport() {
		var myExamChoice = this.getExamSelection()

		if (myExamChoice != null && myExamChoice.length > 0) {
			this.session.initCategories()

			//categoryMngr.setExamChoice(myExamChoice);
		} else {
			var msg = this.translator.instant('VISITS.EXAM_REQUIRED')
			alert(msg)
			return
		}

		// 15.04.2022 se sono tutti di una visita -> imposto il device
		let device = this.getDeviceFromSelection()

		const examsDates = myExamChoice.map((exam) => exam.date)
		const latestExamDate = new Date(Math.max(...examsDates))

		this.wizardModal = this.modalService.open(CategoriesController, { size: 'xl' })

		//categoryMngr.setModal(this.wizardModal);   // non serve piu' ? 31.01.2022

		this.wizardModal.componentInstance.currentPatient = this.currentPatient
		// this.wizardModal.componentInstance.setParent(this) // 07.10.2021
		this.wizardModal.componentInstance.setAction('diagnosis')
		this.wizardModal.componentInstance.setPatientId(this.patientId) // 21.12.201
		this.wizardModal.componentInstance.setVisit(0)
		this.wizardModal.componentInstance.setDevice(device) // prima di setExamChoice cosi' poi semmai ignora alcune categs
		this.wizardModal.componentInstance.exmList = myExamChoice
		this.wizardModal.componentInstance.setVisitDate(latestExamDate)

		// 09.02.2022
		this.wizardModal.result.then(
			(dt) => {
				Util.debug('V - After wizard modal closed: ' + dt) // dt = 'pdf'

				//dt e' categoryMngr.reportSent, boolean
				if (dt == true) {
					Util.debug('V - Report sent, going to refresh visit list...') // ok

					// refresh data
					this.refreshVisitsPage()

					// aggiorna lista esami refertati TODO refresh lista report presenti
					//this.refreshFlag = '' + new Date().getTime(); // per farlo sempre cambiare
				} else {
					Util.debug('V Closed wizard without sending report')
				}
			},
			(reason) => {
				let ris = 'V Dismissed wizard ' + this.getDismissReason(reason)
				Util.debug(ris)
			}
		)

		return
	}

	private refreshVisitsPage() {
		this.manageVisitList()
	}

	// 01.09.2020, simile a downloadReport
	downloadXml(visitId) {
		this.session
			.loadVisitXml(visitId) // lo richiede e decritta
			.then((myVisit) => {
				//.then((myXml) => {
				var myXml = null

				if (myVisit == null) {
					alert('(downloadXml) KO !')
					return
				} else {
					myXml = myVisit.xml_blob
				}

				if (myXml != null) {
					// gia' in chiaro

					// per la visita sul nomefile, sono piu' utili la data e il device che non l'id
					//var filename = "Pat"+this.patientId+"_visit" + visitId + "_details.xml";
					var visitDate = DateParser.formatSqlDate(myVisit.date)

					var filename = 'Pat' + this.patientId + '_' + visitDate + '_' + myVisit.device + '_details.xml'

					//if(myXml.indexOf('#')>0){
					//  alert("the xml contains a # character, it will be removed.");
					//myXml = myXml.replace('#', '\#'); // ko, trovare come sostituirlo
					//  myXml = myXml.replace('#', '');
					//}

					//if(myXml.indexOf('#')>0){
					//  alert("[2] xml contains a # character, it will be removed.");
					//  myXml = myXml.replace('#', '');
					//}
					// TODO, replaceAll

					//TODO: gestire lowercase, altri ?
					var charset = ''
					if (myXml.indexOf('encoding="UTF-8"') > 0) {
						charset = ';charset=utf-8'
					} else if (myXml.indexOf('encoding="UTF-16"') > 0) {
						charset = ';charset=utf-16'
					} else if (myXml.indexOf('encoding="ISO-8859-1"') > 0) {
						charset = ';charset=ISO-8859-1'
					}

					var element = document.createElement('a')
					//element.setAttribute('href', "data:text/plain;charset=utf-8,"+myXml);
					//element.setAttribute('href', "data:text/plain" + charset + "," + myXml);
					element.setAttribute('href', 'data:text/plain' + charset + ',' + encodeURIComponent(myXml))

					element.setAttribute('download', filename)
					element.style.display = 'none'
					document.body.appendChild(element) //Append the element to work in firefox
					element.click()
					//document.body.removeChild(element);  //  opz
				} // chiude if myXml not null
				else if (myXml.trim() == '') {
					// 07.02.2022
					alert('The xml is empty.')
					return
				}
			})
	}

	// ***************
	// SPECIALIST SIDE
	// ***************

	getExamLabel(exam_type) {
		//var label = "E: "+exam_type;
		var label = Exam.getExamLabel(exam_type)
		return label
	}

	isModSelected(visitId, examType) {
		var flag = false

		// 20.01.2022 added test
		if (this.selectedModules != null && this.selectedModules[visitId] != null && this.selectedModules[visitId][examType] != null) {
			flag = this.selectedModules[visitId][examType]
		}
		return flag
	}

	getDeviceFromSelection() {
		let device = ''
		for (let i = 0; i < this.totVisits; i++) {
			var visId = this.visitListRaw[i].id
			// selected module for this visit:
			var selected = this.selectedModules[visId]
			for (const [key, value] of Object.entries(selected)) {
				if (value) {
					// modulo selezionato
					Util.debug('(getDeviceFromSelection): i: ' + i + ' dev: ' + this.visitListRaw[i].device)
					//if(this.visList[i].device != device){
					if (device.indexOf(this.visitListRaw[i].device) == -1) {
						// non c'e' ancora  09.05.2022
						device += this.visitListRaw[i].device
					}
				}
			}
		}
		return device
	}

	getExamSelection() {
		var myExamChoice = []

		Util.debug('(getExamSelection) elenco moduli scelti: ')

		for (let i = 0; i < this.totVisits; i++) {
			var visId = this.visitListRaw[i].id

			// selected module for this visit:
			//console.log("(getExamSelection) selection on visit "+visId);
			var selected = this.selectedModules[visId]
			//console.log(selected); // ok

			for (const [key, value] of Object.entries(selected)) {
				//console.log(key, value); // ok
				if (value) {
					// modulo selezionato
					// prendo tutti gli esami con questo type di questa visita
					var allExams = this.visitListRaw[i].visitExams
					for (var m = 0; m < allExams.length; m++) {
						if (allExams[m].exam_type == key) {
							myExamChoice.push({ ...allExams[m], date: this.visitListRaw[i].date })
							Util.debug('(getExamSelection) visit:' + visId + ' exam type ' + key + ' id:' + allExams[m].id)
						}
					}
				}
			}
		}

		return myExamChoice
	}

	// preselect the most recent exams - TODO: multi-row preselection
	private preSelectModules(pos?) {
		if (this.visitListRaw == null || this.visitListRaw.length == 0) {
			Util.debug('(preSelect) null visit list ...')
			this.disableNewReportBtn = true // 14.10.2020
			return //  04.06.2020 niente da selezionare
		}

		if (!pos) pos = 0 // la piu' recente -> prima dell'array

		var lastVisit = null
		var modules = null
		var totPreSel = 0

		if (pos < this.visitListRaw.length) {
			lastVisit = this.visitListRaw[pos]
		}

		if (lastVisit != null) modules = lastVisit.getTypes()

		Util.debug('(preSelect) most recent visit: ' + lastVisit.id)
		//console.log(modules);

		if (modules != null)
			for (var i = 0; i < modules.length; i++) {
				//this.selectModule(1, modules[i]);

				// 30.07.2020 sceglie solo quelli non gia' fatti
				if (lastVisit.examTypes[i].is_reviewed != 'Y') {
					this.selectModule(lastVisit.id, modules[i])
					totPreSel++
				}
			}

		// 30.07.2020 se ha tutti gia' reviewed sull'ultima, sceglie altra visita
		if (totPreSel == 0) {
			var nextP = pos + 1
			if (this.visitListRaw.length > nextP) {
				//console.log("(preSelect) ricorsiva! "+nextP+" esaminata: "+lastVisit.id);
				this.preSelectModules(nextP)
			}
		} else {
			// 09.11.2020 fix, aggiunto else [ls]

			// 14.10.2020 aggiunto
			if (totPreSel > 0) {
				this.disableNewReportBtn = false
			} else {
				this.disableNewReportBtn = true
			}
			Util.debug('(preSelect) END, esco con  ' + this.disableNewReportBtn + ' last ' + lastVisit.id)

			// 16.11.2020 esamina se ce ne sono altri *diversi* su altre visite
			// valutare se si vuole...
			var myPos = this.preselectMulti(pos + 1) // ok, funziona

			// 19.05.2022 ripristinato
			// 31.01.2022 sospeso, in attesa di validazione lista preferenze [ls]
			// 16.11.2020 esamina se ce ne sono altri ma da un device piu' valido
			this.preselectByDevice(0) // riparte
		}
	}

	// 16.11.2020 multiple row selection
	private preselectMulti(pos) {
		// sono in ordine, parto dall'ultima visita esaminata dalla chiamante,
		// quelle prima sicuramente non hanno esami
		var lastVisit: Visit
		var device = ''

		// att. a non sforare
		if (pos < this.visitListRaw.length) {
			lastVisit = this.visitListRaw[pos]
			device = lastVisit.device
		} else {
			Util.debug('(preselectMulti) end 1')
			return pos // fine loop ricorsivo
		}

		// 18.11.2020 aggiunta soglia x non selez. visite troppo vecchie (cfr con accordi commerciali)
		var today = new Date()
		var maxDays = 10 * 24 * 60 * 60 * 1000 // 10 gg
		var tooOldDate = new Date(today.getMilliseconds() - maxDays)

		if (lastVisit.date < tooOldDate) {
			Util.debug('(preselectMulti) visit: ' + lastVisit.id + ' too old, ' + lastVisit.date.toISOString())
			return -1
		}

		Util.debug('(preselectMulti) visit: ' + lastVisit.id + ' device: ' + device)

		var modules = lastVisit.getTypes()

		if (modules != null) {
			for (var i = 0; i < modules.length; i++) {
				// 30.07.2020 sceglie solo quelli non gia' fatti
				var currV = lastVisit.examTypes[i]
				if (currV.is_reviewed != 'Y') {
					// trovato un potenziale candidato

					var selectedVisId = this.getAlreadySelected(currV.exam_type)

					if (selectedVisId < 0) {
						// non trovato, seleziono lui (selezione multi riga)
						//console.log("(preselectMulti) multi-riga");
						this.selectModule(lastVisit.id, modules[i])
					} else {
						// verra' gestito dalla preselectByDevice
					}
				}
			}
		}

		// esamino la successiva
		var nextP = pos + 1
		if (this.visitListRaw.length > nextP) {
			//console.log("(preselectMulti) ricorsiva! "+nextP+" esaminata: "+lastVisit.id);
			return this.preselectMulti(nextP)
		} else {
			Util.debug('(preselectMulti) end 2') // ok, esce qui
			return pos
		}
	}

	// 19.05.2022 cambiare VX160 in ER ? FIXME
	// 16.11.2020
	// richiamato dopo la preSelectModules,
	// esamina se ce ne sono altri ma da un device piu' valido
	// WF :  pre-selezionare quello fatto dal VX160, poi VX650
	// Topo:  pre-selezionare quello fatto dal VX650, poi VX160
	// PD: VX40 per primo - PD = pupil, not gradable by now
	/*
  The VX160 have priority on the RX, WF & PD
  The VX650 have the priority on the Topo (K reading)
  VX40 have the priority on the PD
  */
	private preselectByDevice(pos) {
		// sono in ordine, parto dall'ultima visita esaminata dalla chiamante,
		// quelle prima sicuramente non hanno esami

		var lastVisit: Visit
		var device = ''

		// att. a non sforare
		if (pos < this.visitListRaw.length) {
			lastVisit = this.visitListRaw[pos]
			device = lastVisit.device
		} else {
			Util.debug('(preselectByDevice) end 1')
			return // fine loop ricorsivo
		}

		//console.log("(preselectByDevice) visita: "+lastVisit.id+" device: "+device);

		// "VX40" for pupil, not gradable by now
		// questa visita potrebbe avere priorita'
		if (device == 'VX650' || device == 'VX160' || device == 'VX610') {
			//console.log("(preselectByDevice) visita: "+lastVisit.id+" potrebbe essere prioritaria rispetto ad altre gia' selezionate");
			var modules = lastVisit.getTypes()

			if (modules != null) {
				for (var i = 0; i < modules.length; i++) {
					// 30.07.2020 sceglie solo quelli non gia' fatti
					var currV = lastVisit.examTypes[i]
					if (currV.is_reviewed != 'Y') {
						// trovato un potenziale candidato

						var selectedVisId = this.getAlreadySelected(currV.exam_type)

						// controllo se tra quelli gia' selezionati c'e' uguale esame su device diverso
						// dovrebbe trovarlo sempre, visto che ho gia' chiamato la selectMulti
						if (selectedVisId < 0) {
							// non trovato, seleziono lui (selezione multi riga)
							Util.debug('(preselectByDevice) not found ?! ')
						} else if (selectedVisId != lastVisit.id) {
							// gia' selezionato, non se' stesso
							// cfr i device
							Util.debug('(preselectByDevice) visit: ' + selectedVisId + ' already selected a similar one')

							var flagPrime =
								(device == 'VX650' && currV.exam_type == Config.EXM_TOPO) ||
								(device == 'VX160' && currV.exam_type == Config.EXM_WF) ||
								(device == 'VX160' && currV.exam_type == Config.EXM_SBJ) ||
								(device == 'VX610' && currV.exam_type == Config.EXM_FUNDUS)
							if (flagPrime) {
								Util.debug('(preselectByDevice) visit: ' + lastVisit.id + ' exam: ' + currV.exam_type + ' by better device: ' + device)
								this.selectModule(lastVisit.id, modules[i])
							}
						} else {
							Util.debug('(preselectByDevice) visit: ' + selectedVisId + ' already selected')
						}
					}
				}
			}
		}

		// esamino la successiva
		var nextP = pos + 1
		if (this.visitListRaw.length > nextP) {
			//console.log("(preselectByDevice) verificare ricorsiva! "+nextP+" esaminata: "+lastVisit.id);
			return this.preselectByDevice(nextP)
		} else {
			Util.debug('(preselectByDevice) end 2') // ok, esce qui
			return
		}
	}

	//17.04.2020 handles select modules for specialists
	// se gia' selezionato, de-seleziona
	// se seleziona, deseleziona eventuali altri con stesso examType
	//selectModule(rowId, moduleId) {
	selectModule(visitId, examType) {
		var action = ''
		//console.log("(selectModule) visitId:"+visitId+" exam:"+examType); // +" rowId:"+rowId+" modId:"+moduleId);

		if (this.selectedModules[visitId][examType] == true) {
			// alreadySelected
			// revert to false
			this.selectedModules[visitId][examType] = false
			action = 'disabled'

			// 14.10.2020 verifica se era l'unico -> disab il bottone
			var totSelected = this.countSelectedModules()
			if (totSelected == 0) {
				this.disableNewReportBtn = true
			}
		} else {
			// ATT: sulla matrice non si cicla con indice da 0 a n ma su visitId
			// mette a false eventuale altro con lo stesso nome

			for (let i = 0; i < this.totVisits; i++) {
				var visId = this.visitListRaw[i].id
				if (visId != visitId) {
					if (this.selectedModules[visId] && this.selectedModules[visId][examType]) {
						this.selectedModules[visId][examType] = false
						break
					}
				}
			}

			// attiva lui
			this.selectedModules[visitId][examType] = true
			action = 'enabled'
			this.disableNewReportBtn = false // 14.10.2020 almeno uno c'e'
		}

		Util.debug('(selectModule) visitId:' + visitId + ' exam:' + examType + ' - ' + action)
	}

	// 14.10.2020 verificare se e' lento ?
	countSelectedModules() {
		var tot = 0
		for (let i = 0; i < this.totVisits; i++) {
			var visId = this.visitListRaw[i].id
			if (this.selectedModules[visId]) {
				for (var j = 0; j < Config.EXAM_TYPES.length; j++) {
					var examType = Config.EXAM_TYPES[j]
					if (this.selectedModules[visId][examType] == true) {
						tot++
					}
				}
			}
		}
		return tot
	}

	// 16.11.2020 verifica se e' gia' selezionato uno di questo tipo.
	// se si, ritorna la visitId
	private getAlreadySelected(examType) {
		var myVisitId = -1
		for (let i = 0; i < this.totVisits; i++) {
			var visId = this.visitListRaw[i].id
			if (this.selectedModules[visId]) {
				if (this.selectedModules[visId][examType] && this.selectedModules[visId][examType] == true) {
					myVisitId = visId
					break
				}
			}
		}
		return myVisitId
	}

	openCategoriesModal(visit?: Visit) {
		// console.log(visit)
		if (visit) {
			// check if account is frozen and visit was created after frozen period start date
			if (!visit.can_see && this.session.isOptician()) {
				let header = this.translator.instant('TOAST.HEADER.WARNING')
				let body = this.translator.instant('TOAST.NOTIFICATIONS.INVALID_VISIT_SUBSCRIPTION')
				let options = new ToastOptions('notification_s')

				this.toastService.show(header, body, false, options, 'center')

				return false
			}

			// 11.11.2020 filtro pupil
			if (visit.is_visible == 'H') {
				// hidden
				//var msg = "Exam display not avaliable yet";
				var msg = this.translator.instant('VISITS.NOT_AVAILABLE')
				alert(msg)
				return false
			}
		}

		// 09.09.2021 visita vuota
		if (visit.isEmpty()) {
			//var msg2 = "nothing to display, empty visit";
			var msg2 = this.translator.instant('VISITS.EMPTY_VISIT_WARN')
			alert(msg2)
			return false
		}

		// 23.01.2023 patch
		// if (this.session.isLoadingPatient()) {
		// 	alert('still loading patient, please wait...') // TODO portare su json
		// 	return
		// }

		// 25.05.2022 mette la visit_id sulla url, patch per diagonal
		if (visit && visit.id > 0) {
			//let newP = document.location.search + "&visit="+visitId ;
			// document.location.search = newP;		// ko, fa refresh
			window.location.hash = 'visit=' + visit.id // mette # davanti
		}

		var myExamChoice: ExamType[]
		myExamChoice = null

		// per i livelli 1, prende tutti quelli della visita,
		// per i livelli 2  scelta dinamica

		if (visit) {
			// ha senso solo per i livelli 1 e view
			Util.debug('(openCategoriesModal) visitId: ' + visit.id)
			myExamChoice = visit.visitExams
		} else {
			// per i refertatori, diagnosis
			myExamChoice = this.getExamSelection()
		}

		this.currentModal = this.modalService.open(CategoriesController, { size: 'xl' })

		// valutare se serve, con le injection nel costruttore
		// per poi chiamare session o altre globali
		//this.currentModal.componentInstance.parent = this;

		this.currentModal.componentInstance.setAction('view')
		this.currentModal.componentInstance.setPatientId(this.patientId) // 21.12.201
		this.currentModal.componentInstance.setVisit(visit.id)
		this.currentModal.componentInstance.setVisitDate(visit.date)
		this.currentModal.componentInstance.currentPatient = this.currentPatient

		// per i livelli 1, prende tutti quelli della visita,
		// per i livelli 2  scelta dinamica, ma potrebbe anche essere di una singola visita
		if (visit) {
			Util.debug('(openCategoriesModal) visitId: ' + visit.id)
			this.currentModal.componentInstance.setVisit(visit.id)
			this.currentModal.componentInstance.setDevice(visit.device) // 25.08.2022	per report vistel
		} else {
			this.currentModal.componentInstance.setVisit(0) // reset
		}

		if (myExamChoice != null) {
			// 03.06.2020 spostato qui da categories [ls]
			// svuota precedenti array e prepara per nuova visualizzazione
			// da richiamare solo la prima volta, dopo che e' stata fatta
			// una selezione della visita o dei moduli (per i refertatori)
			this.session.initCategories()
			this.currentModal.componentInstance.exmList = myExamChoice
		}

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(dt) => {
				Util.debug('V COMP - Categories - After modal closed: ' + dt) // dt = 'pdf'
				//this.submitForm(dt);
				window.location.hash = '' // 25.05.2022 patch per diagonal

				// 25.08.2022 - 27.07.2022 dt e' force refresh of AI Report list, boolean
				if (dt == true && this.session.isLevel1()) {
					Util.debug('V - AI Report requested, going to refresh AI list...')
					// this.loadAiReports()
				}
			},
			(reason) => {
				let ris = 'V Dismissed ' + this.getDismissReason(reason)
				Util.debug(ris)
				window.location.hash = '' // 25.05.2022 patch per diagonal
			}
		)

		//categoryMngr.setModal(this.currentModal);
	}

	public downloadPDF(visit: Visit) {
		// console.log(visit)
		if (visit.can_see && !visit.is_waiting_PDF) {
			visit.is_waiting_PDF = true
			this.reportsService
				.printPDF(visit, ReportType.EXAMS)
				.then((resp) => {
					visit.is_waiting_PDF = false
				})
				.catch((err) => {
					visit.is_waiting_PDF = false

					let header = this.translator.instant('TOAST.HEADER.WARNING')
					let body = this.session.parseErrorMessage(err)
					let options = new ToastOptions('error')

					this.toastService.show(header, body, false, options, 'center')
				})
		} else {
			return
		}
	}

	//*************
	// ADD MANUAL DEVICES AND VISITS
	//**************

	public openDeviceModal() {
		this.currentAction = 'createDevice'

		this.currentModal = this.modalService.open(addDeviceModal, { size: 'lg' })
		this.currentModal.componentInstance.currentPatientId = this.currentPatient.id

		this.currentModal.result.then(
			(confirmed) => {
				Util.debug('(createVisit) After modal closed: ' + confirmed)

				// cambiare con signalR
				if (confirmed) {
					this.manageVisitList()
				}
			},
			(reason) => {
				Util.debug('Dismissed ')
			}
		)
	}

	// 15.04.2020 solo per test
	openVisitModal() {
		if (this.session.user.getDevices().length > 0) {
			this.currentAction = 'createVisit'

			this.currentModal = this.modalService.open(VisitListModal, { size: 'lg' })
			this.currentModal.componentInstance.currentAction = this.currentAction
			this.currentModal.componentInstance.currentPatientId = this.currentPatient.id

			this.currentModal.result.then(
				(confirmed) => {
					Util.debug('(createVisit) After modal closed: ' + confirmed)

					if (confirmed) {
						this.manageVisitList()
					} else {
						Util.debug('(createVisit) - Dismissed with cross click.')
					}
				},
				(reason) => {
					Util.debug('(createVisit) Dismissed ' + Util.getDismissReason(reason))
				}
			)
		} else {
			alert('You must create at least one device first')
			return
		}
	}

	// 31.03.2020 solo per test, modal per caricare un esame (con critt delle immagini)
	openExamModal() {
		if (this.visitListRaw.length > 0) {
			this.currentAction = 'createExam'

			this.currentModal = this.modalService.open(VisitListModal, { size: 'xl' })
			this.currentModal.componentInstance.currentAction = this.currentAction
			this.currentModal.componentInstance.currentPatientId = this.currentPatient.id
			// this.currentModal.componentInstance.lastVisitId = this.visitListRaw[0].id
			this.currentModal.componentInstance.visitList = this.visitListRaw

			this.currentModal.result.then(
				(confirmed) => {
					Util.debug('(createExam) After modal closed: ' + confirmed)

					if (confirmed) {
						this.manageVisitList()
					} else {
						Util.debug('(createExam) - Dismissed with cross click.')
					}
				},
				(reason) => {
					Util.debug('(createExam) Dismissed ' + Util.getDismissReason(reason))
				}
			)
		} else {
			var msg = this.translator.instant('VISITS.VISIT_REQUIRED')
			alert(msg)
			return
		}
	}

	isVisitVisible(visit) {
		return visit.is_visible == 'Y'
	}

	filterText() {
		this.visitPref.filter = this.input.nativeElement.value

		this.visitPref.filter = this.visitPref.filter.trim().toLocaleLowerCase()

		this.visitList.filter = this.visitPref.filter
	}

	private initFakeExams() {
		//this.exam = FundusExam.getFakeExam();   // 17.09.2020
		this.exam = LensmeterExam.getFakeExam() // 27.10.2020

		this.exam.patient_id = this.currentPatient.id

		this.visitListRaw = []
		this.loadingVisitList = true

		this.demoVisit = new Visit()
		this.demoVisit.patient_id = this.currentPatient.id
		this.demoVisit.name = 'All - demo'
		this.demoVisit.date = new Date()
		this.demoVisit.device = 'VX600'
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.visitPref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.visitPref.itemsPerPage))
		// clear all ReportAI Request
		// this.visitListService.clearAllReportAiReq()
		// this.visitListService.clearAiReportList()
		this.creditsChangesSubscription.unsubscribe()
	}
}
