import { EventEmitter, Injectable, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { Toast, ToastOptions, locStorageNotify } from '../models/toast.model'
import { Util } from '../models/util.model'
import { SaleInfo, service } from 'src/app/models/salePlan.model'
import { Config } from 'src/config'

import { canvasService } from './offcanvas.service'
import { SessionService } from './session.service'
import { User } from '../models/user.model'
import { environment } from 'src/environments/environment'
import { ActivatedRoute } from '@angular/router'

import * as Sentry from '@sentry/angular-ivy'

@Injectable({ providedIn: 'root' })
export class AppToastService {
	//
	//
	init: boolean
	onlyCredits: boolean
	saleInfo: SaleInfo
	notifications: locStorageNotify[]
	user: User
	//
	//
	constructor(
		private canvasService: canvasService,
		private session: SessionService,
		private translator: TranslateService,
		private activatedRoute: ActivatedRoute
	) {
		Util.debug('ToastService go')
		this.init = false // with this variable I can check when I have to check notifications, only after login, when moving through pages the notifications will check only one time. Set true on patientList and set false on logout
		this.onlyCredits = false // used on visitList to show notification of only credits and not others seen on patient list
		this.saleInfo = new SaleInfo(null)
		this.notifications = []
		this.user = null
	}

	@Output() position = new EventEmitter<string>()

	toasts: Toast[] = []

	// for simple notifications
	show(header: string, body: string, canvas: boolean, options: ToastOptions, position: string, audio?: boolean) {
		this.position.emit(position)

		if (audio) {
			let audio = new Audio()
			audio.src = 'assets/sounds/ping.mp3'
			audio.load()
			audio.play()
		}

		this.toasts.push({ header, body, canvas, options })

		if (canvas) {
			let date = new Date()
			this.canvasService.addNotification({ header, body, canvas, options, date })
		}
	}

	remove(toast: Toast) {
		if (toast.options.onClose) {
			toast.options.onClose()
		}
		this.toasts = this.toasts.filter((t) => t !== toast)
	}

	clear() {
		// if any toast had an onClose callback, fire it
		this.toasts.forEach((toast) => {
			if (toast.options.onClose) {
				toast.options.onClose()
			}
		})
		this.toasts.splice(0, this.toasts.length)
	}

	private addCanvasNotifications(toast: Toast) {
		this.canvasService.addNotification(toast)
	}

	private removeCanvasNotifications(toast: Toast) {
		this.canvasService.remove(toast)
	}

	private clearCanvasNotifications() {
		this.canvasService.clear()
	}

	// Called from patientList component and VisitList
	public checkNotifications() {
		if (!this.init) {
			Util.debug('(ToastService) - checkNotifications')

			this.init = true

			this.user = this.session.user
			// console.log(this.user)

			if (this.session.isSpecialist()) {
				if (this.user.status == 'disabled') {
					let header = this.translator.instant('TOAST.NOTIFICATIONS.GR_STATUS')
					let body = this.translator.instant('TOAST.NOTIFICATIONS.GR_DISABLED')

					if (this.session.user.vacancies.length !== 0 && this.session.user.vacancies[0].from_date && this.session.user.vacancies[0].to_date) {
						const fromDate = new Date(this.session.user.vacancies[0].from_date)
						const toDate = new Date(this.session.user.vacancies[0].to_date)

						if (!this.session.isMini() && this.session.user.isInUnavailableRange(fromDate, toDate)) {
							body = this.translator.instant('TOAST.NOTIFICATIONS.GR_DISABLED_VACANCY')
						}
					}

					let options = new ToastOptions('notification_s')

					this.show(header, body, false, options, 'center')
				}
			}

			if (this.session.isOptician()) {
				//----------
				// NEW REPORTS
				//----------
				if (!this.onlyCredits) {
					this.session.checkNewReports().then((resp) => {
						if (resp != null && resp.reports != null && resp.reports.length > 0) {
							const reportsCount = resp.reports.length
							const header = this.translator.instant('REPORT.NEW_REPORTS')
							const body = this.translator.instant('REPORT.NEW_REPORTS_NOTIFICATION_BODY', { reportsCount })
							const options = new ToastOptions('info_green')
							const toast = new Toast(header, body, true, options)

							this.show(header, body, false, options, 'center')
							this.addCanvasNotifications(toast)
							Util.debug('HG - new reports: ' + reportsCount)
						} else {
							Util.debug('HG - no new reports')
						}
					})

					//----------
					// SPECIALISTS AVAILABILITY
					//----------
					// find a specialist that has an unavailability scheduled
					const specialistWithVacancies = this.user.specialists.find((spec) => {
						return spec.vacancies.length > 0
					})

					if (specialistWithVacancies) {
						// utc date manipulation
						const fromDate = new Date(specialistWithVacancies.vacancies[0].from_date)
						const toDate = new Date(specialistWithVacancies.vacancies[0].to_date)
						const fromDateUtc = new Date(Date.UTC(fromDate.getUTCFullYear(), fromDate.getUTCMonth(), fromDate.getUTCDate()))
						const toDateUtc = new Date(Date.UTC(toDate.getUTCFullYear(), toDate.getUTCMonth(), toDate.getUTCDate()))

						// unavailability lasts more than one day && unavailability is active
						if (this.getDayDiff(fromDateUtc, toDateUtc) !== 0 && this.session.user.isInUnavailableRange(fromDate, toDate)) {
							this.session.getTotGraders().then((res) => {
								const totGraders = res.graders_available
								const header = this.translator.instant('TOAST.NOTIFICATIONS.UNAVAILABILITY_HEADER')
								let body = this.translator.instant('TOAST.NOTIFICATIONS.UNAVAILABILITY_MAIN_NOTIFICATION', {
									grader: specialistWithVacancies.display_name,
									fromDate: this.session.formatDate(fromDateUtc),
									toDate: this.session.formatDate(toDateUtc),
								})
								const options = new ToastOptions('info_blue')

								if (totGraders === 1) {
									body += ' ' + this.translator.instant('TOAST.NOTIFICATIONS.UNAVAILABILITY_SINGLE_GRADER')
								} else if (specialistWithVacancies.user_subtype === Config.SUB_PRIVATE) {
									body += ' ' + this.translator.instant('TOAST.NOTIFICATIONS.UNAVAILABILITY_PRIVATE_GRADER')
								} else {
									body += ' ' + this.translator.instant('TOAST.NOTIFICATIONS.UNAVAILABILITY_VX_GRADER')
								}

								const toast = new Toast(header, body, true, options)
								this.show(header, body, false, options, 'center')
								this.addCanvasNotifications(toast)
							})
						}
					}

					//----------
					// CHECK DEVICE GMT
					//----------
					this.checkDevicesGMT()
				}
				let userId = this.session.getUserId()

				this.session
					.loadUserPlan(userId)
					.then((salesinfo) => {
						this.saleInfo = salesinfo
						// console.log(this.saleInfo)

						let creditNotification = this.getLocalStorageNotifications(Config.CREDITS)
						let subscriptionNotification = this.getLocalStorageNotifications(Config.SUBSCRIPTION) // notifica della subscription
						// let relationNotification = this.getLocalStorageNotifications(Config.RELATION)
						let reminderNotification = this.getLocalStorageNotifications(Config.REMINDER)
						let advancedAiUnlimited = this.getLocalStorageNotifications(Config.AIUNLIMIT)

						let body = ''

						//----------
						// NEW DEVICE WITH PURCHASED SERVICES
						//----------
						if (!this.onlyCredits) {
							this.noticeNewDeviceWithPurchasedServices()
						}

						//----------------------------
						// CREDITS
						//------------------------------
						if (this.onlyCredits) {
							this.checkCreditsNotifications()
						}

						if (this.saleInfo.salePlan.isFree() && !this.onlyCredits) {
							//
							// -------------------
							// FREE TRIAL
							//-------------------

							if (this.session.isFreeTrialAvailable()) {
								let options = new ToastOptions('notification')

								const daysSinceSubscription = this.getDayDiff(this.user.subscriptionTime, new Date())
								const shouldRemind = daysSinceSubscription < 30 && daysSinceSubscription >= 0

								if (shouldRemind) {
									const reminderDays = 30 - daysSinceSubscription
									const reminderHeader = this.translator.instant('TOAST.NOTIFICATIONS.FREE_TRIAL')
									const reminderBody = this.translator.instant('TOAST.NOTIFICATIONS.REMINDER', { reminderDays })
									options.name = Config.REMINDER
									options.cta = this.session.isEcommerceEnabled() ? Config.ecommerce : ''

									if (!reminderNotification.hide) {
										Util.debug('ToastService - freeTrial - Non é hide')

										this.show(reminderHeader, reminderBody, true, options, 'center')

										reminderNotification.status = Config.RED
									} else {
										Util.debug('ToastService - Relation - É hide')

										const toast = new Toast(reminderHeader, reminderBody, true, options, reminderNotification.date)

										this.addCanvasNotifications(toast)
									}
									this.pushLocalStorageNotification(reminderNotification)
									// this.notifications.push(reminderNotification)
								}
							}
							// -------------------
							// END GRACE PERIOD
							//-------------------
							// teoricamente se sono free e ho nella storage la notifica della subscription red, vuol dire che ero nel grace period
							if (subscriptionNotification.status == Config.RED) {
								Util.debug('ToastService - Subscription status = one or more service expired ')
								let options = new ToastOptions('notification')
								let header = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION')
								body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION4')
								options.name = Config.SUBSCRIPTION

								this.show(header, body, true, options, 'center')

								subscriptionNotification.status = Config.GREEN // la setto green cosí viene notificata solo 1 volta
							}
						}
						// this.notifications.push(storageNotification) //meglio qui o all'interno dell'if?
						//---------------------------------
						// EXPIRY DATE
						//---------------------------------
						if (this.saleInfo.salePlan.isMiddle() || (this.saleInfo.salePlan.isAdvanced() && !this.onlyCredits)) {
							let header = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION')
							let notify = false
							let options = new ToastOptions('notification')
							options.name = Config.SUBSCRIPTION
							options.cta = Config.subscription

							let services: service[] = []

							if (this.saleInfo.flagExpiry == Config.YELLOW) {
								services = this.saleInfo.getNDMservices(Config.YELLOW)
								// console.log(services)

								if (services.length > 1) {
									body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION1_2')
								} else {
									body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION1_1', {
										device: services[0].device_model[0],
										device_sn: services[0].device_sn,
										end_date: this.session.formatDate(services[0].end_date),
									})
								}

								notify = true
							} else if (this.saleInfo.flagExpiry == Config.RED) {
								// non ci arriva mai, il cambio da giallo passa subito a expired, qui dovrebbe rimanere il giorno della scadenza almeno - lato api
								services = this.saleInfo.getNDMservices(Config.RED)
								// console.log(services)

								if (services.length > 1) {
									body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION2_2')
								} else {
									body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION2_1', {
										device: services[0].device_model[0],
										device_sn: services[0].device_sn,
										end_date: this.session.formatDate(services[0].end_date),
									})
								}
								notify = true
							} else {
								subscriptionNotification = new locStorageNotify(Config.SUBSCRIPTION, Config.GREEN, false) // setto la notifica green in quanto non sono passatto per le condizioni sopra
							}

							if (notify) {
								if (subscriptionNotification.status != this.saleInfo.flagExpiry) {
									Util.debug('ToastService - Subscription - status != flagCredits')

									this.show(header, body, true, options, 'center')

									subscriptionNotification.status = this.saleInfo.flagExpiry
								} else {
									if (!subscriptionNotification.hide) {
										Util.debug('ToastService - Subscription - Non é hide')

										this.show(header, body, true, options, 'center')

										subscriptionNotification.status = this.saleInfo.flagExpiry
									} else {
										Util.debug('ToastService - Subscription - É hide')

										let date = new Date()

										let toast = new Toast(header, body, true, options, date)
										// console.log(toast)
										this.addCanvasNotifications(toast)
									}
								}

								// console.log(this.notifications)
							}
							// -------------------
							// GRACE PERIOD
							//-------------------
						} else if (this.saleInfo.salePlan.isExpired() && !this.onlyCredits) {
							//
							Util.debug('ToastService - Subscription status = Expired on grace period')
							let options = new ToastOptions('notification')
							let services: service[] = []
							services = this.saleInfo.getNDMservices(Config.RED)
							// console.log(services)
							if (services.length > 0) {
								if (services.length > 1) {
									body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION3_2')
								} else {
									let gracePeriodExpiryDate = Util.addDays(services[0].end_date, 30)
									body = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION3_1', {
										device: services[0].device_model[0],
										device_sn: services[0].device_sn,
										end_date: this.session.formatDate(services[0].end_date),
										gracePeriodExpiryDate: this.session.formatDate(gracePeriodExpiryDate),
									})
								}

								let header = this.translator.instant('TOAST.NOTIFICATIONS.SUBSCRIPTION')

								options.name = Config.SUBSCRIPTION
								options.cta = Config.subscription

								if (!subscriptionNotification.hide) {
									Util.debug('ToastService - Subscription - Non é hide')
									this.show(header, body, true, options, 'center')
								} else {
									Util.debug('ToastService - Subscription - É hide')

									let date = new Date()

									let toast = new Toast(header, body, true, options, date)
									// console.log(toast)
									this.addCanvasNotifications(toast)
								}
							}

							subscriptionNotification.status = this.saleInfo.flagExpiry // this.saleInfo.flagExpiry dovrebbe essere RED, xk era l último stato sopra
						}

						// this.notifications.push(subscriptionNotification)
						this.pushLocalStorageNotification(subscriptionNotification)

						//----------------------------
						// CREDITS && not HG connected
						//----------------------------

						if (this.saleInfo.salePlan.isAdvanced()) {
							// unlimited ai plan
							// if (this.saleInfo.salePlan.isUnlimitedAi() && this.user.saleInfo.available_credits == 0 && !this.onlyCredits) {
							// 	Util.debug('ToastService - Unlimited ai plan notification')
							// 	let header = this.translator.instant('CREDITS.AI_UNL_TITLE')
							// 	let body = this.translator.instant('CREDITS.AI_UNL')
							// 	let options = new ToastOptions('info_blue')
							// 	options.name = Config.AIUNLIMIT
							// 	if (!advancedAiUnlimited.hide) {
							// 		Util.debug('ToastService - UnlimitedAI non é hide')
							// 		this.show(header, body, true, options, 'center')
							// 		advancedAiUnlimited.status = Config.RED
							// 	} else {
							// 		Util.debug('ToastService - UnlimitedAI é hide')
							// 		//non credo abbia senso mostrare una info nel canvas ogni volta
							// 		// let date = new Date()
							// 		// let toast = new Toast(header, body, true, options, date)
							// 		// this.addCanvasNotifications(toast)
							// 	}
							// 	// this.notifications.push(advancedAiUnlimited)
							// 	this.pushLocalStorageNotification(advancedAiUnlimited)
							//----------------------------
							// CREDITS
							//------------------------------
							// } else {
							// 	let options = new ToastOptions('notification')
							// 	let header = this.translator.instant('TOAST.NOTIFICATIONS.CREDITS')
							// 	let notify = false
							// 	options.name = Config.CREDITS
							// 	if (this.saleInfo.flagCredits == 'yellow') {
							// 		body = this.translator.instant('TOAST.NOTIFICATIONS.CREDIT1') + '<strong>' + this.saleInfo.available_credits + '</strong>'
							// 		notify = true
							// 	} else if (this.saleInfo.flagCredits == 'red') {
							// 		body = this.translator.instant('TOAST.NOTIFICATIONS.CREDIT2') + '<strong>' + this.saleInfo.available_credits + '</strong>'
							// 		notify = true
							// 	} else {
							// 		creditNotification.status = Config.GREEN // setto la notifica green in quanto non sono passatto per le condizioni sopra
							// 	}
							// 	if (notify) {
							// 		// console.log(creditNotification)
							// 		if (creditNotification.status != this.saleInfo.flagCredits) {
							// 			Util.debug('ToastService - Credits -status != flagCredits')
							// 			this.show(header, body, true, options, 'center')
							// 			creditNotification.status = this.saleInfo.flagCredits
							// 		} else {
							// 			if (!creditNotification.hide) {
							// 				Util.debug('ToastService - Credits - Non é hide')
							// 				this.show(header, body, true, options, 'center')
							// 				creditNotification.status = this.saleInfo.flagCredits
							// 			} else {
							// 				Util.debug('ToastService - Credits - É hide')
							// 				let date = new Date()
							// 				let toast = new Toast(header, body, true, options, date) // a differenza dello storage, la data la cambio sempre, altrimenti se hide, quando scalo altri crediti, la notifica sul canvas viene generata nuovamente ma con la sstessa data e crediti diversi
							// 				// console.log(toast)
							// 				this.addCanvasNotifications(toast)
							// 			}
							// 		}
							// 	}
							// 	// }
						}
						// this.onlyCredits = false
						// // this.notifications.push(creditNotification) //meglio qui o all'interno dell'if?
						// this.pushLocalStorageNotification(creditNotification)

						// console.log(this.notifications)

						this.setLocalStorageNotification()
					})
					.catch((err) => {
						console.log(err)
					})
			}
		}
	}

	//----------------------------
	// CREDITS
	//------------------------------
	public checkCreditsNotifications() {
		// da migliorare che aggiorni automaticamente con un observables ad esempio il saleinfo qui nelle notifiche
		this.saleInfo = this.session.user.saleInfo
		if (this.saleInfo.salePlan.isAdvanced() && this.session.isOptician() && !this.session.userHasPrivateGrader()) {
			Util.debug('ToastService - checkCreditsNotifications')

			let creditNotification = this.getLocalStorageNotifications(Config.CREDITS)

			let options = new ToastOptions('notification')
			let header = this.translator.instant('TOAST.NOTIFICATIONS.CREDITS')
			let body = ''
			options.name = Config.CREDITS

			if (this.saleInfo.flagCredits == Config.GREEN) {
				// rimuovo eventuali toast rimasti da notifiche precedenti
				let canvasToast: Toast[] = this.canvasService.getCanvasToastByName(Config.CREDITS)

				creditNotification.hide = false
				creditNotification.status = Config.GREEN

				if (canvasToast && canvasToast.length > 0) {
					canvasToast.forEach((element) => {
						this.removeCanvasNotifications(element)
					})
				}
				this.pushLocalStorageNotification(creditNotification)
			} else {
				if (this.saleInfo.flagCredits == Config.YELLOW) {
					body = this.translator.instant('TOAST.NOTIFICATIONS.CREDIT1') + '<strong>' + this.saleInfo.available_credits + '</strong>'
				}

				if (this.saleInfo.flagCredits == Config.RED) {
					body = this.translator.instant('TOAST.NOTIFICATIONS.CREDIT2') + '<strong>' + this.saleInfo.available_credits + '</strong>'
				}

				if (this.saleInfo.flagCredits == Config.GRAY) {
					let error = new Error('Wrong migration, something worng on services')
					Sentry.setUser({
						username: this.user.username,
					})
					Sentry.captureException(error)
					throw error
				}

				if (creditNotification.status != this.saleInfo.flagCredits) {
					Util.debug('ToastService - Credits -status != flagCredits')

					this.show(header, body, true, options, 'center')

					creditNotification.status = this.saleInfo.flagCredits
				} else {
					if (!creditNotification.hide) {
						Util.debug('ToastService - Credits - Non é hide')

						this.show(header, body, true, options, 'center')

						creditNotification.status = this.saleInfo.flagCredits
					} else {
						Util.debug('ToastService - Credits - É hide')
						let canvasToast: Toast[] = this.canvasService.getCanvasToastByName(Config.CREDITS)
						if (canvasToast && canvasToast.length > 0) {
							canvasToast.forEach((element) => {
								this.removeCanvasNotifications(element)
							})
						}

						let date = new Date()

						let toast = new Toast(header, body, true, options, date) // a differenza dello storage, la data la cambio sempre, altrimenti se hide, quando scalo altri crediti, la notifica sul canvas viene generata nuovamente ma con la sstessa data e crediti diversi
						// console.log(toast)
						this.addCanvasNotifications(toast)
					}
				}
				this.pushLocalStorageNotification(creditNotification)
			}

			this.setLocalStorageNotification()
		}
	}

	public checkDevicesGMT() {
		Util.debug('ToastService - checkDevicesGMT')

		let canvasToast: Toast[] = this.canvasService.getCanvasToastByName(Config.GMTOFFSET)
		if (canvasToast && canvasToast.length == 0) {
			let gmtNotification = this.getLocalStorageNotifications(Config.GMTOFFSET)

			let devices = this.session.user.getDevices()
			let addressOffset = this.session.user.getMainAddressOffset()

			if (devices.length > 0 && addressOffset != 0) {
				devices.forEach((device) => {
					if (device.gmt_offset && device.gmt_offset != '') {
						if (device.gmt_offset == Util.secondsToTime(addressOffset)) {
							device.setGMToffsetState(1)
						} else {
							device.setGMToffsetState(2)
						}
					}
				})
			}

			let notify: boolean = false

			let devicesWithWrongGMT = devices.filter((device) => device.gmt_offset_state == 2)

			let msg = ''

			if (devicesWithWrongGMT.length > 0) {
				notify = true

				msg = this.translator.instant('TOAST.NOTIFICATIONS.WRONG_GMT_TIME1', { model: devicesWithWrongGMT[0].model, sn: devicesWithWrongGMT[0].sn })

				if (devicesWithWrongGMT.length > 1) {
					msg = this.translator.instant('TOAST.NOTIFICATIONS.WRONG_GMT_TIME2')
				}
			}

			if (notify) {
				let header = this.translator.instant('TOAST.HEADER.WARNING')
				let body = msg
				let options = new ToastOptions('notification')
				options.name = Config.GMTOFFSET

				if (!gmtNotification.hide) {
					this.show(header, body, true, options, 'center', true)
					gmtNotification.status = Config.RED
				} else {
					let date = new Date()
					let toast = new Toast(header, body, true, options, date)
					this.addCanvasNotifications(toast)
				}

				this.pushLocalStorageNotification(gmtNotification)
			} else {
				// rimuovo eventuali toast rimasti da notifiche precedenti
				let canvasToast: Toast[] = this.canvasService.getCanvasToastByName(Config.GMTOFFSET)

				gmtNotification.hide = false
				gmtNotification.status = Config.GREEN

				if (canvasToast && canvasToast.length > 0) {
					canvasToast.forEach((element) => {
						this.removeCanvasNotifications(element)
					})
				}

				this.pushLocalStorageNotification(gmtNotification)
			}
			this.setLocalStorageNotification()
		}
	}

	public noticeNewDeviceWithPurchasedServices() {
		Util.debug('ToastService - noticeNewDeviceWithPurchasedServices')

		let serviceNotification = this.getLocalStorageNotifications(Config.SERVICE_PURCHASED)

		let hasPurchasedServices = this.session.user.hasPurchasedServices()
		let hasDevicesWithoutService = this.session.user.hasDevicesWithoutService()
		let notify: boolean = false

		if (hasDevicesWithoutService.res && hasPurchasedServices.res) {
			//cerco di capire se ci sono servizi assegnabili ai dispositivi che ho, altrimenti non mostro la notifica
			let servicesPurchasedModels = [
				...new Set(
					hasPurchasedServices.services.flatMap((el) => {
						if (el.device_model && el.device_model.length > 0) {
							return el.device_model
						} else {
							return ''
						}
					})
				),
			]

			servicesPurchasedModels.forEach((model) => {
				if (model) {
					if (hasDevicesWithoutService.models.find((el) => el == model)) {
						notify = true
					}
				} else {
					// per i servizi NDM non ho il modello nel service, quindi vanno bene su tutti i device, quindi devo notificare cmq
					notify = true
				}
			})
		}
		// volendo, invece che notify si puó gestire una notifica diversa per il caso in cui non ci siano dispositivi validi per i servizi acquistati

		if (notify) {
			let header = this.translator.instant('TOAST.HEADER.WARNING')
			let body = this.translator.instant('TOAST.NOTIFICATIONS.NEW_DEVICE_WITH_SERVICES_PURCHASED')
			let options = new ToastOptions('info_blue')
			options.cta = Config.subscription
			options.name = Config.SERVICE_PURCHASED

			let route = this.activatedRoute.snapshot.children[0].routeConfig.path

			if (!serviceNotification.hide && route != 'subscription') {
				this.show(header, body, true, options, 'center', true)
				serviceNotification.status = Config.RED
			} else {
				let date = new Date()
				let toast = new Toast(header, body, true, options, date)
				this.addCanvasNotifications(toast)
			}

			this.pushLocalStorageNotification(serviceNotification)
		} else {
			if (hasPurchasedServices.res) {
				this.canvasService.updateNotifications()
			}
			// rimuovo eventuali toast rimasti da notifiche precedenti
			let canvasToast: Toast[] = this.canvasService.getCanvasToastByName(Config.SERVICE_PURCHASED)
			serviceNotification.hide = false
			serviceNotification.status = Config.GREEN

			if (canvasToast && canvasToast.length > 0) {
				canvasToast.forEach((element) => {
					this.removeCanvasNotifications(element)
				})
			}

			this.pushLocalStorageNotification(serviceNotification)
		}

		this.setLocalStorageNotification()
	}

	private setLocalStorageNotification() {
		const localStorageNotification = this.session.getUserId() + ' - Notifications'
		localStorage.setItem(localStorageNotification, JSON.stringify(this.notifications))
	}

	private pushLocalStorageNotification(notification: locStorageNotify) {
		let notify = this.notifications.find((el) => el.header == notification.header)

		if (notify) {
			let index = this.notifications.indexOf(notify)

			this.notifications.splice(index, 1)

			this.notifications.push(notification)
		} else {
			this.notifications.push(notification)
		}
	}

	private getLocalStorageNotifications(header: string): locStorageNotify {
		// leggo la localStorage e mi ricavo le varie notifiche salvate
		const localStorageNotification = this.session.getUserId() + ' - Notifications'
		let notifications = localStorage.getItem(localStorageNotification)
		this.notifications = JSON.parse(notifications) // array di notifiche

		if (!this.notifications) {
			this.notifications = []
		}

		let notify = this.notifications.find((el) => el.header == header)

		if (notify) {
			return notify
		} else {
			return new locStorageNotify(header, Config.GREEN, false)
		}
	}

	getDayDiff(startDate: Date, endDate: Date): number {
		// funzione per trovare il numero di giorni alla scadenza del profilo
		if (Number(startDate) > Number(endDate)) {
			console.log('Account expired')

			return -1
		}
		const msInDay = 24 * 60 * 60 * 1000
		return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay)
	}
}
