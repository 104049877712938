import { Component, Input, input, OnDestroy, OnInit } from '@angular/core'
import { NgbActiveModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { Patient } from 'src/app/models/patient.model'
import { SessionService } from 'src/app/service/session.service'

import { Util } from 'src/app/models/util.model'
import { DataModelService } from 'src/app/service/data-model.service'
import { Report, ReportStatus } from 'src/app/models/report.model'
import { TranslateService } from '@ngx-translate/core'
import { reportsService } from 'src/app/service/reports.service'
import { AiReport } from 'src/app/models/aiReport.model'
import { Observable, Subscription } from 'rxjs'

export enum ReportsTab {
	HGREPORT = 'hgreport',
	AIREPORT = 'aireport',
}

@Component({
	selector: 'patient-report-modal',
	templateUrl: './reports.modal.html',
	styleUrls: ['./reports.modal.scss'],
})
export class PatientReportModal implements OnInit, OnDestroy {
	@Input() patient: Patient

	isLoadingHG: boolean
	isLoadingAI: boolean

	loadingHGFail: boolean
	loadingAIFail: boolean

	newHGReportCount: number
	newAIReportCount: number

	reportsTabs: { id: string; label: string; active: boolean }[]
	activeTab: string

	hgReportList: Report[] = []
	aiReportList: AiReport[] = []

	// hgReportListObservable: Observable<Report[]>

	hgReportListSubscription: Subscription
	aiReportListSubscription: Subscription

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		private dataService: DataModelService,
		private translator: TranslateService,
		private reportsService: reportsService
	) {
		Util.debug('(PatientReportModal) - constructor')
		this.isLoadingHG = true
		this.loadingHGFail = false

		this.isLoadingAI = true
		this.loadingAIFail = false

		this.newHGReportCount = 0
		this.newAIReportCount = 0

		this.reportsTabs = [
			{ id: ReportsTab.HGREPORT, label: this.translator.instant('NEW_REPORTS_MODAL.HG_REPORT_TITLE'), active: true },
			{ id: ReportsTab.AIREPORT, label: this.translator.instant('NEW_REPORTS_MODAL.AI_REPORT_TITLE'), active: true },
		]

		this.activeTab = ReportsTab.HGREPORT //default
	}

	ngOnInit(): void {
		Util.debug('(PatientReportModal) - ngOnInit')
		// console.log(this.patient)
		this.reportsService.currentPatient = this.patient

		this.newHGReportCount = this.patient.tot_new_report
		this.newAIReportCount = this.patient.tot_new_aireports

		if (this.session.isLevel1()) {
			this.hgReportListSubscription = this.dataService.reportListChanged.subscribe((resp) => {
				// console.log(resp)
				if (resp.patId === this.patient.id) {
					this.newHGReportCount = resp.reports.filter((rep) => rep.status == ReportStatus.STATUS_NEW).length
				}
			})
		}

		if (this.session.isLevel1()) {
			this.aiReportListSubscription = this.dataService.aiReportListChanged.subscribe((resp) => {
				// console.log(resp)

				if (resp.patId === this.patient.id) {
					this.newAIReportCount = resp.reports.filter((rep) => rep.status == 3).length
				}
			})
		}

		if ((this.patient.tot_reports && this.patient.tot_reports > 0) || (this.patient.tot_reports_done && this.patient.tot_reports_done > 0)) {
			Util.debug('loadPatientHGReport for patId: ' + this.patient.id)
			this.loadPatientHGReport(this.patient.id)
				.then((resp) => {
					this.hgReportList = resp
					if (!this.session.isSpecialist()) {
						for (let rep of this.hgReportList) {
							let refId = rep.created_by
							rep.refDisplayName = this.session.getSpecialistName(refId)
						}
					}
				})
				.catch((error) => {
					this.loadingHGFail = true
				})
				.finally(() => {
					this.isLoadingHG = false
				})
		} else {
			this.isLoadingHG = false
			this.reportsTabs[0].active = false
			this.activeTab = ReportsTab.AIREPORT
		}

		if (!this.session.isSpecialist() && !this.session.isClinicAdmin() && this.patient.tot_aireports && this.patient.tot_aireports > 0) {
			Util.debug('loadPatientAiReport for patId: ' + this.patient.id)
			this.loadPatientAiReport(this.patient.id)
				.then((resp) => {
					this.aiReportList = resp
				})
				.catch((error) => {
					this.loadingAIFail = true
				})
				.finally(() => {
					this.isLoadingAI = false
				})
		} else {
			this.isLoadingAI = false
			this.reportsTabs[1].active = false
			this.activeTab = ReportsTab.HGREPORT
		}
	}

	private loadPatientHGReport(patId: number): Promise<Report[]> {
		const promise = new Promise<Report[]>((resolve, reject) => {
			let repList = this.dataService.getCustomerReportList(patId).reports

			if (repList && repList.length > 0) {
				resolve(repList)
			} else {
				this.session
					.loadReports(patId)
					.then((resp) => {
						resolve(resp)
					})
					.catch((error) => {
						// 31.05.2022
						Util.debug('(countNewReports) KO ')
						console.log(error)
						reject(error)
					})
			}
		})

		return promise
	}

	private loadPatientAiReport(patId: number): Promise<AiReport[]> {
		const promise = new Promise<AiReport[]>((resolve, reject) => {
			let repList = this.dataService.getCustomerAiReportList(patId).reports

			if (repList && repList.length > 0) {
				resolve(repList)
			} else {
				this.reportsService
					.loadAiReports(patId)
					.then((replist) => {
						// console.log(replist)
						resolve(replist)
					})
					.catch((err) => {
						console.log(err)
						reject(err)
					})
			}
		})
		return promise
	}

	public onNavChange(event: NgbNavChangeEvent) {
		this.activeTab = event.nextId
	}

	ngOnDestroy(): void {
		if (this.hgReportListSubscription) {
			this.hgReportListSubscription.unsubscribe()
		}

		if (this.aiReportListSubscription) {
			this.aiReportListSubscription.unsubscribe()
		}
	}
}
