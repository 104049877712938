import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'read-more-modal',
	templateUrl: './read-more.modal.html',
	styleUrls: ['./read-more.modal.scss'],
})
export class ReadMoreModal implements OnInit {
	@Input() url: string
	safeUrl: SafeResourceUrl

	constructor(public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
	}

	close() {
		this.activeModal.dismiss()
	}
}
