import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Util } from '../../models/util.model'
import { Config } from '../../../config'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { SessionService } from '../../service/session.service'

import { FormControl, FormGroup, Validators } from '@angular/forms'

import { User, UserType } from '../../models/user.model'
import { ToastOptions } from '../../models/toast.model'
import { Distrib } from '../../models/specialist.model'

import { AppToastService } from '../../service/toast.service'

import { faInfoCircle, faTriangleExclamation, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

@Component({
	selector: 'grader-modal',
	templateUrl: './grader.modal.html',
	styleUrls: ['./grader.modal.scss'],
})
export class GraderModal implements OnInit {
	@Input() country: string // la country la mando in input perché mi serve subito
	@Input() grader: Distrib

	disableButton: boolean
	isCreate: boolean
	isLoading: boolean

	currentAction: string
	usernameExtension: string
	displayUsername: string

	graderForm: FormGroup

	newGrader: User
	clinicAdmin: User

	faInfoCircle = faInfoCircle
	faTriangleExclamation = faTriangleExclamation
	faCheck = faCheck
	faXmark = faXmark

	constructor(public session: SessionService, public activeModal: NgbActiveModal, private toastService: AppToastService, public translator: TranslateService) {
		Util.debug(' (GraderModal) - constructor')

		this.disableButton = false
		this.isCreate = true
		this.isLoading = true

		this.displayUsername = ''
	}

	ngOnInit(): void {
		Util.debug(' (GraderModal) - ngOnInit')
		Util.debug(' (GraderModal) - current action: ' + this.currentAction)

		// mi serve per ricopiarmi i settings dell'admin
		this.clinicAdmin = this.session.getCurrentUser()

		this.usernameExtension = '@' + this.clinicAdmin.user_id.toString()

		Util.debug(this.usernameExtension)

		// console.log(this.clinicAdmin)

		if (this.currentAction == Config.ACT_CREATE) {
			this.isLoading = false

			this.initGrader()

			this.isCreate = true

			this.newGrader = new User(this.session.cryptoUtils)
			this.newGrader.user_subtype = Config.SUB_MINI
			this.newGrader.country = this.country
			// this.grader.role = Config.PR_SPECIALIST
			// this.grader.type = UserType.CLINIC
			// this.grader.subType = UserType.CLINIC

			this.grader.settings = this.clinicAdmin.settings
		}

		// inizializzo il form in modalitá reactive
		// inizializzo prima il form e dopo se edit lo reinizializzo con i nuovi valori
		this.graderForm = new FormGroup({
			username: new FormControl(this.grader.username, [
				Validators.required,
				Validators.maxLength(40),
				Validators.minLength(3),
				this.invalidUsernameChar.bind(this),
				this.invalidBlankSpace.bind(this),
			]),

			password: new FormControl('password', [Validators.required, Validators.maxLength(40), Validators.minLength(4)]),
			//Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*_0-9])(.{12,})$') // pattern da usare per validare la password

			userData: new FormGroup({
				firstName: new FormControl(this.grader.firstname, [Validators.required, Validators.maxLength(40)]),
				lastName: new FormControl(this.grader.lastname, [Validators.required, Validators.maxLength(40)]),
				// adress: new FormControl(this.grader.mainAddress.address_line1, [Validators.required, Validators.maxLength(40)]),
				// city: new FormControl(this.grader.mainAddress.city, [Validators.required, Validators.maxLength(20)]),
				// province: new FormControl(this.grader.mainAddress.province, [Validators.required, Validators.maxLength(20)]),
				// country: new FormControl(this.country, [Validators.required]),
				email: new FormControl(this.grader.mainAddress.ref_email, [Validators.required, this.emailValidator.bind(this)]),
				// organization: new FormControl(this.grader.mainAddress.organization),
				licenceNumber: new FormControl(this.grader.order_reg_num),
			}),
		})

		// this.graderForm.get('userData').get('country').disable()

		if (this.currentAction == Config.ACT_EDIT) {
			// this.graderForm.get('username').disable()
			this.graderForm.disable() // intanto disabilito tutto il form

			this.isCreate = false

			this.loadSingleDistrib(this.grader.id)
		}

		// console.log(this.grader)
	}

	initForm(obj: Distrib) {
		this.graderForm.get('username').setValue(obj.username)
		// this.graderForm.get('created').setValue(this.session.formatDateTime(obj.creationDate))

		this.graderForm.get('userData').get('firstName').setValue(obj.firstname)
		this.graderForm.get('userData').get('lastName').setValue(obj.lastname)
		// this.graderForm.get('userData').get('adress').setValue(obj.mainAddress.address_line1)
		// this.graderForm.get('userData').get('city').setValue(obj.mainAddress.city)
		// this.graderForm.get('userData').get('province').setValue(obj.mainAddress.province)
		this.graderForm.get('userData').get('email').setValue(obj.mainAddress.ref_email)
		// this.graderForm.get('userData').get('organization').setValue(obj.mainAddress.organization)
		this.graderForm.get('userData').get('licenceNumber').setValue(obj.order_reg_num)
	}

	initGrader() {
		this.grader = new Distrib()
		this.grader.username = ''
		this.grader.firstname = '-'
		this.grader.lastname = '-'
		// this.grader.mainAddress.address_line1 = '-'
		// this.grader.mainAddress.city = '-'
		this.grader.country = this.country
		// this.grader.mainAddress.province = '-'
		this.grader.mainAddress.ref_email = ''
		// this.grader.mainAddress.organization = this.clinicAdmin.getOrganization() // 15.01.2024 poi semmai la cambia lui
		this.grader.order_reg_num = ''
	}

	// quando si va in edit, richiedo tutte le info del distributor
	private loadSingleDistrib(elemId) {
		Util.debug('(loadDistr) going to load: ' + elemId)

		return this.session
			.loadDistrib(elemId)
			.then(() => {
				this.session.getDtDistrib().then((origD) => {
					if (origD != null && origD.id == elemId) {
						this.grader = origD

						this.initForm(origD)

						this.isLoading = false

						// console.log(origD)

						return true
					}
				})
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				Util.debug('(loadSingleDistrib) - KO ' + msg)
				alert('Error loading distrib id ' + elemId)
				return Promise.reject(err)
			})
	}

	// creo un customValidator per lo username, restituisce un oggetto javascript
	invalidUsernameChar(control: FormControl): { [s: string]: boolean } {
		// console.log(control.value)

		if (control.value) {
			if (control.value.indexOf('$') >= 0) {
				return { ['Invalid $ character']: true } // posso cambiare la scritta di errore dinamicamente cercando questo errore
			}
		}
		return null
	}

	invalidBlankSpace(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			if (control.value.indexOf(' ') >= 0) {
				return { ['Invalid blank space']: true } // posso cambiare la scritta di errore dinamicamente cercando questo errore
			}
		}
		return null
	}

	emailValidator(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			var valid = control.value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

			if (valid == null) {
				return { ['Invalid email']: true }
			}
		}
		return null
	}

	usernamecreation() {
		this.displayUsername = this.graderForm.get('username').value + this.usernameExtension
	}

	submitForm() {
		this.disableButton = true

		this.isLoading = true

		// console.log(this.graderForm)

		// solo se nella create mando i su i campi, in queto modo se gli input sono readonly, anche se tolgo il readonly e cambio i campi,
		// cmq non vengono salvati, sul super b posso farlo
		if (this.currentAction == Config.ACT_CREATE) {
			this.newGrader.username = this.graderForm.value.username + this.usernameExtension
			this.newGrader.password = this.graderForm.value.password
			this.newGrader.firstname = this.graderForm.value.userData.firstName
			this.newGrader.lastname = this.graderForm.value.userData.lastName

			// this.newGrader.mainAddress.address_line1 = this.graderForm.value.userData.adress
			// this.newGrader.mainAddress.city = this.graderForm.value.userData.city
			// this.newGrader.mainAddress.province = this.graderForm.value.userData.province
			this.newGrader.mainAddress.ref_email = this.graderForm.value.userData.email
			// this.newGrader.mainAddress.organization = this.graderForm.value.userData.organization

			// this.newGrader.order_reg_num = this.graderForm.value.userData.licenceNumber

			this.newGrader.country = this.country // 15.06.2023

			this.session
				.createNewMiniC(this.newGrader, this.clinicAdmin.settings)
				.then((userId) => {
					// console.log(userId)
					this.disableButton = false
					this.isLoading = false
					Util.debug('(graderModal), create new id:' + userId.user_id)
					this.activeModal.close(userId.user_id)
				})
				.catch((err) => {
					console.log(err)
					this.isLoading = false
					setTimeout(() => {
						this.disableButton = false
					}, 2000)

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant('TOAST.GRADER') + ' ' + this.translator.instant('TOAST.BODYERR.CREATED') + '. ' + err.error.error
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')

					if (err.status == 409) {
						this.graderForm.get('username').setErrors({ incorrect: true })
					}
				})
		} else if (this.currentAction == Config.ACT_EDIT) {
			console.log('TO DO')
		}
	}

	dismiss(reason?) {
		Util.debug('(graderModal), dismiss, no action, ' + reason)
		this.activeModal.close(false)
	}

	close() {
		this.activeModal.dismiss()
	}
}
