export class fakePachyExam {
	static fakePachyValueLEFT = {
		central: { value: '485', set: true },
		nasal_angle: { value: '33', set: true },
		temporal_angle: { value: '31', set: true },
		acd: { value: '2.89', set: true },
		focus_array: {
			value:
				'-1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000',
			set: false,
		},
		pupil_center_x_array: { value: '-2000; -2000; -1000; -1000; 280.5; -1000; -1000; -2000; -1000; -1000; -1000; -1000', set: false },
		pupil_center_y_array: { value: '-2000; -2000; -1000; -1000; 302.442857142857; -1000; -1000; -2000; -1000; -1000; -1000; -1000', set: false },
		slit_pos_array: { value: '-2000; -2000; 0; 0; 0.620893812070283; 0; 0; -2000; 0; 0; 0.620893812070283; 0', set: false },
		grab_elapsed_time_ms_array: {
			value: '91; 172; 253; 337; 479; 565; 647; 730; 812; 895; 976; 1060; -2000; 84; 167; 249; 334; 416; 496; 579; 662; 744; 828; 909; 991; 1073; -2000; -2000',
			set: false,
		},
		visu_pupil_center_x_array: {
			value:
				'1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; -2000; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; 1.22357711426751E-312; -2000; -2000',
			set: false,
		},
		visu_pupil_center_y_array: {
			value:
				'1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; -2000; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; -2000; -2000',
			set: false,
		},
		visu_pupil_radius_array: {
			value:
				'6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; -2000; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; -2000; -2000',
			set: false,
		},
		kappa_offset_point_y: { value: '-1000', set: false },
		kappa_offset_point_x: { value: '-1000', set: false },
		pupilometry_kappa: { value: '', set: false },
		kappa_angle: { value: '89.85414907182', set: false },
		cct_microns_array: { value: '-2000; -2000; -1000; -1000; -1000; 25464.84375; 591.40625; -2000; 606.25; 606.25; -1000; -1000', set: false },
		contrast_array: {
			value:
				'1.47272727272727; 1.25454545454545; 1.47272727272727; 1.47272727272727; 1.49090909090909; 1.28571428571429; 1.49090909090909; 1.47272727272727; 1.47272727272727; 1.87272727272727; 1.56363636363636; 1.52727272727273; -2000; 1.41071428571429; 1.51785714285714; 1.54545454545455; 1.54545454545455; 1.50909090909091; 1.5; 1.5; 1.46428571428571; 1.47272727272727; 1.35714285714286; 1.35714285714286; 1.51785714285714; 1.58928571428571; -2000; 1.18867924528302; 1.26415094339623; 1.27777777777778; 1.18518518518519; 1.28301886792453; -2000; 1.22641509433962; 1.22641509433962; NaN; NaN',
			set: false,
		},
		is_img_sharp_array: {
			value: '1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; -2000; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; -2000; 1; 1; 1; 1; 1; -2000; 1; 1; 0; 0',
			set: false,
		},
		max_val_array: {
			value:
				'81; 69; 81; 81; 82; 72; 82; 81; 81; 103; 86; 84; -2000; 79; 85; 85; 85; 83; 84; 84; 82; 81; 76; 76; 85; 89; -2000; 63; 67; 69; 64; 68; -2000; 65; 65; 0; 0',
			set: false,
		},
		median_array: {
			value:
				'55; 55; 55; 55; 55; 56; 55; 55; 55; 55; 55; 55; -2000; 56; 56; 55; 55; 55; 56; 56; 56; 55; 56; 56; 56; 56; -2000; 53; 53; 54; 54; 53; -2000; 53; 53; 0; 0',
			set: false,
		},
		sharp_array: {
			value: '0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; -2000; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; -2000; 0; 0; 0; 0; 0; -2000; 0; 0; 0; 0',
			set: false,
		},
	}

	static fakePachyValueRIGHT = {
		central: { value: '485', set: true },
		nasal_angle: { value: '33', set: true },
		temporal_angle: { value: '31', set: true },
		acd: { value: '2.89', set: true },
		focus_array: {
			value:
				'-1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000; -1000; 62; -1000; -1000; -1000; 356; -1000; -1000; -2000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000',
			set: false,
		},
		pupil_center_x_array: { value: '-2000; -2000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000', set: false },
		pupil_center_y_array: { value: '-2000; -2000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000', set: false },
		slit_pos_array: { value: '-2000; -2000; 0; 0; 0; 0; 0; -2000; 0; 0; 0; 0', set: false },
		grab_elapsed_time_ms_array: {
			value: '84; 165; 248; 329; 412; 492; 576; 657; 740; 822; 905; 988; 1072; -2000; 89; 172; 255; 337; 419; 501; 87; 675; 1269; 85; 811; 1397; -2000; -2000',
			set: false,
		},
		visu_pupil_center_x_array: {
			value:
				'1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; -2000; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; 1.2235253019639E-312; -2000; -2000',
			set: false,
		},
		visu_pupil_center_y_array: {
			value:
				'1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; -2000; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; 1.1386509616354E-309; -2000; -2000',
			set: false,
		},
		visu_pupil_radius_array: {
			value:
				'6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; -2000; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; 6.95239850613542E-310; -2000; -2000',
			set: false,
		},
		kappa_offset_point_y: { value: '100', set: false },
		kappa_offset_point_x: { value: '89', set: false },
		pupilometry_kappa: { value: '', set: false },
		kappa_angle: { value: '88.674792', set: false },
		cct_microns_array: { value: '-2000; -2000; -1000; -1000; -1000; -1000; -1000; -2000; -1000; -1000; -1000; -1000', set: false },
		contrast_array: {
			value:
				'1.36363636363636; 1.45454545454545; 1.45454545454545; 1.45454545454545; 1.42857142857143; 1.375; 1.375; 1.47272727272727; 1.41818181818182; 1.58181818181818; 1.58181818181818; 0.981818181818182; 1.47272727272727; -2000; 1.6140350877193; 1.82456140350877; 1.40350877192982; 1.40350877192982; 1.33928571428571; 1.52631578947368; 1.11111111111111; 1.03174603174603; 1.12698412698413; 1.046875; 0.861111111111111; 0.945945945945946; -2000; 0.984615384615385; 1.046875; 1.06153846153846; 1.04761904761905; 1.015625; -2000; 1; 1; NaN; NaN',
			set: false,
		},
		is_img_sharp_array: {
			value: '1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; -2000; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; 1; -2000; 1; 1; 1; 1; 1; -2000; 1; 1; 0; 0',
			set: false,
		},
		max_val_array: {
			value:
				'75; 80; 80; 80; 80; 77; 77; 81; 78; 87; 87; 54; 81; -2000; 92; 104; 80; 80; 75; 87; 70; 65; 71; 67; 62; 70; -2000; 64; 67; 69; 66; 65; -2000; 65; 65; 0; 0',
			set: false,
		},
		median_array: {
			value:
				'55; 55; 55; 55; 56; 56; 56; 55; 55; 55; 55; 55; 55; -2000; 57; 57; 57; 57; 56; 57; 63; 63; 63; 64; 72; 74; -2000; 65; 64; 65; 63; 64; -2000; 65; 65; 0; 0',
			set: false,
		},
		sharp_array: {
			value:
				'0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; 0; -2000; 3.38; 3.64666666666668; 3.09333333333333; 3.09333333333333; 0; 4.01333333333335; 0; 3.70666666666668; 4.13999999999999; 3.66; 0; 0; -2000; 0; 0; 0; 0; 0; -2000; 0; 0; 0; 0',
			set: false,
		},
	}
}

export class fakeTopoExams {
	static fakeTopoValueLEFT = {
		eccentricity: { value: '0,88', set: true, edit: false },
		KPI: { value: '0,36', set: true, edit: false },
		SI: { value: '-0,2', set: true, edit: false },
		K1_radius: { value: '7.701', set: true, edit: false },
		K1_r_unit: { value: 'mm', set: true, edit: false },
		K1_axis: { value: '0', set: true, edit: false },
		K1_a_unit: { value: 'deg', set: true, edit: false },
		K2_radius: { value: '7.720', set: true, edit: false },
		K2_r_unit: { value: 'mm', set: true, edit: false },
		K2_axis: { value: '90', set: true, edit: false },
		K2_a_unit: { value: 'deg', set: true, edit: false },
		CYL_power: { value: '2.00', set: true, edit: false },
		CYL_p_unit: { value: 'D', set: true, edit: false },
		CYL_axis: { value: '180', set: true, edit: false },
		CYL_a_unit: { value: 'deg', set: true, edit: false },

		topo_algorithm: { value: 'CSO', set: false, edit: true },
		kisa: { value: '54,85732626', set: false, edit: true },
		kisa_unit: { value: '%', set: true, edit: false },
		K: { value: '1', set: false, edit: true },
		K_unit: { value: 'D', set: true, edit: false },
		srax: { value: '0.36', set: false, edit: true },
		srax_unit: { value: 'deg', set: true, edit: false },
		i_minus_s: { value: '0.45', set: false, edit: true },
		i_minus_s_unit: { value: 'D', set: true, edit: false },
	}

	static fakeTopoValueRIGHT = {
		eccentricity: { value: '0,78', set: true, edit: false },
		KPI: { value: '0', set: true, edit: false },
		SI: { value: '0,21', set: true, edit: false },
		K1_radius: { value: '7.741', set: true, edit: false },
		K1_r_unit: { value: 'mm', set: true, edit: false },
		K1_axis: { value: '120', set: true, edit: false },
		K1_a_unit: { value: 'deg', set: true, edit: false },
		K2_radius: { value: '7.760', set: true, edit: false },
		K2_r_unit: { value: 'mm', set: true, edit: false },
		K2_axis: { value: '030', set: true, edit: false },
		K2_a_unit: { value: 'deg', set: true, edit: false },
		CYL_power: { value: '-1.00', set: true, edit: false },
		CYL_p_unit: { value: 'D', set: true, edit: false },
		CYL_axis: { value: '180', set: true, edit: false },
		CYL_a_unit: { value: 'deg', set: true, edit: false },

		topo_algorithm: { value: 'CSO', set: false, edit: true },
		kisa: { value: '34,32626', set: false, edit: true },
		kisa_unit: { value: '%', set: true, edit: false },
		K: { value: '1', set: false, edit: true },
		K_unit: { value: 'D', set: true, edit: false },
		srax: { value: '0.30', set: false, edit: true },
		srax_unit: { value: 'deg', set: true, edit: false },
		i_minus_s: { value: '0.25', set: false, edit: true },
		i_minus_s_unit: { value: 'D', set: true, edit: false },
	}
}

export class fakeFastRefractionExams {
	static fakeFastRefractionValueLEFT = {
		pupil_size_day: { value: '3', set: true, edit: false },
		pupil_real_size_day: { value: '4.6', set: true, edit: false },
		pupil_size_night: { value: '6.15', set: true, edit: false },
		pupil_real_size_night: { value: '0', set: true, edit: false },
		sphere_day: { value: '5.28', set: true, edit: false },
		cylinder_day: { value: '-0.08', set: true, edit: false },
		sphere_night: { value: '4.78', set: true, edit: false },
		cylinder_night: { value: '-0.11', set: true, edit: false },
		vertex_distance: { value: '12', set: true, edit: false },
		axis_day: { value: '62.17', set: true, edit: false },
		axis_night: { value: '74.31', set: true, edit: false },
		K1_radius: { value: '8.45', set: true, edit: false },
		K1_axis: { value: '2', set: true, edit: false },
		K2_radius: { value: '8.31', set: true, edit: false },
		K2_axis: { value: '2', set: true, edit: false },
		CYL_power: { value: '-0.67', set: true, edit: false },
		CYL_axis: { value: '92', set: true, edit: false },
	}
	static fakeFastRefractionValueRIGHT = {
		pupil_size_day: { value: '3', set: true, edit: false },
		pupil_real_size_day: { value: '4.86', set: true, edit: false },
		pupil_size_night: { value: '7.01', set: true, edit: false },
		pupil_real_size_night: { value: '0', set: true, edit: false },
		sphere_day: { value: '-5.13', set: true, edit: false },
		cylinder_day: { value: '-0.11', set: true, edit: false },
		sphere_night: { value: '-6.45', set: true, edit: false },
		cylinder_night: { value: '-0.01', set: true, edit: false },
		vertex_distance: { value: '12', set: true, edit: false },
		axis_day: { value: '140.07', set: true, edit: false },
		axis_night: { value: '104.31', set: true, edit: false },
		K1_radius: { value: '8.44', set: true, edit: false },
		K1_axis: { value: '1', set: true, edit: false },
		K2_radius: { value: '8.44', set: true, edit: false },
		K2_axis: { value: '1', set: true, edit: false },
		CYL_power: { value: '-0.72', set: true, edit: false },
		CYL_axis: { value: '91', set: true, edit: false },
	}
}
