<div class="container-fluid statistic-container">
	<div class="panel panel-default statistics">
		<div class="row">
			<div class="col-12">
				<div class="statistic-navbar">
					<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" (navChange)="onNavChange($event)" orientation="orizontal" class="nav-tabs">
						<li ngbNavItem="trends">
							<a ngbNavLink>TRENDS</a>
							<ng-template ngbNavContent>
								<form [formGroup]="statisticForm" class="row statistics-form py-2" (ngSubmit)="getReport()">
									<!--  -->
									<!-- PROFILE -->
									<!--  -->
									<div class="col-xxl-2 col-sm-4 py-2 px-3 statistics-form__col">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.PROFILE" | translate}} </span>
											<mat-form-field appearance="outline">
												<mat-label style="color: #4f0aeb">{{"STATISTICS.OPTIONS_SUB_TITLE.PROFILE" | translate}}</mat-label>
												<mat-select name="grading" (selectionChange)="profileSelected()" formControlName="profile">
													<mat-option *ngFor="let profile of profiles" [value]="profile"> {{profile.filter_name}} </mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>

									<!--  -->
									<!-- OPTIONS -->
									<!--  -->
									<div class="col-xxl-3 col-sm-4 py-2 px-3 statistics-form__col" *ngIf="options.length > 0">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.TYPE" | translate}}</span>

											<mat-form-field appearance="outline">
												<mat-label style="color: #4f0aeb">Select Type</mat-label>
												<mat-select name="options" (selectionChange)="optionSelected()" formControlName="option">
													<mat-option *ngFor="let option of options" [value]="option"> {{option.text}} </mat-option>
												</mat-select>
											</mat-form-field>

											<!-- <div class="me-3 my-3 form-check" *ngFor="let option of options">
												<input class="form-check-input" type="radio" [value]="option.type" id="{{option.type}}" (change)="optionSelected(option)" name="options" />
												<label class="form-check-label ms-2" for="{{option.type}}"> {{option.text}} </label>
											</div> -->
										</div>
									</div>
									<!--  -->
									<!-- TIME PERIOD -->
									<!--  -->
									<div class="col-xxl-3 col-sm-4 py-2 px-3 statistics-form__col">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.TIME" | translate}}</span>
											<mat-form-field appearance="outline" *ngIf="usrOption !== 'plans'; else datePicker" style="width: 90%">
												<mat-label style="color: #4f0aeb">{{"STATISTICS.OPTIONS_SUB_TITLE.RANGE_PICKER" | translate}}</mat-label>
												<mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
													<input matStartDate [placeholder]="session.getDateFormat().toLowerCase()" formControlName="fromDate" />
													<input matEndDate [placeholder]="session.getDateFormat().toLowerCase()" (dateChange)="setCadence()" formControlName="toDate" />
												</mat-date-range-input>
												<!-- <mat-hint style="color: #4f0aeb">MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
												<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-date-range-picker startView="multi-year" #picker></mat-date-range-picker>
											</mat-form-field>
											<fa-icon [icon]="faArrowsLeftRight" faArrowsLeftRight [ngbTooltip]="('STATISTICS.MAX_RANGE_SELECTOR_TOOLTIP' | translate)" [ngClass]="(statisticForm.get('profile').value != null && statisticForm.get('option').value != '') ? 'show': '' " (click)="setMaxDateRange($event)"></fa-icon>
											<ng-template #datePicker>
												<mat-form-field class="date-picker-plans" hideRequiredMarker appearance="outline" style="width: 90%">
													<mat-label style="color: #4f0aeb">{{"STATISTICS.OPTIONS_SUB_TITLE.DATE_PICKER" | translate}}</mat-label>
													<div class="d-flex">
														<input matInput [min]="minDate" [max]="maxDate" [placeholder]="session.getDateFormat().toLowerCase()" [matDatepicker]="picker" formControlName="toDate" />
														<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
														<mat-datepicker #picker startView="year"></mat-datepicker>
													</div>
												</mat-form-field>
											</ng-template>
										</div>
									</div>
									<!--  -->
									<!-- CADENCE -->
									<!--  -->
									<div class="col-xxl-2 col-sm-2 py-2 px-3 statistics-form__col" [hidden]=" usrOption === 'hg_reports' || usrOption === 'purchased_sku'">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.CADENCE" | translate}}</span>
											<mat-form-field appearance="outline">
												<mat-label style="color: #4f0aeb">{{"STATISTICS.OPTIONS_SUB_TITLE.CADENCE" | translate}}</mat-label>
												<mat-select name="grading" formControlName="cadence">
													<mat-option *ngFor="let cad of cadence" [value]="cad"> {{cad}} </mat-option>
												</mat-select>
												<fa-icon [icon]="faXmark" faXmark style="color: #ff3b2d" [ngClass]="statisticForm.get('cadence').value != null ? 'show': '' " (click)="statisticForm.get('cadence').reset(); statisticForm.get('cadence').setValue(null); statisticForm.get('size').setValue(false) "></fa-icon>
											</mat-form-field>
										</div>
									</div>

									<!--  -->
									<!-- BIG GRAPH -->
									<!--  -->
									<div class="col-xxl-2 col-sm-2 py-2 px-3 statistics-form__col" *ngIf="statisticForm.get('cadence').value != null && usrOption != 'aireportsfull' && usrOption != 'balances' && usrOption != 'performances' ">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.SIZE" | translate}}</span>
											<mat-form-field appearance="outline">
												<mat-label style="color: #4f0aeb">{{"STATISTICS.OPTIONS_SUB_TITLE.SIZE" | translate}}</mat-label>
												<mat-select name="grading" formControlName="size">
													<mat-option [value]="true"> BIG </mat-option>
													<mat-option [value]="false"> NORMAL </mat-option>
												</mat-select>
											</mat-form-field>
										</div>
									</div>

									<!--  -->
									<!-- COUNTRY -->
									<!--  -->
									<div class="col-xxl-3 col-sm-3 py-2 px-3 statistics-form__col" [hidden]="isSupport">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.COUNTRY" | translate}}</span>

											<mat-form-field appearance="outline" style="width: 90%">
												<span class="fi fi-{{ (statisticForm.get('country').value).alpha2 }}" *ngIf="statisticForm.get('country').value" class="flag"></span>
												<mat-label style="color: #4f0aeb">{{"STATISTICS.OPTIONS_SUB_TITLE.COUNTRY" | translate}}</mat-label>
												<mat-select name="grading" formControlName="country">
													<mat-option *ngFor="let country of countries" [value]="country"
														><div>
															<span class="fi fi-{{ country.alpha2 }}" tabindex="0"></span>
															<span>{{country.name}}</span>
														</div>
													</mat-option>
												</mat-select>
												<fa-icon [icon]="faXmark" faXmark style="color: #ff3b2d" [ngClass]="statisticForm.get('country').value != null ? 'show': '' " (click)="statisticForm.get('country').reset(); statisticForm.get('country').setValue(null) "></fa-icon>
											</mat-form-field>
										</div>
									</div>
									<!-- WARNING MESSAGE -->
									<!-- <div class="col-xxl-3 col-sm-4 py-2 px-3 statistics-form__col" *ngIf="usrOption == 'plans' && statisticForm.get('cadence').value == null">
										<div class="d-flex flex-column  justify-content-end h-100 statistics-form__content position-relative px-2" style="border: 1px solid red">
											<p class="mt-1" style="color: red">Without a cadence selected the From Date is not considered</p>
										</div>
									</div> -->
									<!-- OPT SELECTION -->
									<div class="col-xxl-5 col-sm-5 py-2 px-3 statistics-form__col" [hidden]="!statisticForm.get('opticians').enabled">
										<div class="d-flex flex-column justify-content-center h-100 statistics-form__content position-relative px-3">
											<span class="statistics-form__title">{{"STATISTICS.OPTIONS_TITLE.OPTICIANS" | translate}}</span>

											<div class="input-group input-group-sm">
												<fa-icon class="me-2" [icon]="faCircleQuestion" faCircleQuestion style="font-size: 18px; color: #64a8f7; cursor: pointer" (click)="showInfo()"></fa-icon>
												<input type="text" class="form-control" formControlName="opticians" />
											</div>

											<!-- <mat-form-field appearance="fill">
												<mat-select multiple formControlName="opticians" (openedChange)="onOpenChange(multiUserSearch)">
													<mat-select-trigger>{{statisticForm.get('opticians').value }} </mat-select-trigger>
													<input #multiUserSearch type="text" autocomplete="off" matInput placeholder="Search By opticians" (input)="onInputChange($event)" />
													<mat-option *ngFor="let doctors of doctorListFiltered" [value]="doctors.id">{{doctors.username}}</mat-option>
												</mat-select>
											</mat-form-field> -->
										</div>
									</div>

									<div class="col-xxl-6 col-sm-12 py-2 px-3 d-flex align-items-center">
										<div>
											<button type="submit" class="btn btn-primary" [disabled]="statisticForm.invalid || btnCountDisabled">{{"STATISTICS.GET_REPORT" | translate}}</button>

											<!-- <button type="button" class="btn btn-primary" (click)="getPerformance()">test</button> -->

											<div class="ms-4 d-inline" *ngIf="usrOption === 'accounts'">
												<mat-slide-toggle color="primary" formControlName="full" ngbTooltip=" Retrieve full data" (change)="fullReport=!fullReport"> </mat-slide-toggle>
												<span class="ms-2" style="font-size: 14px">{{"STATISTICS.FULL" | translate}}</span>
											</div>

											<div class="ms-4 d-inline">
												<input class="form-check-input" type="checkbox" class="me-2" id="deleted" (click)="deletecharts($event)" />
												<label class="form-check-label" for="deleted" style="font-size: 12px">{{"STATISTICS.KEEP_OLD" | translate}}</label>
											</div>
										</div>
									</div>
								</form>

								<br />
								<div class="position-relative" style="min-height: 40vh">
									<!--  -->
									<!-- PURCHASE HISTORY -->
									<!--  -->
									<div class="row purchase-container" [hidden]="!purchaseReady">
										<div class="col-7 mb-3">
											<p>
												{{"ACTIVITIES.EXPORT_DATA" | translate}}
												<span [hidden]="loadCSV"><fa-icon [icon]="faFileCsv" faFileCsv class="mx-3" (click)="exportTable(balanceOptList)"></fa-icon></span>
												<span [hidden]="!loadCSV"><fa-icon class="spinner-csv mx-3" [icon]="faSpinner" faSpinner></fa-icon></span>
											</p>
											<div class="p-2 custom-shadow rounded position-relative" style="min-height: 55vh">
												<table mat-table [dataSource]="balanceOptList" style="width: 100%" class="optician-list" *ngIf="purchaseReady">
													<ng-container matColumnDef="username">
														<th mat-header-cell *matHeaderCellDef>username</th>
														<td mat-cell *matCellDef="let element" class="fw-bold">{{ element.username }}</td>
													</ng-container>

													<ng-container matColumnDef="id">
														<th mat-header-cell *matHeaderCellDef class="text-center">id</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{ element.id }}</td>
													</ng-container>

													<ng-container matColumnDef="prev_balance">
														<th mat-header-cell *matHeaderCellDef class="text-center">balance at {{fromDate}}</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{ element.prev_balance }}</td>
													</ng-container>

													<ng-container matColumnDef="sold">
														<th mat-header-cell *matHeaderCellDef class="text-center">Sold</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.sold}}</td>
													</ng-container>
													<ng-container matColumnDef="used">
														<th mat-header-cell *matHeaderCellDef class="text-center">Used</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.used}}</td>
													</ng-container>

													<ng-container matColumnDef="final_balance">
														<th mat-header-cell *matHeaderCellDef class="text-center">
															<span class="row justify-content-center" *ngIf="purchaseThresholdFlag; else altFinalBalanceHeader">
																<input class="w-50" matInput type="text" (keyup)="filterOptText()" placeholder="Max Threshold" #balOptFilter />
																<fa-icon class="w-auto" [icon]="faXmark" style="cursor: pointer" (click)="togglePurchaseThreshold()"></fa-icon>
															</span>

															<ng-template #altFinalBalanceHeader>
																<span>
																	balance at {{toDate}}
																	<fa-icon [icon]="faFilter" faFilter style="cursor: pointer" (click)="togglePurchaseThreshold()"></fa-icon>
																</span>
															</ng-template>
														</th>

														<td mat-cell *matCellDef="let element" class="text-center">{{ element.final_balance }}</td>
													</ng-container>

													<tr mat-header-row *matHeaderRowDef="matHistoryOptColumns" style="opacity: 1"></tr>
													<tr mat-row *matRowDef="let row; columns: matHistoryOptColumns"></tr>
												</table>
												<mat-paginator #balOptPaginator [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
											</div>
										</div>
										<div class="col-5" *ngIf="purchaseReady">
											<apx-chart [fill]="purchasePieChart.fill" [title]="purchasePieChart.title" [legend]="purchasePieChart.legend" [series]="purchasePieChart.series" [chart]="purchasePieChart.chart" [labels]="purchasePieChart.labels" [responsive]="purchasePieChart.responsive" [colors]="purchasePieChart.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
										</div>
									</div>
									<div class="row purchase-container mt-2" *ngIf="purchaseColumnChartReady">
										<div class="col-12">
											<div class="column-purchase">
												<apx-chart [series]="purchaseColumnChart.series" [title]="purchaseColumnChart.title" [chart]="purchaseColumnChart.chart" [dataLabels]="purchaseColumnChart.dataLabels" [plotOptions]="purchaseColumnChart.plotOptions" [responsive]="purchaseColumnChart.responsive" [fill]="purchaseColumnChart.fill" [stroke]="purchaseColumnChart.stroke" [xaxis]="purchaseColumnChart.xaxis" [colors]="purchaseColumnChart.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
											</div>
										</div>
									</div>

									<!--  -->
									<!-- PERFORMANCE -->
									<!--  -->
									<div class="row performance-container" [hidden]="!performance">
										<div class="col-6">
											<div class="p-2 custom-shadow rounded position-relative" style="min-height: 55vh">
												<table mat-table [dataSource]="graderListPerformance" style="width: 100%" class="grader-list-performance" *ngIf="performance">
													<ng-container matColumnDef="isSelected">
														<th mat-header-cell *matHeaderCellDef style="width: 20px"></th>
														<td mat-cell *matCellDef="let element">
															<fa-icon></fa-icon>
															<fa-icon [icon]="faXmark" *ngIf="element.isSelected" (click)=" $event.stopPropagation(); element.isSelected = false; buildPerformancePieChart()" style="color: #e5491f"></fa-icon>
														</td>
													</ng-container>

													<ng-container matColumnDef="username">
														<th mat-header-cell *matHeaderCellDef><input matInput type="text" (keyup)="filterText2()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter2 /></th>
														<!-- <th mat-header-cell *matHeaderCellDef class="text-center">username</th> -->
														<td mat-cell *matCellDef="let element" class="fw-bold">{{ element.username }}</td>
													</ng-container>

													<ng-container matColumnDef="type">
														<th mat-header-cell *matHeaderCellDef class="text-center">Type</th>
														<td mat-cell *matCellDef="let element" class="text-center">
															<div class="logo" *ngIf="element.type == 'VX'; else privateType "></div>
															<ng-template #privateType>
																<span style="font-weight: bold">[PR]</span>
															</ng-template>
														</td>
													</ng-container>

													<ng-container matColumnDef="country">
														<th mat-header-cell *matHeaderCellDef class="text-center">Country</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.country}}</td>
													</ng-container>

													<ng-container matColumnDef="tot_reports">
														<th mat-header-cell *matHeaderCellDef class="text-center">Total</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{ element.tot_reports }}</td>
													</ng-container>

													<ng-container matColumnDef="av_grading_time">
														<th mat-header-cell *matHeaderCellDef class="text-center">
															<span style="display: block">Avg Delivery in:</span>
														</th>
														<td mat-cell *matCellDef="let element" class="text-center" [ngClass]="element.av_grading_time > 48 ? 'warning' : '' ">{{parseAvgGradingTime(element.av_grading_time)}}</td>
													</ng-container>

													<ng-container matColumnDef="over_48h">
														<th mat-header-cell *matHeaderCellDef class="text-center">> 48h</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.over_48h}} %</td>
													</ng-container>

													<ng-container matColumnDef="av_lock_time_s">
														<th mat-header-cell *matHeaderCellDef class="text-center">
															<span style="display: block">Avg grading time</span>
															<span>(mm:ss)</span>
														</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.av_lock_time_s}}</td>
													</ng-container>

													<tr mat-header-row *matHeaderRowDef="displayedColumns2" style="opacity: 1"></tr>
													<tr mat-row *matRowDef="let row; columns: displayedColumns2" style="cursor: pointer" (click)="buildPerformancePieChart(row);" [ngClass]="row.isSelected ? 'onchart' : '' "></tr>
												</table>
												<mat-paginator #paginator2 [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>

												<div class="show-delete">
													<input class="form-check-input" type="checkbox" class="me-2" id="deleted" (click)="showDeletedGraders($event)" />
													<label class="form-check-label" for="deleted" style="font-size: 12px">{{'DASHBOARD.SHOWDELETED' | translate}}</label>
												</div>
											</div>
										</div>
										<div class="col-6" *ngIf="performance">
											<apx-chart [fill]="performancePieChart.fill" [title]="performancePieChart.title" [legend]="performancePieChart.legend" [series]="performancePieChart.series" [chart]="performancePieChart.chart" [labels]="performancePieChart.labels" [responsive]="performancePieChart.responsive" [colors]="performancePieChart.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
										</div>
										<div class="row mt-3">
											<div class="col-12" *ngIf="performance">
												<div class="p-2" *ngIf="performanceWithCadence">
													<apx-chart [series]="performanceColumnChart.series" [title]="performanceColumnChart.title" [chart]="performanceColumnChart.chart" [dataLabels]="performanceColumnChart.dataLabels" [plotOptions]="performanceColumnChart.plotOptions" [responsive]="performanceColumnChart.responsive" [fill]="performanceColumnChart.fill" [stroke]="performanceColumnChart.stroke" [xaxis]="performanceColumnChart.xaxis" [colors]="performanceColumnChart.colors" [noData]="{text: 'No data to display'}">
													</apx-chart>
												</div>
											</div>
										</div>
									</div>

									<!--  -->
									<!-- GRADINGS -->
									<!--  -->
									<div class="row gradings-container" [hidden]="!gradings">
										<div class="col-6" *ngIf="gradingsTableFlag">
											<div class="p-2 mb-3 custom-shadow rounded position-relative" style="min-height: 55vh">
												<table mat-table [dataSource]="gradingsList" style="width: 100%">
													<ng-container matColumnDef="onChart">
														<th mat-header-cell *matHeaderCellDef></th>
														<td mat-cell *matCellDef="let element">
															<fa-icon [icon]="faXmark" *ngIf="element.isOnChart" (click)=" $event.stopPropagation(); element.isOnChart = false; buildGradingsFollowUpTable()" style="color: #e5491f"></fa-icon>
														</td>
													</ng-container>

													<ng-container matColumnDef="username">
														<th mat-header-cell *matHeaderCellDef><input matInput type="text" (keyup)="filterGradingsText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #gradingsFilter /></th>
														<td mat-cell *matCellDef="let element" class="fw-bold">{{ element.username }}</td>
													</ng-container>

													<ng-container matColumnDef="type">
														<th mat-header-cell *matHeaderCellDef class="text-center">Type</th>
														<td mat-cell *matCellDef="let element" class="text-center">
															<div class="logo" *ngIf="element.type == 'Std' || element.type == 'Mini'; else privateType "></div>
															<ng-template #privateType>
																<span style="font-weight: bold">[PR]</span>
															</ng-template>
														</td>
													</ng-container>

													<ng-container matColumnDef="gradings_done">
														<th mat-header-cell *matHeaderCellDef class="text-center">
															<span style="display: block">Gradings done</span>
														</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.gradings_done}}</td>
													</ng-container>

													<ng-container matColumnDef="gradings_pending">
														<th mat-header-cell *matHeaderCellDef class="text-center">
															<span style="display: block">Gradings pending</span>
														</th>
														<td mat-cell *matCellDef="let element" class="text-center">{{element.gradings_pending}}</td>
													</ng-container>

													<tr mat-header-row *matHeaderRowDef="gradingsDisplayedColumns" style="opacity: 1"></tr>
													<tr mat-row *matRowDef="let row; columns: gradingsDisplayedColumns" style="cursor: pointer" (click)="buildGradingsFollowUpTable(row)" [ngClass]="row.isOnChart ? 'onchart' : '' "></tr>
												</table>
												<mat-paginator #gradingsPaginator [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
											</div>
										</div>
										<div class="col-6" *ngIf="gradingsFollowUpPieChart">
											<apx-chart [fill]="gradingsFollowUpPieChart.fill" [title]="gradingsFollowUpPieChart.title" [legend]="gradingsFollowUpPieChart.legend" [series]="gradingsFollowUpPieChart.series" [chart]="gradingsFollowUpPieChart.chart" [labels]="gradingsFollowUpPieChart.labels" [responsive]="gradingsFollowUpPieChart.responsive" [colors]="gradingsFollowUpPieChart.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
										</div>
										<div class="col-6" *ngIf="gradingsPieChart">
											<apx-chart [fill]="gradingsPieChart.fill" [title]="gradingsPieChart.title" [legend]="gradingsPieChart.legend" [series]="gradingsPieChart.series" [chart]="gradingsPieChart.chart" [labels]="gradingsPieChart.labels" [responsive]="gradingsPieChart.responsive" [colors]="gradingsPieChart.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
										</div>
									</div>

									<!--  -->
									<!-- AI REPORTS -->
									<!--  -->
									<div class="row aireports-container" *ngIf="aiRequest">
										<div class="col-4">
											<div class="my-3 p-3 custom-shadow reports-container rounded">
												<p class="fw-bold">Server AI Performance</p>
												<div class="row">
													<div class="col-4 text-center aireports-container__total rounded">
														<p>Total</p>
														<span>{{totalReq}}</span>
													</div>
													<div class="col-4 text-center aireports-container__totaldone rounded">
														<p>Successful</p>
														<span>{{totalDone}}</span>
													</div>
													<div class="col-4 text-center aireports-container__fails rounded">
														<p>KO Received</p>
														<span>{{totalFail}}</span>
													</div>
												</div>
											</div>

											<div *ngIf="aiRequestChart" class="pt-4">
												<apx-chart [fill]="aiTotpieGraphs.fill" [title]="aiTotpieGraphs.title" [legend]="aiTotpieGraphs.legend" [series]="aiTotpieGraphs.series" [chart]="aiTotpieGraphs.chart" [labels]="aiTotpieGraphs.labels" [responsive]="aiTotpieGraphs.responsive" [colors]="aiTotpieGraphs.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
											</div>
										</div>

										<!-- mcS -->
										<div class="col-4" *ngIf="mcs.total !== 0">
											<div class="my-3 p-3 custom-shadow reports-container rounded">
												<p class="fw-bold">Full AI Performance</p>
												<div class="row">
													<div class="col-3 text-center aireports-container__total rounded">
														<p>Total</p>
														<span>{{mcs.total}}</span>
													</div>
													<div class="col-3 text-center aireports-container__green rounded">
														<p>Green</p>
														<span>{{mcs.green}}</span>
													</div>
													<div class="col-3 text-center aireports-container__yellow rounded">
														<p>Yellow</p>
														<span>{{mcs.yellow}}</span>
													</div>
													<div class="col-3 text-center aireports-container__red rounded">
														<p>Red</p>
														<span>{{mcs.red}}</span>
													</div>
												</div>
											</div>

											<div *ngIf="aiRequestChart" class="pt-4">
												<apx-chart [fill]="aiMCSpieGraphs.fill" [title]="aiMCSpieGraphs.title" [legend]="aiMCSpieGraphs.legend" [series]="aiMCSpieGraphs.series" [chart]="aiMCSpieGraphs.chart" [labels]="aiMCSpieGraphs.labels" [responsive]="aiMCSpieGraphs.responsive" [colors]="aiMCSpieGraphs.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											</div>
										</div>

										<!-- DSS -->
										<div class="col-4" *ngIf="dss.total !== 0">
											<div class="my-3 p-3 custom-shadow reports-container rounded">
												<p class="fw-bold">DR AI Performance</p>
												<div class="row">
													<div class="col-3 text-center aireports-container__total rounded">
														<p>Total</p>
														<span>{{dss.total}}</span>
													</div>
													<div class="col-3 text-center aireports-container__green rounded">
														<p>Green</p>
														<span>{{dss.green}}</span>
													</div>
													<div class="col-3 text-center aireports-container__yellow rounded">
														<p>Yellow</p>
														<span>{{dss.yellow}}</span>
													</div>
													<div class="col-3 text-center aireports-container__red rounded">
														<p>Red</p>
														<span>{{dss.red}}</span>
													</div>
												</div>
											</div>

											<div *ngIf="aiRequestChart" class="pt-4">
												<apx-chart [fill]="aiDSSpieGraphs.fill" [title]="aiDSSpieGraphs.title" [legend]="aiDSSpieGraphs.legend" [series]="aiDSSpieGraphs.series" [chart]="aiDSSpieGraphs.chart" [labels]="aiDSSpieGraphs.labels" [responsive]="aiDSSpieGraphs.responsive" [colors]="aiDSSpieGraphs.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											</div>
										</div>

										<div class="col-4" *ngIf="aiRequest && !session.isSupport()">
											<div class="my-3 p-3 custom-shadow reports-container rounded">
												<p class="fw-bold">Response Time Performance</p>
												<div class="row">
													<div class="col-6 text-center aireports-container__total rounded">
														<p>Number of requests</p>
														<span>{{totalAiRequests}}</span>
													</div>
													<div class="col-6 text-center aireports-container__total rounded">
														<p>Average Response Time</p>
														<span>{{avgAiResponseTime}} sec</span>
													</div>
												</div>
											</div>

											<div *ngIf="aiRequestChart" class="pt-4">
												<apx-chart [fill]="avgResponseTimePieGraphs.fill" [title]="avgResponseTimePieGraphs.title" [legend]="avgResponseTimePieGraphs.legend" [series]="avgResponseTimePieGraphs.series" [chart]="avgResponseTimePieGraphs.chart" [labels]="avgResponseTimePieGraphs.labels" [responsive]="avgResponseTimePieGraphs.responsive" [colors]="avgResponseTimePieGraphs.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											</div>
										</div>

										<div class="col-4" *ngIf="aiRequest && !session.isSupport()">
											<div *ngIf="aiRequestChart" class="pt-4">
												<apx-chart [fill]="aiQualityPieGraphs.fill" [title]="aiQualityPieGraphs.title" [legend]="aiQualityPieGraphs.legend" [series]="aiQualityPieGraphs.series" [chart]="aiQualityPieGraphs.chart" [labels]="aiQualityPieGraphs.labels" [responsive]="aiQualityPieGraphs.responsive" [colors]="aiQualityPieGraphs.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											</div>
										</div>
									</div>
									<div class="row mt-3 aiReportsGlobal mb-2" *ngIf="aiReqWithCountry">
										<p class="fw-bold text-center mt-3 title-aisection">Global country details</p>
										<br />
										<div class="col-4">
											<apx-chart [fill]="aiTotpieGraphsGlobal.fill" [title]="aiTotpieGraphsGlobal.title" [legend]="aiTotpieGraphsGlobal.legend" [series]="aiTotpieGraphsGlobal.series" [chart]="aiTotpieGraphsGlobal.chart" [labels]="aiTotpieGraphsGlobal.labels" [responsive]="aiTotpieGraphsGlobal.responsive" [colors]="aiTotpieGraphsGlobal.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
										</div>
										<div class="col-8 position-relative">
											<apx-chart [series]="globalAIcolumnChart.series" [title]="globalAIcolumnChart.title" [chart]="globalAIcolumnChart.chart" [dataLabels]="globalAIcolumnChart.dataLabels" [plotOptions]="globalAIcolumnChart.plotOptions" [responsive]="globalAIcolumnChart.responsive" [fill]="globalAIcolumnChart.fill" [stroke]="globalAIcolumnChart.stroke" [xaxis]="globalAIcolumnChart.xaxis" [yaxis]="globalAIcolumnChart.yaxis" [colors]="globalAIcolumnChart.colors" [noData]="{text: 'No data to display'}">
											</apx-chart>
											<a class="px-3 switch-button" (click)="seeDss = !seeDss; aiColumnChartGlobal(); ">{{seeDss? 'see mcs' : 'see dss' }} <fa-icon class="ms-2" [icon]="faArrowRightArrowLeft"></fa-icon></a>
										</div>
									</div>

									<div class="mt-3 mb-2" [hidden]="!aiRequestChart || aiServiceOptSelected">
										<div class="row">
											<p class="fw-bold text-center mt-3 title-aisection">{{aiReqWithCadence ? 'Cadence details ' : 'Graders details' }}</p>
											<div class="col-4">
												<div class="p-2 custom-shadow rounded" style="height: 55vh; overflow-y: scroll">
													<table mat-table [dataSource]="graderList" matSort style="width: 100%" class="grader-list">
														<ng-container matColumnDef="onChart">
															<th mat-header-cell *matHeaderCellDef></th>
															<td mat-cell *matCellDef="let element">
																<fa-icon [icon]="faCircleCheck" *ngIf="element.isOnChart && !aiReqWithCadence" style="color: #28b32e"></fa-icon>
																<fa-icon [icon]="faXmark" *ngIf="element.isOnChart && aiReqWithCadence" (click)=" $event.stopPropagation(); element.isOnChart = false; resetGraderList()" style="color: #e5491f"></fa-icon>
															</td>
														</ng-container>

														<ng-container matColumnDef="username">
															<th mat-header-cell *matHeaderCellDef><input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter /></th>
															<td mat-cell *matCellDef="let element" class="fw-bold">{{ element.username }}</td>
														</ng-container>

														<ng-container matColumnDef="tot">
															<th mat-header-cell *matHeaderCellDef class="text-center">Total</th>
															<td mat-cell *matCellDef="let element" class="text-center">{{ element.tot }}</td>
														</ng-container>

														<ng-container matColumnDef="green">
															<th mat-header-cell *matHeaderCellDef class="text-center">Green</th>
															<td mat-cell *matCellDef="let element" class="text-center">{{element.green}}</td>
														</ng-container>

														<ng-container matColumnDef="yellow">
															<th mat-header-cell *matHeaderCellDef class="text-center">Yellow</th>
															<td mat-cell *matCellDef="let element" class="text-center">{{element.yellow}}</td>
														</ng-container>

														<ng-container matColumnDef="red">
															<th mat-header-cell *matHeaderCellDef>Red</th>
															<td mat-cell *matCellDef="let element">{{element.red}}</td>
														</ng-container>

														<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
														<tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer" (click)="buildGraderList(row)" [ngClass]="row.isOnChart ? 'onchart' : '' "></tr>
													</table>
													<mat-paginator [pageSizeOptions]="[10]" showFirstLastButtons> </mat-paginator>
												</div>
											</div>
											<div class="col-8 position-relative">
												<div *ngIf="aiRequestChart && !aiServiceOptSelected">
													<apx-chart
														[series]="aiGradersColumnChart.series"
														[title]="aiGradersColumnChart.title"
														[chart]="aiGradersColumnChart.chart"
														[dataLabels]="aiGradersColumnChart.dataLabels"
														[plotOptions]="aiGradersColumnChart.plotOptions"
														[responsive]="aiGradersColumnChart.responsive"
														[fill]="aiGradersColumnChart.fill"
														[stroke]="aiGradersColumnChart.stroke"
														[xaxis]="aiGradersColumnChart.xaxis"
														[yaxis]="aiGradersColumnChart.yaxis"
														[colors]="aiGradersColumnChart.colors"
														[noData]="{text: 'No data to display'}">
													</apx-chart>
												</div>
											</div>
										</div>
									</div>
									<loader [activateSpinner]="loading "></loader>
									<!-- <div class="loader-container" [ngClass]="loading ? 'show' : '' ">
										<div [hidden]="false" class="lds-ring">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div> -->

									<!--  -->
									<!-- FUNNEL CHARTS -->
									<!--  -->
									<div class="row" *ngIf="funnelCharts.length>0 ">
										<div class="col-xl-6 col-sm-12 p-3" *ngFor="let graph of funnelCharts;let i=index">
											<!-- <p class="text-upperase d-inline" style="font-size: 14px" [innerHTML]="graph.title.text"></p> -->
											<apx-chart [fill]="graph.fill" [title]="graph.title" [series]="graph.series" [chart]="graph.chart" [dataLabels]="graph.dataLabels" [plotOptions]="graph.plotOptions" [xaxis]="graph.xaxis" [colors]="graph.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											<a (click)="removePieGraph(i)" class="delete-graph-icon float-end"><fa-icon [icon]="faTrashAlt" aria-hidden="true"> </fa-icon></a>
										</div>
									</div>

									<!--  -->
									<!-- PIE CHARTS -->
									<!--  -->
									<div class="row" *ngIf="pieGraphs.length>0 ">
										<div class="col-xl-6 col-sm-12 p-3" *ngFor="let graph of pieGraphs;let i=index">
											<!-- <p class="text-upperase d-inline" style="font-size: 14px" [innerHTML]="graph.title.text"></p> -->
											<apx-chart [fill]="graph.fill" [title]="graph.title" [legend]="graph.legend" [series]="graph.series" [chart]="graph.chart" [labels]="graph.labels" [responsive]="graph.responsive" [colors]="graph.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											<a (click)="removePieGraph(i)" class="delete-graph-icon float-end"><fa-icon [icon]="faTrashAlt" aria-hidden="true"> </fa-icon></a>
										</div>
									</div>
									<!--  -->
									<!-- COLUMNS STACKED CHARTS -->
									<!--  -->
									<div class="row" *ngIf="ColumnGraphs.length>0 ">
										<div class="col-xl-6 col-sm-12 p-3" *ngFor="let graph of ColumnGraphs;let i=index">
											<!-- <p class="text-upperase d-inline" style="font-size: 14px" [innerHTML]="graph.title"></p> -->

											<apx-chart [series]="graph.series" [title]="graph.title" [chart]="graph.chart" [dataLabels]="graph.dataLabels" [plotOptions]="graph.plotOptions" [responsive]="graph.responsive" [fill]="graph.fill" [stroke]="graph.stroke" [xaxis]="graph.xaxis" [yaxis]="graph.yaxis" [colors]="graph.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											<a (click)="removeColumnGraphs(i)" class="delete-graph-icon float-end"><fa-icon [icon]="faTrashAlt" aria-hidden="true"> </fa-icon></a>
										</div>
									</div>

									<!--  -->
									<!-- COLUMNS STACKED BIGCHARTS -->
									<!--  -->
									<div class="row" *ngIf="ColumnGraphsBig.length>0 ">
										<div class="col-12 p-3" *ngFor="let graph of ColumnGraphsBig;let i=index">
											<!-- <p class="text-upperase d-inline" style="font-size: 14px" [innerHTML]="graph.title"></p> -->

											<apx-chart [series]="graph.series" [title]="graph.title" [chart]="graph.chart" [dataLabels]="graph.dataLabels" [plotOptions]="graph.plotOptions" [responsive]="graph.responsive" [fill]="graph.fill" [stroke]="graph.stroke" [xaxis]="graph.xaxis" [yaxis]="graph.yaxis" [colors]="graph.colors" [noData]="{text: 'No data to display'}"></apx-chart>
											<a (click)="removeColumnGraphsBig(i)" class="delete-graph-icon float-end"><fa-icon [icon]="faTrashAlt" aria-hidden="true"> </fa-icon></a>
										</div>
									</div>
								</div>

								<!-- <div class="col-12 statistics-graph position-relative">
									<button type="button" class="btn btn-primary mt-3" (click)="test()">Test</button>
									<button type="button" class="btn btn-primary mt-3" (click)="test1()">Test 2</button>
								</div> -->
							</ng-template>
						</li>

						<li ngbNavItem="users" [hidden]="!(session.isAdmin() || session.isSupport())">
							<a ngbNavLink>USERS</a>
							<ng-template ngbNavContent>
								<div class="catInfo">
									<!--  class="tabData sb-style"  -->
									<div class="row">
										<form name="usersForm" #usersForm="ngForm">
											<div class="full-container fields-container">
												<div class="row">
													<div class="twothird-container">
														<div class="row" [hidden]="session.isSupport()">
															<div class="form-group full-container">
																<fa-icon [icon]="faInfoCircle" style="color: #64a8f7; margin-right: 5px" aria-hidden="true"></fa-icon>
																<b>{{ myTokInfo }}</b>&nbsp;
																<a class="underline" (click)="getLoggedUsers()">[see the list]</a>

																<div style="border: 1px solid #404040; max-height: 150px; overflow-y: scroll; width: 50%" [hidden]="loggedUsers.length == 0">
																	<ul>
																		<li *ngFor="let usr of loggedUsers">&nbsp;&nbsp; {{ usr.username}} - {{usr.caller}}</li>
																	</ul>
																</div>
															</div>
														</div>

														<div class="row" [hidden]="session.isSupport()">
															<div class="form-group full-container">
																<input type="radio" name="exportType" id="expLog" value="exportLog" [(ngModel)]="exportType" />
																<label for="expLog"> &nbsp; Export SRV LOG</label>
															</div>
														</div>
														<div class="row" [hidden]="session.isSupport()">
															<div class="form-group eigth-container">&nbsp;</div>
															<div class="form-group twothird-container" (click)="exportType = 'exportLog'">
																<input type="text" class="form-control d-inline" style="width: 100px" placeholder="UserID..." name="usrId" id="usrId" [(ngModel)]="userId" />
																&nbsp; or
																<input type="text" class="form-control d-inline" style="width: 200px" placeholder="Username..." name="username" id="usrName" [(ngModel)]="username" />
																&nbsp;
															</div>
														</div>

														<div class="row" [hidden]="session.isSupport()">
															<div class="form-group full-container">
																<input type="radio" name="exportType" id="expDBLog" value="exportDBLog" [(ngModel)]="exportType" />
																<label for="expDBLog"> &nbsp; Export DB LOG</label>
															</div>
														</div>
														<div class="row" [hidden]="session.isSupport()" (click)="exportType = 'exportDBLog'">
															<div class="form-group eigth-container">&nbsp;</div>
															<div class="form-group fifth-container">
																<!-- <input type="radio" name="range" id="hh" value="24h" [(ngModel)]="dbLogRange" /> last 24h
																<br />-->
																<input type="radio" name="range" id="today" value="today" [(ngModel)]="dbLogRange" /> today
																<br />
																<input type="radio" name="range" id="yest" value="yesterday" [(ngModel)]="dbLogRange" /> since yesterday
																<br />
																<input type="radio" name="range" id="week" value="week" [(ngModel)]="dbLogRange" /> last week
																<br />
																<input type="radio" name="range" id="month" value="month" [(ngModel)]="dbLogRange" /> last month
															</div>
															<div class="form-group half-container">
																<input type="radio" name="DBlogType" id="login" value="login" [(ngModel)]="dbLogType" /> all login attempts
																<br />
																<input type="radio" name="DBlogType" id="err" value="errors" [(ngModel)]="dbLogType" /> all errors
																<br />
																<input type="radio" name="DBlogType" id="user" value="user" [(ngModel)]="dbLogType" /> specific user id: <input type="text" class="form-control d-inline" name="user_id" style="width: 80px" [(ngModel)]="userId" /> &nbsp;
															</div>
														</div>

														<div class="row">
															<div class="form-group full-container">
																<input type="radio" name="exportType" id="expUsrs" value="exportUsers" [(ngModel)]="exportType" />
																<label for="expUsrs"> &nbsp; Export users info (with credits)</label>
															</div>
														</div>
														<div class="row" (click)="exportType = 'exportUsers'">
															<div class="form-group eigth-container">&nbsp;</div>
															<div class="form-group fifth-container">
																<label for="toDay">TO DAY * &nbsp;</label>
																<p class="input-group birth">
																	<input type="text" class="form-control" [(ngModel)]="toDay" name="toDay" id="toDay" maxlength="10" #dt="ngbDatepicker" #eToDay="ngModel" ngbDatepicker [minDate]="minRepDate" [maxDate]="maxRepDate" placeholder="yyyy-mm-dd" required />
																	<button (click)="dt.toggle()" class="btn btn-outline-secondary">
																		<fa-icon [icon]="faCalendar" aria-hidden="true"></fa-icon>
																	</button>
																</p>
																<!-- <p class="error-message ng-hide" [hidden]="!(eToDay.touched && eToDay.invalid)">{{ 'MESSAGE.VALID_DATE' | translate }}</p> -->
															</div>

															<div class="form-group half-container">
																<span *ngIf="!session.isSupport()">
																	<br />
																	<input type="radio" name="usrLevel" id="typeC" value="Specialist" [(ngModel)]="usrOption" /> remote specialists (typeC, lev2) &nbsp;
																</span>
																<br />
																<input type="radio" name="usrLevel" id="type1" value="lev1" [(ngModel)]="usrOption" /> device users (typeA + typeB, lev1) &nbsp;
																<!-- 
									  <br><input type="radio" name="usrLevel" id="typeA" value="Optician" [(ngModel)]="usrType" > opticians (typeA, lev1) &nbsp;      
									  <br><input type="radio" name="usrLevel" id="typeB" value="Doctor" [(ngModel)]="usrType" > doctors (typeB, lev1) &nbsp; 
									  -->
															</div>
														</div>

														<div class="row" [hidden]="session.isSupport()">
															<div class="form-group full-container">
																<input type="radio" name="exportType" id="usrActivity" value="usrActivity" [(ngModel)]="exportType" />
																<label for="usrActivity"> &nbsp; Export users activity</label>
															</div>
														</div>

														<div class="row" [hidden]="session.isSupport()">
															<div class="form-group eigth-container">&nbsp;</div>
															<div class="form-group twothird-container" (click)="exportType = 'usrActivity'">
																<input type="radio" name="activeLevel" id="activeUsr" value="count_usr_visits" [(ngModel)]="activeType" /> Users who have made visits in the last <input type="text" name="days" size="2" value="7" [(ngModel)]="activityDays" /> days &nbsp;
																<br />
																<input type="radio" name="activeLevel" id="inactive" value="inactive_users" [(ngModel)]="activeType" [attr.disabled]="true" />
																Inactive users: no exams in the last <input type="text" size="2" value="30" /> days &nbsp;
																<br />
																<input type="radio" name="activeLevel" id="typeSlow" value="slow_specs" [(ngModel)]="activeType" [attr.disabled]="true" />Specialists with more than <input type="text" size="2" value="10" /> exams to review &nbsp;
																<br />
																<input type="radio" name="activeLevel" id="other" value="other" [(ngModel)]="activeType" [attr.disabled]="true" /> Other - TODO &nbsp;
															</div>
														</div>

														<p>&nbsp;</p>
													</div>
												</div>
											</div>
											<div class="full-container fields-container">
												<div class="row">
													<div class="half-container">&nbsp;</div>
													<div class="half-container">
														<div class="third-container">
															<button type="submit" class="btn btn-primary btn-block" [disabled]="btnCountDisabled" (click)="submitUsers()">{{ 'BUTTONS.PROCEED' | translate }}</button>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</ng-template>
						</li>

						<li ngbNavItem="images" [hidden]="session.isSupport()">
							<a ngbNavLink>IMAGES</a>
							<ng-template ngbNavContent>
								<div class="catInfo">
									<div class="row">
										<label class="text-center"><b>COUNT exams IMAGES</b></label>
										<form name="ImagesForm" #imagesForm="ngForm">
											<div class="row">
												<div class="half-container fields-container">
													<!-- colonna vincoli -->

													<!-- 
								<div class="row" >                      
								  <div class="form-group half-container">
									<label for="fromDay">FROM DAY  &nbsp;</label>  
									<p class="input-group birth">
									  <input type="text" class="form-control"  [(ngModel)]="fromDay" name="fromDay" id="fromDay" 
										  #df="ngbDatepicker" #iFromDay="ngModel"                                     
										  ngbDatepicker [minDate]="minRepDate" [maxDate]="maxRepDate" placeholder="yyyy-mm-dd"  />
									  <button (click)="df.toggle()" class="btn btn-outline-secondary"><fa-icon [icon]="faCalendar" aria-hidden="true"></fa-icon></button>
									</p>                               
								  </div>                          
								  <div class="form-group half-container">
									<label for="toDay">TO DAY &nbsp;</label>                        
									<p class="input-group birth"> 
									  <input type="text" class="form-control" [(ngModel)]="toDay" name="toDay" id="toDay" 
										  #dt="ngbDatepicker" #eToDay="ngModel" 
										  ngbDatepicker [minDate]="minRepDate" [maxDate]="maxRepDate" placeholder="yyyy-mm-dd" />
									  <button (click)="dt.toggle()" class="btn btn-outline-secondary"><fa-icon [icon]="faCalendar" aria-hidden="true"></fa-icon></button>
									</p>                                  
								  </div>                                                 
								</div>
							  -->

													<div class="row">
														<div class="form-group half-container">
															<label for="examType">Exam type *</label>
															<select class="form-control" name="examType" [(ngModel)]="examTypeFilter" #errExamType="ngModel" required>
																<!--  style="margin-top:7px;" -->
																<option value="">Choose one ...</option>
																<option *ngFor="let opt of examTypes" [value]="opt">{{ opt }}</option>
															</select>
															<p class="error-message" [hidden]="!(errExamType.touched && errExamType.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
														</div>
													</div>

													<div class="row">
														<div class="form-group half-container">
															<label for="device">Device </label>
															<select class="form-control" name="device" [(ngModel)]="device">
																<option value="">All Models</option>
																<option *ngFor="let opt of session.deviceModels" [value]="opt">{{ opt }}</option>
															</select>
														</div>
													</div>

													<div class="row">
														<div class="form-group half-container">
															<label for="eye">Eye </label>
															<br />
															<input type="radio" name="eye" id="right" value="right" [(ngModel)]="eye" /> right &nbsp; <input type="radio" name="eye" id="left" value="left" [(ngModel)]="eye" /> left &nbsp; <input type="radio" name="eye" id="any" value="any" [(ngModel)]="eye" /> any &nbsp;
														</div>
													</div>

													<div class="row">
														<div class="form-group half-container">
															<label for="operId">Operator Id </label>
															<input type="text" class="form-control" name="operId" id="operId" [(ngModel)]="operId" />
														</div>
													</div>
												</div>

												<div class="half-container fields-container vert_line">
													<!-- colonna comandi e results -->
													<div class="row">
														<div class="form-group sixth-container">&nbsp;</div>
														<div class="form-group half-container">
															&nbsp;<br />
															<!-- [disabled]="!imagesForm.form.valid || btnCountDisabled" -->
															<button type="submit" class="btn btn-primary btn-block" [disabled]="!imagesForm.valid || btnCountDisabled" (click)="countImages()">COUNT</button>
														</div>
													</div>

													<div class="row">
														<div class="form-group sixth-container">&nbsp;</div>
														<div class="half-container">
															<p>Result: <b>{{ msgTot }}</b></p>
														</div>
													</div>

													<!-- 
													<div class="row">
														<div class="form-group sixth-container">&nbsp;</div>
														<div class="form-group half-container">
															&nbsp;<br />
															<button type="submit" class="btn btn-primary btn-block" [disabled]="btnGetImgDisabled" (click)="getImages()">Get IMAGES</button> TODO
														</div>
													</div>
							-->

													<div class="row">
														<div class="form-group sixth-container">&nbsp;</div>
														<div class="half-container">
															<p style="display: inline-block">
																<b>{{ statusMsg }}</b>
															</p>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</ng-template>
						</li>

						<li ngbNavItem="patients" [hidden]="!session.isAdmin()">
							<a ngbNavLink>PATIENTS</a>
							<ng-template ngbNavContent>
								<div class="catInfo">
									<div class="row">
										<form name="patsForm" #patsForm="ngForm">
											<div class="full-container fields-container">
												<div class="row"><b>Statistics on patients</b></div>

												<div class="row">&nbsp;</div>

												<div class="row">
													<div class="full-container">
														<fa-icon [icon]="faInfoCircle" style="color: #64a8f7; margin-right: 5px" aria-hidden="true"></fa-icon>
														Patient code: &nbsp;
														<input type="text" name="patCode" size="5" placeholder="P123" [(ngModel)]="patientFilter" /> &nbsp;&nbsp; [<a class="underline" (click)="findPatient()">Find</a>]
														<br />
														<div>{{ myPatientInfo }}<br />&nbsp;</div>
													</div>
												</div>

												<div class="row">
													<div class="full-container">
														<fa-icon [icon]="faInfoCircle" style="color: #64a8f7; margin-right: 5px" aria-hidden="true"></fa-icon>
														Pie Chart on patients, grouped by sex and/or age, other - TODO
													</div>
												</div>

												<div class="row">&nbsp;</div>
											</div>

											<div class="full-container fields-container">
												<div class="row">
													<div class="half-container">&nbsp;</div>
													<div class="half-container">
														<div class="third-container">
															<button type="submit" class="btn btn-primary btn-block" [disabled]="!patsForm.form.valid || btnCountDisabled" (click)="submitPatients()">{{ 'BUTTONS.PROCEED' | translate }}</button>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</ng-template>
						</li>

						<li ngbNavItem="devices" [hidden]="!session.isAdmin()" [disabled]="true">
							<a ngbNavLink>DEVICES</a>
							<ng-template ngbNavContent>
								<div class="catInfo">
									<div class="row">
										<form name="devForm" #devForm="ngForm">
											<div class="full-container fields-container">
												<div class="row"><b>Statistics on devices</b></div>

												<div class="row">&nbsp;</div>
												<!-- spacer -->

												<div class="row">
													<div class="half-container">
														<fa-icon [icon]="faInfoCircle" style="color: #64a8f7; margin-right: 5px" aria-hidden="true"></fa-icon>
														List last 10 visits done with device &nbsp;
													</div>
													<div class="quarter-container">
														<select class="form-control" name="device" [(ngModel)]="device">
															<option value="">Choose...</option>
															<option *ngFor="let opt of session.deviceModels" [value]="opt">{{ opt }}</option>
														</select>
													</div>

													<div class="quarter-container">
														<a href="#">[search...]</a>
													</div>
												</div>

												<div class="row">&nbsp;</div>
												<!-- spacer -->

												<div class="row">
													<div class="full-container">
														<fa-icon [icon]="faInfoCircle" style="color: #64a8f7; margin-right: 5px" aria-hidden="true"></fa-icon>
														Pie Chart on devices, grouped by model - TODO
													</div>
												</div>

												<div class="row">&nbsp;</div>
												<!-- spacer -->
											</div>

											<div class="full-container fields-container">
												<div class="row">
													<div class="half-container">&nbsp;</div>
													<div class="half-container">
														<div class="third-container">
															<button type="submit" class="btn btn-primary btn-block" [disabled]="!devForm.form.valid || btnCountDisabled" (click)="submitDevices()">{{ 'BUTTONS.PROCEED' | translate }}</button>
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</ng-template>
						</li>
					</ul>
				</div>

				<!-- where currently active nav content will be displayed: -->
				<div class="statistic-container__content">
					<div [ngbNavOutlet]="nav" class=""></div>
					<!-- class="mt-2" class="ml-4" -->

					<!-- bottomRow 
				<div class="row">
				  <div class="close-button-container" > 
					<div>
					  <button (click)="goLanding()" class="btn btn-exit">{{"BUTTONS.CLOSE" | translate}}</button>
					</div>
				  </div>
				</div>
			  -->
				</div>
			</div>
		</div>
	</div>
</div>
