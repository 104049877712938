import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core'
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap'

import { Toast } from 'src/app/models/toast.model'
import { canvasService } from 'src/app/service/offcanvas.service'
import { AppToastService } from 'src/app/service/toast.service'

import { faThumbsUp, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faCircleInfo, faTriangleExclamation, faChevronDown, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { SessionService } from 'src/app/service/session.service'
import { Router } from '@angular/router'

@Component({
	selector: 'app-offcanvas',
	templateUrl: './offcanvas.component.html',
	styleUrls: ['./offcanvas.component.scss'],
})
export class OffcanvasComponent implements OnInit {
	@ViewChild('content', { static: true }) content: TemplateRef<any>

	dshow: boolean

	faThumbsUp = faThumbsUp
	faCircleXmark = faCircleXmark
	faCircleInfo = faCircleInfo
	faTriangleExclamation = faTriangleExclamation
	faChevronDown = faChevronDown
	faUpRightFromSquare = faUpRightFromSquare

	constructor(
		public offcanvasService: NgbOffcanvas,
		public canvasService: canvasService,
		private toastService: AppToastService,
		public session: SessionService,
		private router: Router
	) {
		this.dshow = false
	}

	ngOnInit(): void {
		this.canvasService.open.subscribe((res) => {
			if (res == 'open') {
				this.toastService.clear()
				this.offcanvasService.open(this.content, { position: 'end' })
			}
		})
	}

	dismiss(toast: Toast) {
		this.canvasService.remove(toast)
	}

	onGoToEcommerceClick() {
		this.offcanvasService.dismiss()
		this.session.gotoEcomm()
	}

	goToUrl(url: string) {
		this.toastService.clear()
		this.router.navigateByUrl(url)
	}
}
