<div>
	<table mat-table [dataSource]="dataSource" class="row-border hover" matSort>
		<ng-container matColumnDef="id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
			<td mat-cell *matCellDef="let row">{{ row.id }}</td>
		</ng-container>

		<ng-container matColumnDef="creation_date">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AI_REPORTS.REQUESTED' | translate }}</th>
			<td mat-cell *matCellDef="let row">{{ session.formatDateTime(row.creation_date) }}</td>
		</ng-container>

		<ng-container matColumnDef="device">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.DEVICE' | translate }}</th>
			<td mat-cell *matCellDef="let row">{{ row.device }}</td>
		</ng-container>

		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.STATUS' | translate }}</th>
			<td mat-cell *matCellDef="let row">{{ row.getStatus() }}</td>
		</ng-container>

		<ng-container matColumnDef="batch">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>batch</th>
			<td mat-cell *matCellDef="let row">{{ row.batch_id }}</td>
		</ng-container>

		<ng-container matColumnDef="TL">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>
				<span ngbTooltip="{{ 'AI_REPORTS.TL_FULL' | translate }}" placement="bottom-start">{{ 'AI_REPORTS.TL' | translate }}</span>
			</th>
			<td mat-cell *matCellDef="let row">
				<!-- {{row.getTrafficLight()}} -->
				<span class="text-center pointer" (click)="showGrading(row)">
					<div id="color-badge" [ngClass]="row.getTrafficLight()">{{ getTlFaIcon(row) }}</div>
				</span>
			</td>
		</ng-container>

		<ng-container matColumnDef="grading">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>grading</th>
			<td mat-cell *matCellDef="let row">
				<a (click)="showGrading(row)" ngbTooltip="show..." placement="left">
					<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
				</a>
			</td>
		</ng-container>

		<!-- Actions Column -->
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef>&nbsp;{{ 'BUTTONS.DOWNLOAD' | translate }}</th>
			<td mat-cell *matCellDef="let row">
				<div *ngIf="!row.is_waiting_PDF">
					<a class="table-button ms-4" *ngIf="session.isLevel1() && row.status >= 3" (click)="downloadPdf(row)" ngbTooltip="{{ 'BUTTONS.DOWNLOAD' | translate }}" placement="right">
						<fa-icon [icon]="faSave" aria-hidden="true"></fa-icon>
					</a>
				</div>

				<div *ngIf="row.is_waiting_PDF" class="spinner-border spinner-border-sm text-primary ms-4" role="status">
					<span class="sr-only">Loading...</span>
				</div>
			</td>
		</ng-container>

		<!-- <ng-container matColumnDef="filter">
			<th mat-header-cell *matHeaderCellDef class="text-end">
				<mat-form-field appearance="outline">
					<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
				</mat-form-field>
			</th>
			<td mat-cell *matCellDef="let element"></td>
		</ng-container> -->

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="row.status == 3 ? 'fw-bold' : ''"></tr>
	</table>

	<mat-paginator [hidden]="reportList.length < 11" [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons> </mat-paginator>
</div>
<!-- </div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> -->
