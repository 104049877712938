// e' l'utente loggato, quindi farmacista, medico, distributore, refertatore, ecc.
import { ProfilesResponse } from './profile.model'
import { Specialist } from './specialist.model'
import { Address } from './address.model'
import { AdmSettings, Settings, Preferences } from './settings.model'
import { DateParser } from './dateParser.model'
import { Util } from './util.model'
import { CsvLine } from './csvLine.model'

import { AgreementInfo, AgreementsStatus, AgrStatus, AgrType } from './agreement.model'

import { Config } from '../../config'
import { CryptoUtilsService } from '../service/crypto-utils.service'
import * as forge from 'node-forge' // per le keybox
import { SaleInfo, BalanceRec, service, serviceType, serviceStatus, serviceMode } from './salePlan.model'
import { LatLng } from './map.model'

// ATT: e' un misto di livelli, profili, sotto-tipi...
export enum UserType {
	NONE,
	USER_FIRST_TIME,
	OPERATOR,
	DEALER,
	GOD,
	SPECIALIST,
	OPTICIAN,
	USER_PUK_TIME,
	VICE,
	DOCTOR,
	DISTRIB,
	MANAGER,
	STATS,
	SUB_STD,
	SUB_SUPER,
	SUB_MINI,
	SERVICE,
	SUPPORT,
	INSTALLER,
	CLINIC,
}

// 25.03.2022 piu' chiaro ? TODO sistemare [ls]
//export enum UserProfile { OPTICIAN, DOCTOR, SPECIALIST, DISTRIB, MANAGER, STATS, VICE}

// 28.10.2020 aggiunto stato "connection error"
// 12.11.2021 AGGIORNAMENTO ANGULAR - SPOSTATO DA session.service
export enum SessionStatus {
	NOT_LOGGED,
	LOGGING,
	LOGGED,
	LOG_FAILED,
	CONNECT_ERROR,
}

// 19.01.2022
export const INACTIVE = ' (inactive)'
//export const INACTIVE_1 = " (activating)";  // chi ha iniziato la first login ma deve fare la verifica puk

// 16.02.2022
export const DATE_US = 'MM/dd/yyyy'
export const DATE_EU = 'dd/MM/yyyy' // att: MM = mese, mm = minuti
export const DATE_SQL = 'yyyy-MM-dd'

// 31.08.2022 TODO, core class estesa da tutti: doctor, specialist, loggeduser...

export class CoreUser {
	user_id: number
	code: string
	firstname: string
	lastname: string

	user_type: string
	user_subtype: string
	is_test: string // 13.10.2021 Y or N

	mainAddress: Address
	settings: Settings

	constructor(rawObj) {
		this.mainAddress = new Address()
		this.settings = new Settings()

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}

	// used for export anagr from statistics, both for lev2 and lev1 users
	static getCsvTitle(type?) {
		let ret = ''
		if (!type || type == 'anagr') {
			// prettier-ignore
			ret =
				'ID' + CsvLine.SEP +
				'username' + CsvLine.SEP +
				'type' + CsvLine.SEP +
				'subtype' + CsvLine.SEP +
				//"email" + CsvLine.SEP +   // not GDPR compliant  30.08.2022
				'country' + CsvLine.SEP +
				//"space" + CsvLine.SEP +    // 13.09.2022
				//'space in MB' + CsvLine.SEP + // 13.09.2022
				'created' + CsvLine.SEP +
				'activated' + CsvLine.SEP +
				'flag_test' + CsvLine.SEP; // 04.08.2022
		} else if (type == 'credits') {
			// 03.10.2022
			// prettier-ignore
			ret =
				'ID' + CsvLine.SEP +
				'username' + CsvLine.SEP +
				'type' + CsvLine.SEP +
				'subtype' + CsvLine.SEP +
				'country' + CsvLine.SEP +
				'specialists' + CsvLine.SEP +  // 17.10.2022
        'space in MB' + CsvLine.SEP + // 13.09.2022
				'created' + CsvLine.SEP +
				'activated' + CsvLine.SEP +
				'flag_test' + CsvLine.SEP + // 04.08.2022
				'Level plan' + CsvLine.SEP +
        'purchased CR' + CsvLine.SEP +   // 20.12.2022 tot acquistati
				'available CR' + CsvLine.SEP +
				'expiry dt' + CsvLine.SEP +
				'Unlimited AI' + CsvLine.SEP;
		}
		return ret
	}
}

// in questo file: User, UsrAnamnesis, UsrDicom, UserDevice
// e' un mix delle tabelle sul DB user_access e user, classe usata per l'utente loggato.
export class User {
	//12.12.2017 aggiunto user_id da mostrare su pdf puk
	user_id: number

	username: string
	password: string

	//name: string;
	//surname: string;  // 25.03.2022 allineati al DB
	firstname: string
	lastname: string

	//02.03.2017 aggiunto code per visualizzarlo info user
	code: string

	mainAddress: Address // 25.03.2022 uso oggetto
	addresses: Address[]

	//group: number;  // 21.06.2019 per anamnesi

	keyboxDoctor: string // only for level1 users
	keyboxPhoto: string

	// non dovrebbe servire su user ma su doctors e specialist
	//keyboxAdmin: any;

	keyboxVice: any // 06.08.2018 serve solo agli utenti di tipo vice

	keyDoctor: forge.util.ByteStringBuffer
	keyPhoto: forge.util.ByteStringBuffer
	keyDistrib: forge.util.ByteStringBuffer // 07.02.2017 var di appoggio, usata dai livelli2 o 3, cambia in base al doctor che si sta guardando

	public_key: string // 11.03.2020
	privateKeybox: any // 19.03.2020 per livelli1 e graders, contiene la private_key, della asymetric, crittata

	pukData: any // serve
	pukMetadata: any // non serve

	token: string

	type: UserType // identifica il livello
	subType: UserType // corrisponde a user_type sul DB (profilo), per differenziare doctor da farm e dealer da spec,   --ls

	role: string // 25.03.2022 valore "profilo" dal DB

	user_subtype: string // 17.04.2020 sottotipo dal DB, ATT a non confondere i campi!
	is_test: string // 13.10.2021 Y or N
	country: string // 06.09.2022 stringa di 3 caratteri, in chiaro

	// anamnesis_group: number // 22.02.2023 // moved on settings
	groupId: number // 23.02.2023 per utenti di gruppo

	specialists: Specialist[] // 29.05.2017 solo per opticians
	logo: string
	logo_name: string
	settings: Settings // 11.10.2017
	order_reg_num: string // 16.05.2018 per doctor e specialist, // 13.09.2019 serve
	licence_num: string // crittato
	licence_hashed: string

	//doctor_info: DoctorInfo;  // 03.10.2018
	admSettings: AdmSettings // 27.05.2021
	// agreementInfo: AgreementInfo // 27.05.2022
	// agreementInfoTH: AgreementInfo
	subscriptionTime: Date // 26.10.2022

	agreements_status: AgreementsStatus[]
	agreementPatientsInfo: AgrStatus //07.03.23

	//sale_plan_id: number; // 19.08.2021   // ora un doppione, avendo il salePlan ? dismettere... [ls]
	saleInfo: SaleInfo // 22.12.2022 contiene a sua volta il proprio salePlan e available credits, che cambia spesso
	//salePlan: SalePlan;  // 28.12.2022 basta uno per session
	balanceRecords: BalanceRec[] //  only on specific request to APIs, ricaricare spesso - ogni quanto ?

	// Aggiunti 06.06.2023
	display_name: string
	signature: string
	signature_name: string
	status: string
	vacancies: {
		id?: number
		from_date: string
		to_date: string
	}[]

	userDevices: UserDevice[]

	/*
  constructor() {   
    this.type = UserType.NONE;   
    this.subType = UserType.NONE;
  }
  */

	// ricevo qui il cryptoUtils, poi lo uso su initProfile
	constructor(private cryptoUtils: CryptoUtilsService, myType?: UserType, subtype?: UserType) {
		this.username = ''
		this.password = ''

		this.type = myType != null ? myType : UserType.NONE
		this.subType = subtype != null ? subtype : UserType.NONE
		this.role = ''

		this.mainAddress = new Address()
		this.addresses = []

		this.settings = new Settings()
		this.admSettings = new AdmSettings()

		this.is_test = 'N' // 13.10.2021
		this.country = ''
		// this.anamnesis_group = 1 // 22.02.2023 default 1
		this.groupId = 0

		this.agreements_status = []
		this.agreementPatientsInfo = AgrStatus.IGNORE //07.03.23

		//this.sale_plan_id = 0;
		this.saleInfo = null
		//this.salePlan = null;
		this.balanceRecords = []

		// Aggiunti 06.06.2023
		this.display_name = ''
		this.signature = ''
		this.signature_name = ''
		this.logo = ''
		this.logo_name = ''
		this.status = 'active'
		this.vacancies = []
		this.userDevices = []
	}

	static buildToken(tokenResponse: TokenResponse): string {
		return tokenResponse.token_type + ' ' + tokenResponse.access_token
	}

	// 12.01.2022 TODO spostare ?!
	static buildTokenResp(myVal: string): TokenResponse {
		let tokenResponse: TokenResponse

		let pieces = myVal.toString().split(' ')
		if (pieces != null && pieces.length > 1) {
			tokenResponse = {
				token_type: pieces[0],
				access_token: pieces[1],
				created_at: 0,
				expires_in: 0,
			}
		}
		return tokenResponse
	}

	// 15.11.2021 aggiunto parametro cryptoUtils
	static createUser(cryptoUtils: CryptoUtilsService, tokenResponse: TokenResponse, username: string, password: string) {
		var result = new User(cryptoUtils)
		result.token = User.buildToken(tokenResponse)
		result.username = username
		result.password = password
		return result
	}

	// ex refreshProfile, piu' esatto initProfile....
	initProfile(profileResponse: ProfilesResponse): Promise<boolean> {
		var rawProfile = profileResponse.profile // con dati crittati

		// console.log(profileResponse)

		// 19.08.2021
		//console.log("(initProfile) raw:");
		//console.log(rawProfile);

		//this.name = rawProfile.firstname;  // crittati
		//this.surname = rawProfile.lastname;

		this.code = rawProfile.code
		// 12.12.2017 aggiunto user_id per pdf puk
		this.user_id = rawProfile.user_id

		this.order_reg_num = rawProfile.order_reg_num // 13.09.2019

		this.display_name = rawProfile.display_name

		this.signature = rawProfile.signature

		this.signature_name = rawProfile.signature_name

		this.logo = rawProfile.logo

		this.logo_name = rawProfile.logo_name

		this.status = rawProfile.status

		this.userDevices = []

		this.addresses = []
		let addrArray: Address[]

		if (rawProfile.addresses && rawProfile.addresses[0]) {
			addrArray = rawProfile.addresses // crittato
		} else {
			Util.debug('(initProfile) missing address')
		}
		// 06.02.2017 dismessi --ls
		//this.lastRechargeDate = DateParser.parseDate(rawProfile.last_visit);
		//this.credits = rawProfile.credits;

		if (rawProfile.user_access) {
			this.keyboxDoctor = rawProfile.user_access.keybox_doctor
			this.keyboxPhoto = rawProfile.user_access.keybox_photo
			this.keyboxVice = rawProfile.user_access.keybox_vice

			// 10.02.2021 non serve ?
			//this.keyboxAdmin = rawProfile.user_access.keybox_admin;

			// 08.02.2021 FIXME verificare se davvero servono
			this.pukData = rawProfile.user_access.puk_data // 02.03.2021 serve nella first login
			//this.pukMetadata = rawProfile.user_access.puk_metadata;

			// 24.08.2022 era commentato, riabilitato
			// 19.03.2020 la salvo come box, la apro al bisogno
			this.privateKeybox = rawProfile.user_access.private_keybox

			// 09.05.2024 aggiunta public_key, serve allo specialist per sistemare rels vecchie
			if (rawProfile.user_access.public_key) {
				this.public_key = rawProfile.user_access.public_key
			}

			// 16.04.2019 tolta trace [ls]
			//if(this.keyboxPhoto)
			//	console.log("(initProfile) len keyboxPhoto: "+this.keyboxPhoto.length);
		} else {
			Util.debug('(initProfile) ko dati user_access')
		}

		// ATT: nomi fuorvianti !!!  [ls]

		this.type = User.getUserLevel(profileResponse)
		this.subType = User.getUserProfile(profileResponse) // 17.02.2017

		// console.log(this.type)
		// console.log(this.subType)

		this.user_subtype = rawProfile.user_subtype // 17.04.2020

		// console.log(this.user_subtype)

		Util.debug('(U initProfile) DB userType: ' + rawProfile.user_type + ' sub:' + this.user_subtype)

		// 08.09.2022
		this.role = rawProfile.user_type

		//this.group = rawProfile.user_group;  // 21.06.2019 per anamnesi

		// 13.10.2021
		if (rawProfile.is_test) this.is_test = rawProfile.is_test

		// 06.09.2022
		if (rawProfile.country) {
			this.country = rawProfile.country
		}

		// 22.02.2023
		// if (rawProfile.anamnesis_group) {
		// 	this.anamnesis_group = rawProfile.anamnesis_group
		// }

		if (rawProfile.groupId) {
			this.groupId = rawProfile.groupId
		}

		// sono in chiaro
		if (rawProfile.settings) {
			this.settings = new Settings(rawProfile.settings)
		} else if (rawProfile.settings_admin) {
			// 27.05.2021

			var models = rawProfile.settings_admin.models
			Util.debug('(initProfile) settings_admin, models: ' + models)
			this.admSettings = new AdmSettings(rawProfile.settings_admin)
		}

		// 08.02.2021
		//  uso lo username per "salare" la pwd
		//var usrname = this.username.toLowerCase();
		var mySalt = this.username.toLowerCase()

		if (rawProfile.agreements_status) {
			this.agreements_status = rawProfile.agreements_status
		}

		// 07.03.2023 serve per capire se questo optician ha l'agreement per i pazienti
		if (rawProfile.patients_agreement_status) {
			this.agreementPatientsInfo = rawProfile.patients_agreement_status
		}

		// 26.10.2022
		if (rawProfile.subscription_time) {
			this.subscriptionTime = DateParser.parseDate(rawProfile.subscription_time)
		}

		if (rawProfile.vacancies) {
			this.vacancies = rawProfile.vacancies
		}

		if (rawProfile.devices && rawProfile.devices.length > 0) {
			// console.log(rawProfile.devices)
			rawProfile.devices.forEach((element) => {
				this.userDevices.push(new UserDevice(element))
			})
		}

		// 09.05.2024 devo mantenere entrambi i campi, per poter verificare se ho fatto la migrazione o no
		if (rawProfile.order_reg_num) this.order_reg_num = rawProfile.order_reg_num
		if (rawProfile.licence_num) this.licence_num = rawProfile.licence_num

		// 28.12.2022 no, usiamo saleInfo con dentro il piano
		// if (rawProfile.sale_plan) {
		//   this.salePlan = new SalePlan(rawProfile.sale_plan);
		//   //this.sale_plan_id = this.salePlan.id;
		// }

		let fieldToDecrypt: string[] = Config.fieldToDecrypt
		let fieldToDecryptAddr: string[] = Config.fieldToDecryptAddr

		// 06.02.2017 tutti i tipi utente --ls
		if (this.type != UserType.NONE) {
			if (this.keyboxPhoto) {
				//console.log("(U initProfile) inizio decrypt campi, salt: "+mySalt); // 16.04.2019 tolta trace [ls]

				return this.cryptoUtils.decryptDataWithPwdS(this.password, this.keyboxPhoto, mySalt).then((myKeyPhoto: forge.util.ByteStringBuffer) => {
					this.keyPhoto = myKeyPhoto

					//field info user creo una bag
					var myBag = this.cryptoUtils.generateBag()
					for (let field of fieldToDecrypt) {
						myBag[field] = rawProfile[field]
					}
					this.cryptoUtils.purge(myBag)

					// field adresses che é un aray creo un array di bag
					if (addrArray != null) {
						var myBagAddrArray = []
						for (let addr of addrArray) {
							let myBagAddr = this.cryptoUtils.generateBag()

							for (let field of fieldToDecryptAddr) {
								myBagAddr[field] = addr[field]
							}
							this.cryptoUtils.purge(myBagAddr)
							myBagAddrArray.push(myBagAddr)
						}
					}

					//return this.cryptoUtils.decryptBag(myKeyPhoto, myBag)  // 17.11.2021 OK
					return this.cryptoUtils.decryptDataWithKey(myKeyPhoto, myBag).then((bag) => {
						this.firstname = bag['firstname']
						this.lastname = bag['lastname']

						this.signature = bag['signature']
						this.logo = bag['logo']
						this.display_name = bag['display_name']
						this.signature_name = bag['signature_name']

						if (bag['licence_num']) {
							this.order_reg_num = bag['licence_num'] // || rawProfile.order_reg_num
						}

						//dopo avrer decrittato i dati generali sopra, decritto l'array di address
						let promises = []
						for (let bagAddr of myBagAddrArray) {
							promises.push(this.cryptoUtils.decryptDataWithKey(myKeyPhoto, bagAddr))
						}

						let fieldClearAddr: string[] = Config.fieldClearAddr

						return Promise.all(promises).then((bags) => {
							// console.log(bags)
							//reinserisco i dati in chiaro che sono rimasti fuori dalle bag

							// for (let i = 0; i < bags.length; i++) {
							// 	const bag = bags[i]

							// 	for (let field of fieldClearAddr) {
							// 		bag[field] = addrArray[i][field]
							// 	}
							// }
							for (let n = 0; n < bags.length; n++) {
								const bag = bags[n]

								for (let i = 0; i < fieldClearAddr.length; i++) {
									const field = fieldClearAddr[i]

									if (addrArray[n][field]) {
										bag[field] = addrArray[n][field]
									}
								}
								this.addresses.push(Address.initFromBag(bag))
							}
							this.mainAddress = this.addresses[0] //il primo é il main

							//this.mainAddress = new Address(bag) // 29.08.2022 meglio metodo standard, codice piu' pulito - fix (bug 211) [ls]
							// this.mainAddress = Address.initFromBag(bag) // TODO, test quale delle due ???

							// solo per i lev1
							if (this.keyboxDoctor != null) {
								// nella first login il keyboxDoct non c'e'

								// 17.11.2021 nuovo oggetto, poi viene ri-duplicato nelle funz aes.
								var goodKey = new forge.util.ByteStringBuffer(this.keyPhoto)

								return this.cryptoUtils.decryptDataWithPwdS(this.password, this.keyboxDoctor, mySalt).then((myKeyDoctor: forge.util.ByteStringBuffer) => {
									this.keyDoctor = myKeyDoctor // la key private per dati sensibili pazienti

									Util.debug('(initProfile) ok keyDoctor ') // ko print

									// 04.08.2022 test
									let totSpec = 0

									if (this.isLevel1()) {
										// agli altri levels non interessano
										if (rawProfile.specialists) {
											totSpec = rawProfile.specialists.length
										}
										Util.debug('(initProfile) tot specialists: ' + totSpec)
									}

									this.specialists = [] // nuovo, vuoto  // 26.01.2023 anticipato qui

									// 04.08.2022 FIXME, fare array di promises [ls]

									// 29.05.2017 elenco dei refertatori associati, il display_name e' crittato
									if (rawProfile.specialists) {
										// 18.08.2021 solo per test trace
										var specList = rawProfile.specialists
										Util.debug('(initProfile) has specialists: ' + specList.length)
										//console.log("(initProfile) first specialist");
										//console.log(specList[0]);

										//this.specialists = [] // nuovo, vuoto

										return Specialist.createSpecialistList(specList, this.cryptoUtils, goodKey).then((listaSpec) => {
											this.specialists = listaSpec
											Util.debug('(initProfile) ok ' + this.specialists.length + ' specialists ')

											// 25.07.2017
											if (rawProfile.logo) {
												var logo = rawProfile.logo

												return this.cryptoUtils
													.decryptDataWithKey(goodKey, logo)
													.then((imgLogo) => {
														this.logo = imgLogo
														Util.debug('(initProfile) ok img logo ')
														return true
														//return Promise.all(this.specialists);   // 18.11.2021
													})
													.catch((err) => {
														console.log('(initProfile) [1] ko logo ')
														console.log(err.message)
														return true
														//return Promise.all(this.specialists);   // 18.11.2021
													})
											} else {
												return true
												//return Promise.all(this.specialists);   // 18.11.2021
											}
										})
									}
									// 25.07.2017
									else if (rawProfile.logo) {
										var logo = rawProfile.logo
										//var goodKey = this.keyPhoto;
										//goodKey = new forge.util.ByteStringBuffer(this.keyPhoto);

										//console.log("(initProfile) prima di decrypt logo - TEST ");

										return this.cryptoUtils
											.decryptDataWithKey(goodKey, logo)
											.then((imgLogo) => {
												this.logo = imgLogo
												Util.debug('(initProfile) [f] ok img logo ')
												return true
												//return Promise.all(this.logo);   // 18.11.2021
											})
											.catch((err) => {
												console.log('(initProfile) [2] ko logo ')
												console.log(err.message)
												return true
												//this.logo = "";
												//return Promise.all(this.logo);   // 18.11.2021
											})
									} else {
										Util.debug('(initProfile) missing specialists and logo.')
										return true
										//return Promise.all(this.name);   // 18.11.2021
									}

									//return (cryptoUtils.q.all(this.specialists)) ; // 15.06.2017 fix ? --ls
									//return true;    // a regime esce da qui
									//return Promise.all(this.specialists);   // 18.11.2021 mai qui con le return dentro le if
								})
							}
						})

						//console.log("(initProfile) end DOC");  // lo scrive troppo presto, prima degli specialist ?!
						//return true;
						//return cryptoUtils.q.when(null);
						//return Promise.resolve(this);  // 18.11.2021 fix ?
					})
				})
				// });
			} else {
				console.log('(initProfile) keyboxPhoto not found')
				// per user NS passa di qui ?  04.07.2018
				//return this.cryptoUtils.q.when(null); // 17.01.2022
				if (this.type == UserType.GOD)
					// 17.11.2022 patch, qui con utente GOD
					return Promise.resolve(true)
				else return Promise.reject(null)
			}
		} else {
			Util.debug('(initProfile) ko type, ' + this.type)
			//return this.cryptoUtils.q.when(null);
			return Promise.reject(null)
		}
	}

	public setCredits(credits: number) {
		this.saleInfo.available_credits = credits
		this.saleInfo.initWarningFlags()
	}

	public isImpactEnabled() {
		return this.settings.impact_anamn_group > 0
	}

	public hasValidSaleInfo(): boolean {
		let ret = false

		if (this.saleInfo && this.saleInfo.sale_plan_id > 0) {
			ret = true
		}

		return ret
	}

	// 29.12.2022
	public setSaleInfo(infoSale: SaleInfo) {
		if (infoSale && infoSale.salePlan && infoSale.salePlan.id > 0) {
			// TODO considerazioni su cambiamenti da precedente ?
			// console.log(infoSale)
			this.saleInfo = infoSale
			this.saleInfo.initWarningFlags()
			Util.debug('(setSaleInfo) plan id:' + this.saleInfo.salePlan.id + ' level: ' + this.saleInfo.salePlan.level)
		}
	}

	public setServices(services: service[]) {
		Util.debug('User - setServices')
		this.saleInfo.services = []
		for (let serv of services) {
			this.saleInfo.services.push(new service(serv))
		}
		this.saleInfo.initWarningFlags()
	}

	// 20.08.2019
	// 12.11.2021   AGGIORNAMENTO ANGULAR
	public getBrand() {
		var usrBrand: string = ''
		if (this.settings) {
			usrBrand = this.settings.brand
		}
		//    if(usrBrand == null){
		//      //console.log("(getBrand) ko..");
		//      usrBrand = ""; // Config.BR_NS; // default  ?
		//    }
		return usrBrand
	}

	// 25.08.2022
	getOrganization(): string {
		let ret = ''
		if (this.mainAddress != null) {
			ret = this.mainAddress.organization
		}
		return ret
	}

	getVat(): string {
		return this.mainAddress ? this.mainAddress.vat : ''
	}

	// 25.05.2022
	public getCountry() {
		let ret = ''

		// 06.09.2022 precedenza al campo country in chiaro, come sul doctor
		if (this.country && this.country != '') {
			ret = this.country
		} else if (this.mainAddress != null) {
			ret = this.mainAddress.country
		}
		return ret
	}

	public getTypeAgreementStatus(type: AgrType): AgrStatus {
		let ret: AgrStatus = AgrStatus.IGNORE

		if (this.agreements_status && this.agreements_status.length > 0) {
			let agreement = this.agreements_status.find((x) => x.doc_type == type)

			if (agreement) {
				ret = agreement.status
			}
		}
		return ret
	}

	public getAgreementsStatus(status: AgrStatus): { res: boolean; agreements: AgreementsStatus[] } {
		let res = { res: false, agreements: [] }

		if (this.agreements_status && this.agreements_status.length > 0) {
			let agreements = this.agreements_status.filter((x) => x.status == status)

			if (agreements && agreements.length > 0) {
				res.res = true

				for (let agr of agreements) {
					res.agreements.push(new AgreementsStatus(agr))
				}
			}
		}

		return res
	}

	public setAgreementStatus(agr: AgrType, status: AgrStatus) {
		let found = this.agreements_status.find((x) => x.doc_type == agr)

		found.status = status
	}

	// 27.05.2022
	// public getAgreementStatus() {
	// 	let ret = AgrStatus.IGNORE
	// 	if (this.agreementInfo != null) {
	// 		ret = this.agreementInfo.status
	// 	}
	// 	return ret
	// }

	// public getAgreementTHStatus() {
	// 	let ret = AgrStatus.IGNORE
	// 	if (this.agreementInfoTH != null) {
	// 		ret = this.agreementInfoTH.status
	// 	}
	// 	return ret
	// }

	//credo non piú usato
	public getPatientAgreementStatus(): AgrStatus {
		return this.agreementPatientsInfo
	}

	public getAnamnesisGroup() {
		let grp = 0

		if (this.settings.anamnesis_group != 0) {
			grp = this.settings.anamnesis_group
		}
		return grp
	}

	public getImpactAnamnesisGroup() {
		let grp = 0

		if (this.settings.impact_anamn_group != 0) {
			grp = this.settings.impact_anamn_group
		}
		return grp
	}

	/* 13.07.2021 teniamo gestione su session [ls]
  // 20.08.2019, no create or edit per Brillen
  public canCreatePatient(){
  }
  */

	/* 13.07.2021 teniamo gestione su session [ls]
  // 20.08.2019
  public canEditPatient(){    
  }
  */
	public isPrivate() {
		return this.user_subtype == Config.SUB_PRIVATE
	}

	// 04.02.2021
	public isSuperB() {
		var ret = false
		if (
			this.type == UserType.OPERATOR && // livello 1
			this.subType == UserType.DOCTOR && // doctor, non optician
			this.user_subtype == Config.SUB_SUPER
		) {
			ret = true
		}
		return ret
	}

	// 20.09.2022 replaced by isMini()
	// himself, logged user
	private isMiniB() {
		var ret = false
		if (
			this.type == UserType.OPERATOR && // livello 1
			this.subType == UserType.DOCTOR && // doctor, non optician
			this.user_subtype == Config.SUB_MINI
		) {
			ret = true
		}
		return ret
	}

	// 20.09.2022 both miniB or miniA
	public isMini() {
		var ret = false
		if (this.type == UserType.OPERATOR && this.user_subtype == Config.SUB_MINI) {
			ret = true
		}
		return ret
	}

	public isMiniC() {
		var ret = false

		if (this.role == Config.PR_SPECIALIST && this.user_subtype == Config.SUB_MINI) {
			ret = true
		}

		return ret
	}

	// 11.02.2021 both super or mini B or miniA
	public isGroupB() {
		return this.isGroup()
	}

	// nuovo nome piu' chiaro
	public isGroup() {
		var ret = false
		if (
			this.type == UserType.OPERATOR && // livello 1, sia opt che doct
			(this.user_subtype == Config.SUB_SUPER || this.user_subtype == Config.SUB_MINI)
		) {
			ret = true
		}
		return ret
	}

	// anche per support
	public isSuper() {
		let ret = this.user_subtype == Config.SUB_SUPER
		return ret
	}

	// vd analogo metodo su session
	public isLevel1(): boolean {
		let flagA = this.type == UserType.OPERATOR // entrambi i livelli 1
		let flagB = this.role == Config.PR_OPTICIAN || this.role == Config.PR_DOCTOR

		return flagA || flagB
	}

	// 18.08.2021
	public hasSpecialists() {
		var ret = this.specialists != null && this.specialists.length > 0
		return ret
	}

	public getRelationNumber(): number {
		let ret: number = 0

		if (!this.isLevel1()) {
			return ret
		} else {
			if (this.specialists != null && this.specialists.length > 0) {
				ret = this.specialists.filter((el) => el.user_subtype != Config.SUB_MINI).length

				return ret
			} else {
				return ret
			}
		}
	}

	// 05.06.2020
	public getSpecialistName(specId) {
		var ret = ''
		var spec = this.getSpecialist(specId)
		if (spec != null) {
			ret = spec.display_name
		} else {
			// potrebbe essere stata cancellata la relazione
			Util.debug('(USR) specialist not found! id: ' + specId)
		}

		return ret
	}

	// 05.06.2020 ritorna il livello2 collegato, se c'e'
	// 12.11.2021 AGGIORNAMENTO ANGULAR
	getSpecialist(specId: string) {
		// Tipizzazzione di ret dovuta al cambio di versione: null non ok.
		let ret: Specialist | null = null
		if (this.specialists != null && this.specialists.length > 0) {
			for (var i = 0; i < this.specialists.length; i++) {
				if (this.specialists[i].distributor_id == specId) {
					ret = this.specialists[i]
					break
				}
			}
		}
		return ret
	}

	public addSpecialist(specialists: Specialist[]) {
		for (let spec of specialists) {
			this.specialists.push(spec)
		}
	}

	public clearAllSpecialists() {
		this.specialists = []
	}

	public clearSpecialists(specialists: Specialist[]) {
		// to test
		let indicesToRemove = []

		if (specialists.length > 0) {
			for (let spec of specialists) {
				//questi specialist che mi arrivano non sono proprio oggetto specialist quindi devo cercare per id, almeno ora dove viene chiamato
				// let i = this.specialists.indexOf(spec)
				// console.log(spec)
				let s = this.specialists.find((el) => el.distributor_id == spec.distributor_id)
				if (s) {
					let i = this.specialists.indexOf(s)
					// console.log(i)
					indicesToRemove.push(i)
				}
			}
		}

		indicesToRemove = indicesToRemove.sort((a, b) => b - a)

		indicesToRemove.forEach((index) => {
			if (index > -1) {
				this.specialists.splice(index, 1)
			}
		})
	}

	// 21.12.2022 almeno uno privato
	public hasPrivateSpecialist() {
		if (!this.hasSpecialists()) return false

		let ret = false
		for (let i = 0; i < this.specialists.length; i++) {
			if (this.specialists[i].user_subtype == Config.SUB_PRIVATE) {
				ret = true
				break
			}
		}

		return ret
	}

	public getDevices() {
		return this.userDevices
	}

	public hasDevicesWithoutService(): { res: boolean; models: string[] } {
		//devo tornare anche il modello in modo che poi posso capire poi se per quello modello ci sono servizi associabili
		//qui importante vedo solo se ci sono dispositivi senza services
		let ret = { res: false, models: [] }

		if (this.userDevices.length > 0 && this.saleInfo.services) {
			let devicesSn = [...new Set(this.userDevices.map((el) => el.sn))]

			let deviceServices = this.saleInfo.services.filter((s) => s.status != serviceStatus.PURCHASED && s.service != serviceType.HG)
			// se 0, vuol dire che non ho servizi active o expired dei dispositivi, quindi tutti i dispositivi senza service
			if (deviceServices.length == 0) {
				ret.res = true
				ret.models = [...new Set(this.userDevices.map((el) => el.model))]
				return ret
			}
			for (let sn of devicesSn) {
				// ho servizi attivi/expired/schuladati o altro per questo sn?
				let find = deviceServices.filter((el) => el.device_sn == sn)
				if (find.length == 0) {
					let model = this.userDevices.find((el) => el.sn == sn).model

					ret.res = true
					ret.models.push(model)
				}
			}
		}
		return ret
	}

	public hasPurchasedServices(): { res: boolean; tot: number; services: service[] } {
		let ret = { res: false, tot: 0, services: [] }
		// prima ritornavo il n. servizi, ora ritorna il n. pacchetti
		if (this.saleInfo.services) {
			let services = this.saleInfo.services.filter((s) => s.status == serviceStatus.PURCHASED && s.service != serviceType.HG)

			if (services.length > 0) {
				let tot = [...new Set(services.map((el) => el.pack_id))]

				ret = { res: true, tot: tot.length, services: services }
			}
		}
		return ret
	}

	// 02.03.2023
	public getKeyPhoto() {
		// nuovo oggetto, poi viene ri-duplicato nelle funz aes.
		let myKey = new forge.util.ByteStringBuffer(this.keyPhoto)
		return myKey
	}

	/*
  // 04.08.2022 ritorna il primo specialista, potrebbe essere l'unico
  getFirstSpecialist(){
    let spec: Specialist = null;
    if(this.specialists != null && this.specialists.length > 0){
      for (var i = 0; i < this.specialists.length; i++) {
        if (this.specialists[i].user_type == "Specialist") {
          spec = this.specialists[i];
          break;                   
        }
      }
    }
    return spec;
  }
  */

	// 06.09.2019 solo per Focus
	public urgentSymptEnabled() {
		var ret = false
		return ret
	}

	// 17.02.2017 era getUserSubType
	private static getUserProfile(profileResponse: ProfilesResponse): UserType {
		var rawProfile = profileResponse.profile
		let ret = User.getUserTypeFromRole(rawProfile.user_type)
		return ret

		/*
    if (rawProfile.user_type == Config.PR_OPTICIAN)
      return UserType.OPTICIAN;
    else if (rawProfile.user_type == Config.PR_DOCTOR)
      return UserType.DOCTOR;  // 04.02.2021 differenziato       

    else if (rawProfile.user_type == Config.PR_SPECIALIST)
      return UserType.SPECIALIST;
    else if (rawProfile.user_type == Config.PR_DISTRIB)
      return UserType.DISTRIB; // 04.02.2021 differenziato
    //return UserType.DEALER; // uguale al tipo 

    else
      return UserType.NONE;
    */
	}

	private static getUserTypeFromRole(profile: string): UserType {
		if (profile == Config.PR_OPTICIAN) return UserType.OPTICIAN
		else if (profile == Config.PR_DOCTOR) return UserType.DOCTOR // 04.02.2021 differenziato
		else if (profile == Config.PR_SPECIALIST) return UserType.SPECIALIST
		else if (profile == Config.PR_DISTRIB) return UserType.DISTRIB // 04.02.2021 differenziato
		//return UserType.DEALER; // uguale al tipo
		else if (profile == Config.PR_VICE) return UserType.VICE
		else if (profile == Config.PR_MANAGER) return UserType.MANAGER
		else if (profile == Config.PR_STATS) return UserType.STATS
		else if (profile == Config.PR_CLINIC) return UserType.CLINIC
		else if (profile == Config.PR_INSTALLER) return UserType.INSTALLER
		else return UserType.NONE
	}

	// rinominata con userLevel - ex getUserType
	// Nota:
	// il pharmacist e' un doctor, solo dove serve, si usa il subType
	// stessa cosa per Specialist: e' un dealer, quando serve, usare subtype --ls
	private static getUserLevel(profileResponse: ProfilesResponse): UserType {
		var rawProfile = profileResponse.profile

		// anticipato qui, vale per tutti i liv1, (i livelli 2 e 3 arrivano con counter = 3)
		if (rawProfile.access_counter == 0) {
			return UserType.USER_FIRST_TIME
		} else if (rawProfile.access_counter == 1) {
			return UserType.USER_PUK_TIME // 02.02.2017 --ls
		} else
			switch (rawProfile.user_type) {
				case Config.PR_OPTICIAN:
				case Config.PR_DOCTOR:
					return UserType.OPERATOR

				case Config.PR_SPECIALIST:
				case Config.PR_DISTRIB:
					return UserType.DEALER

				// 11.01.2017 creato user superAdmin
				case 'NextSight':
				case Config.PR_ADMIN:
					return UserType.GOD

				// 03.08.2018 creato user viceAdmin
				case Config.PR_VICE:
					return UserType.VICE

				// 25.05.2021
				case Config.PR_MANAGER:
					return UserType.MANAGER

				// 20.07.2021
				case Config.PR_STATS:
					return UserType.STATS

				// 01.06.2022
				case Config.PR_SERVICE:
					return UserType.SERVICE

				// 09.11.2022
				case Config.PR_SUPPORT:
					return UserType.SUPPORT

				case Config.PR_INSTALLER:
					return UserType.INSTALLER

				case Config.PR_CLINIC:
					return UserType.CLINIC

				default:
					return UserType.NONE
			}
	}

	canDeleteClinicAddress() {
		let ret = false

		let filter = this.addresses.filter((el) => el.role == Config.CLINIC)

		if (filter.length > 1) {
			ret = true
		}
		return ret
	}

	getAddresses() {
		return this.addresses
	}

	// 11.03.2022
	getMainAddress(): Address {
		return this.mainAddress
	}

	getMainAddressOffset(): number {
		return this.mainAddress.gmt_offset_sec
	}

	getMainAddressId(): number {
		return this.mainAddress.id
	}

	//23.02.2021 spostati da session
	// 06.06.2019 per il report medical
	getAddressLine1(): string {
		var addr = ''
		if (this.mainAddress != null) {
			addr = this.mainAddress.address_line1 // + ' - ' +this.user.city;
		}
		if (addr == null)
			// 01.07.2019 patch
			addr = ''

		return addr
	}

	// 06.06.2019 per il report
	getAddressLine2(): string {
		// 24.08.2022 spostata su address
		let mainAddr = this.getMainAddress()
		let addr2 = mainAddr.getAddressLine2()
		return addr2
	}

	public getMainAddressCoordinates(): LatLng | null {
		if (this.mainAddress.latitude && this.mainAddress.longitude) {
			let coordinates: LatLng = {
				latitude: this.mainAddress.latitude,
				longitude: this.mainAddress.longitude,
			}

			return coordinates
		} else {
			return null
		}
	}

	public getMainAddressRole(): string {
		return this.mainAddress.role
	}

	// not used
	// per il report commercial
	private getAddressReport(): string {
		var address = ''
		if (this.mainAddress != null) {
			address = this.mainAddress.address_line1 + '\n' + this.mainAddress.city + ' - ' + this.mainAddress.province
			if (!this.mainAddress.address_line1 && !this.mainAddress.city) {
				// patch
				address = '-'
			}
		}
		return address
	}

	// 16.02.2022
	// non si puo' usare direttamente per il case..
	getDateFormat() {
		var dtFormat = DATE_SQL // default diverso da tutto

		if (Util.equalsIgnoreCase(this.settings.date_format, DATE_US)) {
			dtFormat = DATE_US
		} else if (Util.equalsIgnoreCase(this.settings.date_format, DATE_EU)) {
			dtFormat = DATE_EU
		}

		return dtFormat
	}

	// 29.04.2022
	getFullName() {
		return this.firstname + ' ' + this.lastname
	}

	getVacancies() {
		return this.vacancies
	}

	// 10.03.2022 salvo le nuove pref senza richiederle di nuovo al server
	updatePreferences(prefs: Preferences) {
		this.settings.date_format = prefs.date_format
		this.settings.pdf_format = prefs.pdf_format
	}

	updateLogo(logo: string, logo_name: string) {
		this.logo = logo
		this.logo_name = logo_name
	}

	updateVacancies(vacancy: { from_date: string; to_date: string; id?: number }) {
		this.vacancies[0] = vacancy
	}

	deleteVacancies() {
		this.vacancies = []
	}

	isInUnavailableRange(fromDate: Date, toDate: Date) {
		const today = new Date()
		const fromDateUtc = new Date(Date.UTC(fromDate.getUTCFullYear(), fromDate.getUTCMonth(), fromDate.getUTCDate()))
		const toDateUtc = new Date(Date.UTC(toDate.getUTCFullYear(), toDate.getUTCMonth(), toDate.getUTCDate()))
		const todayUtc = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()))
		return todayUtc >= fromDateUtc && todayUtc <= toDateUtc
	}

	// 29.04.2022
	isTelerefractEnabled() {
		return (this.settings != null && this.settings.telerefract == 'Y') || this.type === UserType.INSTALLER
	}

	/* TODO
	// 13.05.2022 per utenti specialist o doctor
	getMeAsReferrer(currRel ? : Relation) : Referrer {
		
		if(!this.isSpecialist() || currRel == null){
			return null;
		}

		let myId = this.user_id;
		let relId = parseInt(currRel.distrib_id);
		if(relId != myId){  //relazione sbagliata
			return null;
		}

		let meAsRef = new Referrer(myId);
		meAsRef.order_num = this.order_reg_num;
		meAsRef.display_name = currRel.display_name;		
		if(currRel.signature != null && currRel.signature.length > 3){
			meAsRef.signature = currRel.signature; 
			meAsRef.hasSignature = true; 
		}

		return meAsRef;
	}
	*/
}

export class editsInfo {
	firstName?: string
	lastName?: string
	ref_email?: string
	phone1?: string
	organization?: string
	vat?: string

	constructor() {
		this.firstName = ''
		this.lastName = ''
		this.ref_email = ''
		this.phone1 = ''
		this.organization = ''
		this.vat = ''
	}
}

export class editLogo {
	logo?: any
	logo_name?: string

	constructor() {
		this.logo = null
		this.logo_name = ''
	}
}

export class editAddress {
	address?: string // 31.05.2017 add
	city?: string
	province?: string
	country?: string
	zip?: string
	// zipMin?: number
	// zipMax?: number

	constructor() {
		this.address = ''
		this.city = ''
		this.province = ''
		this.country = ''
		this.zip = ''
		// this.zipMin = 4
		// this.zipMax = 10
	}
}

export class editReport {
	order_reg_num?: string // solo per doctors e specialists
	display_name?: string
	signature?: any
	signature_name?: string

	constructor() {
		this.order_reg_num = ''
		this.display_name = ''
		this.signature = null
		this.signature_name = ''
	}
}

export interface TokenResponse {
	access_token: string
	created_at: number
	expires_in: number
	token_type: string
}

// 22.02.2017 con nomi campi un po' diversi --ls
export class LoggedUser {
	usrname: string
	pwd: string
	//anamnesis: DocAnamnesis[];   //domande che il medico puo' fare
	nuovoPuk: string
	keyPhoto: string // serve per il vice --ls
	validBrand: boolean // 30.05.2019
	myIp: string // 08.03.2021
	isFirstLogin: boolean

	constructor() {
		this.usrname = ''
		this.pwd = ''
		this.validBrand = true // fino a prova contraria  30.05.2019
		this.myIp = ''
		this.keyPhoto = ''
		this.nuovoPuk = ''
		this.isFirstLogin = false
	}
}

// 28.09.2017 struttura dati DICOM aggiuntiva
export class UsrDicom {
	patient_id: string
	patient_address: string
	patient_birth_date: string
	patient_telephone_number: string
	patient_telecom_information: string

	constructor(bagDati?) {
		if (bagDati) {
			this.patient_id = bagDati.patient_id
			this.patient_address = bagDati.patient_address
			this.patient_birth_date = bagDati.patient_birth_date
			this.patient_telephone_number = bagDati.patient_telephone_number
			this.patient_telecom_information = bagDati.patient_telecom_information
		} else {
			this.patient_id = ''
			this.patient_birth_date = ''
			this.patient_address = ''
			this.patient_telephone_number = ''
			this.patient_telecom_information = ''
		}
	}
}

// 23.05.2017 info tablet doctor, vd DoctorsResponse
// 10.03.2021 cambiata completamente [ls]
export class UserDevice {
	user_id: number
	device_id: number
	model: string
	sn: string
	caller_ip: string
	main_build: string
	first_use: Date
	last_use: Date
	time_log_request: Date // 23.02.2022
	gmt_offset: string
	gmt_offset_state: number
	available_space: number
	main_comp_name: string
	os_ver: string

	constructor(rawObj?) {
		this.user_id = 0
		this.device_id = 0
		this.caller_ip = ''
		this.main_build = ''
		this.first_use = null
		this.last_use = null
		this.time_log_request = null
		this.gmt_offset_state = 0
		this.gmt_offset = ''
		this.main_comp_name = ''
		this.os_ver = ''
		//TO DO

		if (rawObj != null) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (rawObj.last_use) {
				this.last_use = DateParser.parseDate(rawObj.last_use)
			}

			if (rawObj.first_use) {
				this.first_use = DateParser.parseDate(rawObj.first_use)
			}

			if (rawObj.time_log_request != null) {
				//this.time_log_request = rawObj.time_log_request;
				this.time_log_request = DateParser.parseDate(rawObj.time_log_request) // 29.09.2022 fix [ls]
			}
			// rawObj.gmt_offset = '0400' //test
			if (rawObj.gmt_offset) {
				this.gmt_offset = Util.formatGmtTime(rawObj.gmt_offset).gmt
				this.gmt_offset_state = Util.formatGmtTime(rawObj.gmt_offset).state
			}
		}
	}

	public setGMToffsetState(state: number) {
		this.gmt_offset_state = state
	}
}

export class userDeviceSubscription extends UserDevice {
	services: service[]
	services_names: string[]
	services_status: string
	canAcceptServices: boolean

	constructor(draft?) {
		super(draft)
		this.services = []
		this.services_names = []
		this.services_status = ''
		this.canAcceptServices = true

		if (draft) {
			this.services = draft.services
			this.services_names = draft.services_names
			this.services_status = draft.services_status
			this.canAcceptServices = draft.canAcceptServices
		}
	}

	static getServiceDevicesStatus(draft?) {
		let device = new userDeviceSubscription(draft)

		device.services_status = ''
		let resultsArray: string[] = []
		// console.log(device)

		for (let serv of device.services) {
			if (serv.service == serviceType.NDM) {
				if (serv.status_flag == Config.RED) {
					resultsArray.push(Config.RED)
				} else if (serv.status_flag == Config.YELLOW) {
					resultsArray.push(Config.YELLOW)
				} else if (serv.status_flag == Config.GREEN) {
					resultsArray.push(Config.GREEN)
				}
			} else {
				if (serv.status == serviceStatus.ACTIVE) {
					resultsArray.push(Config.GREEN)
				} else if (serv.status == serviceStatus.EXPIRED) {
					resultsArray.push(Config.RED)
				}
			}
		}

		if (resultsArray.indexOf(Config.GREEN) > -1) {
			device.services_status = Config.GREEN
		}

		if (resultsArray.indexOf(Config.YELLOW) > -1) {
			device.services_status = Config.YELLOW
		}

		if (resultsArray.indexOf(Config.RED) > -1) {
			device.services_status = Config.RED
		}
		return device
	}

	static canDeviceAcceptPairServices(draft) {
		let device: userDeviceSubscription = new userDeviceSubscription(draft)
		device.canAcceptServices = true

		let montlyServices = device.services.filter((s) => s.service_mode == serviceMode.MONTLY_RENEW)

		if (montlyServices.length > 0) {
			device.canAcceptServices = false
		}

		return device
	}
}

// ora salvato sulla sessionStorage, in caso di reload della pg
export class CookieUser {
	username: string
	password: string
	token: string
	lang: string // 03.05.2022
	myIp: string // 31.01.2023
	encr: boolean

	// dovrebbe essere sempre un oggetto User ?
	constructor(RawUsDev: any) {
		this.username = ''
		this.password = ''
		this.token = null
		this.lang = 'en'
		this.myIp = ''
		this.encr = false

		if (RawUsDev) {
			this.username = RawUsDev.username
			this.password = RawUsDev.password
			this.token = RawUsDev.token

			if (RawUsDev.lang) {
				this.lang = RawUsDev.lang
			} else if (RawUsDev.settings && RawUsDev.settings.lang) {
				this.lang = RawUsDev.settings.lang
			}
		}
	}
}

// 18.04.2023 lista di time sulle ultime operazioni fatte da un utente
export class LastSeen {
	deviceInfo: Date
	loginDevice: Date
	loginWeb: Date
	postExam: Date
	postPatient: Date
	postPatientWeb: Date
	postVisit: Date
	web: Date

	constructor(rawObj?) {
		this.deviceInfo = null
		this.loginDevice = null
		this.loginWeb = null
		this.postExam = null
		this.postPatient = null
		this.postPatientWeb = null
		this.postVisit = null
		this.web = null

		if (rawObj) {
			if (rawObj.device_info != null) {
				this.deviceInfo = DateParser.parseDate(rawObj.device_info)
			}
			if (rawObj.login_device != null) {
				this.loginDevice = DateParser.parseDate(rawObj.login_device)
			}
			if (rawObj.login_web != null) {
				this.loginWeb = DateParser.parseDate(rawObj.login_web)
			}
			if (rawObj.post_exam != null) {
				this.postExam = DateParser.parseDate(rawObj.post_exam)
			}
			if (rawObj.post_patient != null) {
				this.postPatient = DateParser.parseDate(rawObj.post_patient)
			}
			if (rawObj.post_patient_web != null) {
				this.postPatientWeb = DateParser.parseDate(rawObj.post_patient_web)
			}
			if (rawObj.post_visit != null) {
				this.postVisit = DateParser.parseDate(rawObj.post_visit)
			}
			if (rawObj.web != null) {
				this.web = DateParser.parseDate(rawObj.web)
			}
		}
	}
}
