<!-- <div class="loading-container" [hidden]="!session.isLoadingRemotes()">
	<div class="tail-spin">&nbsp;</div>
</div> -->

<div class="graders-list-container">
	<div class="panel-head py-3">
		<div class="container-fluid">
			<div class="row mb-5">
				<div class="col-12">
					<ul class="nav nav-pills" ngbNav #nav="ngbNav" (navChange)="onNavChange($event)">
						<li class="nav-item me-3 position-relative" [ngbNavItem]="tab.id" *ngFor="let tab of tabs" [disabled]="loadingRelations || loadingAgreements || loadingRemotes">
							<a ngbNavLink uppercase class="nav-link fw-bold">
								{{tab.name}}
								<div class="spinner-border spinner-border-sm" *ngIf="tab.spinner && activeTab == tab.id && (loadingRelations || loadingAgreements || loadingRemotes)" role="status">
									<span class="visually-hidden">Loading...</span>
								</div>
							</a>
							<span class="badge rounded-pill bg-danger" *ngIf="tab.counter > 0" [ngbTooltip]="tab.counter+' '+tab.message">{{tab.counter}}</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col-11">
					<p class="filter-title-section w-50" (click)="showFilters = !showFilters" [ngClass]="showFilters ? '' : 'border-bottom' ">
						<label class="fw-bold uppercase">{{'DISTRIB_LIST.FILTERS.TITLE' | translate}}</label>
						<fa-icon [icon]="faSortDown" faSortDown [ngClass]="showFilters ? 'show' : '' " aria-hidden="true" class="float-end"></fa-icon>

						<span *ngIf="filterSet" class="me-2 fw-light float-end">{{'DISTRIB_LIST.FILTERS.MESSAGE' | translate}}<fa-icon [icon]="faCircleExclamation" faCircleExclamation aria-hidden="true"></fa-icon></span>
					</p>
					<form [formGroup]="filterForm" class="filter-form__container d-flex justify-content-between flex-wrap" [ngClass]="showFilters ? 'show border-bottom' : '' ">
						<div class="col-xxl-2 col-sm-3 d-flex align-items-center mb-3">
							<!-- IS TEST -->
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'DISTRIB_LIST.FILTERS.ISTEST' | translate}}</mat-label>

								<mat-select name="grading" (selectionChange)="filterValue()" formControlName="isTest">
									<mat-option *ngFor="let opt of isTestOpt" [value]="opt.value"> {{opt.descr}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('isTest').value != null ? 'show': '' " (click)="filterForm.get('isTest').reset(); filterValue()"></fa-icon>
						</div>

						<div class="col-xxl-2 col-sm-3 d-flex align-items-center deleted mb-3">
							<!-- DELETED USER -->
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'DISTRIB_LIST.FILTERS.ISDELETED' | translate}}</mat-label>
								<mat-select name="grading" (selectionChange)="filterValue()" formControlName="showDeleted">
									<mat-option *ngFor="let opt of isTestOpt" [value]="opt.value"> {{opt.descr}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('showDeleted').value != null ? 'show': '' " (click)="filterForm.get('showDeleted').reset(); filterValue()"></fa-icon>
						</div>

						<!--  STATUS  -->
						<div class="col-xxl-2 col-sm-3 d-flex align-items-center mb-3">
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'DISTRIB_LIST.FILTERS.STATUS' | translate}}</mat-label>
								<mat-select name="grading" (selectionChange)="filterValue()" formControlName="status">
									<mat-option *ngFor="let stat of status" [value]="stat.value"> {{stat.descr}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('status').value != null ? 'show': '' " (click)="filterForm.get('status').reset(); filterValue()"></fa-icon>
						</div>

						<!-- SUB TYPE -->
						<div class="col-xxl-2 col-sm-3 d-flex align-items-center mb-3">
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'DISTRIB_LIST.FILTERS.SUBTYPE' | translate}}</mat-label>
								<mat-select name="grading" (selectionChange)="filterValue()" formControlName="subType">
									<mat-option *ngFor="let sub of subtypes" [value]="sub"> {{sub}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('subType').value != null ? 'show': '' " (click)="filterForm.get('subType').reset(); filterValue()"></fa-icon>
						</div>

						<!-- TYPE -->
						<div class="col-xxl-2 col-sm-3 d-flex align-items-center mb-3">
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'DISTRIB_LIST.FILTERS.TYPE' | translate}}</mat-label>
								<mat-select name="grading" (selectionChange)="filterValue()" formControlName="userType">
									<mat-option *ngFor="let type of types" [value]="type"> {{type}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('userType').value != null ? 'show': '' " (click)="filterForm.get('userType').reset(); filterValue()"></fa-icon>
						</div>
					</form>
				</div>
				<div class="col-1 d-flex align-items-end">
					<button type="button" class="btn btn-primary btn-sm reload shadow" (click)="reloadList()" [disabled]="!reloadEnable">
						{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="panel-body position-relative" style="min-height: 35vh">
		<loader [activateSpinner]="needSpinner()"></loader>
		<!-- 
		<div class="loader-container" [ngClass]="needSpinner() ? 'show' : '' ">
			<div [hidden]="false" class="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<table mat-table [dataSource]="remoteListTable" matSort style="width: 100%" class="graders-list-container__table" [ngClass]="!needSpinner() ? 'show' : '' " multiTemplateDataRows>
						<!-- sub-type -->
						<ng-container matColumnDef="subType">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let element">
								<div *ngIf="element.getSubType() == 'VX' || element.getSubType() =='ClinicAdmin' else tablePrivate">
									<div class="logo"></div>
								</div>
								<ng-template #tablePrivate>
									<span class="fw-bold">[PR]</span>
								</ng-template>
							</td>
						</ng-container>

						<!-- code -->
						<ng-container matColumnDef="code">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.CODE' | translate }}</th>
							<td mat-cell *matCellDef="let element">{{ element.code }}</td>
						</ng-container>

						<!-- username -->
						<ng-container matColumnDef="username">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.USERNAME' | translate }}</th>
							<td mat-cell *matCellDef="let element" [ngClass]="element.isActive ? 'fw-bold' : 'notActive' ">{{ element.username }}</td>
						</ng-container>

						<!-- name -->
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.NAME' | translate }}</th>
							<td mat-cell *matCellDef="let element">{{ element.name }}</td>
						</ng-container>

						<!-- organization / display name -->
						<ng-container matColumnDef="organization">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>
								<div>
									<span>{{'DISTRIB_LIST.ORGANIZATION' | translate}}/</span>
									<br />
									<span>{{'DISTRIB_LIST.DISPLAY_NAME' | translate}}</span>
								</div>
							</th>
							<td mat-cell *matCellDef="let element">{{element.user_type == 'ClinicAdmin' ? element.mainAddress.organization : element.display_name }}</td>
						</ng-container>

						<!-- user type -->
						<ng-container matColumnDef="user_type">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.TYPE' | translate }}</th>
							<td mat-cell *matCellDef="let element">{{ element.user_type }}</td>
						</ng-container>

						<!-- country -->
						<ng-container matColumnDef="country">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCTOR_LIST.COUNTRY' | translate }}</th>
							<td mat-cell *matCellDef="let element">{{ element.country }}</td>
						</ng-container>

						<!-- is test -->
						<ng-container matColumnDef="is_test">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CREATE_USER.TEST' | translate }}</th>
							<td mat-cell *matCellDef="let element">{{ element.is_test == 'Y' ? '[T]' : '' }}</td>
						</ng-container>

						<!-- rels number
						<ng-container matColumnDef="relationsCount">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>Rel num</th>
							<td mat-cell *matCellDef="let element">{{element.relations.length}}</td>
						</ng-container> -->

						<!-- active rels -->
						<ng-container matColumnDef="activeRels">
							<th mat-header-cell *matHeaderCellDef>{{ 'DISTRIB_LIST.ACTIVE_RELS' | translate }}</th>
							<td mat-cell *matCellDef="let element" class="normalcase">{{ element.doctorCount}}</td>
						</ng-container>

						<!-- locationIcon-->
						<ng-container matColumnDef="locationIcon">
							<th mat-header-cell *matHeaderCellDef>{{ 'DISTRIB_LIST.LOCATIONS' | translate }}</th>
							<td mat-cell *matCellDef="let element" class="normalcase">
								<div class="locations-valid-icon ms-3 d-inline" *ngIf="element.isActive">
									<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.LOCATION_VALID' | translate }}" *ngIf="element.isAllAddressesValid()"></fa-icon>
									<fa-icon [icon]="faTriangleExclamation" class="p-3" faTriangleExclamation ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.LOCATIONS_WRONG' | translate }}" *ngIf="!element.isAllAddressesValid()"></fa-icon>
								</div>
							</td>
						</ng-container>

						<!-- relationIcon -->
						<ng-container matColumnDef="relationIcon">
							<th mat-header-cell *matHeaderCellDef>{{ 'DISTRIB_LIST.RELATIONS' | translate }}</th>
							<td mat-cell *matCellDef="let element" class="normalcase">
								<div class="relations-valid-icon ms-3 d-inline" *ngIf="element.doctorCount>0 && element.isActive">
									<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.LOADING' | translate }}" *ngIf="!element.loadRelationsComplete"></fa-icon>
									<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{element.allRelationsValid().msg | translate}}" *ngIf="element.loadRelationsComplete && element.allRelationsValid().resp"></fa-icon>
									<fa-icon [icon]="faTriangleExclamation" class="p-3" faTriangleExclamation ngbTooltip="{{element.allRelationsValid().msg | translate}}" *ngIf="element.loadRelationsComplete && !element.allRelationsValid().resp"></fa-icon>
								</div>
							</td>
						</ng-container>

						<!-- DocuemntIcon-->
						<ng-container matColumnDef="documentIcon">
							<th mat-header-cell *matHeaderCellDef>{{ 'DISTRIB_LIST.DOCUMENTS' | translate }}</th>
							<td mat-cell *matCellDef="let element" class="normalcase">
								<div class="documents-valid-icon ms-3 d-inline" *ngIf="element.isActive">
									<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.LOADING' | translate }}" *ngIf="!element.loadAgreementsComplete"></fa-icon>
									<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip=" {{element.isAllDocumentAccepted().msg | translate}}" *ngIf="element.loadAgreementsComplete && element.isAllDocumentAccepted().resp"></fa-icon>
									<fa-icon [icon]="faTriangleExclamation" class="p-3" faTriangleExclamation ngbTooltip=" {{element.isAllDocumentAccepted().msg | translate}}" *ngIf="element.loadAgreementsComplete && !element.isAllDocumentAccepted().resp"></fa-icon>
								</div>
							</td>
						</ng-container>

						<!-- last Column - block bouble click view - only click the buttons -->
						<ng-container matColumnDef="filter">
							<th mat-header-cell *matHeaderCellDef class="text-end">
								<mat-form-field appearance="outline">
									<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" [(ngModel)]="input" />
								</mat-form-field>
							</th>
							<td mat-cell *matCellDef="let element">
								<a *ngIf="session.isLevel3()" [hidden]="element.isDeleted()" (click)="$event.stopPropagation(); openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}">
									<fa-icon [icon]="faTrashAlt" aria-hidden="true"></fa-icon>
								</a>

								<a class="ms-3" *ngIf="session.isLevel3() " [hidden]="element.isDeleted()" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="$event.stopPropagation(); openEditModal(element)">
									<fa-icon [icon]="faEdit" aria-hidden="true"></fa-icon>
								</a>

								<a class="ms-3 showrels-button" *ngIf="(session.isLevel3() || session.isSupport() )&& activeTab == 'relations'" [ngClass]="!element.loadRelationsComplete ? 'disabled' : ''" [hidden]="element.isDeleted() || element.doctorCount == 0" ngbTooltip="{{'DISTRIB_LIST.RELATIONS' | translate }}" (click)="$event.stopPropagation();openRelationModal(element)">
									<fa-icon [icon]="faLink" aria-hidden="true"></fa-icon>
								</a>
							</td>
						</ng-container>

						<!-- expand row -->
						<ng-container matColumnDef="expand">
							<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
							<td mat-cell *matCellDef="let element">
								<div aria-label="expand row" *ngIf="(activeTab == 'locations' || (activeTab == 'relations' && element.user_type == 'ClinicAdmin') || activeTab == 'documents') && element.isActive ">
									<fa-icon [icon]="faCaretDown" aria-hidden="true" *ngIf="expandedElement !== element"></fa-icon>
									<fa-icon [icon]="faCaretUp" aria-hidden="true" *ngIf="expandedElement === element"></fa-icon>
								</div>
							</td>
						</ng-container>

						<!-- detail row -->
						<ng-container matColumnDef="expandedDetail">
							<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns[activeTab].length">
								<div class="additional-element-detail" (click)="$event.stopPropagation()">
									<div class="mt-2 p-3">
										<!-- locations -->
										<div *ngIf="activeTab == 'locations'">
											<span class="fw-bold">{{ 'DISTRIB_LIST.DETAIL_ROW.LOCATION_SAVED' | translate }}: </span><span>{{element.addresses.length}}</span>
											<ul class="mt-3" style="list-style-type: none" *ngIf="element.is_deleted == 'N'">
												<li *ngFor="let address of element.addresses; index as i" class="mb-2">
													<div class="d-flex">
														<div class="border-bottom py-1 px-3">{{i+1}}</div>
														<div class="border-bottom py-1 px-3">
															<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.ADDRESS_VALID' | translate }}" *ngIf="address.isAddressValid()"></fa-icon>
															<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.ADDRESS_WRONG' | translate }}" *ngIf="!address.isAddressValid()"></fa-icon>
														</div>
														<div class="border-bottom py-1 px-3">{{address.getExtendedAddressLabel()}}</div>
													</div>
												</li>
											</ul>
										</div>
										<!-- relations -->
										<div *ngIf="activeTab == 'relations'">
											<p><span class="fw-bold mb-2">{{ 'DISTRIB_LIST.DETAIL_ROW.GRADERS' | translate }}: </span><span> {{element.myMiniGraders.length}}</span></p>

											<table class="table" *ngIf="element.myMiniGraders.length > 0">
												<thead>
													<tr style="font-size: 0.8em">
														<th scope="col">&nbsp;</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.USERNAME' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.IS_ACTIVE' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.DISPLAY_NAME' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.LICENCE_NUM' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.ACTIVE_REL' | translate }}</th>
														<th scope="col">&nbsp;</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let grader of element.myMiniGraders; index as i">
														<th scope="row">{{i+1}}</th>
														<td>{{grader.username}}</td>
														<td>
															<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.GRADER_ACTIVE' | translate }}" *ngIf="grader.isActive"></fa-icon>
															<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.GRADER_INACTIVE' | translate }}" *ngIf="!grader.isActive"></fa-icon>
														</td>
														<td>{{grader.display_name}}</td>
														<td>{{grader.order_reg_num}}</td>

														<td>{{grader.isActive ? grader.doctorCount : ''}}</td>

														<td>
															<div class="relations-valid-icon ms-3 d-inline" *ngIf="grader.isActive ">
																<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.LOADING' | translate }}" *ngIf="!grader.loadRelationsComplete"></fa-icon>
																<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip=" {{ grader.allRelationsValid().msg | translate }}" *ngIf="grader.loadRelationsComplete && grader.allRelationsValid().resp"></fa-icon>
																<fa-icon [icon]="faTriangleExclamation" class="p-3" faTriangleExclamation ngbTooltip=" {{ grader.allRelationsValid().msg | translate }}" *ngIf="grader.loadRelationsComplete && !grader.allRelationsValid().resp"></fa-icon>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<!-- documents -->
										<div *ngIf="activeTab == 'documents'">
											<p><span class="fw-bold">{{ 'DISTRIB_LIST.DETAIL_ROW.ACCEPTED_DOC' | translate }}: </span><span>{{element.acceptedAgreements.length}}</span></p>

											<table class="table" *ngIf="element.acceptedAgreements.length > 0">
												<thead>
													<tr style="font-size: 0.8em">
														<th scope="col">&nbsp;</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.DOC_ID' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.TYPE' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.TARGET' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.ACCEPTED_DATE' | translate }}</th>
														<th scope="col">{{ 'DISTRIB_LIST.DETAIL_ROW.IS_VALID' | translate }}</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let agreement of element.acceptedAgreements; index as i">
														<th>{{i+1}}</th>
														<td>{{agreement.id}}</td>
														<td>{{agreement.doc_type}}</td>
														<td>{{agreement.target}}</td>
														<td>{{session.formatDateTime(agreement.acceptanceDate)}}</td>
														<td>
															<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.DOCUMENT_VALID' | translate }}" *ngIf="agreement.is_valid == 'Y'"></fa-icon>
															<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark ngbTooltip="{{ 'DISTRIB_LIST.TOOLTIPS.DOCUMENT_INVALID' | translate }}" *ngIf="agreement.is_valid == 'N'"></fa-icon>
														</td>
													</tr>
												</tbody>
											</table>

											<!-- <ul class="mt-3" style="list-style-type: none">
												<li *ngFor="let agreement of element.acceptedAgreements; index as i" class="mb-2">
													<div class="d-flex">
														<div class="border-bottom py-1 px-3">{{i+1}}</div>
														<div class="border-bottom py-1 px-3">{{agreement.doc_type}}</div>
														<div class="border-bottom py-1 px-3">{{session.formatDateTime(agreement.acceptanceDate)}}</div>
													</div>
												</li>
											</ul> -->
										</div>
									</div>
								</div>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns[activeTab]"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns[activeTab]" (click)="openDetailRow(row)" [ngClass]="row.user_type == 'ClinicAdmin' ? 'no-border' : '' "></tr>
						<tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row" [ngClass]="element.user_type == 'ClinicAdmin' ? '' : 'no-border' " [class.colapsed-row]="expandedElement != element"></tr>
					</table>

					<mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons [ngClass]="!session.isLoadingRemotes() ? 'show' : '' "> </mat-paginator>

					<div class="noresults-banner" [ngClass]="{show : (remoteList.length == 0 && !session.isLoadingRemotes()) || isFilterTextEmpty }">
						<div class="container">
							<div class="row text-center noresults-banner__cont">
								<div class="col-5"></div>
								<div class="col-2 alert alert-secondary" role="alert" *ngIf="!filterSet">{{ 'DISTRIB_LIST.NO_REMOTES' | translate }}</div>
								<div class="col-2 alert alert-secondary" role="alert" *ngIf="filterSet">{{ 'DISTRIB_LIST.NO_REM_FILTER' | translate }}</div>
								<div class="col-5"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
