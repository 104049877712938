import { Component, Input, OnInit } from '@angular/core'
import { Options } from '@angular-slider/ngx-slider'

@Component({
	selector: 'app-impact-slider',
	templateUrl: './impact-slider.component.html',
	styleUrls: ['./impact-slider.component.scss'],
})
export class ImpactSliderComponent implements OnInit {
	@Input() rangeMin: number
	@Input() rangeMax: number
	@Input() sliderValue: number
	@Input() inRange: boolean
	@Input() decimalDigits: number
	startingValue: number
	innerRangePercentage: string
	sliderOptions: Options
	ticksArray: number[] = [] // Array for the tick values

	ngOnInit() {
		this.startingValue = this.sliderValue
		this.setSliderOptions()
	}

	setSliderOptions() {
		// Define the number of internal and outer steps
		const totalSteps = 4
		const outerSteps = 6
		const totalTicks = totalSteps + outerSteps

		// Calculate the total range to ensure the correct number of steps
		const rangeDiff = this.rangeMax - this.rangeMin
		const buffer = (outerSteps * rangeDiff) / totalSteps
		const totalMin = this.rangeMin - buffer / 2
		const totalMax = this.rangeMax + buffer / 2
		const totalDiff = totalMax - totalMin

		// Calculate percentage to color the inner part of the slider
		this.innerRangePercentage = this.calculateInnerPercentage(rangeDiff, totalDiff)

		const stepSize = (totalMax - totalMin) / totalTicks
		const fineStepSize = parseFloat((stepSize / 100).toFixed(4))

		// Populate the ticksArray for the defined steps
		this.ticksArray = []
		for (let i = 0; i <= totalTicks; i++) {
			this.ticksArray.push(totalMin + i * stepSize)
		}

		// Define slider options
		this.sliderOptions = {
			readOnly: true,
			floor: totalMin,
			ceil: totalMax,
			step: fineStepSize,
			ticksArray: this.ticksArray,
			translate: (value: number): string => {
				if (this.startingValue < totalMin || this.startingValue > totalMax) {
					return value === this.sliderValue ? this.formatNumber(this.startingValue) : ''
				}
				return value === this.sliderValue ? this.formatNumber(this.sliderValue) : ''
			},
		}
	}

	formatNumber(value: number): string {
		return parseFloat(value.toFixed(this.decimalDigits)).toString()
	}

	calculateInnerPercentage(rangeDiff: number, totalDiff: number): string {
		return ((rangeDiff / totalDiff) * 100).toFixed(2) + '%'
	}
}
